import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const input = {
  minHeight: '76px',
};

const FilterResult = styled(Box)`
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: ${colors.White};
  min-height: 140px;
  width: 100%;
  border-radius: 0 0 12px 12px;
  padding: 24px 12px;
  box-shadow: ${colors.ShadowMd};
  display: flex;
  flex-direction: column;
`;

const filterResultWithScroll = {
  paddingRight: 0,
};

const AccountsList = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  max-height: 370px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 4px 12px 10px 0;
`;

const noScroll = {
  overflow: 'hidden',
};

const AccountsPage = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

const buttonViewMore = {
  alignSelf: 'center',
  fontSize: '14px',
  height: 'auto',
};

export { AccountsList, AccountsPage, buttonViewMore, FilterResult, filterResultWithScroll, input, noScroll };
