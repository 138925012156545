import { Box, Td, Th } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const CustomTh = styled(Th)`
  &:first-child {
    & > div {
      margin: 0px 24px 0px 16px;
    }
  }

  &:nth-child(2) {
    & > div {
      margin: 0px 24px 0px 16px;
      gap: 0px;
    }
  }

  &:nth-child(3) {
    & > div {
      margin: 0px 24px 0px 16px;
      gap: 0px;
    }
  }

  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(7),
  &:nth-child(8) {
    & > div {
      margin: 0px 24px 0px 16px;
      gap: 0px;
    }
  }

  &:nth-child(6) {
    width: 222px;
    padding-left: 16px;
    & > div {
      gap: 0px;
      width: 206px;
    }
  }

  &:nth-child(9) {
    position: sticky;
    background-color: ${colors.Neutral40};
    right: 0px;
    width: 40px;
    height: 40px;
    & > div {
      width: 40px;
      height: 40px;
    }
  }
`;

const CustomTd = styled(Td)`
  &:nth-child(1) {
    p {
      padding-left: 16px;
    }
  }

  &:nth-child(2) {
    div > span {
      padding-left: 16px;
      gap: 0px;
    }
    div > div {
      padding-left: 4px;
    }
  }

  &:nth-child(3) {
    span {
      padding-left: 16px;
      gap: 0px;
    }
  }

  &:nth-child(4) {
    max-height: 60px;
    min-height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div {
      padding: 6px 16px 6px 16px;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;
      text-overflow: ellipsis;
    }
  }

  &:nth-child(5) {
    max-height: 60px;
    min-height: 48px;
    flex-direction: column;
    justify-content: center;
    & > div {
      padding: 6px 16px 6px 16px;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;
      text-overflow: ellipsis;
    }
  }

  &:nth-child(6) {
    width: 222px;
    & > span {
      padding-right: 16px;
      display: flex;
      flex-direction: row-reverse;
      width: 206px;
    }
  }

  &:nth-child(7) span {
    padding-left: 16px;
  }

  &:nth-child(8) span {
    padding: 0px 24px 0px 16px;
    text-transform: initial;
  }

  &:nth-child(9) {
    position: sticky;
    background-color: ${colors.White};
    right: 0px;
    & > div {
      padding-left: 4px;
      position: absolute;
      width: 12px;
      height: 100%;
      top: 0px;
      left: -12px;
      transform: rotate(-180deg);
      background: linear-gradient(91.74deg, rgba(0, 0, 0, 0.2) 1.09%, rgba(0, 0, 0, 0) 98.14%);
    }
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }
`;

const CustomBoxLote = styled(Box)`
  display: flex;
`;

const CustomTextDate = {
  borderRadius: '4px',
  color: `${colors.White}`,
  padding: '2px 4px',
  width: '85px',
};

const SeparatorDebitAccount = {
  margin: '0px 5px',
};

const TitleDebitAccount = {
  margin: '0px',
  textTransform: 'initial',
};

const CustomApplicationDate = {
  margin: '0px',
  textTransform: 'initial',
};

const ColorFontsHeaderTable = {
  color: colors.Grey120,
};

const paginator = {
  borderTop: 'solid 1px rgba(0, 0, 0, 0.24)',
  marginTop: '29px',
  height: '56px',
};

export {
  ColorFontsHeaderTable,
  CustomApplicationDate,
  CustomBoxLote,
  CustomTd,
  CustomTextDate,
  CustomTh,
  paginator,
  SeparatorDebitAccount,
  TitleDebitAccount,
};
