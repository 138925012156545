const Base = {
  width: '100%',
  borderRadius: '4px',
};

const BoxSkeletonLeft = {
  width: '610px',
  padding: '0px 30px',
  height: 'calc(100vh - 185px)',
  gap: '48px',
};

const SkeletonHeader = {
  ...Base,
  width: '140px',
  height: '24px',
};

const SkeletonHeader2 = {
  ...Base,
  width: '159px',
  height: '32px',
};

const SkeletonBlock1 = {
  ...Base,
  width: '261px',
  height: '52px',
};

const SkeletonBlock2 = {
  ...Base,
  height: '60px',
};

const SkeletonBody = {
  ...Base,
  height: '112px',
};

export { BoxSkeletonLeft, SkeletonBlock1, SkeletonBlock2, SkeletonBody, SkeletonHeader, SkeletonHeader2 };
