import { TRANSFER_TYPE_CODE } from '../../../../constants-app';

const texts = {
  transferType: 'Tipo de transferencia: ',
  debitAccount: 'Cuenta de débito',
  creditAccount: 'Cuenta de crédito',
  transferAccount: 'Datos de la transferencia',
  disclaimer: '*Estos datos son obligatorios',
  lotInfo: 'Información del lote',
  description: 'Descripción: ',
  paymentNumber: 'Número de pago: ',
  consolidated: 'Consolidado en extracto: ',
  unifiedShipping: 'Envío unificado: ',
  btnAddTransfer: 'Agregar transferencia',
  btnCancel: 'Cancelar',
  btnUnderstood: 'Entendido',
  btnSave: 'Guardar cambios',
  yes: 'Si',
  no: 'No',
  lotTransfersTitle: 'Transferencias agregadas al lote',
  lotTransfersEmpty: 'Todavía no agregaste transferencias al lote.',
  transfer: 'Transferencia',
  saveSuccess: 'Modificaste la transferencia con éxito.',
  deleteSuccess: 'Eliminaste la transferencia con éxito.',
  serviceErrorMessageTitle: 'La información no está disponible en este momento',
  serviceErrorMessageSubtitle: 'Estamos trabajando para solucionarlo.',
  linkText: 'Reintentar',
  modalCurrencyChangeTitle: '¿Querés transferir en otra moneda?',
  modalSameAccountTitle: 'Las cuentas de débito y crédito tienen que ser distintas',
  modalCurrencyChangeDescription:
    'La moneda de las cuentas de débito y de crédito debe ser la misma, si\ncontinuás borraremos la cuenta de crédito y tendrás que elegir otra.',
  modalSameAccountDescription: 'Elegí otra cuenta para continuar con la confección.',
  modalCurrencyBtnConfirm: 'Sí, cambiar moneda',
  modalRemoveTransferTitle: '¿Querés eliminar la transferencia?',
  modalRemoveTransferDescription: 'Tené en cuenta que esta acción no se puede deshacer y borrarás\ndefinitivamente la transferencia.',
  modalRemoveTransferbtnTitle: 'Sí, eliminar',
};

const ids = {
  toastSaveSuccess: 'toast-save-success',
  toastDeleteSuccess: 'toast-delete-success',
  modalRemoveTransfer: 'modal-remove-transfer',
};

const TestIds = {
  form: 'manual-transfer-form-step2',
  transferAccounts: 'transferAccounts',
  transferData: 'transferData',
  transferSummary: 'transferSummary',
  carousel: 'carousel',
  carouselContent: 'carousel-content',
  carouselController: 'carousel-controller',
  transferItem: 'transferItem',
  transferContainer: 'transferContainer',
  btnAdd: 'btnAdd',
  btnCancel: 'btnCancel',
  btnSave: 'btnSave',
  btnEdit: 'btnEdit',
  btnRemove: 'btnRemove',
  skeletonAccordion: 'skeleton-accordion',
};

const WIZARD_IDS = {
  step2: 'mc-wizard-step2',
  step2Attempts: 'mc-wizard-step2-attempts',
};

const WIZARD_STEPS = [
  {
    title: 'Cuenta de débito y de crédito',
    description: 'En este paso tenés que elegir la cuenta desde donde enviarás el dinero y hacia donde llegará.',
    target: TestIds.transferAccounts,
    placement: 'left',
    offsetExtraX: 12,
    offsetExtraY: 70,
  },
  {
    title: 'Datos de la transferencia',
    description: 'Completá los datos obligatorios y agregá la transferencia al lote para continuar.',
    target: TestIds.transferData,
    placement: 'left',
    offsetExtraX: 12,
    offsetExtraY: 24,
  },
  {
    title: 'Resumen del lote',
    description: 'En esta sección verás un resumen de los datos que cargaste y de las transferencias que agregaste al lote.',
    target: TestIds.transferSummary,
    placement: 'right',
    offsetExtraX: -12,
  },
];

const VALID_CODES = [
  TRANSFER_TYPE_CODE.TPJ,
  TRANSFER_TYPE_CODE.SUE,
  TRANSFER_TYPE_CODE.PPR,
  TRANSFER_TYPE_CODE.CUE,
  TRANSFER_TYPE_CODE.PRO,
  TRANSFER_TYPE_CODE.TER,
];

const TRANSFER_TYPE = {
  [TRANSFER_TYPE_CODE.SUE]: 'Sueldos',
  [TRANSFER_TYPE_CODE.INT]: 'BtoB',
  [TRANSFER_TYPE_CODE.CUE]: 'Cuentas Propias',
  [TRANSFER_TYPE_CODE.PRO]: 'Proveedores',
  [TRANSFER_TYPE_CODE.AFI]: 'AFIP',
  [TRANSFER_TYPE_CODE.PPR]: 'Depositos Judiciales',
  [TRANSFER_TYPE_CODE.PDS]: 'Interpagos',
  [TRANSFER_TYPE_CODE.TER]: 'Pagos Terceros',
  [TRANSFER_TYPE_CODE.FAC]: 'Facturación',
  [TRANSFER_TYPE_CODE.TPJ]: 'Pagos Judiciales',
};

const DRAWER_MAX_ACCOUNTS = 10;

export { DRAWER_MAX_ACCOUNTS, ids, TestIds, texts, TRANSFER_TYPE, VALID_CODES, WIZARD_IDS, WIZARD_STEPS };
