import { Box, MenuList } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const inputText = {
  width: '100%',
};

const CustomBox = styled(Box)`
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  border: solid 1px #bec6dc;
  border-radius: 8px;
  height: 64px;
  padding: 12px 16px;
  width: 100%;

  .contentTextFilter {
    height: 100%;
    min-width: 4%;
    display: flex;
    align-items: center;
  }
  .contentOptionsFilter {
    height: 100%;
    width: 89%;
    display: flex;
    align-items: center;
    margin-left: 8px;
    gap: 8px;
    & > div {
      z-index: 2;
      & > div {
        background-color: ${colors.White};
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 10px 4px, rgba(0, 0, 0, 0.3) 0px 2px 3px;
        border-radius: 10px;
        margin-top: 6px;
      }
    }
  }
  .contentClearFilters {
    height: 100%;
    min-width: 7%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .menuButton {
    background-color: ${colors.White};
    color: ${colors.Grey120};
    border-radius: 8px;
    border-color: ${colors.Neutral90};
    height: 32px;
  }
  .menuFiltered {
    margin-left: 24px;
    background-color: ${colors.Primary60};
    color: ${colors.Primary130};
    border-radius: 8px;
    border-color: ${colors.Transparent};
    height: 32px;
  }
  .contentButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--neutral-90, ${colors.Neutral90});
    padding: 10px;
    height: 32px;
  }
  .contentList {
    display: flex;
    flex-direction: column;
  }
`;

const CustomMenuList = styled(MenuList)`
  box-shadow: ${colors.ShadowLg};
`;

export { CustomBox, CustomMenuList, inputText };
