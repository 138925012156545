import { colors } from '../../styled/generalStyles';

const quantityTabStyle = {
  color: colors.Tertiary100,
  marginLeft: '8px',
};

const textTab = {
  fontSize: '16px',
};

export { quantityTabStyle, textTab };
