import { Flex, Skeleton } from '@chakra-ui/react';

import { testIds } from '../../constants';
import {
  Account1,
  Account2,
  Account3,
  Account4,
  Account5,
  Account6,
  Account7,
  Account8,
  Badge,
  BoxAccount,
  Circle,
  Circle2,
  Description,
  Divider,
  Download,
  Title,
} from './index.styled';

function ResultSkeleton() {
  return (
    <Flex direction='column' margin='12px 0 0' data-testid={testIds.skeleton}>
      <Flex direction='row' alignItems='center' justifyContent='space-between' margin='20px 0'>
        <Skeleton style={Badge} />
        <Flex flex={1} direction='column' alignItems='flex-start'>
          <Skeleton style={Title} />
          <Skeleton style={Description} />
        </Flex>
        <Skeleton style={Download} />
        <Skeleton style={Circle} />
      </Flex>
      <Skeleton style={Divider} />
      <Flex sx={BoxAccount}>
        <Flex direction='row' alignItems='center' width='44%'>
          <Skeleton style={Circle2} />
          <Flex direction='column'>
            <Skeleton style={Account1} />
            <Skeleton style={Account2} />
            <Skeleton style={Account3} />
          </Flex>
        </Flex>
        <Flex direction='column' width='16%'>
          <Skeleton style={Account4} />
          <Skeleton style={Account5} />
        </Flex>
        <Flex direction='column' width='16%'>
          <Skeleton style={Account6} />
        </Flex>
        <Flex direction='column' width='20%'>
          <Skeleton style={Account7} />
          <Skeleton style={Account8} />
        </Flex>
        <Flex direction='column' alignItems='flex-end' width='4%'>
          <Skeleton style={Circle} />
        </Flex>
      </Flex>
      <Skeleton style={Divider} />
    </Flex>
  );
}

export default ResultSkeleton;
