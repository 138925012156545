import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { Edit2, Trash2 } from 'react-feather';

import { formatAmount } from '../../../../../../utils/formatAmount';
import { TestIds, texts } from '../../constants';
import { getAccountData, getAccountName } from './helpers';
import { transferItem, transferItemContainer, transferItemContainerActive } from './index.styled';
import { Props } from './interfaces';

function TransferItem({ transfer, isActive, isDisabled, handleEditTransfer, handleRemoveTransfer }: Props) {
  const { debitAccount, creditAccount, transferData, transferNumber } = transfer;
  const debitAccountName = getAccountName(debitAccount?.bank.bankName, debitAccount?.accountName);
  const debitAccountData = getAccountData(debitAccount?.cuit, debitAccount?.cbu, debitAccount?.accountType);
  const creditAccountName = getAccountName(creditAccount?.bank.bankName, creditAccount?.accountName);
  const creditAccountData = getAccountData(creditAccount?.cuit, creditAccount?.cbu, creditAccount?.accountType);

  return (
    <Box
      sx={{ ...transferItemContainer, ...(isActive ? transferItemContainerActive : {}) }}
      data-testid={`${TestIds.transferItem}-${transferNumber}`}
    >
      <Flex sx={transferItem}>
        <Flex flexDirection='column' width='350px'>
          <Box>
            <Text as='span' textStyle='labelLgBold'>
              {`${texts.transfer} ${transferNumber} | ${format(parseISO(transferData.date), 'dd/MM/yyyy')} | ${formatAmount(
                String(debitAccount?.currency),
                transferData.amount,
                '',
              )}`}
            </Text>
          </Box>
          <Box>
            <Text as='span' textStyle='bodySmSemi'>
              {texts.debitAccount}
            </Text>
          </Box>
          <Box width='350px'>
            <Text as='span' textStyle='bodySm'>
              {debitAccountName}
              <br />
              {debitAccountData.join(' | ')}
            </Text>
          </Box>
          <Box marginTop='4px'>
            <Text as='span' textStyle='bodySmSemi'>
              {texts.creditAccount}
            </Text>
          </Box>
          <Box width='350px'>
            <Text as='span' textStyle='bodySm'>
              {creditAccountName}
              <br />
              {creditAccountData.join(' | ')}
            </Text>
          </Box>
        </Flex>
        <Flex>
          <IconButton
            icon={<Trash2 size={20} />}
            onClick={() => handleRemoveTransfer(transfer)}
            isDisabled={isActive || isDisabled}
            size='i-md'
            variant='icon-text'
            aria-label='button-remove'
            data-testid={`${TestIds.transferItem}-${transferNumber}-${TestIds.btnRemove}`}
            marginLeft='12px'
          />
          <IconButton
            icon={<Edit2 size={20} />}
            onClick={() => handleEditTransfer(transfer)}
            isDisabled={isActive || isDisabled}
            size='i-md'
            variant='icon-text'
            aria-label='button-edit'
            data-testid={`${TestIds.transferItem}-${transferNumber}-${TestIds.btnEdit}`}
            marginLeft='12px'
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default TransferItem;
