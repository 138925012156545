import { useTransferSetupStep4 } from '@hooks/useTransferSetupStep4';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';

import BaseScreen from '../../components/BaseScreen';
import BottomNav from '../../components/BottomNav';
import IconBeware from '../../components/Icons/icon-beware';
import IconCancelModal from '../../components/Icons/icon-cancel-modal';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import CustomModal from '../../components/Modal';
import ViewNoResult from '../../components/ViewNoResult';
import { useTransferSetup } from '../../hooks/useTransferSetup';
import { useTransferSetupStep3 } from '../../hooks/useTransferSetupStep3';
import { BUTTONS_TEXTS, CONFECTION_TEXTS, FOOTER_TEXT, PANEL_NUMBERS, TEXT_MODAL_CHANGE_VIEW, VIEW_TEXT } from './constants';
import DrawerHistoricalErrorList from './Panels/FileSetupTransfers/components/HistoricalErrorDrawer';
import { textsError } from './Panels/ManualSetupTransfers/constants';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import { TEXTS } from './Steps/Step4/constants';

function TransferSetup() {
  const [showBottomNav, setShowBottomNav] = useState<boolean>(true);
  const [isWizardActive, setIsWizardActive] = useState<boolean>(false);
  const {
    isExitConfectionModalOpen,
    onConfirmExitConfection,
    tabIndex,
    step,
    statusButtonDisabled,
    isDrawerOpen,
    transferType,
    confectionPermits,
    statusGetPermission,
    activeViewNotResult,
    draftModal,
    draftModalTxt,
    handleTabsChange,
    setStatusButtonDisabled,
    handleGoBack,
    handleFormSubmit,
    handleOpenDrawer,
    setIsDrawerOpen,
    refetch,
    onCancelExitModalConfection,
    handleDraftModalConfirm,
  } = useTransferSetup();
  const {
    summary,
    isConfectionLoading,
    isConfectionError,
    isAsyncProcess,
    getSummary,
    handleConfection,
    handleCancelConfection,
    handleRetryConfection,
    handleAsyncModalOk,
  } = useTransferSetupStep3();
  const { transfersList, showDiscardModal, getTransfersList, handleCancelDiscard, handleDiscardInvalidDate, handleInvalidDate } =
    useTransferSetupStep4();

  useEffect(() => {
    if (step === 3) {
      getSummary();
    } else if (step === 4) {
      getTransfersList();
    }
  }, [step]);

  const onClickRetry = () => {
    refetch();
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Confección manual - Servicio no disponible',
      content_type: 'No pudimos cargar los datos solicitados',
      item_id: 'Reintentar',
    });
  };

  const isFirstPanel = tabIndex === PANEL_NUMBERS.firstPanel;
  const isSecondPanel = tabIndex === PANEL_NUMBERS.secondPanel;

  let { title } = VIEW_TEXT;
  let textButtonPrimaryText = FOOTER_TEXT.buttonPrimaryText;
  let textButtonPrimaryOutLineText = null;

  if (isFirstPanel) {
    if (step === 3) {
      title = VIEW_TEXT.summaryTitle;
      textButtonPrimaryText = FOOTER_TEXT.buttonPrimaryConfirmText;
    } else if (step === 4) {
      title = VIEW_TEXT.nonEnabledScheduleTitle;
      textButtonPrimaryText = FOOTER_TEXT.buttonNextWorkingDay;
      textButtonPrimaryOutLineText = FOOTER_TEXT.buttonDiscard;
    }
  } else if (isSecondPanel) {
    textButtonPrimaryOutLineText = FOOTER_TEXT.buttonPrimaryOutLineText;
  }

  const handleButtonPrimary = () => {
    if (step === 3) {
      handleConfection();
    } else if (step === 4) {
      handleInvalidDate();
    } else {
      handleFormSubmit();
    }
  };

  const handleButtonPrimaryOutline = () => {
    if (isFirstPanel) {
      if (step === 4) {
        handleDiscardInvalidDate();
      }
    } else {
      handleOpenDrawer();
    }
  };

  if (activeViewNotResult) {
    return (
      <BaseScreen title={VIEW_TEXT.title} sizeTitle='h2Bold' id='titleTef'>
        <ViewNoResult
          title={textsError.title}
          description={textsError.description}
          linkName={textsError.link}
          Icon={IconDisconnected}
          onClickLink={onClickRetry}
        />
      </BaseScreen>
    );
  }

  return (
    <>
      {isConfectionLoading && <LoadingScreen title={CONFECTION_TEXTS.loadingTitle} description={CONFECTION_TEXTS.loadingDescription} />}
      <BaseScreen title={title} sizeTitle='h2Bold' id='titleTef'>
        {step === 1 && (
          <Step1
            tabIndex={tabIndex}
            handleTabsChange={handleTabsChange}
            handleFormSubmit={handleFormSubmit}
            setStatusButtonDisabled={setStatusButtonDisabled}
            confectionPermits={confectionPermits}
            statusPermission={statusGetPermission}
            statusGetPermission={statusGetPermission}
            setShowBottomNav={setShowBottomNav}
          />
        )}
        {step === 2 && isFirstPanel && <Step2 handleFormSubmit={handleFormSubmit} />}
        {step === 3 && isFirstPanel && <Step3 setIsWizardActive={setIsWizardActive} />}
        {step === 4 && isFirstPanel && <Step4 />}
        {isDrawerOpen && <DrawerHistoricalErrorList isOpen={isDrawerOpen} onCancel={setIsDrawerOpen} />}
        {showBottomNav && (
          <BottomNav
            clickButtonPrimaryText={handleButtonPrimary}
            handleGoBack={step > 1 ? handleGoBack : undefined}
            textButtonPrimaryText={textButtonPrimaryText}
            statusButtonPrimaryText={statusButtonDisabled}
            textButtonPrimaryOutLineText={textButtonPrimaryOutLineText}
            textButtonGoBack={step > 1 ? FOOTER_TEXT.buttonGoBackText : null}
            showTextTransferType={step > 1}
            textTransferType={transferType ? transferType?.description : ''}
            collapsableTransferType={step === 3 && isFirstPanel}
            customSummary={step === 3 && isFirstPanel ? summary.totals : undefined}
            showSteps={step !== 4}
            currentStep={step}
            handleFourthButton={handleButtonPrimaryOutline}
            size='md'
            isWizardActive={isWizardActive}
          />
        )}
        <CustomModal
          isOpen={!!draftModal}
          Icon={IconBeware}
          onCancel={() => handleDraftModalConfirm(false)}
          firstDescription={draftModalTxt.description}
          title={draftModalTxt.title}
          enableFirstButton
          firstTextButton={BUTTONS_TEXTS.cancel}
          enableSecondButton
          secondTextButton={BUTTONS_TEXTS.continue}
          actionButton2={() => handleDraftModalConfirm(true)}
          alternativeButtonStyling
          size='xl'
          newStyle
        />
        <CustomModal
          isOpen={isConfectionError}
          Icon={IconCancelModal}
          onCancel={handleCancelConfection}
          firstDescription={CONFECTION_TEXTS.errorDescription}
          title={CONFECTION_TEXTS.errorTitle}
          enableFirstButton
          firstTextButton={BUTTONS_TEXTS.cancel}
          enableSecondButton
          secondTextButton={BUTTONS_TEXTS.retry}
          actionButton2={handleRetryConfection}
          alternativeButtonStyling
          size='xl'
          newStyle
        />
        <CustomModal
          isOpen={isAsyncProcess}
          Icon={IconInfoPopup}
          onCancel={handleAsyncModalOk}
          firstDescription={CONFECTION_TEXTS.asyncDescription}
          title={CONFECTION_TEXTS.asyncTitle}
          enableFirstButton
          firstTextButton={BUTTONS_TEXTS.ok}
          size='xl'
          newStyle
        />
        <CustomModal
          isOpen={isExitConfectionModalOpen}
          Icon={IconBeware}
          onCancel={onCancelExitModalConfection}
          firstDescription={TEXT_MODAL_CHANGE_VIEW.description}
          title={TEXT_MODAL_CHANGE_VIEW.title}
          enableFirstButton
          firstTextButton={TEXT_MODAL_CHANGE_VIEW.buttonCancel}
          enableSecondButton
          secondTextButton={TEXT_MODAL_CHANGE_VIEW.buttonOut}
          actionButton2={onConfirmExitConfection}
          alternativeButtonStyling
          size='xl'
          newStyle
        />
        <CustomModal
          isOpen={showDiscardModal}
          Icon={IconBeware}
          onCancel={handleCancelDiscard}
          title={TEXTS.modalDiscardTitle(transfersList.length)}
          firstDescription={TEXTS.modalDiscardDescription(transfersList.length)}
          enableFirstButton
          firstTextButton={TEXTS.buttonCancel}
          enableSecondButton
          secondTextButton={TEXTS.buttonDiscardConfirm}
          actionButton2={() => handleDiscardInvalidDate(true)}
          alternativeButtonStyling
          size='xl'
          newStyle
        />
      </BaseScreen>
    </>
  );
}

export default TransferSetup;
