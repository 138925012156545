import { colors } from '../../../../../../styled/generalStyles';

const AccordionBody = {
  display: 'flex',
  padding: '0 12px 0 36px',
  alignItems: 'center',
  gap: '62px',
  alignSelf: 'stretch',
};

const AccordionBodyContent = {
  display: 'flex',
  padding: '12px 0',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
};

const AccordionBodyLotDetailFrameOne = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
};

const AccordionBodyLotDetailFrameTwo = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
};

const AccordionContainer = {
  '>div:first-of-type, >div:first-of-type>*, >div:first-of-type>*[aria-expanded=true], >div:first-of-type:hover>button:not(disabled)': {
    borderRadius: '8px 8px 0 0',
  },

  '>div:last-of-type, >div:last-of-type>*, >div:last-of-type>*[aria-expanded=true], >div:last-of-type>*[data-expanded]': {
    borderRadius: '0',
  },

  '>div:last-of-type:hover>button:not(disabled)': {
    borderRadius: '0',
  },

  '>div>*, >div>*[aria-expanded=true]': {
    animation: 'none',
  },

  '>div': {
    marginTop: '0',
  },

  '>div:hover>button[aria-expanded=true]': {
    background: colors.Primary60,
  },

  borderRadius: '8px 8px 0 0',
};

const AccordionHeader = {
  boxShadow: 'none',
  padding: '8px 16px',

  '&:focus': {
    boxShadow: 'none',
  },
  '&:hover': {
    backgroundColor: 'inherit', // Mantener el color actual en hover
  },
  '&[aria-expanded=false]:hover, &[aria-expanded=false]:focus, &:focus:hover': {
    background: colors.White,
  },
  '&[aria-expanded=true]:hover': {
    background: colors.Primary60,
  },
  '&[aria-expanded=true], &[aria-expanded=true]:focus, &[aria-expanded=true]:hover': {
    background: colors.Primary60,
    borderBottom: `1px solid ${colors.Grey60}`,
  },
  '&[aria-expanded="true"]': {
    backgroundColor: colors.Primary60,
  },
  '&[aria-expanded="false"]': {
    backgroundColor: colors.White,
  },
};

const AccordionItemTranfers = {
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${colors.Grey60}`,
  },
};

const AccordionTransfers = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0',
};

const AccordionTransfersContent = {
  padding: '12px',
};

const ChevronContainer = {
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
};

const Chevron = {
  color: colors.Primary100,
  width: '20px',
  height: '20px',
};

const ConsolidatedText = {
  margin: '0 12px 0 0',
  paddingRight: '16px',
  borderRight: `1px solid ${colors.Secondary60}`,
};

export {
  AccordionBody,
  AccordionBodyContent,
  AccordionBodyLotDetailFrameOne,
  AccordionBodyLotDetailFrameTwo,
  AccordionContainer,
  AccordionHeader,
  AccordionItemTranfers,
  AccordionTransfers,
  AccordionTransfersContent,
  Chevron,
  ChevronContainer,
  ConsolidatedText,
};
