import { useRef } from 'react';

const useFirstRender = () => {
  const isFirstRender = useRef(true);

  const skipOnFirstRender = (callback: () => void) => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    callback();
  };

  return skipOnFirstRender;
};

export default useFirstRender;
