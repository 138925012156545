import { Flex, Skeleton } from '@chakra-ui/react';

import { testIds } from '../../constants';
import {
  Account1,
  Account2,
  Account3,
  Account4,
  Account5,
  Account6,
  Account7,
  Account8,
  BoxAccount,
  Circle1,
  Circle2,
  Divider,
  Info1,
  Info2,
  Info3,
  Info4,
  Title,
} from './index.styled';

function SummarySkeleton() {
  return (
    <Flex direction='column' data-testid={testIds.skeleton}>
      <Flex direction='row' margin='12px 0' alignItems='center'>
        <Skeleton style={Circle1} />
        <Skeleton style={Info1} />
        <Skeleton style={Circle1} />
        <Skeleton style={Info2} />
        <Skeleton style={Circle1} />
        <Skeleton style={Info3} />
        <Skeleton style={Circle1} />
        <Skeleton style={Info4} />
      </Flex>
      <Skeleton style={Title} />
      <Flex sx={BoxAccount}>
        <Flex direction='row' alignItems='center' width='42%'>
          <Skeleton style={Circle2} />
          <Flex direction='column'>
            <Skeleton style={Account1} />
            <Skeleton style={Account2} />
            <Skeleton style={Account3} />
          </Flex>
        </Flex>
        <Flex direction='column' width='20%'>
          <Skeleton style={Account4} />
          <Skeleton style={Account5} />
        </Flex>
        <Flex direction='column' width='20%'>
          <Skeleton style={Account6} />
        </Flex>
        <Flex direction='column' width='18%'>
          <Skeleton style={Account7} />
          <Skeleton style={Account8} />
        </Flex>
      </Flex>
      <Skeleton style={Divider} />
    </Flex>
  );
}

export default SummarySkeleton;
