/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { Box, Flex, Text } from '@chakra-ui/react';
import CardAccount from '@components/CardAccount';
import { CardAccountMode } from '@components/CardAccount/constants';
import CardAccountNoResult from '@components/CardAccountNoResult';
import CardAccountSkeleton from '@components/CardAccountSkeleton';
import { texts } from '@components/FilterAccounts/constants';
import FilterAccountsDrawer from '@components/FilterAccountsDrawer';
import IconBinoculars from '@components/Icons/icon-binoculars';
import IconDisconnected from '@components/Icons/icon-disconnected';
import SearchAccounts from '@components/SearchAccounts';
import ViewNoResult from '@components/ViewNoResult';
import { AccountingType } from '@interfaces/transfers';
import { AlertComponent, AlertDescription } from '@wow/alert';
import { Drawer, DrawerBody, DrawerHeader } from '@wow/drawer';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import CardAccountError from '../CardAccountError';
import { IDS, TEXTS, TEXTS_EMPTY, TEXTS_ERROR } from './constants';
import styles from './index.styled';
import { Props } from './interfaces';

function AccountsDrawer({
  isOpen,
  page,
  showFilters = false,
  showAlertCurrency = false,
  isLoading,
  isError,
  transferType,
  accountingType,
  accounts,
  accountSelectedId,
  searchValue,
  hasMore,
  usageReferenceList,
  isAnyFilterAppliedDrawer,
  filtersApplied,
  banksList,
  setFiltersApplied,
  loadMore,
  handleSelectAccount,
  setSearchValue,
  handleSearch,
  onClickRetry,
  onClickModify,
  onClose,
}: Props) {
  const id = `accountsDrawer-${accountingType}`;

  const [isFiltersUpdate, setIsFiltersUpdate] = useState(false);
  const [searchValueLocal, setSearchValueLocal] = useState<string>('');

  let isFiltersActive = showFilters && !isLoading;

  const onApplySearch = (value: string) => {
    setIsFiltersUpdate(true);
    setSearchValueLocal(value);
  };

  const onApplyFilters = () => {
    setIsFiltersUpdate(true);
    handleSearch();
  };

  const onSearch = (enabled = true) => {
    setSearchValue(enabled ? searchValueLocal : '');
    handleSearch();
  };

  if (isFiltersUpdate || searchValue || isAnyFilterAppliedDrawer) {
    isFiltersActive = true;
  }

  useEffect(() => {
    if (!isOpen) {
      setSearchValueLocal(searchValue);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading) {
      setIsFiltersUpdate(false);
    }
  }, [isLoading]);

  useEffect(() => {
    setSearchValueLocal(searchValue);
  }, [searchValue]);

  return (
    <Drawer id={id} trapFocus={false} isOpen={isOpen} onClose={onClose} variant='movil' size='lg'>
      <DrawerHeader>
        <Text as='div' textStyle='h4Bold' marginTop='40px' sx={styles.headerTitle} data-testid={IDS.drawerTitle}>
          {TEXTS.title[accountingType]}
        </Text>
        <Text as='div' textStyle='bodySm' margin='4px 0'>
          {TEXTS.subtitle}
        </Text>
      </DrawerHeader>
      <DrawerBody>
        {accountingType === AccountingType.credit &&
        !isError &&
        !isLoading &&
        !accounts.length &&
        !searchValue &&
        !isAnyFilterAppliedDrawer ? (
          <Flex alignItems='center' justifyContent='center' height='100%'>
            <ViewNoResult
              title={TEXTS_EMPTY.title}
              description={TEXTS_EMPTY.description}
              linkName={TEXTS_EMPTY.link}
              Icon={IconBinoculars}
              onClickLink={onClickModify}
              isConfectionDrawer
            />
          </Flex>
        ) : isError && !isLoading && !accounts.length ? (
          <Flex alignItems='center' justifyContent='center' height='100%'>
            <ViewNoResult
              title={TEXTS_ERROR.title}
              description={TEXTS_ERROR.description}
              linkName={TEXTS_ERROR.link}
              Icon={IconDisconnected}
              onClickLink={onClickRetry}
              isConfectionDrawer
            />
          </Flex>
        ) : (
          <Box>
            {showAlertCurrency && (
              <AlertComponent id='alert-currency' variant='information' minWidth='100px' marginBottom='16px'>
                <AlertDescription>{TEXTS.alertCurrency}</AlertDescription>
              </AlertComponent>
            )}
            {isFiltersActive && (
              <Box>
                <SearchAccounts
                  accountingType={accountingType}
                  transferType={transferType}
                  searchValue={searchValueLocal}
                  isLoading={isLoading}
                  setSearchValue={onApplySearch}
                  handleSearch={onSearch}
                />
                <FilterAccountsDrawer
                  isLoading={isLoading}
                  accountingType={accountingType}
                  usageReferenceList={usageReferenceList}
                  banksList={banksList}
                  filterOptionsApplied={filtersApplied}
                  onApplyFilters={onApplyFilters}
                  setFilterOptionsApplied={setFiltersApplied}
                  isEmptyResult={!accounts.length}
                />
              </Box>
            )}
            {isFiltersActive && (!!searchValue || isAnyFilterAppliedDrawer) && !isLoading && !!accounts.length && (
              <Flex flexDirection='column' marginBottom='12px' marginTop='16px'>
                <Text as='span' textStyle='titleSmBold'>
                  {texts.filterResultTitle(accounts.length)}
                </Text>
                {accounts.length > 45 && (
                  <Text as='span' textStyle='labelSm'>
                    {texts.filterResultDescription}
                  </Text>
                )}
              </Flex>
            )}
            {isLoading && !accounts.length ? (
              <CardAccountSkeleton />
            ) : (
              <InfiniteScroll
                scrollableTarget={`chakra-modal--body-${id}`}
                dataLength={accounts.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<CardAccountSkeleton />}
                style={styles.container}
              >
                {!accounts.length && !isLoading && !isError && (!!searchValue || isAnyFilterAppliedDrawer) ? (
                  <CardAccountNoResult />
                ) : (
                  accounts.map(item => (
                    <CardAccount
                      key={`${item.accountId}`}
                      selected={accountSelectedId === item.accountId}
                      account={item}
                      accountingType={accountingType}
                      transferType={transferType}
                      handleSelectAccount={handleSelectAccount}
                      mode={CardAccountMode.drawerList}
                      autoWidth
                    />
                  ))
                )}
                {isLoading && page > 1 && <CardAccountSkeleton />}
                {isError && <CardAccountError onClickRetry={onClickRetry} />}
              </InfiniteScroll>
            )}
          </Box>
        )}
      </DrawerBody>
    </Drawer>
  );
}

export default AccountsDrawer;
