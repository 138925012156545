/* eslint-disable no-nested-ternary */
import { Flex, Text } from '@chakra-ui/react';
import IconDisconnected from '@components/Icons/icon-disconnected';
import ViewNoResult from '@components/ViewNoResult';
import { useAsyncProcesses } from '@hooks/useAsyncProcesses';
import { AsyncProcess } from '@interfaces/api';
import { routesNames } from '@src/constants-app';
import { Drawer, DrawerBody, DrawerHeader } from '@wow/drawer';
import { useLocation, useNavigate } from 'react-router-dom';

import ProcessesCard from '../ProcessesCard';
import ProcessesCardSkeleton from '../ProcessesCardSkeleton';
import { TEST_IDS, TEXTS, TEXTS_ERROR } from './constants';
import styles from './index.styled';
import { Props } from './interfaces';

function ProcessesDrawer({ isOpen, onClose }: Props) {
  const { asyncProcesses, asyncProcessesLoading, asyncProcessesError, getProcesses } = useAsyncProcesses(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onClickRetry = () => {
    getProcesses();
  };

  const onClickView = (process: AsyncProcess) => {
    onClose();
    navigate(process.scheduleNotEnabledError ? routesNames.ConfectionInvalidDate : routesNames.ConfectionResult, {
      state: { from: location, processId: process.number },
    });
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} variant='movil' size='lg'>
      <DrawerHeader>
        <Text as='div' textStyle='h4Bold' marginTop='40px' sx={styles.headerTitle} data-testid={TEST_IDS.processesDrawerTitle}>
          {TEXTS.title}
        </Text>
        <Text as='div' textStyle='bodySm' margin='4px 0'>
          {TEXTS.subtitle}
        </Text>
      </DrawerHeader>
      <DrawerBody>
        {asyncProcessesError ? (
          <Flex alignItems='center' justifyContent='center' height='100%'>
            <ViewNoResult
              title={TEXTS_ERROR.title}
              description={TEXTS_ERROR.description}
              linkName={TEXTS_ERROR.link}
              Icon={IconDisconnected}
              onClickLink={onClickRetry}
            />
          </Flex>
        ) : asyncProcessesLoading ? (
          <ProcessesCardSkeleton />
        ) : (
          asyncProcesses?.processes.map(item => <ProcessesCard key={`${item.number}`} process={item} onClickView={onClickView} />)
        )}
      </DrawerBody>
    </Drawer>
  );
}

export default ProcessesDrawer;
