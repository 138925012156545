import { colors } from '../../styled/generalStyles';

const LotsInfoBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
  marginBottom: '5px',
  height: '20px',
};

const LotsInfoBoxStylesSummary = {
  alignItems: 'center',
  display: 'flex',
  marginTop: '4px',
  marginBottom: '5px',
  paddingLeft: '12px',
};

const BoxStyles = {
  display: 'flex',
  alignItems: 'center',
  margin: '4px',
  width: '18px',
};
const AlertStyles = {
  alignItems: 'center',
  color: colors.Warning100,
  display: 'flex',
  margin: '4.5px',
  marginRight: '0px',
  width: '17px',
};

const BoldStyles = {
  marginRight: '12px',
};

const NormalStyles = {
  marginRight: '12px',
};

const Separator = {
  margin: '0 16px 2px 16px',
  color: colors.Grey120,
};

export { AlertStyles, BoldStyles, BoxStyles, LotsInfoBoxStyles, LotsInfoBoxStylesSummary, NormalStyles, Separator };
