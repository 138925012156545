const TEXTS = {
  title: 'Transferencias en proceso',
  subtitle: 'Conocé el resultado de tus procesos activos. Al final del día, esta información no estará disponible. ',
};

const TEXTS_ERROR = {
  title: 'La información no está disponible en este momento',
  description: 'Estamos trabajando para solucionarlo.',
  link: 'Reintentar',
};

const TEST_IDS = {
  processesDrawerTitle: 'processesDrawerTitle',
};

export { TEST_IDS, TEXTS, TEXTS_ERROR };
