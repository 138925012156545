import { Box, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

import { colors } from '../../styled/generalStyles';
import { CustomMarginText } from '../../styled/global.styled';
import { Content, TextTitle } from './styled';

interface Props {
  title: string;
  description: string;
}

function Loader({ title, description }: Props) {
  return (
    <Box sx={Content}>
      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color={colors.Primary90} size='xl' />
      <Text sx={TextTitle} textStyle='h4Bold'>
        {title}
      </Text>
      <Text sx={CustomMarginText} textStyle='bodyLg'>
        {description}
      </Text>
    </Box>
  );
}

export default Loader;
