import { SystemStyleObject } from '@chakra-ui/react';

const breadcrumb: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  height: '34px',

  '& li': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    span: {
      display: 'block',
      fontSize: '0',
    },
  },
};

const transfersDisabled: SystemStyleObject = {
  '& li:first-of-type + li': {
    opacity: '0.4',

    'a, a:hover, a:focus': {
      textDecoration: 'none',
      cursor: 'default',
    },
  },
};

export { breadcrumb, transfersDisabled };
