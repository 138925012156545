import { Box, Button, Progress, Text } from '@chakra-ui/react';
import { useAsyncProcesses } from '@hooks/useAsyncProcesses';
import { useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import StickyBox from 'react-sticky-box';

import { TEST_IDS } from './constants';
import styles from './index.styled';
import { AsyncProcessesMode, Props } from './interfaces';

function Processes({ openDrawer }: Props) {
  const {
    location,
    isVisible,
    offsetTop,
    asyncProcessesLoading,
    asyncProcessesError,
    statusesCount,
    mode,
    view,
    updateAsyncProcesses,
    handleScroll,
    getProcesses,
  } = useAsyncProcesses();

  useEffect(() => {
    if (updateAsyncProcesses) {
      getProcesses();
    }
  }, [updateAsyncProcesses]);

  useEffect(() => {
    if (isVisible) {
      getProcesses();
    }
  }, [location.pathname, isVisible]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!isVisible || asyncProcessesLoading || asyncProcessesError || !statusesCount.total) {
    return null;
  }

  return (
    <StickyBox offsetTop={offsetTop} style={styles.stickyBox}>
      <Box sx={styles.container} backgroundColor={view.backgroundColor} data-testid={TEST_IDS.processes}>
        {mode === AsyncProcessesMode.inProgress && (
          <>
            <Box sx={{ ...styles.progressMask, background: `linear-gradient(to right, ${view.backgroundColor}, transparent)` }} />
            <Box sx={styles.progress}>
              <Progress isIndeterminate />
            </Box>
          </>
        )}
        {view.icon}
        <Box margin='0 12px 0 6px'>
          <Text as='span' textStyle='bodyMd'>
            {view.text}
          </Text>
        </Box>
        <Button
          variant='primary-text'
          size='lg'
          onClick={openDrawer}
          rightIcon={<ChevronRight width='24px' color={view.linkColor} />}
          sx={{
            ...styles.linkButton,
            color: view.linkColor,
            '&:hover, &:focus': { color: view.linkHoverColor, svg: { stroke: view.linkHoverColor } },
          }}
          data-testid={TEST_IDS.btnViewResult}
        >
          {view.linkText}
        </Button>
      </Box>
    </StickyBox>
  );
}

export default Processes;
