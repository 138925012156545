import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';

import { EXCEPTION_CODES, NUMBER_FAILED_ATTEMPTS, TEXT_ENTITY_TOKEN, TEXT_ERROR_ENTITY_TOKEN } from '../views/EntityToken/constants';

interface UseHandleMessageResponseProps {
  failedAttempts: string;
  start: () => void;
}

interface HandleMessageResponseResult {
  handleResponse: () => void;
  title: string;
  descriptionOne: string;
  descriptionTwo: string;
  descriptionFour: string;
  iconError: boolean;
  password: string;
  statusButton: boolean;
  modalBlockedUser: boolean;
  modalInvalidOtpLastAttempt: boolean;
  modalBlockeUser: boolean;
  messageResponse: string;
  setMessageResponse: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setModalInvalidOtpLastAttempt: React.Dispatch<React.SetStateAction<boolean>>;
  setModalBlockedUser: React.Dispatch<React.SetStateAction<boolean>>;
  setModalBlockeUser: React.Dispatch<React.SetStateAction<boolean>>;
  setStatusButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const useTextEntityToken = ({ failedAttempts, start }: UseHandleMessageResponseProps): HandleMessageResponseResult => {
  const [title, setTitle] = useState<string>(TEXT_ENTITY_TOKEN.title);
  const [descriptionOne, setDescriptionOne] = useState<string>(TEXT_ENTITY_TOKEN.descriptionOne);
  const [descriptionTwo, setDescriptionTwo] = useState<string>(TEXT_ENTITY_TOKEN.descriptionTwo);
  const [descriptionFour, setDescriptionFour] = useState<string>('');
  const [messageResponse, setMessageResponse] = useState<string>('');
  const [iconError, setIconError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [statusButton, setStatusButton] = useState<boolean>(true);
  const [modalBlockedUser, setModalBlockedUser] = useState<boolean>(false);
  const [modalInvalidOtpLastAttempt, setModalInvalidOtpLastAttempt] = useState<boolean>(false);
  const [modalBlockeUser, setModalBlockeUser] = useState<boolean>(false);

  const handleResponse = (): void => {
    if (messageResponse === EXCEPTION_CODES.invalidOtp) {
      pushAnalyticsEvent({
        event: 'modal_impression',
        modal_name: 'Token incorrecto',
      });
      setTitle(TEXT_ERROR_ENTITY_TOKEN.title);
      setDescriptionOne(TEXT_ERROR_ENTITY_TOKEN.descriptionOne);
      setDescriptionTwo('');
      setDescriptionFour(TEXT_ERROR_ENTITY_TOKEN.descriptionTwo);
      setIconError(true);
      setPassword('');
      setStatusButton(true);
      setMessageResponse('');
    }
    if (failedAttempts === NUMBER_FAILED_ATTEMPTS) {
      pushAnalyticsEvent({
        event: 'modal_impression',
        modal_name: 'Advertencia inhabilitación de usuario',
      });
      setModalInvalidOtpLastAttempt(true);
      setStatusButton(true);
      setMessageResponse('');
      setPassword('');
    }
    if (messageResponse === EXCEPTION_CODES.blockedPerson) {
      pushAnalyticsEvent({
        event: 'user_disabled',
        content_group: 'Transferencias - Autorizar - Ingreso Token',
      });
      setModalBlockedUser(true);
      setMessageResponse('');
      start();
      setStatusButton(true);
      setMessageResponse('');
      setPassword('');
    }
    if (messageResponse === EXCEPTION_CODES.personBlockedAttempts) {
      pushAnalyticsEvent({
        event: 'user_disabled',
        content_group: 'Transferencias - Autorizar - Ingreso Token',
      });
      setModalBlockeUser(true);
      start();
      setStatusButton(true);
      setMessageResponse('');
      setPassword('');
    }
  };

  useEffect(() => {
    handleResponse();
  }, [messageResponse]);

  return {
    handleResponse,
    title,
    descriptionOne,
    descriptionTwo,
    descriptionFour,
    iconError,
    password,
    statusButton,
    modalBlockedUser,
    modalInvalidOtpLastAttempt,
    modalBlockeUser,
    setPassword,
    setModalInvalidOtpLastAttempt,
    setModalBlockedUser,
    setModalBlockeUser,
    setStatusButton,
    messageResponse,
    setMessageResponse,
  };
};

export default useTextEntityToken;
