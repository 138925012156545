const AMOUNT_TEST_ID = 'amount-input';
const AMOUNT_LABEL = 'Importe';
const OBSERVATION_LABEL = 'Observación';
const OBSERVATION_TEST_ID = 'observation-input';
const PLACEHOLDER_INPUT = '0,00';
const MAX_LENGTH_OBSERVATION = 60;
const REQUEST_DATE_LABEL = 'Fecha de solicitud';
const DOLLAR_SYMBOL = 'u$s';
const PESOS_SYMBOL = '$';
const VOUCHER_TEST_ID = 'voucher-input';
const VOUCHER_LABEL = 'Comprobante';
const MAX_LENGTH_VOUCHER = 12;
const REASON_TEST_ID = 'reason-input';
const REASON_LABEL = 'Motivo';
const CONCEPT_TEST_ID = 'concept-input';
const CONCEPT_LABEL = 'Concepto';
const PLACEHOLDER_CONCEPT = 'Elegí el concepto';
const TRANSFER_DATA_FORM_TEST_ID = 'transfer-data-form';
const DISCLAIMER_CHECK_TEST_ID = 'disclaimer-checkbox';

export {
  AMOUNT_LABEL,
  AMOUNT_TEST_ID,
  CONCEPT_LABEL,
  CONCEPT_TEST_ID,
  DISCLAIMER_CHECK_TEST_ID,
  DOLLAR_SYMBOL,
  MAX_LENGTH_OBSERVATION,
  MAX_LENGTH_VOUCHER,
  OBSERVATION_LABEL,
  OBSERVATION_TEST_ID,
  PESOS_SYMBOL,
  PLACEHOLDER_CONCEPT,
  PLACEHOLDER_INPUT,
  REASON_LABEL,
  REASON_TEST_ID,
  REQUEST_DATE_LABEL,
  TRANSFER_DATA_FORM_TEST_ID,
  VOUCHER_LABEL,
  VOUCHER_TEST_ID,
};
