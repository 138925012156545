import * as React from 'react';

function IconInfoPopup() {
  return (
    <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M30.6472 67.45H10.4502V4.75H65.5502V67.45H45.0606L37.9888 74.6567L30.6472 67.45Z' fill='#C8F1FE' />
      <path
        d='M64.6 5.69999V66.5H44.6614L44.1028 67.07L37.9753 73.3134L31.5913 67.0453L31.0365 66.5H11.4V5.69999H64.6ZM66.5 3.79999H9.5V68.4H30.2594L38 76L45.4594 68.4H66.5V3.79999Z'
        fill='#0381A8'
      />
      <path
        d='M38.0002 17.1C36.9924 17.1 36.0258 17.5004 35.3132 18.213C34.6006 18.9256 34.2002 19.8922 34.2002 20.9C34.2002 21.9078 34.6006 22.8744 35.3132 23.587C36.0258 24.2996 36.9924 24.7 38.0002 24.7C39.008 24.7 39.9746 24.2996 40.6872 23.587C41.3998 22.8744 41.8002 21.9078 41.8002 20.9C41.8002 19.8922 41.3998 18.9256 40.6872 18.213C39.9746 17.5004 39.008 17.1 38.0002 17.1Z'
        fill='#049BCB'
      />
      <path d='M41.7998 51.3V28.5H32.2998V30.4H34.1998V51.3H32.2998V53.2H43.6998V51.3H41.7998Z' fill='#049BCB' />
    </svg>
  );
}
export default IconInfoPopup;
