/* eslint-disable react/jsx-props-no-spreading */
import { Currencies } from '@interfaces/transfers';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { EXCEPTION_CODES } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { Bank, BankAccount } from '../Interfaces/api';
import { useTransferDebitProps } from '../Interfaces/hooks/IUseTransferDebit';
import { SelectOption } from '../Interfaces/transferSetup';
import { getDebitAccounts, getDebitBanks } from '../services/api';

export function useTransferDebit({
  transferSelected,
  creditAccountSelected,
  codeTransferType = '',
  currencySelected,
  setTransferSelected,
  changeCreditBanksCurrency,
  setCreditCurrencySelected,
  setCreditBanksCurrencyARS,
  setCreditBanksCurrencyUSD,
  resetCreditAccountFilters,
  setCreditAccountSelected,
  setCreditAccountSelectedId,
  setDebitAccountSelectedRef,
  initialDebitCurrency,
  isDrawer = false,
  discardChange,
  creditAccountNumberSelected,
}: useTransferDebitProps) {
  const { manualTransferData, debitAccounts, setDebitAccounts } = useTransfersContext();

  const [carouselStep, setCarouselStep] = useState(1);
  const [debitAccountsLoading, setDebitAccountsLoading] = useState(true);
  const [debitAccountsError, setDebitAccountsError] = useState(false);
  const [debitAccountsFiltered, setDebitAccountsFiltered] = useState<BankAccount[]>([]);
  const [debitAccountsFilteredLoading, setDebitAccountsFilteredLoading] = useState(false);
  const [debitAccountsFilteredError, setDebitAccountsFilteredError] = useState(false);
  const [debitAccountSelectedId, setDebitAccountSelectedId] = useState<string | null>(null);
  const [debitAccountSelected, setDebitAccountSelected] = useState<BankAccount | null>(null);
  const [debitAccountChanged, setDebitAccountChanged] = useState<string | null>(null);
  const [debitUsageReferences, setDebitUsageReferences] = useState<string[]>([]);
  const [debitUsageReferenceSelected, setDebitUsageReferenceSelected] = useState<SelectOption | null>(null);
  const [debitBanks, setDebitBanks] = useState<Bank[]>([]);
  const [debitBanksLoading, setDebitBanksLoading] = useState(true);
  const [debitBanksError, setDebitBanksError] = useState(false);
  const [debitBankSelected, setDebitBankSelected] = useState<SelectOption | null>(null);
  const [debitBanksCurrencyARS, setDebitBanksCurrencyARS] = useState(false);
  const [debitBanksCurrencyUSD, setDebitBanksCurrencyUSD] = useState(false);
  const [debitBanksBothCurrency, setDebitBanksBothCurrency] = useState(false);
  const [disableARSTooltip, setDisableARSTooltip] = useState(false);
  const [disableUSDTooltip, setDisableUSDTooltip] = useState(false);
  const [debitCurrencySelected, setDebitCurrencySelected] = useState<string>(Currencies.ARS);
  const [debitCurrencyChanged, setDebitCurrencyChanged] = useState<string | null>(null);
  const [debitFilterTypeSelected, setDebitFilterTypeSelected] = useState<SelectOption | null>(null);
  const [debitFilterData, setDebitFilterData] = useState<string>('');
  const [debitBalanceError, setDebitBalanceError] = useState(false);
  const [statusGetDebitBanks, setStatusGetDebitBanks] = useState(false);
  const [statusGetDebitAccounts, setStatusGetDebitAccounts] = useState(false);
  const [sameAccountSelectedDebit, setSameAccountSelectedDebit] = useState(false);
  const [shouldShowFilterAccounts] = useState(false);
  const [lastSelectedCurrency, setLastSelectedCurrency] = useState('');
  const [isFirstSelection, setIsFirstSelection] = useState(true);
  const [changedCurrency, setChangedCurrency] = useState<boolean>(false);

  const boxScroll = useRef<HTMLDivElement>(null);

  const debitAccountsCardWidth = 470;
  const debitAccountsMax = 5;
  const debitAccountsLength = debitAccounts.length;
  const debitAccountsFilterActive = debitAccountsLength > debitAccountsMax || isDrawer;
  const carouselHeight = debitAccountSelectedId && !debitBalanceError ? 174 : 124;
  const carouselContentHeight = debitAccountSelectedId && !debitBalanceError ? 190 : 148;

  const handleCarouselScroll = () => {
    if (boxScroll.current && debitAccountsLength > 1) {
      const { scrollLeft } = boxScroll.current;
      let step = 1;
      if (debitAccountsLength === 2) {
        const cardSpace = debitAccountsCardWidth - 50;
        if (scrollLeft > cardSpace && scrollLeft <= cardSpace * 2) {
          step = 2;
        }
      } else {
        for (let i = 1; i <= 30; i += 1) {
          if (scrollLeft > debitAccountsCardWidth * i && scrollLeft <= debitAccountsCardWidth * (i + 1)) {
            step = i + 1;
          }
        }
      }
      setCarouselStep(step);
    }
  };

  const onClickCarouselStep = (step: number) => {
    if (boxScroll.current && boxScroll.current.scrollTo) {
      boxScroll.current.scrollTo({
        top: 0,
        left: step === 1 ? 0 : (debitAccountsCardWidth + step * 10) * (step - 1),
        behavior: 'smooth',
      });
    }
  };

  const handleSelectDebitAccount = (value: string | null, confirm = false) => {
    setDebitBalanceError(false);

    if (!value) {
      setDebitAccountSelectedId(null);
      setDebitAccountSelected(null);
      return;
    }

    const findIndex = debitAccounts.findIndex(item => item.accountId === value);
    const debitAccount = debitAccounts[findIndex];

    if (isFirstSelection) {
      if (initialDebitCurrency && debitAccount.currency !== initialDebitCurrency) {
        setDebitAccountChanged(value);
        setLastSelectedCurrency(debitAccount.currency);
        setIsFirstSelection(false);
        setDebitCurrencyChanged(debitAccount.currency);
        return;
      }
    } else if (debitAccount.currency !== lastSelectedCurrency) {
      setDebitAccountChanged(value);
      setLastSelectedCurrency(debitAccount.currency);
      setDebitCurrencyChanged(debitAccount.currency);
      return;
    }

    setDebitAccountSelectedId(value);
    setDebitAccountSelected(debitAccount);

    if (debitAccountChanged && confirm) {
      if (transferSelected?.creditAccount) {
        setTransferSelected({ ...transferSelected, creditAccount: null });
      }
      resetCreditAccountFilters();
      setCreditAccountSelectedId(null);
      setCreditAccountSelected(null);
      setDebitAccountChanged(null);
    }
  };

  const handleRemoveSelectedDebitAccount = () => {
    if (transferSelected?.debitAccount) {
      setTransferSelected({ ...transferSelected, debitAccount: null });
    }
    setDebitBalanceError(false);
    setDebitAccountSelectedId(null);
    setDebitAccountSelected(null);
    setCreditBanksCurrencyARS(debitBanksCurrencyARS);
    setCreditBanksCurrencyUSD(debitAccountsFilterActive ? !debitBanksCurrencyARS : debitBanksCurrencyUSD);
  };

  const handleSelectDebitUsageReference = (selectedOption: SelectOption | null) => {
    setDebitUsageReferenceSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleSelectDebitBank = (selectedOption: SelectOption | null) => {
    setDebitBankSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleSelectDebitCurrency = (currency: string, confirm = false) => {
    if ((transferSelected?.creditAccount || creditAccountSelected) && debitCurrencySelected !== currency && !confirm) {
      setDebitCurrencyChanged(currency);
      return;
    }

    if (isDrawer && confirm) {
      setDebitCurrencyChanged(currency);
    }

    if (!transferSelected?.creditAccount) {
      changeCreditBanksCurrency(currency);
      handleCurrencyDisable(currency);
    }
    if (debitCurrencyChanged && confirm) {
      if (transferSelected?.creditAccount) {
        setTransferSelected({ ...transferSelected, creditAccount: null });
      }
      changeCreditBanksCurrency(currency);
      handleCurrencyDisable(currency);
      setDebitCurrencyChanged(null);
      setDebitAccountSelectedId(null);
      setDebitAccountSelected(null);
    }
    setDebitCurrencySelected(currency);
  };

  const handleChangeDebitCurrency = (confirm: boolean) => {
    if (confirm) {
      if (debitCurrencyChanged) {
        handleSelectDebitCurrency(debitCurrencyChanged, true);
      }
      if (debitAccountChanged) {
        handleSelectDebitAccount(debitAccountChanged, true);
        setChangedCurrency(true);
      }
    } else {
      setDebitCurrencyChanged(null);
      if (lastSelectedCurrency === Currencies.ARS) {
        setLastSelectedCurrency(Currencies.USD);
      } else {
        setLastSelectedCurrency(Currencies.ARS);
      }
    }
  };

  const handleSelectDebitFilterType = (selectedOption: SelectOption | null) => {
    setDebitFilterTypeSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleCurrencyInitial = () => {
    if (debitAccountsLength > 1) {
      if (currencySelected === Currencies.USD) {
        setDebitCurrencySelected(Currencies.USD);
        setCreditCurrencySelected(Currencies.USD);
      } else {
        setDebitCurrencySelected(debitBanksCurrencyARS ? Currencies.ARS : Currencies.USD);
        setCreditCurrencySelected(debitBanksCurrencyARS ? Currencies.ARS : Currencies.USD);
      }
      if (isDrawer) {
        setCreditBanksCurrencyARS(currencySelected === Currencies.ARS);
        setCreditBanksCurrencyUSD(currencySelected === Currencies.USD);
      } else {
        setCreditBanksCurrencyARS(debitBanksCurrencyARS);
        setCreditBanksCurrencyUSD(debitAccountsFilterActive ? !debitBanksCurrencyARS : debitBanksCurrencyUSD);
      }
      if (debitAccountsFilterActive) {
        if (isDrawer && currencySelected === Currencies.USD) {
          handleCurrencyDisable(Currencies.USD);
        } else {
          handleCurrencyDisable(debitBanksCurrencyARS ? Currencies.ARS : Currencies.USD);
        }
      }
    }
  };

  const handleCurrencyDisable = (currency: string) => {
    if (debitBanksBothCurrency) {
      setDisableARSTooltip(currency !== Currencies.ARS);
      setDisableUSDTooltip(currency !== Currencies.USD);
    }
  };

  const { refetch: fetchDebitAccounts, isError: isErrorFetchDebitAccounts } = useQuery(
    ['get-debit-accounts', manualTransferData.transferType || codeTransferType],
    () => getDebitAccounts({
      transferType: manualTransferData.transferType || codeTransferType,
      accountSelected: creditAccountSelected?.identifier || creditAccountNumberSelected,
    })?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setStatusGetDebitAccounts(true);
          const accounts: BankAccount[] = data?.data.accounts || [];
          setDebitAccounts(accounts);
        } else {
          setDebitAccountsError(true);
        }
        setTimeout(() => {
          setDebitAccountsLoading(false);
        }, 500);
      },
      onError: () => {
        setDebitAccountsError(true);
        setDebitAccountsLoading(false);
      },
    },
  );

  const { refetch: fetchDebitAccountsFiltered } = useQuery(
    [
      'get-debit-accounts-filtered',
      manualTransferData.transferType || codeTransferType,
      debitBankSelected?.value || '',
      debitCurrencySelected,
      debitFilterTypeSelected?.value || '',
      debitFilterData,
    ],
    () =>
      getDebitAccounts({
        transferType: manualTransferData.transferType || codeTransferType,
        cbuSelected: creditAccountSelected?.cbu || '',
        ...(isDrawer ? {} : { currency: debitCurrencySelected }),
        accountSelected: creditAccountSelected?.identifier || creditAccountNumberSelected,
        filterType: debitFilterTypeSelected?.value || '',
        filterData: debitFilterData,
      })?.then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const accounts: BankAccount[] = data?.data.accounts || [];
          setDebitAccountsFiltered(accounts);
        } else {
          setDebitAccountsFilteredError(true);
        }
        setTimeout(() => {
          setDebitAccountsFilteredLoading(false);
        }, 500);
      },
      onError: () => {
        setDebitAccountsFilteredError(true);
        setDebitAccountsFilteredLoading(false);
      },
    },
  );

  const { refetch: fetchDebitBanks, isError: isErrorFetchDebitBanks } = useQuery(
    ['get-debit-banks', manualTransferData.transferType || codeTransferType],
    () => getDebitBanks(manualTransferData.transferType || codeTransferType)?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setDebitBanks(data?.data.banks);
          const currencyARS = data?.data.currencyARS;
          const currencyUSD = data?.data.currencyUSD;

          setDebitBanksCurrencyARS(currencyARS);
          setDebitBanksCurrencyUSD(currencyUSD);
          setDebitBanksBothCurrency(currencyARS && currencyUSD);
          setStatusGetDebitBanks(true);
        } else {
          setDebitBanksError(true);
        }
        setDebitBanksLoading(false);
      },
      onError: () => {
        setDebitBanksLoading(false);
        setDebitBanksError(true);
      },
    },
  );

  const handleSearchDebitAccount = () => {
    setDebitAccountsFiltered([]);
    setDebitAccountsFilteredError(false);
    setDebitAccountsFilteredLoading(true);
    fetchDebitAccountsFiltered();
  };

  const resetDebitAccountFilters = () => {
    setDebitUsageReferenceSelected(null);
    setDebitBankSelected(null);
    setDebitFilterTypeSelected(null);
    setDebitFilterData('');
    setDisableARSTooltip(false);
    setDisableUSDTooltip(false);
  };

  const loadDebitAccounts = () => {
    fetchDebitAccounts();
  };

  const loadDebitBanks = () => {
    fetchDebitBanks();
  };

  useEffect(() => {
    handleCurrencyInitial();
  }, [debitAccountsFilterActive, debitBanksCurrencyARS, debitBanksCurrencyUSD]);

  useEffect(() => {
    if (debitAccounts && debitAccountsLength === 1) {
      handleSelectDebitAccount(debitAccounts[0].accountId);
    } else {
      handleSelectDebitAccount(null);
    }
  }, [debitAccounts, debitAccountsLength]);

  useEffect(() => {
    if (typeof setDebitAccountSelectedRef === 'function') {
      setDebitAccountSelectedRef(debitAccountSelected?.cbu || '');
    }
  }, [debitAccountSelected]);

  useEffect(() => {
    if (discardChange) {
      setIsFirstSelection(true);
      setLastSelectedCurrency('');
    }
  }, [discardChange]);

  return {
    carouselStep,
    carouselHeight,
    carouselContentHeight,
    boxScroll,
    debitAccounts,
    debitAccountsLoading,
    debitAccountsError,
    debitAccountsLength,
    debitAccountSelectedId,
    debitAccountSelected,
    debitAccountChanged,
    debitAccountsFilterActive,
    debitAccountsFiltered,
    debitAccountsFilteredLoading,
    debitAccountsFilteredError,
    debitUsageReferences,
    debitUsageReferenceSelected,
    debitBanks,
    debitBanksLoading,
    debitBanksError,
    debitBanksCurrencyARS,
    debitBanksCurrencyUSD,
    debitBankSelected,
    debitCurrencySelected,
    debitCurrencyChanged,
    debitFilterTypeSelected,
    debitFilterData,
    disableARSTooltip,
    disableUSDTooltip,
    isErrorFetchDebitBanks,
    isErrorFetchDebitAccounts,
    statusGetDebitAccounts,
    statusGetDebitBanks,
    sameAccountSelectedDebit,
    loadDebitAccounts,
    loadDebitBanks,
    setDebitAccountsLoading,
    setDebitBanksLoading,
    setCarouselStep,
    onClickCarouselStep,
    setDebitAccountSelected,
    setDebitAccountSelectedId,
    setDebitCurrencySelected,
    handleCarouselScroll,
    handleSelectDebitAccount,
    handleRemoveSelectedDebitAccount,
    handleSelectDebitUsageReference,
    handleSelectDebitBank,
    handleSelectDebitCurrency,
    handleChangeDebitCurrency,
    setDebitBalanceError,
    handleSelectDebitFilterType,
    setDebitFilterData,
    handleSearchDebitAccount,
    resetDebitAccountFilters,
    setDebitUsageReferences,
    setDisableARSTooltip,
    setDisableUSDTooltip,
    setSameAccountSelectedDebit,
    shouldShowFilterAccounts,
    changedCurrency,
    setChangedCurrency,
    setStatusGetDebitBanks,
    fetchDebitBanks,
    setStatusGetDebitAccounts,
  };
}
