import { DataReadyUpdateTransfer } from '@interfaces/api';
import { Props } from '@interfaces/hooks/IUseUpdateTransfer';
import { putModifytransfer } from '@services/api';
import { IDS_TOAS, TEXT_TOAS } from '@views/Modify/components/modifyDrawer/constants';
import { useState } from 'react';
import { useMutation } from 'react-query';

import useToastMessage from './useToastMessage';

const useUpdateTransfer = ({ idTransferType, onCancel, refetch }: Props) => {
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const toastHook = useToastMessage();

  const { mutate: mutateModifyTransfer, isLoading } = useMutation(
    (data: DataReadyUpdateTransfer) => putModifytransfer(idTransferType, data)?.then(res => res.data),
    {
      retry: 3,
      onSuccess: responseData => {
        if (responseData.data.acceptedTransfers.length > 0) {
          onCancel(false);
          toastHook.success(IDS_TOAS.toastDeleteSuccess, TEXT_TOAS.toastTextSaveSuccess);
        } else if (responseData.data.rejectedTransfers.length > 0) {
          onCancel(false);
          toastHook.error(IDS_TOAS.toastDeleteSuccess, TEXT_TOAS.toastTextNotSavePermissions);
        }
        refetch();
      },
      onError: err => {
        const errorResponse = err as { response: { status: number } };
        if (errorResponse.response.status === 409) {
          window.location.reload();
        } else if (errorResponse.response.status === 400 || errorResponse.response.status === 404) {
          onCancel(false);
          toastHook.error(IDS_TOAS.toastDeleteSuccess, TEXT_TOAS.toastTextNotSavePermissions);
        } else if (errorResponse.response.status === 500 || errorResponse.response.status === 504) {
          onCancel(true);
          setErrorUpdate(true);
        } else {
          onCancel(true);
          setErrorUpdate(true);
        }
      },
    },
  );

  return {
    mutateModifyTransfer,
    isLoading,
    errorUpdate,
  };
};

export default useUpdateTransfer;
