const mockFileEntries = [
  { fileId: '0', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231113_092244.csvctas-prop', dateTime: '13/11/2023 - 09:22 h.' },
  { fileId: '1', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231114_092245.csv', dateTime: '14/11/2023 - 09:22 h.' },
  { fileId: '2', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231115_092246.csv', dateTime: '15/11/2023 - 09:22 h.' },
  { fileId: '3', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231116_092247.csv', dateTime: '16/11/2023 - 09:22 h.' },
  { fileId: '4', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231117_092248.csv', dateTime: '17/11/2023 - 09:22 h.' },
  { fileId: '5', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231118_092249.csv', dateTime: '18/11/2023 - 09:22 h.' },
  { fileId: '6', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231119_092250.csv', dateTime: '19/11/2023 - 09:22 h.' },
  { fileId: '7', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231120_092251.csv', dateTime: '20/11/2023 - 09:22 h.' },
  { fileId: '8', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231121_092252.csv', dateTime: '21/11/2023 - 09:22 h.' },
  { fileId: '9', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231122_092253.csv', dateTime: '22/11/2023 - 09:22 h.' },
  { fileId: '10', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231117_092248.csv', dateTime: '17/11/2023 - 09:22 h.' },
  { fileId: '11', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231118_092249.csv', dateTime: '18/11/2023 - 09:22 h.' },
  { fileId: '12', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231119_092250.csv', dateTime: '19/11/2023 - 09:22 h.' },
  { fileId: '13', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231120_092251.csv', dateTime: '20/11/2023 - 09:22 h.' },
  { fileId: '14', fileName: 'ctas-propias-por-cbu-8-tef-javi-20231121_092252.csv', dateTime: '21/11/2023 - 09:22 h.' },
];

export default mockFileEntries;
