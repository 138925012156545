import { ListTransferTypesOptions } from '../Interfaces/transfers';

const sortTransferTypeOptions = (optionsList: ListTransferTypesOptions[]) => {
  return optionsList.sort((a, b) => {
    const textA = a?.text?.toLowerCase();
    const textB = b?.text?.toLowerCase();
    return textA.localeCompare(textB);
  });
};

export { sortTransferTypeOptions };
