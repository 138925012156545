import RoutesActions from '@components/RoutesActions';
import { WithPermissions } from '@react-ib-mf/style-guide-ui';
import ConfectionSummary from '@views/ConfectionSummary';
import { es } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import NotFoundPage from './components/NotFoundPage';
import {
  permissionsFirmAnnul,
  permissionsFirmAuth,
  permissionsFirmCreate,
  permissionsFirmModify,
  permissionsSend,
  routesNames,
} from './constants-app';
import ContextProvider from './context/TransfersContext';
import { RouteConfig } from './Interfaces/routes';
import Dashboard from './layouts/dashboard';
import ConfectionInvalidDate from './views/ConfectionInvalidDate';
import ConfectionResult from './views/ConfectionResult';
import EntityKey from './views/EntityKey';
import EntityToken from './views/EntityToken';
import LotDetails from './views/LotDetail';
import Modify from './views/Modify';
import Result from './views/Result';
import SendResult from './views/SendResult';
import Transfers from './views/Transfers';
import TransferSetup from './views/TransferSetup';
// import TransferUploadFiles from './views/TransferUploadFiles';

function App() {
  registerLocale('es', es);

  const routeConfig: RouteConfig[] = [
    { path: routesNames.Root, element: <Transfers />, permissions: [...permissionsSend, ...permissionsFirmAuth, ...permissionsFirmAnnul] },
    { path: routesNames.LotDetailsSend, element: <LotDetails activeBadge />, permissions: permissionsSend },
    { path: routesNames.LotDetailsAuth, element: <LotDetails activeBadge={false} />, permissions: permissionsFirmAuth },
    { path: routesNames.LotDetailsUnauth, element: <LotDetails activeBadge={false} />, permissions: permissionsFirmAuth },
    { path: routesNames.LotDetailsAnnul, element: <LotDetails activeBadge={false} />, permissions: permissionsFirmAnnul },
    { path: routesNames.LotDetailsRevoke, element: <LotDetails activeBadge={false} />, permissions: permissionsSend },
    { path: routesNames.EntityKey, element: <EntityKey />, permissions: permissionsSend },
    { path: routesNames.ResultSend, element: <SendResult />, permissions: permissionsSend },
    { path: routesNames.ResultAuth, element: <Result />, permissions: permissionsFirmAuth },
    { path: routesNames.ResultUnauth, element: <Result />, permissions: permissionsFirmAuth },
    { path: routesNames.ResultAnnul, element: <Result />, permissions: permissionsFirmAnnul },
    { path: routesNames.ResultRevoke, element: <Result />, permissions: permissionsSend },
    { path: routesNames.EntityToken, element: <EntityToken />, permissions: permissionsFirmAuth },
    { path: routesNames.ResultPartialAuth, element: <Result />, permissions: permissionsFirmAuth },
    { path: routesNames.TransferSetup, element: <TransferSetup />, permissions: permissionsFirmCreate },
    // TODO: Added this comment because we needed to uninhabited this url in release-2.6.0
    // { path: routesNames.TransferUploadFiles, element: <TransferUploadFiles />, permissions: permissionsFirmCreate },
    { path: routesNames.Modify, element: <Modify />, permissions: permissionsFirmModify },
    { path: routesNames.LotDetailsModify, element: <LotDetails activeBadge={false} />, permissions: permissionsFirmCreate },
    { path: routesNames.ConfectionResult, element: <ConfectionResult />, permissions: permissionsFirmCreate },
    { path: routesNames.ConfectionInvalidDate, element: <ConfectionInvalidDate />, permissions: permissionsFirmCreate },
    { path: routesNames.ConfectionSummary, element: <ConfectionSummary />, permissions: permissionsFirmCreate },
    { path: '*', element: <NotFoundPage /> },
  ];

  return (
    <BrowserRouter>
      <ContextProvider>
        <RoutesActions />
        <Routes>
          <Route path='/' element={<Dashboard routeConfig={routeConfig} />}>
            {routeConfig.map(({ path, element, permissions }) => (
              <Route
                key={path}
                path={path}
                element={
                  permissions ? (
                    <WithPermissions permission={[...permissions]} showErrorPage>
                      {element}
                    </WithPermissions>
                  ) : (
                    element
                  )
                }
              />
            ))}
          </Route>
        </Routes>
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
