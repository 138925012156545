import { Box, Checkbox, Flex, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { Search } from 'react-feather';

import { TRANSFER_TYPE_CODE } from '../../../../constants-app';
import { useTransfersContext } from '../../../../context/TransfersContext';
import { SectionsTypes } from '../../../../Interfaces/api';
import { ListTransferTypesOptions, ServiceAction } from '../../../../Interfaces/transfers';
import { checkIfArraysAreDifferent } from '../../../../utils/checkIfArrayAreEqual';
import { sortTransferTypeOptions } from '../../../../utils/sortTransferTypeOptions';
import { TRANSFER_TYPE } from '../../../../views/LotDetail/constants';
import BaseScreenFilter from '../BaseScreenFilter';
import {
  EMPTY_MESSAGE,
  MENU_TEST_ID_TRANFER_TYPE,
  SEARCH_INPUT_TRANSFER_TEST_ID,
  TEXT_FILTER_TAG,
  TEXT_PLACEHOLDER,
  TITLE_HEADER,
} from './constants';
import { CheckContainer, CustomBox, CustomCheckBox, CustomEmptyText, CustomInput, CustomText } from './styled';

interface FilterTransderTypeProps {
  isFetching: boolean;
  serviceAction?: ServiceAction;
  optionsTransferType?: SectionsTypes[] | [];
}

function FilterTransferType({ isFetching, serviceAction, optionsTransferType = [] }: FilterTransderTypeProps) {
  const {
    setSelectedTransfersTypesCodes,
    setFiltered,
    filtered,
    selectedTransfersTypesCodes,
    sectionPermissions,
    hasData,
    setPageContext,
  } = useTransfersContext();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [transferTypeCodeList, setTransferTypeCodeList] = useState<ListTransferTypesOptions[]>([]);
  const [listCodesCurrentSection, setListCodesCurrentSection] = useState<string[]>([]);
  const [isDisabledApplyButton, setIsDisabledApplyButton] = useState<boolean>(true);
  const [listTransfersTypes, setListTransfersTypes] = useState<SectionsTypes[]>([]);

  useEffect(() => {
    setListTransfersTypes(sectionPermissions.length > 0 ? sectionPermissions : optionsTransferType);
  }, [sectionPermissions || optionsTransferType]);

  useEffect(() => {
    if (searchText) {
      const filteredTransferTypes = listCodesCurrentSection.filter(tranferCodeItem =>
        TRANSFER_TYPE[tranferCodeItem].toLowerCase().includes(searchText.toLowerCase()),
      );
      setTransferTypeCodeList(sortTransferList(filteredTransferTypes));
    } else {
      listCodesCurrentSection.length && setTransferTypeCodeList(sortTransferList(listCodesCurrentSection));
    }
  }, [searchText]);

  useEffect(() => {
    if (listTransfersTypes?.length) {
      const transferCodesCurrentSection = listTransfersTypes.find(item => item?.section === serviceAction)?.transferTypeCodes || [];
      const validTransferTypes = Object.values(TRANSFER_TYPE_CODE);
      const filteredValidCodes = transferCodesCurrentSection?.filter(code => validTransferTypes?.includes(code));

      setListCodesCurrentSection(filteredValidCodes);
      setTransferTypeCodeList(sortTransferList(filteredValidCodes));
    }
  }, [listTransfersTypes]);

  useEffect(() => {
    setIsDisabledApplyButton(checkIfArraysAreDifferent(selectedTypes, selectedTransfersTypesCodes));
  }, [selectedTypes, selectedTransfersTypesCodes]);

  const handleCheckboxChange = (newTransferType: string) => {
    if (selectedTypes.includes(newTransferType)) {
      setSelectedTypes(selectedTypes.filter(transferTypeItem => transferTypeItem !== newTransferType));
    } else {
      setSelectedTypes([...selectedTypes, newTransferType]);
    }
  };

  const handleApplyButton = () => {
    setPageContext(1);
    setSelectedTransfersTypesCodes(selectedTypes);
    setSearchText('');
    setFiltered({
      ...filtered,
      isTransferTypeActive: !!selectedTypes?.length,
    });
  };

  const handleClearCloseWithOutFilter = () => {
    if (!filtered.isTransferTypeActive) {
      setSearchText('');
      setSelectedTypes([]);
    } else {
      setSelectedTypes(selectedTransfersTypesCodes);
    }
  };

  const sortTransferList = (transferTypeList: string[]): ListTransferTypesOptions[] => {
    const optionsList: ListTransferTypesOptions[] = transferTypeList?.map(tranferTypeCodeItem => ({
      code: tranferTypeCodeItem,
      text: TRANSFER_TYPE[tranferTypeCodeItem],
    }));

    return sortTransferTypeOptions(optionsList);
  };

  const isMenuDisabled = () => {
    if (isFetching) {
      return true;
    }

    if (!hasData && !selectedTransfersTypesCodes.length) {
      return true;
    }

    return false;
  };

  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
    setSearchText(filteredValue);
  };

  return (
    <BaseScreenFilter
      textTag={TEXT_FILTER_TAG}
      titleHeader={TITLE_HEADER}
      count={selectedTransfersTypesCodes?.length}
      statusButtonClear={!selectedTypes?.length}
      statusButtonApply={isDisabledApplyButton}
      handleApplyButton={handleApplyButton}
      onClickClearButton={() => setSelectedTypes([])}
      handleClearCloseWithOutFilter={handleClearCloseWithOutFilter}
      menuTestIdName={MENU_TEST_ID_TRANFER_TYPE}
      testId='type'
      isSelected={filtered.isTransferTypeActive && selectedTransfersTypesCodes?.length > 0}
      isDisabled={isMenuDisabled()}
    >
      <Box sx={CustomBox}>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Search size={24} />
          </InputLeftElement>
          <Input
            sx={CustomInput}
            data-testid={SEARCH_INPUT_TRANSFER_TEST_ID}
            name={SEARCH_INPUT_TRANSFER_TEST_ID}
            onChange={onHandleChange}
            value={searchText}
            placeholder={TEXT_PLACEHOLDER}
          />
        </InputGroup>
        <Flex sx={CheckContainer}>
          {transferTypeCodeList.map(tranferTypeCodeItem => (
            <Checkbox
              key={tranferTypeCodeItem?.code}
              isChecked={selectedTypes?.includes(tranferTypeCodeItem?.code)}
              onChange={() => handleCheckboxChange(tranferTypeCodeItem?.code)}
              sx={CustomCheckBox}
            >
              <Text sx={CustomText}>{tranferTypeCodeItem?.text}</Text>
            </Checkbox>
          ))}
        </Flex>
      </Box>
      {!transferTypeCodeList?.length && <Text sx={CustomEmptyText}>{EMPTY_MESSAGE}</Text>}
    </BaseScreenFilter>
  );
}

export default FilterTransferType;
