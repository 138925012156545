const VIEW_TEXT = {
  title: 'Confeccionar transferencias',
  batchDataTitle: 'Datos del lote',
  summaryTitle: 'Resumen de lote',
  nonEnabledScheduleTitle: 'Fecha de solicitud no válida',
};

const FOOTER_TEXT = {
  buttonPrimaryText: 'Continuar',
  buttonPrimaryConfirmText: 'Confeccionar',
  buttonPrimaryOutLineText: 'Ver histórico',
  buttonGoBackText: 'Volver',
  buttonDiscard: 'Descartar estas transferencias',
  buttonNextWorkingDay: 'Pasar al siguiente día hábil',
};

const DRAFT_MODAL = {
  add: {
    title: '¿Querés continuar sin agregar la transferencia?',
    description: 'Cargaste información de una transferencia pero no la agregaste al lote.',
  },
  edit: {
    title: '¿Querés continuar sin guardar?',
    description: 'Si lo hacés, perderás los cambios que hiciste en la transferencia.',
  },
};

const CONFECTION_TEXTS = {
  loadingTitle: 'Estamos confeccionando las transferencias',
  loadingDescription: 'Esto puede demorar unos instantes.',
  errorTitle: 'No podemos confeccionar el lote',
  errorDescription: '¡No te preocupes! Las transferencias agregadas se mantienen, solo tenés que volver a intentarlo.',
  asyncTitle: 'La confección se está procesando',
  asyncDescription: 'Cuando finalice podrás verificar si todo se procesó correctamente desde la<br>sección <b>Confección</b>.',
};

const BUTTONS_TEXTS = {
  cancel: 'Cancelar',
  continue: 'Sí, continuar',
  retry: 'Reintentar',
  ok: 'Entendido',
};

const CUSTOM_HEIGHT = {
  heightLoadFromFile: '124px',
};

const PANEL_NUMBERS = {
  firstPanel: 0,
  secondPanel: 1,
};

const TEXT_MODAL_CHANGE_VIEW = {
  title: 'Estás por salir de esta sección',
  description: 'Al hacerlo, perderás los datos ingresados y tendrás que empezar de nuevo.',
  buttonCancel: 'Cancelar',
  buttonOut: 'Sí, salir',
};

export { BUTTONS_TEXTS, CONFECTION_TEXTS, CUSTOM_HEIGHT, DRAFT_MODAL, FOOTER_TEXT, PANEL_NUMBERS, TEXT_MODAL_CHANGE_VIEW, VIEW_TEXT };
