const Alert = {
  height: '52px',
  borderRadius: '4px',
  marginBottom: '12px',
};

const Title = {
  height: '32px',
  borderRadius: '4px',
  marginBottom: '16px',
};

const BoxAccount = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 24px 12px 16px',
};

const Divider = {
  height: '1px',
};

const Account = {
  height: '16px',
  borderRadius: '4px',
};

const Account1 = {
  ...Account,
  width: '192px',
  marginBottom: '4px',
};

const Account2 = {
  ...Account,
  width: '291px',
  marginBottom: '4px',
};

const Account3 = {
  ...Account,
  width: '224px',
};

const Account4 = {
  ...Account,
  width: '55px',
  marginBottom: '4px',
};

const Account5 = {
  ...Account,
  width: '83px',
};

const Account6 = {
  ...Account,
  width: '126px',
  marginBottom: '4px',
};

const Account7 = {
  ...Account,
  width: '83px',
};

export { Account1, Account2, Account3, Account4, Account5, Account6, Account7, Alert, BoxAccount, Divider, Title };
