const Badge = {
  width: '40px',
  height: '20px',
  margin: '0 38px',
};

const Circle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
};

const Circle2 = {
  ...Circle,
  marginRight: '4px',
};

const BoxAccount = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px',
};

const Divider = {
  height: '1px',
};

const Account = {
  height: '16px',
  borderRadius: '4px',
  margin: '2px 0',
};

const Account1 = {
  ...Account,
  width: '118px',
  height: '26px',
};

const Account2 = {
  ...Account,
  width: '350px',
};

const Account3 = {
  ...Account,
  width: '224px',
};

const Account4 = {
  ...Account,
  width: '84px',
};

const Account5 = {
  ...Account,
  width: '125px',
};

const Account6 = {
  ...Account,
  width: '118px',
  height: '20px',
};

const Account7 = {
  ...Account,
  width: '118px',
};

const Account8 = {
  ...Account,
  width: '184px',
};

const Title = {
  width: '587px',
  height: '26px',
  borderRadius: '4px',
  marginBottom: '4px',
};

const Description = {
  width: '596px',
  height: '16px',
};

const Download = {
  width: '133px',
  height: '32px',
  borderRadius: '4px',
  marginRight: '12px',
};

export {
  Account1,
  Account2,
  Account3,
  Account4,
  Account5,
  Account6,
  Account7,
  Account8,
  Badge,
  BoxAccount,
  Circle,
  Circle2,
  Description,
  Divider,
  Download,
  Title,
};
