/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Badge } from '@wow/badge';
import { Calendar, Info, Package } from 'react-feather';

import { CURRENCY_DOLAR, CURRENCY_PESOS, DataMessageErrorRequest, USD_CURRENCY } from '../../constants-app';
import { TransferDetails, transferStatusInterface } from '../../Interfaces/Drawer';
import { colors } from '../../styled/generalStyles';
import { decimalFormat, fechaHoraFormateada } from '../../utils/FormatChange';
import {
  LOADING_SCREEN_TEXT,
  TRANSFER_TYPE_AFI,
  TRANSFER_TYPE_B2B,
  TRANSFER_TYPE_PDS,
  UNIFIED_SHIPPING_MARK,
} from '../../views/LotDetail/constants';
import BankLogo from '../BankLogo';
// import iconDisconnected from '../../assets/icon-disconnected.svg';
import IconDisconnected from '../Icons/icon-disconnected';
import Loader from '../Loader';
import ViewNoResult from '../ViewNoResult';
import {
  AccountNumber,
  AccountType,
  borderAccordion,
  CloseButton,
  ContentStatusTransfers,
  Importe,
  InfoAccount,
  SectionAcordion,
  SectionAcordionMoreDetails,
  sectionDataAccordion,
  SectionsStatusTransfers,
  sectionTitleAccordion,
  StatusTransfers,
  StatusTransfersBold,
  TextAcordion,
  TextAcordionMoreDetails,
  TextBold,
  TextBoldAcordion,
  TextBoldAcordionMoreDetails,
  TextBoldObservation,
  TotalTransfers,
} from './index.styled';

interface Props {
  isOpen: boolean;
  onCancel: (arg: boolean) => void;
  transfersDetails?: TransferDetails;
  statusTransfersDetail?: boolean;
  handleToggleDrawerRetry?: () => void;
}

function DrawerDetails({ isOpen, onCancel, transfersDetails, statusTransfersDetail, handleToggleDrawerRetry = () => '' }: Props) {
  const {
    debitAccount,
    creditAccount,
    amount,
    transferNumber,
    requestDate,
    description,
    lastStatus,
    unifiedShipping,
    numberPayment,
    transferStatus,
    idTransferType,
    remarks,
    additionalData,
  } = transfersDetails || {};

  const showMoreDetails = additionalData?.addendaPaymentsThird || additionalData?.addendaProviders || additionalData?.addendaInterpagos;

  const textOneMoreDetails = additionalData?.addendaProviders?.codClient
    ? 'Cliente'
    : additionalData?.addendaInterpagos?.nroOperator
    ? 'Nro de operación'
    : 'Concepto';

  const textTwoMoreDetails = additionalData?.addendaProviders?.codClient
    ? 'Tipo Retención'
    : additionalData?.addendaInterpagos?.nroOperator
    ? 'Comunidad'
    : 'Motivo';

  const textThreeMoreDetails = additionalData?.addendaProviders?.codClient
    ? 'Documento a cancelar'
    : additionalData?.addendaInterpagos?.nroOperator
    ? 'Vendedor'
    : 'Comprobante';

  const resultOneMoreDetails =
    additionalData?.addendaProviders?.codClient ||
    additionalData?.addendaInterpagos?.nroOperator ||
    additionalData?.addendaPaymentsThird?.conceptDescription ||
    '-';

  const resultTwoMoreDetails =
    additionalData?.addendaProviders?.typeRetentionId ||
    additionalData?.addendaInterpagos?.nameCommunity ||
    additionalData?.addendaPaymentsThird?.reasons ||
    '-';

  const resultThreeMoreDetails =
    additionalData?.addendaProviders?.typeDocId ||
    additionalData?.addendaInterpagos?.nameSellerPerson ||
    additionalData?.addendaPaymentsThird?.voucherCod ||
    '-';

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={() => onCancel(false)} size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <DrawerCloseButton sx={CloseButton} />
        </DrawerHeader>
        <DrawerBody>
          {statusTransfersDetail ? (
            <ViewNoResult
              title={DataMessageErrorRequest.title}
              description={DataMessageErrorRequest.description}
              linkName={DataMessageErrorRequest.linkName}
              Icon={IconDisconnected}
              handleToggleClick={handleToggleDrawerRetry}
              retryOtherLink
              transferDetailDrawer
            />
          ) : transfersDetails ? (
            <>
              <Badge variant='info-light'>Detalle de transferencia</Badge>
              <Text sx={Importe} textStyle='h2Bold'>{`${
                debitAccount?.descriptionCurrency !== USD_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR
              } ${amount ? decimalFormat(amount || 0) : ' ***'}`}</Text>
              <Text sx={TotalTransfers} textStyle='bodyMdSemi'>{`Transferencia ${transferNumber || ''}`}</Text>
              <Box display='flex' alignItems='center'>
                <Flex marginRight='5px'>
                  <Calendar color={colors.Secondary100} size='20px' fontWeight='900' />
                </Flex>
                <Text sx={TextBold} textStyle='bodySmSemi'>
                  {requestDate?.split('-').reverse().join('/') || ''}
                </Text>
                <Flex margin='0 13px 0 13px'>
                  <Package color={colors.Secondary100} size='20px' fontWeight='900' />
                </Flex>
                <Text sx={TextBold} textStyle='bodySmSemi'>
                  {description}
                </Text>
                {remarks && (
                  <Flex marginRight='6px'>
                    <Info color={colors.Info100} size='20px' fontWeight='900' />
                  </Flex>
                )}
                {remarks && <Text sx={TextBoldObservation} textStyle='bodySmSemi'>{`"${remarks}"`}</Text>}
              </Box>
              <Text sx={AccountType} textStyle='labelMd'>
                Cuenta débito
              </Text>
              <Box display='flex' alignItems='center'>
                <BankLogo src={debitAccount?.codeBCRA} transferType='SUE' />
                <Box>
                  <Text sx={InfoAccount} textStyle='titleSmBold'>
                    {debitAccount?.nameBank && `${debitAccount?.nameBank} `}
                    {debitAccount?.nameAccountDetailView?.nameAccount && ` | ${debitAccount?.nameAccountDetailView?.nameAccount} `}
                    {debitAccount?.cuit && `| ${debitAccount?.cuit}`}
                  </Text>
                  <Text sx={AccountNumber} textStyle='labelMd'>
                    {debitAccount?.cbu || debitAccount?.numberAccount || ''}
                    {debitAccount?.descriptionAccountType && ` | ${debitAccount?.descriptionAccountType}`}
                  </Text>
                </Box>
              </Box>
              <Text sx={AccountType} textStyle='labelMd'>
                Cuenta crédito
              </Text>
              <Box display='flex' alignItems='center'>
                <BankLogo src={creditAccount?.codeBCRA} transferType={idTransferType || ''} />
                <Box>
                  {idTransferType === TRANSFER_TYPE_B2B || idTransferType === TRANSFER_TYPE_AFI || idTransferType === TRANSFER_TYPE_PDS ? (
                    <Text sx={InfoAccount} textStyle='titleSmBold'>
                      {creditAccount?.nameBank && `${creditAccount?.nameBank} `}
                    </Text>
                  ) : (
                    <Text sx={InfoAccount} textStyle='titleSmBold'>
                      {creditAccount?.nameBank && `${creditAccount?.nameBank} `}
                      {creditAccount?.nameAccountDetailView?.nameAccount && ` | ${creditAccount?.nameAccountDetailView?.nameAccount} `}
                      {creditAccount?.cuit && `| ${creditAccount?.cuit}`}
                    </Text>
                  )}
                  <Text sx={AccountNumber} textStyle='labelMd'>
                    {creditAccount?.cbu || creditAccount?.numberAccount || ''}
                    {creditAccount?.descriptionAccountType && ` | ${creditAccount?.descriptionAccountType}`}
                  </Text>
                </Box>
              </Box>
              <Box sx={ContentStatusTransfers}>
                <Box sx={SectionsStatusTransfers}>
                  <Text sx={StatusTransfers} textStyle='titleSm'>
                    Estado
                  </Text>
                  <Text sx={StatusTransfersBold} textStyle='labelLgBold'>
                    {lastStatus || ''}
                  </Text>
                </Box>
                <Box sx={SectionsStatusTransfers}>
                  <Text sx={StatusTransfers} textStyle='titleSm'>
                    Envío unificado
                  </Text>
                  <Text sx={StatusTransfersBold} textStyle='labelLgBold'>
                    {unifiedShipping === UNIFIED_SHIPPING_MARK ? 'Si' : 'No' || ''}
                  </Text>
                </Box>
                <Box sx={SectionsStatusTransfers}>
                  <Text sx={StatusTransfers} textStyle='titleSm'>
                    Número de pago
                  </Text>
                  <Text sx={StatusTransfersBold} textStyle='labelLgBold'>
                    {numberPayment || ''}
                  </Text>
                </Box>
              </Box>
              {showMoreDetails && (
                <Accordion allowToggle marginBottom='20px' sx={borderAccordion}>
                  <AccordionItem>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          sx={sectionTitleAccordion}
                          bg={isExpanded ? colors.Primary60 : colors.White}
                          data-testid='accordion-show-details-button'
                        >
                          <Box flex='1' textAlign='left'>
                            Más Detalles
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb='16px' sx={sectionDataAccordion}>
                          <Box sx={SectionAcordionMoreDetails}>
                            <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                              {textOneMoreDetails}
                            </Text>
                            <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                              {resultOneMoreDetails}
                            </Text>
                          </Box>
                          <Box sx={SectionAcordionMoreDetails}>
                            <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                              {textTwoMoreDetails}
                            </Text>
                            <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                              {resultTwoMoreDetails}
                            </Text>
                          </Box>
                          <Box sx={SectionAcordionMoreDetails}>
                            <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                              {textThreeMoreDetails}
                            </Text>
                            <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                              {resultThreeMoreDetails}
                            </Text>
                          </Box>
                          {additionalData?.addendaProviders && (
                            <>
                              <Box sx={SectionAcordionMoreDetails}>
                                <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                                  Total Retención
                                </Text>
                                <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                                  {additionalData?.addendaProviders?.amountRetention
                                    ? decimalFormat(additionalData?.addendaProviders?.amountRetention || 0)
                                    : '-'}
                                </Text>
                              </Box>
                              <Box sx={SectionAcordionMoreDetails}>
                                <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                                  Tipo Orden de Pago
                                </Text>
                                <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                                  {additionalData?.addendaProviders?.typeOrderPayment || '-'}
                                </Text>
                              </Box>
                              <Box sx={SectionAcordionMoreDetails}>
                                <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                                  Número de Orden de pago
                                </Text>
                                <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                                  {additionalData?.addendaProviders?.numberOrderPayment || '-'}
                                </Text>
                              </Box>
                              <Box sx={SectionAcordionMoreDetails}>
                                <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                                  Número de Documento
                                </Text>
                                <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                                  {additionalData?.addendaProviders?.numberDocCancel || '-'}
                                </Text>
                              </Box>
                              <Box sx={SectionAcordionMoreDetails}>
                                <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                                  Número de NC
                                </Text>
                                <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                                  {additionalData?.addendaProviders?.numberNoteCredir || '-'}
                                </Text>
                              </Box>
                              <Box sx={SectionAcordionMoreDetails}>
                                <Text sx={TextBoldAcordionMoreDetails} textStyle='bodyMdSemi'>
                                  Importe NC
                                </Text>
                                <Text sx={TextAcordionMoreDetails} textStyle='titleSm'>
                                  {additionalData?.addendaProviders?.amountNoteCred
                                    ? decimalFormat(additionalData?.addendaProviders?.amountNoteCred || 0)
                                    : '-'}
                                </Text>
                              </Box>
                            </>
                          )}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
              )}
              <Accordion allowToggle defaultIndex={0} sx={borderAccordion}>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton sx={sectionTitleAccordion} bg={isExpanded ? colors.Primary60 : colors.White}>
                        <Box flex='1' textAlign='left'>
                          Estados por los que pasó la transferencia ({transferStatus?.length || ''})
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb='16px' sx={sectionDataAccordion}>
                        {transferStatus?.map(({ lastName, name, dateTime, description: descriptionStatus }: transferStatusInterface) => (
                          <Box sx={SectionAcordion} key={dateTime}>
                            <Box>
                              <Text sx={TextBoldAcordion} textStyle='bodyMdSemi'>
                                {`${name} ${lastName}` || ''}
                              </Text>
                              <Text sx={TextAcordion} textStyle='titleSm'>
                                {fechaHoraFormateada(dateTime)?.replace(/,/g, ' ·') || ''}
                              </Text>
                            </Box>
                            <Text sx={TextAcordion} textStyle='titleSm'>
                              {descriptionStatus || ''}
                            </Text>
                          </Box>
                        ))}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </>
          ) : (
            <Loader title={LOADING_SCREEN_TEXT.title} description={LOADING_SCREEN_TEXT.description} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerDetails;
