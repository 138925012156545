/* eslint-disable no-shadow */
export const TITLE_TOOLTIP_UNIFIED_SHIPPING = 'Lote con envío unificado:';
export const TEXT_TOOLTIP_UNIFIED_SHIPPING = 'Si tiene más de\nuna cuenta de débito, envía todos los lotes\njuntos.';

export const ALL_CODE_STATUS_TRANSFERS = {
  EFR: 'EFR',
  FIR: 'FIR',
};

export const ALL_STATUS_TRANSFERS = {
  [ALL_CODE_STATUS_TRANSFERS.EFR]: 'En autorización',
  [ALL_CODE_STATUS_TRANSFERS.FIR]: 'Autorizada ',
};

export const DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH = 400;

export const TEXT_LOADING_UNAUTHORIZE = {
  title: 'Estamos quitando tu autorización',
  description: 'Esto puede demorar unos instantes.',
};

export const TEXT_ERROR_UNAUTHORIZE = {
  title: 'No podemos quitar tu autorización',
  description: '¡No te preocupes! Las transferencias seleccionadas se mantienen, solo<br>tenés que volver a intentarlo.',
};

export const TEXT_ERROR_ZERO = {
  title: 'No podemos quitar tu autorización',
  description: 'Las transferencias ya no están disponibles, revisá el estado en la sección<br><strong>Reportes.</strong>',
};

export const TEXT_SUCCESS = {
  title: 'Quitaste tu autorización',
  description: 'Podés volver a autorizar las transferencias desde la sección <strong>Autorizar</strong>.',
};

export const TEXT_MODAL_ASYNC = {
  title: 'Quitando tu autorización de las\ntransferencias',
  description:
    'Esto puede demorar unos minutos.<br>Si necesitás seguir operando, volvé a la sección <strong>Transferencias.</strong><br>No te preocupes, el proceso seguirá en curso.',
};

export const TEXT_MODAL_ASYNC_DONE = {
  title: 'Estamos quitando tu autorización',
  description: 'Cuando finalice podrás verificar si todo se procesó correctamente desde<br>la sección <strong>Reportes</strong>.',
};

export const TEXT_MODAL_ASYNC_ERROR = {
  title: 'Algo no salió como esperábamos',
  description:
    'Para verificar si se quitó tu autorización de las transferencias, revisá el<br>estado en la sección <strong>Reportes</strong>.',
};

export const TEXT_BUTTONS = {
  ok: 'Entendido',
  retry: 'Reintentar',
  cancel: 'Cancelar',
  unauthorize: 'Quitar autorización',
  backToTransfers: 'Volver a Transferencias',
};

export const EXCEPTION_CODES = {
  success: 'TEF-0000',
  partialRejection: 'TEF-0004',
  totalRejection: 'TEF-0005',
  asyncProcess: 'TEF-0008',
  timeOut: 'TEF-0015',
};

export enum UNAUTHORIZE_ACTION {
  NONE = 'NONE',
  GET_DETAILS = 'GET_DETAILS',
  UNAUTHORIZE = 'UNAUTHORIZE',
}

export enum UNAUTHORIZE_ERROR {
  NONE = 'NONE',
  UNAUTHORIZE = 'UNAUTHORIZE',
  ZERO = 'ZERO',
}
