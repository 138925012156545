import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { EXCEPTION_CODES } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { SelectOption } from '../Interfaces/transferSetup';
import { getParamsHolidays, getParamsWorkingDay, getTransferTypes } from '../services/api';

interface Props {
  statusGetPermission: boolean;
}

export function useManualTransfer({ statusGetPermission }: Props) {
  const [status, setStatus] = useState(false);
  const {
    holidays,
    workingDay,
    otpConfigured,
    manualTransferData,
    setTransferTypeByOperators,
    setTransferTypeOptionSelected,
    setDateSelected,
    setHolidays,
    setWorkingDay,
    setOtpConfigured,
    setShowAsyncProcesses,
    setManualTransferData,
  } = useTransfersContext();
  const [transferTypesList, setTransferTypesList] = useState<SelectOption[]>([]);
  const [transferTypesListError, setTransferTypesListError] = useState(false);
  const [holidaysError, setHolidaysError] = useState(false);
  const [workingDayError, setWorkingDayError] = useState(false);

  const { accountsNotAvailable } = manualTransferData;

  const { refetch: fetchTransferTypesList, isFetching: transferTypesListLoading } = useQuery(
    ['tranfer-types-list'],
    () => getTransferTypes().then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const { transferTypeByOperators = [] } = data?.data || {};

          const tranfersferTypesOptions = transferTypeByOperators.map(item => ({
            value: item.code,
            label: item.description,
          }));

          setTransferTypeByOperators(transferTypeByOperators);
          setTransferTypesList(tranfersferTypesOptions);
          setOtpConfigured(data?.data.otpConfigured);
          fetchHolidays();
        } else {
          setStatus(true);
          setTransferTypesListError(true);
        }
      },
      onError: () => {
        setStatus(true);
        setTransferTypesListError(true);
      },
    },
  );

  const { refetch: fetchHolidays, isFetching: holidaysLoading } = useQuery(
    ['get-params-holidays'],
    () => getParamsHolidays()?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setHolidays(data?.data.holidays);
          fetchWorkingDay();
        } else {
          setStatus(true);
          setHolidaysError(true);
        }
      },
      onError: () => {
        setStatus(true);
        setHolidaysError(true);
      },
    },
  );

  const { refetch: fetchWorkingDay, isFetching: workingDayLoading } = useQuery(
    ['get-params-working-day'],
    () => getParamsWorkingDay()?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setWorkingDay(data?.data?.workingDay);
        } else {
          setWorkingDayError(true);
        }
        setStatus(true);
      },
      onError: () => {
        setStatus(true);
        setWorkingDayError(true);
      },
    },
  );

  const fetchData = () => {
    setOtpConfigured(true);
    setTransferTypeOptionSelected({
      calendarDefer: 0,
      deferMaxDays: 0,
      code: '',
      description: '',
    });
    setDateSelected(null);
    setTransferTypesListError(false);
    setHolidaysError(false);
    setWorkingDayError(false);
    fetchTransferTypesList();
  };

  const onClickRetry = () => {
    setStatus(false);
    fetchData();
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Confección manual - Servicio no disponible',
      content_type: 'No pudimos cargar los datos solicitados',
      item_id: 'Reintentar',
    });
  };

  const closeNotAvailableModal = () => {
    setManualTransferData({
      ...manualTransferData,
      accountsNotAvailable: null,
    });
  };

  useEffect(() => {
    setShowAsyncProcesses(false);
  }, []);

  useEffect(() => {
    if (statusGetPermission) {
      fetchData();
    }
  }, [statusGetPermission]);

  const isLoading = transferTypesListLoading || holidaysLoading || workingDayLoading;
  const hasError = transferTypesListError || holidaysError || workingDayError;

  useEffect(() => {
    if (!isLoading) {
      setShowAsyncProcesses(!hasError);
    }
  }, [isLoading, hasError]);

  return {
    status,
    isLoading,
    hasError,
    transferTypesList,
    holidays,
    workingDay,
    otpConfigured,
    accountsNotAvailable,
    onClickRetry,
    closeNotAvailableModal,
  };
}
