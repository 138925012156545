/* eslint-disable @typescript-eslint/no-empty-function */
import { TabList } from '@chakra-ui/react';

import { useTransfersContext } from '../../context/TransfersContext';
import CustomTab from '../CustomTabs';
import { TABS } from './constants';
import CustomBox from './styled';

interface Props {
  showAUT: boolean;
  showENV: boolean;
  showDES: boolean;
  showANU: boolean;
  showREV: boolean;
}

function HomeTabs({ showAUT, showENV, showDES, showANU, showREV }: Props) {
  const { setLotsSelected, setDataLot, setTransfersAccepted, setTransfersRejected } = useTransfersContext();

  const onClick = () => {
    setLotsSelected([]);
    setTransfersAccepted([]);
    setTransfersRejected([]);
    setDataLot([]);
  };

  return (
    <TabList sx={CustomBox} id='tabs'>
      <CustomTab sx={{ display: showAUT ? 'block' : 'none' }} viewTablesTab onClick={() => onClick()}>
        {TABS.tab1}
      </CustomTab>
      <CustomTab sx={{ display: showENV ? 'block' : 'none' }} viewTablesTab onClick={() => onClick()}>
        {TABS.tab2}
      </CustomTab>
      <CustomTab sx={{ display: showANU ? 'block' : 'none' }} viewTablesTab onClick={() => onClick()}>
        {TABS.tab3}
      </CustomTab>
      <CustomTab sx={{ display: showDES ? 'block' : 'none' }} viewTablesTab onClick={() => onClick()}>
        {TABS.tab4}
      </CustomTab>
      <CustomTab sx={{ display: showREV ? 'block' : 'none' }} viewTablesTab onClick={() => onClick()}>
        {TABS.tab5}
      </CustomTab>
    </TabList>
  );
}

export default HomeTabs;
