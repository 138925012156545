/* eslint-disable no-nested-ternary */
import { Flex, Skeleton } from '@chakra-ui/react';

import styles from './index.styled';

function ProcessesCardSkeleton() {
  return (
    <Flex sx={styles.card}>
      <Flex sx={styles.header}>
        <Skeleton width='59px' height='20px' borderRadius='5px' />
        <Skeleton width='114px' height='16px' borderRadius='5px' margin='0 3px 0 8px' />
        <Skeleton width='35px' height='16px' borderRadius='5px' />
      </Flex>
      <Flex sx={styles.content}>
        <Skeleton width='291px' height='20px' borderRadius='5px' />
      </Flex>
      <Flex sx={styles.footer}>
        <Skeleton width='100%' height='40px' borderRadius='5px' />
      </Flex>
    </Flex>
  );
}

export default ProcessesCardSkeleton;
