/* eslint-disable no-shadow */
const texts = {
  loadingBalance: 'Cargando saldo',
  updatedBalance: 'Saldo actualizado: ',
  unique: 'Esta es tu única cuenta disponible para este tipo de transferencia',
  editTooltip: 'Sólo podés modificar una cuenta a la vez',
  btnModify: 'Modificar',
};

const EXCEPTION_CODES = {
  success: 'TEF-0000',
  serviceError: 'TEF-0010',
  timeoutError: 'TEF-0015',
};

const TestIds = {
  account: 'account-',
  accountFooter: 'account-footer',
  removeSelected: 'remove-selected',
};

enum CardAccountMode {
  carousel = 'carousel',
  filterList = 'filterList',
  filterSelected = 'filterSelected',
  modifyTransferdrawer = 'modifyTransferdrawer',
  drawerList = 'drawerList',
  selected = 'selected',
  unique = 'unique',
}

const accountNameMaxLength = 18;
const accountNameDrawerMaxLength = 34;

export { accountNameDrawerMaxLength, accountNameMaxLength, CardAccountMode, EXCEPTION_CODES, TestIds, texts };
