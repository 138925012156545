/* eslint-disable no-nested-ternary */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Text,
} from '@chakra-ui/react';
import IconBeware from '@components/Icons/icon-beware';
import IconDisconnected from '@components/Icons/icon-disconnected';
import IconInfoPopup from '@components/Icons/icon-info-popup';
import CustomModal from '@components/Modal';
import ViewNoResult from '@components/ViewNoResult';
import { CONFECTION_TEXTS } from '@views/TransferSetup/constants';
import { Badge } from '@wow/badge';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { sanitize } from 'dompurify';
import { ChevronDown, ChevronUp, Download } from 'react-feather';

import BaseScreen from '../../components/BaseScreen';
import BottomNav from '../../components/BottomNav';
import IconCancelModal from '../../components/Icons/icon-cancel-modal';
import { DOWNLOAD_FORMAT } from '../../constants-app';
import { useConfectionResult } from '../../hooks/useConfectionResult';
import { ResultStatus } from '../../Interfaces/transferSetup';
import RejectedItem from './components/RejectedItem';
import ResultItem from './components/ResultItem';
import ResultSkeleton from './components/ResultSkeleton';
import { testIds, texts } from './constants';
import styles from './index.styled';

function ConfectionResult() {
  const {
    isResultLoading,
    isResultError,
    isConfectionLoading,
    isConfectionError,
    transferType,
    transfersGroups,
    acceptedTransfersTotal,
    acceptedTransfersLotNumber,
    rejectedTransfersTotal,
    rejectedTransfersGroups,
    validatedTransfersTotal,
    hasAcceptedTransfers,
    hasRejectedTransfers,
    hasValidatedTransfers,
    customSummary,
    primaryButtonText,
    secondaryButtonText,
    showPendingModal,
    isAsyncProcess,
    handleGoToDetail,
    handleDownload,
    primaryButtonAction,
    secondaryButtonAction,
    handleCancelConfection,
    handleRetryConfection,
    handleConfirmPendingModal,
    handleRetryAsyncProcess,
    handleAsyncModal,
  } = useConfectionResult();

  if (isResultError) {
    return (
      <ViewNoResult
        title={texts.serviceErrorTitle}
        description={texts.serviceErrorDescription}
        linkName={texts.retry}
        Icon={IconDisconnected}
        onClickLink={handleRetryAsyncProcess}
      />
    );
  }

  return (
    <>
      {isConfectionLoading && <LoadingScreen title={texts.loadingTitle} description={texts.loadingDescription} />}
      <BaseScreen
        title={texts.title}
        isActiveSubTitle={hasValidatedTransfers}
        subTitle={hasValidatedTransfers ? texts.validatedSubtitle : undefined}
        sizeTitle='h2Bold'
        id={testIds.result}
        data-testid={testIds.result}
      >
        {isResultLoading ? (
          <ResultSkeleton />
        ) : (
          <Accordion
            allowMultiple={(hasValidatedTransfers || hasAcceptedTransfers) && hasRejectedTransfers}
            allowToggle={
              (hasValidatedTransfers || hasAcceptedTransfers) && hasRejectedTransfers
                ? false
                : hasValidatedTransfers || hasAcceptedTransfers || hasRejectedTransfers
            }
            defaultIndex={(hasValidatedTransfers || hasAcceptedTransfers) && hasRejectedTransfers ? [0, 1] : 0}
            sx={styles.accordion}
          >
            {(hasAcceptedTransfers || hasValidatedTransfers) && (
              <AccordionItem sx={styles.item}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton sx={styles.header}>
                      <Flex sx={styles.headerMain}>
                        <Flex sx={styles.badge}>
                          <Badge variant={hasValidatedTransfers ? 'warning-light' : 'success-light'}>
                            {hasValidatedTransfers ? texts.pending : texts.success}
                          </Badge>
                        </Flex>
                        <Flex sx={styles.headerTexts}>
                          <Text as='span' textStyle='h5Bold'>
                            {hasValidatedTransfers
                              ? texts.validatedTitle(validatedTransfersTotal)
                              : texts.acceptedTitle(acceptedTransfersTotal, acceptedTransfersLotNumber)}
                          </Text>
                          <Text
                            as='span'
                            textStyle='labelMd'
                            textAlign='left'
                            dangerouslySetInnerHTML={{
                              __html: sanitize(hasValidatedTransfers ? texts.validatedDescription : texts.acceptedDescription),
                            }}
                          />
                        </Flex>
                      </Flex>
                      <Flex sx={styles.headerActions}>
                        <Menu placement='bottom-end' autoSelect={false}>
                          <MenuButton
                            as={Button}
                            leftIcon={<Download />}
                            rightIcon={<ChevronDown />}
                            variant='primary-outline'
                            size='sm'
                            onClick={e => e.stopPropagation()}
                          >
                            {texts.download}
                          </MenuButton>
                          <MenuList sx={styles.menuList}>
                            <MenuItem
                              data-testid={testIds.txtAccepted}
                              onClick={e => {
                                e.stopPropagation();
                                handleDownload(hasValidatedTransfers ? ResultStatus.validated : ResultStatus.accepted, DOWNLOAD_FORMAT.txt);
                              }}
                            >{`${texts.format} ${texts.txt}`}</MenuItem>
                            <MenuItem
                              data-testid={testIds.csvAccepted}
                              onClick={e => {
                                e.stopPropagation();
                                handleDownload(hasValidatedTransfers ? ResultStatus.validated : ResultStatus.accepted, DOWNLOAD_FORMAT.csv);
                              }}
                            >{`${texts.format} ${texts.csv}`}</MenuItem>
                          </MenuList>
                        </Menu>
                        <Icon as={isExpanded ? ChevronUp : ChevronDown} sx={styles.chevron} />
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel sx={styles.panel}>
                      <Table>
                        {transfersGroups.map((item, index) => (
                          <ResultItem
                            key={`a${index + 1}`}
                            data={item}
                            index={index}
                            status={hasValidatedTransfers ? ResultStatus.validated : ResultStatus.accepted}
                            handleGoToDetail={handleGoToDetail}
                          />
                        ))}
                      </Table>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            )}
            {!!rejectedTransfersTotal && (
              <AccordionItem sx={styles.item}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton sx={styles.header}>
                      <Flex sx={styles.headerMain}>
                        <Box sx={styles.badge}>
                          <Badge variant='error-light'>{texts.error}</Badge>
                        </Box>
                        <Flex sx={styles.headerTexts}>
                          <Text as='span' textStyle='h5Bold'>
                            {texts.rejectedTitle(rejectedTransfersTotal)}
                          </Text>
                          <Text as='span' textStyle='labelMd' dangerouslySetInnerHTML={{ __html: sanitize(texts.rejectedDescription) }} />
                        </Flex>
                      </Flex>
                      <Flex sx={styles.headerActions}>
                        <Menu placement='bottom-end' autoSelect={false}>
                          <MenuButton
                            as={Button}
                            leftIcon={<Download />}
                            rightIcon={<ChevronDown />}
                            variant='primary-outline'
                            size='sm'
                            onClick={e => e.stopPropagation()}
                          >
                            {texts.download}
                          </MenuButton>
                          <MenuList sx={styles.menuList}>
                            <MenuItem
                              data-testid={testIds.txtRejected}
                              onClick={e => {
                                e.stopPropagation();
                                handleDownload(ResultStatus.rejected, DOWNLOAD_FORMAT.txt);
                              }}
                            >{`${texts.format} ${texts.txt}`}</MenuItem>
                            <MenuItem
                              data-testid={testIds.csvRejected}
                              onClick={e => {
                                e.stopPropagation();
                                handleDownload(ResultStatus.rejected, DOWNLOAD_FORMAT.csv);
                              }}
                            >{`${texts.format} ${texts.csv}`}</MenuItem>
                          </MenuList>
                        </Menu>
                        <Icon as={isExpanded ? ChevronUp : ChevronDown} sx={styles.chevron} />
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel sx={styles.panel}>
                      {rejectedTransfersGroups.map((item, index) => (
                        <RejectedItem key={`r${index + 1}`} item={item} index={index} />
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            )}
          </Accordion>
        )}
        <BottomNav
          textButtonPrimaryText={primaryButtonText}
          activeIconButtonPrimaryText
          clickButtonPrimaryText={primaryButtonAction}
          buttonPrimaryDisabled={isResultLoading}
          textButtonPrimaryOutLineText={secondaryButtonText}
          buttonPrimaryOutLineDisabled={isResultLoading}
          handleFourthButton={secondaryButtonAction}
          showTextTransferType
          textTransferType={transferType}
          collapsableTransferType={hasAcceptedTransfers}
          customSummary={hasAcceptedTransfers ? customSummary : undefined}
          size='md'
        />
        <CustomModal
          isOpen={showPendingModal}
          Icon={IconBeware}
          onCancel={() => handleConfirmPendingModal(false)}
          firstDescription={texts.pendingModalDescription}
          title={texts.pendingModalTitle}
          enableFirstButton
          firstTextButton={texts.cancel}
          enableSecondButton
          secondTextButton={texts.continue}
          actionButton2={() => handleConfirmPendingModal(true)}
          alternativeButtonStyling
          size='xl'
          newStyle
        />
        <CustomModal
          isOpen={isConfectionError}
          Icon={IconCancelModal}
          onCancel={handleCancelConfection}
          firstDescription={texts.errorDescription}
          title={texts.errorTitle}
          enableFirstButton
          firstTextButton={texts.cancel}
          enableSecondButton
          secondTextButton={texts.retry}
          actionButton2={handleRetryConfection}
          alternativeButtonStyling
          size='xl'
          newStyle
        />
        <CustomModal
          isOpen={isAsyncProcess}
          Icon={IconInfoPopup}
          onCancel={handleAsyncModal}
          firstDescription={CONFECTION_TEXTS.asyncDescription}
          title={CONFECTION_TEXTS.asyncTitle}
          enableFirstButton
          firstTextButton={texts.ok}
          size='xl'
          newStyle
        />
      </BaseScreen>
    </>
  );
}

export default ConfectionResult;
