export const checkIfArraysAreDifferent = (firstArray: string[] | number[], secondArray: string[] | number[]) => {
  if (firstArray?.length !== secondArray?.length) {
    return false;
  }

  const sortedArr1 = firstArray.sort();
  const sortedArr2 = secondArray.sort();

  for (let i = 0; i < sortedArr1.length; i += 1) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
};
