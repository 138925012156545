const card: React.CSSProperties = {
  border: `0`,
  borderRadius: `12px`,
  background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23565E71FF' stroke-width='1' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  alignItems: 'normal',
};

const cardSection: React.CSSProperties = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '112px',
  padding: '12px 12px 8px 12px',
};

export { card, cardSection };
