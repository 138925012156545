import { Concept } from '@interfaces/api';
import { INITIAL_STATE_TRANSFER_MODIFY } from '@views/Modify/constants';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { AuthorizationState, EXCEPTION_CODES, RetentionState, SUPPLIERS, THIRD_PARTY_PAYMENTS, TRANSFER_TYPE_CODE } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { DataTransferModify } from '../Interfaces/hooks/IUseModifyTransferDrawer';
import { Holiday, LotTransfer, SelectOption } from '../Interfaces/transferSetup';
import {
  getConcepts,
  getDocumentsToPay,
  getParamsHolidays,
  getTransfersDetail,
  getTransferTypes,
  getTypesRetention,
  getUsageReferences,
} from '../services/api';
import useFirstRender from './useFirstRender';
import { setTransferDetailsHelper } from './useTransferDetailHelper';

function useTransfersDetail(
  initialOrdenId: number,
  transferType: string,
  loadCreditBanks: () => void,
  setCreditUsageReferences: React.Dispatch<React.SetStateAction<string[]>>,
  loadDebitBanks: () => void,
  loadDebitAccounts: () => void,
  setDebitUsageReferences: React.Dispatch<React.SetStateAction<string[]>>,
  codeTransferType: string,
  isErrorFetchCreditBanks: boolean,
  isErrorFetchDebitBanks: boolean,
  isErrorFetchDebitAccounts: boolean,
  isErrorFetchCreditAccounts: boolean,
  loadCreditAccounts: () => void,
  statusGetDebitAccounts: boolean,
  statusGetCreditAccounts: boolean,
  statusGetDebitBanks: boolean,
  setStatusButtonConfirm: (arg: boolean) => void,
  setStatusButtonDiscardChange: (arg: boolean) => void,
  setDiscardChange: (arg: boolean) => void,
  discardChange: boolean,
) {
  const { setOtpConfigured } = useTransfersContext();

  const [ordenId, setOrdenId] = useState<number>(initialOrdenId);
  const [transferSelected, setTransferSelected] = useState<LotTransfer | null>(null);
  const [isTERProccess, setIsTERProccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [firstPartRequests, setFirstPartRequests] = useState({
    statusGetTransfersDetail: false,
    statusGetHolidays: false,
    statusGetConcepts: false,
    statusGetTypesRetention: false,
    statusGetDocumentsToPay: false,
    statusGetUsageReferences: false,
    statusGetTransferTypes: false,
  });
  const [, setUsageReferences] = useState<{ debitUsagesReference: string[]; creditUsagesReference: string[] }>({
    debitUsagesReference: [],
    creditUsagesReference: [],
  });
  const [transferCode, setTransferCode] = useState<string>('false');
  const [isSuppliersProcess, setIsSuppliersProcess] = useState(false);
  const [amountRetention, setAmountRetention] = useState<string | undefined>(undefined);
  const [initialCurrency, setInitialCurrency] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState({ creditAccountCbu: '', debitAccountCbu: '' });
  const [transferDetails, setTransferDetails] = useState<DataTransferModify>(INITIAL_STATE_TRANSFER_MODIFY);
  const [holiday, setHoliday] = useState<Holiday[] | null>([]);
  const [transfersTypeByOperators, setTransfersTypeByOperators] = useState<number>(0);
  const [retentionTypeListOptions, setRetentionTypeListOptions] = useState<SelectOption[]>([]);
  const [documentToPayListOptions, setDocumentToPayListOptions] = useState<SelectOption[]>([]);
  const [conceptsListOptions, setConceptsListOptions] = useState<SelectOption[]>([]);
  const [conceptsList, setConceptsList] = useState<Concept[]>([]);
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState<boolean>(false);
  const [disclaimerText, setDisclaimerText] = useState<string>('');
  const skipOnFirstRenderAmount = useFirstRender();
  const skipOnFirstRenderRetention = useFirstRender();
  const skipOnFirstRenderCreditNote = useFirstRender();
  const params = {
    isModify: true,
  };

  const { isError: isErrorTransferDetail, refetch: refetchGetTransferDetail } = useQuery(
    ['get-transfers-detail', ordenId],
    () => getTransfersDetail({ ordenId, params }).then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        setTransferDetailsHelper(data?.data?.transfer, setTransferDetails, setIsDisclaimerChecked, setDisclaimerText);
        setSelectedAccounts({
          creditAccountCbu: data?.data?.transfer?.creditAccount?.cbu,
          debitAccountCbu: data?.data?.transfer?.debitAccount?.cbu,
        });
        setInitialCurrency(data?.data?.transfer?.debitAccount?.descriptionCurrency);
        setTransferCode(data?.data?.transfer?.idTransferType);
        setFirstPartRequests(prevState => ({ ...prevState, statusGetTransfersDetail: true }));
        if (discardChange === false) {
          fetchHolidays();
        } else {
          setDiscardChange(false);
        }
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  const { refetch: fetchHolidays, isError: isErrorFetchHolidays } = useQuery(
    ['get-params-holidays'],
    () => getParamsHolidays()?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setHoliday(data?.data.holidays);
          setFirstPartRequests(prevState => ({ ...prevState, statusGetHolidays: true }));
          fetchTransferTypesList();
        }
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  const { refetch: fetchTransferTypesList, isError: isErrorFetchTransferTypesList } = useQuery(
    ['tranfer-types-list'],
    () => getTransferTypes().then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const deferMaxDays = data?.data?.transferTypeByOperators.find(item => item.code === codeTransferType)?.deferMaxDays || 0;
          setTransfersTypeByOperators(deferMaxDays);
          setOtpConfigured(data?.data.otpConfigured);
          setFirstPartRequests(prevState => ({ ...prevState, statusGetTransferTypes: true }));

          if (transferCode === TRANSFER_TYPE_CODE.TER) {
            fetchConcepts();
          }
          if (transferCode === TRANSFER_TYPE_CODE.PRO) {
            fetchTypesRetention();
          }
        }
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  const { refetch: fetchConcepts, isError: isErrorFetchConcepts } = useQuery(['get-concepts'], () => getConcepts()?.then(res => res.data), {
    enabled: false,
    retry: 3,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.success) {
        const { totalThirdPartyConcepts } = data?.data || {};
        const options: SelectOption[] = totalThirdPartyConcepts?.map(conceptItem => ({
          value: conceptItem?.conceptCode,
          label: conceptItem?.description,
        }));
        setConceptsList(totalThirdPartyConcepts);
        setConceptsListOptions(options);
        setFirstPartRequests(prevState => ({ ...prevState, statusGetConcepts: true }));
      }
    },
    onError: () => {
      setIsError(true);
    },
  });

  const { refetch: fetchTypesRetention, isError: isErrorFetchTypesRetention } = useQuery(
    ['get-types-retention'],
    () => getTypesRetention(RetentionState.ENABLED, AuthorizationState.V)?.then(res => res.data),
    {
      enabled: false,
      retry: 3,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const { retentionTypeView } = data.data || {};
          const options: SelectOption[] = retentionTypeView?.map(retentionItem => ({
            value: String(retentionItem?.code),
            label: retentionItem?.description,
          }));
          setRetentionTypeListOptions(options);
          setFirstPartRequests(prevState => ({ ...prevState, statusGetTypesRetention: true }));
          if (transferCode === TRANSFER_TYPE_CODE.PRO) {
            fetchDocumentsToPay();
          }
        }
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  const { refetch: fetchDocumentsToPay, isError: isErrorFetchDocumentsToPay } = useQuery(
    ['get-documents-to-pay'],
    () => getDocumentsToPay()?.then(res => res.data),
    {
      enabled: false,
      retry: 3,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const { documentTypeCancels } = data.data || {};
          const options: SelectOption[] = documentTypeCancels?.map(documentItem => ({
            value: String(documentItem?.code),
            label: documentItem?.description,
          }));
          setDocumentToPayListOptions(options);
          setFirstPartRequests(prevState => ({ ...prevState, statusGetDocumentsToPay: true }));
        }
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  const { refetch: fetchUsageReferences, isError: isErrorFetchUsageReferences } = useQuery(
    ['get-usage-references', codeTransferType],
    () => getUsageReferences(codeTransferType)?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setUsageReferences(data?.data);
          setDebitUsageReferences(data?.data?.debitUsagesReference);
          setCreditUsageReferences(data?.data?.creditUsagesReference);
        }
        setFirstPartRequests(prevState => ({ ...prevState, statusGetUsageReferences: true }));
        loadDebitBanks();
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  useEffect(() => {
    setTransferProcess(transferType);
    refetchGetTransferDetail();
  }, [refetchGetTransferDetail, ordenId]);

  useEffect(() => {
    if (firstPartRequests.statusGetTransferTypes && transferCode !== TRANSFER_TYPE_CODE.PRO && transferCode !== TRANSFER_TYPE_CODE.TER) {
      fetchUsageReferences();
    }
    if (firstPartRequests.statusGetConcepts || firstPartRequests.statusGetDocumentsToPay) {
      fetchUsageReferences();
    }
  }, [firstPartRequests.statusGetConcepts, firstPartRequests.statusGetDocumentsToPay, firstPartRequests.statusGetTransferTypes]);

  useEffect(() => {
    if (statusGetDebitBanks) {
      loadDebitAccounts();
    }
  }, [statusGetDebitBanks]);

  useEffect(() => {
    if (statusGetDebitAccounts) {
      loadCreditAccounts();
    }
  }, [statusGetDebitAccounts]);

  useEffect(() => {
    if (statusGetCreditAccounts) {
      loadCreditBanks();
    }
  }, [statusGetCreditAccounts]);

  function setTransferProcess(transferTypeValue: string) {
    if (transferTypeValue === THIRD_PARTY_PAYMENTS) {
      setIsTERProccess(true);
      setIsSuppliersProcess(false);
    } else if (transferTypeValue === SUPPLIERS) {
      setIsTERProccess(false);
      setIsSuppliersProcess(true);
    } else {
      setIsTERProccess(false);
      setIsSuppliersProcess(false);
    }
  }

  const onClickRetry = () => {
    const retryConditions = [
      { condition: isErrorTransferDetail, action: refetchGetTransferDetail },
      { condition: isErrorFetchHolidays, action: fetchHolidays },
      { condition: isErrorFetchTransferTypesList, action: fetchTransferTypesList },
      { condition: isErrorFetchConcepts, action: fetchConcepts },
      { condition: isErrorFetchTypesRetention, action: fetchTypesRetention },
      { condition: isErrorFetchDocumentsToPay, action: fetchDocumentsToPay },
      { condition: isErrorFetchUsageReferences, action: fetchUsageReferences },
      { condition: isErrorFetchCreditBanks, action: loadCreditBanks },
      { condition: isErrorFetchDebitBanks, action: loadDebitBanks },
      { condition: isErrorFetchDebitAccounts, action: loadDebitAccounts },
      { condition: isErrorFetchCreditAccounts, action: loadCreditAccounts },
    ];

    const retryItem = retryConditions.find(item => item.condition);

    if (retryItem) {
      retryItem.action();
    }
    setIsError(false);
  };

  const {
    statusGetTransfersDetail,
    statusGetHolidays,
    statusGetTransferTypes,
    statusGetConcepts,
    statusGetTypesRetention,
    statusGetUsageReferences,
    statusGetDocumentsToPay,
  } = firstPartRequests;

  const handleOnChangeConcepto = (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      const conceptSelected = conceptsList?.find(conceptItem => conceptItem.conceptCode === selectedOption.value);
      setDisclaimerText(conceptSelected?.disclaimerText || '');

      setTransferDetails(prevState => ({
        ...prevState,
        concept: selectedOption,
      }));

      setTransferDetails(prevState => ({
        ...prevState,
        disclaimerChecked: false,
      }));

      if (conceptSelected?.disclaimer === 'Y') {
        setStatusButtonConfirm(true);
        setStatusButtonDiscardChange(false);
      } else {
        setStatusButtonConfirm(false);
        setStatusButtonDiscardChange(false);
      }
    }
  };

  const handleOnchangeAmount = (transferAmount: string) => {
    skipOnFirstRenderAmount(() => {
      setTransferDetails({ ...transferDetails, transferAmount });
      const cleanedAmount = transferAmount.trim().replace(/[,]/g, '').replace(/^0+/, '');
      if (transferAmount === '0,00' || transferAmount === '0') {
        setStatusButtonConfirm(true);
        setStatusButtonDiscardChange(false);
      } else if (cleanedAmount === '') {
        setStatusButtonConfirm(true);
        setStatusButtonDiscardChange(false);
      } else {
        updateButtonStatus();
      }
    });
  };

  useEffect(() => {
    setTransferDetails({
      ...transferDetails,
      addendaProviders: { ...transferDetails.addendaProviders, amountRetention },
    });
  }, [amountRetention]);

  const handleOnchangeTotalRetentionAmount = (valueAmountRetention: string) => {
    skipOnFirstRenderRetention(() => {
      setAmountRetention(valueAmountRetention);
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
    });
  };

  const handleOnchangeCreditNoteAmount = (amountNoteCred: string) => {
    skipOnFirstRenderCreditNote(() => {
      setTransferDetails({
        ...transferDetails,
        addendaProviders: { ...transferDetails.addendaProviders, amountNoteCred },
      });
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
    });
  };

  const handleOnchangeDisclaimer = (disclaimerChecked: boolean) => {
    setTransferDetails({ ...transferDetails, disclaimerChecked });
    if (disclaimerChecked) {
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
    } else {
      setStatusButtonConfirm(true);
      setStatusButtonDiscardChange(false);
    }
  };

  const handleOnchangeRetentionType = (typeRetentionId: SelectOption | null) => {
    setTransferDetails({
      ...transferDetails,
      addendaProviders: { ...transferDetails.addendaProviders, typeRetentionId: typeRetentionId || undefined },
    });
    if (transferDetails.addendaProviders.typeRetentionId?.value) {
      setStatusButtonConfirm(true);
      setStatusButtonDiscardChange(true);
    }
    if (transferDetails.addendaProviders.typeRetentionId?.value !== 'undefined' || typeRetentionId) {
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
    }
  };

  const handleOnchangeDocument = (typeDocId: SelectOption | null) => {
    setTransferDetails({
      ...transferDetails,
      addendaProviders: { ...transferDetails.addendaProviders, typeDocId: typeDocId || undefined },
    });
    if (transferDetails.addendaProviders.typeDocId?.value) {
      setStatusButtonConfirm(true);
      setStatusButtonDiscardChange(true);
    }
    if (transferDetails.addendaProviders.typeDocId?.value !== 'undefined' || typeDocId) {
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
    }
  };

  const shouldEnableConfirmButton = () =>
    transferCode === TRANSFER_TYPE_CODE.TER && !transferDetails.disclaimerChecked && disclaimerText !== '';

  const updateButtonStatus = () => {
    if (shouldEnableConfirmButton()) {
      setStatusButtonConfirm(true);
      setStatusButtonDiscardChange(false);
    } else {
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
    }
  };

  return {
    refetchGetTransferDetail,
    transferSelected,
    setTransferSelected,
    ordenId,
    setOrdenId,
    transferDetails,
    setTransferDetails,
    isTERProccess,
    isSuppliersProcess,
    selectedAccounts,
    disclaimerText,
    isDisclaimerChecked,
    onClickRetry,
    statusGetTransfersDetail,
    statusGetHolidays,
    statusGetTransferTypes,
    statusGetConcepts,
    statusGetTypesRetention,
    statusGetUsageReferences,
    statusGetDocumentsToPay,
    transferCode,
    holiday,
    transfersTypeByOperators,
    conceptsListOptions,
    retentionTypeListOptions,
    documentToPayListOptions,
    setDisclaimerText,
    handleOnChangeConcepto,
    handleOnchangeAmount,
    handleOnchangeTotalRetentionAmount,
    handleOnchangeCreditNoteAmount,
    handleOnchangeDisclaimer,
    handleOnchangeRetentionType,
    handleOnchangeDocument,
    isError,
    updateButtonStatus,
    initialCurrency,
    setFirstPartRequests,
  };
}

export default useTransfersDetail;
