import { useTransfersContext } from '@context/TransfersContext';
import { routesNames } from '@src/constants-app';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function RoutesActions() {
  const { pathname } = useLocation();
  const { clearConfection } = useTransfersContext();

  useEffect(() => {
    if (!pathname.startsWith(routesNames.TransferSetup)) {
      clearConfection();
    }

    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default RoutesActions;
