const ALERT_ERROR_CHILDREN_TEXTS = {
  macKeys:
    'Claves MAC no asociadas a Clave Empresa. Asociá tus claves MAC de los bancos que faltan a tu Clave Empresa desde la app Interbanking.',
  totalTransfers: (totalTransferByMessage: number) => {
    if (totalTransferByMessage === 1) {
      return `Cantidad: ${totalTransferByMessage} transferencia no se envió`;
    }
    return `Cantidad: ${totalTransferByMessage} transferencias sin enviar`;
  },
  totalTransfersTexts: (totalTransferByMessage: number) => {
    const plural = totalTransferByMessage > 1 ? 'Claves' : 'Clave';
    return `${plural} MAC no asociada a Clave Empresa. Asociá tu Clave Mac de `;
  },
};

export default ALERT_ERROR_CHILDREN_TEXTS;
