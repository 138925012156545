import { colors } from '../../styled/generalStyles';

const label = {
  fontSize: '13px',
  fontWeight: 'normal',
  fontFamily: 'Inter',
  marginBottom: 0,
  color: colors.Grey120,
  lineHeight: 'normal',
  'span.chakra-form__required-indicator': {
    color: colors.Grey120,
  },
};

export { label };
