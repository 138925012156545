import { Box, Text } from '@chakra-ui/react';

import { ResultItem } from '../../../../Interfaces/transfers';
import { TEXTS } from '../../constants';
import ResultIcon from '../ResultIcon';
import { CustomBox, withBorder } from './index.styled';

interface Props {
  item: ResultItem;
  index: number;
}

function ResultListItem({ item, index }: Props) {
  return (
    <CustomBox sx={index === 0 ? {} : withBorder}>
      <ResultIcon icon={item.error.icon} />
      <Box flex={1} marginLeft='10px'>
        <Text textStyle='h6'>{item.error.description}</Text>
      </Box>
      <Text textStyle='bodyMd'>{TEXTS.transfers(item.count)}</Text>
    </CustomBox>
  );
}

export default ResultListItem;
