/* eslint-disable import/no-unresolved */
import { Box, TabList, Text, Tooltip } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';
import { Dispatch } from 'react';

import CustomTab from '../CustomTabs';
import { CustomMargin, CustomTooltip } from './styled';

interface Props {
  isAuthenticated: boolean;
  setClaveMac: Dispatch<React.SetStateAction<boolean>>;
}

function Tab({ isAuthenticated, setClaveMac }: Props) {
  const clickTab = (isClaveMac: boolean) => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion Clave MAC',
      item_id: isClaveMac ? 'Clave MAC' : 'Clave Empresa',
    });
    setClaveMac(isClaveMac);
  };
  return (
    <TabList>
      {!isAuthenticated ? (
        <Tooltip
          sx={CustomTooltip}
          hasArrow
          placement='right'
          label={
            <Box display='flex' flexDirection='column'>
              <Box>
                <Text as='span' textStyle='bodySm' sx={CustomMargin}>
                  {'Es una clave única que se crea en la '}
                </Text>
                <Text as='span' sx={CustomMargin} textStyle='bodySmSemi'>
                  app
                </Text>
              </Box>
              <Box>
                <Text as='span' textStyle='bodySmSemi' sx={CustomMargin}>
                  {'Interbanking '}
                </Text>
                <Text as='span' textStyle='bodySm' sx={CustomMargin}>
                  y almacena las claves MAC
                </Text>
              </Box>
              <Text as='span' textStyle='bodySm' sx={CustomMargin}>
                para enviar transferencias más rápido.
              </Text>
            </Box>
          }
        >
          <Box as='span'>
            <CustomTab isDisabled>Clave Empresa</CustomTab>
          </Box>
        </Tooltip>
      ) : (
        <span>
          <CustomTab onClick={() => clickTab(false)}>Clave Empresa</CustomTab>
        </span>
      )}
      <span>
        <CustomTab onClick={() => clickTab(true)}>Clave Mac</CustomTab>
      </span>
    </TabList>
  );
}

export default Tab;
