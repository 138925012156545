const TEXTS = {
  status: {
    inProgress: 'En curso',
    success: 'Éxito',
    error: 'Error',
    pending: 'Por confeccionar',
    partial: 'Resultado parcial',
  },
  confectionNumber: 'CONFECCIÓN N°',
  inProgress: 'Estamos confeccionando las transferencias.',
  success: (lot: number) => `Confeccionamos las transferencias en el lote ${lot ? `${lot} ` : ''}con éxito.`,
  error: 'No podemos confeccionar las transferencias.',
  partial: 'No podemos confeccionar algunas transferencias.',
  pending: 'No podemos confeccionar todas las transferencias.',
  invalidDate: 'Hay transferencias con fecha de solicitud no válida. Revisá el detalle para cambiar la fecha o descartarlas.',
  serviceError: 'No podemos confeccionar las transferencias. Volvé a intentarlo más tarde',
  lastUpdate: 'Última actualización',
  linkText: 'Ver resultado',
  linkTextDetail: 'Ver detalle',
};

const TEST_IDS = {
  processesCard: 'processesCard-',
};

export { TEST_IDS, TEXTS };
