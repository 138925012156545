import { IconButton } from '@chakra-ui/react';
import { ChevronUp } from 'react-feather';

import { CustomIconButton } from './index.styled';

interface Props {
  handleUp: () => void;
}

function IconButtonGoUp({ handleUp }: Props) {
  return (
    <IconButton
      sx={CustomIconButton}
      aria-label='go up Icon'
      icon={<ChevronUp />}
      id='icon-button-id'
      onClick={handleUp}
      size='i-md'
      variant='icon'
    />
  );
}

export default IconButtonGoUp;
