import { colors } from '../../../../styled/generalStyles';

const CustomBox = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '72px',
  padding: '16px 20px 20px 16px',
};

const CheckContainer = {
  maxHeight: '226px',
  flexDirection: 'column',
  overflowY: 'auto',
  width: '100%',
};

const CustomCheckBox = {
  marginTop: '12px',
  marginLeft: '16px',
};

const CustomText = {
  margin: '0px',
  marginLeft: '8px',
  fontSize: '14px',
};

const CustomEmptyText = {
  marginTop: '0px',
  marginLeft: '16px',
  marginBottom: '13px',
  fontSize: '14px',
};

const CustomInput = {
  borderRadius: '4px',
  border: `1px solid ${colors.Neutral90}`,
  background: colors.White,
};

export { CheckContainer, CustomBox, CustomCheckBox, CustomEmptyText, CustomInput, CustomText };
