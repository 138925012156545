import { Box, SystemStyleObject, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../../../styled/generalStyles';

const CustomBox = styled(Box)`
  border-bottom: solid 1px ${colors.Grey60};
  display: flex;
  height: 86px;
  justify-content: space-between;
  padding: 0px 24px 0px 16px;
`;

const LotBox = styled(Box)`
  min-width: 126px;
`;

const DebitAccountBox = styled(Box)`
  min-width: 300px;
  width: 300px;
`;

const CreditAccountBox = styled(Box)`
  min-width: 300px;
  width: 300px;
`;

const InporteBox = styled(Box)`
  min-width: 150px;
  width: 150px;
`;

const DateBox = styled(Box)`
  min-width: 130px;
`;

const PaddingBox: SystemStyleObject = {
  padding: '12px 0px 12px 0px',
};

const TextImporte: SystemStyleObject = {
  textAlign: 'end',
};

const TextSeparation: SystemStyleObject = {
  margin: '0px 5px',
};

const CustomText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CustomBoxAccount = styled(Box)`
  display: flex;
`;

export {
  CreditAccountBox,
  CustomBox,
  CustomBoxAccount,
  CustomText,
  DateBox,
  DebitAccountBox,
  InporteBox,
  LotBox,
  PaddingBox,
  TextImporte,
  TextSeparation,
};
