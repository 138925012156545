import * as React from 'react';

function IconBeware() {
  return (
    <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.09663 67.45C5.52705 67.4536 4.96681 67.3053 4.47357 67.0204C3.98033 66.7356 3.5719 66.3244 3.29033 65.8293C3.00206 65.3378 2.8501 64.7784 2.8501 64.2086C2.8501 63.6388 3.00206 63.0794 3.29033 62.5879L35.1932 7.33021C35.7784 6.31561 36.8272 5.70951 37.9995 5.70951C39.1718 5.70951 40.2206 6.31561 40.8058 7.33021L72.7087 62.5879C72.997 63.0794 73.149 63.6388 73.149 64.2086C73.149 64.7784 72.997 65.3378 72.7087 65.8293C72.1235 66.8439 71.0747 67.45 69.9024 67.45H6.09663Z'
        fill='#FFD7B0'
      />
      <path
        d='M37.9996 6.66141C38.3967 6.66141 39.3866 6.77351 39.9832 7.80711L71.8861 63.0648C72.0912 63.4118 72.1994 63.8074 72.1994 64.2105C72.1994 64.6136 72.0912 65.0092 71.8861 65.3562C71.6866 65.6982 71.0957 66.5 69.9025 66.5H6.09671C4.90351 66.5 4.31071 65.6982 4.11311 65.3543C3.90801 65.0073 3.79981 64.6117 3.79981 64.2086C3.79981 63.8055 3.90801 63.4099 4.11311 63.0629L36.016 7.80711C36.2136 7.45585 36.5019 7.16417 36.8509 6.96261C37.1999 6.76105 37.5966 6.65702 37.9996 6.66141ZM37.9996 4.76141C36.5879 4.76141 35.1762 5.46061 34.3706 6.85711L2.46771 62.1129C0.854606 64.9078 2.87051 68.4 6.09671 68.4H69.9025C73.1287 68.4 75.1446 64.9078 73.5315 62.1129L41.6286 6.85711C41.2642 6.21705 40.736 5.68555 40.0982 5.31724C39.4604 4.94892 38.7361 4.75707 37.9996 4.76141Z'
        fill='#BC5D00'
      />
      <path
        d='M37.9994 54.7276C37.701 54.7276 37.4055 54.7864 37.1298 54.9006C36.8541 55.0148 36.6036 55.1822 36.3926 55.3932C36.1816 55.6042 36.0142 55.8547 35.9 56.1304C35.7858 56.4061 35.7271 56.7016 35.7271 57C35.7271 57.2984 35.7858 57.5939 35.9 57.8696C36.0142 58.1453 36.1816 58.3958 36.3926 58.6068C36.6036 58.8178 36.8541 58.9852 37.1298 59.0994C37.4055 59.2136 37.701 59.2724 37.9994 59.2724C38.2979 59.2724 38.5934 59.2136 38.8691 59.0994C39.1448 58.9852 39.3953 58.8178 39.6063 58.6068C39.8173 58.3958 39.9847 58.1453 40.0989 57.8696C40.2131 57.5939 40.2718 57.2984 40.2718 57C40.2718 56.7016 40.2131 56.4061 40.0989 56.1304C39.9847 55.8547 39.8173 55.6042 39.6063 55.3932C39.3953 55.1822 39.1448 55.0148 38.8691 54.9006C38.5934 54.7864 38.2979 54.7276 37.9994 54.7276ZM36.0994 28.5H39.8994V51.3H36.0994V28.5Z'
        fill='#FF7D00'
      />
    </svg>
  );
}
export default IconBeware;
