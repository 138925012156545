import { Text } from '@chakra-ui/react';

import { AccountsNotAvailable, ManualTransferTexts, SelectOption } from '../../../../Interfaces/transferSetup';

const infobox = 'Al terminar la confección, las personas con permisos deberán autorizar y enviar las transferencias.';

const transferTypeOptions: SelectOption[] = [
  { value: 'ctaPropias', label: 'Cta. Propias' },
  { value: 'btoB', label: 'BtoB' },
  { value: 'proveedores', label: 'Proveedores' },
  { value: 'sueldos', label: 'Sueldos' },
  { value: 'pagosTerceros', label: 'Pagos Terceros' },
];

const texts = {
  transferTypeLabel: 'Tipo de transferencia',
  chooseTypePlaceholder: 'Elegí el tipo',
  requestDateLabel: 'Fecha de solicitud',
  requestDateError: (value: number) => `Por el tipo de transferencia, la fecha debe ser menor a ${value} día${value > 1 ? 's' : ''}`,
  descriptionLabel: 'Descripción',
  descriptionPlaceholder: 'Escribí una descripción para identificar las transferencias',
  paymentNumberLabel: 'Número de pago',
  paymentNumberPlaceholder: 'Escribí el número que la empresa usará para identificar el pago',
  batchConfigurationTitle: 'Configuración del lote',
  consolidatedLabel: 'Consolidado en extracto',
  unifiedShippingLabel: 'Envío unificado',
  mandatoryDataNotice: '*Estos datos son obligatorios.',
  transferTypeChangedTitle: '¿Querés cambiar el tipo de transferencia?',
  transferTypeChangedText: 'Al hacerlo, eliminaremos las transferencias agregadas al lote y tendrás que empezar la confección de nuevo.',
  btnCancel: 'Cancelar',
  btnConfirmChange: 'Sí, cambiar',
  warningOtpTitle: 'Solo podés confeccionar las transferencias que no requieren Token',
  warningOtpDescription: 'Descargá la app Interbanking en tu celular y vinculá tu cuenta para activar el Token.',
};

const textsError = {
  title: 'La información no está disponible en este momento',
  description: 'Estamos trabajando para solucionarlo.',
  link: 'Reintentar',
};

const consolidatedTooltipText = (
  <>
    Verás la información del lote consolidada en
    <br />
    una sola línea en tus movimientos y <br />
    extractos. Por esto, el envío deberá ser
    <br />
    unificado.
  </>
);

const shippingTooltipText = (
  <>
    Tendrás que enviar todas las transferencias <br />
    del lote al mismo tiempo.
  </>
);

const InputFields = {
  transferType: 'transferType',
  description: 'description',
  paymentNumber: 'paymentNumber',
  requestDate: 'requestDate',
  inputDatePicker: 'inputDatePicker',
};

const checkboxesFields = {
  consolidated: 'consolidated',
  unifiedShipping: 'unifiedShipping',
};

const FROM_FILE: ManualTransferTexts = {
  title: '¿Cómo confeccionar transferencias?',
  subtitle: {
    text: 'La confección desde un archivo .txt te permite cargar transferencias de manera masiva. Seguí estos pasos para confeccionar transferencias:',
    bold: 'archivo .txt',
  },
  steps: [
    {
      stepNumber: 1,
      description: 'Elegir tipo de transferencia a confeccionar y completá los datos del lote que te solicitamos.',
      bold: 'Elegir tipo de transferencia',
    },
    {
      stepNumber: 2,
      description:
        'Cargar archivo .txt con las transferencias. En el paso 2 te dejamos una plantilla que podés descargar para usar de guía y evitar errores.',
      bold: 'Cargar archivo .txt',
    },
  ],
  infobox: 'Es importante que mantengas esta estructura de archivos para importar transferencias',
  infoboxBold: 'esta estructura',
};

const TestIds = {
  form: 'manual-transfer-form-step1',
};

const modalTexts = {
  title: 'Te redireccionaremos a la versión\nanterior de Interbanking',
  subtitle:
    'Por el momento la confección BtoB está en la versión anterior, estamos\n trabajando para actualizarla y mejorar tu experiencia.',
  thirdDescription: 'Para que podamos redireccionarte, no debés tener ventanas emergentes bloqueadas.',
  btnConfirm: 'Continuar',
  btnCancel: 'Cancelar',
};

const NOT_AVAILABLE_MODAL_TEXTS = {
  ok: 'Entendido',
  [AccountsNotAvailable.both]: {
    title: 'Tenés que asociar al menos una cuenta\nde débito y de crédito',
    description: 'Para este tipo de transferencia no hay cuentas asociadas.\nCambiá el tipo de transferencia o configuralo desde ABM.',
  },
  [AccountsNotAvailable.debit]: {
    title: 'Tenés que asociar al menos una cuenta\nde débito',
    description:
      'Para este tipo de transferencia no hay una cuenta de débito asociada.\nCambiá el tipo de transferencia o configuralo desde ABM.',
  },
  [AccountsNotAvailable.credit]: {
    title: 'Tenés que asociar al menos una cuenta\nde crédito',
    description:
      'Para este tipo de transferencia no hay una cuenta de crédito asociada.\nCambiá el tipo de transferencia o configuralo desde ABM.',
  },
  [AccountsNotAvailable.unique]: {
    title: 'Revisá las cuentas disponibles',
    description:
      'Para este tipo de transferencia, tenés asociada solo 1 cuenta de débito y\n1 cuenta de crédito. Cambiá el tipo de transferencia o revisá en ABM que\nlas cuentas cargadas sean diferentes y tengan la misma moneda.',
  },
};

const WIZARD_IDS = {
  step1: 'mc-wizard-step1',
  step1Attempts: 'mc-wizard-step1-attempts',
};

const WIZARD_WELCOME_TEXTS = {
  title: '¡Renovamos la\nconfección de\ntransferencias!',
  description: 'Explorá el nuevo diseño y conocé cómo funciona.',
  cancelButton: 'En otro momento',
  exploreButton: 'Explorar',
};

const WIZARD_STEPS = [
  {
    title: 'Elegí cómo confeccionar',
    description: 'Según tus permisos podrás ver distintas opciones: de modo manual, desde archivo o desde modelo. ',
    target: 'tabs',
    placement: 'top',
    offsetExtraX: 1,
    offsetExtraY: -10,
  },
  {
    title: 'Completá la información del lote',
    description: (
      <Text
        as='span'
        dangerouslySetInnerHTML={{ __html: 'Ingresá los datos del lote que te solicitamos y después hacé clic en <b>Continuar</b>.' }}
      />
    ),
    target: TestIds.form,
    placement: 'left',
    offsetExtraX: 12,
    offsetExtraY: 24,
  },
];

export {
  checkboxesFields,
  consolidatedTooltipText,
  FROM_FILE,
  infobox,
  InputFields,
  modalTexts,
  NOT_AVAILABLE_MODAL_TEXTS,
  shippingTooltipText,
  TestIds,
  texts,
  textsError,
  transferTypeOptions,
  WIZARD_IDS,
  WIZARD_STEPS,
  WIZARD_WELCOME_TEXTS,
};
