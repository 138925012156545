import { Box, Checkbox, Flex, Skeleton, Text, Tooltip } from '@chakra-ui/react';
import { colors } from '@styled/generalStyles';
import { DollarSign, HelpCircle, Layers } from 'react-feather';

import { addAnimationSkeletonfadeIn, withOutAnimationSkeletonfadeIn } from '../../styled/global.styled';
import { checkboxesFields } from '../../views/TransferSetup/Panels/ManualSetupTransfers/constants';
import {
  checkboxBoxStyles,
  checkboxContainerStyles,
  customSkeletonCircle,
  customSkeletonFirstCheckbox,
  helpCircleStyle,
  iconBoxStyles,
} from './index.styled';
import { ConsolidatedShippingCheckboxesProps } from './interfaces';

function ConsolidatedShippingCheckboxes({
  formState,
  handleCheckboxChange,
  consolidatedLabel,
  unifiedShippingLabel,
  consolidatedTooltipText,
  shippingTooltipText,
  status,
}: ConsolidatedShippingCheckboxesProps) {
  const animation = status ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn;

  return (
    <Flex sx={checkboxContainerStyles}>
      <Flex sx={checkboxBoxStyles}>
        {status ? (
          <>
            <Checkbox
              name={checkboxesFields.consolidated}
              isChecked={formState.consolidated}
              onChange={handleCheckboxChange}
              sx={animation}
            >
              <Flex alignItems='center'>
                <Box height='20px' margin='0 5px 0 3px'>
                  <DollarSign data-testid='dollar-icon' size='20px' color={colors.Secondary100} />
                </Box>
                <Text as='div' textStyle='labelMd'>
                  {consolidatedLabel}
                </Text>
              </Flex>
            </Checkbox>
            <Tooltip label={consolidatedTooltipText} hasArrow placement='bottom'>
              <HelpCircle style={{ ...helpCircleStyle, ...animation }} />
            </Tooltip>
          </>
        ) : (
          <>
            <Skeleton style={customSkeletonCircle} />
            <Skeleton style={customSkeletonFirstCheckbox} />
          </>
        )}
      </Flex>
      <Flex sx={checkboxBoxStyles}>
        {status ? (
          <>
            <Checkbox
              name={checkboxesFields.unifiedShipping}
              isChecked={formState.unifiedShipping}
              onChange={handleCheckboxChange}
              isDisabled={formState.consolidated}
              sx={animation}
            >
              <Flex alignItems='center'>
                <Box height='20px' margin='0 8px 0 5px'>
                  <Layers data-testid='layers-icon' size='20px' color={colors.Secondary100} />
                </Box>
                <Text as='div' textStyle='labelMd'>
                  {unifiedShippingLabel}
                </Text>
              </Flex>
            </Checkbox>
            <Box sx={iconBoxStyles}>
              <Tooltip label={shippingTooltipText} hasArrow placement='bottom'>
                <HelpCircle style={{ ...helpCircleStyle, ...animation }} />
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <Skeleton style={customSkeletonCircle} />
            <Skeleton style={customSkeletonFirstCheckbox} />
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default ConsolidatedShippingCheckboxes;
