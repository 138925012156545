import { Flex, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import BankLogo from '@components/BankLogo';
import { ResultStatus } from '@interfaces/transferSetup';
import { formatAmount } from '@utils/formatAmount';
import { IconButtonComponent } from '@wow/button';
import { format, parseISO } from 'date-fns';
import { ZoomIn } from 'react-feather';

import { testIds, texts } from '../../constants';
import { formatAccountData, formatAccountName } from './helpers';
import { Container } from './index.styled';
import { Props } from './interfaces';

function ResultItem({ data, index, status, handleGoToDetail }: Props) {
  return (
    <Tr sx={Container} data-testid={`${testIds.resultItem}${index + 1}`}>
      <Td width={status === ResultStatus.validated ? '48%' : '44%'}>
        <Flex direction='row' alignItems='center'>
          <BankLogo src={data.debitAccount?.bank.bcoIdBco} transferType={data.transferType} />
          <Flex direction='column'>
            <Text as='span' textStyle='h5Bold'>
              {data.debitAccount?.bank.bankName}
            </Text>
            <Text as='span' textStyle='bodyMd'>
              {formatAccountName(data.debitAccount?.accountName, data.debitAccount?.cuit)}
              <br />
              {formatAccountData(data.debitAccount?.cbu, data.debitAccount?.accountType)}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td width='16%'>
        <Flex direction='column'>
          <Text as='span' textStyle='labelLgBold'>
            {format(parseISO(data.date), 'dd/MM/yyyy')}
          </Text>
          <Text as='span' textStyle='bodyMd'>
            {texts.date}
          </Text>
        </Flex>
      </Td>
      <Td width='16%'>
        <Flex direction='column'>
          <Text as='span' textStyle='labelLgBold'>
            {texts.transfers(data.totalItems)}
          </Text>
        </Flex>
      </Td>
      <Td width='20%'>
        <Flex direction='column'>
          <Text as='span' textStyle='labelLgBold'>
            {formatAmount(data.currency, data.totalAmount, '')}
          </Text>
          <Text as='span' textStyle='bodyMd'>
            {texts.total}
          </Text>
        </Flex>
      </Td>
      {status === ResultStatus.accepted && (
        <Td width='4%' textAlign='right'>
          <IconButtonComponent
            aria-label='icon-button'
            variant='icon-text'
            size='i-md'
            onClick={() => handleGoToDetail(data)}
            icon={
              <Tooltip hasArrow label={texts.viewDetail} placement='top' marginLeft='-4px' marginBottom='8px'>
                <ZoomIn />
              </Tooltip>
            }
          />
        </Td>
      )}
    </Tr>
  );
}

export default ResultItem;
