import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

import { colors } from '../../../../styled/generalStyles';

const drawerHeaderStyles = {
  marginTop: '36px',
  marginLeft: '6px',
};

const headerTextStyle = {
  margin: '0px 0px 20px 0px',
};

const CloseButton = {
  background: colors.White,
  border: 'none',
  color: colors.Grey100,
  cursor: 'pointer',
  fontSize: '14px;',
  padding: '0px',
  height: 'auto',
  position: 'relative',
  width: '16px',
  marginBottom: '8px',
  svg: {
    width: '16px',
  },

  '&:active': {
    background: 'none',
  },

  '&:hover': {
    background: 'none',
  },

  '&:focus': {
    background: 'none',
    boxShadow: colors.ShadowNone,
  },
};

const packageStyle = {
  width: '14px',
  height: '14px',
  color: colors.Neutral90,
  marginRight: '4px',
};

const flexStyles = {
  alignItems: 'center',
  marginLeft: '8px',
  marginRight: '4px',
  marginTop: '10px',
};

const infoStyle = {
  width: '14px',
  height: '14px',
  color: colors.Neutral90,
  marginRight: '4px',
};

const flexEndStyle = {
  justifyContent: 'flex-end',
};

const CustomBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: '48px';
`;

export { CloseButton, CustomBox, drawerHeaderStyles, flexEndStyle, flexStyles, headerTextStyle, infoStyle, packageStyle };
