import { Box, Button, Text } from '@chakra-ui/react';
import { ChevronLeft, ChevronRight, Info, ShoppingBag } from 'react-feather';

import { ARS_CURRENCY, CURRENCY_DOLAR, CURRENCY_PESOS } from '../../constants-app';
import { decimalFormat } from '../../utils/FormatChange';
import TotalAmount from './components/TotalAmount';
import { InterfacesPropsFooter } from './interfaces';
import {
  buttonMarginStyles,
  CustomBox,
  CustomBoxFooter,
  CustomBoxImporte,
  CustomButton,
  CustomButtonCancel,
  CustomTextSeparator,
} from './styled';

function Footer({
  handleGoBack,
  onSubmit,
  statusButtom,
  textButton,
  secondTextButton,
  isActivebutton,
  handleSendTransfersAgain,
  totalAmountPartialReject = [],
  isActivebuttonCancel,
  thirdTextButton,
  statusThirdButtom,
  handleThirdButton,
  showNoText,
  fourthTextButton,
  isActivebuttonFourth,
  step,
  handleFourthButton,
}: InterfacesPropsFooter) {
  return (
    <CustomBoxFooter>
      <CustomBox>
        {!isActivebuttonCancel && !!handleGoBack && (
          <Button variant='primary-text' size='md' onClick={handleGoBack} leftIcon={<ChevronLeft width='20px' />}>
            Volver
          </Button>
        )}
        {!showNoText && isActivebutton && (
          <>
            <Text as='span' textStyle='labelLgBold'>
              Resumen transferencias por autorizar
            </Text>
            <CustomBoxImporte>
              {totalAmountPartialReject.map((item, index) => (
                <Box key={item.currencyType} display='flex'>
                  <TotalAmount
                    icon={<Info />}
                    value={item.totalTransfers}
                    text={`${item.totalTransfers > 1 ? 'transferencias' : 'transferencia'}`}
                  />
                  <TotalAmount
                    icon={<ShoppingBag />}
                    value={
                      item.amount === 0
                        ? `${item.currencyType === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR} ***`
                        : `${item.currencyType === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR} ${decimalFormat(item.amount)}`
                    }
                  />
                  {index < totalAmountPartialReject.length - 1 && <CustomTextSeparator textStyle='titleSm'>|</CustomTextSeparator>}
                </Box>
              ))}
            </CustomBoxImporte>
          </>
        )}
      </CustomBox>
      <Box>
        {isActivebuttonCancel && (
          <CustomButtonCancel
            variant='primary-outline'
            size='md'
            type='submit'
            onClick={handleThirdButton}
            isDisabled={statusThirdButtom}
            rightIcon={<ChevronRight width='20px' />}
          >
            {thirdTextButton}
          </CustomButtonCancel>
        )}
        {!showNoText && (
          <Button
            variant={isActivebutton ? 'primary-outline' : 'primary'}
            size='md'
            type='submit'
            onClick={onSubmit}
            isDisabled={statusButtom}
            rightIcon={<ChevronRight width='20px' />}
          >
            {textButton}
          </Button>
        )}
        {showNoText && (
          <Text as='span' sx={buttonMarginStyles}>
            Paso {step || 1} de 3
          </Text>
        )}
        {isActivebuttonFourth && (
          <CustomButton
            variant='primary-outline'
            size='md'
            type='submit'
            onClick={handleFourthButton}
            rightIcon={showNoText ? undefined : <ChevronRight width='20px' />}
          >
            {fourthTextButton}
          </CustomButton>
        )}
        {isActivebutton && (
          <CustomButton
            variant='primary'
            size='md'
            type='submit'
            onClick={handleSendTransfersAgain || onSubmit}
            isDisabled={statusButtom}
            rightIcon={showNoText ? undefined : <ChevronRight width='20px' />}
          >
            {secondTextButton}
          </CustomButton>
        )}
      </Box>
    </CustomBoxFooter>
  );
}

export default Footer;
