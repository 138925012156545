/* eslint-disable no-shadow */
export const TITLE_TOOLTIP_UNIFIED_SHIPPING = 'Lote con envío unificado:';
export const TEXT_TOOLTIP_UNIFIED_SHIPPING = 'Si tiene más de\nuna cuenta de débito, envía todos los lotes\njuntos.';

export const ALL_CODE_STATUS_TRANSFERS = {
  DPD: 'DPD',
};

export const ALL_STATUS_TRANSFERS = {
  [ALL_CODE_STATUS_TRANSFERS.DPD]: 'Recibida por la red',
};

export const DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH = 400;

export const TEXT_LOADING_REVOKE = {
  title: 'Estamos revocando el envío',
  description: 'Esto puede demorar unos instantes.',
};

export const TEXT_ERROR_REVOKE = {
  title: 'No podemos revocar el envío',
  description: '¡No te preocupes! Las transferencias seleccionadas se mantienen, solo<br>tenés que volver a intentarlo.',
};

export const TEXT_ERROR_ZERO = {
  title: 'No podemos revocar el envío',
  description: 'Las transferencias ya no están disponibles, revisá el estado en la sección<br><strong>Reportes.</strong>',
};

export const TEXT_SUCCESS = {
  title: 'Revocaste el envío',
};

export const TEXT_MODAL_ASYNC = {
  title: 'Revocando el envío',
  description:
    'Esto puede demorar unos minutos.<br>Si necesitás seguir operando, volvé a la sección <strong>Transferencias.</strong><br>No te preocupes, el proceso seguirá en curso.',
};

export const TEXT_MODAL_ASYNC_DONE = {
  title: 'Estamos revocando el envío',
  description: 'Cuando finalice podrás verificar si todo se procesó correctamente desde la sección <strong>Reportes</strong>.',
};

export const TEXT_MODAL_ASYNC_ERROR = {
  title: 'Algo no salió como esperábamos',
  description: 'Para verificar si se revocó el envío de las transferencias, revisá el estado<br>en la sección <strong>Reportes</strong>.',
};

export const TEXT_BUTTONS = {
  ok: 'Entendido',
  retry: 'Reintentar',
  cancel: 'Cancelar',
  revoke: 'Revocar envío',
  backToTransfers: 'Volver a Transferencias',
};

export const EXCEPTION_CODES = {
  success: 'TEF-0000',
  partialRejection: 'TEF-0004',
  totalRejection: 'TEF-0005',
  asyncProcess: 'TEF-0008',
  timeOut: 'TEF-0015',
};

export enum REVOKE_ACTION {
  NONE = 'NONE',
  GET_DETAILS = 'GET_DETAILS',
  REVOKE = 'REVOKE',
}

export enum REVOKE_ERROR {
  NONE = 'NONE',
  REVOKE = 'REVOKE',
  ZERO = 'ZERO',
}

export const CONFIRMATION_REVOKE_MODAL_TEXT = {
  title: '¿Querés revocar el envío de las transferencias?',
  firstTextButton: 'Cancelar',
  secondTextButton: 'Si, revocar',
  altIcon: 'icon-unifiedshipping-modal',
  description: 'Si revocás el envío, las transferencias quedarán en estado <b>"autorizada."</b>',
};
