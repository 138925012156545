import {
  amountRangesValuesDefault,
  filtersAppliedDrawerDefault,
  filtersValuesDefault,
  orderAppliedValueDefault,
  orderPropertyLotDetail,
  orderPropertyValueDefault,
} from '../constants-app';
import { ContextState } from '../Interfaces/contextTypes';
import { TransfersFlow } from '../Interfaces/transfers';

const initialState: ContextState = {
  pageContext: 1,
  sizeContext: 15,
  lotSelectionHandler: {},
  lotsSelected: [],
  isFullSelection: [],
  dataLot: [],
  inputLot: [],
  filtered: filtersValuesDefault,
  detailLotId: '',
  bankCodes: [],
  responseOfTransferSelectedByUser: [],
  dataDetailsTransfers: [],
  dataTotalsTransfers: [],
  transfersRejected: [],
  transfersAccepted: [],
  isAuthenticated: false,
  servicesWorking: true,
  detailParams: {
    lot: '',
    date: '',
    bank: '',
    status: '',
    amount: 0,
    transfersFlow: TransfersFlow.Authorize,
    confection: false,
  },
  panelSelected: 0,
  hasNextTefDay: false,
  isBusinessDay: false,
  hasData: false,
  orderPropertyContext: orderPropertyValueDefault,
  orderAppliedContext: orderAppliedValueDefault,
  orderPropertyLotDetailContext: orderPropertyLotDetail,
  selectedTransfersTypesCodes: [],
  sectionPermissions: [],
  viewPartialRejecteAuth: false,
  transferTypeAuthReadyToSend: [],
  otp: 'Y',
  errorUseAuthPartial: false,
  totalAmountPartialReject: [],
  loadingPartialReject: false,
  manualTransferData: {
    transferType: '',
    requestDate: null,
    description: '',
    paymentNumber: '',
    consolidated: false,
    unifiedShipping: false,
    hasDraft: null,
    transfers: [],
    step: 1,
    accountsNotAvailable: null,
  },
  fileSelected: {
    rowId: '',
    fileId: '',
    fileName: '',
    dateTime: '',
  },
  transferTypeOptionSelected: {
    calendarDefer: 0,
    deferMaxDays: 0,
    code: '',
    description: '',
  },
  transferTypeByOperators: [],
  dateSelected: null,
  amountRanges: amountRangesValuesDefault,
  debitAccounts: [],
  holidays: [],
  workingDay: null,
  transferNumber: '',
  confectionData: {
    status: false,
    operationId: '',
    processId: 0,
    acceptedTransfers: [],
    rejectedTransfers: [],
    rejectedTransferDetails: [],
    validateTransfers: [],
  },
  confectionReset: false,
  isExitConfectionModalOpen: false,
  redirectPath: '',
  otpConfigured: true,
  asyncProcesses: null,
  asyncProcessesLoading: false,
  asyncProcessesError: false,
  updateAsyncProcesses: false,
  showAsyncProcesses: false,
  isReferenceActive: false,
  debitFiltersApplied: filtersAppliedDrawerDefault,
  creditFiltersApplied: filtersAppliedDrawerDefault,
  statusButtonModify: false,
  isLoadingLots: false,
};

export default initialState;
