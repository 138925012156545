import { Box, Button, Text } from '@chakra-ui/react';
import { Card, CardSection } from '@wow/card';

import { TestIds, texts } from './constants';
import { card, cardSection } from './index.styled';

function CardAccountError({ onClickRetry }: { onClickRetry: () => void }) {
  return (
    <Card data-testid={TestIds.cardAccountError} style={card}>
      <CardSection style={cardSection}>
        <Box>
          <Text as='span' textStyle='titleMdBold'>
            {texts.errorTitle}
          </Text>
        </Box>
        <Box>
          <Text as='span' textStyle='bodyMd'>
            {texts.errorDescription}
          </Text>
        </Box>
        <Button variant='primary-text' size='lg' onClick={onClickRetry} data-testid={TestIds.btnRetry}>
          {texts.retry}
        </Button>
      </CardSection>
    </Card>
  );
}

export default CardAccountError;
