import { Box, DrawerBody, Flex, Stack, Text } from '@chakra-ui/react';
import AdditionalInformationForm from '@components/AdditionalInformationForm';
import SearchAccounts from '@components/SearchAccounts';
import { useTransfersContext } from '@context/TransfersContext';
import { AccountingType, Currencies } from '@interfaces/transfers';
import { AlertComponent, AlertDescription } from '@wow/alert';
import { format, parse } from 'date-fns';
import { useEffect, useState } from 'react';

import CardAccount from '../../../../../components/CardAccount';
import { CardAccountMode } from '../../../../../components/CardAccount/constants';
import IconDisconnected from '../../../../../components/Icons/icon-disconnected';
import SkeletonFormDrawerBody from '../../../../../components/SkeletonFormDrawerModify/Body';
import TransferDataSection from '../../../../../components/TransferDataSection';
import ViewNoResult from '../../../../../components/ViewNoResult';
import { CURRENCY_PESOS, DataMessageErrorRequest, TRANSFER_TYPE_CODE } from '../../../../../constants-app';
import useTransfersDetail from '../../../../../hooks/useModifyDrawer';
import { useTransferCredit } from '../../../../../hooks/useTransferCredit';
import { useTransferDebit } from '../../../../../hooks/useTransferDebit';
import useVisibility from '../../../../../hooks/useVisibility';
import { LotTransfer } from '../../../../../Interfaces/transferSetup';
import { texts } from '../../../../TransferSetup/Steps/Step2/constants';
import { MODIFY_DRAWER_TEXTS } from '../constants';
import { CustomBox } from '../styled';
import { Props } from './interfaces';
import { alertComponentStyles, creditInfoTextStyles, textAdenddaStyles } from './styled';

function ModifyDrawerBody({
  transferNumber,
  transferType,
  handleToggleDrawerRetry = () => '',
  codeTransferType,
  transferCurrency,
  setStatusButtonConfirm,
  setTransferData,
  setDebitCurrencyChanged,
  setHandleChangeDebitCurrency,
  discardChange,
  setDiscardChange,
  setStatusButtonDiscardChange,
}: Props) {
  const { visibility, toggleVisibility, setVisibility } = useVisibility();
  const { setStatusButtonModify, statusButtonModify } = useTransfersContext();
  const [transferSelected, setTransferSelected] = useState<LotTransfer | null>(null);
  const [initialDebitCurrency, setInitialDebitCurrency] = useState('');
  const [creditAccountNumberSelected, setCreditAccountNumberSelected] = useState<string>('');
  const [debitAccountNumberSelected, setDebitAccountNumberSelected] = useState<string>('');

  const {
    creditAccountSelected,
    loadCreditBanks,
    setCreditAccountSelected,
    setCreditAccountSelectedId,
    setCreditBanksCurrencyARS,
    setCreditBanksCurrencyUSD,
    setCreditCurrencySelected,
    changeCreditBanksCurrency,
    resetCreditAccountFilters,
    setCreditUsageReferences,
    isErrorFetchCreditBanks,
    statusGetCreditBanks,
    creditAccountsFilteredLoading,
    handleSearchCreditAccount,
    setCreditFilterData,
    creditAccountsFiltered,
    creditAccountsFilteredError,
    creditFilterData,
    creditAccountSelectedId,
    handleSelectCreditAccount,
    handleRemoveSelectedCreditAccount,
    setStatusGetCreditBanks,
    loadCreditAccounts,
    isErrorFetchCreditAccounts,
    statusGetCreditAccounts,
    creditAccounts
  } = useTransferCredit({ transferSelected, setTransferSelected, codeTransferType, debitAccountNumberSelected });
  const {
    debitAccountSelected,
    isErrorFetchDebitBanks,
    isErrorFetchDebitAccounts,
    statusGetDebitAccounts,
    statusGetDebitBanks,
    loadDebitBanks,
    loadDebitAccounts,
    setDebitUsageReferences,
    handleSearchDebitAccount,
    setDebitFilterData,
    debitFilterData,
    debitCurrencyChanged,
    debitAccountsFilteredLoading,
    handleChangeDebitCurrency,
    shouldShowFilterAccounts,
    debitAccountsFilteredError,
    debitAccountsFiltered,
    debitAccountSelectedId,
    handleSelectDebitAccount,
    handleRemoveSelectedDebitAccount,
    changedCurrency,
    setChangedCurrency,
    setStatusGetDebitBanks,
    fetchDebitBanks,
    setStatusGetDebitAccounts,
    debitAccounts,
  } = useTransferDebit({
    transferSelected,
    creditAccountSelected,
    setTransferSelected,
    changeCreditBanksCurrency,
    setCreditCurrencySelected,
    setCreditBanksCurrencyARS,
    setCreditBanksCurrencyUSD,
    resetCreditAccountFilters,
    setCreditAccountSelected,
    setCreditAccountSelectedId,
    codeTransferType,
    currencySelected: transferCurrency,
    isDrawer: true,
    initialDebitCurrency,
    discardChange,
    creditAccountNumberSelected,
  });
  const {
    transferDetails,
    setTransferDetails,
    isTERProccess,
    onClickRetry,
    statusGetTransfersDetail,
    statusGetHolidays,
    statusGetTransferTypes,
    statusGetConcepts,
    statusGetTypesRetention,
    statusGetUsageReferences,
    statusGetDocumentsToPay,
    transferCode,
    holiday,
    transfersTypeByOperators,
    conceptsListOptions,
    disclaimerText,
    isSuppliersProcess,
    retentionTypeListOptions,
    documentToPayListOptions,
    handleOnChangeConcepto,
    handleOnchangeAmount,
    handleOnchangeTotalRetentionAmount,
    handleOnchangeCreditNoteAmount,
    handleOnchangeDisclaimer,
    handleOnchangeRetentionType,
    handleOnchangeDocument,
    isError,
    updateButtonStatus,
    selectedAccounts,
    initialCurrency,
    refetchGetTransferDetail,
    setFirstPartRequests,
  } = useTransfersDetail(
    transferNumber,
    transferType,
    loadCreditBanks,
    setCreditUsageReferences,
    loadDebitBanks,
    loadDebitAccounts,
    setDebitUsageReferences,
    codeTransferType,
    isErrorFetchCreditBanks,
    isErrorFetchDebitBanks,
    isErrorFetchDebitAccounts,
    isErrorFetchCreditAccounts,
    loadCreditAccounts,
    statusGetDebitAccounts,
    statusGetCreditAccounts,
    statusGetDebitBanks,
    setStatusButtonConfirm,
    setStatusButtonDiscardChange,
    setDiscardChange,
    discardChange,
  );

  const handleResetDebitAccount = () => {
    handleRemoveSelectedDebitAccount();
    setDebitFilterData('');
  };

  const handleResetCreditAccount = () => {
    handleRemoveSelectedCreditAccount();
    setCreditFilterData('');
  };

  useEffect(() => {
    if (debitAccountSelected && debitAccounts.length > 1) {
      toggleVisibility('debitAccount');
      setTransferDetails({
        ...transferDetails,
        debitAccount: debitAccountSelected,
      });
      if (changedCurrency) {
        toggleVisibility('creditAccount');
        setChangedCurrency(false);
        setStatusButtonModify(true);
      } else {
        setStatusButtonModify(false);
      }
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
    }
  }, [debitAccountSelected]);

  useEffect(() => {
    if (creditAccountSelected && creditAccounts.length > 1) {
      toggleVisibility('creditAccount');
      setTransferDetails({
        ...transferDetails,
        creditAccount: creditAccountSelected,
      });
      setStatusButtonConfirm(false);
      setStatusButtonDiscardChange(false);
      setStatusButtonModify(false);
    }
  }, [creditAccountSelected]);

  useEffect(() => {
    if (shouldShowFilterAccounts && !visibility.creditAccount) {
      toggleVisibility('creditAccount');
    }
  }, [shouldShowFilterAccounts]);

  useEffect(() => {
    setDebitCurrencyChanged(debitCurrencyChanged);
    setHandleChangeDebitCurrency(() => handleChangeDebitCurrency);
  }, [debitCurrencyChanged]);

  useEffect(() => {
    setTransferData(transferDetails);
    setCreditAccountNumberSelected(transferDetails?.creditAccount?.identifier || '');
    setDebitAccountNumberSelected(transferDetails?.debitAccount?.identifier || '');
  }, [transferDetails]);

  useEffect(() => {
    setStatusButtonModify(false);
  }, [selectedAccounts]);

  useEffect(() => {
    if (initialCurrency) {
      setInitialDebitCurrency(initialCurrency === CURRENCY_PESOS ? Currencies.ARS : Currencies.USD);
    }
  }, [initialCurrency]);

  useEffect(() => {
    if (discardChange) {
      setFirstPartRequests(prevState => ({ ...prevState, statusGetTransfersDetail: false }));
      refetchGetTransferDetail();
      if (statusGetTransfersDetail) {
        setVisibility({
          debitAccount: false,
          creditAccount: false,
        });
        setStatusButtonModify(false);
        setStatusGetCreditBanks(false);
        setStatusGetDebitBanks(false);
        setStatusGetDebitAccounts(false);
        fetchDebitBanks();
        loadCreditBanks();
      }
    }
    if (statusGetCreditBanks && statusGetDebitBanks && statusGetDebitAccounts && statusGetTransfersDetail  && statusGetCreditAccounts) {
      setStatusButtonDiscardChange(true);
      setStatusButtonConfirm(true);
    }
  }, [discardChange, statusGetCreditBanks, statusGetDebitBanks, statusGetDebitAccounts, statusGetTransfersDetail,statusGetCreditAccounts]);

  useEffect(() => {
    if (visibility.debitAccount || visibility.creditAccount) {
      setStatusButtonConfirm(true);
    }
  }, [visibility, transferDetails, statusButtonModify]);

  if (isError || isErrorFetchDebitBanks || isErrorFetchDebitAccounts || isErrorFetchCreditBanks || isErrorFetchCreditAccounts) {
    return (
      <ViewNoResult
        title={DataMessageErrorRequest.title}
        description={DataMessageErrorRequest.description4}
        linkName={DataMessageErrorRequest.linkName}
        Icon={IconDisconnected}
        handleToggleClick={handleToggleDrawerRetry}
        onClickLink={onClickRetry}
        retryOtherLink
        iconDisconnectDrawer
      />
    );
  }

  const hasSingleDebitAccount = debitAccounts.length === 1;
  const hasSingleCreditAccount = creditAccounts.length === 1;

  const isMatchingAccount = hasSingleDebitAccount && transferDetails?.debitAccount?.cbu === debitAccounts[0].cbu;
  const matchesCredAccount = hasSingleCreditAccount && transferDetails?.creditAccount?.cbu === creditAccounts[0].cbu;

  const modee = hasSingleDebitAccount && isMatchingAccount ? CardAccountMode.unique : CardAccountMode.modifyTransferdrawer;
  const credModee = hasSingleCreditAccount && matchesCredAccount ? CardAccountMode.unique : CardAccountMode.modifyTransferdrawer;

  return (
    <DrawerBody>
      {!statusGetTransfersDetail ||
      (!statusGetHolidays && !statusGetTransferTypes) ||
      (transferCode === TRANSFER_TYPE_CODE.TER
        ? !statusGetConcepts
        : transferCode === TRANSFER_TYPE_CODE.PRO && !statusGetTypesRetention && !statusGetDocumentsToPay) ||
      !statusGetUsageReferences ||
      !statusGetDebitAccounts ||
      !statusGetCreditAccounts ||
      !statusGetDebitBanks ||
      !statusGetCreditBanks ? (
        <SkeletonFormDrawerBody title={transferType} />
      ) : (
        <Box padding='0px 32px'>
          <Stack spacing='24px'>
            <Text as='span' textStyle='titleMdBold'>
              {texts.debitAccount}
            </Text>
            {!visibility.debitAccount && transferDetails?.debitAccount && (
              <CardAccount
                account={transferDetails.debitAccount}
                transferType={transferDetails.debitAccount.accountType}
                selected
                mode={modee}
                onEditClick={() => {
                  toggleVisibility('debitAccount');
                  setStatusButtonDiscardChange(false);
                  setStatusButtonModify(true);
                  handleResetDebitAccount();
                }}
                accountingType={AccountingType.debit}
                statusButtonModify={statusButtonModify}
              />
            )}
            {visibility.debitAccount && (
              <SearchAccounts
                accountsError={debitAccountsFilteredError}
                accountingType={AccountingType.debit}
                transferType={transferType}
                searchValue={debitFilterData}
                accountsLoading={debitAccountsFilteredLoading}
                setSearchValue={setDebitFilterData}
                handleSearch={handleSearchDebitAccount}
                accounts={debitAccountsFiltered}
                accountSelectedId={debitAccountSelectedId}
                accountSelected={debitAccountSelected}
                handleSelectAccount={handleSelectDebitAccount}
                handleRemoveSelectedAccount={handleRemoveSelectedDebitAccount}
                showDropdown
                isModifyDrawer
              />
            )}
            <CustomBox marginTop='36px'>
              <Text as='span' textStyle='titleMdBold'>
                {texts.creditAccount}
              </Text>
            </CustomBox>
            {!visibility.creditAccount && transferDetails?.creditAccount && (
              <CardAccount
                account={transferDetails.creditAccount}
                transferType={transferDetails.creditAccount.accountType}
                selected
                mode={credModee}
                onEditClick={() => {
                  toggleVisibility('creditAccount');
                  setStatusButtonDiscardChange(false);
                  handleResetCreditAccount();
                  setStatusButtonModify(true);
                }}
                accountingType={AccountingType.credit}
                statusButtonModify={statusButtonModify}
              />
            )}
            {visibility.creditAccount && (
              <>
                <Text textStyle='bodyMd' style={creditInfoTextStyles}>
                  {MODIFY_DRAWER_TEXTS.creditInfoText}
                </Text>
                <AlertComponent width='100%' variant='information' sx={alertComponentStyles}>
                  <AlertDescription>{MODIFY_DRAWER_TEXTS.alertText1}</AlertDescription>
                </AlertComponent>
                <SearchAccounts
                  accountsError={creditAccountsFilteredError}
                  accounts={creditAccountsFiltered}
                  accountingType={AccountingType.credit}
                  transferType={transferType}
                  searchValue={creditFilterData}
                  accountsLoading={creditAccountsFilteredLoading}
                  setSearchValue={setCreditFilterData}
                  handleSearch={handleSearchCreditAccount}
                  accountSelectedId={creditAccountSelectedId}
                  accountSelected={creditAccountSelected}
                  handleSelectAccount={handleSelectCreditAccount}
                  handleRemoveSelectedAccount={handleRemoveSelectedCreditAccount}
                  showDropdown
                  isModifyDrawer
                />
              </>
            )}
            <Box>
              <Flex flexDirection='column' marginBottom='24px'>
                <Box marginBottom='24px' marginTop='24px'>
                  <Text as='span' textStyle='titleMdBold'>
                    {texts.transferAccount}
                  </Text>
                </Box>
                <TransferDataSection
                  amount={transferDetails?.transferAmount}
                  isDrawer
                  isLocalCurrency={transferDetails?.debitAccount?.currency === Currencies.ARS}
                  selectedDate={
                    transferDetails?.requestDateTransfer
                      ? parse(transferDetails?.requestDateTransfer, MODIFY_DRAWER_TEXTS.DateFormat, new Date())
                      : new Date()
                  }
                  maxDays={transfersTypeByOperators}
                  isLoadingComplete
                  onChangeDate={date => {
                    setTransferDetails({ ...transferDetails, requestDateTransfer: format(date, 'yyyy-MM-dd') });
                    updateButtonStatus();
                  }}
                  holidays={holiday}
                  onChangeObservationInput={requestRemarkTransfer => {
                    setTransferDetails({ ...transferDetails, requestRemarkTransfer });
                    updateButtonStatus();
                  }}
                  observation={transferDetails?.requestRemarkTransfer || ''}
                  onChangeAmountInput={transferAmount => handleOnchangeAmount(transferAmount)}
                  conceptOptions={conceptsListOptions}
                  concept={transferDetails?.concept}
                  onChangeConcept={concept => handleOnChangeConcepto(concept)}
                  reason={transferDetails.reasons}
                  onChangeReasonInput={reasons => {
                    setTransferDetails({ ...transferDetails, reasons });
                    updateButtonStatus();
                  }}
                  voucher={transferDetails.voucherCod}
                  onChangeVoucherInput={voucherCod => {
                    setTransferDetails({ ...transferDetails, voucherCod });
                    updateButtonStatus();
                  }}
                  isFullForm={isTERProccess}
                  disclaimerText={disclaimerText}
                  isDisclaimerChecked={transferDetails.disclaimerChecked}
                  onChangeDisclaimer={disclaimerChecked => handleOnchangeDisclaimer(disclaimerChecked)}
                />
              </Flex>
              <Text textStyle='bodyMdSemi' sx={textAdenddaStyles(isSuppliersProcess)}>
                {MODIFY_DRAWER_TEXTS.ObligatoryData}
              </Text>
              {isSuppliersProcess && (
                <Flex flexDirection='column' marginTop='0px' marginBottom='100px'>
                  <AdditionalInformationForm
                    retentionAmount={String(transferDetails?.addendaProviders?.amountRetention)}
                    retentionType={
                      transferDetails.addendaProviders.typeRetentionId?.value === 'undefined' ||
                      transferDetails.addendaProviders.typeRetentionId?.value === undefined
                        ? null
                        : transferDetails.addendaProviders.typeRetentionId
                    }
                    retentionOptions={retentionTypeListOptions}
                    isLoadingComplete
                    document={
                      transferDetails?.addendaProviders?.typeDocId?.value === 'undefined' ||
                      transferDetails.addendaProviders.typeDocId?.value === undefined
                        ? null
                        : transferDetails?.addendaProviders?.typeDocId
                    }
                    documentOptions={documentToPayListOptions}
                    creditNoteAmount={transferDetails?.addendaProviders?.amountNoteCred || ''}
                    creditNoteNumber={String(transferDetails?.addendaProviders?.numberNoteCredir || '')}
                    numberClient={String(transferDetails?.addendaProviders?.codClient)}
                    documentNumber={String(transferDetails?.addendaProviders?.numberDocCancel)}
                    paymentOrderType={transferDetails?.addendaProviders?.typeOrderPayment || ''}
                    paymentOrderNumber={transferDetails?.addendaProviders?.numberOrderPayment || ''}
                    onChangeNumberClient={codClient => {
                      setTransferDetails({
                        ...transferDetails,
                        addendaProviders: { ...transferDetails.addendaProviders, codClient },
                      });
                      setStatusButtonConfirm(false);
                      setStatusButtonDiscardChange(false);
                    }}
                    onChangeRetentionType={typeRetentionId => handleOnchangeRetentionType(typeRetentionId)}
                    onChangeDocument={typeDocId => handleOnchangeDocument(typeDocId)}
                    onChangeDocumentNumber={numberDocCancel => {
                      setTransferDetails({
                        ...transferDetails,
                        addendaProviders: { ...transferDetails.addendaProviders, numberDocCancel },
                      });
                      setStatusButtonConfirm(false);
                      setStatusButtonDiscardChange(false);
                    }}
                    onChangePaymentOrderType={typeOrderPayment => {
                      setTransferDetails({
                        ...transferDetails,
                        addendaProviders: { ...transferDetails.addendaProviders, typeOrderPayment },
                      });
                      setStatusButtonConfirm(false);
                      setStatusButtonDiscardChange(false);
                    }}
                    onChangePaymentOrderNumber={numberOrderPayment => {
                      setTransferDetails({
                        ...transferDetails,
                        addendaProviders: { ...transferDetails.addendaProviders, numberOrderPayment },
                      });
                      setStatusButtonConfirm(false);
                      setStatusButtonDiscardChange(false);
                    }}
                    onChangeCreditNoteNumber={numberNoteCredir => {
                      setTransferDetails({
                        ...transferDetails,
                        addendaProviders: {
                          ...transferDetails.addendaProviders,
                          numberNoteCredir,
                        },
                      });
                      setStatusButtonConfirm(false);
                      setStatusButtonDiscardChange(false);
                    }}
                    onChangeCreditNoteAmount={amountNoteCred => handleOnchangeCreditNoteAmount(amountNoteCred)}
                    onChangeTotalRetentionAmount={amountRetention => handleOnchangeTotalRetentionAmount(amountRetention)}
                  />
                </Flex>
              )}
            </Box>
          </Stack>
        </Box>
      )}
    </DrawerBody>
  );
}

export default ModifyDrawerBody;
