import { Box, Button, Text } from '@chakra-ui/react';
import IconBank2 from '@components/Icons/icon-bank2';
import { Card, CardSection } from '@wow/card';

import { TEST_IDS, TEXTS } from './constants';
import * as styles from './index.styled';
import { Props } from './interfaces';

function CardAccountEmpty({ accountingType, onClickSearch }: Props) {
  return (
    <Card data-testid={TEST_IDS.cardAccountEmpty} style={styles.card}>
      <CardSection style={styles.cardSection}>
        <Box margin='0 16px 0 12px'>
          <IconBank2 />
        </Box>
        <Box flex='1'>
          <Text as='span' textStyle='bodyMdSemi'>
            {TEXTS.title[accountingType]}
          </Text>
        </Box>
        <Box margin='0 8px'>
          <Button
            variant='primary-text'
            size='lg'
            onClick={onClickSearch}
            padding={0}
            data-testid={TEST_IDS.btnAccountType[accountingType]}
          >
            {TEXTS.btnSearch}
          </Button>
        </Box>
      </CardSection>
    </Card>
  );
}

export default CardAccountEmpty;
