/* eslint-disable import/no-unresolved */
import { Box, Button, Text } from '@chakra-ui/react';

import { filtersValuesDefault } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import useViewNoResultStyles from '../../hooks/useViewNoResultStyles';
import { ViewNoResultProps } from '../../Interfaces/transfers';
import { CustomDescription2Text } from './styled';
import { LinkConstants } from './ViewNoResultText';

function ViewNoResult({
  title,
  description,
  description2,
  linkName,
  Icon,
  onFilterLot,
  handleToggleClick = () => '',
  handleToggleDrawerRetry = () => '',
  retryOtherLink,
  nothingHere,
  onClickLink,
  drawerIcon,
  iconDisconnectDrawer,
  transferDetailDrawer,
  isConfection,
  isConfectionDrawer,
}: ViewNoResultProps) {
  const { setFiltered, setInputLot } = useTransfersContext();
  const handleClean = () => {
    handleToggleClick();
    setFiltered(filtersValuesDefault);
    setInputLot([]);
    if (onFilterLot) {
      onFilterLot([]);
    } else if (retryOtherLink) {
      handleToggleDrawerRetry();
    } else {
      window.location.reload();
    }
  };

  const styles = useViewNoResultStyles({
    iconDisconnectDrawer,
    drawerIcon,
    nothingHere,
    transferDetailDrawer,
    isConfection,
    isConfectionDrawer,
  });

  return (
    <Box sx={styles.containerStyle}>
      <Icon width={styles.iconWidth} height={styles.iconHeight} />
      <Text sx={styles.customTitleText} whiteSpace='pre-line'>
        {title}
      </Text>
      <Text sx={styles.customDescriptionText} whiteSpace='pre-line'>
        {description}
      </Text>
      {description2 && (
        <Text sx={CustomDescription2Text} whiteSpace='pre-line'>
          {description2}
        </Text>
      )}
      {linkName && (
        <Button
          variant={LinkConstants.variant}
          size={LinkConstants.size}
          data-testid={LinkConstants.datatestid}
          onClick={onClickLink || handleClean}
          sx={styles.retryLinkStyle}
        >
          {linkName}
        </Button>
      )}
    </Box>
  );
}

export default ViewNoResult;
