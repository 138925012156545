const texts = {
  emptyTitle: 'No hay resultados para tu búsqueda',
  emptyDescription:
    'Revisá los datos ingresados. También podés verificar si la cuenta está dada de alta o asociada al tipo de transferencia.',
  errorTitle: 'La información no está disponible en este momento',
  errorDescription: 'Estamos trabajando para solucionarlo, reintentá la búsqueda',
};

const TestIds = {
  cardAccountNoResult: 'cardAccountNoResult',
};

export { TestIds, texts };
