const applyCurrencyFormat = (amount: string): string => {
  if (!amount) return '';
  const inputString = amount.endsWith('.') ? `${amount.slice(0, -1)},` : amount;
  let formattedValue = inputString.replace(/[^\d,]/g, '');
  const [integerPart, decimalPart] = formattedValue.split(',');
  let formattedIntegerPart = integerPart.slice(0, 14);
  formattedIntegerPart = formattedIntegerPart && Number(formattedIntegerPart).toLocaleString('es-AR');
  formattedValue = formattedValue.includes(',') ? `${formattedIntegerPart},` : formattedIntegerPart;

  if (decimalPart) {
    formattedValue += decimalPart.slice(0, 2);
  }

  return formattedValue;
};

export { applyCurrencyFormat };
