import { TransferOperationDetails } from '../../../Interfaces/transfers';

const filterBanks = (dataDetailsTransfers: TransferOperationDetails[]) => {
  const uniqueBankCodes: string[] = [
    ...new Set<TransferOperationDetails['bankCode']>(dataDetailsTransfers.map((item: TransferOperationDetails) => item.bankCode)),
  ];
  const uniqueData: TransferOperationDetails[] = uniqueBankCodes.map(code => {
    return dataDetailsTransfers.filter((item: TransferOperationDetails) => item.bankCode === code)[0];
  });

  return uniqueData;
};

export default filterBanks;
