/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-shadow */
import { Box, FormControl, FormErrorMessage, IconButton, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { ChangeEvent, Dispatch, KeyboardEvent, useEffect, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';

import useShowPassword from '../../hooks/useShowPassword';
import { colors } from '../../styled/generalStyles';
import { CustomMarginText } from '../../styled/global.styled';
import BankLogo from '../BankLogo';
import { LOGO_DEFAULT, TEXT_ERROR_VALIDLAST_DIGIT, TEXT_INPUT_WITH_RESULT, TEXT_INPUT_WITHOUT_RESULT } from './constanst';
import { ContenBank, ContentIconButton, ContentListBank, CustomInput, CustomInputPassword } from './styled';

interface Props {
  bankName: string;
  bankCode: string;
  onPasswordChange: (bankCode: string, passwordd: string, bankName: string) => void;
  isValidlastDigit: boolean;
  send: boolean;
  setIsValidlastDigit: Dispatch<React.SetStateAction<boolean[]>>;
  setToDefault: boolean;
  isError?: boolean;
}

function PasswordBank({
  bankName,
  bankCode,
  onPasswordChange,
  isValidlastDigit = true,
  send,
  setIsValidlastDigit,
  setToDefault,
  isError,
}: Props) {
  const [textAlertPassword, setTextAlertPassword] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [inputState, setInputState] = useState(false);
  const { showPassword, hidePassword, onMouseLeave, show } = useShowPassword();
  const alertInput = textAlertPassword ? TEXT_INPUT_WITH_RESULT : TEXT_INPUT_WITHOUT_RESULT;
  const colorHelpTextInput = inputState ? colors.Error100 : '';
  const helpTextInput = inputState ? TEXT_ERROR_VALIDLAST_DIGIT : alertInput;

  const IconInput = show ? (
    <Eye color={inputState ? colors.Error100 : colors.Secondary100} />
  ) : (
    <EyeOff color={inputState ? colors.Error100 : colors.Secondary100} />
  );

  const validationLogo = bankCode || LOGO_DEFAULT;

  useEffect(() => {
    if (!setToDefault) return;
    setInputValue('');
    setIsInvalid(true);
  }, [setToDefault]);

  useEffect(() => {
    if (!isError) return;
    setInputValue('');
    setTextAlertPassword(false);
  }, [isError]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsInvalid(false);
    e.preventDefault();
    const valueInput = e.target.value;
    const transforText = valueInput.toUpperCase();
    setInputValue(transforText);
    setTextAlertPassword(transforText.length > 7);
    onPasswordChange(bankCode, transforText, bankName);
    const hasDeletedCharacter = valueInput.length < inputValue.length;

    if (hasDeletedCharacter) {
      setIsInvalid(true);
    }
  };

  const handleonKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'v' || e.key === 'V') {
      setTextAlertPassword(false);
      setIsInvalid(true);
    }
  };

  const resetValieErrorInput = () => {
    if (isInvalid) {
      setInputState(false);
    } else if (!isValidlastDigit) {
      setInputState(!isValidlastDigit);
    }
  };

  useEffect(() => {
    resetValieErrorInput();
    setIsValidlastDigit([]);
  }, [send, isInvalid]);

  return (
    <Box sx={ContentListBank}>
      <Box display='flex'>
        <BankLogo src={validationLogo} transferType='CUE' />
        <Box sx={ContenBank}>
          <Text sx={CustomMarginText} textStyle='h6'>
            {bankName}
          </Text>
          <Text sx={CustomMarginText} textStyle='bodyMd' color={colorHelpTextInput}>
            {helpTextInput}
          </Text>
        </Box>
      </Box>
      <InputGroup size='sm' sx={CustomInput}>
        <FormControl isInvalid={inputState}>
          <Input
            sx={CustomInputPassword}
            data-testid='input-password'
            name='password'
            type={show ? 'text' : 'password'}
            size='sm'
            onKeyDown={handleonKeyDown}
            onChange={handleChange}
            maxLength={8}
            value={inputValue}
          />
          <FormErrorMessage textStyle='labelSm'>Revisá tu clave</FormErrorMessage>
        </FormControl>
        <InputRightElement width='4.5rem' sx={ContentIconButton}>
          <IconButton
            data-testid='button-show-and-hide-password'
            aria-label='Button password'
            icon={IconInput}
            onMouseDown={showPassword}
            onMouseLeave={onMouseLeave}
            onMouseUp={hidePassword}
            size='sm'
            variant='i-text-light'
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}

export default PasswordBank;
