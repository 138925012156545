import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import { useRef } from 'react';

import BankLogo from '../../components/BankLogo';
import { LotDetail } from '../../Interfaces/transfers';
import { CustomMarginText } from '../../styled/global.styled';
import { CREDIT_ACCOUNT_TOOLTIP_MAX_WIDTH, TRANSFER_TYPE_AFI, TRANSFER_TYPE_B2B, TRANSFER_TYPE_PDS } from './constants';
import { Separator } from './index.styled';

function CreditAccountCell({ row }: CellContext<LotDetail, string>) {
  const elemText = useRef<HTMLInputElement | null>(null);
  const creditAccountElemTextWidth = elemText?.current?.clientWidth || '';
  const hasMaxLength = Number(creditAccountElemTextWidth) >= CREDIT_ACCOUNT_TOOLTIP_MAX_WIDTH;
  const tooltipLabel = hasMaxLength && (
    <Box>
      <Box>
        <Text as='span' textStyle='labelLgBold'>
          {row.original.creditAccount.bankName}
        </Text>
        {row.original.creditAccount.bankName && row.original.debitAccount.accountNumber && (
          <Text as='span' marginLeft='2px' textStyle='labelLgBold'>
            {' | '}
          </Text>
        )}
        <Text as='span' textStyle='labelLgBold'>
          {row.original.creditAccount.accountName}
        </Text>
        {row.original.creditAccount.accountName && (
          <Text as='span' marginLeft='2px' textStyle='labelLgBold'>
            {' | '}
          </Text>
        )}
        <Text as='span' textStyle='labelLg'>
          {row.original.creditAccount.cuit}
        </Text>
      </Box>
      <Box>
        <Text as='span' textStyle='labelLg'>
          {row.original.creditAccount.cbu || row.original.debitAccount.accountNumber}
        </Text>

        {(row.original.creditAccount.cbu || row.original.debitAccount.accountNumber) && (
          <Text as='span' textStyle='labelLgBold'>
            {' | '}
          </Text>
        )}
        <Text as='span' textStyle='labelLg'>
          {row.original.creditAccount.accountType}
        </Text>
      </Box>
    </Box>
  );

  return (
    <Box display='flex' align-items='center'>
      <Tooltip label={row.original.creditAccount.bankName} hasArrow placement='right'>
        <Flex alignItems='center'>
          <BankLogo
            style={{ maxWidth: '32px', width: '32px', height: '32px' }}
            src={row.original.creditAccount?.bankCodeBCRA}
            transferType={row.original.transferType}
          />
        </Flex>
      </Tooltip>
      <Flex alignItems='center'>
        {(row.original.transferType === TRANSFER_TYPE_PDS ||
          row.original.transferType === TRANSFER_TYPE_B2B ||
          row.original.transferType === TRANSFER_TYPE_AFI) && (
          <Text sx={CustomMarginText} textStyle='labelSmBold'>
            {row.original.creditAccount.bankName}
          </Text>
        )}
        {row.original.transferType !== TRANSFER_TYPE_AFI &&
          row.original.transferType !== TRANSFER_TYPE_B2B &&
          row.original.transferType !== TRANSFER_TYPE_PDS && (
            <Tooltip label={tooltipLabel} hasArrow placement='right'>
              <Box>
                <Box display='flex'>
                  <Text as='span' textStyle='labelSmBold'>
                    {row.original.creditAccount.bankName}
                  </Text>
                  {row.original.creditAccount.bankName && row.original.debitAccount.accountNumber && (
                    <Text as='span' sx={Separator} textStyle='labelSmBold'>
                      {' | '}
                    </Text>
                  )}
                  <Text ref={elemText} sx={CustomMarginText} textStyle='labelSmBold'>
                    {row.original.creditAccount.accountName}
                  </Text>
                  {row.original.creditAccount.accountName && (
                    <Text as='span' sx={Separator} textStyle='labelSmBold'>
                      {' | '}
                    </Text>
                  )}
                  {row.original.creditAccount.cuit && (
                    <Text as='span' textStyle='labelSmBold'>
                      {row.original.creditAccount.cuit}
                    </Text>
                  )}
                </Box>
                {(row.original.creditAccount.cbu || row.original.creditAccount.accountNumber || row.original.creditAccount.accountType) && (
                  <Box>
                    <Text as='span' textStyle='bodySm'>
                      {row.original.creditAccount.cbu || row.original.creditAccount.accountNumber}
                    </Text>
                    {(row.original.creditAccount.cbu || row.original.creditAccount.accountNumber) && (
                      <Text as='span' textStyle='bodySm'>
                        {' | '}
                      </Text>
                    )}
                    <Text as='span' textStyle='bodySm'>
                      {row.original.creditAccount.accountType}
                    </Text>
                  </Box>
                )}
              </Box>
            </Tooltip>
          )}
      </Flex>
    </Box>
  );
}

export default CreditAccountCell;
