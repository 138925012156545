import { Flex, Skeleton } from '@chakra-ui/react';

import { TEST_IDS } from '../../constants';
import * as styles from './index.styled';

function SummarySkeleton() {
  return (
    <Flex direction='column' data-testid={TEST_IDS.skeleton}>
      <Skeleton style={styles.Alert} />
      <Skeleton style={styles.Title} />
      <Flex sx={styles.BoxAccount}>
        <Flex direction='column' width='25%'>
          <Skeleton style={styles.Account1} />
          <Skeleton style={styles.Account2} />
          <Skeleton style={styles.Account3} />
        </Flex>
        <Flex width='5%' />
        <Flex direction='column' width='25%'>
          <Skeleton style={styles.Account1} />
          <Skeleton style={styles.Account2} />
          <Skeleton style={styles.Account3} />
        </Flex>
        <Flex width='5%' />
        <Flex direction='column' alignItems='flex-end' width='20%'>
          <Skeleton style={styles.Account4} />
          <Skeleton style={styles.Account5} />
        </Flex>
        <Flex width='5%' />
        <Flex direction='row-reverse' width='15%'>
          <Flex direction='column'>
            <Skeleton style={styles.Account6} />
            <Skeleton style={styles.Account7} />
          </Flex>
        </Flex>
      </Flex>
      <Skeleton style={styles.Divider} />
    </Flex>
  );
}

export default SummarySkeleton;
