import { useTransfersContext } from '@context/TransfersContext';
import { SERVICE_INTERVAL } from '@layouts/components/Processes/constants';
import { getMode, getStatusesCount, getView, hasProcessesInProgress } from '@layouts/components/Processes/helpers';
import { getAsyncProcesses } from '@services/api';
import { EXCEPTION_CODES, routesNames } from '@src/constants-app';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

export function useAsyncProcesses(isSubheader = true) {
  const location = useLocation();
  const {
    asyncProcesses,
    asyncProcessesLoading,
    asyncProcessesError,
    updateAsyncProcesses,
    showAsyncProcesses,
    manualTransferData,
    setAsyncProcesses,
    setUpdateAsyncProcesses,
    setAsyncProcessesLoading,
    setAsyncProcessesError,
  } = useTransfersContext();

  const isVisible =
    showAsyncProcesses &&
    (location.pathname === routesNames.Root || (location.pathname === routesNames.TransferSetup && manualTransferData.step === 1));

  const [offsetTop, setOffsetTop] = useState(0);

  const { refetch: fetchProcesses } = useQuery(['get-async-processes', isSubheader], () => getAsyncProcesses().then(res => res.data), {
    retry: false,
    enabled: isVisible && isSubheader,
    refetchInterval: response => (isVisible && hasProcessesInProgress(response?.data?.asynchronousProcess) ? SERVICE_INTERVAL : false),
    onSuccess: response => {
      if (response?.exception?.code === EXCEPTION_CODES.success) {
        setAsyncProcesses(response?.data?.asynchronousProcess);
      } else {
        setAsyncProcessesError(true);
      }
      setAsyncProcessesLoading(false);
    },
    onError: () => {
      setAsyncProcessesLoading(false);
      setAsyncProcessesError(true);
    },
  });

  const statusesCount = getStatusesCount(asyncProcesses ? asyncProcesses?.processes.map(item => item.status) : []);
  const mode = getMode(statusesCount);
  const view = getView(mode);

  const getProcesses = () => {
    setUpdateAsyncProcesses(false);
    setAsyncProcessesLoading(true);
    setAsyncProcessesError(false);
    fetchProcesses();
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const headerHeight = 124;
    const scrollTopLimit = 150;
    if (scrollTop < scrollTopLimit) {
      setOffsetTop(headerHeight);
    } else {
      setOffsetTop(headerHeight - (scrollTop - scrollTopLimit));
    }
  };

  return {
    location,
    isVisible,
    offsetTop,
    asyncProcesses,
    asyncProcessesLoading,
    asyncProcessesError,
    statusesCount,
    mode,
    view,
    updateAsyncProcesses,
    getProcesses,
    handleScroll,
  };
}
