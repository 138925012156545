import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { DatePickerWrapper } from '@wow/date-picker';
import { addDays, parse } from 'date-fns';
import { es } from 'date-fns/locale';
import { forwardRef, Ref, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar } from 'react-feather';

import { addAnimationSkeletonfadeIn, withOutAnimationSkeletonfadeIn } from '../../styled/global.styled';
import { InputFields } from '../../views/TransferSetup/Panels/ManualSetupTransfers/constants';
import { CalendarStyle, InputGroupDrawerStyles, InputGroupStyles, inputLeftElementStyle, inputStyle } from './index.styled';
import { CustomDatePickerProps, CustomInputProps } from './interfaces';

const CustomInput = forwardRef((props: CustomInputProps, ref: Ref<HTMLInputElement>) => {
  const { value, status, onClick, isDrawer } = props;
  const animation = status ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn;

  return (
    <InputGroup sx={{ ...(isDrawer ? InputGroupDrawerStyles : InputGroupStyles), ...animation }}>
      <InputLeftElement pointerEvents='none' sx={inputLeftElementStyle}>
        <Calendar style={CalendarStyle} />
      </InputLeftElement>
      <Input sx={inputStyle} placeholder='DD/MM/YYYY' value={value} onClick={onClick} ref={ref} name={InputFields.requestDate} isReadOnly />
    </InputGroup>
  );
});

function CustomDatePicker({ minDate, selectedDate, holidays, maxDays, status, onChangeDate, isDrawer }: CustomDatePickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const excludeDates = holidays ? holidays.map(item => parse(item.holidayDate, 'yyyy-MM-dd', new Date())) : [];

  return (
    <DatePickerWrapper>
      <DatePicker
        selected={selectedDate}
        onChange={onChangeDate}
        locale={es}
        customInput={<CustomInput status={status} {...(isDrawer && { isDrawer })} value='' onClick={() => null} ref={inputRef} />}
        formatWeekDay={(nameOfDay: string) => nameOfDay.substring(0, 1)}
        calendarStartDay={0}
        disabledKeyboardNavigation
        dateFormat='dd/MM/yyyy'
        minDate={minDate || new Date()}
        maxDate={addDays(minDate || new Date(), maxDays)}
        excludeDates={excludeDates}
      />
      {/* same input width */}
      <style>{`.react-datepicker__month { width: 261px; margin: 0.4rem 0 }`}</style>
    </DatePickerWrapper>
  );
}

export default CustomDatePicker;
