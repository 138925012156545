/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unreachable-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import packageJson from '../../../package.json';

interface MyCustomGlobal {
  appVersion: string;
}
declare global {
  interface Window {
    myGlobal: MyCustomGlobal;
  }
}

window.myGlobal = {
  appVersion: packageJson.version,
};

const { appVersion } = window.myGlobal;

const semverGreaterThan = (param1: string, param2: string): boolean => {
  return param1 !== param2;
};
interface Props {
  children: ({
    loading,
    isLatestVersion,
    refreshCacheAndReload,
  }: {
    loading: boolean;
    isLatestVersion: boolean;
    refreshCacheAndReload: () => void;
  }) => JSX.Element;
}

function CacheBuster({ children }: Props) {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  const refreshCacheAndReload = async () => {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
    }
    window.location.reload();
  };

  useEffect(() => {
    fetch(`${process.env.CLEAR_CACHE_BASE_URL}meta.json?${new Date().getTime()}`)
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version.replace('release-', '');
        const currentVersion = appVersion;

        // TODO: These console.log, it is here because I need to some do test about reset cache in every release
        console.log(latestVersion, 'latestVersion');
        console.log(currentVersion, 'currentVersion');

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          setLoading(false);
          setIsLatestVersion(true);
        } else {
          setLoading(true);
          setIsLatestVersion(false);
        }
        setLoading(false);
      });
  }, []);

  return children({ loading, isLatestVersion, refreshCacheAndReload });
}

export default CacheBuster;
