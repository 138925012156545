/* eslint-disable import/no-unresolved */
import { Box, Checkbox } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { pushAnalyticsEvent } from '@wow/utils';
import { ChangeEvent, useState } from 'react';

import IconBeware from '../../../../components/Icons/icon-beware';
import CustomModal from '../../../../components/Modal';
import { useTransfersContext } from '../../../../context/TransfersContext';
import { LotDetail, TransfersFlow } from '../../../../Interfaces/transfers';
import { TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION, UNIFIED_SHIPPING_MARK } from '../../constants';

interface Props {
  table: {
    resetRowSelection: () => void;
  };
  row: Row<LotDetail>;
}

export default function CheckboxCell({ row, table }: Props) {
  const { lotsSelected, setLotsSelected, detailLotId, detailParams, isFullSelection, setIsFullSelection } = useTransfersContext();
  const isSendFlow = detailParams.transfersFlow === TransfersFlow.Send;

  const updatedRows = lotsSelected?.filter(item => item.id !== detailLotId);
  const hasFullSelection = isFullSelection?.find((item: Record<string, boolean>) => item[detailLotId]);

  const handleRowCheckboxOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      pushAnalyticsEvent({
        event: 'select_content',
        content_type: 'Listado de transferencias',
        item_id: 'Seleccionar transferencia',
      });
    }
    if (hasFullSelection) {
      setIsEditingWithFullSelection(true);
    } else {
      row.getToggleSelectedHandler()(e);
    }
  };

  const handleCancelAction = () => {
    setIsEditingWithFullSelection(false);
  };

  const handleConfirmAction = () => {
    table.resetRowSelection();
    setIsEditingWithFullSelection(false);
    const rowSelected = updatedRows.map(updatedRowItem => ({
      [updatedRowItem.id]: true,
    }));
    setIsFullSelection(rowSelected);
    setLotsSelected(updatedRows);
  };
  const [isEditingWithFullSelection, setIsEditingWithFullSelection] = useState<boolean>(false);

  return (
    <Box className='px-1'>
      <Checkbox
        isChecked={row.getIsSelected()}
        onChange={handleRowCheckboxOnChange}
        isIndeterminate={row.getIsSomeSelected()}
        isDisabled={row.original.unifiedShippingMark === UNIFIED_SHIPPING_MARK && isSendFlow}
      />
      <CustomModal
        isOpen={isEditingWithFullSelection}
        onCancel={setIsEditingWithFullSelection}
        Icon={IconBeware}
        altIcon={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.altIcon}
        title={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.title}
        firstDescription={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.firstDescription}
        firstTextButton={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.firstTextButton}
        secondTextButton={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.secondTextButton}
        enableFirstButton
        enableSecondButton
        alternativeButtonStyling
        actionButton={handleCancelAction}
        actionButton2={handleConfirmAction}
        size='xl'
      />
    </Box>
  );
}
