import { Flex as ChakraFlex, Link as ChakraLink, Text as ChakraText } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const FlexContainer = styled(ChakraFlex)`
  height: calc(80vh - 96px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonLinkContainer = styled(ChakraFlex)`
  align-items: center;
  margin-top: 16px;
  gap: 16px;
`;

const Link = styled(ChakraLink)`
  color: ${colors.Primary100};
  text-underline-offset: 0.2rem;
`;

const TextTitle = styled(ChakraText)`
  padding: 8px;
`;

const TextDescription = styled(ChakraText)`
  padding-bottom: 16px;
  gap: 10px;
  text-align: center;
  white-space: pre-line;
`;

export { ButtonLinkContainer, FlexContainer, Link, TextDescription, TextTitle };
