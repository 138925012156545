import { Box, Button, Flex, FormControl, Skeleton, Text } from '@chakra-ui/react';
import { AccountingType, Paging } from '@interfaces/transfers';
import { Input } from '@wow/input';
import { ChangeEvent, useEffect, useState } from 'react';

import { BankAccount } from '../../Interfaces/api';
import CardAccount from '../CardAccount';
import { CardAccountMode } from '../CardAccount/constants';
import CardAccountNoResult from '../CardAccountNoResult';
import CardAccountSkeleton from '../CardAccountSkeleton';
import { FIELDS, MAX_LENGTH, PAGE_SIZE, TEST_IDS, TEXTS } from './constants';
import { AccountsList, AccountsPage, buttonViewMore, FilterResult, filterResultWithScroll, input, noScroll } from './index.styled';
import { Props } from './interfaces';

function SearchAccounts({
  isLoading = false,
  showDropdown = false,
  accountingType,
  transferType,
  searchValue,
  accounts,
  accountsLoading,
  accountsError,
  accountSelectedId,
  accountSelected,
  setSearchValue,
  handleSearch,
  handleSelectAccount,
  handleRemoveSelectedAccount,
  setBalanceError,
  isModifyDrawer = false,
}: Props) {
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState(false);

  const [paging, setPaging] = useState<Paging>({
    totalAccounts: 0,
    totalPages: 0,
    page: 0,
    accounts: [],
  });

  const getPageDisplay = (index: number) => {
    return index > paging.page ? 'none' : 'flex';
  };

  const onClickViewMore = () => {
    setPaging(state => ({
      ...state,
      page: state.page + 1,
    }));
  };

  const paginate = (array: BankAccount[], size: number) => {
    const count = Math.ceil(array.length / size);
    const paginated = [];
    for (let i = 0; i < count; i += 1) {
      const startIndex = i * size;
      const page = array.slice(startIndex, startIndex + size);
      paginated.push(page);
    }
    return paginated;
  };

  const handlePaging = () => {
    if (!accounts) {
      return;
    }
    const paginated = paginate(accounts, PAGE_SIZE);
    setPaging({
      totalAccounts: accounts.length,
      totalPages: paginated.length,
      page: 0,
      accounts: paginated,
    });
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const onClear = () => {
    setSearchValue('');
    if (showDropdown) {
      setSearchEnabled(false);
    }
    if (!isModifyDrawer && searchEnabled) {
      setSearchEnabled(false);
      onSearch(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchEnabled(false);
    setSearchValue(e.target.value);
    if (!e.target.value) {
      onClear();
    }
  };

  const onSearch = (enabled = true) => {
    setSearchEnabled(enabled);
    handleSearch(enabled);
    handlePaging();
    handleSelectAccount && handleSelectAccount(null);
  };

  const onRemoveSelectedAccount = () => {
    setSearchEnabled(true);
    handleRemoveSelectedAccount && handleRemoveSelectedAccount();
  };

  useEffect(() => {
    setIsValidForm(searchValue.length >= 3 && searchValue.length <= MAX_LENGTH);
  }, [searchValue, MAX_LENGTH]);

  useEffect(() => {
    if (accounts) {
      handlePaging();
    }
  }, [accounts]);

  useEffect(() => {
    if (accountSelected) {
      setTimeout(() => {
        setSearchEnabled(false);
      }, 300);
    }
  }, [accountSelected]);

  useEffect(() => {
    if (searchValue) {
      setSearchEnabled(true);
    }
  }, []);

  return (
    <Box data-testid={`${accountingType}-${TEST_IDS.searchAccounts}`} position='relative' marginBottom='8px'>
      <FormControl sx={input}>
        <form onSubmit={e => e.preventDefault()}>
          <Input
            id={`${accountingType}-${FIELDS.searchValue}`}
            name={`${accountingType}-${FIELDS.searchValue}`}
            type='text'
            inputSize='md'
            onChange={onChange}
            maxLength={MAX_LENGTH}
            placeholder={!isFocused ? TEXTS.placeholder : ''}
            value={searchValue}
            isDisabled={(!searchEnabled && !!accountSelected) || isLoading}
            data-testid={`${accountingType}-${TEST_IDS.inputSearch}`}
            onFocus={onFocus}
            onBlur={onBlur}
            autoFocus={false}
            inputVariant='buttonRight'
            callToAction={{
              buttonVariant: 'primary',
              label: TEXTS.search,
              onClick: onSearch,
              isDisabled: isLoading || !isValidForm || searchEnabled || (!searchEnabled && !!accountSelected),
            }}
            onClear={onClear}
            isClearable
            label={`${TEXTS.label} ${isModifyDrawer ? '*' : ''}`}
            supportingText={!accountSelected?.accountId && (isFocused || searchValue.length) ? TEXTS.supporting : ' '}
          />
        </form>
      </FormControl>
      {showDropdown && searchEnabled && (
        <FilterResult
          sx={!accountsError && paging.totalAccounts ? filterResultWithScroll : {}}
          data-testid={`${accountingType}-${TEST_IDS.filterResult}`}
        >
          {accountsLoading ? (
            <Box>
              <Skeleton width='315px' height='20px' marginBottom='8px' data-testid={`${accountingType}-${TEST_IDS.skeleton}`} />
              <CardAccountSkeleton />
            </Box>
          ) : (
            <Box>
              {!accountsError && paging.totalAccounts ? (
                <Flex flexDirection='column'>
                  <Flex flexDirection='column' marginBottom='8px'>
                    <Text as='span' textStyle='titleSmBold'>
                      {TEXTS.filterResultTitle(paging.totalAccounts)}
                    </Text>
                    {paging.totalAccounts > 45 && (
                      <Text as='span' textStyle='labelSm'>
                        {TEXTS.filterResultDescription}
                      </Text>
                    )}
                  </Flex>
                  <AccountsList
                    data-testid={`${accountingType}-${TEST_IDS.accountsList}`}
                    style={paging.totalAccounts <= 3 ? noScroll : {}}
                  >
                    {paging.accounts.map((page, pageIndex) => (
                      <AccountsPage key={`${`${pageIndex}`}`} display={getPageDisplay(pageIndex)}>
                        {page.map((account, index) => (
                          <CardAccount
                            key={`${`${pageIndex}-${index}`}`}
                            selected={accountSelectedId === account.accountId}
                            account={account}
                            accountingType={accountingType}
                            transferType={transferType}
                            handleSelectAccount={handleSelectAccount}
                            setBalanceError={accountingType === AccountingType.debit ? setBalanceError : undefined}
                            mode={CardAccountMode.filterList}
                            autoWidth
                          />
                        ))}
                      </AccountsPage>
                    ))}
                  </AccountsList>
                  {paging.totalPages > 1 && paging.page < paging.totalPages - 1 && (
                    <Button
                      id={`${accountingType}-${TEST_IDS.buttonViewMore}`}
                      variant='primary-text'
                      sx={buttonViewMore}
                      onClick={onClickViewMore}
                      data-testid={`${accountingType}-${TEST_IDS.buttonViewMore}`}
                    >
                      {TEXTS.viewMore}
                    </Button>
                  )}
                </Flex>
              ) : (
                <CardAccountNoResult isError={accountsError} />
              )}
            </Box>
          )}
        </FilterResult>
      )}
      {showDropdown && !searchEnabled && accountSelected ? (
        <Box marginTop='24px'>
          <CardAccount
            key={accountSelected.accountId}
            selected
            account={accountSelected}
            accountingType={accountingType}
            transferType={transferType}
            handleRemoveSelectedAccount={onRemoveSelectedAccount}
            setBalanceError={accountingType === AccountingType.debit ? setBalanceError : undefined}
            mode={CardAccountMode.filterSelected}
            autoWidth
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default SearchAccounts;
