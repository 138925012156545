const TEXTS = {
  header: 'Fecha de solicitud no válida',
  title: (nextDate: string) =>
    `Podés continuar y estas transferencias pasarán al siguiente día hábil: ${nextDate}. O podés descartarlas y continuar la confección sin estas transferencias.`,
  warningTitle: (count: number, date: string) =>
    `Dentro del lote hay <b>${count} transferencia${
      count > 1 ? 's' : ''
    }</b> con fecha de solicitud <b>${date}</b> y ya no es posible confeccionarla${
      count > 1 ? 's' : ''
    } porque cerró el horario de operación para los bancos.`,
  debitAccount: 'Cuenta débito:',
  creditAccount: 'Cuenta crédito:',
  amount: 'Importe',
  requestDate: 'Fecha de solicitud',
  modalDiscardTitle: (count: number) => `Se descartará${count > 1 ? 'n' : ''} ${count} transferencia${count > 1 ? 's' : ''}`,
  modalDiscardDescription: (count: number) =>
    `Tené en cuenta que esta acción no se puede deshacer y borrarás\ndefinitivamente la${count > 1 ? 's' : ''} transferencia${
      count > 1 ? 's' : ''
    }.`,
  buttonDiscard: 'Descartar estas transferencias',
  buttonNextWorkingDay: 'Pasar al siguiente día hábil',
  buttonCancel: 'Cancelar',
  buttonDiscardConfirm: 'Sí, descartar',
  errorTitle: 'La información no está disponible en este momento',
  errorDescription: 'Estamos trabajando para solucionarlo.',
  buttonRetry: 'Reintentar',
};

const TEST_IDS = {
  summaryTable: 'summaryTable',
  summaryItem: 'summaryItem',
  skeleton: 'skeleton',
};

const ACCOUNT_NAME_LENGTH = 18;

export { ACCOUNT_NAME_LENGTH, TEST_IDS, TEXTS };
