import { Currencies } from '@interfaces/transfers';
import { format, parse } from 'date-fns';

import { AccountBalance } from '../../Interfaces/api';
import { formatAmount } from '../../utils/formatAmount';
import { accountNameMaxLength } from './constants';

const formatAccountName = (accountName: string | undefined, cuit: string | undefined, accountNameLength?: number) => {
  const data = [];
  if (accountName) {
    data.push(
      accountName.substring(0, accountNameLength || accountNameMaxLength) +
        (accountName.length > (accountNameLength || accountNameMaxLength) ? '...' : ''),
    );
  }
  if (cuit) {
    data.push(cuit);
  }
  return data.join(' | ');
};

const formatAccountData = (cbu: string | undefined, accountType: string | undefined, currency: string | undefined) => {
  const data = [];
  if (cbu) {
    data.push(cbu);
  }
  if (accountType) {
    data.push(accountType);
  }
  if (currency) {
    data.push(currency);
  }
  return data.join(' | ');
};

const formatLastBalance = (accountBalances: AccountBalance[], currency: Currencies) => {
  const lastBalance = accountBalances.length ? formatAmount(currency, accountBalances[0].lastBalance, '') : '$ -';
  const lastBalanceSplit = lastBalance.split(',');
  return {
    int: lastBalanceSplit[0],
    dec: lastBalanceSplit.length === 2 ? lastBalanceSplit[1] : '00',
  };
};

const formatLastUpdateDate = (accountBalances: AccountBalance[]) => {
  return accountBalances.length
    ? format(parse(accountBalances[0].lastUpdateDate, 'dd-MM-yyyy HH:mm:ss', new Date()), 'dd/MM/yyyy - HH:mm')
    : '-';
};

export { formatAccountData, formatAccountName, formatLastBalance, formatLastUpdateDate };
