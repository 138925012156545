/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAuthorizer } from '@react-ib-mf/header-menu-ui';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { pushAnalyticsEvent } from '@wow/utils';
import { useWizard, WizardComponent } from '@wow/wizard';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import BaseScreen from '../../components/BaseScreen';
import CustomBoxWithInput from '../../components/CompanyKey';
import CustomSummary from '../../components/CustomSummary';
import Footer from '../../components/Footer';
import IconBeware from '../../components/Icons/icon-beware';
import IconCancelModal from '../../components/Icons/icon-cancel-modal';
import IconCheckMark from '../../components/Icons/icon-checkmark';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import CustomModal from '../../components/Modal';
import { ARS_CURRENCY, routesNames, TEXT_BUTTON_SEND, TITLES_SCREENS, USD_CURRENCY } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import useAsyncProcess from '../../hooks/useAsyncProcess';
import useAuthPartial from '../../hooks/useAuthPartial';
import useCountdown from '../../hooks/useCountdown';
import useShowPassword from '../../hooks/useShowPassword';
import useTextEntityToken from '../../hooks/useTextEntityToken';
import { PostDataTransfersAction } from '../../Interfaces/api';
import { ServiceAction, TransfersFlow } from '../../Interfaces/transfers';
import { postTransfersAction } from '../../services/api';
import { REGEX_INPUT_TOKEN } from '../../utils/rulesInputs';
import { TEXT_MODAL_FAILED_SEND_AUTHORIZE } from '../AuthorizationTransfers/constants';
import { TEXT_MODAL_ASYNC_ERROR } from '../EntityKey/constants';
import {
  ALERT_SUE_TEXT,
  ASYNC_MODAL_AUTH_ERROR_TEXT,
  CHANNEL_COUNTRY_NOT_FOUND_MODAL,
  DISABLED_USER_MODAL,
  EXCEPTION_CODES,
  NON_AFFILIATED_MODAL,
  NON_LINKED_PERSON_ADHESION_DISABLED_MODAL,
  TEXT_ENTITY_TOKEN,
  TEXT_LOADING_AUTHORIZE,
  TEXT_MODAL_BLOCK_USER,
  TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS,
  TEXT_MODAL_PROCESS_ASYNCHRONOUS,
  TEXT_MODAL_SEND_ASYNC_IS_DONE,
  TEXT_MODAL_SUCCESS,
  TEXT_MODAL_USER_BLOCKED,
  TEXT_MODAL_USER_BLOCKING_WARNING,
} from './constants';
import WizardSteps from './WizardSteps';

function EntityToken() {
  const location = useLocation();
  const navigate = useNavigate();
  const [authorizer] = useAuthorizer();
  const { time, start } = useCountdown();
  const [failedAttempts, setFailedAttempts] = useState<string>('');
  const { mutateAuthPartial, isLoadingAuthPartial } = useAuthPartial();
  const {
    title,
    descriptionOne,
    descriptionTwo,
    descriptionFour,
    iconError,
    password,
    statusButton,
    modalBlockedUser,
    modalInvalidOtpLastAttempt,
    modalBlockeUser,
    setPassword,
    setModalInvalidOtpLastAttempt,
    setModalBlockedUser,
    setModalBlockeUser,
    setStatusButton,
    setMessageResponse,
  } = useTextEntityToken({ failedAttempts, start });
  const {
    dataTotalsTransfers,
    dataDetailsTransfers,
    setPageContext,
    setTransfersRejected,
    clearSelectedLots,
    setViewPartialRejecteAuth,
    transferTypeAuthReadyToSend,
    setTransferTypeAuthReadyToSend,
    errorUseAuthPartial,
    setErrorUseAuthPartial,
    totalAmountPartialReject,
    loadingPartialReject,
    setTotalAmountPartialReject,
    setTransfersAccepted,
    setLoadingPartialReject,
  } = useTransfersContext();
  const { showPassword, hidePassword, onMouseLeave, show } = useShowPassword();
  const [totalTransfersArs, setTotalTransfersArs] = useState<number>(0);
  const [totalTransfersUsd, setTotalTransfersUsd] = useState<number>(0);
  const [hasPartialAmountSue, setHasPartialAmountSue] = useState<boolean>(true);
  const [hasPartialAmountSueUsd, setHasPartialAmountSueUsd] = useState<boolean>(true);
  const [totalAmountArs, setTotalAmountArs] = useState<number>(0);
  const [totalAmountUsd, setTotalAmountUsd] = useState<number>(0);
  const [idProcess, setIdProcess] = useState<number | null>(null);
  const [showModalErrorSendTransfers, setShowModalErrorSendTransfers] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [statusAlert, setStatusAlert] = useState<boolean>(true);
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();
  const [showNotFoundModal, setShowNotFoundModal] = useState<boolean>(false);
  const [showNonAffiliatedModal, setShowNonAffiliatedModal] = useState<boolean>(false);
  const [showNonLinkedAdhesionModal, setShowNonLinkedAdhesionModal] = useState<boolean>(false);
  const [showDisabledUserModal, setShowDisabledUserModal] = useState<boolean>(false);
  const {
    isFetchingAsyncProcess,
    isAsyncProcess,
    isAsyncProcessDone,
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    isAsyncProcessError,
    setIsAsyncProcessError,
  } = useAsyncProcess(idProcess, ServiceAction.AUT, TransfersFlow.Authorize, setShowModalSuccess);

  const { mutate: mutationPostTransfersAction, isLoading: isFetchingAuthorize } = useMutation(
    (data: PostDataTransfersAction) => {
      return postTransfersAction(ServiceAction.AUT, data);
    },
    {
      onSuccess: response => {
        const exceptionCode = response.data.exception.code;
        switch (exceptionCode) {
          case EXCEPTION_CODES.satisfactoryProcess:
            pushAnalyticsEvent({
              event: 'transferencia_autorizada',
              content_group: 'Transferencias - Resumen de autorización',
              status: 'Transferencias autorizadas exitosamente',
            });
            setShowModalSuccess(true);
            break;
          case EXCEPTION_CODES.totalReject:
            setTransfersRejected(response.data.data.transfersRejected);
            navigate(routesNames.ResultAuth);
            break;
          case EXCEPTION_CODES.parcialReject:
            setTransfersRejected(response.data.data.transfersRejected);
            setTransfersAccepted(response.data.data.transfersAccepted);
            navigate(routesNames.ResultAuth);
            break;
          case EXCEPTION_CODES.parcialValidation:
            setTransfersRejected(response.data.data.transfersRejected);
            setViewPartialRejecteAuth(true);
            mutateAuthPartial({ transfer: response.data.data.transfersValidated[0].numberTransfer });
            setLoadingPartialReject(true);
            break;
          case EXCEPTION_CODES.parcialOperation:
            setTransfersRejected(response.data.data.transfersRejected);
            setTransfersAccepted(response.data.data.transfersAccepted);
            setViewPartialRejecteAuth(true);
            mutateAuthPartial({ transfer: response.data.data.transfersValidated[0].numberTransfer });
            setLoadingPartialReject(true);
            break;
          case EXCEPTION_CODES.asyncSendProcess:
            setIdProcess(response.data.data.processId);
            break;
          case EXCEPTION_CODES.timeOut:
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'La autorización se está procesando',
            });
            setIsAsyncProcessDone(true);
            break;
          case EXCEPTION_CODES.CHANNEL_NOT_FOUND:
          case EXCEPTION_CODES.COUNTRY_NOT_FOUND:
            setShowNotFoundModal(true);
            break;
          case EXCEPTION_CODES.NON_AFFILIATED_PERSON:
            setShowNonAffiliatedModal(true);
            break;
          case EXCEPTION_CODES.ADHESION_DISABLED:
          case EXCEPTION_CODES.NON_LINKED_PERSON:
            setShowNonLinkedAdhesionModal(true);
            break;
          case EXCEPTION_CODES.DISABLED_PERSON:
            setShowDisabledUserModal(true);
            break;
          default:
            setFailedAttempts(response.data.data.failedAttempts);
            setMessageResponse(response.data.exception.code);
        }
      },
      onError: () => {
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos autorizar las transferencias - Error servicio',
        });
        setShowModalErrorSendTransfers(true);
      },
    },
  );

  useEffect(() => {
    if (dataTotalsTransfers.length <= 0) navigate(routesNames.Root);
    setPageContext(1);
    if (totalAmountPartialReject.length === 0) {
      dataTotalsTransfers.forEach(element => {
        if (element.currencyType === ARS_CURRENCY) {
          setTotalTransfersArs(element.totalTransfers);
          setTotalAmountArs(element.amount);
          setHasPartialAmountSue(element.hasPartialAmountSue);
        }
        if (element.currencyType === USD_CURRENCY) {
          setTotalTransfersUsd(element.totalTransfers);
          setTotalAmountUsd(element.amount);
          setHasPartialAmountSueUsd(element.hasPartialAmountSue);
        }
      });
    }
    totalAmountPartialReject.forEach(element => {
      if (element.currencyType === ARS_CURRENCY) {
        setTotalTransfersArs(element.totalTransfers);
        setTotalAmountArs(element.amount);
        setHasPartialAmountSue(element.hasPartialAmountSue);
      }
      if (element.currencyType === USD_CURRENCY) {
        setTotalTransfersUsd(element.totalTransfers);
        setTotalAmountUsd(element.amount);
        setHasPartialAmountSueUsd(element.hasPartialAmountSue);
      }
    });
    if (localStorage.getItem('tef-wizard-token') == null) {
      setInitialized(true);
    }
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Transferencias - Autorizar - Ingreso Token',
    });
  }, []);

  useEffect(() => {
    if (time === 0) {
      authorizer.logout();
    }
  }, [time]);

  const resetAndRedirect = () => {
    clearSelectedLots();
    navigate(routesNames.Root);
  };

  const handleSubmit = () => {
    pushAnalyticsEvent({
      event: 'autorizar_transferencias',
      content_type: 'Botones seccion ingreso de Token',
      item_id: 'Autorizar transferencias',
    });

    const transfers = dataDetailsTransfers.map(item => ({
      orderId: item.transfer,
      transferTypeCode: item.type,
    }));

    if (transferTypeAuthReadyToSend.length > 0) {
      const newValueTransfers = transferTypeAuthReadyToSend.map(item => ({
        orderId: item.transferNumber,
        transferTypeCode: item.type,
      }));
      mutationPostTransfersAction({ transfers: newValueTransfers, otp: password });
      setTransferTypeAuthReadyToSend([]);
    } else {
      mutationPostTransfersAction({ transfers, otp: password });
    }
  };

  const goBack = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion ingreso de Token',
      item_id: 'Volver',
    });
    navigate(routesNames.Root, { state: { from: location } });
  };

  const handleValidationLength = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const valueInput = e.target.value;
    const numericValue = valueInput.replace(REGEX_INPUT_TOKEN, '');
    e.target.value = numericValue;
    setPassword(numericValue);
    if (numericValue.length === 6) {
      setStatusButton(false);
    } else {
      setStatusButton(true);
    }
  };

  const handleCloseModalErrorSend = () => {
    setShowModalErrorSendTransfers(false);
    setPassword('');
    setStatusButton(true);
    resetAndRedirect();
    setErrorUseAuthPartial(false);
    setTotalAmountPartialReject([]);
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos autorizar las transferencias - Error servicio',
      item_id: 'Entendido',
    });
  };

  const handleCloseModalwarning = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Advertencia inhabilitación de usuario',
      item_id: 'Entendido',
    });
    setModalInvalidOtpLastAttempt(false);
  };

  const onCloseModalSuccessGoToHome = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal transferencias autorizadas',
      item_id: 'Ir a inicio',
    });
    setShowModalSuccess(false);
    clearSelectedLots();
    setTotalAmountPartialReject([]);
    navigate('/');
  };

  const onCloseModalSuccessGoToTransfers = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal transferencias autorizadas',
      item_id: 'Ir a transferencias',
    });
    setShowModalSuccess(false);
    resetAndRedirect();
    setTotalAmountPartialReject([]);
  };

  const onCloseModalProcessedAsynchronouslyGoToTransfers = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Autorizando transferencias',
      item_id: 'Volver a transferencias',
    });
    setIsAsyncProcess(false);
    resetAndRedirect();
  };

  const onCloseModalasyncProcessStatusIsDoneyGoToTransfers = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'La autorización se está procesando',
      item_id: 'Entendido',
    });
    setIsAsyncProcessDone(false);
    resetAndRedirect();
    setTotalAmountPartialReject([]);
    setTransferTypeAuthReadyToSend([]);
  };

  const onCloseNotFoundModal = () => {
    setShowNotFoundModal(false);
    resetAndRedirect();
  };

  const onCloseNonAffiliatedModal = () => {
    setShowNonAffiliatedModal(false);
    resetAndRedirect();
  };

  const onCloseNonLinkedAdhesionModal = () => {
    setShowNonLinkedAdhesionModal(false);
    resetAndRedirect();
  };

  const onCloseDisabledUserModal = () => {
    setShowDisabledUserModal(false);
    resetAndRedirect();
  };

  const handleFinishWizard = () => {
    localStorage.setItem('tef-wizard-token', 'true');
  };

  const handleCancelButton = () => {
    clearSelectedLots();
    setTransferTypeAuthReadyToSend([]);
    setViewPartialRejecteAuth(false);
    setTotalAmountPartialReject([]);
    navigate('/', { state: { from: location } });
  };

  return (
    <BaseScreen title={TITLES_SCREENS.titleEntityToken} sizeTitle='h2Bold' id='token'>
      <>
        {(isFetchingAuthorize || isFetchingAsyncProcess || loadingPartialReject) && (
          <LoadingScreen title={TEXT_LOADING_AUTHORIZE.title} description={TEXT_LOADING_AUTHORIZE.description} />
        )}
        {((hasPartialAmountSue && totalAmountArs !== 0) || (hasPartialAmountSueUsd && totalAmountUsd !== 0)) && statusAlert && (
          <AlertComponent
            variant='warning'
            width='100%'
            onClose={() => {
              setStatusAlert(false);
            }}
          >
            <AlertTitle>{ALERT_SUE_TEXT.title}</AlertTitle>
            <AlertDescription>{ALERT_SUE_TEXT.description}</AlertDescription>
          </AlertComponent>
        )}
        {initialized && (
          <WizardComponent
            id='tef-wizard-token'
            initialized={initialized}
            setInitialized={setInitialized}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={WizardSteps}
            width={300}
            height={250}
            offset={0}
            onFinish={handleFinishWizard}
          />
        )}
        <CustomSummary
          title='Resumen'
          totalTransfersArs={totalTransfersArs}
          totalAmountArs={totalAmountArs}
          totalTransfersUsd={totalTransfersUsd}
          totalAmountUsd={totalAmountUsd}
          id='authSummary'
        />
        <CustomBoxWithInput
          companyKeyTitle={title}
          companyKeyDescriptionOne={descriptionOne}
          companyKeyDescriptionTwo={descriptionTwo}
          companyKeyDescriptionFour={descriptionFour}
          password={password}
          show={show}
          showPassword={showPassword}
          handleValidationLength={handleValidationLength}
          onMouseLeave={onMouseLeave}
          hidePassword={hidePassword}
          textPlaceholder={TEXT_ENTITY_TOKEN.textPlaceholder}
          maxLength={6}
          companyKeyNotFound={iconError}
          id='inputToken'
        />
        <Footer
          handleGoBack={goBack}
          statusButtom={statusButton}
          onSubmit={handleSubmit}
          textButton={TEXT_BUTTON_SEND.textEntityToken}
          isActivebuttonCancel={totalAmountPartialReject.length !== 0}
          thirdTextButton={TEXT_BUTTON_SEND.textSecondButton}
          statusThirdButtom={false}
          handleThirdButton={handleCancelButton}
        />
      </>
      <CustomModal
        isOpen={modalInvalidOtpLastAttempt}
        onCancel={setModalInvalidOtpLastAttempt}
        Icon={IconBeware}
        altIcon={TEXT_MODAL_USER_BLOCKING_WARNING.altIcon}
        title={TEXT_MODAL_USER_BLOCKING_WARNING.title}
        firstDescription={TEXT_MODAL_USER_BLOCKING_WARNING.firstDescription}
        firstTextButton={TEXT_MODAL_USER_BLOCKING_WARNING.firstTextButton}
        actionButton={handleCloseModalwarning}
        enableFirstButton
        size='xl'
      />
      <CustomModal
        isOpen={modalBlockedUser}
        onCancel={setModalBlockedUser}
        Icon={IconCancelModal}
        altIcon={TEXT_MODAL_USER_BLOCKED.altIcon}
        title={TEXT_MODAL_USER_BLOCKED.title}
        firstDescription={TEXT_MODAL_USER_BLOCKED.firstDescription}
        bottomDescription={`Cerraremos tu sesión en ${time} segundos`}
        size='xl'
      />
      <CustomModal
        isOpen={showModalErrorSendTransfers || errorUseAuthPartial}
        onCancel={setShowModalErrorSendTransfers}
        Icon={IconCancelModal}
        altIcon={TEXT_MODAL_FAILED_SEND_AUTHORIZE.altIcon}
        title={TEXT_MODAL_FAILED_SEND_AUTHORIZE.title}
        firstDescription={TEXT_MODAL_FAILED_SEND_AUTHORIZE.firstDescription}
        firstTextButton={TEXT_MODAL_FAILED_SEND_AUTHORIZE.textButtom}
        actionButton={handleCloseModalErrorSend}
        enableFirstButton
        size='xl'
      />
      <CustomModal
        isOpen={modalBlockeUser}
        onCancel={setModalBlockeUser}
        Icon={IconCancelModal}
        altIcon={TEXT_MODAL_BLOCK_USER.altIcon}
        title={TEXT_MODAL_BLOCK_USER.title}
        firstDescription={TEXT_MODAL_BLOCK_USER.firstDescription}
        bottomDescription={`Cerraremos tu sesión en ${time} segundos`}
        size='xl'
      />
      <CustomModal
        isOpen={showModalSuccess}
        enableFirstButton
        actionButton={onCloseModalSuccessGoToHome}
        firstTextButton={TEXT_MODAL_SUCCESS.goToHome}
        alternativeButtonStyling
        enableSecondButton
        actionButton2={onCloseModalSuccessGoToTransfers}
        secondTextButton={TEXT_MODAL_SUCCESS.goToTransfers}
        Icon={IconCheckMark}
        title={TEXT_MODAL_SUCCESS.title}
        size='xl'
        onCancel={onCloseModalSuccessGoToTransfers}
        cancelType
      />
      <CustomModal
        isOpen={isAsyncProcessDone}
        enableFirstButton
        actionButton={onCloseModalasyncProcessStatusIsDoneyGoToTransfers}
        firstTextButton={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstTextButton}
        firstDescription={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstDescription}
        Icon={IconInfoPopup}
        title={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.title}
        size='xl'
        onCancel={setIsAsyncProcessDone}
      />
      <CustomModal
        isOpen={isAsyncProcess}
        enableFirstButton
        actionButton={onCloseModalProcessedAsynchronouslyGoToTransfers}
        firstTextButton={TEXT_MODAL_PROCESS_ASYNCHRONOUS.firstTextButton}
        firstDescription={TEXT_MODAL_PROCESS_ASYNCHRONOUS.firstDescription}
        iconSpinner
        title={TEXT_MODAL_PROCESS_ASYNCHRONOUS.title}
        size='xl'
        onCancel={setIsAsyncProcess}
      />
      <CustomModal
        isOpen={isAsyncProcessError}
        Icon={IconBeware}
        onCancel={setIsAsyncProcessError}
        altIcon={TEXT_MODAL_SEND_ASYNC_IS_DONE.altIcon}
        firstDescriptionWithBold={ASYNC_MODAL_AUTH_ERROR_TEXT}
        title={TEXT_MODAL_ASYNC_ERROR.title}
        firstTextButton={TEXT_MODAL_SEND_ASYNC_IS_DONE.firstTextButton}
        enableFirstButton
        actionButton={onCloseModalSuccessGoToTransfers}
        size='xl'
        cancelType
      />
      <CustomModal
        isOpen={showNotFoundModal}
        onCancel={onCloseNotFoundModal}
        Icon={IconCancelModal}
        title={CHANNEL_COUNTRY_NOT_FOUND_MODAL.title}
        firstDescription={CHANNEL_COUNTRY_NOT_FOUND_MODAL.description}
        firstTextButton={CHANNEL_COUNTRY_NOT_FOUND_MODAL.textButton}
        actionButton={onCloseNotFoundModal}
        size='xl'
        enableFirstButton
        cancelType
      />
      <CustomModal
        isOpen={showNonAffiliatedModal}
        onCancel={onCloseNonAffiliatedModal}
        Icon={IconCancelModal}
        title={NON_AFFILIATED_MODAL.title}
        firstDescription={NON_AFFILIATED_MODAL.description}
        firstTextButton={NON_AFFILIATED_MODAL.textButton}
        actionButton={onCloseNonAffiliatedModal}
        size='xl'
        enableFirstButton
        cancelType
      />
      <CustomModal
        isOpen={showNonLinkedAdhesionModal}
        onCancel={onCloseNonLinkedAdhesionModal}
        Icon={IconCancelModal}
        title={NON_LINKED_PERSON_ADHESION_DISABLED_MODAL.title}
        firstDescription={NON_LINKED_PERSON_ADHESION_DISABLED_MODAL.description}
        firstTextButton={NON_LINKED_PERSON_ADHESION_DISABLED_MODAL.textButton}
        actionButton={onCloseNonLinkedAdhesionModal}
        size='xl'
        enableFirstButton
        cancelType
      />
      <CustomModal
        isOpen={showDisabledUserModal}
        onCancel={onCloseDisabledUserModal}
        Icon={IconCancelModal}
        title={DISABLED_USER_MODAL.title}
        firstDescription={DISABLED_USER_MODAL.description}
        firstTextButton={DISABLED_USER_MODAL.textButton}
        actionButton={onCloseDisabledUserModal}
        size='xl'
        enableFirstButton
        cancelType
      />
    </BaseScreen>
  );
}

export default EntityToken;
