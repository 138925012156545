import { useEffect, useState } from 'react';

import { addMissingDecimals } from '../utils/addMissingDecimals';
import { applyCurrencyFormat } from '../utils/applyCurrencyFormat';
import { applyCurrencyFormatFromPaste } from '../utils/applyCurrencyFormatFromPaste';

function useAmountInput(initialInputValue = '') {
  const [inputValue, setInputValue] = useState<string>(initialInputValue);

  const isValidCurrencyFormat = (value: string): boolean => {
    return /^(?!0\d)\d{1,3}(?:\.\d{3})*(?:,\d{2})$/.test(value);
  };

  const formatInputValue = (amount: string, isPasted: boolean): string => {
    if (isValidCurrencyFormat(amount)) {
      return amount;
    }
    return isPasted ? applyCurrencyFormatFromPaste(amount) : applyCurrencyFormat(amount);
  };

  const onAmountChanged = (amount: string): void => {
    setInputValue(formatInputValue(amount, false));
  };

  const onAmountPasted = (amount: string): void => {
    if (amount) {
      setInputValue(formatInputValue(amount, true));
    }
  };

  const onHandleBlurAmount = () => {
    if (inputValue && !isValidCurrencyFormat(inputValue)) {
      setInputValue(addMissingDecimals(inputValue));
    }
  };

  useEffect(() => {
    setInputValue(initialInputValue);
  }, [initialInputValue]);

  return {
    inputValue,
    setInputValue,
    onAmountChanged,
    onAmountPasted,
    onHandleBlurAmount,
  };
}

export { useAmountInput };
