import { colors } from '../../styled/generalStyles';

export const CloseButton = {
  background: colors.White,
  border: 'none',
  color: colors.Primary100,
  cursor: 'pointer',
  fontSize: '14px;',
  padding: '0px',
  top: '16px',
  right: '26px',
  marginBottom: '8px',

  '&:active': {
    background: 'none',
  },

  '&:hover': {
    background: 'none',
  },

  '&:focus': {
    background: 'none',
    boxShadow: colors.ShadowNone,
  },
};

export const Importe = {
  margin: '0px',
  paddingTop: '10px',
  textAlign: 'left',
};

export const TotalTransfers = {
  margin: '0px',
  padding: '5px 0px',
  textAlign: 'left',
};

export const TextBold = {
  margin: '0px',
  padding: '0px 5px',
  textAlign: 'left',
};

export const TextBoldObservation = {
  margin: '0px',
  overflow: 'hidden',
  padding: '0px 5px',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '350px',
};

export const AccountType = {
  color: colors.Grey100,
  margin: '30px 0px 5px 0px',
  textAlign: 'left',
};

export const AccountNumber = {
  margin: '0px',
  textAlign: 'left',
};

export const InfoAccount = {
  margin: '0px',
  textAlign: 'left',
};

export const StatusTransfers = {
  textAlign: 'left',
};

export const StatusTransfersBold = {
  textAlign: 'left',
};

export const ContentStatusTransfers = {
  border: `solid 1px ${colors.Grey60}`,
  margin: '35px 0px 20px 0px',
  padding: '12px',
};

export const SectionsStatusTransfers = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const SectionAcordion = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '15px 0px',
};

export const SectionAcordionMoreDetails = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '15px 0px',
  width: '100%',
};

export const TextAcordion = {
  margin: '0px',
  textAlign: 'left',
};

export const TextAcordionMoreDetails = {
  margin: '0px',
  textAlign: 'end',
  width: '65%',
};

export const TextBoldAcordion = {
  margin: '0px',
  textAlign: 'left',
};

export const TextBoldAcordionMoreDetails = {
  margin: '0px',
  textAlign: 'left',
  width: '35%',
};

export const sectionTitleAccordion = {
  height: '60px',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '24px',

  '&:focus': {
    boxShadow: colors.ShadowNone,
  },

  '&:hover': {
    background: colors.Grey50,
  },
};

export const sectionDataAccordion = {
  padding: '0px 15px',
};

export const borderAccordion = {
  '>div:last-of-type': {
    borderRadius: '8px',
  },

  '>div:last-of-type>*': {
    borderRadius: '8px',
  },

  '>div:last-of-type>*[aria-expanded=true]': {
    borderRadius: '8px 8px 0px 0px',
  },
};
