/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-plusplus */
import { Input, InputGroup } from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';
import { Search } from 'react-feather';

import { useTransfersContext } from '../../../../context/TransfersContext';
import BaseScreenFilter from '../BaseScreenFilter';
import { MENU_TEST_ID_NUMBER_LOTS, TEXT_FILTER_TAG, TEXT_PLACEHOLDER, TITLE_HEADER } from './constants';
import { CustomBox, CustomInputLeftElement } from './styled';

interface Props {
  isFetching: boolean;
}

function FilterTransferNumber({ isFetching }: Props) {
  const { transferNumber, setTransferNumber, filtered, setFiltered, hasData, setPageContext } = useTransfersContext();
  const [inputValue, setInputValue] = useState<string>(transferNumber);
  const [filterApply, setFilterApply] = useState<boolean>(false);

  const onHandleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const valueInput = e.target.value.replace(/[^0-9]/g, '');

    setInputValue(prevValue => {
      if (prevValue !== valueInput) {
        setFilterApply(false);
      }
      return valueInput;
    });
  };

  const handleClearCloseWithOutFilter = () => {
    if (filterApply || transferNumber) {
      setInputValue(transferNumber);
      setFilterApply(true);
    } else {
      setInputValue('');
    }
  };

  const handleClearInputFilter = () => {
    if (filterApply) {
      setInputValue('');
      setFilterApply(false);
    }
    setInputValue('');
  };

  const applyLotFiltersAndSetFiltered = () => {
    setPageContext(1);
    setFilterApply(true);
    setTransferNumber(inputValue);
    setFiltered({
      ...filtered,
      isTransferNumberActive: !!inputValue.length || !!transferNumber.length,
    });
  };

  const statusButtonApply = () => {
    if (inputValue.length >= 9 && !filterApply) return false;
    if (inputValue.length === 0 && filtered.isTransferNumberActive) return false;
    if (inputValue.length <= 8) return true;
    if (!filterApply && transferNumber) return false;
    if (transferNumber) return true;
    if (filterApply && inputValue.length >= 9) return false;
    return true;
  };

  return (
    <BaseScreenFilter
      titleHeader={TITLE_HEADER}
      textTag={TEXT_FILTER_TAG}
      count={transferNumber ? 1 : 0}
      statusButtonClear={!inputValue}
      statusButtonApply={statusButtonApply()}
      handleClearCloseWithOutFilter={handleClearCloseWithOutFilter}
      handleApplyButton={applyLotFiltersAndSetFiltered}
      onClickClearButton={handleClearInputFilter}
      menuTestIdName={MENU_TEST_ID_NUMBER_LOTS}
      testId='modify'
      isSelected={filtered.isTransferNumberActive && !!transferNumber.length}
      isDisabled={(!filtered.isTransferNumberActive && !hasData) || isFetching}
    >
      <CustomBox>
        <InputGroup className='groupInput'>
          <CustomInputLeftElement>
            <Search size={24} />
          </CustomInputLeftElement>
          <Input
            placeholder={TEXT_PLACEHOLDER}
            size='md'
            type='text'
            textAlign='left'
            paddingLeft='35px'
            value={inputValue}
            onChange={e => onHandleChangeInput(e)}
            maxLength={17}
          />
        </InputGroup>
      </CustomBox>
    </BaseScreenFilter>
  );
}

export default FilterTransferNumber;
