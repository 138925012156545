export const PATHNAME_ENTITY_KEY = '/transferencias/claveEmpresa';
export const PATHNAME_LOT_DETAIL = '/transferencias/detalleLote';
export const PATHNAME_ENTITY_TOKEN = '/transferencias/ingresarToken';

export const COUNTDOWN_TIME = 10;
export const COUNTDOWN_START = 0;
export const COUNTDOWN_MIN = 1;
export const COUNTDOWN_MS = 1000;
export const ASYNC_MAX_RETRIES = 6;
export const ASYNC_DELAY_RETRY = 5000;

export const TEXT_ENTITY_KEY = {
  title: 'Clave Empresa',
  descriptionOne: 'Es una clave única que se crea en la app Interbanking y almacena las',
  descriptionTwo: 'claves MAC para hacer envíos de dinero más rápido.',
  descriptionThree: 'Ya existe una Clave Empresa para',
  descriptionFour: 'Si no la conocés, contactá a tu administrador.',
  titleError: 'Clave Empresa incorrecta',
  descriptionOneError: 'Ingresá de nuevo la clave para enviar las transferencias.',
  descriptionFourError: 'Si no la conocés, contactá a tu administrador.',
  textPlaceholder: 'Ingresá tu Clave Empresa',
};

export const KeyboardKeys = {
  Enter: 'Enter',
};

export const BANK_CODE = {
  '007': '007',
  '011': '011',
  '014': '014',
  '015': '015',
  '016': '016',
  '017': '017',
  '020': '020',
  '027': '027',
  '029': '029',
  '030': '030',
  '034': '034',
  '044': '044',
  '045': '045',
  '060': '060',
  '065': '065',
  '072': '072',
  '083': '083',
  '086': '086',
  '093': '093',
  '094': '094',
  '097': '097',
  '131': '131',
  '143': '143',
  '147': '147',
  '150': '150',
  '165': '165',
  '191': '191',
  '198': '198',
  '247': '247',
  '254': '254',
  '259': '259',
  '266': '266',
  '268': '268',
  '277': '277',
  '281': '281',
  '285': '285',
  '299': '299',
  '301': '301',
  '305': '305',
  '309': '309',
  '310': '310',
  '311': '311',
  '312': '312',
  '315': '315',
  '319': '319',
  '321': '321',
  '322': '322',
  '330': '330',
  '338': '338',
  '341': '341',
  '386': '386',
  '389': '389',
  '408': '408',
  '415': '415',
  '426': '426',
  '431': '431',
  '432': '432',
  '448': '448',
  '453': '453',
};

export const BANK_NAME = {
  [BANK_CODE['007']]: 'Banco Galicia',
  [BANK_CODE['011']]: 'Banco Nación',
  [BANK_CODE['014']]: 'Banco Provincia',
  [BANK_CODE['015']]: 'Banco ICBC',
  [BANK_CODE['016']]: 'Banco Citi',
  [BANK_CODE['017']]: 'Banco BBVA',
  [BANK_CODE['020']]: 'Banco de Cordoba',
  [BANK_CODE['027']]: 'Banco Supervielle',
  [BANK_CODE['029']]: 'Banco Ciudad',
  [BANK_CODE['030']]: 'Banco Central de la República Argentina',
  [BANK_CODE['034']]: 'Banco Patagonia',
  [BANK_CODE['044']]: 'Banco Hipotecario',
  [BANK_CODE['045']]: 'Banco San Juan',
  [BANK_CODE['060']]: 'Banco Tucumán ',
  [BANK_CODE['065']]: 'Banco Municipal',
  [BANK_CODE['072']]: 'Banco Santander',
  [BANK_CODE['083']]: 'Banco Chubut',
  [BANK_CODE['086']]: 'Banco Santa Cruz',
  [BANK_CODE['093']]: 'Banco de La Pampa',
  [BANK_CODE['094']]: 'Banco de Corrientes',
  [BANK_CODE['097']]: 'Banco de la Provincia de Neuquen',
  [BANK_CODE['131']]: 'Banco de China',
  [BANK_CODE['143']]: 'Brubank',
  [BANK_CODE['147']]: 'Bi Bank',
  [BANK_CODE['150']]: 'Banco Galicia Más',
  [BANK_CODE['165']]: 'Banco J.P Morgan',
  [BANK_CODE['191']]: 'Banco Credicoop',
  [BANK_CODE['198']]: 'Banco de Valores',
  [BANK_CODE['247']]: 'Banco Roela',
  [BANK_CODE['254']]: 'Banco Mariva',
  [BANK_CODE['259']]: 'Banco Itaú',
  [BANK_CODE['266']]: 'Banco Paribas',
  [BANK_CODE['268']]: 'Banco de Tierra del Fuego',
  [BANK_CODE['277']]: 'Banco Saenz',
  [BANK_CODE['281']]: 'Banco Meridian',
  [BANK_CODE['285']]: 'Banco Macro',
  [BANK_CODE['299']]: 'Banco Comafi',
  [BANK_CODE['301']]: 'Banco Piano',
  [BANK_CODE['305']]: 'Banco Julio',
  [BANK_CODE['309']]: 'Banco Rioja',
  [BANK_CODE['310']]: 'Banco del Sol',
  [BANK_CODE['311']]: 'Nuevo Banco del Chaco',
  [BANK_CODE['312']]: 'Banco Voii',
  [BANK_CODE['315']]: 'Banco de Formosa',
  [BANK_CODE['319']]: 'Banco Corporativo CMF',
  [BANK_CODE['321']]: 'Banco Santiago del Estero',
  [BANK_CODE['322']]: 'Banco Industrial Bind',
  [BANK_CODE['330']]: 'Banco Santa Fe',
  [BANK_CODE['338']]: 'Banco de Servicios y Transacciones',
  [BANK_CODE['341']]: 'Banco Masventas',
  [BANK_CODE['386']]: 'Banco Entre Ríos',
  [BANK_CODE['389']]: 'Banco Columbia',
  [BANK_CODE['408']]: 'Banco Efectivo Si',
  [BANK_CODE['415']]: 'Reba Financiera',
  [BANK_CODE['426']]: 'Banco Bica',
  [BANK_CODE['431']]: 'Banco Coinag',
  [BANK_CODE['432']]: 'Banco de Comercio',
  [BANK_CODE['448']]: 'Banco Dino',
  [BANK_CODE['453']]: 'Banco Naranja X',
};

export const TEXT_MODAL_CHECKMARK_SUCCESS = {
  title: 'Transferencias enviadas',
  firstDescription: 'Podrás ver el comprobante cuando el banco procese la transferencia correctamente.',
  firstTextButton: 'Ir a Inicio',
  secondTextButton: 'Ir a Transferencias',
  altIcon: 'icon-checkmark-modal',
};

export const TEXT_MODAL_SEND_ERROR = {
  title: 'No podemos enviar las\n transferencias',
  firstDescription: 'Intentá de nuevo más tarde.',
  firstTextButton: 'Entendido',
  altIcon: 'icon-cancel-modal',
};

export const TEXT_MODAL_SEND_ERROR_SECOND = {
  title: 'Clave Empresa incorrecta',
  firstDescription:
    'Si la ingresás mal una vez más inhabilitaremos tu usuario por seguridad.\nSi no la conocés, contactá a tu administrador.',
  secondDescription: 'Si no la conocés, contactá a tu administrador.',
  firstTextButton: 'Entendido',
  altIcon: 'icon-beware-modal',
};

export const TEXT_MODAL_SEND_ERROR_THIRD = {
  title: 'Inhabilitamos tu usuario',
  firstDescription:
    'Ingresaste mal la Clave Empresa, para habilitar tu usuario comunícate con nuestro equipo de Soporte llamando al (11) 5554 - 2999.',
  altIcon: 'icon-cancel-modal',
};

export const EXCEPTION_CODES = {
  satisfactoryProcess: 'TEF-0000',
  incorrectCompanyKey: 'TEF-0001',
  noBankWithMacKey: 'TEF-0002',
  someBanksWithMacKey: 'TEF-0003',
  userDisabled: 'TEF-0007',
  failedProcess: 'TEF-0010',
  totalReject: 'TEF-0005',
  parcialReject: 'TEF-0004',
  asyncSendProcess: 'TEF-0008',
  asyncSendInProgress: 'TEF-0013',
  asyncSendFailed: 'TEF-0014',
  restClientTimeOut: 'TEF-0015',
};

export const TEXT_MODAL_ERROR_EIGHTH_DIGIT = {
  title: (banks: number) => {
    if (banks === 1) {
      return `Clave MAC incorrecta`;
    }
    return `Claves MAC incorrectas`;
  },
  firstDescription: (banks: number) => {
    if (banks === 1) {
      return `Revisá la clave MAC del `;
    }
    return `Revisá las claves MAC del `;
  },
  secondDescription: 'Si no lo hacés, solo se enviarán las transferencias de los otros bancos.',
  altIcon: 'icon-information-modal',
  firstTextButton: 'Continuar envio',
  secondTextButton: (banks: number) => {
    if (banks === 1) {
      return 'Revisar clave';
    }
    return 'Revisar claves';
  },
};

export const TEXT_MODAL_SEND_ASYNC_IN_PROGRESS = {
  title: 'Enviando transferencias',
  firstTextButton: 'Volver a Transferencias',
  altIcon: 'icon-loading-modal',
};

export const TEXT_MODAL_SEND_ASYNC_IS_DONE = {
  title: 'El envío se está procesando',
  altIcon: 'icon-information-modal',
  firstTextButton: 'Entendido',
};

export const TEXT_MODAL_PROSEGUR = {
  title: 'Anticipar transferencias diferidas',
  altIcon: 'icon-modal',
  firstTextButton: 'No anticipar',
  secondTextButton: 'Sí, anticipar envío',
  textContentModal:
    'Tenés transferencias programadas para el próximo día hábil y podés anticiparlas. Tené en cuenta que aunque anticipes el envío, es posible que la ejecución ocurra en la fecha programada.',
};

export const TEXT_MODAL_ASYNC_ERROR = {
  title: 'Algo no salió como esperábamos',
};

export const asyncModalInProgressText = (
  <>
    Estamos procesando el envío y puede demorar unos minutos. <br />
    Si necesitás seguir operando, volvé a la sección <strong>Transferencias.</strong>
    <br />
    No te preocupes, el proceso seguirá en curso.
  </>
);

export const asyncModalIsDoneText = (
  <>
    Cuando finalice podrás verificar si todo se procesó correctamente desde
    <br />
    la sección <strong>Reportes.</strong>
  </>
);

export const asyncModalErrorText = (
  <>
    Para verificar si las transferencias se enviaron correctamente, revisá el estado en la sección <strong>Reportes.</strong>
  </>
);

export const WizardKey = {
  titleEntityKey: 'Ingreso de clave',
  descEntityKey: 'Este es el último paso antes de enviar las transferencias, conocé cómo funciona.',
  titleSummary: 'Resumen de envío',
  descSummary: (
    <>
      ¡Verificá que los datos sean los correctos! Revisá la <strong>cantidad de transferencias</strong> y <strong>montos totales</strong>.
    </>
  ),
  titleOptionKey: 'Ingresar clave MAC o Empresa',
  descOptionKey: 'Elegí con qué clave hacer el envío, ingresala y ¡listo!',
};
