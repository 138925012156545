import { colors } from '../../styled/generalStyles';

const ActionTef = {
  padding: '5px',
  display: 'inline-flex',
  marginBottom: '14px',
};

const CustomBox = {
  color: colors.Secondary100,
  cursor: 'pointer',
  marginLeft: '8px',
};

const SelectedStyle = {
  bg: colors.Primary50,
};

const TheadCustom = {
  background: colors.Neutral50,
  height: '40px',
};

const ThCustom = {
  textTransform: 'capitalize',
  padding: '0px',
  whiteSpace: 'nowrap',
};

const HoverStyle = {
  _hover: {
    bg: colors.Grey50,
  },
};

const BoxTable = {
  overflowX: 'auto',
  position: 'relative',
  overflowY: 'hidden',
};

export { ActionTef, BoxTable, CustomBox, HoverStyle, SelectedStyle, ThCustom, TheadCustom };
