import { Text } from '@chakra-ui/react';
import { IDS } from '@components/BottomNav/constants';

const texts = {
  title: 'Transferencias agrupadas por cuenta de débito y fecha de solicitud',
  date: 'Fecha de solicitud',
  transfers: (count: number) => `${count} transferencia${count > 1 ? 's' : ''}`,
  total: 'Total a debitar de la cuenta',
};

const testIds = {
  summaryTable: 'summaryTable',
  summaryItem: 'summaryItem',
  skeleton: 'skeleton',
};

const WIZARD_IDS = {
  step3: 'mc-wizard-step3',
  step3Attempts: 'mc-wizard-step3-attempts',
};

const WIZARD_STEPS = [
  {
    title: 'Resumen del lote',
    description: (
      <Text
        as='span'
        dangerouslySetInnerHTML={{
          __html: 'Revisá la información del lote agrupada por <b>fecha de solicitud</b> y <b>cuenta de débito</b>.',
        }}
      />
    ),
    target: testIds.summaryTable,
    placement: 'bottom',
    offsetExtraX: 1,
    offsetExtraY: 10,
  },
  {
    title: 'Último paso',
    description: 'Hacé clic en Confeccionar y ¡listo!',
    target: IDS.bottomNav,
    placement: 'bottom',
    offsetExtraX: 1,
    offsetExtraY: 10,
  },
];

export { testIds, texts, WIZARD_IDS, WIZARD_STEPS };
