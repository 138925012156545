/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Skeleton } from '@chakra-ui/react';
import { AlertComponent, AlertDescription } from '@wow/alert';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { TablePaginationComponent } from '@wow/table-pagination';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomTable from '../../components/CustomTable';
import FilterTable from '../../components/FilterTable';
import IconBeware from '../../components/Icons/icon-beware';
import IconCancelModal from '../../components/Icons/icon-cancel-modal';
import IconSuccess from '../../components/Icons/icon-checkmark';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import CustomModal from '../../components/Modal';
import ViewNoResult from '../../components/ViewNoResult';
import { DataMessageErrorRequest, LOADING_SCREEN_TEXT, MessageWithOutOTP, PAGE_ACTIONS, routesNames } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import useAsyncProcess from '../../hooks/useAsyncProcess';
import useAuthentication from '../../hooks/useAuthorization';
import useAuthPartial from '../../hooks/useAuthPartial';
import { Lot, ServiceAction, TableDataStateType, TransfersFlow } from '../../Interfaces/transfers';
import { AUTH_TRANSFERS } from '../../utils/configTableFunctions';
import sortDataByGetTransfers from '../../utils/sortDataByGetTransfers';
import { TEXT_MODAL_ASYNC_ERROR } from '../EntityKey/constants';
import {
  ASYNC_MODAL_AUTH_ERROR_TEXT,
  TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS,
  TEXT_MODAL_PROCESS_ASYNCHRONOUS,
  TEXT_MODAL_SEND_ASYNC_IS_DONE,
} from '../EntityToken/constants';
import { COLUMNS } from './configTable';
import {
  LOADING_SCREEN_TEXT_SEND_AUTHORIZE,
  TEXT_MODAL_FAILED_AUTHORIZE,
  TEXT_MODAL_RESPONSE_CERO_AUTHORIZE,
  TEXT_MODAL_SUCCESS,
  USER_WITH_OTP,
} from './constants';
import { CustomTd, CustomTh, Paginator } from './index.styled';

function AuthorizationTransfers() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setPageContext,
    setSizeContext,
    lotsSelected,
    dataLot,
    setResponseOfTransferSelectedByUser,
    dataDetailsTransfers,
    clearSelectedLots,
    setIsFullSelection,
    servicesWorking,
    setErrorUseAuthPartial,
    loadingPartialReject,
    pageContext,
    sizeContext,
    setShowAsyncProcesses,
  } = useTransfersContext();

  const [lotNumber, setLotNumber] = useState<number[]>([]);
  const { timeOutModalAuthPartial, isOpenModalProcessDetails, setIsOpenModalProcessDetails } = useAuthPartial();
  setResponseOfTransferSelectedByUser(dataDetailsTransfers);
  const [otp, setOtp] = useState<string>('Y');
  const {
    refetch,
    status,
    isFetching,
    lots,
    pagination,
    tableDataState,
    refetchGetDetailsOfAllTransfers,
    isFetchingGetDetailsOfAllTransfers,
    setPostDataGetDetails,
    isOpenModalResponseCero,
    statesUpdated,
    isOpenModalGetTransferSelectByUser,
    setIsOpenModalGetTransferSelectByUser,
    setIsOpenModalResponseCero,
    postDataGetDetails,
    setStatesUpdated,
    mutationPostTransfersAction,
    isFetchingAuthorize,
    idProcess,
    isOpenModalSuccess,
    setIsOpenModalSuccess,
    timeOutModalOpen,
    setTimeOutModalOpen,
    getDetailsTransfers,
    firstTime,
    secondTime,
    handleResetView,
  } = useAuthentication(setOtp, lotNumber, setLotNumber, otp);

  const {
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    isAsyncProcess,
    isAsyncProcessDone,
    isFetchingAsyncProcess,
    isAsyncProcessError,
    setIsAsyncProcessError,
    setCloseModalCompletely,
  } = useAsyncProcess(idProcess, ServiceAction.AUT, TransfersFlow.Authorize, setIsOpenModalSuccess);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isOpenModalSuccess) {
      setPageContext(1);
      setLotNumber([]);
      setSizeContext(15);
      clearSelectedLots();
      refetch();
    }
  }, [isOpenModalSuccess]);

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Autorizar - Servicio no disponible',
      content_type: 'No pudimos cargar los datos solicitados',
      item_id: 'Reintentar',
    });
  };

  useEffect(() => {
    if (status === 'error') {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Transferencias - Autorizar - Servicio no disponible',
      });
    }
  }, [status]);

  useEffect(() => {
    if (postDataGetDetails.length > 0) {
      refetchGetDetailsOfAllTransfers();
      setIsOpenModalGetTransferSelectByUser(false);
    }
  }, [postDataGetDetails]);

  useEffect(() => {
    const lotsSelectedFullSelection = lotsSelected.filter(lotItem => !lotItem.isPartialSelection);
    const rowSelected = lotsSelectedFullSelection.map(lotItem => ({
      [lotItem.id]: true,
    }));
    setIsFullSelection(rowSelected);
  }, [lotsSelected]);

  useEffect(() => {
    if (statesUpdated) {
      if (otp === USER_WITH_OTP && !isOpenModalResponseCero) {
        navigate(routesNames.EntityToken);
      } else {
        const transfers = dataDetailsTransfers.map(item => ({
          orderId: item.transfer,
          transferTypeCode: item.type,
        }));
        if (getDetailsTransfers > 0) {
          mutationPostTransfersAction({ transfers });
        }
      }
    }
    !isOpenModalResponseCero && setStatesUpdated(false);
  }, [statesUpdated, isOpenModalResponseCero]);

  const onFilterLot = (numberLot: number[]) => {
    setPageContext(1);
    setLotNumber(numberLot);
  };

  const closeModalGetTransferSelectByUser = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos autorizar las transferencias - Error servicio',
      item_id: 'Cancelar',
    });
    setErrorUseAuthPartial(false);
    setIsOpenModalProcessDetails(false);
    setIsOpenModalGetTransferSelectByUser(false);
  };

  const handleOnClickAuthorize = () => {
    setPostDataGetDetails(sortDataByGetTransfers(lotsSelected, dataLot));
    pushAnalyticsEvent({
      event: otp === USER_WITH_OTP ? 'autorizar_transferencias' : 'autorizar_transferencias_sin_OTP',
      content_type: 'Botones Transferencias',
      item_id: 'Autorizar',
    });
  };

  const actionButtonResponseCero = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos autorizar las transferencias - No disponibles',
      item_id: 'Entendido',
    });
    handleResetView();
  };

  const handleClickModalSuccessSend = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal transferencias autorizadas',
      item_id: 'Entendido',
    });
    setIsOpenModalSuccess(false);
  };

  const onCloseModalProcessedAsynchronouslyGoToTransfers = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Autorizando transferencias',
      item_id: 'Volver a transferencias',
    });
    setIsAsyncProcess(false);
    setCloseModalCompletely(true);
    clearSelectedLots();
    navigate(routesNames.Root, { state: { from: location } });
  };

  const onCloseModalasyncProcessStatusIsDoneyGoToTransfers = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'La autorización se está procesando',
      item_id: 'Entendido',
    });
    setIsAsyncProcessDone(false);
    clearSelectedLots();
    navigate(routesNames.Root, { state: { from: location } });
  };

  const onCloseModalTimeOut = () => {
    setTimeOutModalOpen(false);
    clearSelectedLots();
    navigate(routesNames.Root, { state: { from: location } });
  };

  const isLoading = isFetching || isFetchingGetDetailsOfAllTransfers;
  const hasError = status === 'error' || !servicesWorking;

  useEffect(() => {
    if (!isLoading) {
      setShowAsyncProcesses(!hasError);
    }
  }, [isLoading, hasError]);

  if (status === 'error' || !servicesWorking) {
    return (
      <ViewNoResult
        title={DataMessageErrorRequest.title}
        description={DataMessageErrorRequest.description}
        linkName={DataMessageErrorRequest.linkName}
        Icon={IconDisconnected}
        handleToggleClick={handleToggleAnalytics}
      />
    );
  }

  const handleRetryAgain = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos autorizar las transferencias - Error servicio',
      item_id: 'Reintentar',
    });
    setErrorUseAuthPartial(false);
    setIsOpenModalProcessDetails(false);
    setIsOpenModalGetTransferSelectByUser(false);
    refetchGetDetailsOfAllTransfers();
  };

  const handlePageNavigation = (pageNumber: number, action: string) => {
    setPageContext(pageNumber);
    pushAnalyticsEvent({
      event: 'switch_page',
      selection_type: `${pageNumber} | ${action}`,
    });
  };

  return (
    <>
      {(isFetchingGetDetailsOfAllTransfers || isFetchingAuthorize || isFetchingAsyncProcess || loadingPartialReject) && (
        <LoadingScreen
          title={isFetchingAuthorize ? LOADING_SCREEN_TEXT_SEND_AUTHORIZE.title : LOADING_SCREEN_TEXT.title}
          description={LOADING_SCREEN_TEXT.description}
        />
      )}
      {firstTime ? (
        <>
          {otp === 'N' && (
            <AlertComponent variant='warning' width='100%'>
              <AlertDescription>{MessageWithOutOTP.description}</AlertDescription>
            </AlertComponent>
          )}
          <FilterTable
            onFilterLot={onFilterLot}
            isFetching={isFetching}
            serviceAction={ServiceAction.AUT}
            lotNumber={lotNumber}
            customStyle={otp === 'N'}
          />
        </>
      ) : (
        <Skeleton width='100%' height='64px' borderRadius='4px' margin='0 0 12px 0' data-testid='skeleton-component' />
      )}
      <CustomTable
        showButton
        CustomTd={CustomTd}
        CustomTh={CustomTh}
        data={lots as Lot[]}
        columns={COLUMNS as []}
        size={sizeContext}
        selectedTable={AUTH_TRANSFERS}
        handleClickButtom={handleOnClickAuthorize}
        onFilterLot={onFilterLot}
        tableDataState={tableDataState as TableDataStateType}
        text='Autorizar'
        id='table'
        isFetching={isFetching}
        firstStatusSkeleton={firstTime}
        secondStatusSkeleton={secondTime}
      />
      {firstTime && secondTime ? (
        <TablePaginationComponent
          totalPages={pagination.totalPages || 1}
          currentIndex={pagination.pageNumber && pagination.pageNumber - 1}
          totalRecords={pagination.totalElements}
          perPage={[15, 30, 45]}
          disabledPerPage={pagination.totalElements <= 15}
          currentPerPage={pagination.totalElementsRequested}
          onNext={() => handlePageNavigation(pageContext + 1, PAGE_ACTIONS.NEXT)}
          onPrev={() => handlePageNavigation(pageContext - 1, PAGE_ACTIONS.PREV)}
          onFirst={() => handlePageNavigation(1, PAGE_ACTIONS.FIRST)}
          onLast={() => handlePageNavigation(pagination?.totalPages, PAGE_ACTIONS.LAST)}
          onJump={(value: number) => {
            if (Number(value) < pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.PREV);
            } else if (Number(value) > pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.NEXT);
            }
          }}
          onPerPageChange={(value: number) => {
            setSizeContext(Number(value));
            setPageContext(1);
          }}
        />
      ) : (
        <Box sx={Paginator}>
          <Skeleton width='100%' height='56px' borderRadius='4px' marginTop='6px' data-testid='skeleton-component' />
        </Box>
      )}
      <CustomModal
        isOpen={isOpenModalGetTransferSelectByUser || isOpenModalProcessDetails}
        onCancel={setIsOpenModalGetTransferSelectByUser || setIsOpenModalProcessDetails}
        Icon={IconCancelModal}
        altIcon={TEXT_MODAL_FAILED_AUTHORIZE.altIcon}
        title={TEXT_MODAL_FAILED_AUTHORIZE.title}
        firstDescription={TEXT_MODAL_FAILED_AUTHORIZE.firstDescription}
        firstTextButton={TEXT_MODAL_FAILED_AUTHORIZE.cancelButton}
        secondTextButton={TEXT_MODAL_FAILED_AUTHORIZE.textButtom}
        actionButton={closeModalGetTransferSelectByUser}
        actionButton2={handleRetryAgain}
        size='xl'
        enableFirstButton
        enableSecondButton
        alternativeButtonStyling
        cancelType
      />
      <CustomModal
        isOpen={isOpenModalResponseCero}
        onCancel={setIsOpenModalResponseCero}
        Icon={IconCancelModal}
        altIcon={TEXT_MODAL_RESPONSE_CERO_AUTHORIZE.altIcon}
        title={TEXT_MODAL_RESPONSE_CERO_AUTHORIZE.title}
        firstDescriptionWithBold={TEXT_MODAL_RESPONSE_CERO_AUTHORIZE.firstDescription}
        secondTextButton={TEXT_MODAL_RESPONSE_CERO_AUTHORIZE.textButtom}
        actionButton2={actionButtonResponseCero}
        size='xl'
        enableSecondButton
        alternativeButtonStyling
        cancelType
      />
      <CustomModal
        isOpen={isOpenModalSuccess}
        onCancel={setIsOpenModalSuccess}
        Icon={IconSuccess}
        altIcon={TEXT_MODAL_SUCCESS.altIcon}
        title={TEXT_MODAL_SUCCESS.title}
        firstTextButton={TEXT_MODAL_SUCCESS.textButtom}
        actionButton={handleClickModalSuccessSend}
        enableFirstButton
        size='xl'
        cancelType
      />
      <CustomModal
        isOpen={isAsyncProcessDone}
        enableFirstButton
        actionButton={onCloseModalasyncProcessStatusIsDoneyGoToTransfers}
        firstTextButton={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstTextButton}
        firstDescription={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstDescription}
        Icon={IconInfoPopup}
        title={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.title}
        size='xl'
        onCancel={setIsAsyncProcessDone}
      />
      <CustomModal
        isOpen={timeOutModalOpen || timeOutModalAuthPartial}
        enableFirstButton
        actionButton={onCloseModalTimeOut}
        firstTextButton={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstTextButton}
        firstDescription={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstDescription}
        Icon={IconInfoPopup}
        title={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.title}
        size='xl'
        onCancel={setTimeOutModalOpen}
      />
      <CustomModal
        isOpen={isAsyncProcess}
        enableFirstButton
        actionButton={onCloseModalProcessedAsynchronouslyGoToTransfers}
        firstTextButton={TEXT_MODAL_PROCESS_ASYNCHRONOUS.firstTextButton}
        firstDescription={TEXT_MODAL_PROCESS_ASYNCHRONOUS.firstDescription}
        iconSpinner
        title={TEXT_MODAL_PROCESS_ASYNCHRONOUS.title}
        size='xl'
        onCancel={setIsAsyncProcess}
      />
      <CustomModal
        isOpen={isAsyncProcessError}
        Icon={IconBeware}
        onCancel={setIsAsyncProcessError}
        altIcon={TEXT_MODAL_SEND_ASYNC_IS_DONE.altIcon}
        firstDescriptionWithBold={ASYNC_MODAL_AUTH_ERROR_TEXT}
        title={TEXT_MODAL_ASYNC_ERROR.title}
        firstTextButton={TEXT_MODAL_SEND_ASYNC_IS_DONE.firstTextButton}
        enableFirstButton
        actionButton={() => setIsAsyncProcessError(false)}
        size='xl'
        cancelType
      />
    </>
  );
}

export default AuthorizationTransfers;
