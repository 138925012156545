import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { useNavigate } from 'react-router';

import { OfferingResponse } from '../../Interfaces/api';
import { CHANGE_NOTIFICATION, PRE_CHANGE_NOTIFICATION, RESPONSE_CODES } from './constants';
import CustomErrorChildrenStyle from './styled';

interface Props {
  offeringData: OfferingResponse;
  onCloseAlert: () => void;
}

function OfferingAlertTypes({ onCloseAlert, offeringData }: Props) {
  const formatMonths = (duration: number) => (duration > 1 ? `${duration} meses` : `${duration} mes`);

  const preChangeBonificationMessage = (duration: number) =>
    duration > 1 ? `bonificado por ${duration} meses.` : `bonificado por ${duration} mes.`;

  const preChangeMessage = (hasBonus: boolean, duration: number, planName?: string) => {
    if (hasBonus) {
      return PRE_CHANGE_NOTIFICATION.description.replace('al plan', `al plan ${planName} ${preChangeBonificationMessage(duration)}`);
    }
    return `${PRE_CHANGE_NOTIFICATION.description} ${planName}.`;
  };

  const changeMessage = (hasBonus: boolean, duration: number) => {
    if (hasBonus) {
      return CHANGE_NOTIFICATION.description.replace('mejoramos', `${CHANGE_NOTIFICATION.bonusText} ${formatMonths(duration)}`);
    }
    return CHANGE_NOTIFICATION.description;
  };

  const navigate = useNavigate();

  const onRedirect = () => {
    // navega a la home de plataforma
    navigate('/');
  };

  const NOTIFICATION_STATES = {
    [RESPONSE_CODES.PRE_CHANGE]: (
      <AlertComponent
        buttonLabel={PRE_CHANGE_NOTIFICATION.textButton}
        onClick={onRedirect}
        onClose={onCloseAlert}
        variant='information'
        sx={CustomErrorChildrenStyle}
      >
        <AlertTitle>{PRE_CHANGE_NOTIFICATION.title}</AlertTitle>
        <AlertDescription>
          {preChangeMessage(offeringData?.data?.hasBonus, offeringData?.data?.duration, offeringData?.data?.nextOffering?.name)}
        </AlertDescription>
      </AlertComponent>
    ),
    [RESPONSE_CODES.CHANGE]: (
      <AlertComponent
        buttonLabel={CHANGE_NOTIFICATION.textButton}
        onClick={onRedirect}
        onClose={onCloseAlert}
        variant='success'
        sx={CustomErrorChildrenStyle}
      >
        <AlertTitle>
          {CHANGE_NOTIFICATION.title} {offeringData?.data?.currentOffering?.name}
        </AlertTitle>
        <AlertDescription>{changeMessage(offeringData?.data?.hasBonus, offeringData?.data?.duration)}</AlertDescription>
      </AlertComponent>
    ),
    [RESPONSE_CODES.ERROR]: null,
  };

  return NOTIFICATION_STATES[offeringData?.exception?.code];
}

export default OfferingAlertTypes;
