import { StylesConfig } from 'react-select/dist/declarations/src/styles';

import { SelectOption } from '../Interfaces/transferSetup';
import { colors } from './generalStyles';

const CustomMarginText = {
  margin: '0px',
};

const CustomDateDrawerText = {
  textTransform: 'none',
  margin: '0px',
};

const CustomHeaderText = {
  margin: '0px',
  paddingLeft: '16px',
  textStyle: 'titleSmBold',
  textTransform: 'none',
};

const formStyles = {
  padding: '0',
  margin: '0',
  width: '261px',
};

const addAnimationSkeletonfadeIn = {
  animation: 'fadeIn ease 2s',
  opacity: 1,
};

const withOutAnimationSkeletonfadeIn = {
  animation: 'none',
  opacity: 0,
};

const selectCustomStyles = (status: boolean, width?: number | string, isLeftAddon?: boolean): StylesConfig<SelectOption, false> => ({
  container: base => ({
    ...base,
    width: `${width || '261px'}`,
    height: '36px',
  }),
  control: (base, state) => ({
    ...base,
    fontFamily: 'Inter',
    border: `1px solid ${colors.Secondary400}`,
    boxShadow: state.isFocused ? `0 0 0 1px ${colors.Secondary400}` : 'none',
    borderRadius: '4px',
    fontSize: '14px',
    minHeight: '36px',
    paddingBottom: '8px',
    margin: '0px',
    '&.react-select__control--is-disabled': {
      backgroundColor: colors.White,
      border: `1px solid ${colors.Secondary400}`,
      opacity: '0.4',
    },
    '&:hover': {
      border: `1px solid ${colors.Secondary400}`,
    },
    '&.react-select__control': {
      height: '36px',
      paddingLeft: '4px',
      paddingBottom: '8px',
      margin: '0px',
    },
    ...(status ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn),
    ...(isLeftAddon
      ? {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }
      : {}),
  }),
  placeholder: base => ({
    ...base,
    color: colors.Secondary200,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: colors.Secondary600,
    marginTop: state.selectProps.isSearchable ? '-6px' : '-3px',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
    '&:hover': {
      color: colors.Secondary600,
    },
    '&.react-select__control--is-disabled': {
      opacity: '0.4',
    },
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: colors.Secondary600,
    marginTop: state.selectProps.isSearchable ? '-6px' : '-3px',
    '&:hover': {
      color: colors.Secondary600,
    },
    '&.react-select__control--is-disabled': {
      opacity: '0.4',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: '2px 4px 8px',
  }),
  option: (base, state) => ({
    ...base,
    fontFamily: 'Inter',
    fontSize: '14px',
    backgroundColor: state.isSelected ? colors.Grey50 : colors.White,
    color: colors.Grey120,
    '&:hover': {
      backgroundColor: state.isSelected ? colors.Grey50 : colors.Grey40,
    },
    '&.react-select__option--is-disabled': {
      opacity: '0.4',
    },
    padding: '10px',
  }),
  menu: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
  }),
});

const selectLargeCustomStyles = (status: boolean, width?: number | string, isLeftAddon?: boolean): StylesConfig<SelectOption, false> => ({
  container: base => ({
    ...base,
    width: `${width || '546px'}`,
    height: '36px',
  }),
  control: (base, state) => ({
    ...base,
    fontFamily: 'Inter',
    border: `1px solid ${colors.Secondary400}`,
    boxShadow: state.isFocused ? `0 0 0 1px ${colors.Secondary400}` : 'none',
    borderRadius: '4px',
    fontSize: '14px',
    minHeight: '36px',
    paddingBottom: '8px',
    margin: '0px',
    '&.react-select__control--is-disabled': {
      backgroundColor: colors.White,
      border: `1px solid ${colors.Secondary400}`,
      opacity: '0.4',
    },
    '&:hover': {
      border: `1px solid ${colors.Secondary400}`,
    },
    '&.react-select__control': {
      height: '36px',
      paddingLeft: '4px',
      paddingBottom: '8px',
      margin: '0px',
    },
    ...(status ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn),
    ...(isLeftAddon
      ? {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }
      : {}),
  }),
  placeholder: base => ({
    ...base,
    color: colors.Secondary200,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: colors.Secondary600,
    marginTop: state.selectProps.isSearchable ? '-5px' : '-2px',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
    '&:hover': {
      color: colors.Secondary600,
    },
    '&.react-select__control--is-disabled': {
      opacity: '0.4',
    },
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: colors.Secondary600,
    marginTop: state.selectProps.isSearchable ? '-5px' : '-2px',
    '&:hover': {
      color: colors.Secondary600,
    },
    '&.react-select__control--is-disabled': {
      opacity: '0.4',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: '2px 4px 8px',
  }),
  option: (base, state) => ({
    ...base,
    fontFamily: 'Inter',
    fontSize: '14px',
    backgroundColor: state.isSelected ? colors.Grey50 : colors.White,
    color: colors.Grey120,
    '&:hover': {
      backgroundColor: state.isSelected ? colors.Grey50 : colors.Grey40,
    },
    '&.react-select__option--is-disabled': {
      opacity: '0.4',
    },
    padding: '10px',
  }),
  menu: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
  }),
});

export {
  addAnimationSkeletonfadeIn,
  CustomDateDrawerText,
  CustomHeaderText,
  CustomMarginText,
  formStyles,
  selectCustomStyles,
  selectLargeCustomStyles,
  withOutAnimationSkeletonfadeIn,
};
