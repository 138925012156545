import { useState } from 'react';

function useShowPassword() {
  const [show, setShow] = useState<boolean>(false);

  const showPassword = () => setShow(!show);
  const hidePassword = () => setShow(!show);
  const onMouseLeave = () => setShow(false);

  return { showPassword, hidePassword, onMouseLeave, show };
}

export default useShowPassword;
