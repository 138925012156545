import * as React from 'react';

interface IconDisconnectedProps {
  width?: number;
  height?: number;
}

function IconDisconnected({ width = 76, height = 76 }: IconDisconnectedProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 120 120' fill='none' data-testid='icon-disconnected'>
      <path fill='#EBF0FF' d='m45.882 50.622 19.08-19.08 5.502 5.502-19.08 19.08-5.502-5.502Z' />
      <path
        fill='#2172DC'
        d='m64.956 33.663 3.381 3.38L51.381 54l-3.38-3.381 16.955-16.956Zm0-4.242-2.12 2.12-16.957 16.957-2.12 2.12 2.12 2.122 3.381 3.38 2.121 2.122 2.121-2.121 16.956-16.956 2.121-2.121-2.12-2.121-3.382-3.381-2.12-2.121Z'
      />
      <path fill='#EBF0FF' d='m63.882 68.622 19.08-19.08 5.502 5.502-19.08 19.08-5.502-5.502Z' />
      <path
        fill='#2172DC'
        d='m82.956 51.663 3.381 3.38L69.381 72l-3.38-3.381 16.955-16.956Zm0-4.242-2.12 2.12-16.957 16.957-2.12 2.12 2.12 2.122 3.381 3.38 2.121 2.122 2.121-2.121 16.956-16.956 2.121-2.121-2.12-2.121-3.382-3.381-2.12-2.121Z'
      />
      <path
        fill='#AAC7FF'
        d='M61.314 23.586 75.78 9.12a14.207 14.207 0 0 1 10.104-4.185c3.816 0 7.404 1.488 10.104 4.185l4.002 4.005L108 5.12 114.879 12l-8.004 8.004 4.005 4.002a14.308 14.308 0 0 1 0 20.21L96.414 58.684l-35.1-35.097Z'
      />
      <path
        fill='#2172DC'
        d='M85.887 6.435c3.417 0 6.627 1.329 9.045 3.747l2.943 2.943 2.121 2.121 2.121-2.121L108 7.242 112.758 12l-5.883 5.883-2.121 2.121 2.121 2.121 2.943 2.943a12.699 12.699 0 0 1 3.747 9.045 12.703 12.703 0 0 1-3.747 9.045L96.414 56.562 63.438 23.586l13.404-13.404a12.71 12.71 0 0 1 9.045-3.747ZM108 3l-8.004 8.004-2.946-2.943a15.742 15.742 0 0 0-11.166-4.626 15.742 15.742 0 0 0-11.166 4.626L59.193 23.586l37.218 37.218 15.525-15.525a15.788 15.788 0 0 0 0-22.332l-2.943-2.943L117 12l-9-9Z'
      />
      <path
        fill='#AAC7FF'
        d='m49.02 29.538 6.198-6.201c1.932-1.932 4.404-3.04 6.783-3.04 1.686 0 3.132.562 4.191 1.618l31.89 31.89c2.631 2.634 1.995 7.557-1.425 10.974l-6.201 6.198-41.436-41.44Z'
      />
      <path
        fill='#2172DC'
        d='M62.004 21.798c.89 0 2.16.204 3.132 1.176l31.89 31.89c2.013 2.013 1.362 6.069-1.425 8.853l-5.14 5.139-39.32-39.318 5.139-5.14c1.629-1.628 3.768-2.6 5.724-2.6Zm0-3c-2.661 0-5.556 1.19-7.845 3.48l-7.26 7.26L90.462 73.1l7.26-7.26c4.011-4.011 4.647-9.873 1.425-13.095l-31.89-31.89c-1.383-1.386-3.255-2.058-5.253-2.058Z'
      />
      <path
        fill='#AAC7FF'
        d='M33.534 115.645a14.224 14.224 0 0 1-10.104-4.185l-4.002-4.005L12 114.88l-6.88-6.879 7.426-7.425-4.005-4.005a14.31 14.31 0 0 1 0-20.211l14.466-14.466L58.104 96.99l-14.466 14.466a14.197 14.197 0 0 1-10.104 4.188Z'
      />
      <path
        fill='#2172DC'
        d='m23.007 64.017 32.976 32.976-13.404 13.404a12.71 12.71 0 0 1-9.045 3.747 12.703 12.703 0 0 1-9.045-3.747l-2.943-2.943-2.121-2.121-2.121 2.121L12 112.758 7.242 108l5.304-5.304 2.12-2.121-2.12-2.12-2.943-2.944a12.81 12.81 0 0 1 0-18.09l13.404-13.404Zm0-4.242L7.482 75.3a15.789 15.789 0 0 0 0 22.332l2.943 2.943L3 108l9 9 7.425-7.425 2.943 2.943a15.742 15.742 0 0 0 11.166 4.626c4.04 0 8.082-1.542 11.166-4.626l15.525-15.525-37.218-37.218Z'
      />
      <path
        fill='#AAC7FF'
        d='M57.834 100.53a6.45 6.45 0 0 1-4.608-1.866l-31.89-31.89c-2.631-2.63-2.454-7.095.399-9.948l5.175-5.175 41.442 41.44-5.175 5.174c-1.44 1.44-3.387 2.265-5.343 2.265Z'
      />
      <path
        fill='#2172DC'
        d='m26.907 53.772 39.32 39.321-4.112 4.113a6.123 6.123 0 0 1-4.281 1.824c-1.368 0-2.628-.507-3.55-1.428l-31.89-31.89a4.985 4.985 0 0 1-1.424-3.732c.05-1.515.696-2.97 1.82-4.095l4.117-4.113Zm0-4.242-6.234 6.234c-3.444 3.444-3.621 8.847-.4 12.072l31.89 31.89a7.94 7.94 0 0 0 5.67 2.304 9.086 9.086 0 0 0 6.403-2.703l6.234-6.234L26.907 49.53Z'
      />
    </svg>
  );
}

export default IconDisconnected;
