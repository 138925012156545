import { Currencies } from '@interfaces/transfers';

import { CURRENCY_PESOS, USD_CURRENCY } from '../constants-app';
import { DataTransferModify, TransferDetails } from '../Interfaces/hooks/IUseModifyTransferDrawer';

const mapCurrencyDescription = (description: string): Currencies => {
  switch (description) {
    case CURRENCY_PESOS:
      return Currencies.ARS;
    case USD_CURRENCY:
      return Currencies.USD;
    default:
      throw new Error(`Unsupported currency description: ${description}`);
  }
};

export const setTransferDetailsHelper = (
  transferData: TransferDetails,
  setTransferDetails: React.Dispatch<React.SetStateAction<DataTransferModify>>,
  setIsDisclaimerChecked: React.Dispatch<React.SetStateAction<boolean>>,
  setDisclaimerText: React.Dispatch<React.SetStateAction<string>>,
) => {
  setTransferDetails({
    transferAmount: String(transferData?.amount || 0),
    requestDateTransfer: transferData?.requestDate,
    requestRemarkTransfer: transferData?.remarks || '',
    debitAccount: {
      accountId: transferData.debitAccount.accountId,
      accountNumber: transferData.debitAccount.numberAccount,
      accountType: transferData.debitAccount.descriptionAccountType,
      accountName: transferData.debitAccount.nameAccountDetailView?.nameAccount || '',
      bank: {
        bcoIdBco: transferData.debitAccount.codeBCRA,
        bankName: transferData.debitAccount.nameBank,
      },
      cbu: transferData.debitAccount.cbu,
      cuit: transferData.debitAccount.cuit,
      currency: mapCurrencyDescription(transferData.debitAccount.descriptionCurrency || ''),
      identifier: transferData.debitAccount.identifier,
    },
    creditAccount: {
      accountId: transferData?.creditAccount?.accountId,
      accountNumber: transferData?.creditAccount?.numberAccount,
      accountType: transferData?.creditAccount?.descriptionAccountType,
      accountName: transferData?.creditAccount?.nameAccountDetailView?.nameAccount,
      bank: {
        bcoIdBco: transferData?.creditAccount?.codeBCRA,
        bankName: transferData?.creditAccount?.nameBank,
      },
      cbu: transferData?.creditAccount?.cbu,
      cuit: transferData?.creditAccount?.cuit,
      currency: mapCurrencyDescription(transferData?.creditAccount?.descriptionCurrency || ''),
      useReference: '',
      transferType: '',
      identifier: transferData.creditAccount.identifier,
    },
    concept: {
      value: transferData?.additionalData?.addendaPaymentsThird?.conceptTerCod || '',
      label: transferData?.additionalData?.addendaPaymentsThird?.conceptDescription || '',
    },
    addendaProviders: {
      amountNoteCred: String(transferData?.additionalData?.addendaProviders?.amountNoteCred || 0),
      amountRetention: String(transferData?.additionalData?.addendaProviders?.amountRetention || 0),
      codClient: transferData?.additionalData?.addendaProviders?.codClient || '',
      numberDocCancel: transferData?.additionalData?.addendaProviders?.numberDocCancel || '',
      numberNoteCredir:
        transferData?.additionalData?.addendaProviders?.numberNoteCredir !== '0'
          ? transferData?.additionalData?.addendaProviders?.numberNoteCredir
          : 0,
      numberOrderPayment: transferData?.additionalData?.addendaProviders?.numberOrderPayment || '',
      typeDocId: {
        value: String(transferData?.additionalData?.addendaProviders?.typeDocCod),
        label: String(transferData?.additionalData?.addendaProviders?.typeDocId),
      },
      typeOrderPayment: transferData?.additionalData?.addendaProviders?.typeOrderPayment || '',
      typeRetentionId: {
        value: String(transferData?.additionalData?.addendaProviders?.typeRetentionCod),
        label: String(transferData?.additionalData?.addendaProviders?.typeRetentionId),
      },
    },
    reasons: transferData?.additionalData?.addendaPaymentsThird?.reasons || '',
    voucherCod: transferData?.additionalData?.addendaPaymentsThird?.voucherCod || '',
    disclaimerChecked: transferData?.additionalData?.addendaPaymentsThird?.disclaimerTer || false,
    unifiedShipping: transferData.unifiedShipping,
    lotNumber: transferData.lotNumber,
    idTransferType: transferData.idTransferType,
    numberPayment: transferData.numberPayment,
    stepNumber: transferData.stepNumber,
    transferNumber: transferData.transferNumber,
    subscriberNumber: transferData.subscriberNumber,
    consolidated: transferData.consolidated,
    creationDate: transferData.creationDate,
  });

  setIsDisclaimerChecked(transferData?.additionalData?.addendaPaymentsThird?.disclaimerTer || false);
  setDisclaimerText(transferData?.additionalData?.addendaPaymentsThird?.disclaimerText || '');
};
