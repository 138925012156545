import { AsyncConfectionCompleteTransferRejected, AsyncConfectionTransfer } from '@interfaces/api';
import { Currencies } from '@interfaces/transfers';
import { LotTransfer } from '@interfaces/transferSetup';

function mapAsyncTransfers(transfers: AsyncConfectionCompleteTransferRejected[]) {
  return transfers.map(
    item =>
      ({
        transferNumber: item.idTefConfection,
        debitAccount: {
          bank: {
            bcoIdBco: item.debitAccount.bank.code,
            bankName: item.debitAccount.bank.name,
            logoBank: item.debitAccount.bank.logo,
          },
          accountId: item.debitAccount.id,
          accountNumber: item.debitAccount.number,
          accountType: item.debitAccount.type,
          accountName: item.debitAccount.denomination,
          cbu: item.debitAccount.cbu,
          cuit: item.debitAccount.cuit,
          currency: item.currency,
        },
        creditAccount: {
          bank: {
            bcoIdBco: item.creditAccount.bank.code,
            bankName: item.creditAccount.bank.name,
            logoBank: item.creditAccount.bank.logo,
          },
          accountId: item.creditAccount.id,
          accountNumber: item.creditAccount.number,
          accountType: item.creditAccount.type,
          accountName: item.creditAccount.denomination,
          cbu: item.creditAccount.cbu,
          cuit: item.creditAccount.cuit,
          currency: item.currency,
        },
        transferData: {
          date: item.requestDate,
          amount: item.amount,
          observation: item.observation || '',
          reason: item.addendum?.reasons || '',
          voucher: item.addendum?.voucher || '',
          concept: item.addendum?.concept ? { label: item.addendum?.concept, value: item.addendum?.concept } : null,
          isDisclaimerChecked: item.addendum?.disclaimerAccepted || false,
        },
        additionalInformation: {
          numberClient: item.addendum?.clientCode || '',
          document: item.addendum?.documentType ? { label: item.addendum?.documentType, value: item.addendum?.documentType } : null,
          documentNumber: item.addendum?.documentNumber || '',
          retentionType: item.addendum?.retentionType ? { label: item.addendum?.retentionType, value: item.addendum?.retentionType } : null,
          totalRetentionAmount: item.addendum?.totalRetention || 0,
          paymentOrderType: item.addendum?.orderPaymentType || '',
          paymentOrderNumber: item.addendum?.paymentOrderNumber || '',
          creditNoteNumber: item.addendum?.creditNoteNumber || 0,
          creditNoteAmount: item.addendum?.creditNoteAmount || 0,
        },
      } as LotTransfer),
  );
}

function mapValidatedAsyncTransfers(transfers: AsyncConfectionTransfer[]) {
  const validatedTransfers: LotTransfer[] = [];
  let transferNumber = 0;
  transfers.forEach(item => {
    item.creditAccounts.forEach(creditAccount => {
      transferNumber += 1;
      validatedTransfers.push({
        transferNumber,
        debitAccount: {
          bank: {
            bcoIdBco: item.bankId,
            bankName: item.bankName,
            logoBank: item.bankLogo,
          },
          accountId: item.accountId,
          accountNumber: item.accountNumber,
          accountType: item.accountType,
          accountName: item.denominationAccount,
          cbu: item.cbu,
          cuit: item.cuit,
          currency: item.currency as Currencies,
        },
        creditAccount: {
          accountId: creditAccount.accountId,
          accountNumber: creditAccount.accountNumber,
          accountType: creditAccount.accountType,
          accountName: creditAccount.denominationAccount,
          bank: {
            bcoIdBco: creditAccount.bankId,
            bankName: creditAccount.bankName,
            logoBank: creditAccount.bankLogo,
          },
          cbu: creditAccount.cbu,
          cuit: creditAccount.cuit,
          currency: item.currency as Currencies,
          useReference: '',
          transferType: '',
        },
        transferData: {
          date: item.dateRequest,
          amount: creditAccount.amount,
          observation: creditAccount.observation,
          reason: creditAccount.addendum?.reasons || '',
          voucher: creditAccount.addendum?.voucher || '',
          concept: creditAccount.addendum?.concept
            ? { label: creditAccount.addendum?.concept, value: creditAccount.addendum?.concept }
            : null,
          isDisclaimerChecked: creditAccount.addendum?.disclaimerAccepted || false,
        },
        additionalInformation: {
          numberClient: creditAccount.addendum?.clientCode || '',
          document: creditAccount.addendum?.documentType
            ? { label: creditAccount.addendum?.documentType, value: creditAccount.addendum?.documentType }
            : null,
          documentNumber: creditAccount.addendum?.documentNumber || '',
          retentionType: creditAccount.addendum?.retentionType
            ? { label: creditAccount.addendum?.retentionType, value: creditAccount.addendum?.retentionType }
            : null,
          totalRetentionAmount: creditAccount.addendum?.totalRetention || 0,
          paymentOrderType: creditAccount.addendum?.orderPaymentType || '',
          paymentOrderNumber: creditAccount.addendum?.paymentOrderNumber || '',
          creditNoteNumber: creditAccount.addendum?.creditNoteNumber || 0,
          creditNoteAmount: creditAccount.addendum?.creditNoteAmount || 0,
        },
      });
    });
  });
  return validatedTransfers;
}

export { mapAsyncTransfers, mapValidatedAsyncTransfers };
