const lotItem = {
  display: 'flex',
  fontWeight: 600,
  paddingLeft: 0,
};

const textChild = {
  fontSize: '14px',
  textAlign: 'center',
};

const unorderedListStyle = {
  fontSize: '14px',
  justifyContent: 'flex-start',
  listStyleType: 'none',
  marginLeft: 0,
  paddingLeft: 0,
  textAlign: 'left',
  width: '100%',
};

const unifiedShippingChildrenBox = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: '43%',
};

const textLot = {
  marginLeft: '4px',
};

export { lotItem, textChild, textLot, unifiedShippingChildrenBox, unorderedListStyle };
