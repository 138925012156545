import { Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { ARS_CURRENCY, CURRENCY_DOLAR, CURRENCY_PESOS } from '../../constants-app';
import { LotDetail } from '../../Interfaces/transfers';
import { CustomMarginText } from '../../styled/global.styled';
import { decimalFormat } from '../../utils/FormatChange';
import { ALL_STATUS_TRANSFERS } from '../AuthorizationTransfers/constants';
import CreditAccountCell from './helper';
import { ColorFontsHeaderTable } from './index.styled';

const columnHelper = createColumnHelper<LotDetail>();

export const COLUMNSMODIFY = [
  columnHelper.accessor('transferNumber', {
    id: 'numeroTransferencia',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Nro. Transferencia
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm'>
        {row.original.transferNumber}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.creditAccount.bankName} ${row.creditAccount.accountName} ${row.creditAccount.cbu}`, {
    id: 'creditAccount',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Cuenta crédito
      </Text>
    ),
    cell: CreditAccountCell,
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'importe',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Importe
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='labelSmBold'>
        {`${row.original.currency === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR} ${
          row.original.amount === 0 ? ' ***' : decimalFormat(row.original.amount)
        }`}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'statu',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Estado
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm'>
        {ALL_STATUS_TRANSFERS[row.original.status]}
      </Text>
    ),
    footer: info => info.column.id,
  }),
];
