import { AsyncProcess, AsyncProcessStatus } from '@interfaces/api';

import { TEXTS } from './constants';
import { View } from './interfaces';

function getView(process: AsyncProcess) {
  const view: View = {
    badge: 'info-light',
    badgeText: TEXTS.status.inProgress,
    description: TEXTS.inProgress,
    linkText: TEXTS.linkText,
  };
  switch (process.status) {
    case AsyncProcessStatus.inProcess:
    case AsyncProcessStatus.awaitingResult:
      view.badge = 'info-light';
      view.badgeText = TEXTS.status.inProgress;
      view.description = TEXTS.inProgress;
      return view;
    case AsyncProcessStatus.success:
      view.badge = 'success-light';
      view.badgeText = TEXTS.status.success;
      view.description = TEXTS.success(process.lotNumber);
      return view;
    case AsyncProcessStatus.totalRejection:
      view.badge = 'error-light';
      view.badgeText = TEXTS.status.error;
      view.description = TEXTS.error;
      return view;
    case AsyncProcessStatus.partialRejection:
      view.badge = 'warning-light';
      view.badgeText = TEXTS.status.partial;
      view.description = TEXTS.partial;
      return view;
    case AsyncProcessStatus.pending:
      view.badge = 'warning-light';
      view.badgeText = TEXTS.status.pending;
      view.description = TEXTS.pending;
      if (process.scheduleNotEnabledError) {
        view.description = TEXTS.invalidDate;
        view.linkText = TEXTS.linkTextDetail;
      }
      return view;
    default:
      view.badge = 'error-light';
      view.badgeText = TEXTS.status.error;
      view.description = TEXTS.serviceError;
      return view;
  }
}

export { getView };
