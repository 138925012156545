const texts = {
  errorTitle: 'La información no está disponible',
  errorDescription: 'Se produjo un error en la carga de cuentas, volvé a intentarlo.',
  retry: 'Reintentar',
};

const TestIds = {
  cardAccountError: 'cardAccountError',
  btnRetry: 'btnRetry',
};

export { TestIds, texts };
