import { DrawerCloseButton, DrawerHeader, Flex, Text } from '@chakra-ui/react';
import { Info, Package } from 'react-feather';

import { CustomMarginText } from '../../../../../styled/global.styled';
import { getTransferText, MODIFY_DRAWER_TEXTS } from '../constants';
import { CloseButton, drawerHeaderStyles, flexEndStyle, flexStyles, infoStyle, packageStyle } from '../styled';
import { TransferDetailsProps } from './interfaces';

function TransferHeaderDetails({ transferNumber, TempAccount, LotNumberTemp }: TransferDetailsProps) {
  return (
    <DrawerHeader sx={drawerHeaderStyles}>
      <Flex sx={flexEndStyle}>
        <DrawerCloseButton sx={CloseButton} />
      </Flex>
      <Text textStyle='h4Bold' sx={CustomMarginText}>
        {getTransferText(transferNumber)}
      </Text>
      <Flex sx={flexStyles}>
        <Package style={packageStyle} />
        <Text textStyle='labelXs'>{MODIFY_DRAWER_TEXTS.TransferTypeTitle}</Text>
        <Text textStyle='labelXsBold' marginLeft='2px' marginRight='20px'>
          {TempAccount}
        </Text>
        <Info style={infoStyle} />
        <Text textStyle='labelXs'>{MODIFY_DRAWER_TEXTS.LotNumberTitle}</Text>
        <Text textStyle='labelXsBold' marginLeft='2px'>
          {LotNumberTemp}
        </Text>
      </Flex>
    </DrawerHeader>
  );
}

export default TransferHeaderDetails;
