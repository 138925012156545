import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ACTION_TYPE, ACTIONS_TO_SEND, routesNames } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { PostDataTransfersAction } from '../Interfaces/api';
import { Lot, Pagination as PaginationInterfaces, ServiceAction, TableDataStateType, TransfersFlow } from '../Interfaces/transfers';
import { getDetailsOfAllTransfers, getLots, postTransfersAction } from '../services/api';
import { getServiceParams } from '../utils/configTableFunctions';
import { formatSelectedTransfersTypesCodes } from '../utils/formatSelectedTransfersTypesCodesParam';
import sortDataByGetTransfers from '../utils/sortDataByGetTransfers';
import { EXCEPTION_CODES } from '../views/AnnulTransfers/constants';
import useAsyncProcess from './useAsyncProcess';

function useAnnulTransfers() {
  const navigate = useNavigate();
  const {
    pageContext,
    sizeContext,
    lotsSelected,
    dataLot,
    panelSelected,
    dataDetailsTransfers,
    servicesWorking,
    orderPropertyContext,
    selectedTransfersTypesCodes,
    amountRanges,
    setPageContext,
    setSizeContext,
    setServicesWorking,
    setIsFullSelection,
    setDataDetailsTransfers,
    setDataTotalsTransfers,
    clearSelectedLots,
    setTransfersRejected,
    setHasData,
    setTransfersAccepted,
    setShowAsyncProcesses,
    setIsLoadingLots,
  } = useTransfersContext();

  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [secondTime, setSecondTime] = useState<boolean>(false);
  const [lots, setLots] = useState<Lot[]>([]);
  const [lotNumber, setLotNumber] = useState<number[]>([]);
  const [tableDataState, setTableDataState] = useState<TableDataStateType>();
  const [pagination, setPagination] = useState<PaginationInterfaces>({
    totalElements: 0,
    totalPages: 0,
    totalElementsRequested: 0,
    pageNumber: 0,
  });
  const [showAnnulConfirmationModal, setAnnulConfirmationModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showZeroModal, setShowZeroModal] = useState<boolean>(false);
  const [isAnnullingLots, setIsAnnullingLots] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [idProcess, setIdProcess] = useState<number | null>(null);
  const [isTimeOutDetailError, setIsTimeOutDetailError] = useState<boolean>(false);
  const [isReload, setIsReload] = useState(false);

  const {
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    isAsyncProcess,
    isAsyncProcessDone,
    isFetchingAsyncProcess,
    isAsyncProcessError,
    setIsAsyncProcessError,
    setCloseModalCompletely,
  } = useAsyncProcess(idProcess, ServiceAction.ANU, TransfersFlow.Annul, setShowSuccessModal);

  const params = {
    size: String(sizeContext),
    page: String(pageContext),
    lotNumber: lotNumber.length > 0 ? lotNumber.join(';') : null,
    transferType: formatSelectedTransfersTypesCodes(selectedTransfersTypesCodes),
    ...getServiceParams(TransfersFlow.Annul),
    orderProperty: orderPropertyContext,
    ...amountRanges,
    action: ACTION_TYPE.ANU,
  };

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Anular - Servicio no disponible',
      content_type: 'Error de servicio',
      item_id: 'Reintentar',
    });
  };

  const onFilterLot = (numberLot: number[]): void => {
    setPageContext(1);
    setLotNumber(numberLot);
  };

  const getTransfersData = () => {
    return { transfers: dataDetailsTransfers.map(({ transfer, type }) => ({ orderId: transfer, transferTypeCode: type })) };
  };

  const onClickRetry = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos anular las TEFs - Error servicio',
      item_id: 'Reintentar',
    });
    setShowErrorModal(false);
    setIsAnnullingLots(true);
    isErrorGetDetails || (isTimeOutDetailError && refetchGetDetailsOfAllTransfers());
    isErrorAnull && mutationPostTransfersAction(getTransfersData());
  };

  const onClickAnull = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Confirmación de anulación de TEFs',
      item_id: 'Sí, anular',
    });
    refetchGetDetailsOfAllTransfers();
    setIsAnnullingLots(true);
    setAnnulConfirmationModal(false);
  };

  const clearTable = () => {
    setLotNumber([]);
    clearSelectedLots();
    setLots([]);
    setFirstTime(false);
    setSecondTime(false);
    setIsReload(true);
    refetchLots();
  };

  const onCloseModalZeroAnull = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos anular las TEFs - No disponibles',
      item_id: 'Entendido',
    });
    setShowZeroModal(false);
    clearTable();
  };

  useEffect(() => {
    const lotsSelectedFullSelection = lotsSelected.filter(lotItem => !lotItem.isPartialSelection);
    const rowSelected = lotsSelectedFullSelection.map(lotItem => ({
      [lotItem.id]: true,
    }));
    setIsFullSelection(rowSelected);
  }, [lotsSelected]);

  useEffect(() => {
    if (dataDetailsTransfers?.length) {
      mutationPostTransfersAction(getTransfersData());
    }
  }, [dataDetailsTransfers]);

  const {
    refetch: refetchLots,
    isFetching,
    isError,
  } = useQuery(['get-lots-transfers', params], () => getLots(params).then(res => res.data), {
    retry: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
        setServicesWorking(false);
      } else {
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Transferencias - Anular',
        });
        setServicesWorking(true);
        setLots(data?.data?.lots);
        setTableDataState(data);
        setPagination(data?.paging);
        setHasData(data?.data?.lots.length > 0);
        if (data?.data?.lots.length < 1) {
          pushAnalyticsEvent({
            event: 'trackContentGroup',
            content_group: 'Transferencias - Anular - Sin TEFs pendientes',
          });
        }
      }
      setFirstTime(true);
    },
    onError: () => {
      setServicesWorking(false);
    },
  });

  const {
    refetch: refetchGetDetailsOfAllTransfers,
    isFetching: isFetchingGetDetailsOfAllTransfers,
    isError: isErrorGetDetails,
  } = useQuery(
    ['get-details-all-transfers'],
    () => getDetailsOfAllTransfers(sortDataByGetTransfers(lotsSelected, dataLot), ACTIONS_TO_SEND[panelSelected]).then(res => res.data),
    {
      enabled: false,
      retry: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
          setShowErrorModal(true);
          setIsTimeOutDetailError(true);
        } else {
          setDataDetailsTransfers(data?.data?.tranfers);
          setDataTotalsTransfers(data?.data?.totalAmount);
          setIsTimeOutDetailError(false);
          if (!data?.data?.tranfers?.length) {
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'No podemos anular las TEFs - No disponibles',
            });
            setShowZeroModal(true);
            setIsAnnullingLots(false);
          }
        }
      },
      onError: () => {
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos anular las TEFs - Error servicio',
        });
        setShowErrorModal(true);
        setIsAnnullingLots(false);
      },
      onSettled: () => {
        setAnnulConfirmationModal(false);
      },
    },
  );

  const {
    mutate: mutationPostTransfersAction,
    isLoading: isLoadingAnull,
    isError: isErrorAnull,
  } = useMutation(
    (data: PostDataTransfersAction) => {
      return postTransfersAction(ServiceAction.ANU, data).then(res => res.data);
    },
    {
      onSuccess: response => {
        const exceptionCode = response?.exception.code;
        switch (exceptionCode) {
          case EXCEPTION_CODES.success:
            pushAnalyticsEvent({
              event: 'transferencia_anulada',
              content_group: 'Transferencias - Resumen de anulación',
              status: 'Anuladas exitosamente',
            });
            setShowSuccessModal(true);
            break;
          case EXCEPTION_CODES.totalRejection:
            pushAnalyticsEvent({
              event: 'transferencia_anulada',
              content_group: 'Transferencias - Resumen de anulación',
              status: 'Rechazo total',
            });
            setTransfersRejected(response.data.transfersRejected);
            navigate(routesNames.ResultAnnul);
            break;
          case EXCEPTION_CODES.partialRejection:
            setTransfersRejected(response.data.transfersRejected);
            setTransfersAccepted(response.data.transfersAccepted);
            navigate(routesNames.ResultAnnul);
            break;
          case EXCEPTION_CODES.asyncProcess:
            setIdProcess(response.data.processId);
            break;
          case EXCEPTION_CODES.timeOut:
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'La anulación se está procesando',
            });
            setIsAsyncProcessDone(true);
            break;
        }
      },
      onError: () => {
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos anular las TEFs - Error servicio',
        });
        setShowErrorModal(true);
      },
      onSettled: () => {
        setIsAnnullingLots(false);
      },
    },
  );

  useEffect(() => {
    refetchLots();
  }, []);

  useEffect(() => {
    if (isAsyncProcessError) {
      clearTable();
    }
  }, [isAsyncProcessError]);

  const onCloseModalProcessedAsynchronouslyGoToTransfers = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Anulando transferencias',
      item_id: 'Volver a transferencias',
    });
    setIsAsyncProcess(false);
    setCloseModalCompletely(true);
    clearTable();
  };

  const onCloseModalAsyncProcessStatusIsDoneGoToTransfers = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'La anulación se está procesando',
      item_id: 'Entendido',
    });
    setIsAsyncProcessDone(false);
    clearTable();
  };

  const onCloseModalError = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos anular las TEFs - Error servicio',
      item_id: 'Cancelar',
    });
    setShowErrorModal(false);
    setIsAnnullingLots(false);
    setIsTimeOutDetailError(false);
  };

  const handlePageNavigation = (pageNumber: number, action: string) => {
    setPageContext(pageNumber);
    pushAnalyticsEvent({
      event: 'switch_page',
      selection_type: `${pageNumber} | ${action}`,
    });
  };

  const isLoading = isFetching || isFetchingGetDetailsOfAllTransfers;
  const hasError = isError || isErrorGetDetails || isTimeOutDetailError || !servicesWorking;

  useEffect(() => {
    if (!isLoading) {
      setShowAsyncProcesses(!hasError);
    }
  }, [isLoading, hasError]);

  useEffect(() => {
    setSecondTime(!isFetching);
    setIsLoadingLots(!firstTime && !isReload ? isFetching : false);
  }, [firstTime, isFetching, isReload]);

  useEffect(() => {
    showSuccessModal && clearTable();
  }, [showSuccessModal]);

  return {
    isFetching,
    isError,
    lots,
    sizeContext,
    tableDataState,
    pagination,
    pageContext,
    showAnnulConfirmationModal,
    isLoadingAnull,
    showErrorModal,
    showZeroModal,
    isAnnullingLots,
    showSuccessModal,
    servicesWorking,
    idProcess,
    isAsyncProcess,
    isAsyncProcessDone,
    isFetchingAsyncProcess,
    isAsyncProcessError,
    lotNumber,
    firstTime,
    secondTime,
    onCloseModalError,
    onFilterLot,
    handleToggleAnalytics,
    setPageContext,
    setSizeContext,
    setAnnulConfirmationModal,
    setShowErrorModal,
    setShowSuccessModal,
    onCloseModalZeroAnull,
    onClickAnull,
    onClickRetry,
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    setIsAsyncProcessError,
    onCloseModalAsyncProcessStatusIsDoneGoToTransfers,
    onCloseModalProcessedAsynchronouslyGoToTransfers,
    handlePageNavigation,
  };
}

export default useAnnulTransfers;
