import { BreadcrumbComponent } from '@wow/breadcrumb';

import useBreadcrumb from '../../../hooks/useBreadcrumb';
import { breadcrumb, transfersDisabled } from './index.styled';

function Breadcrumb() {
  const { breadcrumbItems, inConfection, hasTransfersPermission } = useBreadcrumb();
  return (
    <BreadcrumbComponent
      sx={{ ...breadcrumb, ...(inConfection && !hasTransfersPermission ? transfersDisabled : {}) }}
      items={breadcrumbItems}
    />
  );
}

export default Breadcrumb;
