import { Box, Skeleton, TabList } from '@chakra-ui/react';

import CustomTab from '../CustomTabs';
import TABS from './constants';
import TransferSetupProps from './interfaces';
import { displayStyle, noDisplayStyle } from './styled';

function TransferSetupTabs({ showManual, showFile, showModel, statusPermission }: TransferSetupProps) {
  return (
    <Box id='tabs' margin='-8px' padding='8px'>
      <TabList>
        {statusPermission ? (
          <>
            <CustomTab sx={showManual ? displayStyle : noDisplayStyle} viewTablesTab>
              {TABS.tab1}
            </CustomTab>
            <CustomTab sx={showFile ? displayStyle : noDisplayStyle} viewTablesTab>
              {TABS.tab2}
            </CustomTab>
            <CustomTab sx={showModel ? displayStyle : noDisplayStyle} viewTablesTab>
              {TABS.tab3}
            </CustomTab>
          </>
        ) : (
          <Skeleton width='58px' height='24px' margin='15px' borderRadius='4px' />
        )}
      </TabList>
    </Box>
  );
}

export default TransferSetupTabs;
