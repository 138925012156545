import { useCallback, useState } from 'react';

import mockFileEntries from '../../test/__mocks__/Drawer/mockFileList';
import { useTransfersContext } from '../context/TransfersContext';
import { File } from '../Interfaces/transfers';
import { UseDrawerStateProps } from '../Interfaces/transferSetup';

export function useDrawerState({ initialFiles = mockFileEntries, onCancel }: UseDrawerStateProps) {
  const [files] = useState<File[]>(initialFiles);
  const [tableDataState] = useState({ data: initialFiles });
  const { setFileSelected } = useTransfersContext();

  const handleRemoveFile = useCallback(() => {
    setFileSelected({ fileId: '', fileName: '', dateTime: '' });
    onCancel(false);
  }, [setFileSelected, onCancel]);

  return {
    files,
    tableDataState,
    handleRemoveFile,
  };
}
