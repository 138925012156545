import { Flex, Skeleton } from '@chakra-ui/react';

import { TestIds } from '../../constants';
import { BoxSkeleton, SkeletonBody, SkeletonCircle, SkeletonFooter, SkeletonHeader1, SkeletonHeader2 } from './index.styled';

function SkeletonAccordion() {
  return (
    <Flex flexDirection='column' sx={BoxSkeleton} data-testid={TestIds.skeletonAccordion}>
      <Flex padding='8px 16px'>
        <Flex padding='12px 0' alignItems='center' width='100%' justifyContent='space-between'>
          <Skeleton style={SkeletonHeader1} />
          <Skeleton style={SkeletonCircle} />
        </Flex>
      </Flex>
      <Flex padding='12px 12px 12px 36px'>
        <Flex gap='10px' flexDirection='column' width='100%'>
          <Skeleton style={SkeletonBody} />
          <Skeleton style={SkeletonFooter} />
        </Flex>
      </Flex>
      <Flex padding='8px 16px'>
        <Flex padding='12px 0' alignItems='center' width='100%' justifyContent='space-between'>
          <Skeleton style={SkeletonHeader2} />
          <Skeleton style={SkeletonCircle} />
        </Flex>
      </Flex>
      <Flex padding='12px 12px 12px 36px'>
        <Skeleton style={SkeletonFooter} />
      </Flex>
    </Flex>
  );
}

export default SkeletonAccordion;
