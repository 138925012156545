const BoxSkeleton = {
  flexDirection: 'column',
  width: '100%',
  gap: '12px',
  padding: '0px 30px',
};

const SkeletonHeaderDebitCardTitle = {
  width: '136px',
  height: '24px',
  borderRadius: '4px',
};

const SkeletonHeaderCreditCardTitle = {
  width: '136px',
  height: '24px',
  borderRadius: '4px',
  marginTop: '36px',
};

const SkeletonBlockForm = {
  width: '306px',
  height: '20px',
  borderRadius: '4px',
};

const SkeletonDataTitle = {
  height: '24px',
  width: '198px',
  borderRadius: '4px',
};

const FlexContainerStyles = {
  flexDirection: 'column',
  width: '100%',
  gap: '26px',
  marginTop: '36px',
};

const SkeletonCustomStyle = {
  height: '52px',
  width: '100%',
  marginRight: '24px',
  borderRadius: '4px',
};

const SkeletonStylesBottom = {
  height: '52px',
  width: '100%',
  borderRadius: '4px',
};

const SkeletonBasicStyles = {
  height: '52px',
  width: '100%',
  borderRadius: '4px',
};

const disclaimerSkeleton = {
  height: '60px',
  width: '100%',
  marginTop: '48px',
  borderRadius: '4px',
};

const terFieldsStyles = {
  flexDirection: 'column',
  width: '100%',
  gap: '26px',
};

export {
  BoxSkeleton,
  disclaimerSkeleton,
  FlexContainerStyles,
  SkeletonBasicStyles,
  SkeletonBlockForm,
  SkeletonCustomStyle,
  SkeletonDataTitle,
  SkeletonHeaderCreditCardTitle,
  SkeletonHeaderDebitCardTitle,
  SkeletonStylesBottom,
  terFieldsStyles,
};
