/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { IconButtonComponent } from '@wow/button';
import { useRef } from 'react';
import { ZoomIn } from 'react-feather';

import BankLogo from '../../components/BankLogo';
import { ARS_CURRENCY, CURRENCY_DOLAR, CURRENCY_PESOS } from '../../constants-app';
import { LotDetail } from '../../Interfaces/transfers';
import { CustomMarginText } from '../../styled/global.styled';
import { decimalFormat } from '../../utils/FormatChange';
import { ALL_STATUS_TRANSFERS } from '../AuthorizationTransfers/constants';
import CheckboxCell from './components/CheckboxCell';
import CheckboxHeader from './components/CheckboxHeader';
import { CREDIT_ACCOUNT_TOOLTIP_MAX_WIDTH, TRANSFER_TYPE_AFI, TRANSFER_TYPE_B2B, TRANSFER_TYPE_PDS } from './constants';
import { ColorFontsHeaderTable, Separator } from './index.styled';

const columnHelper = createColumnHelper<LotDetail>();

export const COLUMNS = [
  columnHelper.accessor(row => row, {
    id: 'select',
    enableSorting: false,
    header: ({ table }) => <CheckboxHeader table={table} />,
    cell: ({ row, table }) => <CheckboxCell row={row} table={table} />,
    footer: info => info.column.id,
  }),
  columnHelper.accessor('transferNumber', {
    id: 'numeroTransferencia',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Nro. Transferencia
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm'>
        {row.original.transferNumber}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.creditAccount.bankName} ${row.creditAccount.accountName} ${row.creditAccount.cbu}`, {
    id: 'creditAccount',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Cuenta crédito
      </Text>
    ),
    cell: ({ row }) => {
      const elemText = useRef<HTMLInputElement | null>(null);
      const creditAccountElemTextWidth = elemText?.current?.clientWidth || '';
      const hasMaxLength = Number(creditAccountElemTextWidth) >= CREDIT_ACCOUNT_TOOLTIP_MAX_WIDTH;
      const tooltipLabel = hasMaxLength && (
        <Box>
          <Box>
            <Text as='span' textStyle='bodySmSemi'>
              {row.original.creditAccount.bankName}
            </Text>
          </Box>
          <Box>
            <Text as='span' textStyle='bodySm'>
              {row.original.creditAccount.accountName}
            </Text>
            {row.original.creditAccount.accountName && (
              <Text as='span' marginLeft='2px' textStyle='bodySm'>
                {' | '}
              </Text>
            )}
            <Text as='span' textStyle='bodySm'>
              {row.original.creditAccount.cuit}
            </Text>
          </Box>
          <Box>
            <Text as='span' textStyle='bodySm'>
              {row.original.creditAccount.cbu || row.original.debitAccount.accountNumber}
            </Text>
            {(row.original.creditAccount.cbu || row.original.debitAccount.accountNumber) && (
              <Text as='span' textStyle='bodySm'>
                {' | '}
              </Text>
            )}
            <Text as='span' textStyle='bodySm'>
              {row.original.creditAccount.accountType}
            </Text>
          </Box>
        </Box>
      );

      return (
        <Box display='flex' align-items='center'>
          <Flex alignItems='center'>
            <BankLogo
              style={{ maxWidth: '32px', width: '32px', height: '32px' }}
              src={row.original.creditAccount?.bankCodeBCRA}
              transferType={row.original.transferType}
            />
          </Flex>
          <Flex alignItems='center'>
            {(row.original.transferType === TRANSFER_TYPE_PDS ||
              row.original.transferType === TRANSFER_TYPE_B2B ||
              row.original.transferType === TRANSFER_TYPE_AFI) && (
              <Text sx={CustomMarginText} textStyle='labelSmBold'>
                {row.original.creditAccount.bankName}
              </Text>
            )}
            {row.original.transferType !== TRANSFER_TYPE_AFI &&
              row.original.transferType !== TRANSFER_TYPE_B2B &&
              row.original.transferType !== TRANSFER_TYPE_PDS && (
                <Tooltip label={tooltipLabel} hasArrow placement='right'>
                  <Box>
                    <Box display='flex'>
                      <Text as='span' textStyle='labelSmBold'>
                        {row.original.creditAccount.bankName}
                      </Text>
                      {row.original.creditAccount.bankName && row.original.debitAccount.accountNumber && (
                        <Text as='span' sx={Separator} textStyle='labelSmBold'>
                          {' | '}
                        </Text>
                      )}
                      <Text ref={elemText} sx={CustomMarginText} textStyle='labelSmBold'>
                        {row.original.creditAccount.accountName}
                      </Text>
                      {row.original.creditAccount.accountName && (
                        <Text as='span' sx={Separator} textStyle='labelSmBold'>
                          {' | '}
                        </Text>
                      )}
                      {row.original.creditAccount.cuit && (
                        <Text as='span' textStyle='labelSmBold'>
                          {row.original.creditAccount.cuit}
                        </Text>
                      )}
                    </Box>
                    {(row.original.creditAccount.cbu ||
                      row.original.creditAccount.accountNumber ||
                      row.original.creditAccount.accountType) && (
                      <Box>
                        <Text as='span' textStyle='bodySm'>
                          {row.original.creditAccount.cbu || row.original.creditAccount.accountNumber}
                        </Text>
                        {(row.original.creditAccount.cbu || row.original.creditAccount.accountNumber) && (
                          <Text as='span' textStyle='bodySm'>
                            {' | '}
                          </Text>
                        )}
                        <Text as='span' textStyle='bodySm'>
                          {row.original.creditAccount.accountType}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              )}
          </Flex>
        </Box>
      );
    },
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'importe',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Importe
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='labelSmBold'>
        {`${row.original.currency === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR} ${
          row.original.amount === 0 ? ' ***' : decimalFormat(row.original.amount)
        }`}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'statu',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Estado
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm' style={{ textTransform: 'none' }}>
        {ALL_STATUS_TRANSFERS[row.original.status]}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'detail',
    enableSorting: false,
    header: '',
    cell: ({ table, row }) => (
      <Tooltip label='Ver detalle de transferencia' hasArrow placement='top'>
        <Flex justifyContent='center'>
          <IconButtonComponent
            aria-label='icon-button'
            variant='icon-text'
            size='i-md'
            onClick={() =>
              table?.options?.meta?.handleToggleDrawer !== undefined &&
              table?.options?.meta?.handleToggleDrawer(row?.original?.transferNumber)
            }
            icon={<ZoomIn />}
          />
        </Flex>
      </Tooltip>
    ),
    footer: info => info.column.id,
  }),
];
