import { Box, Button, CloseButton, Text } from '@chakra-ui/react';
import { Tag } from '@wow/tag';
import { useEffect, useRef, useState } from 'react';

import { IDS, TEXTS } from './constants';
import { Props } from './interfaces';
import { CustomButton, Footer, Header } from './styled';

function FilterMenu({
  children,
  title,
  tag,
  count,
  btnClearIsDisabled,
  btnApplyIsDisabled,
  filterTestId,
  isSelected,
  isDisabled,
  handleClose,
  handleApply,
  handleClear,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleApplyAndClose = () => {
    handleApply();
    onToggleTagFilter();
  };

  const onToggleTagFilter = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
    handleClose();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box position='relative' ref={menuRef}>
      <Tag
        component='filter'
        onClick={onToggleTagFilter}
        size='lg'
        count={count}
        isOpen={isOpen}
        label={tag}
        isSelected={isSelected}
        isDisabled={isDisabled}
        data-testid={filterTestId}
      />
      {isOpen && (
        <Box position='absolute'>
          <Header>
            <Text as='span' textStyle='titleSmBold'>
              {title}
            </Text>
            <CloseButton size='md' variant='light' onClick={onToggleTagFilter} />
          </Header>
          {children}
          <Footer>
            <Button
              sx={CustomButton}
              variant='primary-text'
              isDisabled={btnClearIsDisabled}
              onClick={handleClear}
              data-testid={`${filterTestId}-${IDS.btnClear}`}
            >
              {TEXTS.delete}
            </Button>
            <Button
              sx={CustomButton}
              variant='primary'
              isDisabled={btnApplyIsDisabled}
              onClick={handleApplyAndClose}
              data-testid={`${filterTestId}-${IDS.btnApply}`}
            >
              {TEXTS.apply}
            </Button>
          </Footer>
        </Box>
      )}
    </Box>
  );
}

export default FilterMenu;
