import { AlertTriangle, Calendar, User } from 'react-feather';

import Bank from '../../../../components/Icons/icon-bank';
import Transfer from '../../../../components/Icons/icon-transfer';
import { ResultErrorIcon } from '../../../../Interfaces/transferSetup';

interface Props {
  icon: ResultErrorIcon | string;
}

function ResultIcon({ icon }: Props) {
  switch (icon) {
    case ResultErrorIcon.user:
      return <User />;
    case ResultErrorIcon.calendar:
      return <Calendar />;
    case ResultErrorIcon.bank:
      return <Bank />;
    case ResultErrorIcon.transfer:
      return <Transfer />;
    case ResultErrorIcon.alertTriangle:
    default:
      return <AlertTriangle />;
  }
}

export default ResultIcon;
