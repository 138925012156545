import { colors } from '../../styled/generalStyles';

const card = {
  alignItems: 'normal',
  borderColor: colors.Grey50,
};

const cardContent = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '3px',
  flex: '1 0 0',
  marginLeft: '1rem',
  minHeight: '92px',
};

const cardSection = {
  paddingBottom: '8px',
};

export { card, cardContent, cardSection };
