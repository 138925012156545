/* eslint-disable react/jsx-props-no-spreading */
import { SystemStyleObject, Tab, useTab } from '@chakra-ui/react';
import React from 'react';

import { textTab } from './styled';

type CustomTabProps = {
  children: React.ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
  viewTablesTab?: boolean;
  sx?: SystemStyleObject;
};

const CustomTab = React.forwardRef<HTMLButtonElement, CustomTabProps>((props, ref) => {
  const { isDisabled, sx, ...restProps } = props;
  const tabProps = useTab({ ...restProps, ref, isDisabled });

  return (
    <Tab sx={{ ...textTab, ...(sx || {}) }} {...tabProps} isDisabled={isDisabled}>
      {tabProps.children}
    </Tab>
  );
});

export default CustomTab;
