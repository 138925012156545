import { Td } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../../../../../styled/generalStyles';

export const downloadIconStyle = {
  color: colors.Primary100,
  width: '20px',
  height: '20px',
};

export const drawerHeaderStyles = {
  marginTop: '36px',
  marginLeft: '6px',
};

export const headerTextStyle = {
  margin: '0px 0px 20px 0px',
};

export const CloseButton = {
  background: colors.White,
  border: 'none',
  color: colors.Grey100,
  cursor: 'pointer',
  fontSize: '14px;',
  padding: '0px',
  height: 'auto',
  position: 'relative',
  width: '16px',
  marginBottom: '8px',
  svg: {
    width: '16px',
  },

  '&:active': {
    background: 'none',
  },

  '&:hover': {
    background: 'none',
  },

  '&:focus': {
    background: 'none',
    boxShadow: colors.ShadowNone,
  },
};

export const dateTimeStyles = {
  margin: 0,
  width: '140px',
  textStyle: 'bodySm',
};

export const fileNameStyles = {
  alignItems: 'center',
  minHeight: '48px',
  '& > :first-child': {
    margin: 0,
    paddingLeft: '16px',
    width: '363px',
    textStyle: 'bodySm',
  },
};

export const iconDownloadStyles = {
  justifyContent: 'center',
  width: '40px',
  padding: '0px',
};

export const CustomTd = styled(Td)`
  &:nth-child(3) {
    & > div {
      &:last-child {
        & > div {
          p {
            white-space: wrap;
            overflow: hidden;
            max-width: 400px;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &:nth-child(4) {
    text-align: end;
  }

  tr:last-child & {
    padding: 0px;
  }
`;
