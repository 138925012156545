/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/prefer-default-export */
import { Flex, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Download } from 'react-feather';

import { useTransfersContext } from '../../../../../../context/TransfersContext';
import { File } from '../../../../../../Interfaces/transfers';
import { CustomDateDrawerText, CustomHeaderText } from '../../../../../../styled/global.styled';
import { HISTORICAL_ERROR_DRAWER_TEXTS } from './constants';
import { dateTimeStyles, downloadIconStyle, fileNameStyles, iconDownloadStyles } from './styled';

const columnHelper = createColumnHelper<File>();

export const COLUMNS = [
  columnHelper.accessor(row => `${row.fileName}`, {
    id: 'fileName',
    header: ({ table }) => {
      const hasNoData = table.getRowModel().rows.length === 0;
      return (
        <Text
          sx={{
            ...CustomHeaderText,
            width: hasNoData ? '230px' : 'auto',
          }}
        >
          {HISTORICAL_ERROR_DRAWER_TEXTS.fileNameTitle}
        </Text>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex sx={fileNameStyles}>
          <Text>{row.original.fileName}</Text>
        </Flex>
      );
    },
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.dateTime}`, {
    id: 'date',
    header: () => (
      <Text sx={CustomDateDrawerText} textStyle='titleSmBold'>
        {HISTORICAL_ERROR_DRAWER_TEXTS.DateTitle}
      </Text>
    ),
    cell: ({ row }) => {
      return <Text sx={dateTimeStyles}>{row.original.dateTime}</Text>;
    },
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'download',
    enableSorting: false,
    header: '',
    cell: ({ row }) => {
      const { setFileSelected } = useTransfersContext();
      return (
        <Flex sx={iconDownloadStyles} onClick={() => setFileSelected({ ...row.original, rowId: row.id })}>
          <Download style={downloadIconStyle} />
        </Flex>
      );
    },
    footer: info => info.column.id,
  }),
];
