/* eslint-disable no-shadow */
import { AdditionalInformation, BankAccount, TransferData } from './api';
import { DraftModal } from './modals';
import { File } from './transfers';

export interface StepDetail {
  stepNumber: number;
  description: string;
  bold: string;
}

export interface SubtitleDetail {
  text: string;
  bold: string;
}

export interface ManualTransferTexts {
  title: string;
  subtitle: SubtitleDetail;
  infobox: string;
  infoboxBold?: string;
  steps: StepDetail[];
}

export interface ManualTransferData {
  transferType: string;
  requestDate: string | null;
  description: string | null;
  paymentNumber: string | null;
  consolidated: boolean;
  unifiedShipping: boolean;
  hasDraft: DraftModal | null;
  transfers: LotTransfer[];
  step: number;
  accountsNotAvailable: AccountsNotAvailable | null;
}

export interface LoadFromFile {
  transferType: string | null;
  uploadType: string | null;
  importMode: string | null;
  format: string | null;
  processingType: string | null;
  paymentNumber: string | null;
  consolidated: boolean;
  unifiedShipping: boolean;
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface BaseTransferSetupState {
  consolidated: boolean;
  unifiedShipping: boolean;
}

export type SetFormState<T> = (state: T | ((prevState: T) => T)) => void;

export interface CheckboxLogicHook<T extends BaseTransferSetupState> {
  handleCheckboxChange: (formState: T, setFormState: SetFormState<T>) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface UseDrawerStateProps {
  initialFiles?: File[];
  onCancel: (close: boolean) => void;
}
export interface FormManualTransferSetupProps {
  transferTypesList: SelectOption[];
  holidays: Holiday[] | null;
  status: boolean;
  onSubmit: () => void;
}

export interface Holiday {
  dayDifference: number;
  holidayDate: string;
  holidayDescrip: string;
}

export interface WorkingDay {
  isWorkingDay: boolean;
  nextWorkingDay: string;
}

export interface LotTransfer {
  transferNumber: number;
  debitAccount: BankAccount | null;
  creditAccount: BankAccount | null;
  transferData: TransferData;
  additionalInformation?: AdditionalInformation | null;
  quantityTef?: number;
}

export enum ResultStatus {
  accepted = 'accepted',
  rejected = 'rejected',
  validated = 'validated',
}

export enum ResultErrorCode {
  TEF_GENERIC_ERROR = 'TEF_GENERIC_ERROR',
  TEF_CON_NOT_AUTHORIZED = 'TEF_CON_NOT_AUTHORIZED',
  TEF_CON_DATE_ERROR = 'TEF_CON_DATE_ERROR',
  TEF_CON_SERVICE_NOT_ENABLED = 'TEF_CON_SERVICE_NOT_ENABLED',
  TEF_CON_DEBIT_ACCOUNT_ERROR = 'TEF_CON_DEBIT_ACCOUNT_ERROR',
  TEF_CON_CREDIT_ACCOUNT_ERROR = 'TEF_CON_CREDIT_ACCOUNT_ERROR',
  TEF_CON_NOT_OPERATOR_BANK = 'TEF_CON_NOT_OPERATOR_BANK',
  TEF_CON_NOT_OPERATOR_DEBIT_ACCOUNT = 'TEF_CON_NOT_OPERATOR_DEBIT_ACCOUNT',
  TEF_CON_NOT_OPERATOR_CREDIT_ACCOUNT = 'TEF_CON_NOT_OPERATOR_CREDIT_ACCOUNT',
  TEF_CON_DEBIT_ACCOUNT_USE_ERROR = 'TEF_CON_DEBIT_ACCOUNT_USE_ERROR',
  TEF_CON_CREDIT_ACCOUNT_USE_ERROR = 'TEF_CON_CREDIT_ACCOUNT_USE_ERROR',
  TEF_CON_TEF_NOT_EXIST = 'TEF_CON_TEF_NOT_EXIST',
  TEF_CON_TEF_ERROR = 'TEF_CON_TEF_ERROR',
  TEF_CON_BANK_CREDIT_ACCOUNT_NOT_ACCEPTED_DIFF = 'TEF_CON_BANK_CREDIT_ACCOUNT_NOT_ACCEPTED_DIFF',
  TEF_NEW_CREDITACCOUNT = 'TEF_NEW_CREDITACCOUNT',
  TEF_UNRELATED_ACCOUNT = 'TEF_UNRELATED_ACCOUNT',
  TEF_NON_ENABLED_SCHEDULE = 'TEF_NON_ENABLED_SCHEDULE',
}

export enum ResultErrorCodeId {
  'transfConfeccionErrorNoTienePermisoEspecifico' = 'transf.confeccion.error.noTienePermisoEspecifico',
  'transfConfErrorFechaSolicitud' = 'transf.conf.error.fechaSolicitud',
  'transfConfErrorFechaSolicitudAnterior' = 'transf.conf.error.fechaSolicitud.anterior',
  'tefGestionErrorFSolicitudVencida' = 'tef.gestion.error.fsolicitudvencida',
  'tefGestionErrorFechaSolicitudNoEsDiaHabil' = 'tef.gestion.error.fechasolicitudnoesdiahabil',
  'tefGestionErrorDiasDiferimientoMin' = 'tef.gestion.error.diasdiferimientomin',
  'tefTratamErrorFSolicitudNoEsDiaHabil' = 'tef.tratam.error.fsolicitudnoesdiahabil',
  'transfConfErrorServicioNoHabilitado' = 'transf.conf.error.servicio_no_habilitado',
  'tefGestionErrorCtaControlDebitoNoOperativa' = 'tef.gestion.error.ctacontrol.debito.no.operativa',
  'tefGestionErrorCtaDebHa' = 'tef.gestion.error.ctadebha',
  'tefGestionErrorCuentaDebitoUsoHabilitado' = 'tef.gestion.error.cuentadebitousohabilitado',
  'tefGestionErrorCuentaInhabilitadaDebito' = 'tef.gestion.error.cuentainhabilitadadebito',
  'tefGestionErrorCuentaParteNoHabilitada' = 'tef.gestion.error.cuentapartenohabilitada',
  'tefGestionErrorCtaParteNoTef' = 'tef.gestion.error.ctapartenotef',
  'tefGestionErrorCtaControlCreditoNoOperativa' = 'tef.gestion.error.ctacontrol.credito.no.operativa',
  'tefGestionErrorCtaCredHa' = 'tef.gestion.error.ctacredha',
  'tefGestionErrorCuentaCreditoUsoHabilitado' = 'tef.gestion.error.cuentacreditousohabilitado',
  'tefGestionErrorCuentaContraparteNoHabilitada' = 'tef.gestion.error.cuentacontrapartenohabilitada',
  'tefGestionErrorOperadorNoOperaConBanco' = 'tef.gestion.error.operadornooperaconbanco',
  'tefGestionErrorOpeCtaParteDebito' = 'tef.gestion.error.opectapartedebito',
  'tefGestionErrorOperadorModoUsoDebito' = 'tef.gestion.error.operadormodousodebito',
  'tefGestionErrorOpeCtaParteCredito' = 'tef.gestion.error.opectapartecredito',
  'tefGestionErrorOperadorModoUsoCredito' = 'tef.gestion.error.operadormodousocredito',
  'tefGestionErrorCuentaModoUsoDebito' = 'tef.gestion.error.cuentamodousodebito',
  'tefGestionErrorCuentaModoUsoCredito' = 'tef.gestion.error.cuentamodousocredito',
  'offlineTefTipoTefInexistente' = 'offline.tef.tipoTefInexistente',
  'tefGestionErrorOpeNoTef' = 'tef.gestion.error.openotef',
  'tefTratamErrorNoSePuedeTratarBcoCredNoAceptaDif' = 'tef.tratam.error.nosepuedetratarbcocrednoaceptadif',
  'tefGestionErrorCtaCreditoNuevaNoSuperaTiempEspera' = 'tef.gestion.error.ctacreditonuevanosuperatiempespera',
  'tefGestionErrorRelacionesCuenta' = 'tef.gestion.error.relacionescuenta',
  'tefGestionErrorHorarioHaDebito' = 'tef.gestion.error.horariohadebito',
  'tefGestionErrorHorarioHaCredito' = 'tef.gestion.error.horariohacredito',
}

export enum ResultErrorIcon {
  alertTriangle = 'alert-triangle',
  bank = 'bank',
  calendar = 'calendar',
  user = 'user',
  transfer = 'transfer',
}

export interface ResultError {
  code: ResultErrorCode;
  errors?: string[];
  icon: ResultErrorIcon;
  description: string;
}

export interface ResultRejectedItem {
  error: ResultError;
  count: number;
}

export type LotTransferRejected = LotTransfer & {
  error: ResultError;
};

export interface FiltersApplied {
  usageReferences: string[];
  banks: string[];
}

export enum AccountsNotAvailable {
  debit = 'debit',
  credit = 'credit',
  both = 'both',
  unique = 'unique',
}
