/* eslint-disable global-require */
import { Button, CloseButton, Image, Text } from '@chakra-ui/react';
import IconCancelModal from '@components/Icons/icon-cancel-modal';
import CustomModal from '@components/Modal';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { Modal, ModalBody, ModalBox, ModalContent, ModalFooter, ModalHeader, ModalLeftBox, ModalOverlay, ModalRightBox } from '@wow/modal';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useEffect, useState } from 'react';

import IconDisconnected from '../../../../components/Icons/icon-disconnected';
import ViewNoResult from '../../../../components/ViewNoResult';
import { useManualTransfer } from '../../../../hooks/useManualTransfer';
import { NOT_AVAILABLE_MODAL_TEXTS, texts, textsError, WIZARD_IDS, WIZARD_STEPS, WIZARD_WELCOME_TEXTS } from './constants';
import FormManualTransferSetup from './Form';
import { CustomBox } from './index.styled';

export interface Props {
  onSubmit: () => void;
  statusGetPermission: boolean;
  setShowBottomNav: (arg: boolean) => void;
}

function ManualTransferSetup({ onSubmit, statusGetPermission, setShowBottomNav }: Props) {
  const { hasError, transferTypesList, holidays, status, otpConfigured, accountsNotAvailable, onClickRetry, closeNotAvailableModal } =
    useManualTransfer({
      statusGetPermission,
    });

  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(false);

  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();

  const wizardAttempts = Number(localStorage.getItem(WIZARD_IDS.step1Attempts) || '0');

  const isWizardSeen = sessionStorage.getItem(WIZARD_IDS.step1);

  const onCloseWizard = () => {
    sessionStorage.setItem(WIZARD_IDS.step1, 'true');
    localStorage.setItem(WIZARD_IDS.step1Attempts, currentStep + 1 === WIZARD_STEPS.length ? '2' : String(wizardAttempts + 1));
  };

  const onCloseWelcomeModal = () => {
    setIsOpenWelcomeModal(false);
    onCloseWizard();
  };

  const onCancelWelcomeModal = () => {
    onCloseWelcomeModal();
  };

  const onStartWelcomeModal = () => {
    setIsOpenWelcomeModal(false);
    setInitialized(true);
  };

  const setWizardOffset = () => {
    WIZARD_STEPS[0].offsetExtraX = window.innerWidth / 2 - 235;
  };

  useEffect(() => {
    if (status && !isWizardSeen && wizardAttempts < 2) {
      setIsOpenWelcomeModal(true);
    }
  }, [status]);

  useEffect(() => {
    setShowBottomNav(true);
    setWizardOffset();
    window.addEventListener('resize', setWizardOffset);

    return () => {
      window.removeEventListener('resize', setWizardOffset);
    };
  }, []);

  if (hasError) {
    return (
      <ViewNoResult
        title={textsError.title}
        description={textsError.description}
        linkName={textsError.link}
        Icon={IconDisconnected}
        onClickLink={onClickRetry}
      />
    );
  }

  return (
    <>
      {!otpConfigured && (
        <AlertComponent id='alert-otp' variant='warning' width='100%' marginTop='12px'>
          <AlertTitle>{texts.warningOtpTitle}</AlertTitle>
          <AlertDescription>{texts.warningOtpDescription}</AlertDescription>
        </AlertComponent>
      )}
      <CustomBox>
        <FormManualTransferSetup transferTypesList={transferTypesList} holidays={holidays} status={status} onSubmit={onSubmit} />
      </CustomBox>
      <CustomModal
        isOpen={accountsNotAvailable !== null}
        Icon={IconCancelModal}
        onCancel={closeNotAvailableModal}
        title={accountsNotAvailable ? NOT_AVAILABLE_MODAL_TEXTS[accountsNotAvailable].title : ''}
        firstDescription={accountsNotAvailable ? NOT_AVAILABLE_MODAL_TEXTS[accountsNotAvailable].description : ''}
        secondTextButton={NOT_AVAILABLE_MODAL_TEXTS.ok}
        enableSecondButton
        alternativeButtonStyling
        size='xl'
        newStyle
      />
      {initialized && (
        <WizardComponent
          id={WIZARD_IDS.step1}
          steps={WIZARD_STEPS}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          initialized={initialized}
          setInitialized={setInitialized}
          onFinish={onCloseWizard}
          hasWelcome={false}
        />
      )}
      <Modal isOpen={isOpenWelcomeModal} onClose={onCloseWelcomeModal} variant='welcome' trapFocus={false} isCentered>
        <ModalOverlay />
        <ModalContent maxWidth='760px'>
          <CloseButton onClick={onCloseWelcomeModal} alignSelf='end' />
          <ModalBox gap='16px' alignItems='start'>
            <ModalLeftBox>
              <ModalHeader padding='0'>
                <Text textStyle='h3Bold' whiteSpace='pre-line'>
                  {WIZARD_WELCOME_TEXTS.title}
                </Text>
              </ModalHeader>
              <ModalBody>
                <Text>{WIZARD_WELCOME_TEXTS.description}</Text>
              </ModalBody>
              <ModalFooter justifyContent='start' padding='0'>
                <Button variant='primary-outline' size='md' onClick={onCancelWelcomeModal}>
                  {WIZARD_WELCOME_TEXTS.cancelButton}
                </Button>
                <Button variant='primary' size='md' onClick={onStartWelcomeModal}>
                  {WIZARD_WELCOME_TEXTS.exploreButton}
                </Button>
              </ModalFooter>
            </ModalLeftBox>
            <ModalRightBox>
              <Image src={require('../../../../assets/wizard-confection.png')} />
            </ModalRightBox>
          </ModalBox>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ManualTransferSetup;
