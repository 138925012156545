/* eslint-disable import/prefer-default-export */

const TextTitle = {
  margin: '16px 0px 0px 0px',
};

const Content = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
};

export { Content, TextTitle };
