const customMargin = {
  marginTop: '24px',
};

const customTextTitle = {
  marginBottom: '24px',
};

const titleSkeleton = {
  width: '109px',
  borderRadius: '4px',
  marginBottom: '24px',
};

const customSkeleton = {
  width: '261px',
  height: '52px',
  borderRadius: '4px',
};

const customSkeletonAllSection = {
  width: '546px',
  height: '52px',
  borderRadius: '4px',
};

const customHelpText = {
  marginTop: '24px',
  height: '18px',
  width: '211px',
};

const customHelpTextDrawer = {
  marginTop: '24px',
  height: '118px',
  width: '211px',
};

const costumSecondText = {
  height: '24px',
  width: '180px',
  margin: '24px 0px',
};

const flexContent = {
  flexDirection: 'column',
  width: '610px',
  height: '100%',
  padding: '16px 32px 24px',
  marginTop: '8px',
};

const customBoxInputs = {
  padding: '0',
  margin: '0 0 32px 0',
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

const customBoxRowWithMarginTop = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '24px',
  width: '546px',
};

const formLabelStyles = {
  fontSize: '13px',
  fontWeight: 'normal',
  fontFamily: 'Inter',
  marginBottom: 0,
  color: 'gray.700',
  lineHeight: 'normal',
  'span.chakra-form__required-indicator': {
    color: 'black',
  },
};

const formErrorStyles = {
  fontSize: '12px',
  paddingTop: '2px',
};

const customSkeletonSecondText = {
  width: '180px',
  height: '24px',
  margin: '15px 0px',
  borderRadius: '4px',
};

const customSkeletonHelpText = {
  width: '211px',
  height: '20px',
  margin: '24px 0px',
  borderRadius: '4px',
};

export {
  costumSecondText,
  customBoxInputs,
  customBoxRowWithMarginTop,
  customHelpText,
  customHelpTextDrawer,
  customMargin,
  customSkeleton,
  customSkeletonAllSection,
  customSkeletonHelpText,
  customSkeletonSecondText,
  customTextTitle,
  flexContent,
  formErrorStyles,
  formLabelStyles,
  titleSkeleton,
};
