/* eslint-disable no-shadow */
import { AccountingType } from '@interfaces/transfers';
import { FiltersApplied, SelectOption } from '@interfaces/transferSetup';

interface Props {
  isLoading?: boolean;
  accountingType: AccountingType;
  isFiltersActive?: boolean;
  usageReferenceList: SelectOption[];
  banksList: SelectOption[];
  filterOptionsApplied: FiltersApplied;
  isEmptyResult: boolean;
  onApplyFilters: () => void;
  setFilterOptionsApplied: (value: FiltersApplied) => void;
}

enum FilterType {
  usageReferences = 'usageReferences',
  banks = 'banks',
}

export type { Props };
export { FilterType };
