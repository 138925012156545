const formatAccountName = (accountName: string | undefined, cuit: string | undefined) => {
  const data = [];
  if (accountName) {
    const accountNameMaxLength = 32;
    data.push(`${accountName.substring(0, accountNameMaxLength)}${accountName.length > accountNameMaxLength ? '...' : ''}`);
  }
  if (cuit) {
    data.push(cuit);
  }
  return data.join(' | ');
};

const formatAccountData = (cbu: string | undefined, accountType: string | undefined) => {
  const data = [];
  if (cbu) {
    data.push(cbu);
  }
  if (accountType) {
    data.push(accountType);
  }
  return data.join(' | ');
};

export { formatAccountData, formatAccountName };
