/* eslint-disable import/no-unresolved */
import { Text } from '@chakra-ui/react';

interface Props {
  text: string;
  fontWeight?: string;
  fontSize?: string;
  margin?: string;
  padding?: string;
  marginBottom?: string;
}

function CustomText({ text, fontWeight, fontSize, margin, padding, marginBottom }: Props) {
  return (
    <Text fontWeight={fontWeight} fontSize={fontSize} margin={margin} padding={padding} marginBottom={marginBottom}>
      {text}
    </Text>
  );
}

export default CustomText;
