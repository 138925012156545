import { WizardTransfers } from './constants';

const WizardSteps = [
  {
    title: WizardTransfers.titleTef,
    description: WizardTransfers.descTef,
    target: 'titleTef',
    placement: 'top',
    offsetExtraY: -20,
    offsetExtraX: -10,
    enableScroll: false,
  },
  {
    title: WizardTransfers.titleSec,
    description: WizardTransfers.descSec,
    target: 'tabs',
    placement: 'top',
    offsetExtraY: -20,
    offsetExtraX: 0,
  },
  {
    title: WizardTransfers.titleLotList,
    description: WizardTransfers.descLotList,
    target: 'table',
    placement: 'bottom',
    offsetExtraY: 30,
    offsetExtraX: 0,
  },
  {
    title: WizardTransfers.titleLotDetail,
    description: WizardTransfers.descLotDetail,
    target: 'table',
    placement: 'bottom',
    offsetExtraY: 30,
    offsetExtraX: 0,
  },
  {
    title: WizardTransfers.titleLotSearch,
    description: WizardTransfers.descLotSearch,
    target: 'filterTable',
    placement: 'bottom',
    offsetExtraY: 30,
    offsetExtraX: 0,
  },
  {
    title: WizardTransfers.titleConfirm,
    description: WizardTransfers.descConfirm,
    placement: 'left',
    target: 'actionTef',
    offsetExtraY: 0,
    offsetExtraX: 30,
  },
];

export default WizardSteps;
