import { AlertTriangle, Calendar, Clock, Edit3, Layers, Lock, Settings, TrendingUp } from 'react-feather';

import Bank from '../../../../components/Icons/icon-bank';
import { ResultErrorIcon } from '../../../../Interfaces/transfers';

interface Props {
  icon: ResultErrorIcon | string;
}

function ResultIcon({ icon }: Props) {
  switch (icon) {
    case ResultErrorIcon.clock:
      return <Clock />;
    case ResultErrorIcon.edit3:
      return <Edit3 />;
    case ResultErrorIcon.settings:
      return <Settings />;
    case ResultErrorIcon.trendingUp:
      return <TrendingUp />;
    case ResultErrorIcon.bank:
      return <Bank />;
    case ResultErrorIcon.layers:
      return <Layers />;
    case ResultErrorIcon.calendar:
      return <Calendar />;
    case ResultErrorIcon.alertTriangle:
      return <AlertTriangle />;
    case ResultErrorIcon.lock:
    default:
      return <Lock />;
  }
}

export default ResultIcon;
