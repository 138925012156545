import { Td, Th } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const CustomTh = styled(Th)`
  &:first-of-type {
    position: sticky;
    background-color: ${colors.Neutral40};
    left: 0px;
    width: 48px;
    height: 40px;
    & > div {
      padding-left: 6.5px;
      padding-right: 17.5px;
      width: 100%;
      height: 100%;
    }
  }

  &:nth-of-type(2) {
    position: sticky;
    background-color: ${colors.Neutral40};
    left: 48px;
    & > div {
      margin: 0px 24px 0px 16px;
      gap: 0px;
    }
  }

  &:nth-of-type(3) {
    position: sticky;
    background-color: ${colors.Neutral40};
    left: 158px;
    & > div {
      margin: 0px 24px 0px 16px;
      gap: 0px;
    }
    div:nth-child(2) {
      position: absolute;
      width: 12px;
      height: 100%;
      top: 0px;
      right: -12px;
      background: linear-gradient(91.74deg, rgba(0, 0, 0, 0.2) 1.09%, rgba(0, 0, 0, 0) 98.14%);
    }
  }

  &:nth-of-type(4),
  &:nth-of-type(5),
  &:nth-of-type(6),
  &:nth-of-type(8) {
    & > div {
      margin: 0px 24px 0px 16px;
      gap: 0px;
    }
  }

  &:nth-of-type(7) {
    width: 222px;
    padding-left: 16px;
    & > div {
      gap: 0px;
    }
  }

  &:nth-of-type(9) {
    width: 39.5px;
    height: 39.5px;
    & > div {
      width: 39.5px;
      height: 39.5px;
    }
  }
`;

const CustomTd = styled(Td)`
  &:first-of-type {
    position: sticky;
    background-color: ${colors.White};
    left: 0px;
    width: 48px;
    height: 40px;
    & > div {
      display: flex;
      padding-left: 6.5px;
      padding-right: 17.5px;
      width: 100%;
      height: 100%;
    }
  }

  &:nth-of-type(2) {
    position: sticky;
    background-color: ${colors.White};
    left: 48px;
    p {
      padding-left: 16px;
    }
  }

  &:nth-of-type(3) {
    position: sticky;
    background-color: ${colors.White};
    left: 158px;
    div:nth-child(1) {
      padding-left: 16px;
      display: flex;
      gap: 0px;
    }
    div:nth-child(2) {
      position: absolute;
      width: 12px;
      height: 100%;
      top: 0px;
      right: -12px;
      background: linear-gradient(91.74deg, rgba(0, 0, 0, 0.2) 1.09%, rgba(0, 0, 0, 0) 98.14%);
    }
  }

  &:nth-of-type(4) {
    span {
      padding-left: 16px;
    }
  }

  &:nth-of-type(5) span {
    padding-left: 16px;
    white-space: nowrap;
  }

  &:nth-of-type(6) {
    max-height: 60px;
    min-height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div {
      padding: 6px 16px 6px 16px;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      max-width: 400px;
      text-overflow: ellipsis;
    }
  }

  &:nth-of-type(7) {
    width: 222px;
    & > span {
      padding: 0px 16px;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &:nth-of-type(8) span {
    padding-left: 16px;
  }

  &:nth-of-type(9) {
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }
`;

const CustomTextDate = {
  borderRadius: '4px',
  color: `${colors.White}`,
  padding: '2px 4px',
  width: '85px',
};

const CustomAmountStyle = {
  justifySelf: 'flex-end',
  marginLeft: 'auto',
  marginBottom: '0px',
  marginTop: '0px',
  paddingRight: '2px',
};

const CustomBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10px',
};

const CustomTooltipStyle = {
  background: `${colors.Grey120}`,
  borderRadius: '8px',
  color: `${colors.White}`,
  fontSize: '12px',
  marginRight: '10px',
  padding: '8px',
  width: '100%',
};

const CustomTooltipDebitStyle = {
  background: `${colors.Grey120}`,
  borderRadius: '8px',
  color: `${colors.White}`,
  fontSize: '12px',
  marginRight: '10px',
  padding: '8px',
  width: '294px',
};

const CustomMargin = {
  margin: '0px',
  fontSize: '12px',
  whiteSpace: 'pre-wrap',
};

const SeparatorDebitAccount = {
  margin: '0px 5px',
};

const ColorFontsHeaderTable = {
  color: colors.Grey120,
  textTransform: 'initial',
};

const Paginator = {
  borderTop: 'solid 1px rgba(0, 0, 0, 0.24)',
  marginTop: '12px',
  height: '64px',
};

export {
  ColorFontsHeaderTable,
  CustomAmountStyle,
  CustomBoxStyle,
  CustomMargin,
  CustomTd,
  CustomTextDate,
  CustomTh,
  CustomTooltipDebitStyle,
  CustomTooltipStyle,
  Paginator,
  SeparatorDebitAccount,
};
