const TEXT_FILTER_TAG = 'Importe';
const TITLE_HEADER = 'Buscá por un valor o rango';
const MENU_TEST_ID_AMOUNTS = 'tag-filter-amount';
const PLACEHOLDER_INPUT_FROM = 'Ingresá el valor mínimo';
const PLACEHOLDER_INPUT_TO = 'Ingresá el valor máximo';
const TO_VALIDATION_MESSAGE = 'El valor "Hasta" debe ser mayor que el valor "Desde"';
const FROM_VALIDATION_MESSAGE = 'El valor "Desde" debe ser menor que el valor "Hasta"';
const FROM_TITLE = 'Desde';
const TO_TITLE = 'Hasta';
const AMOUNT_FROM_TEST_ID = 'amount-from';
const AMOUNT_TO_TEST_ID = 'amount-to';
const initialInputValues = { fromAmount: '', toAmount: '' };
const initialValiInputValidation = { fromAmount: false, toAmount: false };
const CLEAR_FILTERS_AMOUNT_BUTTON_TEST_ID = 'btn-filter-by-amount-limpiar';
const APPLY_BUTTON_TEST_ID = 'btn-filter-by-amount-aplicar';

export {
  AMOUNT_FROM_TEST_ID,
  AMOUNT_TO_TEST_ID,
  APPLY_BUTTON_TEST_ID,
  CLEAR_FILTERS_AMOUNT_BUTTON_TEST_ID,
  FROM_TITLE,
  FROM_VALIDATION_MESSAGE,
  initialInputValues,
  initialValiInputValidation,
  MENU_TEST_ID_AMOUNTS,
  PLACEHOLDER_INPUT_FROM,
  PLACEHOLDER_INPUT_TO,
  TEXT_FILTER_TAG,
  TITLE_HEADER,
  TO_TITLE,
  TO_VALIDATION_MESSAGE,
};
