import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { routesNames } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { PostDataAuthPartial } from '../Interfaces/api';
import { postAuthPartialTransfers } from '../services/api';
import { EXCEPTION_CODES } from '../views/AuthorizationTransfers/constants';

const useAuthPartial = () => {
  const navigate = useNavigate();
  const [timeOutModalAuthPartial, setTimeOutModalAuthPartial] = useState(false);
  const [isOpenModalProcessDetails, setIsOpenModalProcessDetails] = useState(false);
  const {
    setTransferTypeAuthReadyToSend,
    setOtp,
    setErrorUseAuthPartial,
    setTotalAmountPartialReject,
    setLoadingPartialReject,
    errorUseAuthPartial,
  } = useTransfersContext();
  const { mutate: mutateAuthPartial, isLoading: isLoadingAuthPartial } = useMutation(
    (data: PostDataAuthPartial) => {
      return postAuthPartialTransfers(data);
    },
    {
      onSuccess: response => {
        const data = response?.data;
        const exceptionCode: string = data?.exception?.code;
        switch (exceptionCode) {
          case EXCEPTION_CODES.satisfactoryProcess:
            setTotalAmountPartialReject(data?.data?.totalAmount);
            setTransferTypeAuthReadyToSend(data?.data?.transfer);
            setOtp(data?.data?.otpMark);
            setLoadingPartialReject(false);
            navigate(routesNames.ResultPartialAuth);
            break;
          case EXCEPTION_CODES.timeOut:
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'La autorización se está procesando',
            });
            setTimeOutModalAuthPartial(true);
            break;
          default:
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'La autorización se está procesando',
            });
            setTimeOutModalAuthPartial(true);
        }
      },
      onError: () => {
        setErrorUseAuthPartial(true);
      },
    },
  );

  useEffect(() => {
    if (errorUseAuthPartial) {
      setIsOpenModalProcessDetails(errorUseAuthPartial);
    }
  }, [errorUseAuthPartial]);

  return {
    mutateAuthPartial,
    isLoadingAuthPartial,
    timeOutModalAuthPartial,
    setTimeOutModalAuthPartial,
    isOpenModalProcessDetails,
    setIsOpenModalProcessDetails,
  };
};

export default useAuthPartial;
