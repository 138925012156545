/* eslint-disable no-shadow */
export const PERMISSIONS_SEND = {
  Transferencias_Generacion_SUE_Liberacion: '1a4665a7c972e67dff20b2ad43ef847be2671ed8842319fa9d8443d59fb3c9ac',
  Transferencias_Generacion_INT_Liberacion: '6f863e859c75647dfcfb459819112ce18be291854fbbd88b96d14ff524f28879',
  Transferencias_Generacion_CUE_Liberacion: 'b2b6fc840a5e1014d11fa1e89322238a90f69e3baaf417c770eaca7de756ee1a',
  Transferencias_Generacion_PRO_Liberacion: '18ca8463d3549283e49f3cd30ba7ddf38a8a38c37461289ec7b628744ff5386a',
  Transferencias_Generacion_SEG_Liberacion: '0ef94a20c2ac5061c339f16ef5800168b5cb0ec339c49bb7fc0afa23b1e6d83f',
  Transferencias_Generacion_AFI_Liberacion: '72f8318f8abf691e97e49578e09afd477cb382cc8b600c02bc4483d7a052d74b',
  Transferencias_Generacion_DAR_Liberacion: '46731f966d570a55bce388f1a1cc35983597481e9a20b3cc75bd91c933e5ba32',
  Transferencias_Generacion_PPR_Liberacion: '9ca2f0a6fb450b4ed9c3276bf7a46064675c679b15d01e27e0423a63126c6934',
  Transferencias_Generacion_PDS_Liberacion: '9a582654798f0ecbbc9ef705e72e26dcad75959e8ea3d6004e836fcf8186be18',
  Transferencias_Generacion_TER_Liberacion: '2c9b3b3cfbf0bd492d36f851ffc01c48d5875785a4831d4ae347615f3bd10356',
  Transferencias_Generacion_TPJ_Liberacion: '8ec0595c0e050a74fb7a8238ba06ba2276ce4265fc226ca046ca6b0d3f6fbfa0',
};

export const PERMISSIONS_FIRM_ANULATION = {
  Transferencias_Generacion_AFI_Anulacion: '555bee2e06a4ea9df13921d6cb9474aca1c31ac0c00181b15a7ba60fba1ca51d',
  Transferencias_Generacion_CUE_Anulacion: '7e7e150b7f180e28c4b444b74644f3621ef0e0a17e29fe67ddfc10a0e50bffbf',
  Transferencias_Generacion_INT_Anulacion: 'c65c5d6bb10cdb6db828cacf91e16d270d4607e1ca1e78bf9e0cb8fe3fc22849',
  Transferencias_Generacion_PDS_Anulacion: '565289851f5e76edcd4bca1bc349bfee124bdfb955582a0c210d67b83a2091fd',
  Transferencias_Generacion_PRO_Anulacion: '9eb98719da59178150f1ef0dd62e7c9672d08541e5bd25b80623337e23c0ea83',
  Transferencias_Generacion_SUE_Anulacion: '4b9cc31e5446fb0df2398eccc5071643f23685f7071676ef0e0471244782e76a',
  Transferencias_Generacion_TER_Anulacion: '34a4a1734868e954a1106959bb73a22d7450257368ea355fe095e776c12fecb1',
  Transferencias_Generacion_PPR_Anulacion: '60434589a671544bd5cd6ae716e72688782cdf9a932002945bc13f790b587705',
};

export const PERMISSIONS_FIRM_AUTHORIZATION = {
  Transferencias_Generacion_AFI_Firma: 'cfaf19f8f1a488f25c57ae0e4271fe483f292acb0cca24958aace5050277fd3f',
  Transferencias_Generacion_CUE_Firma: '4b6ff54c4cbb8682fb59207e731710dc6558528555958dad60e5639c3bbc841a',
  Transferencias_Generacion_INT_Firma: '32cf3caf00c2fe5b0911d78aac0ccc8f97e3ca954131b661990bc163b26b5212',
  Transferencias_Generacion_PDS_Firma: '3843a9c8dd5933b6d7cfdb649b7f7410b133c9d91ce82be7fdd3ab52cf5dbae3',
  Transferencias_Generacion_PRO_Firma: '97631100eb552f81fb8ea681e0405b4a98bce353d661b44f2725c33fa1e476e1',
  Transferencias_Generacion_SUE_Firma: '3c533d0fd66bc2e949fb41d16654825fedce6e9a8cd68ae081a624b3cd0356d0',
  Transferencias_Generacion_TER_Firma: '2be9f01d1363c358b982e6ebb8c0ef1dcd7d9dd02ab4e7fab78682abdcd1d297',
  Transferencias_Generacion_PPR_Firma: '24b743315be1b86b8311bcabe80c905c7222537ad2d804097e26e87d6a142b08',
};

export const PERMISSIONS_FIRM_CREATE = {
  Transferencias_Generacion_AFI_Confeccion: 'cfaf19f8f1a488f25c57ae0e4271fe483f292acb0cca24958aace5050277fd3f',
  Transferencias_Generacion_CUE_Confeccion: '0fd94c2006251b23d393fd41ccf3096063f7f28e18668fb1efbc9be932a8e8cf',
  Transferencias_Generacion_INT_Confeccion: '2a93ed118cd6b8ce22dd53cda3bcd4281981a0b417cac6a9e15bb3d106260a9c',
  Transferencias_Generacion_PDS_Confeccion: 'ee79445189b812950c268dd3dc0e06585cd88266763910b901da41d5e2c50193',
  Transferencias_Generacion_PRO_Confeccion: '342da59b08f6077662e8fc192d17ff7d8b72a66fe040a3ba143a492678044269',
  Transferencias_Generacion_SUE_Confeccion: 'd052e69975c1af341d50d5b0c492de6ffa5e8cf941be6371272a76a5bd3db8ec',
  Transferencias_Generacion_TER_Confeccion: '19040ac4b0db0c0671da1b22514809d1630fe4a9c8ee07ea900b7b6ad6c85f0e',
  Transferencias_Generacion_PPR_Confeccion: '5f16e21d7cd262e2f786b449d9b98772cbcec72ecffe394e2ae4a3663b894804',
  Transferencias_Generacion_FAC_Confeccion: '526d478a82c8bb8e67831fef6318d64dbf317a10c195eae1597e11003d3b9364',
  Transferencias_Generacion_TPJ_Confeccion: 'cfb1c32ce357271cfeca0c73fd410f7d7d8419c969713b7280178a6a808a6e16',
  Transferencias_Generacion_CUE_Upload: '96178b3fdef611d6e54a85a2099ab3559acad09da9e3ce01ddd2414fd3bd8ede',
  Transferencias_Generacion_INT_Upload: '183645b8e1e09107b2c1940753ae1bf3a05872a9bbcb8895d6991713b794981d',
  Transferencias_Generacion_PDS_Upload: '95a9cf2ff7143b7631238e23ee618e8b6ae5955d1eaa9f97ccda8ea5ae435785',
  Transferencias_Generacion_PRO_Upload: '0006408998c9f693f8d2f8d98f54ce73d381231002d9382968bc92acc97a0a78',
  Transferencias_Generacion_SUE_Upload: 'b5d5ef31cb95ff8ec3c68ba45959ae43e81e71edd0049d081201ac1beaa58c7e',
  Transferencias_Generacion_TER_Upload: '12172458d61f02abca97cb44360a6d6a3eae38929f445e88cd0b24e3ebde2383',
  Transferencias_Generacion_PPR_Upload: 'd9f0b2d850d5bd2f593e8775c93d17b5079f3158194fec4f92d8b5bfbda7da69',
  Transferencias_Generacion_FAC_Upload: '8d289fbed88565469793f47a2bdc54ed303d536d5add7dd661b137add065f39e',
  Transferencias_Generacion_TPJ_Upload: '40ed8dabe3f40be9b428928add993057a3ba47a0a13467367aad425373c179fd',
  Transferencias_Generacion_CUE_DirectImport: 'dc11770c9cdd882ad445540ac6a75b2099faeb57b6c62b8ca36071760551c1e9',
  Transferencias_Generacion_INT_DirectImport: 'd7bd9e0092d0775d2a8ce71a11b8c642bf34f10510f3c28e49cff05fa1bef4b2',
  Transferencias_Generacion_PDS_DirectImport: '3989d7c090cdbb2124e75dea44a42b5f6e6e5475a15aa1bdc12d40d87a18c8b3',
  Transferencias_Generacion_PRO_DirectImport: '22b9985c24024d9b29991c58ffbd2ed666aac24a1db87e1cb0b78735f5c7a1e8',
  Transferencias_Generacion_SUE_DirectImport: 'db0d52153bc87eab48443273184dad4141d9db21f277710453a0c7262b548cb5',
  Transferencias_Generacion_TER_DirectImport: '3d0c97b9327e85cf12549db26ad392d252f7ea57b4c56144b28f418365fac306',
  Transferencias_Generacion_PPR_DirectImport: 'cc4ac11534aaf73d51c376eccf124c6fb510ff15cfdbba94860ed27b6c6948e6',
  Transferencias_Generacion_FAC_DirectImport: '8ab956f91b080591e480206bceb994a808225b5c25ba563e1bf3baec559ee0a9',
  Transferencias_Generacion_TPJ_DirectImport: '7c19176ed55f549a41e2b861055272136e621eb0ae0a695cd491d8f4257dca33',
};

export const PERMISSIONS_FIRM_MODIFY = {
  Transferencias_Generacion_AFI_Modificacion: '0266d5fdc572809b447866e6612179b58de6916979834fa1784f1ddde44854f2',
  Transferencias_Generacion_CUE_Modificacion: 'b1d8734a9959b75a416c4e277764ca7a29c15c9af5e222453be4f743287e7b34',
  Transferencias_Generacion_INT_Modificacion: 'eb889920ddb0f04c0cca9cf79533fbe9a1cc06cd0737c725233afc176360fc89',
  Transferencias_Generacion_PDS_Modificacion: 'f2415b300741be70f3d0542c054b35773298cf9dcaaa7c4f873bff95eca8e6f5',
  Transferencias_Generacion_PRO_Modificacion: 'd6e53d85e879fcc58caaa14e5a20c53a8edc9315eb9e71f5937261c7701e3c9b',
  Transferencias_Generacion_SUE_Modificacion: '6869f8b216f349d4fa939e002f979f4e9c897a4f142bc828c22b41da9d1c07d7',
  Transferencias_Generacion_TER_Modificacion: '48858e13e370e181a7952523da753e0cbe7b389075ce1c7eddb64e0ea9e0ff3b',
  Transferencias_Generacion_PPR_Modificacion: '2e61e461671e7fa6c168015d768995cf8e2ca72e1a24bce846230eb921f464d9',
  Transferencias_Generacion_FAC_Modificacion: 'f021cef68eb9ffb5fb669a613c1edd81907b0971845e148b9b816b5e5ee2e276',
  Transferencias_Generacion_TPJ_Modificacion: '738cb2a153ddfaaf38f7401aa580f7836f1652980d201599a60ea181cddbda1c',
};

export const ARS_CURRENCY = 'ARS';
export const USD_CURRENCY = 'USD';
export const CURRENCY_PESOS = '$';
export const CURRENCY_DOLAR = 'u$s';

export const LOADING_SCREEN_TEXT = {
  title: 'Estamos procesando tu información',
  description: 'Esto puede demorar unos instantes.',
};

export const DataMessageErrorRequest = {
  title: 'La información no está disponible',
  description: 'Estamos trabajando para solucionarlo.',
  description2: 'Volvé a intentar en unos minutos.',
  description3: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
  description4: 'Estamos trabajando para solucionarlo.',
  linkName: 'Reintentar',
  transfersLinkName: 'Volver a Transferencias',
  confectionTitle: 'La información no está disponible en este momento',
  confectionLinkName: 'Volver al resultado',
};

export const OPTION_SELECT_PAGE = [15, 30, 45];

export const MessageWithOutOTP = {
  description: 'Solo podés ver y autorizar las transferencias que no requieren Token, gestionalo para ver todas las transferencias.',
};

export const ACTIONS_TO_SEND: { [key: number]: string } = { 0: 'AUT', 1: 'ENV', 2: 'ANU' };

export enum TRANSFER_STATUS {
  CON = 'CON',
  EFR = 'EFR',
  FIR = 'FIR',
  DPD = 'DPD',
}

export const TRANSFER_TYPE_CODE = {
  SUE: 'SUE',
  INT: 'INT',
  CUE: 'CUE',
  PRO: 'PRO',
  AFI: 'AFI',
  PPR: 'PPR',
  PDS: 'PDS',
  TER: 'TER',
  FAC: 'FAC',
  TPJ: 'TPJ',
};

export const TITLES_SCREENS = {
  titleEntityToken: 'Ingresar Token',
  titleEntityKey: 'Ingresar clave',
};

export const TEXT_BUTTON_SEND = {
  textEntityToken: 'Autorizar transferencias',
  textEntityKey: 'Enviar transferencias',
  textSecondButton: 'Cancelar',
};

export const permissionsSend = Object.values(PERMISSIONS_SEND);
export const permissionsFirmAuth = Object.values(PERMISSIONS_FIRM_AUTHORIZATION);
export const permissionsFirmAnnul = Object.values(PERMISSIONS_FIRM_ANULATION);
export const permissionsFirmCreate = Object.values(PERMISSIONS_FIRM_CREATE);
export const permissionsFirmModify = Object.values(PERMISSIONS_FIRM_MODIFY);

export const PAGE_ACTIONS = {
  PREV: 'Retroceder',
  NEXT: 'Avanzar',
  FIRST: 'Primero',
  LAST: 'Ultimo',
};

export enum routesNames {
  LotDetailsSend = '/transferencias/detalleLote/envio',
  LotDetailsAuth = '/transferencias/detalleLote/autorizacion',
  LotDetailsUnauth = '/transferencias/detalleLote/desautorizacion',
  LotDetailsAnnul = '/transferencias/detalleLote/anulacion',
  LotDetailsRevoke = '/transferencias/detalleLote/revocarenvio',
  EntityKey = '/transferencias/claveEmpresa',
  EntityToken = '/transferencias/ingresarToken',
  ResultSend = '/transferencias/resultado/envio',
  ResultAuth = '/transferencias/resultado/autorizacion',
  ResultUnauth = '/transferencias/resultado/desautorizacion',
  ResultAnnul = '/transferencias/resultado/anulacion',
  ResultRevoke = '/transferencias/resultado/revocarenvio',
  TransferSetup = '/transferencias/confeccion',
  TransferUploadFiles = '/transferencias/uploadFiles',
  ResultPartialAuth = '/transferencias/resultado/autorizacion-parcial',
  Modify = '/transferencias/modificar',
  LotDetailsModify = '/transferencias/confeccion/resultado/detalleLote',
  ConfectionResult = '/transferencias/confeccion/resultado',
  ConfectionInvalidDate = '/transferencias/confeccion/fechanovalida',
  ConfectionSummary = '/transferencias/confeccion/resumen',
  Root = '/transferencias',
}

export enum breadcrumbNames {
  ROOT = 'Inicio',
  TRANSFERENCIAS = 'Transferencias',
  DETALLE = 'Detalle de lote',
  RESULTADO = 'Resultado',
  AUTORIZAR = 'Autorizar',
  ENVIAR = 'Enviar',
  CONFECCION = 'Confeccionar',
  MODIFY = 'Modificar',
}

export const orderPropertyValueDefault = 'fechaSolicitud+';

export const orderPropertyLotDetail = 'numeroTransferencia+';

export const orderAppliedValueDefault = [
  { id: 'fechaSolicitud', desc: false },
  { id: 'numeroTransferencia', desc: false },
];

export enum FilterAmountFieldNames {
  FromAmount = 'fromAmount',
  ToAmount = 'toAmount',
}

export const amountRangesValuesDefault = { fromAmount: null, toAmount: null };

export const PAGINATOR_BUTTONS_TEST_ID = 'paginator__buttons';

export const filtersValuesDefault = {
  isTransferTypeActive: false,
  isLotListActive: false,
  isRangeAmountsActive: false,
  isTransferNumberActive: false,
};

export enum RetentionState {
  ENABLED = 'HA',
  DISABLED = 'IN',
}

export enum AuthorizationState {
  V = 'V',
}

export const TOAST_DURATION = 4000;

export enum TOAST_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

export const EXCEPTION_CODES = {
  success: 'TEF-0000',
  partialRejection: 'TEF-0004',
  totalRejection: 'TEF-0005',
  asyncProcess: 'TEF-0008',
  serviceError: 'TEF-0010',
  timeoutError: 'TEF-0015',
  empty: 'TEF-0031',
  requestError: 'TEF-0033',
  notFoundError: 'TEF-0034',
};

export enum DOWNLOAD_FORMAT {
  txt = 'txt',
  csv = 'csv',
}

export const THIRD_PARTY_PAYMENTS = 'Pagos Terceros';
export const ACCOUNT_TYPE_OWN_ACCOUNTS = 'Cuentas Propias';

export const SUPPLIERS = 'Proveedores';

export const filtersAppliedDrawerDefault = {
  usageReferences: [],
  banks: [],
};

export enum ACTION_TYPE {
  FIR = 'FIR',
  LIB = 'LIB',
  ANU = 'ANU',
  QFI = 'QFI',
  QLI = 'QLI',
}
