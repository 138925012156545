import { DataReadyUpdateTransfer } from '@interfaces/api';
import { DataTransferModify } from '@interfaces/hooks/IUseModifyTransferDrawer';
import { TRANSFER_TYPE_CODE } from '@src/constants-app';

const createDataModify = (transferData: DataTransferModify, mutateModifyTransfer: (data: DataReadyUpdateTransfer) => void) => {
  const {
    transferAmount,
    requestDateTransfer,
    stepNumber,
    requestRemarkTransfer,
    debitAccount,
    creditAccount,
    subscriberNumber,
    idTransferType,
  } = transferData;

  const data: DataReadyUpdateTransfer = {
    identifierOperation: String(transferData.transferNumber),
    nroAbonated: subscriberNumber,
    tefs: [
      {
        nroTransfer: String(transferData.transferNumber),
        numberStep: stepNumber,
        dateCreation: transferData.creationDate,
        numberLot: transferData.lotNumber,
        requestDate: requestDateTransfer,
        amount: parseFloat(transferAmount),
        observationTransfer: requestRemarkTransfer || '',
        cbuAliasDebitAccount: debitAccount.cbu,
        cbuAliasCreditAccount: creditAccount.cbu,
        ...(idTransferType === TRANSFER_TYPE_CODE.TER && {
          addendaBody: {
            conceptTerCod: transferData?.concept.value || '',
            disclaimerAccepted: transferData?.disclaimerChecked || false,
            voucher: transferData?.voucherCod || '',
            reason: transferData?.reasons || '',
          },
        }),
        ...(idTransferType === TRANSFER_TYPE_CODE.PRO && {
          addendaBody: {
            codClient: transferData?.addendaProviders?.codClient || '',
            ...(transferData?.addendaProviders?.typeRetentionId?.value !== 'undefined' && {
              typeRetentionId: transferData?.addendaProviders?.typeRetentionId?.value,
            }),
            amountRetention: parseFloat(transferData?.addendaProviders?.amountRetention || '0'),
            amountNoteCred: parseFloat(transferData?.addendaProviders?.amountNoteCred || '0'),
            numberDocCancel: transferData?.addendaProviders?.numberDocCancel,
            typeOrderPayment: transferData?.addendaProviders?.typeOrderPayment || '',
            numberNoteCredir:
              transferData?.addendaProviders?.numberNoteCredir === 0 ||
              transferData?.addendaProviders?.numberNoteCredir === '' ||
              transferData?.addendaProviders?.numberNoteCredir === undefined
                ? null
                : String(transferData?.addendaProviders?.numberNoteCredir),
            numberOrderPayment: transferData?.addendaProviders?.numberOrderPayment || '',
            ...(transferData?.addendaProviders?.typeDocId?.value !== 'undefined' && {
              typeDocumentId: transferData?.addendaProviders?.typeDocId?.value,
            }),
          },
        }),
      },
    ],
  };
  mutateModifyTransfer(data);
};

export { createDataModify };
