/* eslint-disable import/prefer-default-export */
export const handleVariant = (propDate: string) => {
  switch (propDate) {
    case 'MATURITY_DATE':
      return 'danger-light';
    case 'NEXT_TO_MATURITY_DATE':
      return 'warning-light';
    default:
      return 'success-light';
  }
};
