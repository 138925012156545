import { SystemStyleObject } from '@chakra-ui/react';

const stickyBox = {
  zIndex: 9,
};

const container: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '38px',
  margin: '0 0 0 -60px',
};

const linkButton: SystemStyleObject = {
  padding: 0,
};

const progress: SystemStyleObject = {
  position: 'absolute',
  top: 0,
  left: '45%',
  right: 0,
  zIndex: 1,

  '> div': {
    height: '2px',
  },
};

const progressMask: SystemStyleObject = {
  position: 'absolute',
  top: 0,
  left: '45%',
  width: '150px',
  height: '2px',
  zIndex: 10,
};

export default { stickyBox, container, linkButton, progress, progressMask };
