import { Box, Checkbox, Text, Tooltip } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { ChangeEvent, useState } from 'react';

import IconBeware from '../../../../components/Icons/icon-beware';
import CustomModal from '../../../../components/Modal';
import { useTransfersContext } from '../../../../context/TransfersContext';
import { LotDetail, TransfersFlow } from '../../../../Interfaces/transfers';
import { CustomMarginText } from '../../../../styled/global.styled';
import { TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION, TOOLTIP_UNIFIED_SHIPPING, UNIFIED_SHIPPING_MARK } from '../../constants';

interface Props {
  table: {
    resetRowSelection: () => void;
    getIsAllRowsSelected: () => boolean;
    getIsSomePageRowsSelected: () => boolean;
    getToggleAllRowsSelectedHandler: () => (e: ChangeEvent) => void;
    getRowModel: () => { flatRows: Row<LotDetail>[] };
  };
}

export default function CheckboxHeader({ table }: Props) {
  const { lotsSelected, setLotsSelected, detailLotId, detailParams, isFullSelection, setIsFullSelection } = useTransfersContext();
  const isSendFlow = detailParams.transfersFlow === TransfersFlow.Send;

  const updatedRows = lotsSelected?.filter(item => item.id !== detailLotId);
  const hasUnifiedShipping = !!table
    .getRowModel()
    .flatRows.find((item: Row<LotDetail>) => item.original.unifiedShippingMark === UNIFIED_SHIPPING_MARK);
  const hasFullSelection = isFullSelection?.find(item => item[detailLotId]);

  const handleCancelAction = () => {
    setIsEditingWithFullSelection(false);
  };
  const handleConfirmAction = () => {
    table.resetRowSelection();
    setIsEditingWithFullSelection(false);
    const rowSelected = updatedRows.map(row => ({
      [row.id]: true,
    }));
    setIsFullSelection(rowSelected);
    setLotsSelected(updatedRows);
  };
  const [isEditingWithFullSelection, setIsEditingWithFullSelection] = useState<boolean>(false);

  return (
    <Box>
      <Tooltip
        label={
          hasUnifiedShipping && isSendFlow ? (
            <Box>
              <Text sx={CustomMarginText}>{TOOLTIP_UNIFIED_SHIPPING}</Text>
            </Box>
          ) : null
        }
        hasArrow
        placement='right'
      >
        <Box>
          <Checkbox
            isChecked={table?.getIsAllRowsSelected()}
            onChange={e => {
              if (hasFullSelection) {
                setIsEditingWithFullSelection(true);
              } else {
                table?.getToggleAllRowsSelectedHandler()(e);
              }
            }}
            isIndeterminate={table?.getIsSomePageRowsSelected()}
            isDisabled={hasUnifiedShipping && isSendFlow}
          />
        </Box>
      </Tooltip>
      <CustomModal
        isOpen={isEditingWithFullSelection}
        onCancel={setIsEditingWithFullSelection}
        Icon={IconBeware}
        altIcon={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.altIcon}
        title={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.title}
        firstDescription={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.firstDescription}
        firstTextButton={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.firstTextButton}
        secondTextButton={TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION.secondTextButton}
        enableFirstButton
        enableSecondButton
        alternativeButtonStyling
        actionButton={handleCancelAction}
        actionButton2={handleConfirmAction}
        size='xl'
      />
    </Box>
  );
}
