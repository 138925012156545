const TEXTS = {
  label: 'Buscá por Razón social, CUIT, Banco, Nro. de cuenta o CBU',
  placeholder: 'Ingresá al menos 3 caracteres para la búsqueda',
  supporting: 'Ingresá al menos 3 caracteres para la búsqueda',
  search: 'Buscar',
  filterResultTitle: (value: number) => `Encontramos ${value} cuenta${value > 1 ? 's' : ''} para tu búsqueda`,
  filterResultDescription: 'Probá con datos más específicos para reducir las cuentas.',
  viewMore: 'Ver más resultados',
};

const FIELDS = {
  searchValue: 'searchValue',
};

const PAGE_SIZE = 15;

const MAX_LENGTH = 40;

const TEST_IDS = {
  searchAccounts: 'searchAccounts',
  inputSearch: 'inputSearch',
  buttonSearch: 'buttonSearch',
  filterResult: 'filterResult',
  accountsList: 'accountsList',
  buttonViewMore: 'buttonViewMore',
  skeleton: 'skeleton',
};

export { FIELDS, MAX_LENGTH, PAGE_SIZE, TEST_IDS, TEXTS };
