import ErrorPage from '../ErrorPage';
import IconBrokenLink from '../Icons/icon-broken-link';
import { NOT_FOUND_PAGE_CONSTANTS } from './constans';

function NotFoundPage() {
  return (
    <ErrorPage
      title={NOT_FOUND_PAGE_CONSTANTS.TITLE}
      description={NOT_FOUND_PAGE_CONSTANTS.DESCRIPTION}
      Icon={IconBrokenLink}
      linkText={NOT_FOUND_PAGE_CONSTANTS.LINK_TEXT}
      linkAction={NOT_FOUND_PAGE_CONSTANTS.LINK_ACTION}
    />
  );
}

export default NotFoundPage;
