/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';

import { useTransfersContext } from '../../context/TransfersContext';
import { Lot } from '../../Interfaces/transfers';

interface Props {
  table: {
    toggleAllRowsSelected: () => void;
    getIsAllRowsSelected: () => boolean;
    getIsSomePageRowsSelected: () => boolean;
    getRowModel: () => { flatRows: Row<Lot>[] };
  };
}

function CheckboxHeader({ table }: Props) {
  const { lotsSelected, setLotsSelected, removeLotsByPendingTableIds } = useTransfersContext();

  const toggleHandler = () => {
    if (lotsSelected.length > 0) {
      const lotsWithoutFlag = lotsSelected.map(({ isPartialSelection, ...noPartialSelection }) => noPartialSelection);
      setLotsSelected(lotsWithoutFlag);
    }

    const getIdsToBeErased = table.getRowModel().flatRows.map((e: { id: string }) => e.id);
    removeLotsByPendingTableIds(getIdsToBeErased);
    table.toggleAllRowsSelected();
  };

  return (
    <Checkbox isChecked={table.getIsAllRowsSelected()} isIndeterminate={table?.getIsSomePageRowsSelected()} onChange={toggleHandler} />
  );
}

export default CheckboxHeader;
