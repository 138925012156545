import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { orderAppliedValueDefault, orderPropertyLotDetail } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { FieldTransferTypeResponse, SectionsTypes } from '../Interfaces/api';
import { ITableModify } from '../Interfaces/ITableModify';
import { Pagination as PaginationInterfaces } from '../Interfaces/transfers';
import { getListModifiedTransfers, getTransferType } from '../services/api';
import { formatSelectedTransfersTypesCodes } from '../utils/formatSelectedTransfersTypesCodesParam';

function useModifyTransfers(lotNumber: number[]) {
  const {
    pageContext,
    sizeContext,
    setHasData,
    selectedTransfersTypesCodes,
    transferNumber,
    orderPropertyContext,
    orderAppliedContext,
    setOrderAppliedContext,
    clearSelectedLots,
    setOrderPropertyContext,
  } = useTransfersContext();
  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [secondTime, setSecondTime] = useState<boolean>(false);
  const [statusServicesGetTransfers, setStatusServicesGetTransfers] = useState<boolean>(false);
  const [dataTransfer, setDataTransfer] = useState([]);
  const [tableDataState, setTableDataState] = useState<ITableModify>();
  const [optionsTransferType, setOptionsTranferType] = useState<SectionsTypes[]>([]);
  const [pagination, setPagination] = useState<PaginationInterfaces>({
    totalElements: 0,
    totalPages: 0,
    totalElementsRequested: 0,
    pageNumber: 0,
  });

  const params = {
    size: String(sizeContext),
    page: String(pageContext),
    orderProperty: orderPropertyContext,
    lotNumber: lotNumber.length > 0 ? lotNumber.join(';') : null,
    transferType: formatSelectedTransfersTypesCodes(selectedTransfersTypesCodes),
    transfer: transferNumber.length ? [parseInt(transferNumber, 10)] : [],
  };

  const { isFetching, refetch } = useQuery(
    ['get-list-modified-transfers', params],
    () => getListModifiedTransfers(params).then(res => res.data),
    {
      retry: false,
      onSuccess: data => {
        setDataTransfer(data?.data?.transfer);
        setHasData(data?.data?.transfer.length > 0);
        setTableDataState(data);
        setPagination(data?.paging);
        setFirstTime(true);
        setSecondTime(true);
      },
      onError: () => {
        setStatusServicesGetTransfers(true);
      },
    },
  );

  useEffect(() => {
    const [, secondItem] = orderAppliedValueDefault;
    setOrderPropertyContext(orderPropertyLotDetail);
    setOrderAppliedContext([secondItem]);
    return () => {
      clearSelectedLots();
    };
  }, []);

  useEffect(() => {
    setSecondTime(false);
    refetch();
  }, [orderAppliedContext]);

  useQuery(['get-transfer-type'], () => getTransferType().then(res => res.data), {
    retry: false,
    onSuccess: (data: FieldTransferTypeResponse) => {
      if (data.data?.sections) {
        setOptionsTranferType(data.data.sections);
      }
    },
  });

  useEffect(() => {
    if (isFetching) {
      setSecondTime(false);
    }
  }, [isFetching]);

  return {
    isFetching,
    dataTransfer,
    tableDataState,
    pagination,
    firstTime,
    setFirstTime,
    secondTime,
    setSecondTime,
    optionsTransferType,
    statusServicesGetTransfers,
    refetch,
  };
}

export default useModifyTransfers;
