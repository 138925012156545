import { TRANSFER_TYPE_CODE } from '../../../../constants-app';
import { ConfectionService } from '../../../../Interfaces/api';

const getConfectionService = (transferType: string) => {
  switch (transferType) {
    case TRANSFER_TYPE_CODE.PRO:
      return ConfectionService.providers;
    case TRANSFER_TYPE_CODE.TER:
      return ConfectionService.thirdPartyPayments;
    case TRANSFER_TYPE_CODE.PPR:
      return ConfectionService.judicialDeposits;
    case TRANSFER_TYPE_CODE.SUE:
      return ConfectionService.salaries;
    case TRANSFER_TYPE_CODE.CUE:
    default:
      return ConfectionService.ownAccounts;
  }
};

export { getConfectionService };
