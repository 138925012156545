/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Box, Flex, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useAuthorizer } from '@react-ib-mf/header-menu-ui';
import { hashPass } from '@react-ib-mf/style-guide-ui';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { pushAnalyticsEvent } from '@wow/utils';
import { useWizard, WizardComponent } from '@wow/wizard';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import PasswordBank from '../../components/BankListMac';
import BaseScreen from '../../components/BaseScreen';
import CustomBoxWithInput from '../../components/CompanyKey';
import CustomSummary from '../../components/CustomSummary';
import Footer from '../../components/Footer';
import IconBeware from '../../components/Icons/icon-beware';
import IconCancelModal from '../../components/Icons/icon-cancel-modal';
import IconCheckMark from '../../components/Icons/icon-checkmark';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import CustomModal from '../../components/Modal';
import ModalChildrenErrorMac from '../../components/Modal/ModalChildrenErrorMac';
import Tab from '../../components/TabList';
import { ARS_CURRENCY, routesNames, TEXT_BUTTON_SEND, TITLES_SCREENS, USD_CURRENCY } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import useShowPassword from '../../hooks/useShowPassword';
import { macKeys, sendTef } from '../../Interfaces/api';
import { TransferData } from '../../Interfaces/entityKey';
import {
  ServiceAction,
  TransferOperationDetails,
  TransfersAccepted,
  TransfersRejected,
  TransfersRejectedUnassociatedMacKey,
} from '../../Interfaces/transfers';
import { getAsyncProcess, getMacKeys, getNameCompany, getParamsProsegur, getSubscriberId, postSendTefs } from '../../services/api';
import { REGEX_INPUT_COMPANY_KEY } from '../../utils/rulesInputs';
import SortTransfers from '../../utils/SortTransfers';
import { ALERT_SUE_TEXT, LOADING_SEND_TRANSFERS } from '../SendingTransfers/constants';
import {
  ASYNC_DELAY_RETRY,
  ASYNC_MAX_RETRIES,
  asyncModalErrorText,
  asyncModalInProgressText,
  asyncModalIsDoneText,
  COUNTDOWN_MIN,
  COUNTDOWN_MS,
  COUNTDOWN_START,
  COUNTDOWN_TIME,
  EXCEPTION_CODES,
  KeyboardKeys,
  TEXT_ENTITY_KEY,
  TEXT_MODAL_ASYNC_ERROR,
  TEXT_MODAL_CHECKMARK_SUCCESS,
  TEXT_MODAL_ERROR_EIGHTH_DIGIT,
  TEXT_MODAL_PROSEGUR,
  TEXT_MODAL_SEND_ASYNC_IN_PROGRESS,
  TEXT_MODAL_SEND_ASYNC_IS_DONE,
  TEXT_MODAL_SEND_ERROR,
  TEXT_MODAL_SEND_ERROR_SECOND,
  TEXT_MODAL_SEND_ERROR_THIRD,
} from './constants';
import { ContentForm, CustomTabTitle, TabFlexStyle } from './index.styled';
import filterBanks from './utils/filterBanks';
import WizardSteps from './WizardSteps';

function EntityKey() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [authorizer] = useAuthorizer();
  const {
    lotsSelected,
    bankCodes,
    dataTotalsTransfers,
    responseOfTransferSelectedByUser,
    setLotsSelected,
    clearSelectedLots,
    transfersRejected,
    dataDetailsTransfers,
    setTransfersRejected,
    isAuthenticated,
    setDataLot,
    isBusinessDay,
    hasNextTefDay,
    setTransfersAccepted,
    setPageContext,
    setSizeContext,
  } = useTransfersContext();

  const [countdown, setCountdown] = useState(COUNTDOWN_TIME);
  const [totalTransfersArs, setTotalTransfersArs] = useState<number>(0);
  const [totalTransfersUsd, setTotalTransfersUsd] = useState<number>(0);
  const [hasPartialAmountSue, setHasPartialAmountSue] = useState<boolean>(true);
  const [hasPartialAmountSueUsd, setHasPartialAmountSueUsd] = useState<boolean>(true);
  const [totalAmountArs, setTotalAmountArs] = useState<number>(0);
  const [totalAmountUsd, setTotalAmountUsd] = useState<number>(0);
  const [statusButtom, setStatusButtom] = useState<boolean>(true);
  const [nameCompany, setNameCompany] = useState<string>('');
  const [subscriberId, setSubscriberId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [pathParamsGetBankCodes, setPathParamsGetBankCodes] = useState<string>('');
  const { showPassword, hidePassword, onMouseLeave, show } = useShowPassword();
  const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isAsyncError, setIsAsyncError] = useState<boolean>(false);
  const [isErrorSecondTime, setIsErrorSecondTime] = useState<boolean>(false);
  const [isErrorThirdTime, setIsErrorThirdTime] = useState<boolean>(false);
  const [companyKeyNotFound, setCompanyKeyNotFound] = useState<boolean>(false);
  const [errorsUnassociatedMacKey, setErrorsUnassociatedMacKey] = useState<TransfersRejectedUnassociatedMacKey | any>({});
  const [bankPasswords, setBankPasswords] = useState<{ [key: string]: string }>({});
  const [passwordCompleted, setPasswordCompleted] = useState<number>(0);
  const [isErrorEighthDigit, setIsErrorEighthDigit] = useState<boolean>(false);
  const [send, setSend] = useState<boolean>(false);
  const [idProcess, setIdProcess] = useState<number | null>(null);
  const [isValidlastDigit, setIsValidlastDigit] = useState<boolean[] | any[]>([]);
  const [banksMacError, setbanksMacError] = useState<number>(0);
  const [bankList, setBankList] = useState<string>('');
  const [initialRetries, setInitialRetries] = useState(1);
  const [, setTransfersReadyToSend] = useState<sendTef[]>();
  const [asyncProcessStatusIsDone, setAsyncProcessStatusIsDone] = useState(false);
  const [asyncProcessStatusInProgress, setAsyncProcessStatusInProgress] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
  const [isClaveMac, setClaveMac] = useState(!isAuthenticated);
  const [statusAlert, setStatusAlert] = useState<boolean>(true);
  const [isAnticipate, setIsAnticipate] = useState<boolean | null>(null);
  const [openModalProsegur, setOpenModalProsegur] = useState<boolean>(false);
  const [isOutOfTime, setIsOutOfTime] = useState<boolean | null>(null);
  const [flagModal, setFlagModal] = useState<boolean>(false);
  const [eventForm, setEventForm] = useState<React.FormEvent>();
  const [errorMackeyEvent, setErrorMackeyEvent] = useState<TransferData | null>(null);
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();

  const { status, refetch } = useQuery(['get-name-company'], () => getNameCompany().then(res => res.data), {
    retry: false,
    onSuccess: data => {
      setNameCompany(data?.data?.companyName);
    },
  });

  const { refetch: refetchSubscriberId } = useQuery(['get-subscriber-id'], () => getSubscriberId()?.then(res => res.data), {
    retry: false,
    onSuccess: data => {
      setSubscriberId(data?.data?.subscriberId);
    },
  });

  const { refetch: refetchProsegur } = useQuery(['get-params-prosegur'], () => getParamsProsegur()?.then(res => res.data), {
    retry: false,
    enabled: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.satisfactoryProcess) {
        setIsOutOfTime(data?.data?.isOutOfTime);
      } else {
        setIsOutOfTime(false);
      }
    },
    onError: () => setIsOutOfTime(false),
  });

  const { refetch: refetchAsyncProcess } = useQuery(
    ['get-async-process', idProcess],
    () => getAsyncProcess(ServiceAction.ENV, idProcess).then(res => res.data),
    {
      enabled: false,
      retry: true,
      onSuccess: data => {
        const exception = data?.exception?.code;
        if (initialRetries === ASYNC_MAX_RETRIES || EXCEPTION_CODES.restClientTimeOut === exception) {
          setAsyncProcessStatusInProgress(false);
          return setAsyncProcessStatusIsDone(true);
        }
        if (EXCEPTION_CODES.asyncSendInProgress === exception) {
          setAsyncProcessStatusInProgress(true);
          setTimeout(() => {
            refetchAsyncProcess();
            setInitialRetries(initialRetries + 1);
          }, ASYNC_DELAY_RETRY);
        }
        if (EXCEPTION_CODES.restClientTimeOut === exception) {
          setAsyncProcessStatusIsDone(true);
        } else if (EXCEPTION_CODES.asyncSendFailed === exception) {
          setIsAsyncError(true);
        } else if (EXCEPTION_CODES.satisfactoryProcess === exception) {
          setAsyncProcessStatusInProgress(false);
          processTransfersDataResult(data);
        }
        return data;
      },
      onError: error => {
        console.log('An error occurred:', error);
      },
    },
  );

  const { mutate, isLoading: isFetchingsendTransfers } = useMutation(
    (params: any) => {
      return postSendTefs(params);
    },
    {
      onSuccess: data => {
        const exceptionCode = data?.data?.exception?.code;
        switch (exceptionCode) {
          case EXCEPTION_CODES.totalReject:
            pushAnalyticsEvent({
              event: 'transferencia_enviada',
              content_group: 'Transferencias - Resumen de envio',
              status: 'Rechazo total',
            });
            break;
          case EXCEPTION_CODES.parcialReject:
            pushAnalyticsEvent({
              event: 'transferencia_enviada',
              content_group: 'Transferencias - Resumen de envio',
              status: 'Rechazo parcial',
            });
            break;
          case EXCEPTION_CODES.asyncSendProcess:
            setIdProcess(data?.data?.data?.processId);
            processTransfersDataResult(data?.data);
            break;
          case EXCEPTION_CODES.restClientTimeOut:
            setPassword('');
            setAsyncProcessStatusIsDone(true);
            setPasswordCompleted(0);
            setStatusButtom(true);
            setCompanyKeyNotFound(false);
            break;
          case EXCEPTION_CODES.asyncSendFailed:
            setIsAsyncError(true);
            break;
          default:
            break;
        }
        if (!idProcess && exceptionCode !== EXCEPTION_CODES.restClientTimeOut) {
          if (isClaveMac) {
            processTransfersDataResult(data?.data);
          } else {
            setErrorMackeyEvent(data?.data);
          }
        }
      },
      onError: () => {
        setPassword('');
        setIsError(true);
        setPasswordCompleted(0);
        setStatusButtom(true);
        setCompanyKeyNotFound(false);
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'Envio fallido de transferencias',
        });
      },
    },
  );

  const { isFetching: isFetchingMacKeys } = useQuery(
    ['get-mac-keys', pathParamsGetBankCodes],
    () => getMacKeys(pathParamsGetBankCodes).then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: data => {
        const code = data?.exception?.code;
        const failures = data?.data?.failures;
        switch (code) {
          case EXCEPTION_CODES.incorrectCompanyKey:
            setStatusButtom(true);
            if (failures > 1) {
              setPassword('');
              pushAnalyticsEvent({
                event: 'modal_impression',
                modal_name: 'Advertencia inhabilitación de usuario',
              });
              pushAnalyticsEvent({
                event: 'modal_impression',
                modal_name: 'Clave Empresa incorrecta',
              });
              setIsErrorSecondTime(true);
              setCompanyKeyNotFound(true);
            } else {
              pushAnalyticsEvent({
                event: 'modal_impression',
                modal_name: 'Clave Empresa incorrecta',
              });
              setPassword('');
              setCompanyKeyNotFound(true);
            }
            break;
          case EXCEPTION_CODES.failedProcess:
            setStatusButtom(true);
            setPassword('');
            setIsError(true);
            setPasswordCompleted(0);
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'Envio fallido de transferencias',
            });
            setCompanyKeyNotFound(false);
            break;
          case EXCEPTION_CODES.userDisabled:
            setStatusButtom(true);
            setPassword('');
            pushAnalyticsEvent({
              event: 'user_disabled',
              content_group: 'Transferencias',
            });
            setIsErrorThirdTime(true);
            setCompanyKeyNotFound(true);
            break;
          case EXCEPTION_CODES.restClientTimeOut:
            setIsError(true);
            setPassword('');
            setPasswordCompleted(0);
            setStatusButtom(true);
            break;
          default:
            setErrorsUnassociatedMacKey(
              SortTransfers(
                data?.data?.repository,
                responseOfTransferSelectedByUser,
                password,
                bankCodes,
                [],
                '',
                isAnticipate,
                hasNextTefDay,
                isOutOfTime,
                flagModal,
              ).TEF_UNASSOCIATED_MAC_KEY,
            );
            const { OrderedTransfers } = SortTransfers(
              data?.data?.repository,
              responseOfTransferSelectedByUser,
              password,
              bankCodes,
              [],
              '',
              isAnticipate,
              hasNextTefDay,
              isOutOfTime,
              flagModal,
            );
            if (OrderedTransfers.length <= 0) {
              setTransfersRejected([
                SortTransfers(
                  data?.data?.repository,
                  responseOfTransferSelectedByUser,
                  password,
                  bankCodes,
                  [],
                  '',
                  isAnticipate,
                  hasNextTefDay,
                  isOutOfTime,
                  flagModal,
                ).TEF_UNASSOCIATED_MAC_KEY,
              ] as TransfersRejected[]);
              navigate(routesNames.ResultSend);
            } else {
              mutate(OrderedTransfers);
            }
            break;
        }
      },
      onError: () => {
        setPassword('');
        setIsError(true);
        setPasswordCompleted(0);
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'Envio fallido de transferencias',
        });
        setStatusButtom(true);
        setCompanyKeyNotFound(false);
      },
    },
  );

  useEffect(() => {
    if (errorsUnassociatedMacKey?.totalTransferByMessage >= 0 && errorMackeyEvent !== null) {
      processTransfersDataResult(errorMackeyEvent);
    }
  }, [errorMackeyEvent]);

  useEffect(() => {
    if (localStorage.getItem('wizard-key-transfer') == null) {
      setInitialized(true);
    }
    refetch();
  }, []);

  useEffect(() => {
    refetchSubscriberId();
  }, []);

  useEffect(() => {
    if (!idProcess) return;
    refetchAsyncProcess();
  }, [idProcess]);

  useEffect(() => {
    if (lotsSelected.length <= 0) navigate(routesNames.Root);
    dataTotalsTransfers.forEach(element => {
      if (element.currencyType === ARS_CURRENCY) {
        setTotalTransfersArs(element.totalTransfers);
        setTotalAmountArs(element.amount);
        setHasPartialAmountSue(element.hasPartialAmountSue);
      }
      if (element.currencyType === USD_CURRENCY) {
        setTotalTransfersUsd(element.totalTransfers);
        setTotalAmountUsd(element.amount);
        setHasPartialAmountSueUsd(element.hasPartialAmountSue);
      }
    });
  }, []);

  const processTransfersDataResult = (data: TransferData) => {
    if (errorsUnassociatedMacKey?.totalTransferByMessage > 0) {
      setTransfersRejected((previous: TransfersRejected[]) => {
        return [...previous, ...data?.data?.transfersRejected, errorsUnassociatedMacKey];
      });
    } else {
      setTransfersRejected((previous: TransfersRejected[]) => {
        return [...previous, ...data?.data?.transfersRejected] as TransfersRejected[];
      });
    }
    setTransfersAccepted((previous: TransfersAccepted[]) => {
      return [...previous, ...data?.data?.transfersAccepted];
    });
    if (EXCEPTION_CODES.asyncSendProcess !== data?.exception?.code) {
      if (
        data?.exception?.code === EXCEPTION_CODES.satisfactoryProcess &&
        (errorsUnassociatedMacKey?.totalTransferByMessage === 0 || errorsUnassociatedMacKey?.totalTransferByMessage === undefined || 0) &&
        transfersRejected.length === 0
      ) {
        setIsSuccessOpen(true);
        pushAnalyticsEvent({
          event: 'transferencia_enviada',
          content_group: 'Transferencias - Resumen de envio',
          status: 'Transferencias exitosas',
        });
      } else {
        navigate(routesNames.ResultSend);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    event.key === KeyboardKeys.Enter && event.preventDefault();
  };

  const handleCommonLogic = (event: any, valueResetIsAnticipate: boolean | null = null) => {
    event?.preventDefault();
    pushAnalyticsEvent({
      event: 'enviar_transferencias',
      content_type: isClaveMac ? 'Botones seccion Clave MAC' : 'Botones seccion Clave Empresa',
      item_id: 'Enviar transferencias',
    });
    if (isClaveMac) {
      event.preventDefault();
      pushAnalyticsEvent({
        event: 'enviar_transferencias',
        content_type: 'Botones seccion Clave MAC',
        item_id: 'Enviar transferencias',
      });
      logicToSendIsClaveMac(valueResetIsAnticipate);
    } else {
      pushAnalyticsEvent({
        event: 'enviar_transferencias',
        content_type: 'Botones seccion Clave Empresa',
        item_id: 'Enviar transferencias',
      });
      const companyKeyIn = hashPass(password, password);
      let pathParams = `companyKeyIn=${companyKeyIn}&`;
      bankCodes?.forEach((item: string, index: number) => {
        pathParams += `listBanks=${item}`;
        if (bankCodes.length !== index + 1) pathParams += '&';
      });
      setPathParamsGetBankCodes(pathParams);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    refetchProsegur();
    setEventForm(event);
  };

  useEffect(() => {
    if (isOutOfTime !== null) {
      logicToSend(eventForm);
    }
  }, [isOutOfTime, eventForm]);

  const logicToSend = (event: any) => {
    if (!hasNextTefDay) {
      handleCommonLogic(event);
    } else if (hasNextTefDay && isBusinessDay && isOutOfTime) {
      setOpenModalProsegur(true);
      setFlagModal(true);
      if (isAnticipate) {
        handleCommonLogic(event);
      }
    } else if (hasNextTefDay && !isBusinessDay) {
      setOpenModalProsegur(true);
      setFlagModal(true);
      if (isAnticipate) {
        handleCommonLogic(event);
      }
    } else if (hasNextTefDay && isBusinessDay && !isOutOfTime) {
      handleCommonLogic(event);
    }
  };

  const logicToSendIsClaveMac = (valueFlag: boolean | null) => {
    const macsByBanks = Object.entries(bankPasswords)
      .filter(([, value]) => value !== '')
      .map(([key, value]) => ({ key, value }));
    const { OrderedTransfers, ListKeys } = SortTransfers(
      null,
      responseOfTransferSelectedByUser,
      '',
      [''],
      macsByBanks,
      subscriberId,
      valueFlag,
      hasNextTefDay,
      isOutOfTime,
      flagModal,
    );
    setTransfersReadyToSend(OrderedTransfers);
    const filteredBanks = ListKeys.filter((obj, index, arr) => {
      return index === arr.findIndex((t: macKeys) => t.bankCode === obj.bankCode && !t.isValidlastDigit);
    });
    setHasPasswordError(filterBanks(dataDetailsTransfers).length > filteredBanks?.length);
    if (filteredBanks?.length > 0) {
      setStatusButtom(true);
    }
    const banks = filteredBanks.map((item: macKeys) => item.bankName);
    if (banks.length === 1) {
      setBankList(banks[0]);
    } else if (banks.length === 2) {
      setBankList(`${banks[0]} y ${banks[1]} `);
    } else {
      setBankList(`${banks.slice(0, -1).join(', ')} y ${banks[banks.length - 1]} `);
    }
    setbanksMacError(filteredBanks.length);
    setIsErrorEighthDigit(filteredBanks.some((item: macKeys) => item.isValidlastDigit === false));
    const newIsValidlastDigit = filterBanks(dataDetailsTransfers)?.map((item: any) => {
      const bank = ListKeys?.find((s: macKeys) => s.bankCode === item.bankCode);
      return bank ? bank?.isValidlastDigit : false;
    });
    setIsValidlastDigit(newIsValidlastDigit);
    setSend(!send);
    if (newIsValidlastDigit.every(value => value === true)) {
      mutate(OrderedTransfers);
    }
  };

  const goBack = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones seccion Clave Empresa',
      item_id: 'Volver',
    });
    navigate(routesNames.Root, { state: { from: location } });
  };

  useEffect(() => {
    if (pathParamsGetBankCodes.length > 0) {
      queryClient.refetchQueries(['get-mac-keys', pathParamsGetBankCodes]);
    }
  }, [pathParamsGetBankCodes, queryClient]);

  useEffect(() => {
    if (isErrorThirdTime && !isClaveMac) {
      countdown > COUNTDOWN_START && setTimeout(() => setCountdown(countdown - 1), COUNTDOWN_MS);
      if (countdown < COUNTDOWN_MIN) {
        authorizer.logout();
      }
    }
  }, [countdown, isErrorThirdTime]);

  useEffect(() => {
    if (isErrorEighthDigit) {
      pushAnalyticsEvent({
        event: 'modal_impression',
        modal_name: 'Clave MAC incorrecta',
      });
    }
  }, [isErrorEighthDigit]);

  useEffect(() => {
    if (isClaveMac) {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Transferencias - Clave MAC',
      });
      setPassword('');
      setPasswordCompleted(0);
      setBankPasswords({});
    } else {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Transferencias - Clave empresa',
      });
    }
  }, [isClaveMac]);

  const handleValidationLength = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const valueInput = e.target.value;
    setPassword(valueInput);
    const validation = REGEX_INPUT_COMPANY_KEY.test(valueInput);
    setStatusButtom(!validation);
  };

  const handleReturnToTransfersClick = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal transferencias enviadas',
      item_id: 'Enviar más transferencias',
    });
    clearSelectedLots();
    navigate(routesNames.Root);
  };

  const handleReturnToHome = () => {
    navigate('/');
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal transferencias enviadas',
      item_id: 'Ir a inicio',
    });
  };

  const handleErrorContinue = () => {
    setIsError(false);
    setBankPasswords({});
    clearSelectedLots();
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Envio fallido de transferencias',
      item_id: 'Entendido',
    });
    setLotsSelected([]);
    setDataLot([]);
    setPageContext(1);
    setSizeContext(15);
    navigate(routesNames.Root);
  };

  const companyKeyTitle = companyKeyNotFound ? TEXT_ENTITY_KEY.titleError : TEXT_ENTITY_KEY.title;
  const companyKeyDescriptionOne = companyKeyNotFound ? TEXT_ENTITY_KEY.descriptionOneError : TEXT_ENTITY_KEY.descriptionOne;
  const companyKeyDescriptionFour = companyKeyNotFound ? TEXT_ENTITY_KEY.descriptionFourError : TEXT_ENTITY_KEY.descriptionFour;

  const handlePasswordChange = (bankCode: string, macPassword: string, bankName: string) => {
    const paswords = { ...bankPasswords, [bankCode]: macPassword };
    const valuesPasword = Object.values(paswords);
    const allHaveEightChars = Object.values(paswords).every((value: string | any) => value.substr(0, 8).length === 8);
    const count = valuesPasword
      .map(valueInput => {
        if (valueInput.length > 8) {
          return valueInput.substring(0, 8);
        }
        if (valueInput.length === 8) {
          return valueInput;
        }
      })
      .filter(valueInput => valueInput !== undefined);
    setPasswordCompleted(count.length);
    if (valuesPasword.length === filterBanks(dataDetailsTransfers).length && allHaveEightChars) {
      setStatusButtom(false);
    } else {
      setStatusButtom(true);
    }
    setBankPasswords({ ...bankPasswords, [bankCode]: `${macPassword}${bankName}` });
  };

  const handleContinue = () => {
    setIsErrorEighthDigit(false);
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Clave MAC incorrecta',
      item_id: 'Continuar Envio',
    });
    const macsByBanks = Object.entries(bankPasswords)
      .filter(([, value]) => value !== '')
      .map(([key, value]) => ({ key, value }));
    const { OrderedTransfersFilterdByValidMac } = SortTransfers(
      null,
      responseOfTransferSelectedByUser,
      '',
      [''],
      macsByBanks,
      subscriberId,
      isAnticipate,
      hasNextTefDay,
      isOutOfTime,
      flagModal,
    );
    mutate(OrderedTransfersFilterdByValidMac);
  };

  const handleCheck = () => {
    setIsErrorEighthDigit(false);
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Clave MAC incorrecta',
      item_id: 'Revisar Claves',
    });
  };

  const handleCloseModalErrorPasword = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Advertencia inhabilitación de usuario',
      item_id: 'Entendido',
    });
    setIsErrorSecondTime(false);
  };

  const handleNoActiveAnticipate = () => {
    setIsAnticipate(false);
    setOpenModalProsegur(false);
    handleCommonLogic(eventForm, false);
  };

  const handleActiveAnticipate = () => {
    setIsAnticipate(true);
    setOpenModalProsegur(false);
    handleCommonLogic(eventForm, true);
  };

  const handleTabChange = (index: number) => {
    setPassword('');
    setCompanyKeyNotFound(false);
    setPasswordCompleted(0);
    setBankPasswords({});
    setStatusButtom(true);
    if (index === 1) {
      setClaveMac(true);
    } else {
      setClaveMac(false);
    }
  };

  const handleFinishWizard = () => {
    localStorage.setItem('wizard-key-transfer', 'true');
  };

  return (
    <BaseScreen title={TITLES_SCREENS.titleEntityKey} sizeTitle='h2Bold' id='entityKey'>
      <>
        {(isFetchingMacKeys || isFetchingsendTransfers) && (
          <LoadingScreen title={LOADING_SEND_TRANSFERS.title} description={LOADING_SEND_TRANSFERS.description} />
        )}
        {((hasPartialAmountSue && totalAmountArs !== 0) || (hasPartialAmountSueUsd && totalAmountUsd !== 0)) && statusAlert && (
          <AlertComponent
            variant='warning'
            width='100%'
            onClose={() => {
              setStatusAlert(false);
            }}
          >
            <AlertTitle>{ALERT_SUE_TEXT.title}</AlertTitle>
            <AlertDescription>{ALERT_SUE_TEXT.description}</AlertDescription>
          </AlertComponent>
        )}
        {initialized && (
          <WizardComponent
            id='wizard-key-transfer'
            initialized={initialized}
            setInitialized={setInitialized}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={WizardSteps}
            width={300}
            height={250}
            offset={0}
            onFinish={handleFinishWizard}
          />
        )}
        <CustomSummary
          title='Resumen'
          totalTransfersArs={totalTransfersArs}
          totalAmountArs={totalAmountArs}
          totalTransfersUsd={totalTransfersUsd}
          totalAmountUsd={totalAmountUsd}
          id='sendSummary'
        />
        <Tabs size='lg' defaultIndex={!isAuthenticated ? 1 : 0} isLazy onChange={handleTabChange}>
          <Flex sx={TabFlexStyle} id='optionKey'>
            <Text sx={CustomTabTitle} textStyle='titleLg'>
              Elegí una clave para enviar tus transferencias:
            </Text>
            <Tab isAuthenticated={isAuthenticated} setClaveMac={setClaveMac} />
          </Flex>
          <TabPanels>
            <TabPanel>
              <CustomBoxWithInput
                companyKeyNotFound={companyKeyNotFound}
                companyKeyTitle={companyKeyTitle}
                companyKeyDescriptionOne={companyKeyDescriptionOne}
                companyKeyDescriptionTwo={TEXT_ENTITY_KEY.descriptionTwo}
                companyKeyDescriptionThree={TEXT_ENTITY_KEY.descriptionThree}
                password={password}
                show={show}
                showPassword={showPassword}
                handleValidationLength={handleValidationLength}
                onMouseLeave={onMouseLeave}
                hidePassword={hidePassword}
                status={status}
                nameCompany={nameCompany}
                companyKeyDescriptionFour={companyKeyDescriptionFour}
                textPlaceholder={TEXT_ENTITY_KEY.textPlaceholder}
                maxLength={100}
                id='inputKey'
              />
            </TabPanel>
            <TabPanel>
              <Box sx={ContentForm}>
                <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
                  <Text>{`${passwordCompleted} de ${filterBanks(dataDetailsTransfers).length} ${
                    filterBanks(dataDetailsTransfers).length > 1 ? 'claves ingresadas' : 'clave ingresada'
                  } `}</Text>
                  {filterBanks(dataDetailsTransfers)?.map((item: TransferOperationDetails, index: number) => (
                    <PasswordBank
                      bankName={item.bankName}
                      bankCode={item.bankCode}
                      key={item.transfer}
                      onPasswordChange={handlePasswordChange}
                      isValidlastDigit={isValidlastDigit[index]}
                      send={send}
                      setIsValidlastDigit={setIsValidlastDigit}
                      setToDefault={!isClaveMac}
                      isError={isError}
                    />
                  ))}
                </form>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Footer handleGoBack={goBack} statusButtom={statusButtom} onSubmit={handleSubmit} textButton={TEXT_BUTTON_SEND.textEntityKey} />
        <CustomModal
          isOpen={isSuccessOpen}
          onCancel={setIsSuccessOpen}
          actionButton={handleReturnToHome}
          actionButton2={handleReturnToTransfersClick}
          Icon={IconCheckMark}
          altIcon={TEXT_MODAL_CHECKMARK_SUCCESS.altIcon}
          title={TEXT_MODAL_CHECKMARK_SUCCESS.title}
          firstDescription={TEXT_MODAL_CHECKMARK_SUCCESS.firstDescription}
          firstTextButton={TEXT_MODAL_CHECKMARK_SUCCESS.firstTextButton}
          secondTextButton={TEXT_MODAL_CHECKMARK_SUCCESS.secondTextButton}
          enableFirstButton
          enableSecondButton
          alternativeButtonStyling
          size='xl'
        />
        <CustomModal
          isOpen={isError}
          onCancel={setIsError}
          Icon={IconCancelModal}
          altIcon={TEXT_MODAL_SEND_ERROR.altIcon}
          title={TEXT_MODAL_SEND_ERROR.title}
          firstDescription={TEXT_MODAL_SEND_ERROR.firstDescription}
          firstTextButton={TEXT_MODAL_SEND_ERROR.firstTextButton}
          enableFirstButton
          actionButton={handleErrorContinue}
          size='xl'
        />
        <CustomModal
          isOpen={isErrorSecondTime}
          onCancel={setIsErrorSecondTime}
          Icon={IconBeware}
          altIcon={TEXT_MODAL_SEND_ERROR_SECOND.altIcon}
          title={TEXT_MODAL_SEND_ERROR_SECOND.title}
          firstDescription={TEXT_MODAL_SEND_ERROR_SECOND.firstDescription}
          firstTextButton={TEXT_MODAL_SEND_ERROR_SECOND.firstTextButton}
          enableFirstButton
          actionButton={handleCloseModalErrorPasword}
          size='xl'
        />
        <CustomModal
          isOpen={isErrorThirdTime}
          onCancel={() => false}
          Icon={IconCancelModal}
          altIcon={TEXT_MODAL_SEND_ERROR_THIRD.altIcon}
          title={TEXT_MODAL_SEND_ERROR_THIRD.title}
          firstDescription={TEXT_MODAL_SEND_ERROR_THIRD.firstDescription}
          bottomDescription={`Cerraremos tu sesión en ${countdown} segundos`}
          size='xl'
        />
        <CustomModal
          isOpen={isErrorEighthDigit}
          onCancel={() => false}
          Icon={IconInfoPopup}
          altIcon={TEXT_MODAL_ERROR_EIGHTH_DIGIT.altIcon}
          title={TEXT_MODAL_ERROR_EIGHTH_DIGIT.title(banksMacError)}
          firstDescription=''
          firstTextButton={TEXT_MODAL_ERROR_EIGHTH_DIGIT.firstTextButton}
          secondTextButton={TEXT_MODAL_ERROR_EIGHTH_DIGIT.secondTextButton(banksMacError)}
          enableFirstButton={hasPasswordError}
          enableSecondButton
          alternativeButtonStyling
          actionButton={handleContinue}
          actionButton2={handleCheck}
          size='xl'
        >
          <ModalChildrenErrorMac banksMacError={banksMacError} bankList={bankList} hasPasswordError={hasPasswordError} />
        </CustomModal>
        <CustomModal
          isOpen={asyncProcessStatusInProgress}
          onCancel={setAsyncProcessStatusInProgress}
          iconSpinner
          altIcon={TEXT_MODAL_SEND_ASYNC_IN_PROGRESS.altIcon}
          firstDescriptionWithBold={asyncModalInProgressText}
          title={TEXT_MODAL_SEND_ASYNC_IN_PROGRESS.title}
          firstTextButton={TEXT_MODAL_SEND_ASYNC_IN_PROGRESS.firstTextButton}
          enableFirstButton
          actionButton={handleReturnToTransfersClick}
          size='xl'
        />
        <CustomModal
          isOpen={asyncProcessStatusIsDone}
          onCancel={setAsyncProcessStatusIsDone}
          Icon={IconInfoPopup}
          altIcon={TEXT_MODAL_SEND_ASYNC_IS_DONE.altIcon}
          firstDescriptionWithBold={asyncModalIsDoneText}
          title={TEXT_MODAL_SEND_ASYNC_IS_DONE.title}
          firstTextButton={TEXT_MODAL_SEND_ASYNC_IS_DONE.firstTextButton}
          enableFirstButton
          actionButton={handleReturnToTransfersClick}
          size='xl'
          cancelType
        />
        <CustomModal
          isOpen={isAsyncError}
          Icon={IconBeware}
          onCancel={setAsyncProcessStatusIsDone}
          altIcon={TEXT_MODAL_SEND_ASYNC_IS_DONE.altIcon}
          firstDescriptionWithBold={asyncModalErrorText}
          title={TEXT_MODAL_ASYNC_ERROR.title}
          firstTextButton={TEXT_MODAL_SEND_ASYNC_IS_DONE.firstTextButton}
          enableFirstButton
          actionButton={handleReturnToTransfersClick}
          size='xl'
          cancelType
        />
        <CustomModal
          isOpen={openModalProsegur}
          Icon={IconInfoPopup}
          onCancel={setAsyncProcessStatusIsDone}
          altIcon={TEXT_MODAL_PROSEGUR.altIcon}
          firstDescriptionWithBold={TEXT_MODAL_PROSEGUR.textContentModal}
          title={TEXT_MODAL_PROSEGUR.title}
          firstTextButton={TEXT_MODAL_PROSEGUR.firstTextButton}
          secondTextButton={TEXT_MODAL_PROSEGUR.secondTextButton}
          enableFirstButton
          actionButton={handleNoActiveAnticipate}
          actionButton2={handleActiveAnticipate}
          enableSecondButton
          size='xl'
          alternativeButtonStyling
        />
      </>
    </BaseScreen>
  );
}

export default EntityKey;
