import { ChangeEvent, useCallback, useState } from 'react';

import { BaseTransferSetupState, CheckboxLogicHook, SetFormState } from '../Interfaces/transferSetup';
import { checkboxesFields } from '../views/TransferSetup/Panels/ManualSetupTransfers/constants';

const useCheckboxLogic = <T extends BaseTransferSetupState>(initialState: T): CheckboxLogicHook<T> => {
  const [unifiedFirstChecked, setUnifiedFirstChecked] = useState(initialState.unifiedShipping);

  const handleCheckboxChange = useCallback(
    (formState: T, setFormState: SetFormState<T>) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;

      if (name === checkboxesFields.consolidated) {
        setFormState(prevFormState => ({
          ...prevFormState,
          [name]: checked,
          unifiedShipping: checked ? true : unifiedFirstChecked,
        }));
      } else if (name === checkboxesFields.unifiedShipping) {
        setUnifiedFirstChecked(checked);
        if (!formState.consolidated) {
          setFormState(prevFormState => ({ ...prevFormState, [name]: checked }));
        }
      }
    },
    [unifiedFirstChecked],
  );

  return { handleCheckboxChange };
};

export default useCheckboxLogic;
