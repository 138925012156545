import { colors } from '../../styled/generalStyles';

const AlertStyles = {
  alignItems: 'center',
  color: colors.Warning100,
  display: 'flex',
  margin: '4.5px',
  marginRight: '0px',
  width: '17px',
};

export default AlertStyles;
