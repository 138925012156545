export const PRE_CHANGE_NOTIFICATION = {
  title: '¡Buen trabajo! Estás por subir de nivel',
  description: 'Si superás el límite de monto o cantidad de transferencias, te pasamos al plan',
  textButton: 'Ver consumo',
};
export const CHANGE_NOTIFICATION = {
  title: '¡Subiste de nivel! Ahora estás en el plan',
  description: 'Superaste el límite de tu plan, así que lo mejoramos. ¡Ahora podés realizar más transferencias y descargas!',
  bonusText: 'mejoramos y te lo bonificamos por',
  textButton: 'Ver consumo',
};

export const RESPONSE_CODES = {
  PRE_CHANGE: 'TEF-0026',
  CHANGE: 'TEF-0027',
  ERROR: 'TEF-0000',
};

export const IS_VISIBLE_OFFERING_ALERT = 'isVisibleOfferingAlert';
export const COMPANY_ID = 'companyId';
