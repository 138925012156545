import { Image, Td, Th } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const CustomTh = styled(Th)`
  &:first-child {
    & > div {
      padding-left: 6.5px;
      padding-right: 17.5px;
      width: 100%;
      height: 100%;
    }
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(5) {
    & > div {
      margin-left: 16px;
      gap: 0px;
    }
  }

  &:nth-child(4) {
    width: 222px;
    padding-left: 16px;
    & > p {
      gap: 0px;
      width: 222px;
    }
  }

  &:nth-child(9) {
    & > div {
      width: 39.5px;
      height: 39.5px;
    }
  }
`;

const CustomThModify = styled(Th)`
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(4) {
    & > div {
      margin-left: 16px;
      gap: 0px;
    }
  }

  &:nth-child(3) {
    width: 222px;
    padding-left: 16px;
    & > p {
      gap: 0px;
      width: 222px;
    }
  }
`;

const BankImage = styled(Image)`
  width: '32px';
  height: '32px';
  margin-right: '6px';
`;

const CustomTd = styled(Td)`
  &:first-child {
    width: 47.5px;
    & > div {
      display: flex;
      padding-left: 6.5px;
      padding-right: 17.5px;
      width: 47.5px;
    }
  }

  &:nth-child(2) {
    p {
      padding-left: 16px;
    }
  }

  &:nth-child(3) {
    & > div {
      max-height: 47.5px;
      height: 60px;
      padding-left: 16px;
      gap: 0px;
      &:last-child {
        & > div {
          p {
            white-space: nowrap;
            overflow: hidden;
            max-width: 400px;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &:nth-of-type(4) {
    width: 222px;
    & > p {
      padding-right: 16px;
      display: flex;
      flex-direction: row-reverse;
      width: 222px;
    }
  }

  &:nth-child(5) {
    p {
      padding-left: 16px;
    }
  }

  &:nth-child(6) {
    width: 39.5px;
    height: 39.5px;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 39.5px;
      height: 39.5px;
    }
  }
`;

const CustomTdModify = styled(Td)`
  &:first-child {
    p {
      padding-left: 16px;
    }
  }

  &:nth-child(2) {
    & > div {
      max-height: 47.5px;
      height: 60px;
      padding-left: 16px;
      gap: 0px;
      &:last-child {
        & > div {
          p {
            white-space: nowrap;
            overflow: hidden;
            max-width: 400px;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &:nth-of-type(3) {
    width: 222px;
    & > p {
      padding-right: 16px;
      display: flex;
      flex-direction: row-reverse;
      width: 222px;
    }
  }

  &:nth-child(4) {
    p {
      padding-left: 16px;
    }
  }
`;

const Separator = {
  margin: '0px 4px',
};

const ColorFontsHeaderTable = {
  color: colors.Grey120,
  textTransform: 'initial',
};

const Paginator = {
  borderTop: 'solid 1px rgba(0, 0, 0, 0.24)',
  marginTop: '12px',
  height: '64px',
};

export { BankImage, ColorFontsHeaderTable, CustomTd, CustomTdModify, CustomTh, CustomThModify, Paginator, Separator };
