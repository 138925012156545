import { Currencies } from '@interfaces/transfers';

const TITLE_TOOLTIP_UNIFIED_SHIPPING = 'Lote con envío unificado:';

const TEXT_TOOLTIP_UNIFIED_SHIPPING = 'Si tiene más de\nuna cuenta de débito, enviá todos los lotes\njuntos.';

const ALL_CODE_STATUS_TRANSFERS = {
  CON: 'CON',
  EFR: 'EFR',
  FIR: 'FIR',
};
const ALL_STATUS_TRANSFERS = {
  [ALL_CODE_STATUS_TRANSFERS.CON]: 'Confeccionada',
  [ALL_CODE_STATUS_TRANSFERS.EFR]: 'En autorización',
  [ALL_CODE_STATUS_TRANSFERS.FIR]: 'Autorizada',
};
export const MODIFY_NOTIFICATION = {
  title: 'La modificación de ARCA y BtoB se hace en la versión anterior de Pendientes',
  description1: 'Para modificar estas transferencias, hacé clic en',
  description2: '\nIr a Pendientes.',
  description3: '\nRecordá no tener ventanas emergentes bloqueadas para que podamos redireccionarte.',
  textButton: 'Ir a Pendientes',
};

const DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH = 300;

const INITIAL_STATE_TRANSFER_MODIFY = {
  addendaProviders: {
    amountNoteCred: '',
    amountRetention: '',
    codClient: '',
    numberDocCancel: '',
    numberNoteCredir: null,
    numberOrderPayment: '',
    typeDocId: { value: '', label: '' },
    typeOrderPayment: '',
    typeRetentionId: { value: '', label: '' },
  },
  concept: { value: '', label: '' },
  creditAccount: {
    accountId: '',
    accountName: '',
    accountNumber: '',
    accountType: '',
    bank: { bcoIdBco: '', bankName: '' },
    cbu: '',
    cuit: '',
    currency: Currencies.ARS,
    transferType: '',
    useReference: '',
    identifier: '',
  },
  debitAccount: {
    accountId: '',
    accountName: '',
    accountNumber: '',
    accountType: '',
    bank: { bcoIdBco: '', bankName: '' },
    cbu: '',
    cuit: '',
    currency: Currencies.ARS,
    identifier: '',
  },
  disclaimerChecked: false,
  reasons: '',
  requestDateTransfer: '',
  requestRemarkTransfer: '',
  transferAmount: '',
  voucherCod: '',
  unifiedShipping: 'N',
  lotNumber: 0,
  idTransferType: '',
  numberPayment: '',
  stepNumber: '',
  transferNumber: 0,
  subscriberNumber: '',
  consolidated: false,
  creationDate: '2024-09-12',
};

export {
  ALL_STATUS_TRANSFERS,
  DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH,
  INITIAL_STATE_TRANSFER_MODIFY,
  TEXT_TOOLTIP_UNIFIED_SHIPPING,
  TITLE_TOOLTIP_UNIFIED_SHIPPING,
};
