const TITLE = 'Información opcional';
const ICON_BUTTON_ACCORDION_TEST_ID = 'icon-button-accordion-additional-information-form';
const ADDITIONAL_INFORMATION_PRO_TEST_ID = 'additional-information-form';
const CLIENT_TEST_ID = 'client-input';
const CLIENT_NUMBER_LABEL = 'Número de cliente';
const CLIENT_NUMBER_PLACEHOLDER = 'Es el número con el que identificás al proveedor';
const MAX_LENGTH_CLIENT_NUMBER = 12;

const DOCUMENT_TO_CANCEL_TEST_ID = 'document-to-cancel-combo';
const DOCUMENT_TO_CANCEL_LABEL = 'Documento a cancelar';
const DOCUMENT_TO_CANCEL_PLACEHOLDER = 'Elegí el tipo de documento';

const DOCUMENT_NUMBER_TEST_ID = 'document-number-input';
const DOCUMENT_NUMBER_LABEL = 'Número de documento a cancelar';

const RETENTION_TYPE_TEST_ID = 'retention-input';
const RETENTION_TYPE_LABEL = 'Tipo de retención';
const RETENTION_TYPE_PLACEHOLDER = 'Elegí el tipo de retención';

const RETENTION_AMOUNT_TEST_ID = 'retention-amount-input';
const RETENTION_AMOUNT_LABEL = 'Importe total de retención';
const RETENTION_AMOUNT_PLACEHOLDER = 'Elegí el tipo de retención';

const PAYMENT_ORDER_TYPE_TEST_ID = 'payment-order-type-input';
const PAYMENT_ORDER_TYPE_LABEL = 'Tipo de orden de pago';
const MAX_LENGTH_PAYMENT_ORDER_TYPE = 2;

const PAYMENT_ORDER_NUMBER_TEST_ID = 'payment-order-number-input';
const PAYMENT_ORDER_NUMBER_LABEL = 'Número de orden de pago';

const CREDIT_NOTE_NUMBER_TEST_ID = 'credit-note-number-input';
const CREDIT_NOTE_NUMBER_LABEL = 'Número de nota de crédito';

const CREDIT_NOTE_AMOUNT_TEST_ID = 'credit-note-amount-input';
const CREDIT_NOTE_AMOUNT_LABEL = 'Importe de nota de crédito';
const PLACEHOLDER_INPUT_AMOUNT = '0,00';

const TWELVE_CHARACTERS_MAXIMUM = 12;

export {
  ADDITIONAL_INFORMATION_PRO_TEST_ID,
  CLIENT_NUMBER_LABEL,
  CLIENT_NUMBER_PLACEHOLDER,
  CLIENT_TEST_ID,
  CREDIT_NOTE_AMOUNT_LABEL,
  CREDIT_NOTE_AMOUNT_TEST_ID,
  CREDIT_NOTE_NUMBER_LABEL,
  CREDIT_NOTE_NUMBER_TEST_ID,
  DOCUMENT_NUMBER_LABEL,
  DOCUMENT_NUMBER_TEST_ID,
  DOCUMENT_TO_CANCEL_LABEL,
  DOCUMENT_TO_CANCEL_PLACEHOLDER,
  DOCUMENT_TO_CANCEL_TEST_ID,
  ICON_BUTTON_ACCORDION_TEST_ID,
  MAX_LENGTH_CLIENT_NUMBER,
  MAX_LENGTH_PAYMENT_ORDER_TYPE,
  PAYMENT_ORDER_NUMBER_LABEL,
  PAYMENT_ORDER_NUMBER_TEST_ID,
  PAYMENT_ORDER_TYPE_LABEL,
  PAYMENT_ORDER_TYPE_TEST_ID,
  PLACEHOLDER_INPUT_AMOUNT,
  RETENTION_AMOUNT_LABEL,
  RETENTION_AMOUNT_PLACEHOLDER,
  RETENTION_AMOUNT_TEST_ID,
  RETENTION_TYPE_LABEL,
  RETENTION_TYPE_PLACEHOLDER,
  RETENTION_TYPE_TEST_ID,
  TITLE,
  TWELVE_CHARACTERS_MAXIMUM,
};
