import initialState from '@context/initialState';
import { isManualTransferFormEmpty } from '@utils/isManualTransferFormEmpty';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { EXCEPTION_CODES } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { Permissions } from '../Interfaces/api';
import { DraftModal, DraftModalAction } from '../Interfaces/modals';
import { getPermissions } from '../services/api';
import { DRAFT_MODAL } from '../views/TransferSetup/constants';

export function useTransferSetup() {
  const navigate = useNavigate();
  const {
    manualTransferData,
    transferTypeByOperators,
    isExitConfectionModalOpen,
    redirectPath,
    confectionReset,
    setTransferTypeOptionSelected,
    setManualTransferData,
    setSectionPermissions,
    clearConfection,
    setIsExitConfectionModalOpen,
    setRedirectPath,
    setConfectionReset,
  } = useTransfersContext();
  const [confectionPermits, setConfectionPermits] = useState({ canCreate: false, canUpload: false });
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabTempIndex, setTabTempIndex] = useState<number>(0);
  const [statusGetPermission, serStatusGetPermission] = useState<boolean>(false);
  const [activeViewNotResult, setActiveViewNotResult] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [statusButtonDisabled, setStatusButtonDisabled] = useState<boolean>(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [draftModal, setDraftModal] = useState<DraftModal | null>(null);
  const [draftModalAction, setDraftModalAction] = useState<DraftModalAction | null>(null);

  const transferType = transferTypeByOperators.find(item => item.code === manualTransferData.transferType);

  const draftModalTxt = draftModal === DraftModal.edit ? DRAFT_MODAL.edit : DRAFT_MODAL.add;

  const { refetch } = useQuery(['get-permissions-operators'], () => getPermissions().then(res => res.data), {
    retry: 3,
    onSuccess: (data: Permissions) => {
      if (data?.exception?.code === EXCEPTION_CODES.timeoutError) {
        setActiveViewNotResult(true);
      }
      setConfectionPermits({ canCreate: data?.data?.canCreate, canUpload: data?.data?.canUpload });
      setSectionPermissions(data?.data?.sections);
      serStatusGetPermission(true);
    },
    onError: () => {
      serStatusGetPermission(false);
      setActiveViewNotResult(true);
    },
  });

  useEffect(() => {
    refetch();
    setConfectionReset(true);
  }, []);

  const onCancelExitModalConfection = (): void => {
    setIsExitConfectionModalOpen(false);
    setTabTempIndex(0);
    setRedirectPath('');
  };

  const onConfirmExitConfection = (): void => {
    setIsExitConfectionModalOpen(false);
    setTransferTypeOptionSelected(initialState.transferTypeOptionSelected);
    clearConfection();
    if (redirectPath) {
      changeByURL();
    } else {
      changeTabConfirm();
    }
  };

  const changeTabConfirm = (): void => {
    setTabIndex(tabTempIndex);
    setTabTempIndex(0);
  };

  const changeByURL = () => {
    navigate(redirectPath);
    setRedirectPath('');
  };

  const handleTabsChange = (index: number): void => {
    if (!isManualTransferFormEmpty(manualTransferData) && index > 0) {
      setIsExitConfectionModalOpen(true);
      setTabTempIndex(index);
    } else {
      setTabIndex(index);
    }
  };

  const resetDraft = () => {
    setManualTransferData({ ...manualTransferData, hasDraft: null });
    setDraftModal(null);
  };

  const handleGoBack = (e?: Event, confirm = false) => {
    if (manualTransferData.hasDraft && !confirm) {
      setDraftModal(manualTransferData.hasDraft);
      setDraftModalAction(DraftModalAction.back);
      return;
    }
    draftModal && confirm && resetDraft();
    setStep(val => (val === 4 ? 2 : val - 1));
  };

  const handleFormSubmit = (e?: Event, confirm = false) => {
    if (manualTransferData.hasDraft && !confirm) {
      setDraftModal(manualTransferData.hasDraft);
      setDraftModalAction(DraftModalAction.next);
      return;
    }
    draftModal && confirm && resetDraft();
    setStep(val => (val === 4 ? 3 : val + 1));
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleDraftModalConfirm = (confirm: boolean) => {
    if (confirm) {
      if (draftModalAction === DraftModalAction.back) {
        handleGoBack(undefined, true);
      } else if (draftModalAction === DraftModalAction.next) {
        handleFormSubmit(undefined, true);
      }
    } else {
      setDraftModal(null);
    }
  };

  useEffect(() => {
    if (manualTransferData.step !== step) {
      setStep(manualTransferData.step);
    }
  }, [manualTransferData.step]);

  useEffect(() => {
    if (step !== manualTransferData.step) {
      setManualTransferData({ ...manualTransferData, step });
    }
  }, [step]);

  useEffect(() => {
    let indexSelectedByPermissions: number;

    switch (true) {
      case confectionPermits.canCreate:
        indexSelectedByPermissions = 0;
        break;
      case confectionPermits.canUpload:
        indexSelectedByPermissions = 1;
        break;
      default:
        indexSelectedByPermissions = 0;
        break;
    }
    setTabIndex(indexSelectedByPermissions);
  }, [confectionPermits]);

  useEffect(() => {
    let disabled = true;
    if (step === 1) {
      disabled = !manualTransferData.transferType || !manualTransferData.requestDate;
    } else if (step === 2) {
      disabled = !manualTransferData.transfers.length;
    } else if (step === 3) {
      disabled = false;
    } else if (step === 4) {
      disabled = false;
    }
    setStatusButtonDisabled(disabled);
  }, [step, manualTransferData.transferType, manualTransferData.requestDate, manualTransferData.transfers]);

  useEffect(() => {
    if (confectionReset) {
      clearConfection();
      setStep(1);
      setConfectionReset(false);
    }
  }, [confectionReset]);

  return {
    tabIndex,
    step,
    statusButtonDisabled,
    isDrawerOpen,
    transferType,
    confectionPermits,
    statusGetPermission,
    activeViewNotResult,
    draftModal,
    draftModalTxt,
    isExitConfectionModalOpen,
    onCancelExitModalConfection,
    onConfirmExitConfection,
    setIsExitConfectionModalOpen,
    handleTabsChange,
    setStatusButtonDisabled,
    handleGoBack,
    handleFormSubmit,
    handleOpenDrawer,
    setIsDrawerOpen,
    refetch,
    handleDraftModalConfirm,
  };
}
