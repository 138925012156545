import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Card as WowCard } from '@wow/card';

import { colors } from '../../styled/generalStyles';

const container = {
  borderRadius: '12px',
};

const containerBorder = {
  borderWidth: '3px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  padding: '2px',
};

const containerSelected = {
  borderColor: colors.Primary100,
};

const Card = styled(WowCard)`
  align-items: normal;
  &:hover {
    cursor: ${props => (props['aria-selected'] ? 'default' : 'pointer')};
    background-color: ${props => (props['aria-selected'] ? 'white' : colors.BlackAlpha100)};
  }
`;

const cardSection = {
  paddingBottom: '8px',
};

const CardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  flex: 1 0 0;
  margin-left: 1rem;
  min-height: 92px;
  width: 400px;
`;

const cardContentAutoWidth = {
  width: 'auto',
};

const cardIcon = {
  marginRight: 0,
};

const cardFooter = {
  display: 'flex',
  width: '100%',
  borderRadius: '0 0 12px 12px',
  flexDirection: 'row',
  backgroundColor: colors.Neutral40,
  height: '40px',
  alignItems: 'center',
  paddingLeft: '24px',
};

const balanceSup = {
  fontSize: '10px',
  marginLeft: '2px',
};

export {
  balanceSup,
  Card,
  CardContent,
  cardContentAutoWidth,
  cardFooter,
  cardIcon,
  cardSection,
  container,
  containerBorder,
  containerSelected,
};
