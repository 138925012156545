import { Box, Text } from '@chakra-ui/react';

import { ModalErrorsTransfersAlert } from '../../../../Interfaces/modals';
import { TransfersRejected } from '../../../../Interfaces/transfers';
import ResultIcon from '../../../Result/components/ResultIcon';
import { CustomBox } from '../../../Result/components/ResultListItem/index.styled';
import AlertErrorChildren from '../TextResultListItem';
import { CustomBoxContainer, CustomIconContainer } from './index.styled';

interface AlertErrorsModalChildrenProps {
  transfersRejected: TransfersRejected[];
  modalErrorsTransfersAlert: ModalErrorsTransfersAlert;
}

function ResultListItem({ transfersRejected, modalErrorsTransfersAlert }: AlertErrorsModalChildrenProps) {
  const totalTransferByMessageSett = [...new Set([...transfersRejected])];

  return (
    <Box>
      {totalTransferByMessageSett.map((alertError: TransfersRejected) => {
        const { description, icon } = modalErrorsTransfersAlert[alertError.message] ?? {};
        if (!description) {
          return (
            <CustomBox key={alertError.message} sx={CustomBoxContainer}>
              <CustomBox sx={CustomIconContainer}>
                <ResultIcon icon={icon} />
                <Box marginLeft='10px'>
                  <Text textStyle='h6'>
                    <AlertErrorChildren text={alertError.text ?? []} totalTransferByMessage={alertError.totalTransferByMessage} />
                  </Text>
                </Box>
              </CustomBox>
              <Text textStyle='bodyMd'>
                {alertError.totalTransferByMessage === 1
                  ? `${alertError.totalTransferByMessage} transferencia`
                  : `${alertError.totalTransferByMessage} transferencias`}
              </Text>
            </CustomBox>
          );
        }
        return (
          <CustomBox key={alertError.message}>
            <ResultIcon icon={icon} />
            <Box flex={1} marginLeft='10px'>
              <Text textStyle='h6'>{description}</Text>
            </Box>
            <Text textStyle='bodyMd'>
              {alertError.totalTransferByMessage === 1
                ? `${alertError.totalTransferByMessage} transferencia`
                : `${alertError.totalTransferByMessage} transferencias`}
            </Text>
          </CustomBox>
        );
      })}
    </Box>
  );
}

export default ResultListItem;
