import { Box, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AlertTriangle } from 'react-feather';

import AlertStyles from './styled';

function IconAlert() {
  return (
    <Box sx={AlertStyles}>
      <Tooltip
        hasArrow
        placement='top'
        label={
          <Box>
            <Text as='span' textStyle='bodySmSemi'>
              El importe no incluye el total <br /> de transferencias.
            </Text>
            <Text as='span'>
              En función de los permisos <br /> de tu perfil no podés consultar el monto total <br />a transferir.
            </Text>
          </Box>
        }
      >
        <AlertTriangle />
      </Tooltip>
    </Box>
  );
}

export default IconAlert;
