interface Props {
  onClick?: (event: unknown) => void;
}

function IconArrowUpDown({ onClick }: Props) {
  return (
    <svg onClick={onClick} width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2071 6.70711C19.8166 7.09763 19.1834 7.09763 18.7929 6.70711L17.5 5.41421L17.5 21C17.5 21.5523 17.0523 22 16.5 22C15.9477 22 15.5 21.5523 15.5 21L15.5 5.41421L14.2071 6.70711C13.8166 7.09763 13.1834 7.09763 12.7929 6.70711C12.4024 6.31658 12.4024 5.68342 12.7929 5.29289L15.7929 2.29289C16.1834 1.90237 16.8166 1.90237 17.2071 2.29289L20.2071 5.29289C20.5976 5.68342 20.5976 6.31658 20.2071 6.70711ZM10.7071 17.2929C11.0976 17.6834 11.0976 18.3166 10.7071 18.7071L7.70711 21.7071C7.31658 22.0976 6.68342 22.0976 6.29289 21.7071L3.29289 18.7071C2.90237 18.3166 2.90237 17.6834 3.29289 17.2929C3.68342 16.9024 4.31658 16.9024 4.70711 17.2929L6 18.5858L6 3C6 2.44772 6.44772 2 7 2C7.55229 2 8 2.44772 8 3L8 18.5858L9.29289 17.2929C9.68342 16.9024 10.3166 16.9024 10.7071 17.2929Z'
        fill='#565E71'
      />
    </svg>
  );
}

export default IconArrowUpDown;
