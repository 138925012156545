import { Box, Checkbox, Flex, FormControl, FormLabel, Input, InputGroup, Text } from '@chakra-ui/react';
import AutoNumericInput from '@components/AutoNumericInput';
import { useTransfersContext } from '@context/TransfersContext';
import { parse } from 'date-fns';
import { useEffect, useState } from 'react';

import { useAmountInput } from '../../hooks/useAmountInput';
import { addAnimationSkeletonfadeIn, withOutAnimationSkeletonfadeIn } from '../../styled/global.styled';
import CustomDatePicker from '../CustomDatePicker';
import CustomSelect from '../CustomSelect';
import {
  AMOUNT_LABEL,
  AMOUNT_TEST_ID,
  CONCEPT_LABEL,
  CONCEPT_TEST_ID,
  DISCLAIMER_CHECK_TEST_ID,
  DOLLAR_SYMBOL,
  MAX_LENGTH_OBSERVATION,
  MAX_LENGTH_VOUCHER,
  OBSERVATION_LABEL,
  OBSERVATION_TEST_ID,
  PESOS_SYMBOL,
  PLACEHOLDER_CONCEPT,
  PLACEHOLDER_INPUT,
  REASON_LABEL,
  REASON_TEST_ID,
  REQUEST_DATE_LABEL,
  TRANSFER_DATA_FORM_TEST_ID,
  VOUCHER_LABEL,
  VOUCHER_TEST_ID,
} from './constants';
import { label } from './index.styled';
import { TransferDataSectionProps } from './interfaces';

function TransferDataSection({
  amount,
  isLocalCurrency,
  selectedDate,
  maxDays,
  isLoadingComplete,
  holidays,
  observation,
  concept,
  reason,
  voucher,
  isFullForm,
  conceptOptions,
  disclaimerText,
  isDisclaimerChecked,
  onChangeVoucherInput,
  onChangeReasonInput,
  onChangeConcept,
  onChangeAmountInput,
  isDrawer,
  onChangeObservationInput,
  onChangeDate,
  onChangeDisclaimer,
}: TransferDataSectionProps) {
  const { workingDay } = useTransfersContext();

  const [animation, setAnimation] = useState({});
  const { inputValue } = useAmountInput(amount);

  const minDate =
    !workingDay?.isWorkingDay && workingDay?.nextWorkingDay ? parse(workingDay?.nextWorkingDay, 'yyyy-MM-dd', new Date()) : new Date();

  useEffect(() => {
    onChangeAmountInput(inputValue);
  }, [inputValue]);

  useEffect(() => {
    const styles = isLoadingComplete ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn;
    setAnimation(styles);
  }, [isLoadingComplete]);

  return (
    <Box data-testid={TRANSFER_DATA_FORM_TEST_ID} sx={animation}>
      <Flex gap='24px' marginBottom='24px'>
        <FormControl isRequired>
          <FormLabel htmlFor={AMOUNT_TEST_ID} sx={label}>
            {AMOUNT_LABEL}
          </FormLabel>
          <InputGroup>
            <AutoNumericInput
              value={amount}
              id={AMOUNT_TEST_ID}
              name={AMOUNT_TEST_ID}
              data-testid={AMOUNT_TEST_ID}
              placeholder={PLACEHOLDER_INPUT}
              size='md'
              currencySymbol={isLocalCurrency ? PESOS_SYMBOL : DOLLAR_SYMBOL}
              decimalPlaces={2}
              onValueChange={onChangeAmountInput}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel sx={label}>{REQUEST_DATE_LABEL}</FormLabel>
          <CustomDatePicker
            minDate={minDate}
            holidays={holidays}
            maxDays={maxDays}
            selectedDate={selectedDate}
            onChangeDate={onChangeDate}
            status={isLoadingComplete}
            {...(isDrawer && { isDrawer })}
          />
        </FormControl>
      </Flex>
      {isFullForm ? (
        <>
          <Flex gap='24px' marginBottom='24px'>
            <FormControl isRequired>
              <FormLabel htmlFor={CONCEPT_TEST_ID} sx={label}>
                {CONCEPT_LABEL}
              </FormLabel>
              <CustomSelect
                value={concept || undefined}
                id={CONCEPT_TEST_ID}
                name={CONCEPT_TEST_ID}
                options={conceptOptions}
                placeholder={PLACEHOLDER_CONCEPT}
                onChange={e => onChangeConcept(e)}
                status={isLoadingComplete}
                width='100%'
                isSearchable={false}
              />
            </FormControl>
          </Flex>
          <Flex gap='24px' marginBottom='24px'>
            <FormControl>
              <FormLabel htmlFor={REASON_LABEL} sx={label}>
                {REASON_LABEL}
              </FormLabel>
              <Input
                id={REASON_LABEL}
                name={REASON_LABEL}
                data-testid={REASON_TEST_ID}
                type='text'
                size='md'
                onChange={e => onChangeReasonInput(e?.target?.value)}
                maxLength={MAX_LENGTH_OBSERVATION}
                value={reason}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor={VOUCHER_TEST_ID} sx={label}>
                {VOUCHER_LABEL}
              </FormLabel>
              <Input
                id={VOUCHER_TEST_ID}
                name={VOUCHER_TEST_ID}
                data-testid={VOUCHER_TEST_ID}
                type='text'
                size='md'
                onChange={e => onChangeVoucherInput(e?.target?.value)}
                maxLength={MAX_LENGTH_VOUCHER}
                value={voucher}
              />
            </FormControl>
          </Flex>
          <Flex gap='24px'>
            <FormControl>
              <FormLabel htmlFor={OBSERVATION_TEST_ID} sx={label}>
                {OBSERVATION_LABEL}
              </FormLabel>
              <Input
                id={OBSERVATION_TEST_ID}
                name={OBSERVATION_TEST_ID}
                data-testid={OBSERVATION_TEST_ID}
                type='text'
                size='md'
                onChange={e => onChangeObservationInput(e?.target?.value)}
                maxLength={MAX_LENGTH_OBSERVATION}
                value={observation}
              />
            </FormControl>
          </Flex>
          {disclaimerText && (
            <Flex marginTop='24px' gap='8px'>
              <Box>
                <Checkbox
                  name={DISCLAIMER_CHECK_TEST_ID}
                  id={DISCLAIMER_CHECK_TEST_ID}
                  data-testid={DISCLAIMER_CHECK_TEST_ID}
                  isChecked={isDisclaimerChecked}
                  onChange={e => onChangeDisclaimer(e?.target?.checked)}
                />
              </Box>
              <Box>
                <Text textStyle='labelMd' margin={0}>
                  {disclaimerText}
                </Text>
              </Box>
            </Flex>
          )}
        </>
      ) : (
        <Flex>
          <FormControl>
            <FormLabel htmlFor={OBSERVATION_TEST_ID} sx={label}>
              {OBSERVATION_LABEL}
            </FormLabel>
            <Input
              id={OBSERVATION_TEST_ID}
              name={OBSERVATION_TEST_ID}
              data-testid={OBSERVATION_TEST_ID}
              type='text'
              size='md'
              onChange={e => onChangeObservationInput(e?.target?.value)}
              maxLength={MAX_LENGTH_OBSERVATION}
              value={observation}
            />
          </FormControl>
        </Flex>
      )}
    </Box>
  );
}

export default TransferDataSection;
