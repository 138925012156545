import { SystemStyleObject } from '@chakra-ui/react';

const CustomIconButton: SystemStyleObject = {
  position: 'fixed',
  top: '148px',
  right: '64px',
  zIndex: 100,
};

export { CustomIconButton };
