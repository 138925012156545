import { ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const CustomModalContent = styled(ModalContent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const CustomModalCloseButton = styled(ModalCloseButton)`
  background: ${colors.White};
  border: none;
  color: ${colors.Primary100};
  cursor: pointer;
  font-size: 14px;
  padding: 0px;
  top: 16px;
  right: 16px;
  margin-bottom: 8px;

  & > .css-1cl6z1j {
    position: initial;
  }

  &:active {
    background: none;
  }

  &:hover {
    background: none;
  }

  &:focus {
    background: none;
  }
`;

const CustomTextDescription = {
  textAlign: 'center',
  margin: '0px',
};

const CustomTextSecondDescription = {
  textAlign: 'center',
  margin: '16px 0px 0px 0px',
};

const CustomTextThirdDescription = {
  textAlign: 'center',
  marginTop: '16px',
  color: colors.Info110,
  letterSpacing: '0.25px',
  lineHeight: '20px',
};

const CustomBoldTextSecondDescription = {
  textAlign: 'center',
  margin: '4px 0px 0px 0px',
};

const CustomModalHeader = styled(ModalHeader)`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const CustomModalHeaderText = {
  margin: '0px',
  textAlign: 'center',
};

const CustomModalBody = styled(ModalBody)`
  width: 100%;
`;

const CustomModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  & > .third-level-card-wrapper {
    text-decoration: none;
    outline: none;
  }
`;

export {
  CustomBoldTextSecondDescription,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalContent,
  CustomModalFooter,
  CustomModalHeader,
  CustomModalHeaderText,
  CustomTextDescription,
  CustomTextSecondDescription,
  CustomTextThirdDescription,
};
