const card: React.CSSProperties = {
  height: '112px',
  border: '0',
};

const cardSection: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '14px 12px 0 12px',
};

export { card, cardSection };
