export const getLocales = () => [{ languageCode: 'es', countryCode: 'AR', languageTag: 'es-AR', isRTL: false }];

export const findBestLanguageTag = supportedLanguages => {
  const defaultLanguage = 'es-AR';
  const matchedLanguage = supportedLanguages.find(lang => lang === defaultLanguage);

  return {
    languageTag: matchedLanguage || supportedLanguages[0],
    isRTL: false,
  };
};
