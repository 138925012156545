export const CustomBoxContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
};

export const CustomIconContainer = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
};
