import { Box, Flex, Skeleton, Text, Tooltip } from '@chakra-ui/react';
import { Badge } from '@wow/badge';
import { useRef } from 'react';
import { Calendar, Layers, Package } from 'react-feather';

import BankLogo from '../../../components/BankLogo';
import { ARS_CURRENCY, CURRENCY_DOLAR, CURRENCY_PESOS } from '../../../constants-app';
import { useTransfersContext } from '../../../context/TransfersContext';
import { colors } from '../../../styled/generalStyles';
import { addAnimationSkeletonfadeIn, CustomMarginText, withOutAnimationSkeletonfadeIn } from '../../../styled/global.styled';
import { decimalFormat } from '../../../utils/FormatChange';
import { handleVariant } from '../../../utils/GetVariantBadge';
import { DENOMINATION_TOOLTIP_MAX_WIDTH, TRANSFER_TYPE, UNIFIED_SHIPPING_MARK } from '../constants';
import {
  CustomBox,
  CustomBoxLotDetail,
  CustomBoxSubtitle,
  CustomTextDenomination,
  CustomTextDetailsBank,
  CustomTextDetailsTransfer,
  CustomTextSeparator,
  CustomTextSeparatorSmall,
} from './index.styled';
import { Props } from './interfaces';

function GeneralLotInformation({ data, totalTransfer, requestDateStatus, activeBadge, firstTime }: Props) {
  const { lotNumber, transferType, debitAccount, requestDate, currency, unifiedShippingMark } = data[0] || [];
  const animation = firstTime ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn;

  const elemText = useRef<HTMLInputElement | null>(null);
  const debitAccountElemTextWidth = elemText?.current?.clientWidth || '';
  const hasMaxLength = Number(debitAccountElemTextWidth) >= DENOMINATION_TOOLTIP_MAX_WIDTH;
  const tooltipLabel = hasMaxLength ? (
    <Text as='span' textStyle='bodySm'>
      Razón social: <b>{debitAccount?.accountName}</b>
    </Text>
  ) : (
    ''
  );
  const { detailParams } = useTransfersContext();

  return (
    <Box>
      <CustomBox id='titleDetail'>
        {firstTime ? (
          <Flex sx={animation}>
            <Text sx={CustomMarginText} textStyle='h2Bold'>{`Lote ${lotNumber || ''}`}</Text>
            <Text sx={CustomTextSeparator} textStyle='h2Bold'>
              |
            </Text>
            <Text sx={CustomMarginText} textStyle='h2Bold'>
              {`${currency === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR} ${
                detailParams.amount === 0 ? ' ***' : decimalFormat(detailParams.amount)
              }`}
            </Text>
            <Text sx={CustomTextSeparator} textStyle='h2Bold'>
              |
            </Text>
            <Text sx={CustomMarginText} textStyle='h2Bold'>{`${totalTransfer || ''}  ${
              totalTransfer <= 1 ? 'transferencia' : 'transferencias'
            }`}</Text>
            {activeBadge && (
              <Tooltip
                label='Se incluyen solo las transferencias autorizadas. Si dentro del lote existe alguna transferencia anulada o no autorizada, podrás verla en el historial.'
                hasArrow
                placement='right'
              >
                <Flex>
                  <Badge marginLeft='10px' variant='info-light'>
                    Solo autorizadas
                  </Badge>
                </Flex>
              </Tooltip>
            )}
          </Flex>
        ) : (
          <Skeleton width='476px' height='40px' borderRadius='4px' />
        )}
      </CustomBox>
      <CustomBoxSubtitle>
        <Flex marginRight='5px'>
          {firstTime ? (
            <Calendar color={colors.Secondary100} size='20px' fontWeight='900' style={animation} />
          ) : (
            <Skeleton width='20px' height='20px' borderRadius='50%' margin='6px 0px' />
          )}
        </Flex>
        {firstTime ? (
          <Text marginRight='15px' sx={animation} textStyle='bodySmSemi' variant={handleVariant(requestDateStatus)}>
            {requestDate?.split('-').reverse().join('/') || ''}
          </Text>
        ) : (
          <Skeleton marginRight='30px' width='73px' height='16px' borderRadius='4px' />
        )}
        <Flex margin='0 13px 0 13px'>
          {firstTime ? (
            <Package color={colors.Secondary100} size='20px' fontWeight='900' style={animation} />
          ) : (
            <Skeleton width='18px' height='18px' borderRadius='50%' />
          )}
        </Flex>
        {firstTime ? (
          <Text sx={{ ...CustomTextDetailsTransfer, ...animation }} textStyle='bodySmSemi'>
            {TRANSFER_TYPE[transferType] || ''}
          </Text>
        ) : (
          <Skeleton width='54px' height='16px' borderRadius='4px' />
        )}
        {unifiedShippingMark === UNIFIED_SHIPPING_MARK && (
          <>
            <Flex marginRight='5px'>
              {firstTime ? (
                <Layers color={colors.Secondary100} size='20px' fontWeight='900' style={animation} />
              ) : (
                <Skeleton width='18px' height='18px' borderRadius='50%' />
              )}
            </Flex>
            {firstTime ? (
              <Text sx={{ ...CustomTextDetailsTransfer, ...animation }} textStyle='bodySmSemi'>
                Envío unificado
              </Text>
            ) : (
              <Skeleton width='95px' height='16px' borderRadius='4px' />
            )}
          </>
        )}
      </CustomBoxSubtitle>
      <Flex sx={CustomBoxLotDetail}>
        {firstTime ? (
          <Flex sx={animation}>
            <BankLogo src={debitAccount?.bankCodeBCRA} transferType='CUE' />
          </Flex>
        ) : (
          <Skeleton marginRight='6px' width='40px' height='40px' borderRadius='50%' />
        )}
        {firstTime ? (
          <Flex flexWrap='wrap' sx={animation}>
            <CustomBox>
              <Text as='span' textStyle='bodyMd'>
                Cuenta débito:
              </Text>
              <Text as='span' sx={CustomTextDetailsBank} textStyle='labelLgBold'>
                {debitAccount?.bankName}
              </Text>
            </CustomBox>
            {debitAccount?.accountName && (
              <CustomBox>
                <Text as='span' sx={CustomTextSeparatorSmall} textStyle='labelLgBold'>
                  |
                </Text>
                <Text as='span' textStyle='bodyMd'>
                  Razón social:
                </Text>
                <Tooltip label={tooltipLabel} hasArrow placement='top'>
                  <Text as='span' ref={elemText} sx={CustomTextDenomination} textStyle='labelLgBold'>
                    {debitAccount?.accountName || ''}
                  </Text>
                </Tooltip>
              </CustomBox>
            )}
            {debitAccount?.cuit && (
              <CustomBox>
                <Text as='span' sx={CustomTextSeparatorSmall} textStyle='labelLgBold'>
                  |
                </Text>
                <Text as='span' textStyle='bodyMd'>
                  CUIT:
                </Text>
                <Text as='span' sx={CustomTextDetailsBank} textStyle='labelLgBold'>
                  {debitAccount?.cuit || ''}
                </Text>
              </CustomBox>
            )}
            {(debitAccount?.cbu || debitAccount?.accountNumber) && (
              <CustomBox>
                <Text as='span' sx={CustomTextSeparatorSmall} textStyle='labelLgBold'>
                  |
                </Text>
                <Text as='span' textStyle='bodyMd'>
                  {debitAccount?.cbu ? 'CBU:' : 'Nro Cuenta:'}
                </Text>
                <Text as='span' sx={CustomTextDetailsBank} textStyle='labelLgBold'>
                  {debitAccount?.cbu || debitAccount?.accountNumber}
                </Text>
              </CustomBox>
            )}
            {debitAccount?.accountType && (
              <CustomBox>
                <Text as='span' sx={CustomTextSeparatorSmall} textStyle='labelLgBold'>
                  |
                </Text>
                <Text as='span' textStyle='bodyMd'>
                  Tipo de cuenta:
                </Text>
                <Text as='span' sx={CustomTextDetailsBank} textStyle='labelLgBold'>
                  {debitAccount?.accountType}
                </Text>
              </CustomBox>
            )}
          </Flex>
        ) : (
          <Skeleton width='1029px' height='20px' borderRadius='4px' />
        )}
      </Flex>
    </Box>
  );
}

export default GeneralLotInformation;
