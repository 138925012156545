const texts = {
  usageReference: 'Referencia de uso',
  usageReferencePlaceholder: 'Elegí la referencia de uso',
  bank: 'Banco',
  bankPlaceholder: 'Elegí el banco',
  currency: 'Moneda',
  currencyARS: 'ARS',
  currencyUSD: 'USD',
  filter: 'Elegí el dato que querés buscar',
  filterCbu: 'CBU',
  filterCuit: 'CUIT',
  filterDenomination: 'Razón social',
  filterCbuPlaceholder: 'Ingresá los 22 números',
  filterCuitPlaceholder: 'Ingresá los 11 números sin guiones',
  filterAccountNamePlaceholder: 'Ingresá al menos 3 caracteres',
  filterCbuSupporting: 'Ingresá los 22 números para buscar',
  filterCuitSupporting: 'Ingresá los 11 números sin guiones para buscar',
  filterAccountNameSupporting: 'Ingresá al menos 3 caracteres para buscar',
  search: 'Buscar',
  filterResultTitle: (value: number) => `Encontramos ${value} cuenta${value > 1 ? 's' : ''} para tu búsqueda`,
  filterResultDescription: 'Probá con datos más específicos para reducir las cuentas.',
  viewMore: 'Ver más resultados',
  tooltipCurrency: 'La moneda de las cuentas de débito y de crédito debe ser la misma.',
};

const formFields = {
  usageReference: 'usageReference',
  bank: 'bank',
  currency: 'currency',
  filterType: 'filterType',
  filterData: 'filterData',
};

const pageSize = 15;

const filterDataMaxLength = {
  cbu: 22,
  cuit: 11,
  denomination: 40,
};

const TestIds = {
  filterAccounts: 'filterAccounts',
  selectUsageReference: 'selectUsageReference',
  selectBank: 'selectBank',
  selectFilterType: 'selectFilterType',
  radioCurrencyARS: 'radioCurrencyARS',
  radioCurrencyUSD: 'radioCurrencyUSD',
  inputFilterData: 'inputFilterData',
  buttonSearch: 'buttonSearch',
  filterResult: 'filterResult',
  accountsList: 'accountsList',
  buttonViewMore: 'buttonViewMore',
  skeleton: 'skeleton',
};

export { filterDataMaxLength, formFields, pageSize, TestIds, texts };
