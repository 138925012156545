const getAccountName = (bankName: string | undefined, accountName: string | undefined) => {
  const accountNameMaxLength = 45;
  let name = bankName;
  if (accountName) {
    name = `${bankName} | ${accountName}`;
    name = `${name.substring(0, accountNameMaxLength)}${name.length > accountNameMaxLength ? '...' : ''}`;
  }
  return name;
};

const getAccountData = (cuit: string | undefined, cbu: string | undefined, accountType: string | undefined) => {
  const data = [];
  if (cuit) {
    data.push(cuit);
  }
  if (cbu) {
    data.push(cbu);
  }
  if (accountType) {
    data.push(accountType);
  }
  return data;
};

export { getAccountData, getAccountName };
