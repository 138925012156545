import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { Layers } from 'react-feather';

import { colors } from '../../../styled/generalStyles';
import { lotItem, textChild, textLot, unifiedShippingChildrenBox, unorderedListStyle } from './index.styled';

interface Props {
  lotNumbers: number[];
  unifiedTitleShippingText1: string;
  unifiedTitleShippingText2: string;
}

function UnifiedShippingChildren({ lotNumbers, unifiedTitleShippingText1, unifiedTitleShippingText2 }: Props) {
  return (
    <Box>
      <Text sx={textChild}>{unifiedTitleShippingText1}</Text>
      <Box sx={unifiedShippingChildrenBox}>
        <UnorderedList sx={unorderedListStyle}>
          {lotNumbers.map((lotNumber: number) => (
            <ListItem sx={lotItem} key={lotNumber}>
              <Flex marginRight='6px'>
                <Layers color={colors.Secondary500} size='20px' fontWeight='900' />
                <Text as='span' sx={textLot}>
                  Lote {lotNumber}
                </Text>
              </Flex>
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
      <Text sx={textChild}>{unifiedTitleShippingText2}</Text>
    </Box>
  );
}

export default UnifiedShippingChildren;
