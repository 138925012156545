import { Box, Text } from '@chakra-ui/react';
import { sanitize } from 'dompurify';

import { transformTextToBold } from '../../utils/TransformTextToBold';
import { InterfacesPropsBaseScreen } from './interfaces';
import { CustomText } from './styled';

function BaseScreen({ title, sizeTitle, children, id, customStyle, isActiveSubTitle, subTitle = '' }: InterfacesPropsBaseScreen) {
  return (
    <Box sx={customStyle}>
      <Text sx={CustomText} textStyle={sizeTitle} id={id}>
        {title}
      </Text>
      {isActiveSubTitle && (
        <Box>
          <Text
            as='span'
            textStyle='bodyMd'
            dangerouslySetInnerHTML={{
              __html: sanitize(transformTextToBold('clave única', 'Confeccionada.', subTitle)),
            }}
          />
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
}

export default BaseScreen;
