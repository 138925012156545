import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ACTION_TYPE, ACTIONS_TO_SEND, routesNames } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { PostDataTransfersAction } from '../Interfaces/api';
import { ResponseDataLotAndTransfers } from '../Interfaces/contextTypes';
import { Lot, Pagination as PaginationInterfaces, ServiceAction, TransfersFlow } from '../Interfaces/transfers';
import { getDetailsOfAllTransfers, getLots, postTransfersAction } from '../services/api';
import { getServiceParams } from '../utils/configTableFunctions';
import { formatSelectedTransfersTypesCodes } from '../utils/formatSelectedTransfersTypesCodesParam';
import { EXCEPTION_CODES, USER_WITH_OTP } from '../views/AuthorizationTransfers/constants';
import useAuthPartial from './useAuthPartial';

const useAuthorization = (setOtp: (arg: string) => void, lotNumber: number[], setLotNumber: (arg: number[]) => void, otp?: string) => {
  const navigate = useNavigate();
  const {
    pageContext,
    sizeContext,
    orderPropertyContext,
    sectionPermissions,
    setTransfersRejected,
    setTransfersAccepted,
    setServicesWorking,
    setDataDetailsTransfers,
    setDataTotalsTransfers,
    panelSelected,
    clearSelectedLots,
    setHasData,
    selectedTransfersTypesCodes,
    setViewPartialRejecteAuth,
    setLoadingPartialReject,
    setPageContext,
    setSizeContext,
    amountRanges,
    setIsLoadingLots,
  } = useTransfersContext();
  const { mutateAuthPartial } = useAuthPartial();

  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [secondTime, setSecondTime] = useState<boolean>(false);
  const [lots, setLots] = useState<Lot[]>([]);
  const [tableDataState, setTableDataState] = useState([]);
  const [postDataGetDetails, setPostDataGetDetails] = useState<ResponseDataLotAndTransfers[]>([]);
  const [isOpenModalResponseCero, setIsOpenModalResponseCero] = useState<boolean>(false);
  const [getDetailsTransfers, setGetDetailsTransfers] = useState<number>(0);
  const [statesUpdated, setStatesUpdated] = useState(false);
  const [isOpenModalGetTransferSelectByUser, setIsOpenModalGetTransferSelectByUser] = useState<boolean>(false);
  const [idProcess, setIdProcess] = useState<number | null>(null);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);
  const [timeOutModalOpen, setTimeOutModalOpen] = useState(false);
  const [pagination, setPagination] = useState<PaginationInterfaces>({
    totalElements: 0,
    totalPages: 0,
    totalElementsRequested: 0,
    pageNumber: 0,
  });
  const [isReload, setIsReload] = useState(false);

  const params = {
    size: String(sizeContext),
    page: String(pageContext),
    lotNumber: lotNumber.length > 0 ? lotNumber.join(';') : null,
    transferType: formatSelectedTransfersTypesCodes(selectedTransfersTypesCodes),
    ...getServiceParams(TransfersFlow.Authorize),
    orderProperty: orderPropertyContext,
    ...amountRanges,
    action: ACTION_TYPE.FIR,
  };

  const { refetch, status, isFetching } = useQuery(['get-lots-transfers', params], () => getLots(params).then(res => res.data), {
    retry: false,
    onSuccess: data => {
      if (EXCEPTION_CODES.satisfactoryProcess === data?.exception?.code) {
        setOtp(data.data.otpMark);
        setServicesWorking(true);
        setLots(data?.data?.lots);
        setPagination({ ...data?.paging, itemsByPage: data?.data?.totalPagesRequests });
        setTableDataState(data);
        setHasData(data?.data?.lots.length > 0);
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: data.data.otpMark === USER_WITH_OTP ? 'Transferencias - Autorizar' : 'Transferencias - Autorizar sin OTP',
        });
        if (data?.data?.lots.length < 1) {
          pushAnalyticsEvent({
            event: 'trackContentGroup',
            content_group: 'Transferencias - Autorizar - Sin TEFs pendientes',
          });
        }
      } else if (EXCEPTION_CODES.timeOut === data?.exception?.code) {
        setServicesWorking(false);
      }
      setFirstTime(true);
    },
    onError: () => {
      setServicesWorking(false);
    },
  });

  const { refetch: refetchGetDetailsOfAllTransfers, isFetching: isFetchingGetDetailsOfAllTransfers } = useQuery(
    ['get-details-all-transfers', postDataGetDetails],
    () => getDetailsOfAllTransfers(postDataGetDetails, ACTIONS_TO_SEND[panelSelected]).then(res => res.data),
    {
      retry: false,
      enabled: postDataGetDetails.length > 0,
      onSuccess: data => {
        if (EXCEPTION_CODES.satisfactoryProcess === data?.exception?.code) {
          setDataDetailsTransfers(data?.data?.tranfers);
          setIsOpenModalResponseCero(!data?.data?.tranfers.length);
          setGetDetailsTransfers(data?.data?.tranfers.length);
          setDataTotalsTransfers(data?.data?.totalAmount);
          if (!data?.data?.tranfers.length) {
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'No podemos autorizar las transferencias - No disponibles',
            });
          } else {
            setStatesUpdated(true);
          }
        } else if (EXCEPTION_CODES.timeOut === data?.exception?.code) {
          setIsOpenModalGetTransferSelectByUser(true);
        }
      },
      onError: () => {
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos autorizar las transferencias - Error servicio',
        });
        setIsOpenModalGetTransferSelectByUser(true);
      },
    },
  );

  const { mutate: mutationPostTransfersAction, isLoading: isFetchingAuthorize } = useMutation(
    (data: PostDataTransfersAction) => {
      return postTransfersAction(ServiceAction.AUT, data);
    },
    {
      onSuccess: response => {
        const exceptionCode: string = response?.data?.exception?.code;
        switch (exceptionCode) {
          case EXCEPTION_CODES.satisfactoryProcess:
            pushAnalyticsEvent({
              event: 'transferencia_autorizada',
              content_group: otp === USER_WITH_OTP ? 'Transferencias - Resumen de autorización' : 'Transferencias - Autorizar sin OTP',
              status: 'Transferencias autorizadas exitosamente',
            });
            setIsOpenModalSuccess(true);
            if (otp === USER_WITH_OTP) {
              handleResetView();
            }
            break;
          case EXCEPTION_CODES.parcialReject:
            pushAnalyticsEvent({
              event: 'transferencia_autorizada',
              content_group: otp === USER_WITH_OTP ? 'Transferencias - Resumen de autorización' : 'Transferencias - Autorizar sin OTP',
              status: 'Autorización parcial',
            });
            setTransfersRejected(response.data.data.transfersRejected);
            setTransfersAccepted(response.data.data.transfersAccepted);
            navigate(routesNames.ResultAuth);
            break;
          case EXCEPTION_CODES.parcialValidation:
            setTransfersRejected(response.data.data.transfersRejected);
            setViewPartialRejecteAuth(true);
            mutateAuthPartial({ transfer: response.data.data.transfersValidated[0].numberTransfer });
            setLoadingPartialReject(true);
            break;
          case EXCEPTION_CODES.parcialOperation:
            setTransfersRejected(response.data.data.transfersRejected);
            setTransfersAccepted(response.data.data.transfersAccepted);
            setViewPartialRejecteAuth(true);
            mutateAuthPartial({ transfer: response.data.data.transfersValidated[0].numberTransfer });
            setLoadingPartialReject(true);
            break;
          case EXCEPTION_CODES.totalReject:
            pushAnalyticsEvent({
              event: 'transferencia_autorizada',
              content_group: otp === USER_WITH_OTP ? 'Transferencias - Resumen de autorización' : 'Transferencias - Autorizar sin OTP',
              status: 'Rechazo total',
            });
            setTransfersRejected(response.data.data.transfersRejected);
            navigate(routesNames.ResultAuth);
            break;
          case EXCEPTION_CODES.asyncProcess:
            setIdProcess(response?.data?.data?.processId);
            break;
          case EXCEPTION_CODES.timeOut:
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'La autorización se está procesando',
            });
            setTimeOutModalOpen(true);
            break;
          default:
            setIsOpenModalGetTransferSelectByUser(true);
        }
      },
      onError: () => {
        setIsOpenModalGetTransferSelectByUser(true);
      },
    },
  );

  const handleResetView = () => {
    setPageContext(1);
    setLotNumber([]);
    setLots([]);
    setSizeContext(15);
    setIsOpenModalResponseCero(false);
    clearSelectedLots();
    setFirstTime(false);
    setSecondTime(false);
    setIsReload(true);
    refetch();
  };

  useEffect(() => {
    setSecondTime(!isFetching);
    setIsLoadingLots(!firstTime && !isReload ? isFetching : false);
  }, [firstTime, isFetching, isReload]);

  return {
    refetch,
    status,
    isFetching,
    lots,
    pagination,
    tableDataState,
    isFetchingGetDetailsOfAllTransfers,
    isOpenModalResponseCero,
    statesUpdated,
    isOpenModalGetTransferSelectByUser,
    postDataGetDetails,
    isFetchingAuthorize,
    idProcess,
    isOpenModalSuccess,
    timeOutModalOpen,
    getDetailsTransfers,
    firstTime: !!sectionPermissions.length && firstTime,
    secondTime,
    handleResetView,
    refetchGetDetailsOfAllTransfers,
    setPostDataGetDetails,
    setIsOpenModalGetTransferSelectByUser,
    setIsOpenModalResponseCero,
    setStatesUpdated,
    mutationPostTransfersAction,
    setIsOpenModalSuccess,
    setTimeOutModalOpen,
    setLots,
  };
};

export default useAuthorization;
