function IconBrokenLink() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={76} height={76} fill='none'>
      <path fill='#EBF0FF' d='m23.452 14.668 5.74-1.436 1.7 10.204-2.444 1.222-4.996-9.99Z' />
      <path fill='#2172DC' d='m28.384 14.466 1.402 8.406-.89.444-3.99-7.982 3.478-.868ZM30 12l-8 2 6 12 4-2-2-12Z' />
      <path fill='#EBF0FF' d='m13.232 29.192 1.436-5.74 9.99 4.996-1.222 2.444-10.204-1.7Z' />
      <path fill='#2172DC' d='m15.336 24.904 7.982 3.99-.444.89-8.406-1.402.868-3.478ZM14 22l-2 8 12 2 2-4-12-6Z' />
      <path
        fill='#D6E2FF'
        d='M15.11 75c-3.786 0-7.332-1.462-9.99-4.12-5.508-5.508-5.508-14.472 0-19.98l9.782-9.78c2.656-2.658 6.204-4.12 9.99-4.12 2.042 0 4.066.45 5.928 1.31l-4.768 4.766A8.208 8.208 0 0 0 24.95 43c-2.184 0-4.226.838-5.748 2.362l-9.78 9.78a8.066 8.066 0 0 0-2.364 5.748c0 2.182.84 4.222 2.364 5.748C10.946 68.162 12.986 69 15.17 69c2.182 0 4.222-.838 5.746-2.362l9.782-9.78c1.524-1.522 2.362-3.564 2.362-5.748 0-.416-.038-.844-.116-1.298l4.708-4.71c2.52 5.356 1.458 11.77-2.772 16l-9.782 9.78C22.444 73.538 18.896 75 15.11 75Z'
      />
      <path
        fill='#2172DC'
        d='M24.89 38c1.41 0 2.81.23 4.146.676l-3.354 3.354c-.244-.02-.49-.03-.732-.03-2.45 0-4.742.942-6.454 2.656l-9.78 9.78C7.002 56.148 6.06 58.44 6.06 60.89s.942 4.742 2.656 6.454C10.426 69.056 12.72 70 15.17 70s4.742-.942 6.454-2.656l9.78-9.78c1.712-1.712 2.656-4.004 2.656-6.454 0-.31-.018-.622-.056-.946l3.29-3.29c1.596 4.672.46 9.938-3.12 13.518l-9.78 9.78C21.926 72.64 18.628 74 15.11 74c-3.518 0-6.816-1.36-9.284-3.826C3.36 67.706 2 64.408 2 60.89c0-3.518 1.36-6.816 3.828-9.284l9.78-9.78C18.074 39.36 21.372 38 24.89 38Zm0-2c-3.878 0-7.756 1.47-10.698 4.412l-9.78 9.78c-5.884 5.884-5.884 15.512 0 21.394C7.354 74.53 11.232 76 15.11 76s7.756-1.47 10.698-4.412l9.78-9.78c4.96-4.96 5.722-12.576 2.316-18.372l-6.046 6.046c.122.528.202 1.07.202 1.628 0 1.916-.734 3.706-2.07 5.04l-9.78 9.78C18.876 67.266 17.084 68 15.17 68c-1.916 0-3.706-.734-5.04-2.07a7.073 7.073 0 0 1-2.07-5.04c0-1.916.734-3.706 2.07-5.04l9.78-9.78c1.334-1.336 3.124-2.07 5.04-2.07.492 0 .976.052 1.446.146l6.094-6.094A15.166 15.166 0 0 0 24.89 36Z'
      />
      <path
        fill='#AAC7FF'
        d='M51.11 38.808c-2.024 0-4.032-.442-5.876-1.286l4.796-4.796c.386.056.764.084 1.14.084 2.182 0 4.222-.838 5.746-2.362l9.716-9.584A8.073 8.073 0 0 0 69 15.11c0-2.184-.84-4.226-2.364-5.748C65.116 7.838 63.074 7 60.89 7c-2.184 0-4.226.838-5.748 2.362l-9.716 9.584a8.072 8.072 0 0 0-2.368 5.752c0 .404.036.822.11 1.262l-4.796 4.796c-2.55-5.366-1.5-11.802 2.748-16.05l9.716-9.582C53.496 2.462 57.044.998 60.83.998c3.786 0 7.334 1.462 9.99 4.12 2.658 2.656 4.122 6.204 4.122 9.99 0 3.786-1.464 7.334-4.122 9.99l-9.716 9.582c-2.66 2.664-6.21 4.128-9.994 4.128Z'
      />
      <path
        fill='#2172DC'
        d='M60.83 2c3.518 0 6.816 1.36 9.284 3.826 2.468 2.468 3.826 5.764 3.826 9.282s-1.36 6.816-3.818 9.274l-9.73 9.598c-2.468 2.468-5.764 3.826-9.284 3.826-1.39 0-2.772-.224-4.09-.658l3.376-3.376c.26.022.518.034.774.034 2.45 0 4.742-.942 6.446-2.646l9.73-9.598C69.056 19.852 70 17.56 70 15.11s-.942-4.742-2.656-6.454C65.632 6.944 63.34 6 60.89 6s-4.742.942-6.444 2.646l-9.73 9.598c-1.712 1.712-2.656 4.004-2.656 6.454 0 .298.016.598.05.906l-3.384 3.384c-1.626-4.686-.498-9.974 3.092-13.564l9.73-9.598C54.016 3.36 57.312 2 60.83 2Zm0-2c-3.878 0-7.756 1.47-10.698 4.412L40.412 14c-4.974 4.974-5.726 12.616-2.29 18.418l6.128-6.128a7.272 7.272 0 0 1-.192-1.594c0-1.916.734-3.706 2.07-5.04l9.72-9.588A7.08 7.08 0 0 1 60.89 8c1.916 0 3.706.734 5.04 2.07 1.336 1.334 2.07 3.124 2.07 5.04s-.734 3.706-2.07 5.04l-9.72 9.588a7.073 7.073 0 0 1-5.04 2.07c-.504 0-.998-.058-1.48-.158l-6.132 6.132a15.168 15.168 0 0 0 7.552 2.024c3.878 0 7.756-1.47 10.698-4.412l9.72-9.588c5.884-5.884 5.884-15.512 0-21.394C68.586 1.47 64.708 0 60.83 0Z'
      />
      <path fill='#EBF0FF' d='m51.312 47.524 1.224-2.446 10.202 1.702-1.436 5.738-9.99-4.994Z' />
      <path fill='#2172DC' d='m53.098 46.186 8.406 1.402-.87 3.48-7.982-3.99.446-.892ZM51.97 43.97l-2 4 12 6 2-8-12-2Z' />
      <path fill='#EBF0FF' d='m45.078 52.536 2.446-1.224 4.996 9.99-5.74 1.436-1.702-10.202Z' />
      <path fill='#2172DC' d='m47.076 52.654 3.99 7.982-3.48.87-1.402-8.406.892-.446Zm.894-2.684-4 2 2 12 8-2-6-12Z' />
    </svg>
  );
}
export default IconBrokenLink;
