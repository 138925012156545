/* eslint-disable no-nested-ternary */
import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import IconBeware from '@components/Icons/icon-beware';
import IconDisconnected from '@components/Icons/icon-disconnected';
import Loader from '@components/Loader';
import CustomModal from '@components/Modal';
import ViewNoResult from '@components/ViewNoResult';
import useUpdateTransfer from '@hooks/useUpdateTransfer';
import { DataTransferModify } from '@interfaces/hooks/IUseModifyTransferDrawer';
import { DataMessageErrorRequest, LOADING_SCREEN_TEXT } from '@src/constants-app';
import { INITIAL_STATE_TRANSFER_MODIFY } from '@views/Modify/constants';
import { useState } from 'react';

import BottomNav from '../../../../components/BottomNav';
import { texts } from '../../../TransferSetup/Steps/Step2/constants';
import ModifyDrawerBody from './Body';
import { MODIFY_DRAWER_TEXTS } from './constants';
import TransferHeaderDetails from './Header';
import { createDataModify } from './helper';
import { Props } from './interfaces';

function ModifyDrawer({ isOpen, onCancel, transferNumber, lotNumber, transferType, codeTransferType, transferCurrency, refetch }: Props) {
  const [statusButtonConfirm, setStatusButtonConfirm] = useState<boolean>(true);
  const [statusButtonDiscardChange, setStatusButtonDiscardChange] = useState<boolean>(true);
  const [discardChange, setDiscardChange] = useState<boolean>(false);
  const [transferData, setTransferData] = useState<DataTransferModify>(INITIAL_STATE_TRANSFER_MODIFY);
  const { idTransferType } = transferData;
  const { mutateModifyTransfer, isLoading, errorUpdate } = useUpdateTransfer({ idTransferType, onCancel, refetch });

  const updateTransfer = () => {
    createDataModify(transferData, mutateModifyTransfer);
  };

  const handleToggleDrawerRetry = () => {
    createDataModify(transferData, mutateModifyTransfer);
  };

  const [debitCurrencyChanged, setDebitCurrencyChanged] = useState<string | null>(null);
  const [handleChangeDebitCurrency, setHandleChangeDebitCurrency] = useState<((confirm: boolean) => void) | null>(null);

  const handleCloseDrawer = () => {
    onCancel(false);
  };

  const handleDiscard = () => {
    setDiscardChange(true);
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={() => onCancel(false)} size='lg' trapFocus={false}>
      <DrawerOverlay />
      <DrawerContent>
        <TransferHeaderDetails transferNumber={transferNumber} TempAccount={transferType} LotNumberTemp={lotNumber} />
        {isLoading ? (
          <Loader title={LOADING_SCREEN_TEXT.title} description={LOADING_SCREEN_TEXT.description} />
        ) : errorUpdate ? (
          <ViewNoResult
            title={DataMessageErrorRequest.title}
            description={DataMessageErrorRequest.description4}
            linkName={DataMessageErrorRequest.linkName}
            Icon={IconDisconnected}
            handleToggleClick={handleToggleDrawerRetry}
            retryOtherLink
            iconDisconnectDrawer
          />
        ) : (
          <>
            <ModifyDrawerBody
              transferNumber={transferNumber}
              transferType={transferType}
              codeTransferType={codeTransferType}
              transferCurrency={transferCurrency}
              setStatusButtonConfirm={setStatusButtonConfirm}
              setTransferData={setTransferData}
              setDebitCurrencyChanged={setDebitCurrencyChanged}
              setHandleChangeDebitCurrency={setHandleChangeDebitCurrency}
              discardChange={discardChange}
              setDiscardChange={setDiscardChange}
              setStatusButtonDiscardChange={setStatusButtonDiscardChange}
            />
            <BottomNav
              drawerNav
              textButtonPrimaryText={MODIFY_DRAWER_TEXTS.Confirm}
              clickButtonPrimaryText={updateTransfer}
              statusButtonPrimaryText={statusButtonConfirm}
              buttonPrimaryOutLineDisabled={false}
              textButtonPrimaryOutLineText={MODIFY_DRAWER_TEXTS.Cancel}
              handleFourthButton={handleCloseDrawer}
              activeIconButtonPrimaryText={false}
              activeIconTextButtonPrimaryOutLineText={false}
              size='lg'
              textButtonprimaryLink={MODIFY_DRAWER_TEXTS.buttonDiscard}
              buttontextButtonprimaryLinkDisabled={statusButtonDiscardChange}
              handleButtonprimaryLink={handleDiscard}
            />
          </>
        )}
      </DrawerContent>
      <CustomModal
        isOpen={!!debitCurrencyChanged}
        Icon={IconBeware}
        onCancel={() => handleChangeDebitCurrency && handleChangeDebitCurrency(false)}
        firstDescription={texts.modalCurrencyChangeDescription}
        title={texts.modalCurrencyChangeTitle}
        enableFirstButton
        firstTextButton={texts.btnCancel}
        enableSecondButton
        secondTextButton={texts.modalCurrencyBtnConfirm}
        actionButton2={() => handleChangeDebitCurrency && handleChangeDebitCurrency(true)}
        alternativeButtonStyling
        size='xl'
        newStyle
      />
    </Drawer>
  );
}

export default ModifyDrawer;
