/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';

interface CountdownResult {
  time: number;
  start: () => void;
}

const useCountdown = (): CountdownResult => {
  const [time, setTime] = useState(10);
  let intervalId: NodeJS.Timeout | null = null;

  const start = () => {
    if (!intervalId) {
      setTime(10);
      intervalId = setInterval(() => {
        setTime(prevTime => {
          const nextTime = prevTime - 1;
          if (nextTime === 0) {
            clearInterval(intervalId!);
            intervalId = null;
          }
          return nextTime >= 0 ? nextTime : 0;
        });
      }, 1000);
    }
  };

  return { time, start };
};

export default useCountdown;
