/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-constant-condition */

import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Text } from '@chakra-ui/react';

import mockFileEntries from '../../../../../../../test/__mocks__/Drawer/mockFileList';
import CustomTable from '../../../../../../components/CustomTable';
import IconDisconnected from '../../../../../../components/Icons/icon-disconnected';
import Loader from '../../../../../../components/Loader';
import ViewNoResult from '../../../../../../components/ViewNoResult';
import { DataMessageErrorRequest, LOADING_SCREEN_TEXT } from '../../../../../../constants-app';
import { useDrawerState } from '../../../../../../hooks/useDrawerErrorFile';
import { File, TableDataStateTypeDrawerError } from '../../../../../../Interfaces/transfers';
import { CustomMarginText } from '../../../../../../styled/global.styled';
import { CustomTh } from '../../../../../LotDetail/index.styled';
import { COLUMNS } from './configTable';
import { HISTORICAL_ERROR_DRAWER_TEXTS } from './constants';
import { Props } from './interfaces';
import { CloseButton, CustomTd, drawerHeaderStyles, headerTextStyle } from './styled';

function DrawerHistoricalErrorList({ isOpen, onCancel, handleToggleDrawerRetry = () => '' }: Props) {
  const { files, tableDataState, handleRemoveFile } = useDrawerState({ initialFiles: mockFileEntries, onCancel });

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={handleRemoveFile} size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader sx={drawerHeaderStyles}>
          <Flex justifyContent='flex-end'>
            <DrawerCloseButton sx={CloseButton} />
          </Flex>
          <Text textStyle='h4Bold' sx={CustomMarginText}>
            {HISTORICAL_ERROR_DRAWER_TEXTS.title}
          </Text>
          <Text textStyle='bodySm' sx={headerTextStyle}>
            {HISTORICAL_ERROR_DRAWER_TEXTS.subtitle}
          </Text>
        </DrawerHeader>
        <DrawerBody>
          {false ? (
            <ViewNoResult
              title={DataMessageErrorRequest.title}
              description={DataMessageErrorRequest.description}
              linkName={DataMessageErrorRequest.linkName}
              Icon={IconDisconnected}
              handleToggleClick={handleToggleDrawerRetry}
              retryOtherLink
              iconDisconnectDrawer
            />
          ) : true ? (
            <CustomTable
              CustomTd={CustomTd}
              CustomTh={CustomTh}
              data={files as File[]}
              columns={COLUMNS as []}
              tableDataState={tableDataState as TableDataStateTypeDrawerError}
              size={15}
              selectedTable={HISTORICAL_ERROR_DRAWER_TEXTS.tableName}
              id='table'
              isFetching={false}
              hoverEffectOn
            />
          ) : (
            <Loader title={LOADING_SCREEN_TEXT.title} description={LOADING_SCREEN_TEXT.description} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerHistoricalErrorList;
