import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Badge, Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { ChevronDown, ChevronUp } from 'react-feather';

import { useTransfersContext } from '../../../../context/TransfersContext';
import { TransfersAcceptedPartialRejectAuth } from '../../../../Interfaces/transfers';
import ResultListItemAcepted from '../ResultListItemAcepted';
import styles from './index.styled';

interface Props {
  transfers: TransfersAcceptedPartialRejectAuth[] | [];
  handleAddMoreItems: () => void;
  totalTransfers: number;
  transfersAcceptedTotal: number;
}

function AccordionTransfersAcepted({ transfers, handleAddMoreItems, totalTransfers, transfersAcceptedTotal }: Props) {
  const { transferTypeAuthReadyToSend } = useTransfersContext();

  return (
    <Accordion
      allowToggle
      defaultIndex={1}
      sx={{
        ...styles.accordion,
        '> div:last-of-type>*': {
          borderRadius: '0',
          marginTop: transfersAcceptedTotal > 0 ? '0px' : '10px',
        },
      }}
    >
      <AccordionItem sx={styles.accordionItem}>
        {({ isExpanded }) => (
          <>
            <AccordionButton sx={styles.accordionButton}>
              <Flex sx={styles.accordionButtonMain}>
                <Flex sx={styles.badge}>
                  <Badge variant='warning-light'>Por autorizar</Badge>
                </Flex>
                <Flex sx={styles.accordionButtonTexts}>
                  <Text textStyle='h5Bold'>{`${totalTransfers} ${
                    totalTransfers > 1 ? 'transferencias' : 'transferencia'
                  } por autorizar`}</Text>
                  <Text
                    textStyle='labelMd'
                    dangerouslySetInnerHTML={{
                      __html: sanitize(`Revisá el listado y ${totalTransfers > 1 ? 'autorizalas.' : 'autorizala.'}`),
                    }}
                  />
                </Flex>
              </Flex>
              <Icon as={isExpanded ? ChevronUp : ChevronDown} sx={styles.chevron} />
            </AccordionButton>
            <AccordionPanel sx={styles.accordionPanel}>
              <ResultListItemAcepted items={transfers} />
              {transfers.length >= 15 && (
                <Box sx={styles.boxbutton}>
                  <Button
                    loadingText='Button'
                    rightIcon={<ChevronDown />}
                    size='lg'
                    spinnerPlacement='start'
                    variant='primary-text'
                    onClick={handleAddMoreItems}
                    isDisabled={transferTypeAuthReadyToSend.length === transfers.length}
                  >
                    Ver más
                  </Button>
                </Box>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

export default AccordionTransfersAcepted;
