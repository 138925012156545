import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const titleSkeleton = {
  width: '109px',
  borderRadius: '4px',
  marginBottom: '24px',
};

const CustomBox = styled(Box)`
  display: flex;
  margin-bottom: 100px;
  width: 100%;
`;

export { CustomBox };
