const TITLE_HEADER = 'Buscá por tipo de transferencia';
const TEXT_FILTER_TAG = 'Tipo de transferencia';
const TEXT_PLACEHOLDER = 'Buscar';
const EMPTY_MESSAGE = 'No hay resultados';
const MENU_TEST_ID_TRANFER_TYPE = 'tag-filter-tranfer-types';
const SEARCH_INPUT_TRANSFER_TEST_ID = 'searchTransferType';
const APPLY_FILTER_TYPE_BUTTON_TEST_ID = 'btn-filter-by-type-aplicar';

export {
  APPLY_FILTER_TYPE_BUTTON_TEST_ID,
  EMPTY_MESSAGE,
  MENU_TEST_ID_TRANFER_TYPE,
  SEARCH_INPUT_TRANSFER_TEST_ID,
  TEXT_FILTER_TAG,
  TEXT_PLACEHOLDER,
  TITLE_HEADER,
};
