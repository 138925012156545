import { TransferOperationDetails } from '../../../Interfaces/transfers';

export const getBankCodes = (dataDetailsTransfers: TransferOperationDetails[]) => {
  const uniqueBankCodes = dataDetailsTransfers
    .filter(
      (transferItem: TransferOperationDetails, index: number, arr: TransferOperationDetails[]) =>
        arr.findIndex((item: TransferOperationDetails) => item.bankCode === transferItem.bankCode) === index,
    )
    .map((transferItem: TransferOperationDetails) => transferItem.bankCode);

  return uniqueBankCodes;
};

export default getBankCodes;
