/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */

import { Box, Button, Skeleton, Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect } from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';

import { useTransfersContext } from '../../context/TransfersContext';
import useTableLogic from '../../hooks/useTableLogic';
import { colors } from '../../styled/generalStyles';
import {
  ANNUL_TRANSFERS,
  AUTH_TRANSFERS,
  HISTORICAL_ERROR_DRAWER_LIST,
  LOT_DETAIL,
  MODIFY_TRANSFERS,
  PENDING_TRANSFERS,
  REVOKE_TRANSFERS,
  UNAUTHORIZE_TRANSFERS,
} from '../../utils/configTableFunctions';
import { isAnyFilterActive } from '../../utils/isAnyFilterActive';
import IconArrowUpDown from '../Icons/icon-arrow-up-down';
import IconBinoculars from '../Icons/icon-binoculars';
import ViewNoResult from '../ViewNoResult';
import { DataMessageLotEmpty, DataMessageModifyEmpty, DataMessageTefEmpty } from '../ViewNoResult/ViewNoResultText';
import { InterfacesPropsCustomTable } from './interfaces';
import { ActionTef, BoxTable, CustomBox, HoverStyle, SelectedStyle, ThCustom, TheadCustom } from './styled';

function CustomTable({
  data,
  columns,
  size,
  CustomTd,
  CustomTh,
  showButton,
  handleClickButtom,
  onFilterLot,
  selectedTable,
  pendingTableId,
  totalTransfer,
  totalAmount,
  handleToggleDrawer,
  handleToggleDrawerWithDetails,
  tableDataState,
  text,
  id,
  isFetching,
  hoverEffectOn,
  firstStatusSkeleton = true,
  secondStatusSkeleton = true,
}: InterfacesPropsCustomTable) {
  const { filtered, fileSelected } = useTransfersContext();

  const { disableButton, table } = useTableLogic({
    selectedTable,
    pendingTableId,
    totalTransfer,
    totalAmount,
    size,
    data,
    columns,
    handleToggleDrawer,
    handleToggleDrawerWithDetails,
  });

  let emptyDescription = DataMessageTefEmpty.description;
  let skeletonArray = Array(15).fill(0);

  switch (selectedTable) {
    case PENDING_TRANSFERS:
      emptyDescription = DataMessageTefEmpty.description2;
      skeletonArray = Array(6).fill(0);
      break;
    case UNAUTHORIZE_TRANSFERS:
      emptyDescription = DataMessageTefEmpty.description3;
      skeletonArray = Array(6).fill(0);
      break;
    case ANNUL_TRANSFERS:
      emptyDescription = DataMessageTefEmpty.description4;
      skeletonArray = Array(6).fill(0);
      break;
    case REVOKE_TRANSFERS:
      emptyDescription = DataMessageTefEmpty.description6;
      skeletonArray = Array(6).fill(0);
      break;
    case HISTORICAL_ERROR_DRAWER_LIST:
      emptyDescription = DataMessageTefEmpty.description5;
      break;
    case MODIFY_TRANSFERS:
      emptyDescription = DataMessageModifyEmpty;
      skeletonArray = Array(6).fill(0);
      break;
    case AUTH_TRANSFERS:
      emptyDescription = DataMessageTefEmpty.description;
      skeletonArray = Array(6).fill(0);
      break;
    default:
      break;
  }

  useEffect(() => {
    if (data?.length <= 0 && tableDataState?.data && isAnyFilterActive(filtered)) {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Transferencias - Busqueda sin resultados',
      });
    }
  }, [filtered]);

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'CTAs Nada por aqui',
      item_id: 'Ver lotes pendientes',
    });
  };

  const shouldShowSortingIcons = selectedTable === HISTORICAL_ERROR_DRAWER_LIST && data.length === 0;

  return (
    <>
      {showButton ? (
        firstStatusSkeleton ? (
          <Box sx={ActionTef} id='actionTef'>
            <Button variant='primary' size='md' isDisabled={disableButton} onClick={handleClickButtom}>
              {text}
            </Button>
          </Box>
        ) : (
          <Skeleton width='100%' height='40px' borderRadius='4px' marginBottom='14px' data-testid='skeleton-component' />
        )
      ) : null}
      <Box>
        <Box sx={BoxTable} id={id}>
          <Table>
            <Thead sx={firstStatusSkeleton ? TheadCustom : undefined}>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {firstStatusSkeleton ? (
                    headerGroup.headers.map(header => {
                      return (
                        <CustomTh key={header.id} sx={ThCustom}>
                          {header.isPlaceholder ? null : (
                            <Box display='flex' gap='4px'>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {!shouldShowSortingIcons && header?.column?.getCanSort() && data?.length > 0 && (
                                <Box sx={CustomBox}>
                                  {{
                                    asc: (
                                      <ArrowUp onClick={() => header?.column?.toggleSorting(true)} size={18} color={colors.Secondary100} />
                                    ),
                                    desc: (
                                      <ArrowDown
                                        onClick={() => header?.column?.toggleSorting(false)}
                                        size={18}
                                        color={colors.Secondary100}
                                      />
                                    ),
                                    false: <IconArrowUpDown onClick={header?.column?.getToggleSortingHandler()} />,
                                  }[header?.column?.getIsSorted() as string] ?? null}
                                </Box>
                              )}
                            </Box>
                          )}
                        </CustomTh>
                      );
                    })
                  ) : (
                    <Skeleton width='100%' height='40px' borderRadius='4px' data-testid='skeleton-component' />
                  )}
                </Tr>
              ))}
            </Thead>
            {secondStatusSkeleton && (
              <Tbody>
                {data?.length > 0 &&
                  tableDataState?.data &&
                  table.getRowModel().rows.map(row => (
                    <Tr
                      key={row.id}
                      sx={{
                        ...(hoverEffectOn && HoverStyle),
                        ...(fileSelected && fileSelected.rowId === row.id ? SelectedStyle : {}),
                      }}
                    >
                      {firstStatusSkeleton ? (
                        row.getVisibleCells().map(cell => {
                          return (
                            <CustomTd
                              key={cell.id}
                              borderBottom='none'
                              sx={{
                                ...ThCustom,
                                whiteSpace: selectedTable === HISTORICAL_ERROR_DRAWER_LIST ? 'normal' : 'nowrap',
                              }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </CustomTd>
                          );
                        })
                      ) : (
                        <Skeleton
                          width='100%'
                          height={selectedTable === LOT_DETAIL ? '36px' : '48px'}
                          borderRadius='4px'
                          marginTop='12px'
                          data-testid='skeleton-component'
                        />
                      )}
                    </Tr>
                  ))}
              </Tbody>
            )}
          </Table>
          {!secondStatusSkeleton && (
            <Box>
              {skeletonArray.map((_, i) => (
                <Skeleton
                  key={i}
                  width='100%'
                  height={selectedTable === LOT_DETAIL ? '36px' : '48px'}
                  borderRadius='4px'
                  marginTop='12px'
                  data-testid='skeleton-component'
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      {!isFetching &&
        tableDataState?.data &&
        data?.length <= 0 &&
        (isAnyFilterActive(filtered) ? (
          <ViewNoResult
            title={DataMessageLotEmpty.title}
            description={DataMessageLotEmpty.description}
            Icon={IconBinoculars}
            onFilterLot={onFilterLot}
            handleToggleClick={handleToggleAnalytics}
          />
        ) : (
          <ViewNoResult
            title={DataMessageTefEmpty.title}
            description={emptyDescription}
            Icon={IconBinoculars}
            nothingHere
            {...(selectedTable === HISTORICAL_ERROR_DRAWER_LIST ? { drawerIcon: true } : {})}
          />
        ))}
    </>
  );
}

export default CustomTable;
