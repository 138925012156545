const textAdenddaStyles = (isSuppliersProcess: boolean) => ({
  textStyle: 'bodyMdSemi',
  marginBottom: isSuppliersProcess ? '48px' : '148px',
});

const alertComponentStyles = {
  width: '100%',
  minWidth: '0',
  marginBottom: '24px',
};

const creditInfoTextStyles = {
  marginTop: '6px',
};
export { alertComponentStyles, creditInfoTextStyles, textAdenddaStyles };
