const Circle1 = {
  width: '18px',
  height: '18px',
  borderRadius: '50%',
};

const Circle2 = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  marginRight: '4px',
};

const BoxAccount = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px',
};

const Divider = {
  height: '1px',
};

const Account = {
  height: '16px',
  borderRadius: '4px',
  margin: '2px 0',
};

const Account1 = {
  ...Account,
  width: '118px',
  height: '26px',
};

const Account2 = {
  ...Account,
  width: '350px',
};

const Account3 = {
  ...Account,
  width: '224px',
};

const Account4 = {
  ...Account,
  width: '84px',
};

const Account5 = {
  ...Account,
  width: '125px',
};

const Account6 = {
  ...Account,
  width: '118px',
  height: '20px',
};

const Account7 = {
  ...Account,
  width: '118px',
};

const Account8 = {
  ...Account,
  width: '184px',
};

const Info = {
  height: '16px',
  borderRadius: '4px',
  marginLeft: '4px',
  marginRight: '16px',
};

const Info1 = {
  ...Info,
  width: '264px',
};

const Info2 = {
  ...Info,
  width: '184px',
  marginRight: '40px',
};

const Info3 = {
  ...Info,
  width: '192px',
};

const Info4 = {
  ...Info,
  width: '129px',
};

const Title = {
  width: '58%',
  height: '32px',
  borderRadius: '4px',
  marginBottom: '16px',
};

export {
  Account1,
  Account2,
  Account3,
  Account4,
  Account5,
  Account6,
  Account7,
  Account8,
  BoxAccount,
  Circle1,
  Circle2,
  Divider,
  Info,
  Info1,
  Info2,
  Info3,
  Info4,
  Title,
};
