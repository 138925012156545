/* eslint-disable import/prefer-default-export */
export const ASYNC_MAX_RETRIES = 6;
export const ASYNC_DELAY_RETRY = 5000;
export const NUMBER_FAILED_ATTEMPTS = '4';

export const TEXT_ENTITY_TOKEN = {
  title: 'Ingresar Token',
  descriptionOne: 'El Token es una clave dinámica y un doble factor de autenticación que nos',
  descriptionTwo: 'permite validar tu identidad.',
  textPlaceholder: 'Ingresá el Token',
};

export const TEXT_ERROR_ENTITY_TOKEN = {
  title: 'Token incorrecto',
  descriptionOne: 'Ingresá de nuevo el Token para autorizar las transferencias.',
  descriptionTwo: 'El Token es una clave dinámica y un doble factor de autenticación que nos permite validar tu identidad.',
};

export const TEXT_MODAL_USER_BLOCKING_WARNING = {
  title: 'Estás por bloquear tu usuario',
  firstDescription: 'Si ingresás mal otra vez el Token se bloqueará tu usuario. <br /> Revisá el código antes de volver a ingresarlo.',
  firstTextButton: 'Entendido',
  altIcon: 'icon-checkmark-modal',
};

export const TEXT_MODAL_USER_BLOCKED = {
  title: 'Usuario bloqueado',
  firstDescription: 'Por seguridad vamos a cerrar tu sesión. <br /> Podés desbloquear tu usuario en <strong>Gestión de claves.</strong>',
  altIcon: 'icon-checkmark-modal',
};

export const TEXT_MODAL_SUCCESS = {
  title: 'Transferencias autorizadas',
  goToHome: 'Ir a Inicio',
  goToTransfers: 'Ir a Transferencias',
};

export const TEXT_LOADING_AUTHORIZE = {
  title: 'Estamos autorizando las transferencias',
  description: 'Esto puede demorar unos instantes.',
};

export const EXCEPTION_CODES = {
  invalidOtp: 'TEF-0024',
  invalidOtpLastAttempt: 'TEF-0025',
  blockedPerson: 'TEF-0016',
  personBlockedAttempts: 'TEF-0023',
  satisfactoryProcess: 'TEF-0000',
  totalReject: 'TEF-0005',
  parcialReject: 'TEF-0004',
  asyncSendProcess: 'TEF-0008',
  timeOut: 'TEF-0015',
  CHANNEL_NOT_FOUND: 'TEF-0017',
  COUNTRY_NOT_FOUND: 'TEF-0018',
  NON_AFFILIATED_PERSON: 'TEF-0019',
  ADHESION_DISABLED: 'TEF-0022',
  NON_LINKED_PERSON: 'TEF-0021',
  DISABLED_PERSON: 'TEF-0020',
  parcialValidation: 'TEF-0029',
  parcialOperation: 'TEF-0032',
};

export const TEXT_MODAL_BLOCK_USER = {
  title: 'Bloqueamos tu usuario',
  firstDescription:
    'Ingresaste mal el Token y por seguridad vamos a cerrar tu sesión. <br /> Podés desbloquear tu usuario en <strong>Gestión de claves.</strong>',
  altIcon: 'icon-checkmark-modal',
};

export const ALERT_SUE_TEXT = {
  title: 'El importe no incluye el total de transferencias',
  description: 'Según los permisos de tu perfil, no te podemos mostrar el monto total a autorizar.',
};

export const TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS = {
  title: 'La autorización se está procesando',
  firstDescription: 'Cuando finalice podrás verificar si todo se procesó correctamente desde<br />la sección <strong>Reportes.</strong>',
  firstTextButton: 'Entendido',
};

export const TEXT_MODAL_PROCESS_ASYNCHRONOUS = {
  title: 'Autorizando transferencias',
  firstDescription:
    'Estamos procesando la autorización y puede demorar unos minutos, <br /> Si necesitás seguir operando, volvé a la sección <strong>Transferencias.</strong> <br /> No te preocupes, la autorización seguirá en proceso.',
  firstTextButton: 'Volver a Transferencias',
};

export const EXCEPTION_CODES_PROCESS_ASYNCHRONOUS = {
  satisfactoryProcess: 'TEF-0000',
  partialRejection: 'TEF-0004',
  totalRejection: 'TEF-0005',
  submissionProcessedAsynchronously: 'TEF-0008',
  asynchronousProcessNotFound: 'TEF-0012',
  processProgress: 'TEF-0013',
  processFailed: 'TEF-0014',
  processFailedTimeOut: 'TEF-0015',
  parcialValidation: 'TEF-0029',
  parcialOperation: 'TEF-0032',
};

export const ASYNC_MODAL_AUTH_ERROR_TEXT = (
  <>
    Para verificar si las transferencias se autorizaron correctamente, revisá el estado en la sección <strong>Reportes.</strong>
  </>
);

export const TEXT_MODAL_SEND_ASYNC_IS_DONE = {
  title: 'El envío se está procesando',
  altIcon: 'icon-information-modal',
  firstTextButton: 'Entendido',
};

export const WizardToken = {
  titleToken: 'Ingreso de token',
  descToken: 'Este es el último paso antes de autorizar las transferencias, conocé cómo funciona.',
  titleSummary: 'Resumen de autorización',
  descSummary: (
    <>
      ¡Verificá que los datos sean los correctos! Revisá la <strong>cantidad de transferencias</strong> y <strong>montos totales</strong>.
    </>
  ),
  titleTokenInput: 'Ingresar Token',
  descTokenInput: 'Escribí el Token que generaste en la App para autorizar las transferencias ¡y listo!',
};

export const CHANNEL_COUNTRY_NOT_FOUND_MODAL = {
  title: 'Necesitás configurar tu Token',
  description:
    'Comunicate con nuestro equipo de Soporte llamando al (11) 5554 - 2999\npara configurar tu Token y así poder autorizar las transferencias.',
  textButton: 'Entendido',
};

export const NON_AFFILIATED_MODAL = {
  title: 'Necesitás vincular tu Token',
  description: 'Para autorizar transferencias necesitás vincular tu Token, hacelo desde <strong>Gestión de claves.</strong>',
  textButton: 'Entendido',
};

export const NON_LINKED_PERSON_ADHESION_DISABLED_MODAL = {
  title: 'Necesitás habilitar tu Token',
  description: 'Necesitás configurar tu dispositivo Token para autorizar transferencias, hacelo en <strong>Gestión de claves.</strong>',
  textButton: 'Entendido',
};

export const DISABLED_USER_MODAL = {
  title: 'Usuario inhabilitado',
  description: 'Para habilitar tu usuario comunicate con nuestro equipo de Soporte llamando al (11) 5554 - 2999.',
  textButton: 'Entendido',
};
