import { Currencies } from '@interfaces/transfers';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { EXCEPTION_CODES } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { Bank, BankAccount } from '../Interfaces/api';
import { useTransferCreditProps } from '../Interfaces/hooks/IUseTransferCredit';
import { SelectOption } from '../Interfaces/transferSetup';
import { getCreditAccounts, getCreditBanks } from '../services/api';

export function useTransferCredit({
  transferSelected,
  codeTransferType = '',
  debitAccountSelectedRef,
  setTransferSelected,
  debitAccountNumberSelected,
}: useTransferCreditProps) {
  const { manualTransferData } = useTransfersContext();
  const [creditAccounts, setCreditAccounts] = useState<BankAccount[]>([]);
  const [creditAccountsFiltered, setCreditAccountsFiltered] = useState<BankAccount[]>([]);
  const [creditAccountsFilteredLoading, setCreditAccountsFilteredLoading] = useState(false);
  const [creditAccountsFilteredError, setCreditAccountsFilteredError] = useState(false);
  const [creditAccountSelectedId, setCreditAccountSelectedId] = useState<string | null>(null);
  const [creditAccountSelected, setCreditAccountSelected] = useState<BankAccount | null>(null);
  const [creditUsageReferences, setCreditUsageReferences] = useState<string[]>([]);
  const [creditUsageReferenceSelected, setCreditUsageReferenceSelected] = useState<SelectOption | null>(null);
  const [creditBanks, setCreditBanks] = useState<Bank[]>([]);
  const [creditBanksLoading, setCreditBanksLoading] = useState(true);
  const [creditBanksError, setCreditBanksError] = useState(false);
  const [creditBankSelected, setCreditBankSelected] = useState<SelectOption | null>(null);
  const [creditBanksCurrencyARS, setCreditBanksCurrencyARS] = useState(false);
  const [creditBanksCurrencyUSD, setCreditBanksCurrencyUSD] = useState(false);
  const [creditCurrencySelected, setCreditCurrencySelected] = useState<string>(Currencies.ARS);
  const [creditFilterTypeSelected, setCreditFilterTypeSelected] = useState<SelectOption | null>(null);
  const [creditFilterData, setCreditFilterData] = useState<string>('');
  const [statusGetCreditBanks, setStatusGetCreditBanks] = useState(false);
  const [sameAccountSelectedCredit, setSameAccountSelectedCredit] = useState(false);
  const [statusGetCreditAccounts, setStatusGetCreditAccounts] = useState(false);

  const changeCreditBanksCurrency = (currency: string) => {
    setCreditCurrencySelected(currency);
    setCreditBanksCurrencyARS(currency === Currencies.ARS);
    setCreditBanksCurrencyUSD(currency === Currencies.USD);
    setCreditUsageReferenceSelected(null);
    setCreditBankSelected(null);
    setCreditFilterTypeSelected(null);
    setCreditFilterData('');
    setCreditAccountSelectedId(null);
    setCreditAccountSelected(null);
  };

  const handleSelectCreditAccount = (value: string | null) => {
    setCreditAccountSelectedId(value);

    if (!value) {
      setCreditAccountSelected(null);
      return;
    }

    const findIndex = creditAccountsFiltered.findIndex(item => item.accountId === value);

    if (creditAccountsFiltered[findIndex].cbu === debitAccountSelectedRef?.current) {
      setSameAccountSelectedCredit(true);
      setCreditUsageReferenceSelected(null);
      setCreditBankSelected(null);
      setCreditFilterTypeSelected(null);
      setCreditFilterData('');
      setCreditAccountSelectedId(null);
      setCreditAccountSelected(null);
      return;
    }
    setCreditAccountSelected(creditAccountsFiltered[findIndex]);
  };

  const handleRemoveSelectedCreditAccount = () => {
    if (transferSelected?.creditAccount) {
      setTransferSelected({ ...transferSelected, creditAccount: null });
    }
    setCreditAccountSelectedId(null);
    setCreditAccountSelected(null);
  };

  const handleSelectCreditUsageReference = (selectedOption: SelectOption | null) => {
    setCreditUsageReferenceSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleSelectCreditBank = (selectedOption: SelectOption | null) => {
    setCreditBankSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleSelectCreditCurrency = (currency: string) => {
    setCreditCurrencySelected(currency);
  };

  const handleSelectCreditFilterType = (selectedOption: SelectOption | null) => {
    setCreditFilterTypeSelected(selectedOption?.value ? selectedOption : null);
  };

  const { refetch: fetchCreditAccounts, isError: isErrorFetchCreditAccounts } = useQuery(
    [
      'get-credit-accounts-filtered',
      manualTransferData.transferType || codeTransferType,
      creditBankSelected?.value || '',
      creditCurrencySelected,
      creditFilterTypeSelected?.value || '',
      creditFilterData,
    ],
    () =>
      getCreditAccounts({
        transferType: manualTransferData.transferType || codeTransferType,
        cbuSelected: debitAccountSelectedRef?.current || '',
        accountSelected: debitAccountSelectedRef?.current || debitAccountNumberSelected,
        currency: creditCurrencySelected,
        filterType: creditFilterTypeSelected?.value || '',
        filterData: creditFilterData,
      })?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success || data?.exception?.code === EXCEPTION_CODES.empty) {
          setStatusGetCreditAccounts(true);
          const accounts: BankAccount[] = data?.data.accounts || [];
          setCreditAccounts(accounts);
        } else {
          setCreditAccountsFilteredError(true);
        }
        setTimeout(() => {
          setCreditAccountsFilteredLoading(false);
        }, 500);
      },
      onError: () => {
        setCreditAccountsFilteredError(true);
        setCreditAccountsFilteredLoading(false);
      },
    },
  );

  const { refetch: fetchCreditAccountsFiltered } = useQuery(
    [
      'get-credit-accounts-filtered',
      manualTransferData.transferType || codeTransferType,
      creditBankSelected?.value || '',
      creditCurrencySelected,
      creditFilterTypeSelected?.value || '',
      creditFilterData,
    ],
    () =>
      getCreditAccounts({
        transferType: manualTransferData.transferType || codeTransferType,
        cbuSelected: debitAccountSelectedRef?.current || '',
        accountSelected: debitAccountSelectedRef?.current || debitAccountNumberSelected,
        currency: creditCurrencySelected,
        filterType: creditFilterTypeSelected?.value || '',
        filterData: creditFilterData,
      })?.then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success || data?.exception?.code === EXCEPTION_CODES.empty) {
          const accounts: BankAccount[] = data?.data.accounts || [];
          setCreditAccountsFiltered(accounts);
        } else {
          setCreditAccountsFilteredError(true);
        }
        setTimeout(() => {
          setCreditAccountsFilteredLoading(false);
        }, 500);
      },
      onError: () => {
        setCreditAccountsFilteredError(true);
        setCreditAccountsFilteredLoading(false);
      },
    },
  );

  const { refetch: fetchCreditBanks, isError: isErrorFetchCreditBanks } = useQuery(
    ['get-credit-banks', manualTransferData.transferType || codeTransferType],
    () => getCreditBanks(manualTransferData.transferType || codeTransferType)?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setCreditBanks(data?.data.banks);
          setStatusGetCreditBanks(true);
        } else {
          setCreditBanksError(true);
        }
        setCreditBanksLoading(false);
      },
      onError: () => {
        setCreditBanksError(true);
        setCreditBanksLoading(false);
      },
    },
  );

  const handleSearchCreditAccount = () => {
    setCreditAccountsFiltered([]);
    setCreditAccountsFilteredError(false);
    setCreditAccountsFilteredLoading(true);
    fetchCreditAccountsFiltered();
  };

  const resetCreditAccountFilters = () => {
    setCreditUsageReferenceSelected(null);
    setCreditBankSelected(null);
    setCreditFilterTypeSelected(null);
    setCreditFilterData('');
  };

  const loadCreditAccounts = () => {
    fetchCreditAccounts();
  };

  const loadCreditBanks = () => {
    fetchCreditBanks();
  };

  return {
    creditAccountSelectedId,
    creditAccountSelected,
    creditAccountsFiltered,
    creditAccountsFilteredLoading,
    creditAccountsFilteredError,
    creditUsageReferences,
    creditUsageReferenceSelected,
    creditBanks,
    creditBanksLoading,
    creditBanksError,
    creditBanksCurrencyARS,
    creditBanksCurrencyUSD,
    creditBankSelected,
    creditCurrencySelected,
    creditFilterTypeSelected,
    creditFilterData,
    isErrorFetchCreditBanks,
    statusGetCreditBanks,
    sameAccountSelectedCredit,
    loadCreditBanks,
    setCreditBanksLoading,
    setCreditBanksCurrencyARS,
    setCreditBanksCurrencyUSD,
    setCreditAccountSelected,
    setCreditAccountSelectedId,
    setCreditCurrencySelected,
    handleSelectCreditAccount,
    handleRemoveSelectedCreditAccount,
    handleSelectCreditUsageReference,
    handleSelectCreditBank,
    handleSelectCreditCurrency,
    handleSelectCreditFilterType,
    setCreditFilterData,
    handleSearchCreditAccount,
    changeCreditBanksCurrency,
    resetCreditAccountFilters,
    setCreditUsageReferences,
    setSameAccountSelectedCredit,
    setStatusGetCreditBanks,
    fetchCreditBanks,
    loadCreditAccounts,
    isErrorFetchCreditAccounts,
    statusGetCreditAccounts,
    setStatusGetCreditAccounts,
    creditAccounts
  };
}
