import * as React from 'react';

function IconTransfer() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.70711 3.29289C7.09763 3.68342 7.09763 4.31658 6.70711 4.70711L5.41421 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H5.41421L6.70711 9.29289C7.09763 9.68342 7.09763 10.3166 6.70711 10.7071C6.31658 11.0976 5.68342 11.0976 5.29289 10.7071L2.29289 7.70711C1.90237 7.31658 1.90237 6.68342 2.29289 6.29289L5.29289 3.29289C5.68342 2.90237 6.31658 2.90237 6.70711 3.29289ZM17.2929 12.7929C17.6834 12.4024 18.3166 12.4024 18.7071 12.7929L21.7071 15.7929C22.0976 16.1834 22.0976 16.8166 21.7071 17.2071L18.7071 20.2071C18.3166 20.5976 17.6834 20.5976 17.2929 20.2071C16.9024 19.8166 16.9024 19.1834 17.2929 18.7929L18.5858 17.5H3C2.44772 17.5 2 17.0523 2 16.5C2 15.9477 2.44772 15.5 3 15.5H18.5858L17.2929 14.2071C16.9024 13.8166 16.9024 13.1834 17.2929 12.7929Z'
        fill='#565E71'
      />
    </svg>
  );
}

export default IconTransfer;
