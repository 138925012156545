import { Box, IconButton, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { Eye, EyeOff } from 'react-feather';

import { colors } from '../../styled/generalStyles';
import { CustomBox, CustomInputGroup, CustomInputPassword, TextBox, TextBoxNameCompany } from '../../views/EntityKey/index.styled';
import IconCancelModal from '../Icons/icon-cancel-modal';

interface CustomBoxWithInputProps {
  companyKeyNotFound?: boolean;
  companyKeyTitle?: string;
  companyKeyDescriptionOne?: string;
  companyKeyDescriptionTwo?: string;
  companyKeyDescriptionThree?: string;
  password: string;
  show: boolean;
  showPassword: () => void;
  handleValidationLength: (e: ChangeEvent<HTMLInputElement>) => void;
  onMouseLeave: () => void;
  hidePassword: () => void;
  status?: string;
  nameCompany?: string;
  companyKeyDescriptionFour?: string;
  textPlaceholder: string;
  maxLength: number;
  id: string;
}

function CustomBoxWithInput({
  companyKeyNotFound,
  companyKeyTitle,
  companyKeyDescriptionOne,
  companyKeyDescriptionTwo,
  companyKeyDescriptionThree,
  password,
  show,
  showPassword,
  handleValidationLength,
  onMouseLeave,
  hidePassword,
  status,
  nameCompany,
  companyKeyDescriptionFour,
  textPlaceholder,
  maxLength,
  id,
}: CustomBoxWithInputProps) {
  const IconInput = show ? <Eye color={colors.Secondary100} /> : <EyeOff color={colors.Secondary100} />;
  const TypeInput = show ? 'text' : 'password';

  return (
    <Box sx={CustomBox} marginTop={{ lg: '40px', sm: '10px' }} padding={{ lg: '40px 48px', sm: '15px' }} id={id}>
      {companyKeyNotFound && <IconCancelModal />}
      <Text textStyle='h4Bold' marginBottom={{ lg: '24px', sm: '8px' }} marginTop={{ lg: '24px', sm: '16px' }}>
        {companyKeyTitle}
      </Text>
      <Text sx={TextBox} textStyle='titleSm'>
        {companyKeyDescriptionOne}
      </Text>
      {!companyKeyNotFound && (
        <Text sx={TextBox} textStyle='titleSm'>
          {companyKeyDescriptionTwo}
        </Text>
      )}
      <InputGroup size='md' sx={CustomInputGroup} margin={{ lg: '45px 0px', sm: '20px 0px' }}>
        <Input
          sx={CustomInputPassword}
          background={{}}
          fontSize={{}}
          maxLength={maxLength}
          onChange={handleValidationLength}
          type={TypeInput}
          placeholder={textPlaceholder}
          value={password}
          size='md'
        />
        <InputRightElement width='4.5rem'>
          <IconButton
            data-testid='button-show-and-hide-password'
            aria-label='Button password'
            icon={IconInput}
            onMouseDown={showPassword}
            onMouseLeave={onMouseLeave}
            onMouseUp={hidePassword}
            size='i-md'
            variant='i-text-light'
          />
        </InputRightElement>
      </InputGroup>
      {!companyKeyNotFound && companyKeyDescriptionThree && (
        <Text as='p' sx={TextBoxNameCompany} textStyle='titleSm'>
          {companyKeyDescriptionThree} {status !== 'error' && <Text as='span' textStyle='labelLgBold'>{`${nameCompany}.`}</Text>}
        </Text>
      )}
      <Text sx={TextBox} textStyle='titleSm'>
        {companyKeyDescriptionFour}
      </Text>
    </Box>
  );
}

export default CustomBoxWithInput;
