import { SystemStyleObject } from '@chakra-ui/react';

import { colors } from '../../../../styled/generalStyles';

const accordion: SystemStyleObject = {
  marginBottom: '100px',

  '> div, & p': {
    margin: 0,
  },

  '> div + div': {
    '&, &:focus:hover, &>*, &:focus:hover>*, &>*[aria-expanded=true], &>*[data-expanded], &:hover>button:not(disabled)': {
      borderRadius: 0,
    },
  },

  '>div:hover>button[aria-expanded=true]': {
    background: colors.Primary60,
  },
};

const accordionItem: SystemStyleObject = {
  border: 0,
};

const accordionButton: SystemStyleObject = {
  height: '86px',
  justifyContent: 'space-between',
  boxShadow: `${colors.ShadowXs}`,
  background: colors.White,
  padding: '0 16px 0 0',
  borderRadius: '8px 8px 0 0',

  '&[aria-expanded=false]:hover, &[aria-expanded=false]:focus, &:focus:hover': {
    background: colors.White,
  },

  '&[aria-expanded=true]:hover': {
    background: colors.Primary60,
  },

  '&[aria-expanded=true], &[aria-expanded=true]:focus': {
    background: colors.Primary60,
    borderBottom: `1px solid ${colors.Grey60}`,
  },

  '& + div': {
    background: colors.White,
  },
};

const accordionButtonMain: SystemStyleObject = {
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
};

const accordionButtonTexts: SystemStyleObject = {
  flexDirection: 'column',
  alignItems: 'flex-start',
};

const badge: SystemStyleObject = {
  alignItems: 'center',
  justifyContent: 'center',
  width: '100px',
  margin: '0 8px',
};

const chevron: SystemStyleObject = {
  color: colors.Primary100,
  width: '20px',
  height: '20px',
  margin: '0 10px 0 22px',
};

const accordionPanel: SystemStyleObject = {
  padding: 0,
};

const listHeaderSuccessStyle: SystemStyleObject = {
  flex: '1',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingX: '38px',
  paddingY: '8px',
  borderRadius: '8px 8px 0 0',
  border: `1px solid ${colors.Grey60}`,
  borderBottom: 'none',
  backgroundColor: colors.White,
  boxShadow: colors.ShadowXs,
  marginTop: '12px',
  height: '70px',
};

const boxStyle: SystemStyleObject = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const boxbutton: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'center',
};

export default {
  accordion,
  boxbutton,
  accordionItem,
  accordionButton,
  accordionButtonMain,
  accordionButtonTexts,
  accordionPanel,
  badge,
  chevron,
  listHeaderSuccessStyle,
  boxStyle,
};
