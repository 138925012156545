export const BUTTON_TEXT = 'Anular';
export const TITLE_TOOLTIP_UNIFIED_SHIPPING = 'Lote con envío unificado: ';
export const TEXT_TOOLTIP_UNIFIED_SHIPPING = 'Si tiene más de\nuna cuenta de débito, envía todos los lotes\njuntos.';
export const TEXT_TOOLTIP_QUANTITY = 'Cantidad de transferencias que';
export const TEXT_TOOLTIP_LOT = 'conforman el lote';
export const TEXT_TOOLTIP_ICON = 'Ver detalle de lote';
export const TEXT_TITLE_ANULL_LOADING = 'Estamos anulando las transferencias';
export const CONFIRMATION_MODAL_TEXT = {
  title: '¿Querés anular las transferencias?',
  firstTextButton: 'Cancelar',
  secondTextButton: 'Si, anular',
  altIcon: 'icon-unifiedshipping-modal',
  description: 'Si anulás las transferencias se eliminarán de la lista y tendrás que confeccionarlas de nuevo.',
};
export const EXCEPTION_CODES = {
  success: 'TEF-0000',
  partialRejection: 'TEF-0004',
  totalRejection: 'TEF-0005',
  asyncProcess: 'TEF-0008',
  timeOut: 'TEF-0015',
};
export const ERROR_MODAL_TEXTS = {
  title: 'No podemos anular las transferencias',
  description: '¡No te preocupes! Las transferencias seleccionadas se mantienen, solo tenés que volver a intentarlo.',
  cancel: 'Cancelar',
  retry: 'Reintentar',
};
export const SUCCESS_MODAL_TEXTS = {
  title: 'Anulaste las transferencias',
  description: 'Quedarán en la sección <strong>Reportes</strong> con estado anulada.',
  ok: 'Entendido',
};
export const ZERO_ANULL_MODAL_TEXTS = {
  title: 'No podemos anular las transferencias',
  description: 'Las transferencias ya no están disponibles, revisá el estado en la sección <strong>Reportes.</strong>',
  textButton: 'Entendido',
};

export const ASYNC_MODAL_IN_PROGRESS_TEXT = (
  <>
    Esto puede demorar unos minutos.
    <br />
    Si necesitás seguir operando, volvé a la sección <strong>Transferencias</strong>.<br />
    No te preocupes, la anulación seguirá en proceso.
  </>
);
export const ANNUL_ASYNC_MODAL_DONE = (
  <>
    Cuando finalice podrás verificar si todo se procesó correctamente desde
    <br />
    la sección <strong>Reportes.</strong>
  </>
);
export const ANNUL_ASYNC_MODAL_IN_PROGRESS_TEXT = {
  title: 'Anulando transferencias',
  textButton: 'Volver a Transferencias',
  titleDone: 'La anulación se está procesando',
  buttonDone: 'Entendido',
};

export const ASYNC_MODAL_ANNUL_ERROR_TEXT = (
  <>
    Para verificar si las transferencias se anularon correctamente, revisá el estado en la sección <strong>Reportes.</strong>
  </>
);
