const CustomBox = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '35px 0px 200px',
};

const CustomTransferDetailDrawer = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '120px 0px',
};

const CustomBoxDrawerBinoculars = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '180px 0px',
};

const CustomBoxDrawerDisconnected = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '180px 0px',
};

const CustomAltBox = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '35px 0px 200px',
};

const CustomBoxConfection = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '160px 0px',
};

const DefaultTitleText = {
  margin: '15px 0px 0px 0px',
  textAlign: 'center',
  textStyle: 'h5Bold',
};

const ConfectionTitleText = {
  margin: '16px 0px 0px 0px',
  textAlign: 'center',
  textStyle: 'h4Bold',
};

const DisconnectDrawerTitleText = {
  margin: '15px 0px 0px 0px',
  textAlign: 'center',
  textStyle: 'h4Bold',
  padding: '0px 64px',
};

const BinocularsDrawerTitleText = {
  margin: '15px 0px 0px 0px',
  textAlign: 'center',
  textStyle: 'h4Bold',
};

const CustomTitleTextDisconnect = {
  margin: '15px 0px 0px 0px',
  textAlign: 'center',
};

const DefaultDescriptionText = {
  margin: '8px 0px 0px 0px',
  textAlign: 'center',
  textStyle: 'titleSm',
};

const ConfectionDescriptionText = {
  margin: '8px 0px 14px 0px',
  textAlign: 'center',
  textStyle: 'bodyLg',
};

const DrawerDescriptionText = {
  margin: '8px 0px 0px 0px',
  textAlign: 'center',
  textStyle: 'bodyLg',
};

const CustomDescription2Text = {
  marginTop: '-3px',
  textAlign: 'center',
  textStyle: 'titleSm',
};

const RetryLinkStyle = {
  marginTop: '10px',
  textStyle: 'labelLgBoldUnderline',
  fontSize: '14px',
};

export {
  BinocularsDrawerTitleText,
  ConfectionDescriptionText,
  ConfectionTitleText,
  CustomAltBox,
  CustomBox,
  CustomBoxConfection,
  CustomBoxDrawerBinoculars,
  CustomBoxDrawerDisconnected,
  CustomDescription2Text,
  CustomTitleTextDisconnect,
  CustomTransferDetailDrawer,
  DefaultDescriptionText,
  DefaultTitleText,
  DisconnectDrawerTitleText,
  DrawerDescriptionText,
  RetryLinkStyle,
};
