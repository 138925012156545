import { SystemStyleObject } from '@chakra-ui/react';

const card: SystemStyleObject = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: '12px',
};

const header: SystemStyleObject = {
  alignItems: 'center',
  height: '44px',
  padding: '12px',
};

const content: SystemStyleObject = {
  padding: '8px 16px 16px 12px',
};

const footer: SystemStyleObject = {
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
  padding: '12px',
};

export default { card, content, footer, header };
