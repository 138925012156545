import styled from '@emotion/styled';
import { WithPermissions } from '@react-ib-mf/style-guide-ui';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { RouteConfig } from '../Interfaces/routes';
import Breadcrumb from './components/Breadcrumb';
import Processes from './components/Processes';
import ProcessesDrawer from './components/ProcessesDrawer';

function Dashboard({ routeConfig }: { routeConfig: RouteConfig[] }) {
  const location = useLocation();
  const route = routeConfig.find(item => item.path === location.pathname);
  let routePermissions: string[] = [];

  if (route?.permissions) {
    routePermissions = route.permissions;
  }

  const [showDrawer, setShowDrawer] = useState(false);

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const isErrorUrlPage = !route || route.path === '*';

  return (
    <>
      {routePermissions.length ? (
        <WithPermissions permission={[...routePermissions]}>
          <Processes openDrawer={openDrawer} />
        </WithPermissions>
      ) : (
        <Processes openDrawer={openDrawer} />
      )}
      <ProcessesDrawer isOpen={showDrawer} onClose={closeDrawer} />
      <DashboardPanel>
        {!isErrorUrlPage &&
          (routePermissions.length ? (
            <WithPermissions permission={[...routePermissions]}>
              <Breadcrumb />
            </WithPermissions>
          ) : (
            <Breadcrumb />
          ))}
        <Outlet />
      </DashboardPanel>
    </>
  );
}

export default Dashboard;

const DashboardPanel = styled.div`
  padding-left: 28px;
  padding-right: 64px;
`;
