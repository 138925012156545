const TEXTS = {
  title: {
    debit: 'Elegí alguna de las cuentas de débito disponibles',
    credit: 'Elegí alguna de las cuentas de crédito disponibles',
  },
  btnSearch: 'Buscar',
};

const TEST_IDS = {
  cardAccountEmpty: 'cardAccountEmpty',
  btnSearch: 'btnSearch',
  btnAccountType: {
    debit: 'btnSearch-debit',
    credit: 'btnSearch-credit',
  },
};

export { TEST_IDS, TEXTS };
