/* eslint-disable no-shadow */
import { AccountingType, Currencies } from '@interfaces/transfers';
import { TRANSFER_STATUS } from 'src/constants-app';

import { TransfersAccepted, TransfersRejected, TransfersValidated } from './transfers';
import { Holiday, SelectOption, WorkingDay } from './transferSetup';

type ApiResponse<T> = {
  data: T;
  status: number;
  statusText: string;
};

type ApiResponseData = {
  exception: {
    code: string;
    message: string;
  };
  success: boolean;
};

type paramsGetLots = {
  transfersStatus?: string;
  size: string;
  page: string | number;
  myApprovals?: string;
  lotNumber?: string | null;
};

type paramsGetModifyTransfers = {
  size: string;
  page: string | number;
  transfer: [] | number[];
};

type paramsLotDetail = {
  lot: string | undefined;
  date: string | undefined;
  bank: string | undefined;
  status?: string | undefined;
  params: {
    orderProperty?: string;
    transfersStatus?: string | undefined;
    myApprovals?: string | undefined;
    confection?: boolean;
  };
};

type paramsGetTransfersDetail = {
  ordenId: number;
  params?: {
    isModify?: boolean;
  };
};

type paramsGenralInformationLot = {
  macTransfer: string | undefined;
};

type paramsSendTransfers = {
  transfers: sendTef[];
};

type sendTef = {
  orderId: string | number;
  typeTransferCode: string;
  mac1: string;
  numberStep: string | number;
  numberLot: string | number;
  dateCreation: string;
  unifiedShipping: string;
  immediately?: string;
};

type macKeys = {
  bankCode: string;
  bankName: string;
  macKey: string;
  validMacKey?: boolean;
  isValidlastDigit?: boolean;
};

type PostDataTransfer = {
  orderId: string | number;
  transferTypeCode: string;
};

type PostDataTransfersAction = {
  transfers: PostDataTransfer[];
  otp?: string | null;
};

type Permissions = ApiResponseData & {
  data: PermissionsData;
};

type PermissionsData = {
  canCreate: boolean;
  canUpload: boolean;
  sections: Section[];
  permissions: string[];
  tabs: Tab[];
  exception: {
    code: string;
    message: string;
  };
};

type Section = {
  section: string;
  transferTypeCodes: string[];
};

type Tab = {
  permissons: string[];
  tab: string;
};

type ResponseCode = {
  codigo: string;
  descripcion: string;
};

type ResponseGetLots = ApiResponseData & {
  paging: {
    pageNumber: number;
    totalElements: number;
    totalElementsRequested: number;
    totalPages: number;
  };
  data: {
    respuestas: ResponseCode[];
    paginateView: {
      totalPages: number;
      totalElements: number;
    };
    lots: [];
  };
};

type ResponsePostTransfersAction = ApiResponseData & {
  data: {
    processId: number;
    transfersAccepted: TransfersAccepted[];
    transfersRejected: TransfersRejected[];
    transfersValidated: TransfersValidated[];
    failedAttempts: string;
  };
};

type ResponseAsyncProcess = ApiResponseData & {
  data: {
    action: string;
    origin: string;
    statusDescription: string;
    transfersAccepted: TransfersAccepted[];
    transfersRejected: TransfersRejected[];
    transfersValidated: TransfersValidated[];
  };
};

type OfferingResponse = ApiResponseData & {
  data: OfferingData;
};

type OfferingData = {
  hasBonus: boolean;
  startsAt?: string;
  endsAt?: string;
  duration: number;
  currentOffering: CurrentOffering;
  nextOffering?: NextOffering;
  reason: string;
  type: string;
};

type CurrentOffering = {
  code: string;
  name: string;
};

type NextOffering = {
  code: string;
  name: string;
};

type PostDataAuthPartial = {
  transfer: number[];
};

type TransferTypeByOperator = {
  calendarDefer: number;
  code: string;
  deferMaxDays: number;
  description: string;
  id: number;
};

type TransferTypesData = {
  otpConfigured: boolean;
  transferResponses: {
    code: string;
    description: string;
  };
  transferTypeByOperators: TransferTypeByOperator[];
};

type TransferTypesResponse = ApiResponseData & {
  data: TransferTypesData;
};

type ImportMode = {
  importModeCode: string;
  importModeDescription: string;
};

type EnabledTransfersToUploadData = {
  enabledTransfersToUpload?: EnabledTransfersOptions[];
  sections?: SectionsTypes[];
};

type EnabledTransfersOptions = {
  transferTypeCode: string;
  transferTypeDescription: string;
  importModes: ImportMode[];
};

type SectionsTypes = {
  section: string;
  transferTypeCodes: string[];
};

type Paging = {
  totalPages: number;
  pageNumber: number;
  totalElements: number;
  totalElementsRequested: number;
};

type FieldTransferTypeResponse = ApiResponseData & {
  paging: Paging;
  data?: EnabledTransfersToUploadData;
};

type HolidaysData = {
  holidays: Holiday[];
};

type HolidaysResponse = ApiResponseData & {
  data: HolidaysData;
};

type WorkingDayData = {
  workingDay: WorkingDay;
};

type WorkingDayResponse = ApiResponseData & {
  data: WorkingDayData;
};

type BankAccount = {
  identifier?: string;
  accountId: string;
  accountNumber: string;
  accountType: string;
  accountName: string;
  bank: {
    bcoIdBco: string;
    bankName: string;
    logoBank?: string;
  };
  cbu: string;
  cuit: string;
  currency: Currencies;
  useReference?: string;
  transferType?: string;
};

type BankAccountData = {
  accounts: BankAccount[];
};

type BankAccountsResponse = ApiResponseData & {
  paging: Paging;
  data: BankAccountData;
};

type BankAccountRequestData = {
  accountingType?: AccountingType;
  transferType: string;
  usageReference?: string[];
  bankCode?: string[];
  accountSelected?: string;
  cbuSelected?: string;
  currency?: string;
  filterType?: string;
  filterData?: string;
  page?: number;
  size?: number;
};

type AccountBalance = {
  cbu: string;
  currency: string;
  lastBalance: number;
  lastUpdateDate: string;
  online: boolean;
};

type AccountBalancesData = {
  accountBalances: AccountBalance[];
};

type AccountBalancesResponse = ApiResponseData & {
  data: AccountBalancesData;
};

type UsageReferencesData = {
  creditUsagesReference: string[];
  debitUsagesReference: string[];
};

type UsageReferencesResponse = ApiResponseData & {
  data: UsageReferencesData;
};

type Bank = {
  transferTypeCode: string;
  currencyCode: string;
  bcraCode: string;
  bankName: string;
};

type BanksData = {
  banks: Bank[];
  currencyARS: boolean;
  currencyUSD: boolean;
};

type BanksResponse = ApiResponseData & {
  data: BanksData;
};

type ConceptsResponse = ApiResponseData & {
  data: ConceptData;
};

type ConceptData = {
  totalThirdPartyConcepts: Concept[];
};

type Concept = {
  conceptCode: string;
  description: string;
  disclaimerText?: string;
  disclaimer?: string;
};

type TransferData = {
  date: string;
  observation: string;
  amount: number;
  reason: string;
  voucher: string;
  concept: SelectOption | null;
  isDisclaimerChecked: boolean;
};

type AdditionalInformation = {
  numberClient: string;
  document: SelectOption | null;
  documentNumber: string;
  retentionType: SelectOption | null;
  totalRetentionAmount: number;
  paymentOrderType: string;
  paymentOrderNumber: string;
  creditNoteNumber: number;
  creditNoteAmount: number;
};

type DocumentsToPay = ApiResponseData & {
  data: DocumentsToPayData;
};

type DocumentsToPayData = {
  documentTypeCancels: OptionItem[];
};

type TypesRetention = ApiResponseData & {
  data: TypesRetentionData;
};

type TypesRetentionData = {
  retentionTypeView: OptionItem[];
};

type OptionItem = {
  authorizationState: string;
  code: string;
  description: string;
  id: number;
  state: string;
};

enum ConfectionService {
  judicialDeposits = 'judicialdeposits',
  ownAccounts = 'OwnAccounts',
  providers = 'providersconfection',
  salaries = 'salariesconfection',
  thirdPartyPayments = 'ThirdPartyPayments',
}

type ConfectionTransfer = {
  idTefConfeccion: number;
  requestDate: string;
  accountDebitCbuAlias: string;
  accountCreditCbuAlias: string;
  observation: string;
  amount: number;
  conception?: string;
  disclaimerAccepted?: boolean;
  voucher?: string;
  cause?: string;
  clientCode?: string;
  amountCreditNote?: number;
  documentNumber?: string;
  creditNoteNumber?: number;
  payOrderNumber?: string;
  documentType?: string;
  orderPayType?: string;
  retentionType?: string;
  totalRetention?: number;
};

type ConfectionRequestData = {
  operationId: number;
  unifiedliberation: boolean;
  payNumber: string;
  consolidated: boolean;
  description: string;
  resendValidated: boolean;
  tefs: ConfectionTransfer[];
};

type AcceptedTransfers = {
  orderId: number;
  lotNumber: number;
  idTefConfection: number;
};

type RejectedTransfers = {
  orderId?: number;
  lotNumber?: number;
  message: string;
  idTefConfection: number;
};

type RejectedTransfersDetail = {
  totalTransferByMessage: number;
  message: string;
};

type ValidatedTransfers = {
  orderId: number;
  lotNumber: number;
  idTefConfection: number;
};

type TransfersActionResponseData = {
  status: boolean;
  operationId: string;
  processId: number;
  acceptedTransfers: AcceptedTransfers[];
  rejectedTransfers: RejectedTransfers[];
  rejectedTransferDetails: RejectedTransfersDetail[];
  validateTransfers: ValidatedTransfers[];
};

type TransfersActionResponse = ApiResponseData & {
  data: TransfersActionResponseData;
};

enum AsyncProcessStatus {
  inProcess = 'EN_PROCESO',
  processed = 'PROCESADO',
  awaitingResult = 'BUSCANDO_RESULTADO',
  success = 'EXITOSO',
  pending = 'POR_CONFECCIONAR',
  partialRejection = 'RECHAZO_PARCIAL',
  totalRejection = 'RECHAZO_TOTAL',
}

type AsyncProcess = {
  type: TRANSFER_STATUS;
  number: number;
  status: AsyncProcessStatus;
  quantityTefs: number;
  lotNumber: number;
  lastUpdateDate: string;
  scheduleNotEnabledError?: boolean;
};

type AsyncProcesses = {
  processes: AsyncProcess[];
  totalElements: number;
  totalPages: number;
};

type AsyncProcessesResponseData = {
  asynchronousProcess: AsyncProcesses;
};

type AsyncProcessesResponse = ApiResponseData & {
  data: AsyncProcessesResponseData;
};

type AsyncConfectionAddendum = {
  concept?: string;
  disclaimerAccepted?: boolean;
  reasons?: string;
  voucher?: string;
  clientCode?: string;
  creditNoteAmount?: number;
  creditNoteNumber?: number;
  documentNumber?: string;
  documentType?: string;
  orderPaymentType?: string;
  paymentOrderNumber?: string;
  retentionType?: string;
  totalRetention?: number;
};

type AsyncConfectionCreditAccount = {
  accountId: string;
  accountNumber: string;
  accountType: string;
  addendum?: AsyncConfectionAddendum;
  amount: number;
  bankId: string;
  bankLogo: string;
  bankName: string;
  cbu: string;
  cuit: string;
  denominationAccount: string;
  observation: string;
};

type AsyncConfectionTransfer = {
  accountId: string;
  accountNumber: string;
  accountType: string;
  amount: number;
  bankId: string;
  bankLogo: string;
  bankName: string;
  cbu: string;
  cuit: string;
  currency: string;
  dateRequest: string;
  denominationAccount: string;
  quantityTef: number;
  creditAccounts: AsyncConfectionCreditAccount[];
};

type AsyncConfectionTransferRejected = {
  error: string;
  idTefConfection: number;
};

type AsyncConfectionTransferRejectedDetail = {
  message: string;
  totalTransferByMessage: number;
};

type CompleteTransferRejectedAccount = {
  bank: {
    code: string;
    name: string;
    logo: string;
  };
  cbu: string;
  cuit: string;
  denomination: string;
  id: string;
  number: string;
  type: string;
};

type AsyncConfectionCompleteTransferRejected = {
  addendum: {
    concept: string;
    disclaimerAccepted: boolean;
    reasons: string;
    voucher: string;
    clientCode: string;
    creditNoteAmount: number;
    documentNumber: string;
    creditNoteNumber: string;
    paymentOrderNumber: string;
    documentType: string;
    orderPaymentType: string;
    retentionType: string;
    totalRetention: number;
  };
  amount: number;
  creditAccount: CompleteTransferRejectedAccount;
  currency: string;
  debitAccount: CompleteTransferRejectedAccount;
  error: string;
  idTefConfection: number;
  observation: string;
  requestDate: string;
};

type AsyncConfectionResponseData = {
  processId: number;
  typeTefs: string;
  description: string;
  payNumber: string;
  consolidatedExtract: boolean;
  unifiedRelease: boolean;
  acceptedTransfers: {
    lotNumber: number;
    totalTransfer: number;
    transfer: AsyncConfectionTransfer[];
  };
  rejectedTransfers: {
    totalTransfer: number;
    transfer: AsyncConfectionTransferRejected[];
  };
  rejectedTransfersDetail: AsyncConfectionTransferRejectedDetail[];
  validateTransfers: {
    totalTransfer: number;
    transfer: AsyncConfectionTransfer[];
  };
  completeRejectedTransfers: AsyncConfectionCompleteTransferRejected[];
};

type AsyncConfectionResponse = ApiResponseData & {
  data: AsyncConfectionResponseData;
};

type ModifyTransferResponse = ApiResponseData & {
  data: TransfersActionResponseData;
};

type AdditionalData = {
  conceptTerCod?: string;
  disclaimerAccepted?: boolean;
  voucher?: string;
  reason?: string;
  typeDocumentId?: string;
  codClient?: string;
  typeRetentionId?: string;
  amountRetention?: number;
  amountNoteCred?: number;
  numberDocCancel?: string;
  typeOrderPayment?: string;
  numberNoteCredir?: string | null;
  numberOrderPayment?: string;
};

type DataReadyUpdateTransfer = {
  identifierOperation: string;
  nroAbonated: string;
  tefs: [
    {
      nroTransfer: string;
      numberStep: string;
      dateCreation: string;
      numberLot: number;
      requestDate: string;
      amount: number;
      observationTransfer: string;
      cbuAliasDebitAccount: string;
      cbuAliasCreditAccount: string;
      addendaBody?: AdditionalData;
    },
  ];
};

type RevokeDebitAccount = {
  debitAccountId: string;
  cbu: string;
};

type RevokeLot = {
  lotNumber: number;
  requestDate: string;
  revokeDebitAccountInfos: RevokeDebitAccount[];
};

type RevokeData = {
  transfers?: number[];
  revokeLots?: RevokeLot[];
};

export type {
  AccountBalance,
  AccountBalancesData,
  AccountBalancesResponse,
  AdditionalInformation,
  ApiResponse,
  ApiResponseData,
  AsyncConfectionCompleteTransferRejected,
  AsyncConfectionResponse,
  AsyncConfectionResponseData,
  AsyncConfectionTransfer,
  AsyncConfectionTransferRejected,
  AsyncConfectionTransferRejectedDetail,
  AsyncProcess,
  AsyncProcesses,
  AsyncProcessesResponse,
  Bank,
  BankAccount,
  BankAccountData,
  BankAccountRequestData,
  BankAccountsResponse,
  BanksData,
  BanksResponse,
  Concept,
  ConceptsResponse,
  ConfectionRequestData,
  ConfectionTransfer,
  CurrentOffering,
  DataReadyUpdateTransfer,
  DocumentsToPay,
  EnabledTransfersOptions,
  EnabledTransfersToUploadData,
  FieldTransferTypeResponse,
  HolidaysData,
  HolidaysResponse,
  ImportMode,
  macKeys,
  ModifyTransferResponse,
  NextOffering,
  OfferingData,
  OfferingResponse,
  Paging,
  paramsGenralInformationLot,
  paramsGetLots,
  paramsGetModifyTransfers,
  paramsGetTransfersDetail,
  paramsLotDetail,
  paramsSendTransfers,
  Permissions,
  PermissionsData,
  PostDataAuthPartial,
  PostDataTransfer,
  PostDataTransfersAction,
  ResponseAsyncProcess,
  ResponseCode,
  ResponseGetLots,
  ResponsePostTransfersAction,
  RevokeData,
  Section,
  SectionsTypes,
  sendTef,
  Tab,
  TransferData,
  TransfersActionResponse,
  TransfersActionResponseData,
  TransferTypeByOperator,
  TransferTypesData,
  TransferTypesResponse,
  TypesRetention,
  UsageReferencesData,
  UsageReferencesResponse,
  WorkingDayResponse,
};
export { AsyncProcessStatus, ConfectionService };
