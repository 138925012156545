import { Box, SystemStyleObject } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../../../styled/generalStyles';

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 64px;
  align-items: center;
  padding: 0 24px 0 16px;
`;

const withBorder: SystemStyleObject = {
  borderTop: `1px solid ${colors.Grey60}`,
};

export { CustomBox, withBorder };
