/* eslint-disable import/prefer-default-export */

const DataMessageTefEmpty = {
  title: 'Nada por aquí',
  description: 'No tenés transferencias pendientes de autorización.',
  description2: 'No tenés transferencias pendientes por enviar.',
  description3: 'No tenés ninguna transferencia para quitar tu autorización.',
  description4: 'No tenés ninguna transferencia por anular.',
  description5: 'No tenés archivos con errores.',
  description6: 'No tenés ninguna transferencia para revocar envío.',
};

const DataMessageLotEmpty = {
  title: 'Nada por aquí',
  description: 'No encontramos resultados para tu búsqueda, reintentá con otros filtros.',
};

const LinkConstants = {
  variant: 'primary-text',
  size: 'md',
  datatestid: 'try-again',
};

const DataMessageModifyEmpty = 'Todavía no tenés ninguna transferencia en estado Confeccionada que puedas modificar.';

export { DataMessageLotEmpty, DataMessageModifyEmpty, DataMessageTefEmpty, LinkConstants };
