/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-nested-ternary */
import { Box, Button, Modal, ModalOverlay, Spinner, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@wow/utils';

import { routesNames } from '../../constants-app';
import { colors } from '../../styled/generalStyles';
import { CustomMarginText } from '../../styled/global.styled';
import { transformTextToBold } from '../../utils/TransformTextToBold';
import { ContainerCloseButtonStyles } from '../../views/SendResult/index.styled';
import { ModalProps } from './interfaces';
import {
  CustomBoldTextSecondDescription,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalContent,
  CustomModalFooter,
  CustomModalHeader,
  CustomModalHeaderText,
  CustomTextDescription,
  CustomTextSecondDescription,
  CustomTextThirdDescription,
} from './styled';

function CustomModal({
  isOpen,
  onCancel,
  Icon,
  iconSpinner,
  title,
  firstDescription,
  firstDescriptionWithBold,
  secondDescription,
  firstTextButton,
  secondTextButton,
  modalCloseButton,
  children,
  scrollBehavior,
  actionButton,
  actionButton2,
  enableSecondButton,
  alternativeButtonStyling,
  size,
  urlSecondLink,
  firstButtonLink,
  secondButtonLink,
  enableFirstButton,
  closebuttononside,
  bottomDescription,
  cancelType,
  secondDescriptionWithBold,
  boldText,
  newStyle = true,
  id,
  thirdDescription,
}: ModalProps) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => onCancel(false)}
      scrollBehavior={scrollBehavior}
      size={size}
      id={id || 'customModal'}
    >
      <ModalOverlay />
      <CustomModalContent margin={{}} marginBottom={{}} marginTop={{}}>
        <CustomModalHeader closebuttononside={closebuttononside} sx={newStyle ? { padding: '56px 32px 16px 32px' } : {}}>
          {modalCloseButton && (
            <Box sx={ContainerCloseButtonStyles}>
              <CustomModalCloseButton
                sx={{
                  position: 'initial',
                }}
                top={{}}
                right={{}}
                fontSize={{}}
                padding={{}}
                background={{}}
                _hover={{}}
                _active={{}}
                _focus={{}}
              />
            </Box>
          )}
          {Icon && (
            <Box marginTop={modalCloseButton ? '32px' : '0'} marginBottom={newStyle ? '16px' : '0'}>
              <Icon />
            </Box>
          )}
          {iconSpinner && <Spinner mt='2' mb='2' thickness='4px' speed='0.55s' emptyColor='gray.200' color={colors.Primary90} size='xl' />}
          <Text sx={CustomModalHeaderText} textStyle='h3' whiteSpace='pre-line'>
            {title}
          </Text>
        </CustomModalHeader>
        {children || firstDescription || firstDescriptionWithBold || secondDescription || thirdDescription ? (
          <CustomModalBody
            sx={{ ...(cancelType ? { paddingTop: '0px' } : {}), ...(newStyle ? { padding: '0 32px 16px' } : { padding: '0' }) }}
          >
            {children || (
              <>
                {firstDescription && (
                  <Text
                    sx={CustomTextDescription}
                    textStyle='labelLg'
                    dangerouslySetInnerHTML={{ __html: transformTextToBold('clave única', 'app Interbanking', firstDescription) }}
                    whiteSpace='pre-line'
                  />
                )}
                {firstDescriptionWithBold && (
                  <Text sx={CustomTextDescription} textStyle='labelLg'>
                    {firstDescriptionWithBold}
                  </Text>
                )}
                {secondDescription && (
                  <Text sx={secondDescriptionWithBold ? CustomBoldTextSecondDescription : CustomTextSecondDescription} textStyle='labelLg'>
                    {secondDescription}
                    {secondDescriptionWithBold && (
                      <Text as='span' textStyle='labelLgBold'>
                        {boldText}
                      </Text>
                    )}
                  </Text>
                )}
                {thirdDescription && (
                  <Text sx={CustomTextThirdDescription} textStyle='bodySm'>
                    {thirdDescription}
                  </Text>
                )}
              </>
            )}
          </CustomModalBody>
        ) : null}
        {!closebuttononside && (
          <CustomModalFooter
            justifyContent={{}}
            sx={{ ...(cancelType ? { paddingTop: '0px' } : {}), ...(newStyle ? { padding: '24px 32px 48px' } : {}) }}
          >
            {enableFirstButton && firstButtonLink ? (
              <OpenLink openMode='component' url={routesNames.Root} isRouter>
                <Button
                  variant={alternativeButtonStyling ? 'primary-outline' : 'primary'}
                  size='md'
                  data-testid='close-modal'
                  onClick={actionButton || (() => onCancel(false))}
                  margin={alternativeButtonStyling ? '0px 12px 0px 0px' : '0px'}
                >
                  {firstTextButton}
                </Button>
              </OpenLink>
            ) : enableFirstButton ? (
              <Button
                variant={alternativeButtonStyling ? 'primary-outline' : 'primary'}
                size='md'
                data-testid='close-modal'
                onClick={actionButton || (() => onCancel(false))}
                margin={alternativeButtonStyling ? '0px 12px 0px 0px' : '0px'}
              >
                {firstTextButton}
              </Button>
            ) : null}
            {enableSecondButton && secondButtonLink ? (
              <OpenLink openMode='newTab' url={urlSecondLink} isRouter={false}>
                <Button
                  variant='primary'
                  size='md'
                  data-testid='continue-modal'
                  onClick={actionButton2 || (() => onCancel(false))}
                  margin='0px'
                >
                  {secondTextButton}
                </Button>
              </OpenLink>
            ) : enableSecondButton ? (
              <Button
                variant='primary'
                size='md'
                data-testid='continue-modal'
                onClick={actionButton2 || (() => onCancel(false))}
                margin='0px'
              >
                {secondTextButton}
              </Button>
            ) : null}
            {bottomDescription && (
              <>
                <Spinner width='25px' height='25px' variant='default' mr='8px' color={colors.Primary90} />{' '}
                <Text sx={CustomMarginText} textStyle='labelLgBold'>
                  {bottomDescription}
                </Text>
              </>
            )}
          </CustomModalFooter>
        )}
      </CustomModalContent>
    </Modal>
  );
}

export default CustomModal;
