import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Skeleton, Text } from '@chakra-ui/react';

import ConsolidatedShippingCheckboxes from '../../../../../components/CheckboxesForm';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import CustomSelect from '../../../../../components/CustomSelect';
import IconBeware from '../../../../../components/Icons/icon-beware';
import IconInfoPopup from '../../../../../components/Icons/icon-info-popup';
import CustomModal from '../../../../../components/Modal';
import { useManualTransferForm } from '../../../../../hooks/useManualTransferForm';
import { FormManualTransferSetupProps } from '../../../../../Interfaces/transferSetup';
import { addAnimationSkeletonfadeIn, formStyles, withOutAnimationSkeletonfadeIn } from '../../../../../styled/global.styled';
import { VIEW_TEXT } from '../../../constants';
import {
  costumSecondText,
  customBoxInputs,
  customBoxRowWithMarginTop,
  customHelpText,
  customSkeleton,
  customSkeletonAllSection,
  customSkeletonHelpText,
  customSkeletonSecondText,
  customTextTitle,
  flexContent,
  formErrorStyles,
  formLabelStyles,
  titleSkeleton,
} from '../../../index.styled';
import { consolidatedTooltipText, InputFields, modalTexts, shippingTooltipText, TestIds, texts } from '../constants';
import { customBoxRowWithMarginBottom } from './index.styled';

function FormManualTransferSetup({ transferTypesList, holidays, status, onSubmit }: FormManualTransferSetupProps) {
  const {
    minDate,
    selectedDate,
    formState,
    maxDays,
    validDate,
    transferTypeChanged,
    isBTOBProccesSelected,
    setIsBTOBProccesSelected,
    onRedirectBTOBProcess,
    handleCheckboxChange,
    handleInputChange,
    onChangeDate,
    handleSelectChange,
    handleChangeTransferType,
    handleSelectOptionDisabled,
  } = useManualTransferForm();

  const animation = status ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn;

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Flex as='form' sx={flexContent} onSubmit={handleSubmit} id={TestIds.form} data-testid={TestIds.form}>
      {status ? (
        <Text as='span' textStyle='titleMdBold' sx={{ ...customTextTitle, ...animation }}>
          {VIEW_TEXT.batchDataTitle}
        </Text>
      ) : (
        <Skeleton style={titleSkeleton} data-testid='skeleton-component' />
      )}
      <Box sx={customBoxInputs}>
        <Box sx={customBoxRowWithMarginBottom}>
          <FormControl isRequired sx={formStyles}>
            {status ? (
              <>
                <FormLabel htmlFor={InputFields.transferType} sx={{ ...formLabelStyles, ...animation }}>
                  {texts.transferTypeLabel}
                </FormLabel>
                <CustomSelect
                  value={
                    transferTypesList.length && formState.transferType
                      ? transferTypesList.find(item => item.value === formState.transferType)
                      : undefined
                  }
                  id={InputFields.transferType}
                  name={InputFields.transferType}
                  options={transferTypesList}
                  placeholder={texts.chooseTypePlaceholder}
                  onChange={handleSelectChange}
                  setOptionDisabled={handleSelectOptionDisabled}
                  status={status}
                  isSearchable={false}
                />
              </>
            ) : (
              <Skeleton style={customSkeleton} />
            )}
          </FormControl>
          <FormControl isRequired sx={formStyles} isInvalid={!validDate}>
            {status ? (
              <>
                <FormLabel sx={{ ...formLabelStyles, ...animation }} htmlFor={InputFields.requestDate}>
                  {texts.requestDateLabel}
                </FormLabel>
                <CustomDatePicker
                  holidays={holidays}
                  maxDays={maxDays}
                  minDate={minDate}
                  selectedDate={selectedDate}
                  onChangeDate={onChangeDate}
                  status={status}
                />
                {!validDate && <FormErrorMessage sx={formErrorStyles}>{texts.requestDateError(maxDays)}</FormErrorMessage>}
              </>
            ) : (
              <Skeleton style={customSkeleton} />
            )}
          </FormControl>
        </Box>
        <Box sx={customBoxRowWithMarginTop}>
          <FormControl>
            {status ? (
              <>
                <FormLabel htmlFor={InputFields.description} sx={animation}>
                  {texts.descriptionLabel}
                </FormLabel>
                <Input
                  id={InputFields.description}
                  name={InputFields.description}
                  value={String(formState.description)}
                  type='text'
                  size='md'
                  onChange={handleInputChange}
                  maxLength={60}
                  placeholder={texts.descriptionPlaceholder}
                  sx={animation}
                />
              </>
            ) : (
              <Skeleton style={customSkeletonAllSection} />
            )}
          </FormControl>
        </Box>
        <Box sx={customBoxRowWithMarginTop}>
          <FormControl>
            {status ? (
              <>
                <FormLabel sx={animation} htmlFor={InputFields.paymentNumber}>
                  {texts.paymentNumberLabel}
                </FormLabel>
                <Input
                  id={InputFields.paymentNumber}
                  name={InputFields.paymentNumber}
                  value={String(formState.paymentNumber)}
                  type='text'
                  size='md'
                  onChange={handleInputChange}
                  maxLength={16}
                  placeholder={texts.paymentNumberPlaceholder}
                  sx={animation}
                />
              </>
            ) : (
              <Skeleton style={customSkeletonAllSection} />
            )}
          </FormControl>
        </Box>
      </Box>
      {status ? (
        <Text textStyle='titleMdBold' sx={{ ...costumSecondText, ...animation }} as='span'>
          {texts.batchConfigurationTitle}
        </Text>
      ) : (
        <Skeleton style={customSkeletonSecondText} />
      )}
      <ConsolidatedShippingCheckboxes
        formState={formState}
        handleCheckboxChange={handleCheckboxChange}
        consolidatedLabel={texts.consolidatedLabel}
        unifiedShippingLabel={texts.unifiedShippingLabel}
        consolidatedTooltipText={consolidatedTooltipText}
        shippingTooltipText={shippingTooltipText}
        status={status}
      />
      {status ? (
        <Text as='span' textStyle='bodyMdSemi' sx={{ ...customHelpText, ...animation }}>
          {texts.mandatoryDataNotice}
        </Text>
      ) : (
        <Skeleton style={customSkeletonHelpText} />
      )}
      <CustomModal
        isOpen={!!transferTypeChanged}
        Icon={IconBeware}
        onCancel={() => handleChangeTransferType(false)}
        firstDescription={texts.transferTypeChangedText}
        title={texts.transferTypeChangedTitle}
        enableFirstButton
        firstTextButton={texts.btnCancel}
        enableSecondButton
        secondTextButton={texts.btnConfirmChange}
        actionButton2={() => handleChangeTransferType(true)}
        alternativeButtonStyling
        size='xl'
        newStyle
      />
      <CustomModal
        isOpen={isBTOBProccesSelected}
        Icon={IconInfoPopup}
        title={modalTexts.title}
        firstDescription={modalTexts.subtitle}
        thirdDescription={modalTexts.thirdDescription}
        enableFirstButton
        firstTextButton={modalTexts.btnCancel}
        secondTextButton={modalTexts.btnConfirm}
        enableSecondButton
        onCancel={() => setIsBTOBProccesSelected(false)}
        actionButton2={() => onRedirectBTOBProcess()}
        alternativeButtonStyling
        secondButtonLink
        urlSecondLink={`${process.env.URL_BASE_SIB}${process.env.SIB_TRANSFERENCIAS_AUTORIZACIONES}`}
        size='xl'
        newStyle
      />
    </Flex>
  );
}

export default FormManualTransferSetup;
