/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Skeleton, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import RevokeTransfers from '@views/RevokeTransfers';
import { pushAnalyticsEvent } from '@wow/utils';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BaseScreen from '../../components/BaseScreen';
import Header from '../../components/Header';
import HomeTabs from '../../components/HomeTabs';
import IconBeware from '../../components/Icons/icon-beware';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import CustomModal from '../../components/Modal';
import OfferingAlert from '../../components/OfferingAlert';
import ViewNoResult from '../../components/ViewNoResult';
import { DataMessageErrorRequest, routesNames } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import { Permissions } from '../../Interfaces/api';
import { ServiceAction, UrlTransfers } from '../../Interfaces/transfers';
import { getPermissions } from '../../services/api';
import AnnulTransfers from '../AnnulTransfers';
import AuthenticationTransfers from '../AuthorizationTransfers';
import SendingTransfers from '../SendingTransfers';
import UnauthorizeTransfers from '../UnauthorizeTransfers';
import HEADER_TEXT, { EXCEPTION_CODES, TEXT_MODAL_ALERT } from './constants';
import WizardSteps from './WizardSteps';

interface Props {
  tabSetter?: (currentPermissions: number) => void;
}

function Transfers({ tabSetter }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    panelSelected,
    servicesWorking,
    hasData,
    lotsSelected,
    dataLot,
    isLoadingLots,
    setPanelSelected,
    clearSelectedLots,
    setSectionPermissions,
    setShowAsyncProcesses,
  } = useTransfersContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<number>(0);
  const [tabChanged, setTabChanged] = useState(false);

  const handleTabChange = (newIndex: number) => {
    setTabChanged(true);
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Tabs Transferencias',
      item_id: getTab(newIndex),
    });
    if (lotsSelected.length > 0 || dataLot.length > 0) {
      setOpenModal(true);
      setNextStep(newIndex);
    } else {
      setPanelSelected(newIndex);
      clearSelectedLots();
    }
  };

  const getTab = (newIndex: number): string => {
    switch (newIndex) {
      case 1:
        return 'Enviar';
      case 2:
        return 'Anular';
      case 3:
        return 'Quitar Autorización';
      case 4:
        return 'Revocar envío';
      case 0:
      default:
        return 'Autorizar';
    }
  };

  const handleCancel = () => {
    setNextStep(0);
    setOpenModal(false);
  };

  const handleChangeTabs = () => {
    setPanelSelected(nextStep);
    setNextStep(0);
    clearSelectedLots();
    setOpenModal(false);
  };

  const [showAUT, setShowAUT] = useState(false);
  const [showENV, setShowENV] = useState(false);
  const [showDES, setShowDES] = useState(false);
  const [showANU, setShowANU] = useState(false);
  const [showREV, setShowREV] = useState(false);

  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();
  const [isTimeOutTranferError, setIsTimeOutTranferError] = useState<boolean>(false);

  const { refetch: refetchPermissions, isFetching: isLoadingPermissions } = useQuery(
    ['get-permissions'],
    () => getPermissions().then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: (data: Permissions) => {
        if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
          setIsTimeOutTranferError(true);
        } else {
          setSectionPermissions(data?.data?.sections);
          const tabPermissions = [ServiceAction.AUT, ServiceAction.ENV, ServiceAction.ANU, ServiceAction.DES, ServiceAction.ENV];
          const permissionsChecks = tabPermissions.map(permission =>
            data?.data?.sections?.some(sectionItem => sectionItem?.section?.includes(permission)),
          );

          const [hasPermissionsAUT, hasPermissionsENV, hasPermissionsANU, hasPermissionsDES, hasPermissionsREV] = permissionsChecks;

          setShowAUT(hasPermissionsAUT);
          setShowENV(hasPermissionsENV);
          setShowANU(hasPermissionsANU);
          setShowDES(hasPermissionsDES);
          setShowREV(hasPermissionsREV);

          const panelSelections = permissionsChecks.indexOf(true);

          if (!permissionsChecks[panelSelected]) {
            setPanelSelected(panelSelections !== -1 ? panelSelections : 0);
          }
        }
      },
    },
  );

  useEffect(() => {
    setShowAsyncProcesses(false);
    refetchPermissions();
  }, []);

  useEffect(() => {
    tabSetter && tabSetter(panelSelected);
  }, [panelSelected]);

  useEffect(() => {
    const panelMapping = {
      [UrlTransfers.Authorize]: 0,
      [UrlTransfers.Send]: 1,
      [UrlTransfers.Annul]: 2,
      [UrlTransfers.Unauthorize]: 3,
      [UrlTransfers.Revoke]: 4,
    };

    const selectedPanel = panelMapping[searchParams?.get('tab') as UrlTransfers];
    if (selectedPanel !== undefined) {
      clearSelectedLots();
      navigate(routesNames.Root, { replace: true });
      setPanelSelected(selectedPanel);
    }
  }, [searchParams]);

  useEffect(() => {
    if (localStorage.getItem('tef-wizard-init') == null && hasData) {
      setTimeout(() => {
        setInitialized(true);
      }, 1000);
    }
  }, [hasData]);

  const handleFinishWizard = () => {
    localStorage.setItem('tef-wizard-init', 'true');
  };

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Servicio no disponible',
      content_type: 'No pudimos cargar los datos solicitados',
      item_id: 'Reintentar',
    });
  };

  return (
    <BaseScreen title={HEADER_TEXT.title} sizeTitle='h1' id='titleTef'>
      <>
        {initialized && (
          <WizardComponent
            id='tef-wizard-init'
            initialized={initialized}
            setInitialized={setInitialized}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={WizardSteps}
            width={300}
            height={250}
            offset={0}
            onFinish={handleFinishWizard}
          />
        )}
        <Header description={HEADER_TEXT.description} />
        {isTimeOutTranferError ? (
          <ViewNoResult
            title={DataMessageErrorRequest.title}
            description={DataMessageErrorRequest.description}
            linkName={DataMessageErrorRequest.linkName}
            Icon={IconDisconnected}
            handleToggleClick={handleToggleAnalytics}
          />
        ) : (
          <Tabs size='lg' index={panelSelected} isLazy onChange={handleTabChange}>
            {isLoadingPermissions || (!tabChanged && isLoadingLots) ? (
              <Skeleton width='100%' height='56px' borderRadius='4px' margin='0 0 24px 0' data-testid='skeleton-component' />
            ) : servicesWorking ? (
              <HomeTabs showAUT={showAUT} showENV={showENV} showDES={showDES} showANU={showANU} showREV={showREV} />
            ) : null}
            <OfferingAlert />
            <TabPanels>
              <TabPanel>
                <AuthenticationTransfers />
              </TabPanel>
              <TabPanel>
                <SendingTransfers />
              </TabPanel>
              <TabPanel>
                <AnnulTransfers />
              </TabPanel>
              <TabPanel>
                <UnauthorizeTransfers />
              </TabPanel>
              <TabPanel>
                <RevokeTransfers />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </>
      <CustomModal
        isOpen={openModal}
        onCancel={setOpenModal}
        Icon={IconBeware}
        enableFirstButton
        altIcon={TEXT_MODAL_ALERT.altIcon}
        title={TEXT_MODAL_ALERT.title}
        secondTextButton={TEXT_MODAL_ALERT.secondTextButton}
        firstTextButton={TEXT_MODAL_ALERT.firstTextButton}
        firstDescription={TEXT_MODAL_ALERT.firstDescription}
        actionButton2={handleChangeTabs}
        actionButton={handleCancel}
        size='xl'
        enableSecondButton
        alternativeButtonStyling
        cancelType
      />
      <style>{`body.wizard--active-tef-wizard-init .wizard-tef-wizard-init .tooltip-tef-wizard-init { z-index: 102; }`}</style>
    </BaseScreen>
  );
}

export default Transfers;
