const IDS = {
  filterAccountsDrawer: 'filterAccountsDrawer',
  btnClear: 'btnClear',
  btnApply: 'btnApply',
};

const TEXTS = {
  delete: 'Borrar',
  apply: 'Aplicar',
};

export { IDS, TEXTS };
