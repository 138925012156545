import { colors } from '../../../../styled/generalStyles';

const InfoItem = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '16px',
};

const InfoItemHasDivider = {
  borderLeft: `1px solid ${colors.Neutral90}`,
  paddingLeft: '24px',
};

const InfoIcon = {
  width: '18px',
  height: '18px',
  marginRight: '4px',
};

export { InfoIcon, InfoItem, InfoItemHasDivider };
