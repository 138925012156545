const SERVICE_INTERVAL = 10000;

const TEXTS = {
  titleInProgress: 'Seguí el proceso de tus transferencias',
  titleResult: 'Revisá el resultado de tus transferencias',
  viewProcess: 'Ver proceso',
  viewResult: 'Ver resultado',
};

const TEST_IDS = {
  processes: 'processes',
  btnViewResult: 'btnViewResult',
};

export { SERVICE_INTERVAL, TEST_IDS, TEXTS };
