import { Box, Checkbox, Flex, Input, InputGroup, InputLeftElement, Text, Tooltip } from '@chakra-ui/react';
import { SelectOption } from '@interfaces/transferSetup';
import { checkIfArraysAreDifferent } from '@utils/checkIfArrayAreEqual';
import { sortAndCombineOptions } from '@utils/sortAndCombineOptions ';
import { ChangeEvent, useEffect, useState } from 'react';
import { Search } from 'react-feather';

import FilterMenu from '../FilterMenu';
import { IDS, TEXTS } from './constants';
import { Props } from './interfaces';
import { CheckContainer, CustomBox, CustomCheckBox, CustomEmptyText, CustomInput, CustomText } from './styled';

function FilterItem({ filterType, isLoading, options = [], appliedOptions = [], isEmptyResult, setAppliedOptions, onApplyFilters }: Props) {
  const [optionsList, setOptionsList] = useState<SelectOption[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const isDisabledApplyButton = checkIfArraysAreDifferent(selectedOptions, appliedOptions);
  const isMenuDisabled = isLoading || (isEmptyResult && !appliedOptions.length);

  useEffect(() => {
    setOptionsList(sortAndCombineOptions(options, appliedOptions));
  }, [options, appliedOptions]);

  useEffect(() => {
    if (searchValue) {
      const filteredOptions = options.filter(optionItem => optionItem.label.toLowerCase().includes(searchValue.toLowerCase()));
      setOptionsList(filteredOptions);
    } else {
      options.length && setOptionsList(sortAndCombineOptions(options, appliedOptions));
    }
  }, [searchValue]);

  useEffect(() => {
    setIsSelected(appliedOptions.length > 0);
  }, [selectedOptions, appliedOptions]);

  const handleClose = () => {
    setSearchValue('');
    if (appliedOptions?.length) {
      setSelectedOptions(appliedOptions);
    } else {
      setSelectedOptions([]);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e?.target?.value);
  };

  const handleCheckboxChange = (newOption: string) => {
    if (selectedOptions.includes(newOption)) {
      setSelectedOptions(selectedOptions.filter(selectedOptionItem => selectedOptionItem !== newOption));
    } else {
      setSelectedOptions([...selectedOptions, newOption]);
    }
  };

  const handleClear = () => {
    setSelectedOptions([]);
  };

  const handleApply = () => {
    setAppliedOptions(selectedOptions);
    setSearchValue('');
    onApplyFilters();
  };

  return (
    <FilterMenu
      tag={TEXTS[filterType].tag}
      title={TEXTS[filterType].title}
      count={appliedOptions?.length}
      btnClearIsDisabled={!selectedOptions?.length}
      btnApplyIsDisabled={isDisabledApplyButton}
      handleApply={handleApply}
      handleClear={handleClear}
      handleClose={handleClose}
      filterTestId={IDS[filterType].filter}
      isSelected={isSelected}
      isDisabled={isMenuDisabled}
    >
      <Box sx={CustomBox}>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Search size={24} />
          </InputLeftElement>
          <Input
            sx={CustomInput}
            data-testid={IDS[filterType].input}
            name={IDS[filterType].input}
            onChange={handleInputChange}
            value={searchValue}
            placeholder={TEXTS.placeholder}
          />
        </InputGroup>
        <Flex sx={CheckContainer}>
          {optionsList.map(({ value, label }) => (
            <Checkbox
              key={value}
              isChecked={selectedOptions?.includes(value)}
              onChange={() => handleCheckboxChange(value)}
              sx={CustomCheckBox}
            >
              {label.length > 36 ? (
                <Tooltip hasArrow placement='left' label={label} marginRight='42px'>
                  <Text sx={CustomText}>{label}</Text>
                </Tooltip>
              ) : (
                <Text sx={CustomText}>{label}</Text>
              )}
            </Checkbox>
          ))}
        </Flex>
      </Box>
      {!optionsList.length && <Text sx={CustomEmptyText}>{TEXTS.empty}</Text>}
    </FilterMenu>
  );
}

export default FilterItem;
