const applyCurrencyFormatFromPaste = (amountInputString: string): string => {
  const amount = amountInputString.trim();
  if (!amount) return '';
  const dotCount = (amount.match(/\./g) || []).length;
  const commaCount = (amount.match(/,/g) || []).length;

  let integerPart = '';
  let decimalPart = '';

  if (/^(?!0\d)\d{1,3}(?:\.\d{3})*(?:,\d{2})$/.test(amount)) {
    [integerPart, decimalPart] = amount.split(',');
    integerPart = integerPart.replace(/\./g, '');
  } else if (dotCount === 1 && commaCount === 0) {
    [integerPart, decimalPart] = amount.split('.');
  } else if (commaCount === 1 && dotCount === 0) {
    [integerPart, decimalPart] = amount.split(',');
  } else if (commaCount > dotCount) {
    [integerPart, decimalPart] = amount.split('.');
    integerPart = integerPart.replace(/,/g, '');
  } else {
    integerPart = amount;
  }

  integerPart = integerPart ? integerPart.replace(/\D/g, '').slice(0, 14) : '0';
  if (integerPart) {
    integerPart = Number(integerPart).toLocaleString('es-AR');
  }

  if (decimalPart) {
    decimalPart = `,${decimalPart.replace(/\D/g, '').slice(0, 2).padEnd(2, '0')}`;
  } else {
    decimalPart = '';
  }

  return `${integerPart}${decimalPart}`;
};

export { applyCurrencyFormatFromPaste };
