export const USER_WITH_OTP = 'Y';
export const TEXT_BUTTON_AUTHORIZE = 'Autorizar';

export const LOADING_SEND_TRANSFERS = {
  title: 'Estamos enviando las transferencias',
  description: 'Esto puede demorar unos instantes.',
};

export const TITLE_TOOLTIP_UNIFIED_SHIPPING = 'Lote con envío unificado:';

export const TEXT_TOOLTIP_UNIFIED_SHIPPING = 'Si tiene más de\nuna cuenta de débito, enviá todos los lotes\njuntos.';

export const TEXT_MODAL_FAILED_AUTHORIZE = {
  title: 'No podemos autorizar las transferencias',
  firstDescription: '¡No te preocupes! Las transferencias seleccionadas se mantienen, solo tenés que volver a intentarlo.',
  altIcon: 'icon-cancel-modal',
  textButtom: 'Reintentar',
  cancelButton: 'Cancelar',
};

export const TEXT_MODAL_RESPONSE_CERO_AUTHORIZE = {
  title: 'No podemos autorizar las transferencias',
  firstDescription: (
    <>
      Las transferencias ya no están disponibles, revisá el estado en la sección <strong>Reportes.</strong>
    </>
  ),
  altIcon: 'icon-cancel-modal',
  textButtom: 'Entendido',
};

export const ALERT_SUE_TEXT = {
  title: 'El importe no incluye el total de transferencias',
  description: 'En función de los permisos de tu perfil no podés consultar el monto total a transferir.',
};

export const TRANSFERS_STATUS = ['TEF-STA001', 'TEF-STA002'];

export const ALL_CODE_STATUS_TRANSFERS = {
  CON: 'CON',
  EFR: 'EFR',
  FIR: 'FIR',
  DPD: 'DPD',
};
export const ALL_STATUS_TRANSFERS = {
  [ALL_CODE_STATUS_TRANSFERS.CON]: 'Confeccionada',
  [ALL_CODE_STATUS_TRANSFERS.EFR]: 'En autorización',
  [ALL_CODE_STATUS_TRANSFERS.FIR]: 'Autorizada',
  [ALL_CODE_STATUS_TRANSFERS.DPD]: 'Recibida por la red',
};

export const DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH = 400;

export const TEXT_MODAL_FAILED_SEND_AUTHORIZE = {
  title: 'No podemos autorizar las transferencias',
  firstDescription: 'Intentá de nuevo más tarde.',
  altIcon: 'icon-error-modal',
  textButtom: 'Entendido',
  cancelButton: 'Entendido',
};

export const LOADING_SCREEN_TEXT_SEND_AUTHORIZE = {
  title: 'Estamos autorizando las transferencias',
};

export const TEXT_MODAL_SUCCESS = {
  title: 'Transferencias autorizadas',
  altIcon: 'icon-success-modal',
  textButtom: 'Entendido',
};

export const EXCEPTION_CODES = {
  satisfactoryProcess: 'TEF-0000',
  totalReject: 'TEF-0005',
  parcialReject: 'TEF-0004',
  parcialValidation: 'TEF-0029',
  parcialOperation: 'TEF-0032',
  asyncProcess: 'TEF-0008',
  timeOut: 'TEF-0015',
};
