/* eslint-disable global-require */
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { OpenLink } from '@wow/utils';
import { useEffect } from 'react';

type Props = {
  setShowBottomNav: (arg: boolean) => void;
  text: string;
};

function RedirectView({ setShowBottomNav, text }: Props) {
  useEffect(() => {
    setShowBottomNav(false);
  }, []);

  return (
    <Flex flexDirection='column' alignItems='center' width='100%'>
      <Image alt='Icon-redirect' src={require('../../../../../assets/export.svg')} width='66px' height='72px' />
      <Text textStyle='h4Bold' marginTop='16px'>
        Tenés que ir a la plataforma anterior para hacer este tipo de confección
      </Text>
      <Text textStyle='bodyLg' marginTop='8px' as='span'>
        {`Por el momento la confección de transferencias desde ${text} se hace en la plataforma anterior.`}
      </Text>
      <Text textStyle='bodyLg' marginBottom='24px' as='span'>
        Estamos trabajando para que pronto puedas hacerlo acá.
      </Text>
      <OpenLink openMode='newTab' url={`${process.env.URL_BASE_SIB}${process.env.SIB_TRANSFERENCIAS_AUTORIZACIONES}`} isRouter={false}>
        <Button variant='primary' size='md'>
          Ir a plataforma anterior
        </Button>
      </OpenLink>
    </Flex>
  );
}
export default RedirectView;
