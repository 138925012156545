/* eslint-disable import/prefer-default-export */

import { LotSelected } from '../Interfaces/contextTypes';
import { UNIFIED_SHIPPING_MARK } from '../views/LotDetail/constants';

export const filterByUnifiedShippingY = (data: LotSelected[]): number[] => {
  const filteredData = data.filter((entry: LotSelected) => entry.unifiedShipping === UNIFIED_SHIPPING_MARK);
  const lotNumbers = new Set<number>();
  filteredData.forEach((entry: LotSelected) => {
    if (entry.lotNumber) {
      lotNumbers.add(entry.lotNumber);
    }
  });

  return Array.from(lotNumbers);
};
