import { colors } from '../../../../../../styled/generalStyles';

const Container = {
  td: {
    padding: '12px 0',
    borderBottom: `1px solid ${colors.Grey60}`,
  },
  'td:first-of-type': {
    paddingLeft: '16px',
  },
  'td:last-of-type': {
    paddingRight: '24px',
  },
};

export { Container };
