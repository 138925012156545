/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ACTION_TYPE, routesNames } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { PostDataTransfersAction } from '../Interfaces/api';
import { ResponseDataLotAndTransfers } from '../Interfaces/contextTypes';
import { Lot, Pagination as PaginationInterfaces, ServiceAction, TransfersFlow } from '../Interfaces/transfers';
import { LocationState } from '../Interfaces/utils/reactRouter';
import { getDetailsOfAllTransfers, getLots, postTransfersAction } from '../services/api';
import { getServiceParams } from '../utils/configTableFunctions';
import { formatSelectedTransfersTypesCodes } from '../utils/formatSelectedTransfersTypesCodesParam';
import sortDataByGetTransfers from '../utils/sortDataByGetTransfers';
import { PATHNAME_LOT_DETAIL } from '../views/EntityKey/constants';
import { EXCEPTION_CODES, UNAUTHORIZE_ACTION, UNAUTHORIZE_ERROR } from '../views/UnauthorizeTransfers/constants';
import useAsyncProcess from './useAsyncProcess';

export default function useUnauthorizeTransfers() {
  const location = useLocation() as LocationState;
  const navigate = useNavigate();
  const {
    pageContext,
    sizeContext,
    lotsSelected,
    dataLot,
    dataDetailsTransfers,
    servicesWorking,
    orderPropertyContext,
    selectedTransfersTypesCodes,
    amountRanges,
    setPageContext,
    setSizeContext,
    setServicesWorking,
    setDataDetailsTransfers,
    setDataTotalsTransfers,
    setIsBusinessDay,
    setHasNextTefDay,
    clearSelectedLots,
    setIsFullSelection,
    setTransfersAccepted,
    setTransfersRejected,
    setHasData,
    setShowAsyncProcesses,
    setIsLoadingLots,
  } = useTransfersContext();

  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [secondTime, setSecondTime] = useState<boolean>(false);
  const [lots, setLots] = useState<Lot[]>([]);
  const [pagination, setPagination] = useState<PaginationInterfaces>({
    totalElements: 0,
    totalPages: 0,
    totalElementsRequested: 0,
    pageNumber: 0,
  });
  const [lotNumber, setLotNumber] = useState<number[]>([]);
  const [tableDataState, setTableDataState] = useState([]);
  const [postDataGetDetails, setPostDataGetDetails] = useState<ResponseDataLotAndTransfers[]>([]);
  const [action, setAction] = useState<UNAUTHORIZE_ACTION>(UNAUTHORIZE_ACTION.NONE);
  const [error, setError] = useState<UNAUTHORIZE_ERROR>(UNAUTHORIZE_ERROR.NONE);
  const [success, setSuccess] = useState(false);
  const [processId, setProcessId] = useState<number | null>(null);
  const [isReload, setIsReload] = useState(false);

  const reload = () => {
    setLotNumber([]);
    setLots([]);
    setPageContext(1);
    setSizeContext(15);
    clearSelectedLots();
    setFirstTime(false);
    setSecondTime(false);
    setIsReload(true);
    refetchGetLots();
    setAction(UNAUTHORIZE_ACTION.NONE);
  };

  const {
    isAsyncProcess,
    isAsyncProcessDone,
    isAsyncProcessError,
    isFetchingAsyncProcess,
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    setIsAsyncProcessError,
    setRetries,
    setCloseModalCompletely,
  } = useAsyncProcess(processId, ServiceAction.DES, TransfersFlow.Unauthorize, setSuccess);

  const fromLotDetail = location.state?.from?.pathname.includes(PATHNAME_LOT_DETAIL);

  const params = {
    size: String(sizeContext),
    page: String(pageContext),
    lotNumber: lotNumber.length > 0 ? lotNumber.join(';') : null,
    transferType: formatSelectedTransfersTypesCodes(selectedTransfersTypesCodes),
    ...getServiceParams(TransfersFlow.Unauthorize),
    orderProperty: orderPropertyContext,
    ...amountRanges,
    action: ACTION_TYPE.QFI,
  };

  const {
    refetch: refetchGetLots,
    status,
    isFetching,
  } = useQuery(['get-lots-transfers', params], () => getLots(params).then(res => res.data), {
    retry: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
        setServicesWorking(false);
      } else {
        setServicesWorking(true);
        setLots(data?.data?.lots);
        setPagination(data?.paging);
        setTableDataState(data);
        setHasData(data?.data?.lots.length > 0);
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Transferencias - Quitar autorización',
        });
        if (data?.data?.lots.length < 1) {
          pushAnalyticsEvent({
            event: 'trackContentGroup',
            content_group: 'Transferencias - Quitar autorización - Sin TEFs pendientes',
          });
        }
      }
      setFirstTime(true);
    },
    onError: () => {
      setServicesWorking(false);
    },
  });

  const onCloseModalErrorZero = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos quitar tu autorización - No disponibles',
      item_id: 'Entendido',
    });
    setError(UNAUTHORIZE_ERROR.NONE);
    reload();
  };

  const onCloseModalSuccess = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal autorizaciones quitadas',
      item_id: 'Entendido',
    });
    setSuccess(false);
  };

  const onCloseModalAsyncProcess = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Quitando autorización de transferencias',
      item_id: 'Volver a transferencias',
    });
    setIsAsyncProcess(false);
    setCloseModalCompletely(true);
    reload();
  };

  const onCloseModalAsyncProcessDone = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Estamos quitando tu autorización',
      item_id: 'Entendido',
    });
    setRetries(1);
    setIsAsyncProcessDone(false);
    reload();
  };

  const onCloseModalAsyncProcessError = () => {
    setIsAsyncProcessError(false);
    reload();
  };

  const { refetch: refetchGetDetailsOfAllTransfers, isFetching: isFetchingGetDetailsOfAllTransfers } = useQuery(
    ['get-details-all-transfers', postDataGetDetails],
    () => getDetailsOfAllTransfers(postDataGetDetails, ServiceAction.DES).then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
          setAction(UNAUTHORIZE_ACTION.NONE);
          setError(UNAUTHORIZE_ERROR.UNAUTHORIZE);
        } else {
          setDataDetailsTransfers(data?.data?.tranfers);
          setDataTotalsTransfers(data?.data?.totalAmount);
          setIsBusinessDay(data?.data?.isBusinessDay);
          setHasNextTefDay(data?.data?.hasNextTefDay);
          if (data?.data?.tranfers.length === 0) {
            setError(UNAUTHORIZE_ERROR.ZERO);
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'No podemos quitar tu autorización - No disponibles',
            });
          } else {
            setAction(UNAUTHORIZE_ACTION.UNAUTHORIZE);
          }
        }
      },
      onError: () => {
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos quitar tu autorización - Error servicio',
        });
        setAction(UNAUTHORIZE_ACTION.NONE);
        setError(UNAUTHORIZE_ERROR.UNAUTHORIZE);
      },
    },
  );

  const { mutate: mutationPostTransfersAction, isLoading: isFetchingUnauthorize } = useMutation(
    (data: PostDataTransfersAction) => {
      return postTransfersAction(ServiceAction.DES, data).then(res => res.data);
    },
    {
      onSuccess: response => {
        const exceptionCode = response?.exception.code;
        if (EXCEPTION_CODES.success === exceptionCode) {
          pushAnalyticsEvent({
            event: 'autorización_transferencia_quitada',
            content_group: 'Transferencias - Resumen de quitar autorización',
            status: 'Autorizaciones quitadas exitosamente',
          });
          setSuccess(true);
        } else if (EXCEPTION_CODES.partialRejection === exceptionCode) {
          pushAnalyticsEvent({
            event: 'autorización_transferencia_quitada',
            content_group: 'Transferencias - Resumen de quitar autorización',
            status: 'Quita parcial',
          });
          setTransfersRejected(response.data.transfersRejected);
          setTransfersAccepted(response.data.transfersAccepted);
          navigate(routesNames.ResultUnauth);
        } else if (EXCEPTION_CODES.totalRejection === exceptionCode) {
          pushAnalyticsEvent({
            event: 'autorización_transferencia_quitada',
            content_group: 'Transferencias - Resumen de quitar autorización',
            status: 'Rechazo total',
          });
          setTransfersRejected(response.data.transfersRejected);
          navigate(routesNames.ResultUnauth);
        } else if (EXCEPTION_CODES.asyncProcess === exceptionCode) {
          setProcessId(response.data.processId);
        } else if (EXCEPTION_CODES.timeOut === exceptionCode) {
          pushAnalyticsEvent({
            event: 'modal_impression',
            modal_name: 'Estamos quitando tu autorización',
          });
          setIsAsyncProcessDone(true);
        } else {
          setAction(UNAUTHORIZE_ACTION.NONE);
          setError(UNAUTHORIZE_ERROR.UNAUTHORIZE);
        }
      },
      onError: () => {
        setAction(UNAUTHORIZE_ACTION.NONE);
        setError(UNAUTHORIZE_ERROR.UNAUTHORIZE);
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos quitar tu autorización - Error servicio',
        });
      },
    },
  );

  const onFilterLot = (numberLot: number[]) => {
    setPageContext(1);
    setLotNumber(numberLot);
  };

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Quitar autorización - Servicio no disponible',
      content_type: 'Error de servicio',
      item_id: 'Reintentar',
    });
  };

  const onClickRetry = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos quitar tu autorización - Error servicio',
      item_id: 'Reintentar',
    });
    setError(UNAUTHORIZE_ERROR.NONE);
    setAction(UNAUTHORIZE_ACTION.GET_DETAILS);
  };

  const onCancel = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos quitar tu autorización - Error servicio',
      item_id: 'Cancelar',
    });
    setError(UNAUTHORIZE_ERROR.NONE);
  };

  const onClickUnauthorize = () => {
    setError(UNAUTHORIZE_ERROR.NONE);
    setPostDataGetDetails(sortDataByGetTransfers(lotsSelected, dataLot));
    setAction(UNAUTHORIZE_ACTION.GET_DETAILS);
    pushAnalyticsEvent({
      event: 'quitar_autorización_transferencias',
      content_type: 'Botones Transferencias',
      item_id: 'Quitar autorización',
    });
  };

  const isLoading = isFetching || isFetchingGetDetailsOfAllTransfers;
  const hasError = status === 'error' || !servicesWorking;

  useEffect(() => {
    if (!isLoading) {
      setShowAsyncProcesses(!hasError);
    }
  }, [isLoading, hasError]);

  useEffect(() => {
    const lotsSelectedFullSelection = lotsSelected.filter(lotItem => !lotItem.isPartialSelection);
    const rowSelected = lotsSelectedFullSelection.map(lotItem => ({
      [lotItem.id]: true,
    }));
    setIsFullSelection(rowSelected);
  }, [lotsSelected]);

  useEffect(() => {
    if (action === UNAUTHORIZE_ACTION.UNAUTHORIZE) {
      const transfers = dataDetailsTransfers.map(item => ({
        orderId: item.transfer,
        transferTypeCode: item.type,
      }));
      mutationPostTransfersAction({ transfers });
    }
  }, [action]);

  useEffect(() => {
    if (action === UNAUTHORIZE_ACTION.GET_DETAILS) {
      refetchGetDetailsOfAllTransfers();
    }
  }, [action]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Transferencias',
    });
    if (fromLotDetail) {
      setPageContext(pageContext);
    }
    refetchGetLots();
  }, []);

  useEffect(() => {
    success && reload();
  }, [success]);

  useEffect(() => {
    setSecondTime(!isFetching);
    setIsLoadingLots(!firstTime && !isReload ? isFetching : false);
  }, [firstTime, isFetching, isReload]);

  return {
    error,
    isFetchingUnauthorize,
    isFetching,
    isFetchingGetDetailsOfAllTransfers,
    lots,
    pageContext,
    pagination,
    status,
    sizeContext,
    success,
    tableDataState,
    isAsyncProcess,
    isAsyncProcessDone,
    isAsyncProcessError,
    isFetchingAsyncProcess,
    servicesWorking,
    lotNumber,
    firstTime,
    secondTime,
    handleToggleAnalytics,
    onFilterLot,
    onClickRetry,
    onClickUnauthorize,
    onCloseModalErrorZero,
    onCloseModalSuccess,
    onCloseModalAsyncProcess,
    onCloseModalAsyncProcessDone,
    onCloseModalAsyncProcessError,
    onCancel,
    setPageContext,
    setSizeContext,
  };
}
