import { Button, Collapse, Flex, IconButton, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { useState } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'react-feather';

import CustomSummary from '../CustomSummary';
import { IDS } from './constants';
import { InterfacesPropsBottomNav } from './interfaces';
import {
  buttonMarginStyles,
  container,
  CustomBoxFooter,
  flexCustomInfoTransfer,
  flexCustomInfoTransferWithOutInfo,
  flexLeftStyle,
  flexRightStyle,
} from './styled';

function BottomNav({
  handleGoBack,
  textButtonGoBack,
  textButtonPrimaryText,
  clickButtonPrimaryText,
  statusButtonPrimaryText,
  activeIconButtonPrimaryText = false,
  textButtonPrimaryOutLineText,
  activeIconTextButtonPrimaryOutLineText = false,
  showSteps,
  currentStep,
  showTextTransferType,
  showTextTransferTypeLoading = false,
  textTransferType,
  collapsableTransferType = true,
  lotNumber,
  customSummary,
  showCustomSummary,
  handleFourthButton,
  drawerNav,
  size,
  buttonPrimaryOutLineDisabled,
  buttonPrimaryDisabled,
  isWizardActive,
  textButtonprimaryLink,
  buttontextButtonprimaryLinkDisabled,
  handleButtonprimaryLink,
}: InterfacesPropsBottomNav) {
  const [openTransferInf, setOpenTransferInf] = useState<boolean>(false);
  const { isOpen, onToggle } = useDisclosure();

  const handleClickOpenInfoTransfer = () => {
    onToggle();
    setOpenTransferInf(!openTransferInf);
  };

  return (
    <CustomBoxFooter drawerNav={!!drawerNav} openTransferInf={openTransferInf} isWizardActive={isWizardActive}>
      <Flex id={IDS.bottomNav} style={container}>
        <Flex sx={flexLeftStyle(!!drawerNav)}>
          {textButtonGoBack && (
            <Button variant='primary-text' size='md' onClick={handleGoBack} leftIcon={<ChevronLeft width='20px' />}>
              {textButtonGoBack}
            </Button>
          )}
          {showTextTransferType && (
            <Flex>
              {showTextTransferTypeLoading ? (
                <Skeleton width='145px' height='16px' borderRadius='4px' />
              ) : (
                <Flex sx={flexCustomInfoTransfer}>
                  <Flex sx={flexCustomInfoTransferWithOutInfo}>
                    <Text
                      as='span'
                      sx={buttonMarginStyles}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(
                          `${
                            lotNumber ? `<strong>Lote ${lotNumber}</strong>` : ''
                          } Tipo de transferencia: <strong>${textTransferType}</strong>`,
                        ),
                      }}
                    />
                    {collapsableTransferType && (
                      <IconButton
                        aria-label='Search Icon'
                        icon={openTransferInf ? <ChevronUp /> : <ChevronDown />}
                        id='icon-button-id'
                        onClick={handleClickOpenInfoTransfer}
                        size='i-sm'
                        variant='icon-text'
                      />
                    )}
                  </Flex>
                  {customSummary ? (
                    <Collapse in={isOpen} animateOpacity>
                      <CustomSummary
                        totalTransfersArs={customSummary?.ARS.items}
                        totalAmountArs={customSummary?.ARS.amount}
                        totalTransfersUsd={customSummary?.USD.items}
                        totalAmountUsd={customSummary?.USD.amount}
                        id='authSummary'
                      />
                    </Collapse>
                  ) : null}
                </Flex>
              )}
            </Flex>
          )}
          {showCustomSummary && customSummary && (
            <CustomSummary
              totalTransfersArs={customSummary?.ARS.items}
              totalAmountArs={customSummary?.ARS.amount}
              totalTransfersUsd={customSummary?.USD.items}
              totalAmountUsd={customSummary?.USD.amount}
              id='authSummary'
            />
          )}
        </Flex>
        <Flex sx={flexRightStyle(!!drawerNav)}>
          {showSteps && (
            <Text as='span' sx={buttonMarginStyles} marginRight='16px'>
              {`Paso ${currentStep} de 3`}
            </Text>
          )}
          {textButtonprimaryLink && (
            <Button
              variant='primary-text'
              size={size}
              type='submit'
              onClick={handleButtonprimaryLink}
              isDisabled={buttontextButtonprimaryLinkDisabled}
              style={{ marginRight: '4px' }}
            >
              {textButtonprimaryLink}
            </Button>
          )}
          {textButtonPrimaryOutLineText && (
            <Button
              variant='primary-outline'
              size={size}
              type='submit'
              onClick={handleFourthButton}
              rightIcon={activeIconTextButtonPrimaryOutLineText ? <ChevronRight width='20px' /> : undefined}
              isDisabled={buttonPrimaryOutLineDisabled}
            >
              {textButtonPrimaryOutLineText}
            </Button>
          )}
          {textButtonPrimaryText && (
            <Button
              variant='primary'
              size={size}
              type='submit'
              onClick={clickButtonPrimaryText}
              isDisabled={statusButtonPrimaryText || buttonPrimaryDisabled}
              marginLeft='16px'
              rightIcon={activeIconButtonPrimaryText ? <ChevronRight width='20px' /> : undefined}
            >
              {textButtonPrimaryText}
            </Button>
          )}
        </Flex>
      </Flex>
    </CustomBoxFooter>
  );
}

export default BottomNav;
