const INPUT_BACKSPACE = 'Backspace';
const INPUT_DELETE = 'Delete';
const autoNumericConfig = {
  decimalCharacter: ',',
  digitGroupSeparator: '.',
  maximumValue: '99999999999999.99',
  modifyValueOnWheel: false,
  onInvalidPaste: 'ignore',
  minimumValue: '0',
};

export { autoNumericConfig, INPUT_BACKSPACE, INPUT_DELETE };
