import { Flex, Td, Text, Tr } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

import BankLogo from '../../../../../../components/BankLogo';
import { formatAmount } from '../../../../../../utils/formatAmount';
import { testIds, texts } from '../../constants';
import { formatAccountData, formatAccountName } from './helpers';
import { Container } from './index.styled';
import { Props } from './interfaces';

function SummaryItem({ data, index }: Props) {
  return (
    <Tr sx={Container} data-testid={`${testIds.summaryItem}${index + 1}`}>
      <Td width='42%'>
        <Flex direction='row' alignItems='center'>
          <BankLogo src={data.debitAccount?.bank.bcoIdBco} transferType={data.transferType} />
          <Flex direction='column'>
            <Text as='span' textStyle='h5Bold'>
              {data.debitAccount?.bank.bankName}
            </Text>
            <Text as='span' textStyle='bodyMd'>
              {formatAccountName(data.debitAccount?.accountName, data.debitAccount?.cuit)}
              <br />
              {formatAccountData(data.debitAccount?.cbu, data.debitAccount?.accountType)}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td width='20%'>
        <Flex direction='column'>
          <Text as='span' textStyle='labelLgBold'>
            {format(parseISO(data.date), 'dd/MM/yyyy')}
          </Text>
          <Text as='span' textStyle='bodyMd'>
            {texts.date}
          </Text>
        </Flex>
      </Td>
      <Td width='20%'>
        <Flex direction='column'>
          <Text as='span' textStyle='labelLgBold'>
            {texts.transfers(data.totalItems)}
          </Text>
        </Flex>
      </Td>
      <Td width='18%'>
        <Flex direction='column'>
          <Text as='span' textStyle='labelLgBold'>
            {formatAmount(data.currency, data.totalAmount, '')}
          </Text>
          <Text as='span' textStyle='bodyMd'>
            {texts.total}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}

export default SummaryItem;
