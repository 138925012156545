import { colors } from '../../../../../../styled/generalStyles';

const transferItemContainer = {
  paddingLeft: '24px',
  '&:not(:first-of-type)>div': {
    borderTop: `1px solid ${colors.Grey60}`,
  },
};

const transferItemContainerActive = {
  backgroundColor: colors.Primary60,
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${colors.Grey60}`,
  },
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${colors.Grey60}`,
  },
  '&:not(:first-of-type)>div, &+div:not(:first-of-type)>div': {
    borderTop: '0',
  },
};

const transferItem = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px',
};

export { transferItem, transferItemContainer, transferItemContainerActive };
