export const actionsTypes = {
  SET_STATE: 'SET_STATE',
  CLEAR_SELECTED_LOTS: 'CLEAR_SELECTED_LOTS',
  CLEAR_CONFECTION: 'CLEAR_CONFECTION',
  CLEAR_DRAWER_CONFECTION_FILTERS_APPLIED: 'CLEAR_DRAWER_CONFECTION_FILTERS_APPLIED',
};

export const targets = {
  pageContext: 'pageContext',
  sizeContext: 'sizeContext',
  lotSelectionHandler: 'lotSelectionHandler',
  lotsSelected: 'lotsSelected',
  isFullSelection: 'isFullSelection',
  dataLot: 'dataLot',
  inputLot: 'inputLot',
  filtered: 'filtered',
  detailLotId: 'detailLotId',
  bankCodes: 'bankCodes',
  responseOfTransferSelectedByUser: 'responseOfTransferSelectedByUser',
  dataDetailsTransfers: 'dataDetailsTransfers',
  dataTotalsTransfers: 'dataTotalsTransfers',
  transfersRejected: 'transfersRejected',
  transfersAccepted: 'transfersAccepted',
  isAuthenticated: 'isAuthenticated',
  servicesWorking: 'servicesWorking',
  detailParams: 'detailParams',
  panelSelected: 'panelSelected',
  hasNextTefDay: 'hasNextTefDay',
  isBusinessDay: 'isBusinessDay',
  hasData: 'hasData',
  orderPropertyContext: 'orderPropertyContext',
  orderAppliedContext: 'orderAppliedContext',
  orderPropertyLotDetailContext: 'orderPropertyLotDetailContext',
  selectedTransfersTypesCodes: 'selectedTransfersTypesCodes',
  sectionPermissions: 'sectionPermissions',
  viewPartialRejecteAuth: 'viewPartialRejecteAuth',
  transferTypeAuthReadyToSend: 'transferTypeAuthReadyToSend',
  otp: 'otp',
  errorUseAuthPartial: 'errorUseAuthPartial',
  totalAmountPartialReject: 'totalAmountPartialReject',
  loadingPartialReject: 'loadingPartialReject',
  manualTransferData: 'manualTransferData',
  fileSelected: 'fileSelected',
  transferTypeOptionSelected: 'transferTypeOptionSelected',
  transferTypeByOperators: 'transferTypeByOperators',
  dateSelected: 'dateSelected',
  amountRanges: 'amountRanges',
  debitAccounts: 'debitAccounts',
  holidays: 'holidays',
  workingDay: 'workingDay',
  transferNumber: 'transferNumber',
  confectionData: 'confectionData',
  confectionReset: 'confectionReset',
  isExitConfectionModalOpen: 'isExitConfectionModalOpen',
  redirectPath: 'redirectPath',
  otpConfigured: 'otpConfigured',
  asyncProcesses: 'asyncProcesses',
  asyncProcessesLoading: 'asyncProcessesLoading',
  asyncProcessesError: 'asyncProcessesError',
  updateAsyncProcesses: 'updateAsyncProcesses',
  showAsyncProcesses: 'showAsyncProcesses',
  isReferenceActive: 'isReferenceActive',
  debitFiltersApplied: 'debitFiltersApplied',
  creditFiltersApplied: 'creditFiltersApplied',
  statusButtonModify: 'statusButtonModify',
  isLoadingLots: 'isLoadingLots',
};
