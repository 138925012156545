import { Flex } from '@chakra-ui/react';

import useOfferingAlert from '../../hooks/useOfferingAlert';
import OfferingAlertTypes from '../OfferingAlertTypes';

function OfferingAlert() {
  const { showOfferingAlert, offeringData, onCloseAlert } = useOfferingAlert();

  return <Flex>{showOfferingAlert && <OfferingAlertTypes offeringData={offeringData} onCloseAlert={onCloseAlert} />}</Flex>;
}

export default OfferingAlert;
