import { AsyncConfectionResponseData, Permissions } from '@interfaces/api';
import { LotTransfer, ResultErrorCodeId } from '@interfaces/transferSetup';
import { getAsyncConfection, getParamsWorkingDay, getPermissions as getPermissionsApi } from '@services/api';
import { EXCEPTION_CODES, routesNames } from '@src/constants-app';
import { TRANSFER_TYPE } from '@views/TransferSetup/Steps/Step2/constants';
import { mapAsyncTransfers, mapValidatedAsyncTransfers } from '@views/TransferSetup/Steps/Step4/helpers';
import { addDays, format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTransfersContext } from '../context/TransfersContext';

export function useTransferSetupStep4() {
  const {
    manualTransferData,
    confectionData,
    workingDay,
    setSectionPermissions,
    setWorkingDay,
    setManualTransferData,
    clearConfection,
    setConfectionReset,
  } = useTransfersContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [isListLoading, setIsListLoading] = useState(true);
  const [transfersList, setTransfersList] = useState<LotTransfer[]>([]);
  const [currentDate, setCurrentDate] = useState<string | null>(null);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [asyncConfection, setAsyncConfection] = useState<AsyncConfectionResponseData | null>(null);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(false);
  const [isPermissionsError, setIsPermissionsError] = useState(false);
  const [isWorkingDayLoading, setIsWorkingDayLoading] = useState(false);
  const [isWorkingDayError, setIsWorkingDayError] = useState(false);
  const [isAsyncConfectionLoading, setIsAsyncConfectionLoading] = useState(false);
  const [isAsyncConfectionError, setIsAsyncConfectionError] = useState(false);

  const isRoute = location?.pathname === routesNames.ConfectionInvalidDate;
  const processId = location?.state?.processId || '';
  const transferType = asyncConfection ? TRANSFER_TYPE[asyncConfection.typeTefs] : TRANSFER_TYPE[manualTransferData.transferType];
  const formatCurrentDate = currentDate ? format(parseISO(currentDate), 'dd/MM/yyyy') : '';
  const nextDate = workingDay ? workingDay?.nextWorkingDay : format(addDays(new Date(), 1), 'yyyy-MM-dd');
  const formatNextDate = nextDate ? format(parseISO(nextDate), 'dd/MM/yyyy') : '';
  const isLoading = isListLoading || isPermissionsLoading || isWorkingDayLoading || isAsyncConfectionLoading;
  const isError = isWorkingDayError || isPermissionsError || isAsyncConfectionError;

  const { refetch: fetchPermissions } = useQuery(['get-permissions-operators'], () => getPermissionsApi().then(res => res.data), {
    retry: 3,
    enabled: false,
    onSuccess: (responseData: Permissions) => {
      if (responseData?.exception?.code === EXCEPTION_CODES.success) {
        setSectionPermissions(responseData.data.sections);
        setIsPermissionsError(false);
      } else {
        setIsPermissionsError(true);
      }
      setIsPermissionsLoading(false);
    },
    onError: () => {
      setIsPermissionsError(true);
      setIsPermissionsLoading(false);
    },
  });

  const { refetch: fetchWorkingDay } = useQuery(['get-params-working-day'], () => getParamsWorkingDay()?.then(res => res.data), {
    retry: 3,
    enabled: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.success) {
        setWorkingDay(data?.data?.workingDay);
      } else {
        setIsWorkingDayError(true);
      }
      setIsWorkingDayLoading(false);
    },
    onError: () => {
      setIsWorkingDayError(true);
      setIsWorkingDayLoading(false);
    },
  });

  const { refetch: fetchAsyncConfection } = useQuery(
    ['get-async-confection-invalid-date', processId],
    () => getAsyncConfection(processId).then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: response => {
        if (
          response?.exception?.code === EXCEPTION_CODES.success ||
          response?.exception?.code === EXCEPTION_CODES.partialRejection ||
          response?.exception?.code === EXCEPTION_CODES.totalRejection
        ) {
          setIsAsyncConfectionError(false);
          setAsyncConfection(response.data);
        } else {
          setIsAsyncConfectionLoading(false);
          setIsAsyncConfectionError(true);
        }
      },
      onError: () => {
        setIsAsyncConfectionLoading(false);
        setIsAsyncConfectionError(true);
      },
    },
  );

  const getPermissions = () => {
    setIsPermissionsError(false);
    setIsPermissionsLoading(true);
    fetchPermissions();
  };

  const getWorkingDay = () => {
    setIsWorkingDayError(false);
    setIsWorkingDayLoading(true);
    fetchWorkingDay();
  };

  const getAsyncProcess = () => {
    setIsAsyncConfectionError(false);
    setIsAsyncConfectionLoading(true);
    fetchAsyncConfection();
  };

  const getTransfersList = () => {
    const rejected = confectionData?.rejectedTransfers?.filter(
      item =>
        item.message.includes(ResultErrorCodeId.tefGestionErrorHorarioHaDebito) ||
        item.message.includes(ResultErrorCodeId.tefGestionErrorHorarioHaCredito),
    );

    const list: LotTransfer[] = [];

    rejected.forEach(item => {
      const exist = manualTransferData.transfers.find(transfer => item.idTefConfection === transfer.transferNumber);
      if (exist) {
        list.push(exist);
        if (!currentDate) {
          setCurrentDate(exist.transferData.date);
        }
      }
    });

    setTransfersList(list);
    setIsListLoading(false);
  };

  const getTransfersListAsync = () => {
    const list: LotTransfer[] = mapAsyncTransfers(
      asyncConfection?.completeRejectedTransfers.filter(
        item =>
          item.error.includes(ResultErrorCodeId.tefGestionErrorHorarioHaDebito) ||
          item.error.includes(ResultErrorCodeId.tefGestionErrorHorarioHaCredito),
      ) || [],
    );
    setTransfersList(list);
    setCurrentDate(list[0].transferData.date);
    setIsListLoading(false);
  };

  const sortTransfers = (transfers: LotTransfer[]) => {
    return transfers
      .sort((a, b) => a.transferNumber - b.transferNumber)
      .map((transfer, index) => ({
        ...transfer,
        transferNumber: index + 1,
      }))
      .sort((a, b) => b.transferNumber - a.transferNumber);
  };

  const mapTransfers = (discard = false) => {
    let newTransfers: LotTransfer[] = [];
    if (processId) {
      if (!discard) {
        transfersList.forEach(item => newTransfers.push({ ...item, transferData: { ...item.transferData, date: nextDate } }));
      }
      mapAsyncTransfers(
        asyncConfection?.completeRejectedTransfers.filter(
          item =>
            !item.error.includes(ResultErrorCodeId.tefGestionErrorHorarioHaDebito) &&
            !item.error.includes(ResultErrorCodeId.tefGestionErrorHorarioHaCredito),
        ) || [],
      ).forEach(item => {
        newTransfers.push(item);
      });
      mapValidatedAsyncTransfers(asyncConfection?.validateTransfers?.transfer || []).forEach(item => {
        newTransfers.push(item);
      });
    } else {
      manualTransferData.transfers.forEach(item => {
        if (!discard) {
          const invalidTransfer = transfersList.find(transfer => item.transferNumber === transfer.transferNumber);
          if (invalidTransfer) {
            newTransfers.push({ ...item, transferData: { ...item.transferData, date: nextDate } });
          }
        }
        const otherRejectedTransfer = confectionData?.rejectedTransfers?.find(
          transfer =>
            item.transferNumber === transfer.idTefConfection &&
            !transfer?.message?.includes(ResultErrorCodeId.tefGestionErrorHorarioHaDebito) &&
            !transfer?.message?.includes(ResultErrorCodeId.tefGestionErrorHorarioHaCredito),
        );
        if (otherRejectedTransfer) {
          newTransfers.push(item);
        }
        const validatedTransfer = confectionData?.validateTransfers?.find(transfer => item.transferNumber === transfer.idTefConfection);
        if (validatedTransfer) {
          newTransfers.push(item);
        }
      });
    }
    if (newTransfers.length) {
      newTransfers = sortTransfers(newTransfers);
    }
    return newTransfers;
  };

  const setConfection = (transfers: LotTransfer[]) => {
    setConfectionReset(true);
    setManualTransferData({
      ...manualTransferData,
      ...(asyncConfection
        ? {
            transferType: asyncConfection.typeTefs,
            requestDate: currentDate,
            description: asyncConfection.description,
            paymentNumber: asyncConfection.payNumber,
            unifiedShipping: asyncConfection.unifiedRelease,
            consolidated: asyncConfection.consolidatedExtract,
          }
        : {}),
      transfers,
    });
  };

  const handleCancelDiscard = () => {
    setShowDiscardModal(false);
  };

  const handleDiscardInvalidDate = (confirm?: boolean) => {
    if (!confirm) {
      setShowDiscardModal(true);
      return;
    }
    setShowDiscardModal(false);
    const newTransfers: LotTransfer[] = mapTransfers(true);
    if (newTransfers.length) {
      if (processId) {
        setConfection(newTransfers);
        navigate(routesNames.ConfectionSummary, { state: { from: location } });
      } else {
        setManualTransferData({ ...manualTransferData, transfers: newTransfers, step: 3 });
      }
    } else {
      clearConfection();
      if (processId) {
        navigate(routesNames.TransferSetup, { state: { from: location } });
      }
    }
  };

  const handleInvalidDate = () => {
    const newTransfers: LotTransfer[] = mapTransfers(false);
    if (processId) {
      setConfection(newTransfers);
      navigate(routesNames.ConfectionSummary, { state: { from: location } });
    } else {
      setManualTransferData({ ...manualTransferData, transfers: newTransfers, step: 3 });
    }
  };

  const handleRetryRequests = () => {
    if (isWorkingDayError) {
      getWorkingDay();
    }
    if (isPermissionsError) {
      getPermissions();
    }
    if (isAsyncConfectionError) {
      getAsyncProcess();
    }
  };

  useEffect(() => {
    if (asyncConfection) {
      getTransfersListAsync();
      setTimeout(() => {
        setIsAsyncConfectionLoading(false);
      }, 300);
    }
  }, [asyncConfection]);

  useEffect(() => {
    if (!workingDay) {
      getWorkingDay();
    }
    if (isRoute) {
      if (processId) {
        getPermissions();
        getAsyncProcess();
      } else {
        clearConfection();
        navigate(routesNames.TransferSetup);
      }
    } else {
      getTransfersList();
    }
  }, []);

  return {
    isLoading,
    isError,
    formatCurrentDate,
    formatNextDate,
    transfersList,
    showDiscardModal,
    transferType,
    getTransfersList,
    handleInvalidDate,
    handleCancelDiscard,
    handleDiscardInvalidDate,
    handleRetryRequests,
  };
}
