import { Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { TEST_IDS } from './constans';
import ErrorPageProps from './interfaces';
import { ButtonLinkContainer, FlexContainer, Link, TextDescription, TextTitle } from './style';

function ErrorPage({ title, description, Icon, linkText, linkAction, buttonText, buttonAction }: ErrorPageProps) {
  const navigate = useNavigate();

  return (
    <FlexContainer>
      {!!Icon && <Icon data-testid={TEST_IDS.ICON_ERROR} />}

      {!!title && <TextTitle textStyle='h4Bold'>{title}</TextTitle>}

      {!!description && <TextDescription textStyle='bodyLg'>{description}</TextDescription>}

      <ButtonLinkContainer>
        {!!linkText && !!linkAction && (
          <Link data-testid={TEST_IDS.LINK_ACTION} className='link--underline' onClick={() => navigate(linkAction)} href={linkAction}>
            <Text textStyle='labelLgBoldUnderline'>{linkText}</Text>
          </Link>
        )}

        {!!buttonText && !!buttonAction && (
          <Button data-testid={TEST_IDS.BUTTON_ACTION} variant='primary' size='lg' onClick={buttonAction}>
            <Text textStyle='labelLgBold'>{buttonText}</Text>
          </Button>
        )}
      </ButtonLinkContainer>
    </FlexContainer>
  );
}

export default ErrorPage;
