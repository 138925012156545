/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */

export const decimalFormat = (number: number) => {
  if (number % 1 === 0) {
    const formatChangeNumber = new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(number);
    return `${formatChangeNumber},00`;
  }

  const separate = String(number).split('.');
  if (separate[1]?.length === 1) {
    const baseNumber = new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(Number(separate[0]));
    const decimalNumber = separate[1];
    return `${baseNumber},${decimalNumber}0`;
  }

  return new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(number);
};

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-GB');
};

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(number);
};

export const blockInvalidChar = (e: any) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

const formatter = new Intl.DateTimeFormat('es', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
});

export const fechaHoraFormateada = (date: string) => formatter.format(new Date(date));
