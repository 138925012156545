import { Currencies } from '@interfaces/transfers';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { EXCEPTION_CODES, filtersAppliedDrawerDefault } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { Bank, BankAccount } from '../Interfaces/api';
import { useTransferDebitProps2 } from '../Interfaces/hooks/IUseTransferDebit';
import { SelectOption } from '../Interfaces/transferSetup';
import { getDebitAccounts, getDebitBanks } from '../services/api';

export function useTransferDebit({
  transferSelected,
  creditAccountSelected,
  enabledAccountSelection,
  setTransferSelected,
  changeCreditBanksCurrency,
  setCreditCurrencySelected,
  resetCreditAccountFilters,
  setCreditAccountSelected,
  setCreditAccountSelectedId,
  setDebitAccountSelectedRef,
  loadCreditAccountsFiltered,
  fetchCreditBanks,
}: useTransferDebitProps2) {
  const { manualTransferData, debitAccounts, debitFiltersApplied, setDebitAccounts, setDebitFiltersApplied, setCreditFiltersApplied } =
    useTransfersContext();
  const [debitPage, setDebitPage] = useState(1);
  const [debitHasMore, setDebitHasMore] = useState(false);
  const [debitDrawerIsOpen, setDebitDrawerIsOpen] = useState(false);
  const [debitAccountsLoading, setDebitAccountsLoading] = useState(true);
  const [debitAccountsError, setDebitAccountsError] = useState(false);
  const [debitAccountsFiltered, setDebitAccountsFiltered] = useState<BankAccount[]>([]);
  const [debitAccountsFilteredLoading, setDebitAccountsFilteredLoading] = useState(true);
  const [debitAccountsFilteredError, setDebitAccountsFilteredError] = useState(false);
  const [debitAccountSelectedId, setDebitAccountSelectedId] = useState<string | null>(null);
  const [debitAccountSelected, setDebitAccountSelected] = useState<BankAccount | null>(null);
  const [debitAccountChanged, setDebitAccountChanged] = useState<string | null>(null);
  const [debitUsageReferences, setDebitUsageReferences] = useState<string[]>([]);
  const [debitBanks, setDebitBanks] = useState<Bank[]>([]);
  const [debitBanksLoading, setDebitBanksLoading] = useState(true);
  const [debitBanksError, setDebitBanksError] = useState(false);
  const [debitBankSelected, setDebitBankSelected] = useState<SelectOption | null>(null);
  const [debitBanksCurrencyARS, setDebitBanksCurrencyARS] = useState(false);
  const [debitBanksCurrencyUSD, setDebitBanksCurrencyUSD] = useState(false);
  const [debitCurrencySelected, setDebitCurrencySelected] = useState<string>(Currencies.ARS);
  const [debitCurrencyChanged, setDebitCurrencyChanged] = useState<string | null>(null);
  const [debitFilterTypeSelected, setDebitFilterTypeSelected] = useState<SelectOption | null>({ label: 'cbu', value: 'cbu' });
  const [debitFilterData, setDebitFilterData] = useState<string>('');
  const [statusGetDebitBanks, setStatusGetDebitBanks] = useState(false);
  const [statusGetDebitAccounts, setStatusGetDebitAccounts] = useState(false);
  const [sameAccountSelectedDebit, setSameAccountSelectedDebit] = useState(false);
  const [shouldShowFilterAccounts, setShouldShowFilterAccounts] = useState(false);
  const [debitReferenceOptionsList, setDebitReferenceOptionsList] = useState<SelectOption[]>([]);
  const [isAnyDebitFilterApplied, setIsAnyDebitFilterApplied] = useState<boolean>(false);
  const [debitBankOptionsList, setDebitBankOptionsList] = useState<SelectOption[]>([]);
  const debitAccountsMax = 10;
  const debitAccountsLength = debitAccounts.length;
  const debitAccountsFilterActive = debitAccountsLength > debitAccountsMax;

  const openDebitDrawer = () => {
    setDebitDrawerIsOpen(true);
  };

  const closeDebitDrawer = () => {
    setDebitDrawerIsOpen(false);
  };

  const handleSelectDebitAccount = useCallback(
    (value: string | null, confirm = false) => {
      if (!value) {
        setDebitAccountSelectedId(null);
        setDebitAccountSelected(null);
        return;
      }

      const findIndex = debitAccountsFiltered.findIndex(item => item.accountId === value);
      const debitAccount = debitAccountsFiltered[findIndex];

      if (debitAccount && creditAccountSelected && debitAccount?.identifier === creditAccountSelected?.identifier) {
        setSameAccountSelectedDebit(true);
        return;
      }

      let creditCurrencySelected = null;
      if (creditAccountSelected?.currency) {
        creditCurrencySelected = creditAccountSelected?.currency;
      } else if (transferSelected?.creditAccount?.currency) {
        creditCurrencySelected = transferSelected?.creditAccount?.currency;
      }

      if (creditCurrencySelected && creditCurrencySelected !== debitAccount?.currency && !confirm) {
        setDebitAccountChanged(value);
        return;
      }

      setDebitAccountSelectedId(value);
      setDebitAccountSelected(debitAccount);
      setDebitCurrencySelected(debitAccount?.currency);

      setCreditCurrencySelected(debitAccount?.currency);

      if (debitAccountChanged && confirm) {
        if (transferSelected?.creditAccount) {
          setTransferSelected({ ...transferSelected, creditAccount: null });
        }

        resetCreditAccountFilters();
        setCreditAccountSelectedId(null);
        setCreditAccountSelected(null);

        setDebitAccountChanged(null);
      }

      setTimeout(() => {
        if (debitDrawerIsOpen) {
          closeDebitDrawer();
        }
        loadCreditAccountsFiltered();
        fetchCreditBanks();
      }, 300);
    },
    [debitAccountsFiltered, debitAccountChanged, creditAccountSelected, transferSelected?.creditAccount, debitDrawerIsOpen],
  );

  const handleRemoveSelectedDebitAccount = () => {
    if (transferSelected?.debitAccount) {
      setTransferSelected({ ...transferSelected, debitAccount: null });
    }
    setDebitAccountSelectedId(null);
    setDebitAccountSelected(null);
  };

  const handleSelectDebitBank = (selectedOption: SelectOption | null) => {
    setDebitBankSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleSelectDebitCurrency = (currency: string, confirm = false) => {
    if ((transferSelected?.creditAccount || creditAccountSelected) && debitCurrencySelected !== currency && !confirm) {
      setDebitCurrencyChanged(currency);
      return;
    }

    if (!transferSelected?.creditAccount) {
      changeCreditBanksCurrency(currency);
    }

    if (debitCurrencyChanged && confirm) {
      if (transferSelected?.creditAccount) {
        setTransferSelected({ ...transferSelected, creditAccount: null });
      }
      changeCreditBanksCurrency(currency);
      setDebitCurrencyChanged(null);
    }

    setDebitCurrencySelected(currency);
  };

  const handleChangeDebitCurrency = (confirm: boolean) => {
    if (confirm) {
      if (debitAccountChanged) {
        handleSelectDebitAccount(debitAccountChanged, true);
        setCreditFiltersApplied(filtersAppliedDrawerDefault);
      } else if (debitCurrencyChanged) {
        handleSelectDebitCurrency(debitCurrencyChanged, true);
        setShouldShowFilterAccounts(true);
      }
    } else if (debitAccountChanged) {
      setDebitAccountChanged(null);
    } else if (debitCurrencyChanged) {
      setDebitCurrencyChanged(null);
    }
  };

  const handleSelectDebitFilterType = (selectedOption: SelectOption | null) => {
    setDebitFilterTypeSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleCurrencyInitial = () => {
    if (debitAccountsLength > 1) {
      setDebitCurrencySelected(debitBanksCurrencyARS ? Currencies.ARS : Currencies.USD);
      setCreditCurrencySelected(debitBanksCurrencyARS ? Currencies.ARS : Currencies.USD);
    }
  };

  const { refetch: fetchDebitAccounts, isError: isErrorFetchDebitAccounts } = useQuery(
    ['get-debit-accounts', manualTransferData.transferType],
    () => getDebitAccounts({ transferType: manualTransferData.transferType })?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const accounts: BankAccount[] = data?.data?.accounts || [];
          setDebitAccounts(accounts);
          setDebitAccountsFiltered(accounts);
          setDebitHasMore(data?.paging?.totalPages ? data?.paging?.totalPages > 1 : false);
          setStatusGetDebitAccounts(true);
        } else {
          setDebitAccountsError(true);
          setDebitAccountsFilteredError(true);
          setDebitHasMore(false);
        }
        setDebitAccountsLoading(false);
        setDebitAccountsFilteredLoading(false);
      },
      onError: () => {
        setDebitAccountsLoading(false);
        setDebitAccountsError(true);
        setDebitAccountsFilteredLoading(false);
        setDebitAccountsFilteredError(true);
        setDebitHasMore(false);
      },
    },
  );

  const { refetch: fetchDebitAccountsFiltered } = useQuery(
    [
      'get-debit-accounts-filtered',
      debitPage,
      manualTransferData.transferType,
      debitFiltersApplied.usageReferences || [],
      debitFiltersApplied.banks || [],
      creditAccountSelected?.identifier || '',
      debitFilterTypeSelected?.value || '',
      debitFilterData,
    ],
    () =>
      getDebitAccounts({
        page: debitPage,
        transferType: manualTransferData.transferType,
        usageReference: debitFiltersApplied.usageReferences || [],
        bankCode: debitFiltersApplied.banks || [],
        accountSelected: creditAccountSelected?.identifier || '',
        filterType: debitFilterTypeSelected?.value || '',
        filterData: debitFilterData,
      })?.then(res => res.data),
    {
      retry: false,
      enabled: false,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const accounts: BankAccount[] = data?.data?.accounts || [];
          if (debitPage > 1) {
            setDebitAccountsFiltered(prevAccounts => [...prevAccounts, ...accounts]);
          } else {
            setDebitAccountsFiltered(accounts);
          }
          setDebitHasMore(data?.paging?.totalPages ? debitPage < data?.paging?.totalPages : false);
        } else {
          setDebitAccountsFilteredError(true);
          setDebitHasMore(false);
        }
        setDebitAccountsFilteredLoading(false);
      },
      onError: () => {
        setDebitAccountsFilteredLoading(false);
        setDebitAccountsFilteredError(true);
        setDebitHasMore(false);
      },
    },
  );

  const { refetch: fetchDebitBanks, isError: isErrorFetchDebitBanks } = useQuery(
    ['get-debit-banks', manualTransferData.transferType],
    () => getDebitBanks(manualTransferData.transferType)?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setDebitBanks(data?.data.banks);
          setDebitBanksCurrencyARS(data?.data.currencyARS);
          setDebitBanksCurrencyUSD(data?.data.currencyUSD);
          setStatusGetDebitBanks(true);
        } else {
          setDebitBanksError(true);
        }
        setDebitBanksLoading(false);
      },
      onError: () => {
        setDebitBanksLoading(false);
        setDebitBanksError(true);
      },
    },
  );

  const handleSearchDebitAccount = () => {
    loadDebitAccountsFiltered();
  };

  const resetDebitAccountFilters = () => {
    setDebitFiltersApplied(filtersAppliedDrawerDefault);
    setDebitBankSelected(null);
    setDebitFilterTypeSelected(null);
    setDebitFilterData('');
  };

  const loadDebitAccounts = () => {
    setDebitPage(1);
    setDebitAccounts([]);
    setDebitAccountsLoading(true);
    setDebitAccountsError(false);
    setDebitAccountsFiltered([]);
    setDebitAccountsFilteredLoading(true);
    setDebitAccountsFilteredError(false);
    setTimeout(() => {
      fetchDebitAccounts();
    }, 300);
  };

  const loadDebitAccountsFiltered = () => {
    setDebitPage(1);
    setDebitAccountsFiltered([]);
    setDebitAccountsFilteredLoading(true);
    setDebitAccountsFilteredError(false);
    setTimeout(() => {
      fetchDebitAccountsFiltered();
    }, 300);
  };

  const loadDebitAccountsFilteredMore = () => {
    if (debitAccountsFilteredError) {
      setDebitHasMore(true);
      setDebitAccountsFilteredError(false);
    } else {
      setDebitPage(value => value + 1);
    }
    setTimeout(() => {
      fetchDebitAccountsFiltered();
    }, 500);
  };

  const loadDebitBanks = () => {
    setDebitBanks([]);
    setDebitBanksCurrencyARS(false);
    setDebitBanksCurrencyUSD(false);
    setDebitBanksLoading(true);
    setDebitBanksError(false);
    setTimeout(() => {
      fetchDebitBanks();
    }, 300);
  };

  useEffect(() => {
    handleCurrencyInitial();
  }, [debitAccountsFilterActive, debitBanksCurrencyARS, debitBanksCurrencyUSD]);

  useEffect(() => {
    if (enabledAccountSelection && debitAccounts.length === 1) {
      handleSelectDebitAccount(debitAccounts[0].accountId);
    }
  }, [debitAccounts, enabledAccountSelection]);

  useEffect(() => {
    if (typeof setDebitAccountSelectedRef === 'function') {
      setDebitAccountSelectedRef(debitAccountSelected?.identifier || '');
    }
  }, [debitAccountSelected]);

  useEffect(() => {
    if (debitAccounts.length) {
      loadDebitAccountsFiltered();
    }
  }, [creditAccountSelected]);

  useEffect(() => {
    const options = debitUsageReferences?.map(reference => ({ value: reference, label: reference })) || [];
    setDebitReferenceOptionsList(options);
  }, [debitUsageReferences]);

  useEffect(() => {
    const bankOptionsList =
      debitBanks
        .filter((obj, index, banksList) => banksList.findIndex(item => item.bcraCode === obj.bcraCode) === index)
        .map(bank => ({ value: bank.bcraCode, label: bank.bankName })) || [];
    setDebitBankOptionsList(bankOptionsList);
  }, [debitBanks]);

  useEffect(() => {
    if (debitFiltersApplied.banks?.length > 0 || debitFiltersApplied.usageReferences?.length > 0) {
      setIsAnyDebitFilterApplied(true);
    } else {
      setIsAnyDebitFilterApplied(false);
    }
  }, [debitFiltersApplied]);

  return {
    debitPage,
    debitHasMore,
    debitDrawerIsOpen,
    debitAccounts,
    debitAccountsLoading,
    debitAccountsError,
    debitAccountsLength,
    debitAccountSelectedId,
    debitAccountSelected,
    debitAccountChanged,
    debitAccountsFilterActive,
    debitAccountsFiltered,
    debitAccountsFilteredLoading,
    debitAccountsFilteredError,
    debitUsageReferences,
    debitBanks,
    debitBanksLoading,
    debitBanksError,
    debitBanksCurrencyARS,
    debitBanksCurrencyUSD,
    debitBankSelected,
    debitCurrencySelected,
    debitCurrencyChanged,
    debitFilterTypeSelected,
    debitFilterData,
    isErrorFetchDebitBanks,
    isErrorFetchDebitAccounts,
    statusGetDebitAccounts,
    statusGetDebitBanks,
    sameAccountSelectedDebit,
    shouldShowFilterAccounts,
    debitFiltersApplied,
    debitReferenceOptionsList,
    isAnyDebitFilterApplied,
    debitBankOptionsList,
    setDebitFiltersApplied,
    loadDebitAccounts,
    loadDebitAccountsFiltered,
    loadDebitAccountsFilteredMore,
    loadDebitBanks,
    setDebitAccountsLoading,
    setDebitBanksLoading,
    setDebitAccountSelected,
    setDebitAccountSelectedId,
    setDebitCurrencySelected,
    handleSelectDebitAccount,
    handleRemoveSelectedDebitAccount,
    handleSelectDebitBank,
    handleSelectDebitCurrency,
    handleChangeDebitCurrency,
    handleSelectDebitFilterType,
    setDebitFilterData,
    handleSearchDebitAccount,
    resetDebitAccountFilters,
    setDebitUsageReferences,
    setSameAccountSelectedDebit,
    openDebitDrawer,
    closeDebitDrawer,
  };
}
