import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import AutoNumericInput from '@components/AutoNumericInput';
import { useEffect, useState } from 'react';

import { FilterAmountFieldNames } from '../../../../constants-app';
import { useTransfersContext } from '../../../../context/TransfersContext';
import { useAmountInput } from '../../../../hooks/useAmountInput';
import { applyCurrencyFormatFromPaste } from '../../../../utils/applyCurrencyFormatFromPaste';
import { convertAmountNumberToText } from '../../../../utils/convertAmountNumberToText.ts';
import BaseScreenFilter from '../BaseScreenFilter';
import {
  AMOUNT_FROM_TEST_ID,
  AMOUNT_TO_TEST_ID,
  FROM_TITLE,
  FROM_VALIDATION_MESSAGE,
  MENU_TEST_ID_AMOUNTS,
  PLACEHOLDER_INPUT_FROM,
  PLACEHOLDER_INPUT_TO,
  TEXT_FILTER_TAG,
  TITLE_HEADER,
  TO_TITLE,
  TO_VALIDATION_MESSAGE,
} from './constants';
import { FilterAmountProps, ValidationStatus } from './interfaces';
import { CustomBox, CustomText } from './styled';

function FilterAmount({ isFetching }: FilterAmountProps) {
  const [isInvalid, setIsInvalid] = useState<ValidationStatus>({ fromAmount: false, toAmount: false });
  const [focusedInput, setFocusedInput] = useState<string | null>(null);
  const [textTagAmounts, setTextTagAmounts] = useState('');
  const [isDisabledApplyButton, setIsDisabledApplyButton] = useState(false);
  const [isDisabledDeleteButton, setIsDisabledDeleteButton] = useState(false);
  const [isMenuDisabled, setIsMenuDisabled] = useState(false);
  const { setAmountRanges, amountRanges, setFiltered, filtered, setPageContext, hasData } = useTransfersContext();

  const { inputValue: fromAmount, setInputValue: setInputFromValue } = useAmountInput(
    amountRanges.fromAmount ? convertAmountNumberToText(amountRanges.fromAmount) : '',
  );
  const { inputValue: toAmount, setInputValue: setInputToValue } = useAmountInput(
    amountRanges.toAmount ? convertAmountNumberToText(amountRanges.toAmount) : '',
  );

  useEffect(() => {
    setTextTagAmounts(getTextTag());
  }, [filtered.isRangeAmountsActive, amountRanges.fromAmount, amountRanges.toAmount]);

  useEffect(() => {
    setIsMenuDisabled(checkMenuDisabled());
  }, [hasData, amountRanges.fromAmount, isFetching, amountRanges.toAmount]);

  useEffect(() => {
    setIsDisabledApplyButton(checkApplyButtonDisabled());
    setIsDisabledDeleteButton(!fromAmount?.length && !toAmount?.length);
  }, [fromAmount, toAmount, filtered.isRangeAmountsActive, amountRanges.fromAmount, amountRanges.toAmount, isInvalid]);

  useEffect(() => {
    const fromValue = Number(fromAmount);
    const toValue = Number(toAmount);

    const fromError = fromAmount && toAmount ? fromValue > toValue : false;
    const toError = toAmount && fromAmount ? toValue < fromValue : false;

    setIsInvalid({
      fromAmount: fromError,
      toAmount: toError,
    });
  }, [fromAmount, toAmount]);

  const onApplyButton = (): void => {
    setPageContext(1);
    const fromAmountNum = Number(fromAmount) || null;
    const toAmountNum = Number(toAmount) || null;
    setAmountRanges({ fromAmount: fromAmountNum, toAmount: toAmountNum });
    const shouldFilter = fromAmountNum !== null || toAmountNum !== null;
    setFiltered({
      ...filtered,
      isRangeAmountsActive: shouldFilter,
    });
  };

  const onClickClearButton = (): void => {
    setInputFromValue('');
    setInputToValue('');
    setIsInvalid({ fromAmount: false, toAmount: false });
  };

  const isErrorShownInFromInput = (): boolean => isInvalid.fromAmount && focusedInput === FilterAmountFieldNames.FromAmount;

  const isErrorShownInToInput = (): boolean => isInvalid.toAmount && focusedInput === FilterAmountFieldNames.ToAmount;

  const checkApplyButtonDisabled = (): boolean => {
    const formattedFromAmount = amountRanges.fromAmount !== null ? applyCurrencyFormatFromPaste(amountRanges.fromAmount.toFixed(2)) : '';
    const formattedToAmount = amountRanges.toAmount !== null ? applyCurrencyFormatFromPaste(amountRanges.toAmount.toFixed(2)) : '';

    if (isInvalid.fromAmount || isInvalid.toAmount) {
      return true;
    }

    if (formattedFromAmount === fromAmount && formattedToAmount === toAmount) {
      return true;
    }

    if ((fromAmount && Number(fromAmount) === 0) || (toAmount && Number(toAmount) === 0)) {
      return true;
    }

    return false;
  };

  const getTextTag = (): string => {
    if (filtered.isRangeAmountsActive && amountRanges.fromAmount && amountRanges.toAmount) {
      return `${TEXT_FILTER_TAG}: ${applyCurrencyFormatFromPaste(
        String(amountRanges.fromAmount.toFixed(2)),
      )} - ${applyCurrencyFormatFromPaste(String(amountRanges.toAmount.toFixed(2)))}`;
    }
    if (filtered.isRangeAmountsActive && amountRanges.fromAmount) {
      return `${TEXT_FILTER_TAG}: Desde ${applyCurrencyFormatFromPaste(String(amountRanges.fromAmount.toFixed(2)))} `;
    }
    if (filtered.isRangeAmountsActive && amountRanges.toAmount) {
      return `${TEXT_FILTER_TAG}: Hasta ${applyCurrencyFormatFromPaste(String(amountRanges.toAmount.toFixed(2)))}`;
    }
    return TEXT_FILTER_TAG;
  };

  const checkMenuDisabled = (): boolean => isFetching || (!hasData && amountRanges.fromAmount === null && amountRanges.toAmount === null);

  const onHandleClearCloseWithOutFilter = (): void => {
    if (!filtered.isRangeAmountsActive) {
      setInputFromValue('');
      setInputToValue('');
      setIsInvalid({ fromAmount: false, toAmount: false });
      setFocusedInput(null);
    }
  };

  return (
    <BaseScreenFilter
      titleHeader={TITLE_HEADER}
      textTag={textTagAmounts}
      count={0}
      statusButtonClear={isDisabledDeleteButton}
      statusButtonApply={isDisabledApplyButton}
      handleClearCloseWithOutFilter={onHandleClearCloseWithOutFilter}
      handleApplyButton={onApplyButton}
      onClickClearButton={onClickClearButton}
      menuTestIdName={MENU_TEST_ID_AMOUNTS}
      testId='amount'
      isSelected={filtered.isRangeAmountsActive && (amountRanges.fromAmount !== null || amountRanges.toAmount !== null)}
      isDisabled={isMenuDisabled}
    >
      <CustomBox>
        <FormControl className='content'>
          <FormLabel>{FROM_TITLE}</FormLabel>
          <AutoNumericInput
            value={fromAmount}
            id={AMOUNT_FROM_TEST_ID}
            name={AMOUNT_FROM_TEST_ID}
            data-testid={AMOUNT_FROM_TEST_ID}
            placeholder={PLACEHOLDER_INPUT_FROM}
            size='md'
            decimalPlaces={2}
            onValueChange={setInputFromValue}
            type='text'
            onFocus={() => setFocusedInput(FilterAmountFieldNames.FromAmount)}
            onBlur={() => setFocusedInput(null)}
            showCurrencySymbol={false}
          />

          {isErrorShownInFromInput() && <Text sx={CustomText}>{FROM_VALIDATION_MESSAGE}</Text>}
        </FormControl>

        <FormControl className='content'>
          <FormLabel>{TO_TITLE}</FormLabel>
          <AutoNumericInput
            value={toAmount}
            id={AMOUNT_TO_TEST_ID}
            name={AMOUNT_TO_TEST_ID}
            data-testid={AMOUNT_TO_TEST_ID}
            placeholder={PLACEHOLDER_INPUT_TO}
            size='md'
            decimalPlaces={2}
            onValueChange={setInputToValue}
            onFocus={() => setFocusedInput(FilterAmountFieldNames.ToAmount)}
            onBlur={() => setFocusedInput(null)}
            showCurrencySymbol={false}
          />

          {isErrorShownInToInput() && <Text sx={CustomText}>{TO_VALIDATION_MESSAGE}</Text>}
        </FormControl>
      </CustomBox>
    </BaseScreenFilter>
  );
}

export default FilterAmount;
