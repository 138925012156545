/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { themeChakra } from '@wow/themes';

// COLORES
const {
  colors: { secondary, success, error, tertiary, info, grey, neutral, primary, white, black, transparent, blackAlpha },
  shadows: { none, lg, xs, md, '2xl': dosXL, 'dark-lg': darkLg, '2xl-reverse': dosxlreverse },
} = themeChakra;

export const colors = {
  ShadowNone: none,
  ShadowLg: lg,
  ShadowMd: md,
  Shadow2Xl: dosXL,
  ShadowDarkLg: darkLg,
  ShadowXs: xs,
  Shadow2XlReverse: dosxlreverse,
  Black: black,
  White: white,
  Transparent: transparent,
  Info50: '#D0F3FF',
  Info100: '#049BCB',
  Info110: info[110]!,
  Info120: info[120]!,
  Grey40: grey[40],
  Grey50: '#E3E2E6',
  Grey60: grey[60]!,
  Grey70: grey[70]!,
  Grey100: '#5E5E61',
  Grey120: grey[120]!,
  Neutral40: neutral[40]!,
  Neutral50: '#E0E2EC',
  Neutral90: neutral[90]!,
  Neutral100: '#5C5E66',
  Warning50: '#FCFCFC',
  Warning100: '#FFD7B0',
  Primary50: '#EBF0FF',
  Primary60: primary[60]!,
  Primary90: primary[90]!,
  Primary100: '#2172DC',
  Primary110: primary[110]!,
  Primary130: primary[130]!,
  Secondary50: '#DAE2F9',
  Secondary60: secondary[60]!,
  Secondary70: secondary[70]!,
  Secondary90: secondary[90]!,
  Secondary100: '#565E71',
  Secondary110: secondary[110]!,
  Secondary200: secondary[200]!,
  Secondary400: secondary[400]!,
  Secondary500: secondary[500]!,
  Secondary600: secondary[600]!,
  Tertiary50: '#D2BCFF',
  Tertiary100: '#5B31B2',
  Tertiary110: tertiary[110]!,
  Success50: '#F1FFF7',
  Success80: success[80]!,
  Success90: success[90]!,
  Success100: '#109B75',
  Success110: success[110]!,
  Error50: '#FFEDE9',
  Error100: '#F03C3C',
  Error110: error[110]!,
  Danger50: '#FFEDE9',
  Danger100: '#ED1B1B',
  BlackAlpha100: blackAlpha[100],
  BlackAlpha400: blackAlpha[400],
};

export default { colors };
