import { Flex, Skeleton } from '@chakra-ui/react';
import { SUPPLIERS, THIRD_PARTY_PAYMENTS } from 'src/constants-app';

import CardAccountDrawerSkeleton from '../../CardAccountDrawerSkeleton';
import SKELETON_DRAWER from './constants';
import {
  BoxSkeleton,
  disclaimerSkeleton,
  FlexContainerStyles,
  SkeletonBasicStyles,
  SkeletonCustomStyle,
  SkeletonDataTitle,
  SkeletonHeaderCreditCardTitle,
  SkeletonHeaderDebitCardTitle,
  SkeletonStylesBottom,
  terFieldsStyles,
} from './index.styled';
import { SkeletonFormDrawerBodyProps } from './interfaces';

function SkeletonFormDrawerBody({ title }: SkeletonFormDrawerBodyProps) {
  return (
    <Flex sx={BoxSkeleton} data-testid={SKELETON_DRAWER}>
      <Skeleton style={SkeletonHeaderDebitCardTitle} />
      <CardAccountDrawerSkeleton isDebit />
      <Skeleton style={SkeletonHeaderCreditCardTitle} />
      <CardAccountDrawerSkeleton />
      <Flex sx={FlexContainerStyles}>
        <Skeleton style={SkeletonDataTitle} />
        <Flex>
          <Skeleton style={SkeletonCustomStyle} />
          <Skeleton style={SkeletonBasicStyles} />
        </Flex>
        <Skeleton style={SkeletonStylesBottom} />
        {title === THIRD_PARTY_PAYMENTS && (
          <Flex sx={terFieldsStyles}>
            <Flex>
              <Skeleton style={SkeletonCustomStyle} />
              <Skeleton style={SkeletonBasicStyles} />
            </Flex>
            <Skeleton style={SkeletonStylesBottom} />
          </Flex>
        )}
        <Skeleton style={SkeletonDataTitle} />
      </Flex>
      {title === SUPPLIERS && <Skeleton style={disclaimerSkeleton} />}
    </Flex>
  );
}

export default SkeletonFormDrawerBody;
