import { Box, Skeleton } from '@chakra-ui/react';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { TablePaginationComponent } from '@wow/table-pagination';
import { pushAnalyticsEvent } from '@wow/utils';

import CustomTable from '../../components/CustomTable';
import FilterTable from '../../components/FilterTable';
import IconBeware from '../../components/Icons/icon-beware';
import IconError from '../../components/Icons/icon-cancel-modal';
import IconSuccess from '../../components/Icons/icon-checkmark';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import CustomModal from '../../components/Modal';
import ViewNoResult from '../../components/ViewNoResult';
import { DataMessageErrorRequest, PAGE_ACTIONS } from '../../constants-app';
import useUnauthorizeTransfers from '../../hooks/useUnauthorizeTransfers';
import { ServiceAction, TableDataStateType } from '../../Interfaces/transfers';
import { UNAUTHORIZE_TRANSFERS } from '../../utils/configTableFunctions';
import { COLUMNS } from './configTable';
import {
  TEXT_BUTTONS,
  TEXT_ERROR_UNAUTHORIZE,
  TEXT_ERROR_ZERO,
  TEXT_LOADING_UNAUTHORIZE,
  TEXT_MODAL_ASYNC,
  TEXT_MODAL_ASYNC_DONE,
  TEXT_MODAL_ASYNC_ERROR,
  TEXT_SUCCESS,
  UNAUTHORIZE_ERROR,
} from './constants';
import { CustomTd, CustomTh, Paginator } from './index.styled';

function UnauthorizeTransfers() {
  const {
    error,
    isFetchingUnauthorize,
    isFetching,
    isFetchingGetDetailsOfAllTransfers,
    lots,
    sizeContext,
    pageContext,
    pagination,
    status,
    success,
    tableDataState,
    isAsyncProcess,
    isAsyncProcessDone,
    isAsyncProcessError,
    isFetchingAsyncProcess,
    servicesWorking,
    lotNumber,
    firstTime,
    secondTime,
    handleToggleAnalytics,
    onClickRetry,
    onCancel,
    onClickUnauthorize,
    onFilterLot,
    onCloseModalSuccess,
    onCloseModalErrorZero,
    onCloseModalAsyncProcess,
    onCloseModalAsyncProcessDone,
    onCloseModalAsyncProcessError,
    setPageContext,
    setSizeContext,
  } = useUnauthorizeTransfers();

  if (status === 'error' || !servicesWorking) {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Transferencias - Quitar autorización - Servicio no disponible',
    });
    return (
      <ViewNoResult
        title={DataMessageErrorRequest.title}
        description={DataMessageErrorRequest.description}
        linkName={DataMessageErrorRequest.linkName}
        Icon={IconDisconnected}
        handleToggleClick={handleToggleAnalytics}
      />
    );
  }

  const handlePageNavigation = (pageNumber: number, action: string) => {
    setPageContext(pageNumber);
    pushAnalyticsEvent({
      event: 'switch_page',
      selection_type: `${pageNumber} | ${action}`,
    });
  };

  return (
    <>
      {(isFetchingGetDetailsOfAllTransfers || isFetchingUnauthorize || isFetchingAsyncProcess) && (
        <LoadingScreen title={TEXT_LOADING_UNAUTHORIZE.title} description={TEXT_LOADING_UNAUTHORIZE.description} />
      )}
      {firstTime ? (
        <FilterTable onFilterLot={onFilterLot} isFetching={isFetching} serviceAction={ServiceAction.DES} lotNumber={lotNumber} />
      ) : (
        <Skeleton width='100%' height='64px' borderRadius='4px' margin='0 0 12px 0' data-testid='skeleton-component' />
      )}
      <CustomTable
        showButton
        CustomTd={CustomTd}
        CustomTh={CustomTh}
        data={lots}
        columns={COLUMNS as []}
        size={sizeContext}
        selectedTable={UNAUTHORIZE_TRANSFERS}
        handleClickButtom={onClickUnauthorize}
        onFilterLot={onFilterLot}
        tableDataState={tableDataState as TableDataStateType}
        text={TEXT_BUTTONS.unauthorize}
        id='table'
        isFetching={isFetching}
        firstStatusSkeleton={firstTime}
        secondStatusSkeleton={secondTime}
      />
      {firstTime && secondTime ? (
        <TablePaginationComponent
          totalPages={pagination.totalPages || 1}
          currentIndex={pagination.pageNumber && pagination.pageNumber - 1}
          totalRecords={pagination.totalElements}
          perPage={[15, 30, 45]}
          disabledPerPage={pagination.totalElements <= 15}
          currentPerPage={pagination.totalElementsRequested}
          onNext={() => handlePageNavigation(pageContext + 1, PAGE_ACTIONS.NEXT)}
          onPrev={() => handlePageNavigation(pageContext - 1, PAGE_ACTIONS.PREV)}
          onFirst={() => handlePageNavigation(1, PAGE_ACTIONS.FIRST)}
          onLast={() => handlePageNavigation(pagination?.totalPages, PAGE_ACTIONS.LAST)}
          onJump={(value: number) => {
            if (Number(value) < pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.PREV);
            } else if (Number(value) > pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.NEXT);
            }
          }}
          onPerPageChange={(value: number) => {
            setSizeContext(Number(value));
            setPageContext(1);
          }}
        />
      ) : (
        <Box sx={Paginator}>
          <Skeleton width='100%' height='56px' borderRadius='4px' marginTop='6px' data-testid='skeleton-component' />
        </Box>
      )}
      <CustomModal
        isOpen={error === UNAUTHORIZE_ERROR.UNAUTHORIZE}
        onCancel={onCancel}
        Icon={IconError}
        title={TEXT_ERROR_UNAUTHORIZE.title}
        firstDescription={TEXT_ERROR_UNAUTHORIZE.description}
        firstTextButton={TEXT_BUTTONS.cancel}
        secondTextButton={TEXT_BUTTONS.retry}
        actionButton2={onClickRetry}
        size='xl'
        enableFirstButton
        enableSecondButton
        alternativeButtonStyling
        cancelType
        id='modalErrorUnauthorize'
      />
      <CustomModal
        isOpen={error === UNAUTHORIZE_ERROR.ZERO}
        onCancel={onCloseModalErrorZero}
        Icon={IconError}
        title={TEXT_ERROR_ZERO.title}
        firstDescription={TEXT_ERROR_ZERO.description}
        firstTextButton={TEXT_BUTTONS.ok}
        actionButton={onCloseModalErrorZero}
        size='xl'
        enableFirstButton
        cancelType
        id='modalErrorZero'
      />
      <CustomModal
        isOpen={success}
        onCancel={onCloseModalSuccess}
        Icon={IconSuccess}
        title={TEXT_SUCCESS.title}
        firstDescription={TEXT_SUCCESS.description}
        firstTextButton={TEXT_BUTTONS.ok}
        actionButton={onCloseModalSuccess}
        size='xl'
        enableFirstButton
        cancelType
        id='modalSuccess'
      />
      <CustomModal
        isOpen={isAsyncProcessError}
        onCancel={onCloseModalAsyncProcessError}
        Icon={IconBeware}
        title={TEXT_MODAL_ASYNC_ERROR.title}
        firstDescription={TEXT_MODAL_ASYNC_ERROR.description}
        firstTextButton={TEXT_BUTTONS.ok}
        actionButton={onCloseModalAsyncProcessError}
        size='xl'
        enableFirstButton
        id='modalAsyncProcessError'
      />
      <CustomModal
        isOpen={isAsyncProcessDone}
        onCancel={onCloseModalAsyncProcessDone}
        Icon={IconInfoPopup}
        title={TEXT_MODAL_ASYNC_DONE.title}
        firstDescription={TEXT_MODAL_ASYNC_DONE.description}
        firstTextButton={TEXT_BUTTONS.ok}
        actionButton={onCloseModalAsyncProcessDone}
        size='xl'
        enableFirstButton
        id='modalAsyncProcessDone'
      />
      <CustomModal
        isOpen={isAsyncProcess}
        onCancel={onCloseModalAsyncProcess}
        iconSpinner
        title={TEXT_MODAL_ASYNC.title}
        firstDescription={TEXT_MODAL_ASYNC.description}
        firstTextButton={TEXT_BUTTONS.backToTransfers}
        actionButton={onCloseModalAsyncProcess}
        size='xl'
        enableFirstButton
        id='modalAsyncProcess'
      />
    </>
  );
}

export default UnauthorizeTransfers;
