import { ResultErrorIcon } from '../../Interfaces/transfers';

export const MODAL_ERRORS_TRANSFERS_ALERT = {
  TEF_NOT_AVAILABLE: {
    icon: ResultErrorIcon.alertTriangle,
    description: 'El servicio del banco no está operativo en este momento.',
  },
  TEF_INCOMPLETE_UNIFIED_SHIPPING: {
    icon: ResultErrorIcon.layers,
    description: 'Lote con envío unificado incompleto. Revisá que todas las transferencias estén autorizadas.',
  },
  TEF_NOT_WORKDAY: {
    icon: ResultErrorIcon.calendar,
    description: 'Día inhabilitado, enviá las transferencias en un día habil.',
  },
  TEF_LIMIT_EXCEEDED: {
    icon: ResultErrorIcon.trendingUp,
    description: 'Alcanzaste el límite de transferencias por este mes.',
  },
  TEF_NON_EXISTENT_TOP: {
    icon: ResultErrorIcon.edit3,
    description: 'Error en el esquema de firmas, comunicate con Soporte',
  },
  TEF_ALREADY_SENT: {
    icon: ResultErrorIcon.alertTriangle,
    description: 'Las transferencias están incluidas en otro proceso de envío.',
  },
  TEF_GENERIC_ERROR: {
    icon: ResultErrorIcon.alertTriangle,
    description: 'El sistema no está disponible en este momento',
  },
};
