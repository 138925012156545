import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import { CustomMarginText } from '../../../../styled/global.styled';
import ALERT_ERROR_CHILDREN_TEXTS from './constants';

interface Props {
  text: string[];
  totalTransferByMessage: number;
}

function TextResultListItem({ text = [], totalTransferByMessage }: Props) {
  const hasUndefined = text.some(element => element === undefined);

  return (
    <>
      {text.length > 1 && !hasUndefined && (
        <Box data-testid='multiple-bank-box'>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {'Claves MAC no asociadas a Clave Empresa. Asociá tus Claves Mac de '}
          </Text>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {text
              .slice(0, -1)
              .map((item: string) => item)
              .join(', ')}
          </Text>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {' '}
            y{' '}
          </Text>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {text[text.length - 1]}
          </Text>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {' a tu Clave Empresa desde la app Interbanking.'}
          </Text>
        </Box>
      )}
      {text.length === 1 && !hasUndefined && (
        <Box data-testid='single-bank-box'>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {ALERT_ERROR_CHILDREN_TEXTS.totalTransfersTexts(totalTransferByMessage)}
          </Text>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {text[0]}
          </Text>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {' a tu Clave Empresa desde la app Interbanking.'}
          </Text>
        </Box>
      )}
      {(hasUndefined || text.length === 0) && (
        <Box>
          <Text as='span' sx={CustomMarginText} textStyle='h6'>
            {ALERT_ERROR_CHILDREN_TEXTS.macKeys}
          </Text>
        </Box>
      )}
    </>
  );
}

export default TextResultListItem;
