import { CustomBox, CustomText } from './styled';

interface Props {
  icon: React.ReactElement;
  value: string | number;
  text?: string;
}

function TotalAmount({ icon, value, text }: Props) {
  return (
    <CustomBox>
      {icon}
      <CustomText textStyle='titleSm'>
        {value} {text}
      </CustomText>
    </CustomBox>
  );
}

export default TotalAmount;
