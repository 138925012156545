export const LOADING_SCREEN_TEXT = {
  title: 'Estamos procesando tu información',
  description: 'Esto puede demorar unos instantes.',
};

export const LOADING_SEND_TRANSFERS = {
  title: 'Estamos enviando las transferencias',
  description: 'Esto puede demorar unos instantes.',
};

export const TITLE_TOOLTIP_UNIFIED_SHIPPING = 'Lote con envío unificado:';

export const TEXT_TOOLTIP_UNIFIED_SHIPPING = 'Si tiene más de\nuna cuenta de débito, envía todos los lotes\njuntos.';

export const TEXT_MODAL_FAILED_VALIDATE_KEY = {
  title: 'Necesitás crear tu Clave Empresa',
  firstDescription:
    'Esta es una clave única que se crea en la app Interbanking y permite almacenar las claves MAC para enviar transferencias más rápido.',
  secondDescription: 'Para crearla ingresá en la opción “Claves” del menú de la App.',
  altIcon: 'icon-cancel-modal',
  textButtom: 'Entendido',
};

export const TEXT_MODAL_FAILED_TRANSFER_SELECTED_BY_USER = {
  title: 'No podemos enviar las\ntransferencias',
  firstDescription: '¡No te preocupes! Las transferencias seleccionadas se mantienen, solo tenés que volver a intentarlo.',
  altIcon: 'icon-cancel-modal',
  textButtom: 'Reintentar',
  cancelButton: 'Cancelar',
};

export const ZERO_SENDING_MODAL_TEXTS = {
  altIcon: 'icon-cancel-modal',
  title: 'No podemos enviar las\n transferencias',
  description: 'Las transferencias ya no están disponibles, revisá el estado en la sección</br><strong>Reportes.</strong>',
  textButton: 'Entendido',
};

export const TEXT_MODAL_FAILED_NO_TRANSFERS = {
  firstDescription: 'Es probable que las transferencias ya no estén autorizadas, revisalas en la',
  secondDescription: 'sección ',
  firstTextButton: 'Entendido',
  boldText: 'Reportes.',
};

export const TEXT_MODAL_UNIFIED_SHIPPING = {
  title: 'Transferencias con envío unificado',
  firstTextButton: 'Continuar con el envío',
  secondTextButton: 'Revisar transferencias',
  altIcon: 'icon-unifiedshipping-modal',
  unifiedTitleShippingText1:
    'Todas las transferencias del lote deben estar seleccionadas para enviar el lote completo. Estos son los lotes que seleccionaste con envío unificado:',
  unifiedTitleShippingText2: 'Podés filtrar por número de lote para revisar que todas las transferencias estén seleccionadas.',
};

export const ALERT_SUE_TEXT = {
  title: 'El importe no incluye el total de transferencias',
  description: 'Según los permisos de tu perfil, no te podemos mostrar el monto total a transferir.',
};

export const EXCEPTION_CODES = {
  timeOut: 'TEF-0015',
};
