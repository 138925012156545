import { Box, InputLeftElement } from '@chakra-ui/react';
import styled from '@emotion/styled';

const CustomBox = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 72px;
  padding: 12.5px 20px;

  .containerInput {
    width: 100%;
  }
`;

const CustomInputLeftElement = styled(InputLeftElement)`
  pointer-events: none;
`;

export { CustomBox, CustomInputLeftElement };
