import { format } from 'date-fns';

import { DOWNLOAD_FORMAT } from '../constants-app';

const downloadTransfersFile = (content: string, formatFile: DOWNLOAD_FORMAT, fileName: string): boolean => {
  try {
    const fileType = formatFile === DOWNLOAD_FORMAT.csv ? 'csv' : 'plain';
    const blob = new Blob([content], { type: `text/${fileType};charset=utf-8` });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}${format(new Date(), 'yyyyMMdd_HHmmss')}`;
    link.click();
    URL.revokeObjectURL(url);
    return true;
  } catch {
    return false;
  }
};

export { downloadTransfersFile };
