import { SortingState } from '@tanstack/react-table';
import { Dispatch } from 'react';

import { AsyncProcesses, BankAccount, Section, TransfersActionResponseData, TransferTypeByOperator } from '../Interfaces/api';
import { ContextAction, ContextState, DataLot, LotSelected, Option } from '../Interfaces/contextTypes';
import {
  AmountRanges,
  DetailParams,
  File,
  FilterStatus,
  TotalAmountPartialRejectAuth,
  TransferOperationDetails,
  TransferOperationTotals,
  TransfersAccepted,
  TransfersAcceptedPartialRejectAuth,
  TransfersRejected,
  TransferTypeOptionSelected,
} from '../Interfaces/transfers';
import { FiltersApplied, Holiday, ManualTransferData, WorkingDay } from '../Interfaces/transferSetup';
import { actionsTypes, targets } from './constants';

const actionsCreators = (dispatch: Dispatch<ContextAction>, state: ContextState) => ({
  setPageContext: (payload: number) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.pageContext, payload });
  },
  setSizeContext: (payload: number) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.sizeContext, payload });
  },
  setOrderPropertyContext: (payload: string) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.orderPropertyContext, payload });
  },
  setOrderAppliedContext: (payload: SortingState) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.orderAppliedContext, payload });
  },
  setOrderPropertyLotDetailContext: (payload: string) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.orderPropertyLotDetailContext, payload });
  },
  setLotSelectionHandler: (payload: Record<string, boolean>) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.lotSelectionHandler, payload });
  },
  setLotsSelected: (payload: LotSelected[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.lotsSelected, payload });
  },
  setIsFullSelection: (payload: Record<string, boolean>[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.isFullSelection, payload });
  },
  setDataLot: (payload: DataLot[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.dataLot, payload });
  },
  setInputLot: (payload: readonly Option[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.inputLot, payload });
  },
  setFiltered: (payload: FilterStatus) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.filtered, payload });
  },
  setDetailLotId: (payload: string) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.detailLotId, payload });
  },
  setBankCodes: (payload: string[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.bankCodes, payload });
  },
  setResponseOfTransferSelectedByUser: (payload: TransferOperationDetails[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.responseOfTransferSelectedByUser, payload });
  },
  setDataDetailsTransfers: (payload: TransferOperationDetails[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.dataDetailsTransfers, payload });
  },
  setDataTotalsTransfers: (payload: TransferOperationTotals[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.dataTotalsTransfers, payload });
  },
  setTransfersRejected: (value: ((prev: TransfersRejected[]) => TransfersRejected[]) | TransfersRejected[]) => {
    const payload = typeof value === 'function' ? value(state.transfersRejected) : value;
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.transfersRejected, payload });
  },
  setTransfersAccepted: (value: ((prev: TransfersAccepted[]) => TransfersAccepted[]) | TransfersAccepted[]) => {
    const payload = typeof value === 'function' ? value(state.transfersAccepted) : value;
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.transfersAccepted, payload });
  },
  setIsAuthenticated: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.isAuthenticated, payload });
  },
  setServicesWorking: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.servicesWorking, payload });
  },
  setDetailParams: (payload: DetailParams) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.detailParams, payload });
  },
  setPanelSelected: (payload: number) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.panelSelected, payload });
  },
  setHasNextTefDay: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.hasNextTefDay, payload });
  },
  setIsBusinessDay: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.isBusinessDay, payload });
  },
  clearSelectedLots: () => {
    return dispatch({ type: actionsTypes.CLEAR_SELECTED_LOTS, target: '' });
  },
  removeLotsByPendingTableIds: (pendingTableIds: string[]) => {
    const payload = state.dataLot.filter(lot => !pendingTableIds.includes(lot.pendingTableId));
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.dataLot, payload });
  },
  setHasData: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.hasData, payload });
  },
  setSelectedTransfersTypesCodes: (payload: string[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.selectedTransfersTypesCodes, payload });
  },
  setSectionPermissions: (payload: Section[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.sectionPermissions, payload });
  },
  setViewPartialRejecteAuth: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.viewPartialRejecteAuth, payload });
  },
  setTransferTypeAuthReadyToSend: (payload: TransfersAcceptedPartialRejectAuth[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.transferTypeAuthReadyToSend, payload });
  },
  setOtp: (payload: string) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.otp, payload });
  },
  setErrorUseAuthPartial: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.errorUseAuthPartial, payload });
  },
  setTotalAmountPartialReject: (payload: TotalAmountPartialRejectAuth[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.totalAmountPartialReject, payload });
  },
  setLoadingPartialReject: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.loadingPartialReject, payload });
  },
  setManualTransferData: (payload: ManualTransferData) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.manualTransferData, payload });
  },
  setFileSelected: (payload: File) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.fileSelected, payload });
  },
  setTransferTypeOptionSelected: (payload: TransferTypeOptionSelected) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.transferTypeOptionSelected, payload });
  },
  setTransferTypeByOperators: (payload: TransferTypeByOperator[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.transferTypeByOperators, payload });
  },
  setDateSelected: (payload: Date | null) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.dateSelected, payload });
  },
  setAmountRanges: (payload: AmountRanges) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.amountRanges, payload });
  },
  setDebitAccounts: (payload: BankAccount[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.debitAccounts, payload });
  },
  setHolidays: (payload: Holiday[]) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.holidays, payload });
  },
  setWorkingDay: (payload: WorkingDay) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.workingDay, payload });
  },
  setTransferNumber: (payload: string) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.transferNumber, payload });
  },
  setConfectionData: (payload: TransfersActionResponseData) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.confectionData, payload });
  },
  setConfectionReset: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.confectionReset, payload });
  },
  clearConfection: () => {
    return dispatch({ type: actionsTypes.CLEAR_CONFECTION, target: '' });
  },
  setIsExitConfectionModalOpen: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.isExitConfectionModalOpen, payload });
  },
  setRedirectPath: (payload: string) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.redirectPath, payload });
  },
  setOtpConfigured: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.otpConfigured, payload });
  },
  setAsyncProcesses: (payload: AsyncProcesses) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.asyncProcesses, payload });
  },
  setAsyncProcessesLoading: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.asyncProcessesLoading, payload });
  },
  setAsyncProcessesError: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.asyncProcessesError, payload });
  },
  setUpdateAsyncProcesses: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.updateAsyncProcesses, payload });
  },
  setShowAsyncProcesses: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.showAsyncProcesses, payload });
  },
  setIsReferenceActive: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.isReferenceActive, payload });
  },
  setDebitFiltersApplied: (payload: FiltersApplied) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.debitFiltersApplied, payload });
  },
  setCreditFiltersApplied: (payload: FiltersApplied) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.creditFiltersApplied, payload });
  },
  clearDrawerConfectionFilters: () => {
    return dispatch({ type: actionsTypes.CLEAR_DRAWER_CONFECTION_FILTERS_APPLIED, target: '' });
  },
  setStatusButtonModify: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.statusButtonModify, payload });
  },
  setIsLoadingLots: (payload: boolean) => {
    return dispatch({ type: actionsTypes.SET_STATE, target: targets.isLoadingLots, payload });
  },
});

export default actionsCreators;
