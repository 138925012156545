import { useTransfersContext } from '@context/TransfersContext';
import { RevokeData } from '@interfaces/api';
import { ResponseDataLotAndTransfers } from '@interfaces/contextTypes';
import { Lot, Pagination as PaginationInterfaces, ServiceAction, TransfersAccepted, TransfersFlow } from '@interfaces/transfers';
import { LocationState } from '@interfaces/utils/reactRouter';
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { getDetailsOfAllTransfers, getLots, revokeTransfers } from '@services/api';
import { ACTION_TYPE, routesNames } from '@src/constants-app';
import { getServiceParams } from '@utils/configTableFunctions';
import { formatSelectedTransfersTypesCodes } from '@utils/formatSelectedTransfersTypesCodesParam';
import sortDataByGetTransfers from '@utils/sortDataByGetTransfers';
import { PATHNAME_LOT_DETAIL } from '@views/EntityKey/constants';
import { EXCEPTION_CODES, REVOKE_ACTION, REVOKE_ERROR } from '@views/RevokeTransfers/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import useAsyncProcess from './useAsyncProcess';

export default function useRevokeTransfers() {
  const location = useLocation() as LocationState;
  const navigate = useNavigate();
  const {
    pageContext,
    sizeContext,
    lotsSelected,
    dataLot,
    dataDetailsTransfers,
    servicesWorking,
    orderPropertyContext,
    selectedTransfersTypesCodes,
    amountRanges,
    setPageContext,
    setSizeContext,
    setServicesWorking,
    setDataDetailsTransfers,
    setDataTotalsTransfers,
    setIsBusinessDay,
    setHasNextTefDay,
    clearSelectedLots,
    setIsFullSelection,
    setTransfersAccepted,
    setTransfersRejected,
    setHasData,
    setShowAsyncProcesses,
    setIsLoadingLots,
  } = useTransfersContext();

  const [showRevokeConfirmationModal, setShowRevokeConfirmationModal] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [secondTime, setSecondTime] = useState<boolean>(false);
  const [lots, setLots] = useState<Lot[]>([]);
  const [pagination, setPagination] = useState<PaginationInterfaces>({
    totalElements: 0,
    totalPages: 0,
    totalElementsRequested: 0,
    pageNumber: 0,
  });
  const [lotNumber, setLotNumber] = useState<number[]>([]);
  const [tableDataState, setTableDataState] = useState([]);
  const [postDataGetDetails, setPostDataGetDetails] = useState<ResponseDataLotAndTransfers[]>([]);
  const [action, setAction] = useState<REVOKE_ACTION>(REVOKE_ACTION.NONE);
  const [error, setError] = useState<REVOKE_ERROR>(REVOKE_ERROR.NONE);
  const [success, setSuccess] = useState(false);
  const [processId, setProcessId] = useState<number | null>(null);
  const [isRevokedLoadingShown, setIsRevokedLoadingShown] = useState(false);
  const [isReload, setIsReload] = useState(false);

  const reload = () => {
    setLotNumber([]);
    setLots([]);
    setPageContext(1);
    setSizeContext(15);
    clearSelectedLots();
    setFirstTime(false);
    setSecondTime(false);
    setIsReload(true);
    setProcessId(null);
    refetchGetLots();
    setAction(REVOKE_ACTION.NONE);
  };

  const {
    isAsyncProcess,
    isAsyncProcessDone,
    isAsyncProcessError,
    isFetchingAsyncProcess,
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    setIsAsyncProcessError,
    setRetries,
  } = useAsyncProcess(processId, ServiceAction.REV, TransfersFlow.Revoke, setSuccess);

  const fromLotDetail = location.state?.from?.pathname.includes(PATHNAME_LOT_DETAIL);

  const params = {
    size: String(sizeContext),
    page: String(pageContext),
    lotNumber: lotNumber.length > 0 ? lotNumber.join(';') : null,
    transferType: formatSelectedTransfersTypesCodes(selectedTransfersTypesCodes),
    ...getServiceParams(TransfersFlow.Revoke),
    orderProperty: orderPropertyContext,
    ...amountRanges,
    action: ACTION_TYPE.QLI,
  };

  const {
    refetch: refetchGetLots,
    status,
    isFetching: isFetchingLots,
  } = useQuery(['get-lots-transfers', params], () => getLots(params).then(res => res.data), {
    retry: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
        setServicesWorking(false);
      } else {
        setServicesWorking(true);
        setLots(data?.data?.lots);
        setPagination(data?.paging);
        setTableDataState(data);
        setHasData(data?.data?.lots.length > 0);
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Transferencias - Revocar envío',
        });
        if (data?.data?.lots.length < 1) {
          pushAnalyticsEvent({
            event: 'trackContentGroup',
            content_group: 'Transferencias - Revocar envío - Sin TEFs pendientes',
          });
        }
        setFirstTime(true);
      }
    },
    onError: () => {
      setServicesWorking(false);
    },
  });

  const onCloseModalErrorZero = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos revocar el envío - No disponibles',
      item_id: 'Entendido',
    });
    setError(REVOKE_ERROR.NONE);
    reload();
  };

  const onCloseModalSuccess = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal envío revocado',
      item_id: 'Entendido',
    });
    setSuccess(false);
  };

  const onCloseModalAsyncProcess = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Revocando envío de transferencias',
      item_id: 'Volver a transferencias',
    });
    setIsAsyncProcess(false);
    reload();
  };

  const onCloseModalAsyncProcessDone = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Estamos revocando el envío',
      item_id: 'Entendido',
    });
    setRetries(1);
    setIsAsyncProcessDone(false);
    reload();
  };

  const onCloseModalAsyncProcessError = () => {
    setIsAsyncProcessError(false);
    reload();
  };

  const { refetch: refetchGetDetailsOfAllTransfers, isFetching: isFetchingGetDetailsOfAllTransfers } = useQuery(
    ['get-details-all-transfers', postDataGetDetails],
    () => getDetailsOfAllTransfers(postDataGetDetails, ServiceAction.REV).then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
          setAction(REVOKE_ACTION.NONE);
          setError(REVOKE_ERROR.REVOKE);
        } else {
          setDataDetailsTransfers(data?.data?.tranfers);
          setDataTotalsTransfers(data?.data?.totalAmount);
          setIsBusinessDay(data?.data?.isBusinessDay);
          setHasNextTefDay(data?.data?.hasNextTefDay);
          if (data?.data?.tranfers.length === 0) {
            setIsRevokedLoadingShown(false);
            setError(REVOKE_ERROR.ZERO);
            pushAnalyticsEvent({
              event: 'modal_impression',
              modal_name: 'No podemos revocar el envío - No disponibles',
            });
          } else {
            setAction(REVOKE_ACTION.REVOKE);
          }
        }
      },
      onError: () => {
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos revocar el envío - Error servicio',
        });
        setIsRevokedLoadingShown(false);
        setAction(REVOKE_ACTION.NONE);
        setError(REVOKE_ERROR.REVOKE);
      },
    },
  );

  const { mutate: mutationPostTransfersAction, isLoading: isFetchingRevoke } = useMutation(
    (data: RevokeData) => {
      return revokeTransfers(data).then(res => res.data);
    },
    {
      onSuccess: response => {
        const exceptionCode = response?.exception.code;
        if (EXCEPTION_CODES.success === exceptionCode) {
          pushAnalyticsEvent({
            event: 'envio_transferencia_revocado',
            content_group: 'Transferencias - Resumen de revocar envío',
            status: 'Envío revocado exitosamente',
          });
          setSuccess(true);
        } else if (EXCEPTION_CODES.partialRejection === exceptionCode) {
          pushAnalyticsEvent({
            event: 'envio_transferencia_revocado',
            content_group: 'Transferencias - Resumen de revocar envío',
            status: 'Rechazo parcial',
          });
          setTransfersRejected(response.data.rejectedTransferDetails);
          const accepted: TransfersAccepted[] = [];
          response.data.acceptedTransfers.forEach(item => {
            const exist = accepted.findIndex(a => a.numberLot === item.lotNumber);
            if (exist !== -1) {
              accepted[exist].numberTransfer.push(item.orderId);
              accepted[exist].totalTransfer += 1;
            } else {
              accepted.push({
                numberLot: item.lotNumber,
                numberTransfer: [item.orderId],
                totalTransfer: 1,
              });
            }
          });
          setTransfersAccepted(accepted);
          navigate(routesNames.ResultRevoke);
        } else if (EXCEPTION_CODES.totalRejection === exceptionCode) {
          pushAnalyticsEvent({
            event: 'envio_transferencia_revocado',
            content_group: 'Transferencias - Resumen de revocar envío',
            status: 'Rechazo total',
          });
          setTransfersRejected(response.data.rejectedTransferDetails);
          if (response.data.processId) {
            setProcessId(response.data.processId);
          } else {
            navigate(routesNames.ResultRevoke);
          }
        } else if (EXCEPTION_CODES.asyncProcess === exceptionCode) {
          setProcessId(response.data.processId);
        } else if (EXCEPTION_CODES.timeOut === exceptionCode) {
          pushAnalyticsEvent({
            event: 'modal_impression',
            modal_name: 'Estamos revocando el envío',
          });
          setIsAsyncProcessDone(true);
        } else {
          setAction(REVOKE_ACTION.NONE);
          setError(REVOKE_ERROR.REVOKE);
        }
      },
      onError: () => {
        setAction(REVOKE_ACTION.NONE);
        setError(REVOKE_ERROR.REVOKE);
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos revocar el envío - Error servicio',
        });
      },
      onSettled: () => {
        setIsRevokedLoadingShown(false);
      },
    },
  );

  const onFilterLot = (numberLot: number[]) => {
    setPageContext(1);
    setLotNumber(numberLot);
  };

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Revocar envío - Servicio no disponible',
      content_type: 'Error de servicio',
      item_id: 'Reintentar',
    });
  };

  const onClickRetry = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos revocar el envío - Error servicio',
      item_id: 'Reintentar',
    });
    setError(REVOKE_ERROR.NONE);
    setAction(REVOKE_ACTION.GET_DETAILS);
  };

  const onCancel = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos revocar el envío - Error servicio',
      item_id: 'Cancelar',
    });
    setError(REVOKE_ERROR.NONE);
  };

  const onClickRevoke = () => {
    setShowRevokeConfirmationModal(false);
    setError(REVOKE_ERROR.NONE);
    setPostDataGetDetails(sortDataByGetTransfers(lotsSelected, dataLot));
    setAction(REVOKE_ACTION.GET_DETAILS);
    pushAnalyticsEvent({
      event: 'revocar_envio_transferencias',
      content_type: 'Botones Transferencias',
      item_id: 'Revocar envío',
    });
  };

  const isLoading = isFetchingLots || isFetchingGetDetailsOfAllTransfers;
  const hasError = status === 'error' || !servicesWorking;

  useEffect(() => {
    if (!isLoading) {
      setShowAsyncProcesses(!hasError);
    }
  }, [isLoading, hasError]);

  useEffect(() => {
    const lotsSelectedFullSelection = lotsSelected.filter(lotItem => !lotItem.isPartialSelection);
    const rowSelected = lotsSelectedFullSelection.map(lotItem => ({
      [lotItem.id]: true,
    }));
    setIsFullSelection(rowSelected);
  }, [lotsSelected]);

  useEffect(() => {
    if (action === REVOKE_ACTION.REVOKE) {
      setIsRevokedLoadingShown(true);
      setTransfersAccepted([]);
      setTransfersRejected([]);
      const transfers = dataDetailsTransfers.map(item => item.transfer);
      mutationPostTransfersAction({ transfers });
    }
  }, [action]);

  useEffect(() => {
    if (action === REVOKE_ACTION.GET_DETAILS) {
      setIsRevokedLoadingShown(true);
      refetchGetDetailsOfAllTransfers();
    }
  }, [action]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Transferencias',
    });
    if (fromLotDetail) {
      setPageContext(pageContext);
    }
    refetchGetLots();
  }, []);

  useEffect(() => {
    success && reload();
  }, [success]);

  useEffect(() => {
    setSecondTime(!isFetchingLots);
    setIsLoadingLots(!firstTime && !isReload ? isFetchingLots : false);
  }, [firstTime, isFetchingLots, isReload]);

  return {
    error,
    isFetchingRevoke,
    isFetchingLots,
    isFetchingGetDetailsOfAllTransfers,
    lots,
    pageContext,
    pagination,
    firstTime,
    secondTime,
    status,
    sizeContext,
    success,
    tableDataState,
    isAsyncProcess,
    isAsyncProcessDone,
    isAsyncProcessError,
    isFetchingAsyncProcess,
    servicesWorking,
    lotNumber,
    showRevokeConfirmationModal,
    isRevokedLoadingShown,
    handleToggleAnalytics,
    onFilterLot,
    onClickRetry,
    onClickRevoke,
    onCloseModalErrorZero,
    onCloseModalSuccess,
    onCloseModalAsyncProcess,
    onCloseModalAsyncProcessDone,
    onCloseModalAsyncProcessError,
    onCancel,
    setPageContext,
    setSizeContext,
    setFirstTime,
    setSecondTime,
    setShowRevokeConfirmationModal,
  };
}
