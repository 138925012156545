/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */

const vectorBancos = [
  '386',
  '097',
  '165',
  '325',
  '007',
  '065',
  '011',
  '297',
  '014',
  '015',
  '016',
  '017',
  '018',
  '027',
  '029',
  '034',
  '299',
  '046',
  '285',
  '072',
  '150',
  '045',
  '322',
  '060',
  '086',
  '294',
  '191',
  '254',
  '255',
  '305',
  '266',
  '309',
  '311',
  '389',
  '336',
  '341',
  '330',
  '093',
  '321',
  '094',
  '312',
  '247',
  '326',
  '083',
  '338',
  '268',
  '303',
  '079',
  '259',
  '044',
  '020',
  '319',
  '315',
  '426',
  '281',
  '431',
  '277',
  '448',
  '198',
  '147',
  '432',
  '415',
  '301',
  '030',
  '143',
  '131',
  '310',
  '453',
  '408',
  '435',
  '340',
];

const vectorDV = [
  'Q',
  'W',
  'E',
  'R',
  'T',
  'Y',
  'U',
  'I',
  'O',
  'P',
  'A',
  'S',
  'D',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
  'Z',
  'X',
  'C',
  'V',
  'B',
  'N',
  'M',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '=',
];

function getBytes(str) {
  const a = [];
  const k = str.length;
  for (let i = 0; i < k; i++) {
    a[i] = str.charCodeAt(i);
  }
  return a;
}

function Long(s) {
  return parseInt(s, 10);
}

function sumatoriaBytes(macBanco) {
  let ret = 0;
  // el ?ltimo caracter lo paso a " "
  const b = getBytes(`${macBanco.substring(0, 7)} `);
  for (let i = 0; i < b.length; i += 2) {
    // tomo de a 2. Cada byte lo transformo a Hexadecimal.
    ret += b[i] * 256 + b[i + 1];
  }
  return ret;
}

function getBancoPosition(codBanco) {
  for (let i = 0; i < vectorBancos.length; i++) {
    if (vectorBancos[i] === codBanco) {
      return i + 1;
    }
  }
  return -1;
}

/* eslint-disable sonarjs/no-identical-functions */
function getDigito(macBanco, codBanco, codCliente) {
  const posicion = getBancoPosition(codBanco);
  if (posicion < 0) return false;
  const abonado = Long(codCliente.substring(1, 6));
  const result = posicion + abonado + sumatoriaBytes(macBanco);
  const res = result - Math.floor(result / 37) * 37 + 1;
  const dv = vectorDV[res - 1];
  return dv;
}
/* eslint-enable sonarjs/no-identical-functions */

export function checkDigito(macBanco, codBanco, codCliente) {
  const dv = getDigito(macBanco, codBanco, codCliente);
  return dv != null && dv === macBanco.substring(7, 8);
}
