const IDS = {
  filterAccountsDrawer: 'filterAccountsDrawer',
};

const TEXTS = {
  title: 'Filtrá por:',
  clear: 'Borrar filtros',
};

export { IDS, TEXTS };
