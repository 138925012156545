import { CustomBox } from './index.styled';
import RedirectView from './RedirectView';

type Props = {
  setShowBottomNav: (arg: boolean) => void;
};

function LoadFromFile({ setShowBottomNav }: Props) {
  // const [valuesTransferTypesSelected, setValuesTransferTypesSelected] = useState<string | null>('');
  // const { status, valuesTransferTypes, valuesImportModes } = useTransferType({ valuesTransferTypesSelected, statusGetPermission });

  return (
    <CustomBox>
      <RedirectView setShowBottomNav={setShowBottomNav} text='archivo' />
      {/* TODO: Added comment in the code down because created new redirect for a short time  */}
      {/* <BoxRigth>
        <Form
          status={status}
          valuesTransferTypes={valuesTransferTypes}
          setValuesTransferTypesSelected={setValuesTransferTypesSelected}
          valuesImportModes={valuesImportModes}
          setStatusButton={setStatusButton}
        />
      </BoxRigth> */}
    </CustomBox>
  );
}
export default LoadFromFile;
