/* eslint-disable no-plusplus */
import { InputGroup } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import CreatableSelect from 'react-select/creatable';

import { useTransfersContext } from '../../../../context/TransfersContext';
import { Option } from '../../../../Interfaces/transfers';
import { checkIfArraysAreDifferent } from '../../../../utils/checkIfArrayAreEqual';
import BaseScreenFilter from '../BaseScreenFilter';
import {
  HELP_TEXT,
  MAXIMUM_NUMBER_TAGS,
  MENU_TEST_ID_NUMBER_LOTS,
  TEXT_FILTER_TAG,
  TEXT_FIVE_NUMBER_LOTS,
  TEXT_PLACEHOLDER,
  TITLE_HEADER,
} from './constants';
import { CustomBox, CustomInputLeftElement, CustomText } from './styled';

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

interface Props {
  onFilterLot: (numberLot: number[]) => void;
  isFetching: boolean;
  lotNumber: number[];
}

function FilterLots({ onFilterLot, isFetching, lotNumber }: Props) {
  const { setInputLot, inputLot, filtered, setFiltered, setPageContext, pageContext, hasData } = useTransfersContext();
  const [inputValue, setInputValue] = useState<string>('');
  const [listLots, setListLots] = useState<readonly Option[]>(inputLot);
  const [isDisabledApplyButton, setIsDisabledApplyButton] = useState<boolean>(true);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListLots(prev => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const handlerControlLength = (newLotChip: string) => {
    const numericRegex = /^[0-9]+$/;
    if (numericRegex.test(newLotChip)) {
      const exists = listLots?.some((lotItem: Option) => {
        return lotItem.label === newLotChip;
      });
      const newValueInput = newLotChip.length > 3 ? newLotChip.substring(0, 3) : newLotChip;
      setInputValue(exists ? '' : newValueInput);
    } else {
      setInputValue('');
    }
  };

  useEffect(() => {
    if (!filtered.isLotListActive) {
      setListLots(inputLot);
    }
  }, [inputLot]);

  useEffect(() => {
    if (filtered.isLotListActive && inputLot?.length > 0) {
      const listLotsToSearch = inputLot?.length > 0 ? inputLot?.map(inputItem => parseInt(inputItem?.value, 10)) : [];
      onFilterLot(listLotsToSearch);
      setPageContext(pageContext);
    }
  }, []);

  useEffect(() => {
    const listLotsToSearch = inputLot?.length > 0 ? inputLot?.map(inputItem => parseInt(inputItem?.value, 10)) : [];
    const valueLocal = listLots?.length > 0 ? listLots?.map(inputItem => parseInt(inputItem?.value, 10)) : [];
    setIsDisabledApplyButton(checkIfArraysAreDifferent(listLotsToSearch, valueLocal));
  }, [listLots]);

  useEffect(() => {
    const valueLocal = listLots?.length > 0 ? listLots?.map(inputItem => parseInt(inputItem?.value, 10)) : [];
    setIsDisabledApplyButton(checkIfArraysAreDifferent(lotNumber, valueLocal));
  }, [listLots]);

  const handleClearCloseWithOutFilter = () => {
    if (lotNumber.length) {
      const convertedArray = lotNumber.map(number => ({ label: number.toString(), value: number.toString() }));
      setListLots(convertedArray);
    } else if (!filtered.isLotListActive) {
      setListLots([]);
    }
  };

  const applyLotFiltersAndSetFiltered = () => {
    const listLotsToSearch = listLots?.length > 0 && listLots?.map(inputItem => parseInt(inputItem?.value, 10));
    onFilterLot(listLotsToSearch || []);
    setInputLot(listLots);
    setFiltered({
      ...filtered,
      isLotListActive: listLots?.length > 0,
    });
  };

  return (
    <BaseScreenFilter
      titleHeader={TITLE_HEADER}
      textTag={TEXT_FILTER_TAG}
      count={lotNumber.length}
      statusButtonClear={!inputValue && !listLots.length}
      statusButtonApply={isDisabledApplyButton}
      handleClearCloseWithOutFilter={handleClearCloseWithOutFilter}
      handleApplyButton={applyLotFiltersAndSetFiltered}
      onClickClearButton={() => setListLots([])}
      menuTestIdName={MENU_TEST_ID_NUMBER_LOTS}
      testId='lot'
      isSelected={filtered.isLotListActive && lotNumber?.length > 0}
      isDisabled={(!filtered.isLotListActive && !hasData) || isFetching}
    >
      <CustomBox>
        <InputGroup className='groupInput'>
          <CustomInputLeftElement>
            <Search size={24} />
          </CustomInputLeftElement>
          <CreatableSelect
            components={components}
            inputValue={inputValue}
            isClearable={false}
            isMulti
            menuIsOpen={false}
            onChange={newValue => setListLots(newValue)}
            onInputChange={handlerControlLength}
            onKeyDown={handleKeyDown}
            placeholder={TEXT_PLACEHOLDER}
            value={listLots}
            className='containerInput'
            classNamePrefix='react-value-container'
            isDisabled={listLots.length === MAXIMUM_NUMBER_TAGS}
            name='input-lot'
          />
        </InputGroup>
        {listLots.length === MAXIMUM_NUMBER_TAGS && <CustomText textStyle='labelSm'>{TEXT_FIVE_NUMBER_LOTS}</CustomText>}
        {inputValue.length >= 1 && inputValue.length <= 3 && <CustomText textStyle='labelSm'>{HELP_TEXT}</CustomText>}
      </CustomBox>
    </BaseScreenFilter>
  );
}

export default FilterLots;
