import i18next, { Module } from 'i18next';
import { initReactI18next } from 'react-i18next';

import esTranslations from './src/translations/es.json';
import { findBestLanguageTag } from './src/utils/web-localize';

const getLanguage = {
  type: 'languageDetector' as Module['type'],
  init: () => null,
  detect: () => {
    const supportedLanguages = ['es-AR', 'en'];
    const bestLanguageTag = findBestLanguageTag(supportedLanguages);
    return bestLanguageTag?.languageTag || 'es-AR';
  },
  cacheUserLanguage: () => null,
};

i18next
  .use(getLanguage)
  .use(initReactI18next)
  .init({
    lng: 'es-AR',
    fallbackLng: 'es-AR',
    compatibilityJSON: 'v4',
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      'es-AR': {
        translation: esTranslations,
      },
    },
  });

export default i18next;
