import { Box, InputLeftElement, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const CustomBox = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 72px;
  padding: 12.5px 20px;

  .containerInput {
    width: 100%;
  }

  .react-value-container__value-container {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    left: 23px;
    padding: 2px 0px 2px 5px;
  }

  .react-value-container__control:focus-within {
    border-color: #74777f;
    box-shadow: initial;
    border: solid 2px #74777f;
  }

  .react-value-container__multi-value {
    background-color: #fff;
    border: solid 1px #74777f;
    border-radius: 8px;
    height: 24px;
    width: 60px;
    justify-content: space-evenly;
  }

  .react-value-container__multi-value__remove:hover {
    color: inherit;
    background-color: transparent;
  }

  .react-value-container__multi-value__label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .react-value-container__multi-value__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 0px 0px 5px;
  }

  .react-value-container__multi-value__remove:svg {
    width: 18px;
    height: 18px;
  }

  .react-value-container__indicators {
    display: none;
  }

  .react-value-container__input-container {
    width: 90%;
  }

  .react-value-container--is-disabled {
    pointer-events: painted;
  }

  .react-value-container--is-disabled > div > div > .react-value-container__input-container {
    display: none;
  }
`;

const CustomInputLeftElement = styled(InputLeftElement)`
  pointer-events: none;
`;

const CustomText = styled(Text)`
  margin: 0px;
  width: 100%;
`;

export { CustomBox, CustomInputLeftElement, CustomText };
