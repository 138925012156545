import { SystemStyleObject } from '@chakra-ui/react';
import { colors } from '@styled/generalStyles';

const card: SystemStyleObject = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: `1px solid ${colors.Secondary60}`,
  borderRadius: '12px',
  marginBottom: '12px',
};

const header: SystemStyleObject = {
  alignItems: 'center',
  height: '44px',
  padding: '12px',
};

const content: SystemStyleObject = {
  padding: '8px 16px 16px 16px',
};

const footer: SystemStyleObject = {
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
  padding: '12px',
  borderRadius: '0 0 12px 12px',
  backgroundColor: colors.Grey40,
};

const linkButton: SystemStyleObject = {
  padding: 0,
};

export default { card, content, footer, header, linkButton };
