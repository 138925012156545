import { Box, Text } from '@chakra-ui/react';

import { ResultRejectedItem } from '../../../../Interfaces/transferSetup';
import { texts } from '../../constants';
import ResultIcon from '../ResultIcon';
import { CustomBox, withBorder } from './index.styled';

interface Props {
  item: ResultRejectedItem;
  index: number;
}

function RejectedItem({ item, index }: Props) {
  return (
    <Box sx={{ ...CustomBox, ...(index === 0 ? {} : withBorder) }}>
      <ResultIcon icon={item.error.icon} />
      <Box flex={1} marginLeft='10px'>
        <Text textStyle='h6'>{item.error.description}</Text>
      </Box>
      <Text textStyle='bodyMd'>{texts.transfers(item.count)}</Text>
    </Box>
  );
}

export default RejectedItem;
