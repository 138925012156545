import { SystemStyleObject } from '@chakra-ui/react';

const headerTitle: SystemStyleObject = {
  '& + div + button': {
    width: '32px',
    height: '32px',
    top: '32px',
    right: '32px',

    svg: {
      width: '16px',
      height: '16px',
    },
  },
};

export default { headerTitle };
