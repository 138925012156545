import { WizardDetail } from './constants';

const WizardSteps = [
  {
    title: WizardDetail.titleLotDetail,
    description: WizardDetail.descLotDetail,
    target: 'titleDetail',
    placement: 'top',
    offsetExtraY: -20,
    offsetExtraX: 200,
    enableScroll: false,
  },
  {
    title: WizardDetail.titleLotList,
    description: WizardDetail.descLotList,
    target: 'tableDetail',
    placement: 'bottom',
    offsetExtraY: 30,
    offsetExtraX: 0,
  },
  {
    title: WizardDetail.titleTefDetail,
    description: WizardDetail.descTefDetail,
    target: 'tableDetail',
    placement: 'bottom',
    offsetExtraY: 30,
    offsetExtraX: 0,
  },
];

export default WizardSteps;
