import { Box, Flex, Table, Text } from '@chakra-ui/react';
import BottomNav from '@components/BottomNav';
import IconCancelModal from '@components/Icons/icon-cancel-modal';
import IconInfoPopup from '@components/Icons/icon-info-popup';
import CustomModal from '@components/Modal';
import { BUTTONS_TEXTS, CONFECTION_TEXTS, FOOTER_TEXT, VIEW_TEXT } from '@views/TransferSetup/constants';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useEffect } from 'react';
import { DollarSign, Info, Layers } from 'react-feather';

import { useTransferSetupStep3 } from '../../../../hooks/useTransferSetupStep3';
import { colors } from '../../../../styled/generalStyles';
import { texts as step2Texts } from '../Step2/constants';
import SummaryItem from './components/SummaryItem';
import SummarySkeleton from './components/SummarySkeleton';
import { testIds, texts, WIZARD_IDS, WIZARD_STEPS } from './constants';
import { InfoIcon, InfoItem, InfoItemHasDivider } from './index.styled';

function Step3({ isRoute, setIsWizardActive }: { isRoute?: boolean; setIsWizardActive?: (value: boolean) => void }) {
  const {
    manualTransferData,
    hasDescription,
    hasPaymentNumber,
    summary,
    isSummaryLoading,
    isConfectionLoading,
    isConfectionError,
    transferTypeText,
    isAsyncProcess,
    getSummary,
    handleConfection,
    handleCancelConfection,
    handleAsyncModalOk,
    handleRetryConfection,
  } = useTransferSetupStep3();
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();

  const wizardAttempts = Number(localStorage.getItem(WIZARD_IDS.step3Attempts) || '0');

  const isWizardSeen = sessionStorage.getItem(WIZARD_IDS.step3);

  const onCloseWizard = () => {
    sessionStorage.setItem(WIZARD_IDS.step3, 'true');
    localStorage.setItem(WIZARD_IDS.step3Attempts, currentStep + 1 === WIZARD_STEPS.length ? '2' : String(wizardAttempts + 1));
  };

  const setWizardOffset = () => {
    WIZARD_STEPS[0].offsetExtraX = window.innerWidth / 2 - 225;
    WIZARD_STEPS[1].offsetExtraX = window.innerWidth / 2 - 230;
  };

  useEffect(() => {
    setIsWizardActive && setIsWizardActive(initialized && currentStep === 1);
  }, [initialized, currentStep]);

  useEffect(() => {
    if (!isSummaryLoading && !isWizardSeen && wizardAttempts < 2) {
      setInitialized(true);
    }
  }, [isSummaryLoading]);

  useEffect(() => {
    getSummary();
    setWizardOffset();
    window.addEventListener('resize', setWizardOffset);

    return () => {
      window.removeEventListener('resize', setWizardOffset);
    };
  }, []);

  return (
    <Box>
      {isRoute && isConfectionLoading && (
        <LoadingScreen title={CONFECTION_TEXTS.loadingTitle} description={CONFECTION_TEXTS.loadingDescription} />
      )}
      {isRoute && (
        <Text textStyle='h2Bold' whiteSpace='pre-line'>
          {VIEW_TEXT.summaryTitle}
        </Text>
      )}
      <Box margin='12px 0 100px'>
        {isSummaryLoading ? (
          <SummarySkeleton />
        ) : (
          <Flex direction='column'>
            <Flex direction='row' alignItems='center' padding='12px 0'>
              {hasDescription && (
                <Box sx={InfoItem}>
                  <Info color={colors.Secondary100} style={InfoIcon} />
                  <Text as='span' textStyle='labelLg'>
                    {step2Texts.description} <b>{manualTransferData?.description}</b>
                  </Text>
                </Box>
              )}
              {hasPaymentNumber && (
                <Box sx={InfoItem}>
                  <Info color={colors.Secondary100} style={InfoIcon} />
                  <Text as='span' textStyle='labelLg'>
                    {step2Texts.paymentNumber} <b>{manualTransferData?.paymentNumber}</b>
                  </Text>
                </Box>
              )}
              <Box sx={{ ...InfoItem, ...(hasDescription || hasPaymentNumber ? InfoItemHasDivider : {}) }}>
                <DollarSign color={colors.Secondary100} style={InfoIcon} />
                <Text as='span' textStyle='labelLg'>
                  {step2Texts.consolidated} <b>{manualTransferData?.consolidated ? step2Texts.yes : step2Texts.no}</b>
                </Text>
              </Box>
              <Box sx={InfoItem}>
                <Layers color={colors.Secondary100} style={InfoIcon} />
                <Text as='span' textStyle='labelLg'>
                  {step2Texts.unifiedShipping} <b>{manualTransferData?.unifiedShipping ? step2Texts.yes : step2Texts.no}</b>
                </Text>
              </Box>
            </Flex>
            <Box marginBottom='16px'>
              <Text as='span' textStyle='h4Bold'>
                {texts.title}
              </Text>
            </Box>
            <Box id={testIds.summaryTable} margin='-16px -20px' padding='16px 20px'>
              <Table>
                {summary.transfersGroups.map((item, index) => (
                  <SummaryItem key={`${index + 1}`} index={index} data={item} />
                ))}
              </Table>
            </Box>
          </Flex>
        )}
        {initialized && (
          <WizardComponent
            id={WIZARD_IDS.step3}
            steps={WIZARD_STEPS}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            initialized={initialized}
            setInitialized={setInitialized}
            onFinish={onCloseWizard}
            hasWelcome={false}
          />
        )}
        {isRoute && (
          <>
            <BottomNav
              textButtonPrimaryText={FOOTER_TEXT.buttonPrimaryConfirmText}
              clickButtonPrimaryText={handleConfection}
              buttonPrimaryDisabled={isSummaryLoading}
              showTextTransferType
              showTextTransferTypeLoading={isSummaryLoading}
              textTransferType={transferTypeText}
              collapsableTransferType
              customSummary={summary.totals}
              size='md'
            />
            <CustomModal
              isOpen={isConfectionError}
              Icon={IconCancelModal}
              onCancel={handleCancelConfection}
              firstDescription={CONFECTION_TEXTS.errorDescription}
              title={CONFECTION_TEXTS.errorTitle}
              enableFirstButton
              firstTextButton={BUTTONS_TEXTS.cancel}
              enableSecondButton
              secondTextButton={BUTTONS_TEXTS.retry}
              actionButton2={handleRetryConfection}
              alternativeButtonStyling
              size='xl'
              newStyle
            />
            <CustomModal
              isOpen={isAsyncProcess}
              Icon={IconInfoPopup}
              onCancel={handleAsyncModalOk}
              firstDescription={CONFECTION_TEXTS.asyncDescription}
              title={CONFECTION_TEXTS.asyncTitle}
              enableFirstButton
              firstTextButton={BUTTONS_TEXTS.ok}
              size='xl'
              newStyle
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default Step3;
