/* eslint-disable no-nested-ternary */
import { Box, Button, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { AccountingType } from '@interfaces/transfers';
import { CardSection } from '@wow/card';
import { useEffect, useState } from 'react';
import { Clock } from 'react-feather';
import { useQuery } from 'react-query';

import { AccountBalance } from '../../Interfaces/api';
import { getAccountBalances } from '../../services/api';
import { colors } from '../../styled/generalStyles';
import BankLogo from '../BankLogo';
import { accountNameDrawerMaxLength, CardAccountMode, EXCEPTION_CODES, TestIds, texts } from './constants';
import { formatAccountData, formatAccountName, formatLastBalance, formatLastUpdateDate } from './helpers';
import {
  balanceSup,
  Card,
  CardContent,
  cardContentAutoWidth,
  cardFooter,
  cardIcon,
  cardSection,
  container,
  containerBorder,
  containerSelected,
} from './index.styled';
import { Props } from './interfaces';

function CardAccount({
  account,
  accountingType,
  transferType,
  selected = false,
  autoWidth = false,
  mode,
  showsBalanceInfo = false,
  handleSelectAccount,
  handleRemoveSelectedAccount,
  setBalanceError,
  onEditClick,
  statusButtonModify = false,
}: Props) {
  const { accountId, bank, accountName, cuit, cbu, accountType, currency } = account;

  const [accountBalances, setAccountBalances] = useState<AccountBalance[]>([]);
  const [accountBalancesError, setAccountBalancesError] = useState(false);
  const title = formatAccountName(accountName, cuit, mode === CardAccountMode.drawerList ? accountNameDrawerMaxLength : undefined);
  const description = formatAccountData(cbu, accountType, currency);
  const lastBalance = formatLastBalance(accountBalances, currency);
  const lastUpdateDate = formatLastUpdateDate(accountBalances);

  const showBalance =
    showsBalanceInfo ||
    (selected &&
      accountingType === AccountingType.debit &&
      !accountBalancesError &&
      (mode === CardAccountMode.carousel ||
        mode === CardAccountMode.unique ||
        mode === CardAccountMode.selected ||
        mode === CardAccountMode.filterSelected ||
        mode === CardAccountMode.modifyTransferdrawer));

  const { refetch: fetchAccountBalances, isFetching: accountBalancesLoading } = useQuery(
    ['get-account-balances', cbu],
    () => getAccountBalances(cbu)?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success && data?.data.accountBalances.length) {
          setAccountBalances(data?.data.accountBalances);
        } else {
          setAccountBalancesError(true);
        }
      },
      onError: () => {
        setAccountBalancesError(true);
      },
    },
  );

  const handleEditClick = () => {
    if (mode === CardAccountMode.modifyTransferdrawer && onEditClick) {
      onEditClick();
    } else if (handleRemoveSelectedAccount) {
      handleRemoveSelectedAccount();
    }
  };

  useEffect(() => {
    if (setBalanceError) {
      setBalanceError(accountBalancesError);
    }
  }, [accountBalancesError]);

  useEffect(() => {
    if (
      ((mode === CardAccountMode.carousel ||
        mode === CardAccountMode.filterSelected ||
        mode === CardAccountMode.selected ||
        mode === CardAccountMode.unique) &&
        selected &&
        accountingType === AccountingType.debit) ||
      (mode === CardAccountMode.modifyTransferdrawer && accountingType === AccountingType.debit)
    ) {
      setAccountBalancesError(false);
      fetchAccountBalances();
    }
  }, [selected, accountingType, accountId]);

  return (
    <Box
      style={{
        ...container,
        ...(mode === CardAccountMode.carousel ||
        mode === CardAccountMode.unique ||
        ((mode === CardAccountMode.drawerList ||
          mode === CardAccountMode.selected ||
          mode === CardAccountMode.filterList ||
          mode === CardAccountMode.filterSelected) &&
          selected) ||
        mode === CardAccountMode.modifyTransferdrawer
          ? containerBorder
          : {}),
        ...(selected ? containerSelected : {}),
      }}
    >
      <Tooltip
        hasArrow
        placement='top'
        top='5px'
        label={mode === CardAccountMode.drawerList && accountName.length > accountNameDrawerMaxLength ? accountName : null}
      >
        <Card
          id={`${accountingType}-${TestIds.account}${accountId}`}
          data-testid={`${accountingType}-${TestIds.account}${accountId}`}
          aria-selected={selected}
          onClick={() => (selected || !handleSelectAccount ? null : handleSelectAccount(accountId))}
        >
          <CardSection style={cardSection}>
            <Box marginLeft='12px'>
              <BankLogo src={bank.bcoIdBco} transferType={transferType} style={cardIcon} />
            </Box>
            <CardContent style={autoWidth ? cardContentAutoWidth : {}}>
              <Text as='span' textStyle='overlineLg'>
                {bank.bankName}
              </Text>
              <Text as='span' textStyle='titleMdBold'>
                {title}
              </Text>
              <Text as='span' textStyle='bodyMdSemi'>
                {description}
              </Text>
              {showBalance && !accountBalancesLoading && (
                <Text as='span' textStyle='titleMdBold'>
                  {lastBalance.int}
                  <sup style={balanceSup}>{lastBalance.dec}</sup>
                </Text>
              )}
            </CardContent>
            {(mode === CardAccountMode.unique ||
              mode === CardAccountMode.selected ||
              mode === CardAccountMode.filterSelected ||
              mode === CardAccountMode.modifyTransferdrawer) && (
              <Tooltip
                hasArrow
                placement={statusButtonModify ? 'left' : 'top'}
                offset={statusButtonModify ? [0, 24] : undefined}
                label={mode === CardAccountMode.unique ? texts.unique : statusButtonModify && texts.editTooltip}
              >
                <Button
                  variant='primary-text'
                  size='lg'
                  onClick={handleEditClick}
                  padding={0}
                  data-testid={TestIds.removeSelected}
                  aria-label='button-edit'
                  margin='0 8px'
                  style={{ marginRight: statusButtonModify ? '10px' : '0px' }}
                  disabled={mode === CardAccountMode.unique || statusButtonModify}
                >
                  {texts.btnModify}
                </Button>
              </Tooltip>
            )}
          </CardSection>
          {showBalance && (
            <Box sx={cardFooter} data-testid={TestIds.accountFooter}>
              {accountBalancesLoading ? (
                <Spinner borderWidth={2} speed='0.65s' emptyColor='gray.200' color={colors.Primary90} size='sm' />
              ) : (
                <Clock size={16} color={colors.Secondary110} />
              )}
              <Text as='span' textStyle='labelSm' marginLeft='8px'>
                {accountBalancesLoading ? texts.loadingBalance : `${texts.updatedBalance}${lastUpdateDate}`}
              </Text>
            </Box>
          )}
        </Card>
      </Tooltip>
    </Box>
  );
}

export default CardAccount;
