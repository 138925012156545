/* eslint-disable no-shadow */
export type ScrollBehavior = 'inside' | 'outside';

export interface TransferAlert {
  icon: string;
  description: string;
}

export interface ModalErrorsTransfersAlert {
  [key: string]: TransferAlert;
  TEF_NOT_AVAILABLE: TransferAlert;
  TEF_INCOMPLETE_UNIFIED_SHIPPING: TransferAlert;
  TEF_NOT_WORKDAY: TransferAlert;
  TEF_LIMIT_EXCEEDED: TransferAlert;
  TEF_GENERIC_ERROR: TransferAlert;
}

export enum DraftModal {
  add = 'add',
  edit = 'edit',
}

export enum DraftModalAction {
  back = 'back',
  next = 'next',
}
