import { Box, Text } from '@chakra-ui/react';

import { TEXT_MODAL_ERROR_EIGHTH_DIGIT } from '../../../views/EntityKey/constants';

interface Props {
  banksMacError: number;
  bankList: string;
  hasPasswordError: boolean;
}

function ModalChildrenErrorMac({ banksMacError, bankList, hasPasswordError }: Props) {
  return (
    <Box textAlign='center'>
      <Text as='span' textStyle='titleSm'>
        {TEXT_MODAL_ERROR_EIGHTH_DIGIT.firstDescription(banksMacError)}
      </Text>
      <Text as='span' textStyle='titleSmBold'>
        {`${bankList} ${' '}`}
      </Text>
      <Text as='span' textStyle='titleSm'>
        para enviar las transferencias.
      </Text>
      {hasPasswordError && <Text textStyle='titleSm'>{TEXT_MODAL_ERROR_EIGHTH_DIGIT.secondDescription}</Text>}
    </Box>
  );
}

export default ModalChildrenErrorMac;
