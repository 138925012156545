const IDS = {
  usageReferences: {
    menu: 'menuUsageReferences',
    filter: 'filterUsageReferences',
    input: 'inputUsageReferences',
    btnApply: 'btnApplyUsageReferences',
  },
  banks: {
    menu: 'menuBanks',
    filter: 'filterBanks',
    input: 'inputBanks',
    btnApply: 'btnApplyBanks',
  },
};

const TEXTS = {
  usageReferences: {
    title: 'Buscá por referencia de uso',
    tag: 'Referencia de uso',
  },
  banks: {
    title: 'Buscá por banco',
    tag: 'Banco',
  },
  placeholder: 'Buscar',
  empty: 'No hay resultados',
};

export { IDS, TEXTS };
