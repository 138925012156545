import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const drawerNavStyles = {
  height: '88px',
  padding: '0px 24px 0px 52px',
  borderTop: `1px solid ${colors.BlackAlpha400}`,
  left: '0',
};

const openTransferInfStyles = {
  height: '88px',
  padding: '0px 65px 0px 52px',
  borderTop: 'none',
};

const defaultStyles = {
  height: '72px',
  padding: '0px 65px 0px 52px',
  borderTop: 'none',
};

const CustomBoxFooter = styled(Box)<{ drawerNav: boolean; openTransferInf: boolean; isWizardActive?: boolean }>`
  position: fixed;
  left: 64px;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${colors.White};
  box-shadow: ${colors.Shadow2XlReverse};
  -webkit-box-pack: justify;
  -webkit-box-align: center;

  ${({ isWizardActive }) =>
    isWizardActive &&
    `z-index: 101;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: #2c2c2c6f;
      display: block;
      content: '';
    }`}

  ${({ drawerNav, openTransferInf }) => {
    if (drawerNav) return drawerNavStyles;
    if (openTransferInf) return openTransferInfStyles;
    return defaultStyles;
  }}
`;

const buttonMarginStyles = {
  textStyle: 'labelLg',
  marginLeft: '4px',
};

const flexLeftStyle = (drawerNav: boolean) => ({
  alignItems: 'center',
  ...(drawerNav && {
    width: '50%',
    height: '100%',
  }),
});

const flexRightStyle = (drawerNav: boolean) => ({
  alignItems: 'center',
  justifyContent: 'end',
  ...(drawerNav && {
    width: 'auto',
    height: '100%',
  }),
});

const flexCustomInfoTransfer = {
  flexDirection: 'column',
  justifyContent: 'center',
};

const flexCustomInfoTransferWithOutInfo = {
  alignItems: 'center',
  height: '32px',
};

const container = {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '-5px -10px',
  padding: '5px 10px',
};

export {
  buttonMarginStyles,
  container,
  CustomBoxFooter,
  flexCustomInfoTransfer,
  flexCustomInfoTransferWithOutInfo,
  flexLeftStyle,
  flexRightStyle,
};
