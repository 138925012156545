import { Checkbox } from '@chakra-ui/react';

interface Props {
  row: {
    getIsSelected: () => boolean;
    getToggleSelectedHandler: () => (event: unknown) => void;
    getIsSomeSelected: () => boolean;
  };
}

function CheckboxCell({ row }: Props) {
  return (
    <div className='px-1'>
      <Checkbox isChecked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} isIndeterminate={row.getIsSomeSelected()} />
    </div>
  );
}

export default CheckboxCell;
