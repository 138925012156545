/* eslint-disable */
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Icon, Text } from '@chakra-ui/react';
/* eslint-enable */
import { Badge } from '@wow/badge';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'react-feather';

import { pushAnalyticsEvent } from '@wow/utils';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import IconCancelModal from '../../components/Icons/icon-cancel-modal';
import IconCheckMark from '../../components/Icons/icon-checkmark';
import IconBeware from '../../components/Icons/icon-beware';

import { TEXT_MODAL_FAILED_AUTHORIZE } from '../AuthorizationTransfers/constants';
import BaseScreen from '../../components/BaseScreen';
import Footer from '../../components/Footer';
import { useTransfersContext } from '../../context/TransfersContext';
import { ResultError, ResultItem, ServiceAction, TransfersAcceptedPartialRejectAuth, TransfersFlow } from '../../Interfaces/transfers';
import ResultListItem from './components/ResultListItem';
import {
  AUTHORIZATION_ERRORS,
  GENERIC_ERROR,
  TEXTS,
  UNAUTHORIZATION_ERRORS,
  UNANUUL_ERRORS,
  REVOKE_ERRORS,
  VIEW_PARTIAL_REJECT_AUTHORIZATION,
  TEXT_MODAL_SUCCESS_AUTH,
} from './constants';
import { getFlow } from './helper';
import styles from './index.styled';
import { routesNames } from '../../constants-app';
import IconButtonGoUp from '../../components/ButtonGoUp';
import AccordionTransfersAcepted from './components/AccordionTransfersAcepted';
import {
  ASYNC_MODAL_AUTH_ERROR_TEXT,
  TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS,
  TEXT_MODAL_PROCESS_ASYNCHRONOUS,
  TEXT_MODAL_SEND_ASYNC_IS_DONE,
  TEXT_MODAL_SUCCESS,
} from '../EntityToken/constants';
import CustomModal from '../../components/Modal';
import useAuthPartial from '../../hooks/useAuthPartial';
import useAuthentication from '../../hooks/useAuthorization';
import { LOADING_SCREEN_TEXT } from '../LotDetail/constants';
import useAsyncProcess from '../../hooks/useAsyncProcess';
import { TEXT_MODAL_ASYNC_ERROR } from '../UnauthorizeTransfers/constants';

function Result() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    transfersAccepted,
    viewPartialRejecteAuth,
    transfersRejected,
    setInputLot,
    setTransfersRejected,
    setTransfersAccepted,
    clearSelectedLots,
    transferTypeAuthReadyToSend,
    setTransferTypeAuthReadyToSend,
    setViewPartialRejecteAuth,
    setOtp,
    otp,
    totalAmountPartialReject,
    setTotalAmountPartialReject,
    setErrorUseAuthPartial,
  } = useTransfersContext();
  const [lotNumber, setLotNumber] = useState<number[]>([]);
  const { timeOutModalAuthPartial, setTimeOutModalAuthPartial, isLoadingAuthPartial, setIsOpenModalProcessDetails } = useAuthPartial();
  const {
    mutationPostTransfersAction,
    setIsOpenModalGetTransferSelectByUser,
    isOpenModalGetTransferSelectByUser,
    idProcess,
    isOpenModalSuccess,
    setIsOpenModalSuccess,
    setTimeOutModalOpen,
    timeOutModalOpen,
  } = useAuthentication(setOtp, lotNumber, setLotNumber, otp);

  const flow = getFlow(location.pathname);
  let serviceAction = ServiceAction.AUT;
  switch (flow) {
    case TransfersFlow.Unauthorize:
      serviceAction = ServiceAction.DES;
      break;
    case TransfersFlow.Annul:
      serviceAction = ServiceAction.ANU;
      break;
    case TransfersFlow.Revoke:
      serviceAction = ServiceAction.REV;
      break;
    case TransfersFlow.Authorize:
    default:
      serviceAction = ServiceAction.AUT;
      break;
  }

  const {
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    isAsyncProcess,
    isAsyncProcessDone,
    isAsyncProcessError,
    setIsAsyncProcessError,
    setCloseModalCompletely,
  } = useAsyncProcess(idProcess, serviceAction, flow, setShowModalSuccess);
  const [transferData, setTransferData] = useState<TransfersAcceptedPartialRejectAuth[]>([]);

  useEffect(() => {
    setTransferData(transferTypeAuthReadyToSend.slice(0, 15));
  }, [transferTypeAuthReadyToSend]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsVisible(scrollTop > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleAddMoreItems = () => {
    const addTransfers = transferTypeAuthReadyToSend.slice(transferData.length, transferData.length + 15);
    setTransferData(prevTransferData => [...prevTransferData, ...addTransfers]);
  };

  let errors: ResultError[] = [];
  switch (flow) {
    case TransfersFlow.Unauthorize:
      errors = UNAUTHORIZATION_ERRORS;
      break;
    case TransfersFlow.Annul:
      errors = UNANUUL_ERRORS;
      break;
    case TransfersFlow.Revoke:
      errors = REVOKE_ERRORS;
      break;
    default:
      errors = AUTHORIZATION_ERRORS;
      break;
  }

  let transfersRejectedTotal = 0;
  let transfersAcceptedTotal = 0;

  if (transfersAccepted && transfersAccepted.length) {
    transfersAccepted.forEach(item => {
      transfersAcceptedTotal += item.totalTransfer;
    });
  }

  const resultItems: ResultItem[] = transfersRejected.map(item => {
    transfersRejectedTotal += item.totalTransferByMessage;
    const error = errors.find(e => e.code === item.message) || GENERIC_ERROR;
    return { error, count: item.totalTransferByMessage };
  });

  const handleClearPartialRejecteAuth = () => {
    clearSelectedLots();
    setInputLot([]);
    setTransfersAccepted([]);
    setTransfersRejected([]);
    setTransferTypeAuthReadyToSend([]);
    setViewPartialRejecteAuth(false);
    setTotalAmountPartialReject([]);
  };

  const onClickGoToTransfers = () => {
    switch (flow) {
      case TransfersFlow.Authorize:
        pushAnalyticsEvent({
          event: 'select_content',
          content_type: 'Botones modal transferencias autorizadas',
          item_id: 'Ir a transferencias',
        });
        break;
      case TransfersFlow.Unauthorize:
        pushAnalyticsEvent({
          event: 'select_content',
          content_type: 'Botones modal autorizaciones quitadas',
          item_id: 'Ir a transferencias',
        });
        break;
      case TransfersFlow.Annul:
        pushAnalyticsEvent({
          event: 'select_content',
          content_type: 'Botones modal TEFs anuladas',
          item_id: 'Ir a transferencias',
        });
        break;
      case TransfersFlow.Revoke:
        pushAnalyticsEvent({
          event: 'select_content',
          content_type: 'Botones modal TEFs revocadas',
          item_id: 'Ir a transferencias',
        });
        break;
      default:
        break;
    }
    handleClearPartialRejecteAuth();
    clearSelectedLots();
    setInputLot([]);
    setTransfersAccepted([]);
    setTransfersRejected([]);
    navigate(routesNames.Root, { state: { from: location } });
  };

  useEffect(() => {
    if (!transfersAccepted.length && !transfersRejected.length) {
      onClickGoToTransfers();
    }
  }, [transfersAccepted, transfersRejected]);

  const newValueTransfers = transferTypeAuthReadyToSend.map(item => ({
    orderId: item.transferNumber,
    transferTypeCode: item.type,
  }));

  const handleSendTransfersAgain = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal transferencias autorizadas',
      item_id: 'Autorizar',
    });
    if (otp === 'Y') {
      setViewPartialRejecteAuth(false);
      navigate(routesNames.EntityToken);
    } else {
      mutationPostTransfersAction({ transfers: newValueTransfers });
    }
  };

  const handleUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const closeModalGetTransferSelectByUser = () => {
    clearSelectedLots();
    setErrorUseAuthPartial(false);
    setIsOpenModalProcessDetails(false);
    setIsOpenModalGetTransferSelectByUser(false);
    navigate('/', { state: { from: location } });
  };

  const handleRetryAgain = () => {
    setErrorUseAuthPartial(false);
    setIsOpenModalProcessDetails(false);
    setIsOpenModalGetTransferSelectByUser(false);
    mutationPostTransfersAction({ transfers: newValueTransfers });
  };

  const onCloseModalProcessedAsynchronouslyGoToTransfers = () => {
    setIsAsyncProcess(false);
    setCloseModalCompletely(true);
    clearSelectedLots();
    navigate('/', { state: { from: location } });
  };

  const onCloseModalasyncProcessStatusIsDoneyGoToTransfers = () => {
    setIsAsyncProcessDone(false);
    clearSelectedLots();
    navigate('/', { state: { from: location } });
  };

  const onCloseModalSuccessGoToTransfers = () => {
    setShowModalSuccess(false);
    setTotalAmountPartialReject([]);
    clearSelectedLots();
    navigate('/');
  };

  const onCloseModalSuccessGoToHome = () => {
    setShowModalSuccess(false);
    clearSelectedLots();
    setTotalAmountPartialReject([]);
    const host = window.location.href.split('/transferencias')[0];
    window.location.href = host;
  };

  const handleClickModalSuccessSend = () => {
    handleClearPartialRejecteAuth();
    clearSelectedLots();
    setTotalAmountPartialReject([]);
    setIsOpenModalSuccess(false);
  };

  const onCloseModalTimeOut = () => {
    setTimeOutModalOpen(false);
    clearSelectedLots();
    navigate('/', { state: { from: location } });
  };

  return (
    <BaseScreen
      title={viewPartialRejecteAuth ? VIEW_PARTIAL_REJECT_AUTHORIZATION.title : TEXTS[flow].title}
      sizeTitle='h1'
      id='result'
      subTitle={VIEW_PARTIAL_REJECT_AUTHORIZATION.subTitle}
      isActiveSubTitle={viewPartialRejecteAuth}
    >
      {isLoadingAuthPartial || viewPartialRejecteAuth
        ? (isLoadingAuthPartial || !transferData.length) && (
            <LoadingScreen title={LOADING_SCREEN_TEXT.title} description={LOADING_SCREEN_TEXT.description} />
          )
        : ''}
      {isVisible && <IconButtonGoUp handleUp={handleUp} />}
      {transfersAcceptedTotal > 0 && (
        <Box sx={styles.listHeaderSuccessStyle}>
          <Box marginRight={!viewPartialRejecteAuth ? '38px' : '78px'}>
            <Badge variant='success-light'>{TEXTS.success}</Badge>
          </Box>
          <Box sx={styles.boxStyle}>
            <Text textStyle='h5Bold'>{TEXTS[flow].successTitle(transfersAcceptedTotal)}</Text>
          </Box>
        </Box>
      )}
      {viewPartialRejecteAuth && (
        <AccordionTransfersAcepted
          transfersAcceptedTotal={transfersAcceptedTotal}
          transfers={transferData}
          handleAddMoreItems={handleAddMoreItems}
          totalTransfers={transferTypeAuthReadyToSend.length}
        />
      )}
      <Accordion
        allowToggle
        defaultIndex={0}
        sx={{
          ...styles.accordion,
          marginTop: transfersAcceptedTotal ? '0px' : '12px',
        }}
      >
        <AccordionItem
          sx={{
            ...styles.accordionItem,
            ...(transfersAcceptedTotal > 0 || viewPartialRejecteAuth ? styles.accordionItemWithoutBorder : {}),
          }}
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton sx={styles.accordionButton}>
                <Flex sx={styles.accordionButtonMain}>
                  <Flex sx={styles.badge}>
                    <Badge variant='error-light'>{TEXTS.error}</Badge>
                  </Flex>
                  <Flex sx={styles.accordionButtonTexts}>
                    <Text textStyle='h5Bold'>{TEXTS[flow].accordionTitle(transfersRejectedTotal)}</Text>
                    <Text textStyle='labelMd' dangerouslySetInnerHTML={{ __html: TEXTS[flow].accordionDescription }} />
                  </Flex>
                </Flex>
                <Icon as={isExpanded ? ChevronUp : ChevronDown} sx={styles.chevron} />
              </AccordionButton>
              <AccordionPanel sx={styles.accordionPanel}>
                {resultItems.map((item, index) => (
                  <ResultListItem key={item.error.code} item={item} index={index} />
                ))}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <Footer
        statusButtom={false}
        onSubmit={onClickGoToTransfers}
        textButton={TEXTS.goToTransfers}
        secondTextButton='Autorizar'
        isActivebutton={!!transferData.length}
        handleSendTransfersAgain={handleSendTransfersAgain}
        totalAmountPartialReject={totalAmountPartialReject}
      />
      <CustomModal
        isOpen={timeOutModalAuthPartial}
        enableFirstButton
        actionButton={() => setTimeOutModalAuthPartial(false)}
        firstTextButton={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstTextButton}
        firstDescription={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstDescription}
        Icon={IconInfoPopup}
        title={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.title}
        size='xl'
        onCancel={setTimeOutModalAuthPartial}
      />
      <CustomModal
        isOpen={isOpenModalGetTransferSelectByUser}
        onCancel={setIsOpenModalGetTransferSelectByUser}
        Icon={IconCancelModal}
        altIcon={TEXT_MODAL_FAILED_AUTHORIZE.altIcon}
        title={TEXT_MODAL_FAILED_AUTHORIZE.title}
        firstDescription={TEXT_MODAL_FAILED_AUTHORIZE.firstDescription}
        firstTextButton={TEXT_MODAL_FAILED_AUTHORIZE.cancelButton}
        secondTextButton={TEXT_MODAL_FAILED_AUTHORIZE.textButtom}
        actionButton={closeModalGetTransferSelectByUser}
        actionButton2={handleRetryAgain}
        size='xl'
        enableFirstButton
        enableSecondButton
        alternativeButtonStyling
        cancelType
      />
      <CustomModal
        isOpen={isAsyncProcess}
        enableFirstButton
        actionButton={onCloseModalProcessedAsynchronouslyGoToTransfers}
        firstTextButton={TEXT_MODAL_PROCESS_ASYNCHRONOUS.firstTextButton}
        firstDescription={TEXT_MODAL_PROCESS_ASYNCHRONOUS.firstDescription}
        iconSpinner
        title={TEXT_MODAL_PROCESS_ASYNCHRONOUS.title}
        size='xl'
        onCancel={setIsAsyncProcess}
      />
      <CustomModal
        isOpen={isAsyncProcessDone}
        enableFirstButton
        actionButton={onCloseModalasyncProcessStatusIsDoneyGoToTransfers}
        firstTextButton={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstTextButton}
        firstDescription={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstDescription}
        Icon={IconInfoPopup}
        title={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.title}
        size='xl'
        onCancel={setIsAsyncProcessDone}
      />
      <CustomModal
        isOpen={showModalSuccess}
        enableFirstButton
        actionButton={onCloseModalSuccessGoToHome}
        firstTextButton={TEXT_MODAL_SUCCESS.goToHome}
        alternativeButtonStyling
        enableSecondButton
        actionButton2={onCloseModalSuccessGoToTransfers}
        secondTextButton={TEXT_MODAL_SUCCESS.goToTransfers}
        Icon={IconCheckMark}
        title={TEXT_MODAL_SUCCESS.title}
        size='xl'
        onCancel={onCloseModalSuccessGoToTransfers}
        cancelType
      />
      <CustomModal
        isOpen={isOpenModalSuccess}
        onCancel={setIsOpenModalSuccess}
        Icon={IconCheckMark}
        altIcon={TEXT_MODAL_SUCCESS_AUTH.altIcon}
        title={TEXT_MODAL_SUCCESS_AUTH.title}
        firstTextButton={TEXT_MODAL_SUCCESS_AUTH.textButtom}
        actionButton={handleClickModalSuccessSend}
        enableFirstButton
        size='xl'
        cancelType
      />
      <CustomModal
        isOpen={isAsyncProcessError}
        Icon={IconBeware}
        onCancel={setIsAsyncProcessError}
        altIcon={TEXT_MODAL_SEND_ASYNC_IS_DONE.altIcon}
        firstDescriptionWithBold={ASYNC_MODAL_AUTH_ERROR_TEXT}
        title={TEXT_MODAL_ASYNC_ERROR.title}
        firstTextButton={TEXT_MODAL_SEND_ASYNC_IS_DONE.firstTextButton}
        enableFirstButton
        actionButton={() => setIsAsyncProcessError(false)}
        size='xl'
        cancelType
      />
      <CustomModal
        isOpen={timeOutModalOpen || timeOutModalAuthPartial}
        enableFirstButton
        actionButton={onCloseModalTimeOut}
        firstTextButton={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstTextButton}
        firstDescription={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.firstDescription}
        Icon={IconInfoPopup}
        title={TEXT_MODAL_INFORMATION_ASYNCHRONOUS_PROCESS.title}
        size='xl'
        onCancel={setTimeOutModalOpen}
      />
    </BaseScreen>
  );
}

export default Result;
