const CustomAlertErrorBox = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  width: '100%',
};

const CustomAlertTextStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
  margin: '0px',
  textAlign: 'center',
};

const CustomAlertBottomTextStyle = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '0.4px',
  lineHeight: '16px',
  margin: '0px',
  marginBottom: '8px',
  textAlign: 'center',
};

const ContainerCloseButtonStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
};

export { ContainerCloseButtonStyles, CustomAlertBottomTextStyle, CustomAlertErrorBox, CustomAlertTextStyle };
