import { Currencies } from '@interfaces/transfers';
import { format, parseISO } from 'date-fns';

import { CURRENCY_DOLAR, CURRENCY_PESOS, TRANSFER_TYPE_CODE } from '../constants-app';
import { LotTransfer } from '../Interfaces/transferSetup';
import { texts } from '../views/ConfectionResult/constants';
import { TRANSFER_TYPE } from '../views/LotDetail/constants';
import { convertAmountNumberToText } from './convertAmountNumberToText.ts';

const generateTransfContent = (
  lotNumber: number | undefined,
  transferType: string,
  transfers: LotTransfer[],
  isCSVFormat: boolean,
): string => {
  let content = '';
  if (transfers.length > 0) {
    if (lotNumber) {
      content = `Transferencias confeccionadas con éxito${isCSVFormat ? `${texts.separator};` : ''}\n`;
      content += `Lote;${lotNumber}${isCSVFormat ? texts.separator : ''}\n`;
    } else {
      content = `Transferencias por confeccionar${isCSVFormat ? `${texts.separator};` : ''}\n`;
    }
    content += `Tipo de transferencia;${TRANSFER_TYPE[transferType] || ''}${isCSVFormat ? texts.separator : ''}\n`;

    const columns = [
      'Banco cuenta débito',
      'CBU cuenta débito',
      'Denominación cuenta débito',
      'CUIT Cuenta débito',
      'Tipo de cuenta débito',
      'Banco cuenta crédito',
      'CBU cuenta crédito',
      'Denominación cuenta crédito',
      'CUIT Cuenta crédito',
      'Tipo de cuenta crédito',
      'Importe',
      'Moneda',
      'Fecha de solicitud',
      'Observación',
    ];

    if (lotNumber) {
      columns.unshift('Número de transferencia');
    }

    if (transferType === TRANSFER_TYPE_CODE.TER) {
      columns.push('Concepto');
    }
    content += `${columns.join(';')}\n`;

    const transferRows = transfers.map((transferItem: LotTransfer) => {
      const {
        transferNumber,
        debitAccount,
        creditAccount,
        transferData: { amount, date, observation, concept },
      } = transferItem;

      const debitBankName = debitAccount?.bank.bankName || '';
      const debitCbu = debitAccount?.cbu || '';
      const debitAccountName = debitAccount?.accountName || '';
      const debitCuit = debitAccount?.cuit || '';
      const debitAccountType = debitAccount?.accountType || '';

      const creditBankName = creditAccount?.bank.bankName || '';
      const creditCbu = creditAccount?.cbu || '';
      const creditAccountName = creditAccount?.accountName || '';
      const creditCuit = creditAccount?.cuit || '';
      const creditAccountType = creditAccount?.accountType || '';
      const currency = debitAccount?.currency === Currencies.ARS ? CURRENCY_PESOS : CURRENCY_DOLAR;

      return `${
        lotNumber ? `${transferNumber};` : ''
      }${debitBankName};${debitCbu};${debitAccountName};${debitCuit};${debitAccountType};${creditBankName};${creditCbu};${creditAccountName};${creditCuit};${creditAccountType};${convertAmountNumberToText(
        amount,
      )};${currency};${format(parseISO(date), 'dd/MM/yyyy')};${observation || ''}${
        transferType === TRANSFER_TYPE_CODE.TER ? `;${concept?.label || ''}` : ''
      }`;
    });

    content += transferRows.join('\n');
    content += `${isCSVFormat ? `\n${`${texts.separator};`}` : ''}`;
  }
  return content;
};

export { generateTransfContent };
