import { AsyncProcesses, AsyncProcessStatus } from '@interfaces/api';
import { colors } from '@styled/generalStyles';
import { CheckCircle, Info, XCircle } from 'react-feather';

import { TEXTS } from './constants';
import { AsyncProcessesMode, AsyncProcessesStatusesCount } from './interfaces';

function hasProcessesInProgress(processes: AsyncProcesses | undefined) {
  const inProgress = processes?.processes
    ? processes?.processes.some(item => item.status === AsyncProcessStatus.inProcess || item.status === AsyncProcessStatus.awaitingResult)
    : false;
  return processes?.totalElements && inProgress;
}

function getStatusesCount(statuses: AsyncProcessStatus[]) {
  const statusesCount: AsyncProcessesStatusesCount = {
    total: statuses.length,
    inProgress: 0,
    success: 0,
    error: 0,
  };

  statuses.forEach(item => {
    switch (item) {
      case AsyncProcessStatus.inProcess:
      case AsyncProcessStatus.awaitingResult:
        statusesCount.inProgress += 1;
        break;
      case AsyncProcessStatus.success:
        statusesCount.success += 1;
        break;
      case AsyncProcessStatus.totalRejection:
      case AsyncProcessStatus.partialRejection:
      case AsyncProcessStatus.pending:
      default:
        statusesCount.error += 1;
        break;
    }
  });

  return statusesCount;
}

function getMode(statusesCount: AsyncProcessesStatusesCount): AsyncProcessesMode {
  if (statusesCount.inProgress) {
    return AsyncProcessesMode.inProgress;
  }
  if (statusesCount.success && !statusesCount.error) {
    return AsyncProcessesMode.success;
  }
  if (statusesCount.error && !statusesCount.success) {
    return AsyncProcessesMode.error;
  }
  return AsyncProcessesMode.result;
}

function getView(mode: AsyncProcessesMode) {
  const view = {
    icon: <Info size='18px' color={colors.Primary100} />,
    text: TEXTS.titleResult,
    linkText: TEXTS.viewResult,
    linkColor: colors.Primary100,
    linkHoverColor: colors.Primary90,
    backgroundColor: colors.Primary50,
  };
  switch (mode) {
    case AsyncProcessesMode.inProgress:
      view.text = TEXTS.titleInProgress;
      view.linkText = TEXTS.viewProcess;
      return view;
    case AsyncProcessesMode.success:
      view.icon = <CheckCircle size='18px' color={colors.Success100} />;
      view.linkColor = colors.Success90;
      view.linkHoverColor = colors.Success80;
      view.backgroundColor = colors.Success50;
      return view;
    case AsyncProcessesMode.error:
      view.icon = <XCircle size='18px' color={colors.Error110} />;
      view.linkColor = colors.Error100;
      view.linkHoverColor = colors.Error110;
      view.backgroundColor = colors.Error50;
      return view;
    case AsyncProcessesMode.result:
    default:
      return view;
  }
}

export { getMode, getStatusesCount, getView, hasProcessesInProgress };
