import { Box, MenuList, SystemStyleObject } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../../../styled/generalStyles';

const Header = styled(Box)`
  align-items: center;
  border-bottom: solid 1px ${colors.BlackAlpha400};
  display: flex;
  flex-direction: row;
  height: 61px;
  justify-content: space-between;
  padding: 12.5px 20px;
`;

const Footer = styled(Box)`
  align-items: center;
  border-top: solid 1px ${colors.BlackAlpha400};
  display: flex;
  justify-content: space-between;
  height: 65px;
  padding: 12px;
`;

const CustomMenuButton: SystemStyleObject = {
  '& button span': {
    fontSize: '13px',
    fontWeight: '600',
  },
};

const CustomMenuList = styled(MenuList)`
  width: 354px;
  padding: 0px;
`;

const CustomButton: SystemStyleObject = {
  height: '40px',
  width: '160px',
};

export { CustomButton, CustomMenuButton, CustomMenuList, Footer, Header };
