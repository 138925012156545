import { Box, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../../styled/generalStyles';

const CustomBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const CustomBoxSubtitle = styled(Box)`
  display: flex;
  align-items: center;
  margin: 12px 0px 12px 0px;
`;

const CustomTextDetailsTransferDate = {
  borderRadius: '4px',
  color: colors.White,
  marginRight: '15px',
  padding: '2px 4px',
};

const CustomTextDetailsTransfer = {
  margin: '0px 15px 0px 0px',
};

const CustomTextDetailsBank = {
  margin: '0px 0px 0px 5px',
};

const CustomTextDenomination = {
  marginLeft: '5px',
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const CustomImage = styled(Image)`
  margin-right: 5px;
`;

const CustomTextSeparator = {
  alignItems: 'center',
  display: 'flex',
  margin: '0 5px 0 5px',
};

const CustomTextSeparatorSmall = {
  alignItems: 'center',
  display: 'flex',
  marginLeft: '5px',
  marginRight: '5px',
};

const CustomBoxLotDetail = {
  alignItems: 'center',
  marginBottom: '20px',
};

export {
  CustomBox,
  CustomBoxLotDetail,
  CustomBoxSubtitle,
  CustomImage,
  CustomTextDenomination,
  CustomTextDetailsBank,
  CustomTextDetailsTransfer,
  CustomTextDetailsTransferDate,
  CustomTextSeparator,
  CustomTextSeparatorSmall,
};
