import { WizardToken } from './constants';

const WizardSteps = [
  {
    title: WizardToken.titleToken,
    description: WizardToken.descToken,
    target: 'token',
    placement: 'top',
    offsetExtraY: -20,
    offsetExtraX: -40,
    enableScroll: false,
  },
  {
    title: WizardToken.titleSummary,
    description: WizardToken.descSummary,
    target: 'authSummary',
    placement: 'top',
    offsetExtraY: -20,
    offsetExtraX: 0,
  },
  {
    title: WizardToken.titleTokenInput,
    description: WizardToken.descTokenInput,
    target: 'inputToken',
    placement: 'right',
    offsetExtraY: 0,
    offsetExtraX: -20,
  },
];

export default WizardSteps;
