/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import { Box, Skeleton } from '@chakra-ui/react';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { TablePaginationComponent } from '@wow/table-pagination';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomTable from '../../components/CustomTable';
import FilterTable from '../../components/FilterTable';
import IconCancelModal from '../../components/Icons/icon-cancel-modal';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import CustomModal from '../../components/Modal';
import UnifiedShippingChildren from '../../components/Modal/UnifiedShippingChildrenModal';
import ViewNoResult from '../../components/ViewNoResult';
import { ACTION_TYPE, ACTIONS_TO_SEND, DataMessageErrorRequest, PAGE_ACTIONS, routesNames } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import {
  ActionFunction,
  Lot,
  Pagination as PaginationInterfaces,
  ServiceAction,
  TableDataStateType,
  TransfersFlow,
} from '../../Interfaces/transfers';
import { LocationState } from '../../Interfaces/utils/reactRouter';
import { getDetailsOfAllTransfers, getLots, getNameCompany } from '../../services/api';
import { getServiceParams, PENDING_TRANSFERS } from '../../utils/configTableFunctions';
import { filterByUnifiedShippingY } from '../../utils/filterUnifiedShipping';
import { formatSelectedTransfersTypesCodes } from '../../utils/formatSelectedTransfersTypesCodesParam';
import sortDataByGetTransfers from '../../utils/sortDataByGetTransfers';
import { PATHNAME_ENTITY_KEY, PATHNAME_LOT_DETAIL } from '../EntityKey/constants';
import { COLUMNS } from './configTable';
import {
  EXCEPTION_CODES,
  LOADING_SCREEN_TEXT,
  TEXT_MODAL_FAILED_TRANSFER_SELECTED_BY_USER,
  TEXT_MODAL_UNIFIED_SHIPPING,
  ZERO_SENDING_MODAL_TEXTS,
} from './constants';
import { CustomTd, CustomTh, Paginator } from './index.styled';
import getBankCodes from './utils/GetBankCodes';

function SendingTransfers() {
  const navigate = useNavigate();
  const location = useLocation() as LocationState;
  const fromEntityKEy = location.state?.from?.pathname === PATHNAME_ENTITY_KEY;
  const fromLotDetail = location.state?.from?.pathname.includes(PATHNAME_LOT_DETAIL);
  const {
    lotsSelected,
    dataLot,
    pageContext,
    setPageContext,
    sizeContext,
    setSizeContext,
    setBankCodes,
    setResponseOfTransferSelectedByUser,
    dataDetailsTransfers,
    setDataDetailsTransfers,
    setDataTotalsTransfers,
    setIsAuthenticated,
    clearSelectedLots,
    setServicesWorking,
    setIsBusinessDay,
    setHasNextTefDay,
    panelSelected,
    setIsFullSelection,
    setHasData,
    orderPropertyContext,
    selectedTransfersTypesCodes,
    amountRanges,
    setShowAsyncProcesses,
    setIsLoadingLots,
    sectionPermissions,
  } = useTransfersContext();

  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [secondTime, setSecondTime] = useState<boolean>(false);
  const [lots, setLots] = useState<Lot[]>([]);
  const [pagination, setPagination] = useState<PaginationInterfaces>({
    totalElements: 0,
    totalPages: 0,
    totalElementsRequested: 0,
    pageNumber: 0,
  });
  const [isValidateKey, setIsValidateKey] = useState<boolean>();
  const [isOpenUnifiedShippingModal, setIsOpenUnifiedShippingModal] = useState<boolean>(false);
  const [lotNumber, setLotNumber] = useState<number[]>([]);
  const [isOpenModalGetTransferSelectByUser, setIsOpenModalGetTransferSelectByUser] = useState<boolean>(false);
  const [isOpenModalZeroTransfers, setIsOpenModalZeroTransfers] = useState<boolean>(false);
  const [flagValidateKey, setFlagValidateKey] = useState<boolean>(false);
  const [tableDataState, setTableDataState] = useState([]);
  const [isSendingLotsLoading, setSendingLotsLoading] = useState<boolean>(false);
  const [isTimeOutLotError, setIsTimeOutLotError] = useState<boolean>(false);
  const [isReload, setIsReload] = useState(false);
  const firstRenderRef = useRef(true);
  const filterUnifiedLot = filterByUnifiedShippingY(lotsSelected);

  const params = {
    size: String(sizeContext),
    page: String(pageContext),
    lotNumber: lotNumber.length > 0 ? lotNumber.join(';') : null,
    transferType: formatSelectedTransfersTypesCodes(selectedTransfersTypesCodes),
    ...getServiceParams(TransfersFlow.Send),
    orderProperty: orderPropertyContext,
    ...amountRanges,
    action: ACTION_TYPE.LIB,
  };

  const { refetch, status, isFetching } = useQuery(['get-lots-transfers', params], () => getLots(params).then(res => res.data), {
    retry: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
        setIsTimeOutLotError(true);
        setServicesWorking(false);
      } else {
        setServicesWorking(true);
        setLots(data?.data?.lots);
        setPagination(data?.paging);
        setTableDataState(data);
        setHasData(data?.data?.lots.length > 0);
      }
      setFirstTime(true);
    },
    onError: () => {
      setServicesWorking(false);
    },
  });

  const { refetch: refetchValidateKey } = useQuery(['validate-key'], () => getNameCompany().then(res => res.data), {
    retry: false,
    enabled: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
        setIsOpenModalGetTransferSelectByUser(true);
        setSendingLotsLoading(false);
      } else {
        setIsValidateKey(true);
        setIsAuthenticated(data?.data?.isValidateKey);
        setFlagValidateKey(!flagValidateKey);
      }
    },
    onError: () => {
      pushAnalyticsEvent({
        event: 'modal_impression',
        modal_name: 'No podemos hacer el envio',
      });
      setSendingLotsLoading(false);
      setIsOpenModalGetTransferSelectByUser(true);
    },
  });

  const { refetch: refetchGetDetailsOfAllTransfers, isFetching: isFetchingGetDetailsOfAllTransfers } = useQuery(
    ['get-details-all-transfers'],
    () => getDetailsOfAllTransfers(sortDataByGetTransfers(lotsSelected, dataLot), ACTIONS_TO_SEND[panelSelected]).then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
          setIsOpenModalGetTransferSelectByUser(true);
        } else {
          setDataDetailsTransfers(data?.data?.tranfers);
          setResponseOfTransferSelectedByUser(data?.data?.tranfers);
          setBankCodes(getBankCodes(data?.data?.tranfers));
          setDataTotalsTransfers(data?.data?.totalAmount);
          setIsBusinessDay(data?.data?.isBusinessDay);
          setHasNextTefDay(data?.data?.hasNextTefDay);
          if (data?.data?.tranfers.length === 0) {
            setIsOpenModalZeroTransfers(true);
          } else {
            navigate(routesNames.EntityKey);
          }
        }
      },
      onError: () => {
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'No podemos hacer el envio',
        });
        setIsOpenModalGetTransferSelectByUser(true);
      },
      onSettled: () => {
        setSendingLotsLoading(false);
      },
    },
  );

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Transferencias',
    });
  }, []);

  useEffect(() => {
    if (fromEntityKEy) {
      setPageContext(1);
    } else if (fromLotDetail) {
      setPageContext(pageContext);
    }
  }, [isValidateKey, flagValidateKey]);

  const handleOnClickSend = () => {
    setSendingLotsLoading(true);
    pushAnalyticsEvent({
      event: 'enviar_transferencia',
      content_type: 'Botones Transferencias',
      item_id: 'Enviar',
    });
    if (filterUnifiedLot.length) {
      pushAnalyticsEvent({
        event: 'modal_impression',
        modal_name: 'Transferencias con envio unificado',
      });
      setIsOpenUnifiedShippingModal(true);
    } else {
      refetchValidateKey();
    }
  };

  const reload = () => {
    setPageContext(1);
    setSizeContext(15);
    setLots([]);
    setLotNumber([]);
    clearSelectedLots();
    setFirstTime(false);
    setSecondTime(false);
    setIsReload(true);
    refetch();
  };

  useEffect(() => {
    if (!firstRenderRef.current) {
      refetchGetDetailsOfAllTransfers();
    } else {
      firstRenderRef.current = false;
    }
  }, [isValidateKey, flagValidateKey]);

  const handleClickContinueModal = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Transferencias con envio unificado',
      item_id: 'Continuar con el envio',
    });
    refetchValidateKey();
    setIsOpenUnifiedShippingModal(false);
  };

  const onFilterLot = (numberLot: number[]) => {
    setPageContext(1);
    setLotNumber(numberLot);
  };

  const retryGetTransfer = () => {
    setIsOpenModalGetTransferSelectByUser(false);
    refetchGetDetailsOfAllTransfers();
  };

  const retryKeyValidation = () => {
    setIsOpenModalGetTransferSelectByUser(false);
    refetchValidateKey();
  };

  const retryAction = (actionFunction: ActionFunction) => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos hacer el envio',
      item_id: 'Reintentar',
    });
    setIsOpenModalGetTransferSelectByUser(false);
    actionFunction();
  };

  const closeModalKeyValidation = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'No podemos hacer el envio',
      item_id: 'Cancelar',
    });
    setIsOpenModalGetTransferSelectByUser(false);
  };

  const handleClickReturnToTransfers = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Transferencias con envio unificado',
      item_id: 'Revisar transferencias',
    });
    setIsOpenUnifiedShippingModal(false);
    setSendingLotsLoading(false);
  };

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Servicio no disponible',
      content_type: 'No pudimos cargar los datos solicitados',
      item_id: 'Reintentar',
    });
  };

  const handlePageNavigation = (pageNumber: number, action: string) => {
    setPageContext(pageNumber);
    pushAnalyticsEvent({
      event: 'switch_page',
      selection_type: `${pageNumber} | ${action}`,
    });
  };

  const handleCloseModalZeroTransfers = () => {
    reload();
    setIsOpenModalZeroTransfers(false);
  };

  useEffect(() => {
    if (status === 'error') {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Transferencias - Servicio no disponible',
      });
    }
  }, [status]);

  useEffect(() => {
    const lotsSelectedFullSelection = lotsSelected.filter(lotItem => !lotItem.isPartialSelection);
    const rowSelected = lotsSelectedFullSelection.map(lotItem => ({
      [lotItem.id]: true,
    }));
    setIsFullSelection(rowSelected);
  }, [lotsSelected]);

  const isLoading = isFetching || isFetchingGetDetailsOfAllTransfers;
  const hasError = status === 'error' || isTimeOutLotError;

  useEffect(() => {
    if (!isLoading) {
      setShowAsyncProcesses(!hasError);
    }
  }, [isLoading, hasError]);

  useEffect(() => {
    setSecondTime(!isFetching);
    setIsLoadingLots(!firstTime && !isReload ? isFetching : false);
  }, [firstTime, isFetching, isReload]);

  useEffect(() => {
    refetch();
  }, []);

  if (status === 'error' || isTimeOutLotError) {
    return (
      <ViewNoResult
        title={DataMessageErrorRequest.title}
        description={DataMessageErrorRequest.description}
        linkName={DataMessageErrorRequest.linkName}
        Icon={IconDisconnected}
        handleToggleClick={handleToggleAnalytics}
      />
    );
  }

  return (
    <>
      {isSendingLotsLoading && <LoadingScreen title={LOADING_SCREEN_TEXT.title} description={LOADING_SCREEN_TEXT.description} />}
      {!!sectionPermissions.length && firstTime ? (
        <FilterTable onFilterLot={onFilterLot} isFetching={isFetching} serviceAction={ServiceAction.ENV} lotNumber={lotNumber} />
      ) : (
        <Skeleton width='100%' height='64px' borderRadius='4px' margin='0 0 12px 0' data-testid='skeleton-component' />
      )}
      <CustomTable
        showButton
        CustomTd={CustomTd}
        CustomTh={CustomTh}
        data={lots as Lot[]}
        columns={COLUMNS as []}
        size={sizeContext}
        selectedTable={PENDING_TRANSFERS}
        handleClickButtom={handleOnClickSend}
        onFilterLot={onFilterLot}
        tableDataState={tableDataState as TableDataStateType}
        text='Enviar'
        id='table'
        isFetching={isFetching}
        firstStatusSkeleton={!!sectionPermissions.length && firstTime}
        secondStatusSkeleton={secondTime}
      />
      {!!sectionPermissions.length && firstTime && secondTime ? (
        <TablePaginationComponent
          totalPages={pagination.totalPages || 1}
          currentIndex={pagination.pageNumber && pagination.pageNumber - 1}
          totalRecords={pagination.totalElements}
          perPage={[15, 30, 45]}
          disabledPerPage={pagination.totalElements <= 15}
          currentPerPage={pagination.totalElementsRequested}
          onNext={() => handlePageNavigation(pageContext + 1, PAGE_ACTIONS.NEXT)}
          onPrev={() => handlePageNavigation(pageContext - 1, PAGE_ACTIONS.PREV)}
          onFirst={() => handlePageNavigation(1, PAGE_ACTIONS.FIRST)}
          onLast={() => handlePageNavigation(pagination?.totalPages, PAGE_ACTIONS.LAST)}
          onJump={(value: number) => {
            if (Number(value) < pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.PREV);
            } else if (Number(value) > pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.NEXT);
            }
          }}
          onPerPageChange={(value: number) => {
            setSizeContext(Number(value));
            setPageContext(1);
          }}
        />
      ) : (
        <Box sx={Paginator}>
          <Skeleton width='100%' height='56px' borderRadius='4px' marginTop='6px' data-testid='skeleton-component' />
        </Box>
      )}
      <CustomModal
        isOpen={isOpenModalGetTransferSelectByUser}
        onCancel={setIsOpenModalGetTransferSelectByUser}
        Icon={IconCancelModal}
        altIcon={TEXT_MODAL_FAILED_TRANSFER_SELECTED_BY_USER.altIcon}
        title={TEXT_MODAL_FAILED_TRANSFER_SELECTED_BY_USER.title}
        firstDescription={TEXT_MODAL_FAILED_TRANSFER_SELECTED_BY_USER.firstDescription}
        firstTextButton={TEXT_MODAL_FAILED_TRANSFER_SELECTED_BY_USER.cancelButton}
        secondTextButton={TEXT_MODAL_FAILED_TRANSFER_SELECTED_BY_USER.textButtom}
        actionButton={closeModalKeyValidation}
        actionButton2={dataDetailsTransfers ? () => retryAction(retryKeyValidation) : () => retryAction(retryGetTransfer)}
        size='xl'
        enableFirstButton
        enableSecondButton
        alternativeButtonStyling
        cancelType
      />
      <CustomModal
        isOpen={isOpenUnifiedShippingModal}
        onCancel={setIsOpenUnifiedShippingModal}
        Icon={IconInfoPopup}
        altIcon={TEXT_MODAL_UNIFIED_SHIPPING.altIcon}
        title={TEXT_MODAL_UNIFIED_SHIPPING.title}
        firstDescription=''
        secondDescription=''
        firstTextButton={TEXT_MODAL_UNIFIED_SHIPPING.firstTextButton}
        secondTextButton={TEXT_MODAL_UNIFIED_SHIPPING.secondTextButton}
        scrollBehavior='inside'
        enableFirstButton
        enableSecondButton
        actionButton={handleClickContinueModal}
        actionButton2={handleClickReturnToTransfers}
        size='xl'
        alternativeButtonStyling
      >
        <UnifiedShippingChildren
          lotNumbers={filterUnifiedLot}
          unifiedTitleShippingText1={TEXT_MODAL_UNIFIED_SHIPPING.unifiedTitleShippingText1}
          unifiedTitleShippingText2={TEXT_MODAL_UNIFIED_SHIPPING.unifiedTitleShippingText2}
        />
      </CustomModal>
      <CustomModal
        isOpen={isOpenModalZeroTransfers}
        onCancel={setIsOpenModalGetTransferSelectByUser}
        Icon={IconCancelModal}
        altIcon={ZERO_SENDING_MODAL_TEXTS.altIcon}
        title={ZERO_SENDING_MODAL_TEXTS.title}
        firstDescription={ZERO_SENDING_MODAL_TEXTS.description}
        firstTextButton={ZERO_SENDING_MODAL_TEXTS.textButton}
        actionButton={handleCloseModalZeroTransfers}
        size='xl'
        enableFirstButton
        cancelType
      />
    </>
  );
}

export default SendingTransfers;
