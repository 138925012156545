import { Box, Button, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../styled/generalStyles';

const CustomBoxFooter = styled(Box)`
  align-items: center;
  background: ${colors.White};
  bottom: 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px -3px 8px 4px;
  display: flex;
  height: 72px;
  justify-content: space-between;
  left: 0px;
  padding: 0px 64px 0 120px;
  position: fixed;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
`;

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const CustomBoxImporte = styled(Box)`
  display: flex;
  margin-top: 10px;
`;

const CustomButton = styled(Button)`
  margin-left: 30px;
`;

const CustomButtonCancel = styled(Button)`
  margin-right: 30px;
`;

const CustomTextSeparator = styled(Text)`
  margin: 0px 10px 0px 0px;
`;

const buttonMarginStyles = {
  textStyle: 'labelLg',
  marginLeft: '4px',
};

export { buttonMarginStyles, CustomBox, CustomBoxFooter, CustomBoxImporte, CustomButton, CustomButtonCancel, CustomTextSeparator };
