/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-children-prop */

import { Box, Button, Text } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@wow/utils';

import { amountRangesValuesDefault, filtersValuesDefault } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import { CustomMarginText } from '../../styled/global.styled';
import { isAnyFilterActive } from '../../utils/isAnyFilterActive';
import FilterAmount from './Components/FilterAmount';
import FilterLots from './Components/FilterLots';
import FilterTransferNumber from './Components/FilterTransferNumber';
import FilterTransferType from './Components/FilterTransferType';
import { CLEAR_FILTERS_BUTTON_TEXT, FILTER_TITLE } from './constants';
import { InterfacesPropsFilterTable } from './interfaces';
import { CustomBox } from './styled';

function FilterTable({
  onFilterLot,
  isFetching,
  serviceAction,
  lotNumber = [],
  customStyle = false,
  isActiveAmount = true,
  isActiveTransferNumber,
  optionsTransferType,
}: InterfacesPropsFilterTable) {
  const { setInputLot, filtered, setFiltered, setSelectedTransfersTypesCodes, setAmountRanges, setTransferNumber } = useTransfersContext();

  const handleClean = () => {
    setFiltered(filtersValuesDefault);
    setSelectedTransfersTypesCodes([]);
    setInputLot([]);
    onFilterLot([]);
    setTransferNumber('');
    setAmountRanges(amountRangesValuesDefault);
    pushAnalyticsEvent({
      event: 'remove_filters',
      content_group: 'Transferencias',
    });
  };

  return (
    <CustomBox id='filterTable' data-testid='filterTable' marginTop={customStyle ? '12px' : '0px'}>
      <Box className='contentTextFilter'>
        <Text sx={CustomMarginText} textStyle='labelLgBold' as='span' whiteSpace='nowrap' data-testid='Filtrá por'>
          {FILTER_TITLE}
        </Text>
      </Box>
      <Box className='contentOptionsFilter'>
        {isActiveTransferNumber && <FilterTransferNumber isFetching={isFetching || false} />}
        <FilterLots onFilterLot={onFilterLot} isFetching={isFetching || false} lotNumber={lotNumber || []} />
        <FilterTransferType
          isFetching={isFetching || false}
          serviceAction={serviceAction}
          optionsTransferType={optionsTransferType || []}
        />
        {isActiveAmount && <FilterAmount isFetching={isFetching || false} />}
      </Box>
      <Box className='contentClearFilters'>
        <Button
          variant='primary-text'
          size='md'
          onClick={handleClean}
          padding='0px'
          isDisabled={!isAnyFilterActive(filtered) || isFetching}
        >
          {CLEAR_FILTERS_BUTTON_TEXT}
        </Button>
      </Box>
    </CustomBox>
  );
}

export default FilterTable;
