import { Box, Text } from '@chakra-ui/react';
import { Card, CardSection } from '@wow/card';

import IconBinoculars from '../Icons/icon-binoculars';
import IconDisconnected from '../Icons/icon-disconnected';
import { TestIds, texts } from './constants';
import { card, cardContent, cardSection } from './index.styled';

const iconSize = 40;

function CardAccountNoResult({ isError = false }: { isError?: boolean }) {
  return (
    <Card data-testid={TestIds.cardAccountNoResult} style={card}>
      <CardSection style={cardSection}>
        <Box marginLeft='12px'>
          {isError ? <IconDisconnected width={iconSize} height={iconSize} /> : <IconBinoculars width={iconSize} height={iconSize} />}
        </Box>
        <Box sx={cardContent}>
          <Text as='span' textStyle='titleMdBold'>
            {isError ? texts.errorTitle : texts.emptyTitle}
          </Text>
          <Text as='span' textStyle='bodyMd'>
            {isError ? texts.errorDescription : texts.emptyDescription}
          </Text>
        </Box>
      </CardSection>
    </Card>
  );
}

export default CardAccountNoResult;
