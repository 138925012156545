/* eslint-disable import/prefer-default-export */
/* eslint-disable no-octal */

export const CODE_BANK = [
  '007',
  '011',
  '014',
  '015',
  '016',
  '017',
  '020',
  '027',
  '029',
  '030',
  '034',
  '044',
  '045',
  '060',
  '065',
  '072',
  '083',
  '086',
  '093',
  '094',
  '097',
  '131',
  '143',
  '147',
  '150',
  '165',
  '191',
  '198',
  '247',
  '254',
  '259',
  '266',
  '268',
  '277',
  '281',
  '285',
  '299',
  '301',
  '305',
  '309',
  '310',
  '311',
  '312',
  '315',
  '319',
  '321',
  '322',
  '330',
  '338',
  '341',
  '386',
  '389',
  '408',
  '415',
  '426',
  '431',
  '432',
  '448',
  '453',
];
