/* eslint-disable react/jsx-no-useless-fragment */
import { Text, Tooltip } from '@chakra-ui/react';

import { TransfersAcceptedPartialRejectAuth } from '../../../../Interfaces/transfers';
import { formatAmount } from '../../../../utils/formatAmount';
import { TRANSFER_TYPE } from '../../constants';
import TooltipInformation from '../TooltipInformation';
import {
  CreditAccountBox,
  CustomBox,
  CustomBoxAccount,
  CustomText,
  DateBox,
  DebitAccountBox,
  InporteBox,
  LotBox,
  PaddingBox,
  TextImporte,
  TextSeparation,
} from './index.styled';

interface Props {
  items: TransfersAcceptedPartialRejectAuth[];
}

function ResultListItemAcepted({ items }: Props) {
  return (
    <>
      {items?.map((item: TransfersAcceptedPartialRejectAuth) => (
        <CustomBox key={item.lotNumber}>
          <LotBox sx={PaddingBox}>
            <Text textStyle='h6'>{`Lote: ${item.lotNumber}`}</Text>
            <Text textStyle='bodyMd'>Nro. Transferencia</Text>
            <Text textStyle='bodyMd'>{item.transferNumber}</Text>
          </LotBox>
          <Tooltip
            hasArrow
            placement='right'
            width='350px'
            minWidth='350px'
            label={
              <DebitAccountBox>
                <TooltipInformation account={item.debitAccount} />
              </DebitAccountBox>
            }
          >
            <DebitAccountBox sx={PaddingBox}>
              <>
                <Text textStyle='titleSmBold'>{`Cuenta de débito: ${item.debitAccount.bankName}`}</Text>
                <CustomBoxAccount>
                  {item.debitAccount.accountName && <CustomText textStyle='bodyMd'>{`${item.debitAccount.accountName}`}</CustomText>}
                  {item.debitAccount.accountName && (item.debitAccount.cuit || item.debitAccount.accountNumber) && (
                    <Text as='span' textStyle='bodyMd' sx={TextSeparation}>
                      |
                    </Text>
                  )}
                  {item.debitAccount.cuit || item.debitAccount.accountNumber ? (
                    <Text textStyle='bodyMd'>{`${item.debitAccount.cuit || item.debitAccount.accountNumber}`}</Text>
                  ) : null}
                </CustomBoxAccount>
                <CustomBoxAccount>
                  {item.debitAccount.cbu !== undefined && <Text textStyle='bodyMd'>{`${item.debitAccount.cbu}`}</Text>}
                  {item.debitAccount.cbu && item.debitAccount.accountType && (
                    <Text as='span' textStyle='bodyMd' sx={TextSeparation}>
                      |
                    </Text>
                  )}
                  {item.debitAccount.accountType !== undefined && <Text textStyle='bodyMd'>{`${item.debitAccount.accountType}`}</Text>}
                </CustomBoxAccount>
              </>
            </DebitAccountBox>
          </Tooltip>
          <Tooltip
            width='350px'
            minWidth='350px'
            hasArrow
            placement='right'
            label={
              item.type === TRANSFER_TYPE.AFIP || item.type === TRANSFER_TYPE.INTERNAL || item.type === TRANSFER_TYPE.TO_DEFINE_SAA ? (
                ''
              ) : (
                <CreditAccountBox>
                  <TooltipInformation account={item.creditAccount} />
                </CreditAccountBox>
              )
            }
          >
            <CreditAccountBox sx={PaddingBox}>
              {item.type === TRANSFER_TYPE.AFIP || item.type === TRANSFER_TYPE.INTERNAL || item.type === TRANSFER_TYPE.TO_DEFINE_SAA ? (
                <>
                  <Text textStyle='titleSmBold'>Cuenta de crédito:</Text>
                  <CustomBoxAccount>
                    <CustomText textStyle='bodyMd'>{`${item.creditAccount.bankName}`}</CustomText>
                  </CustomBoxAccount>
                </>
              ) : (
                <>
                  <Text textStyle='titleSmBold'>{`Cuenta de crédito: ${item.creditAccount.bankName}`}</Text>
                  <CustomBoxAccount>
                    {item.creditAccount.accountName && <CustomText textStyle='bodyMd'>{`${item.creditAccount.accountName}`}</CustomText>}
                    {item.creditAccount.accountName && (item.creditAccount.cuit || item.creditAccount.accountNumber) && (
                      <Text as='span' textStyle='bodyMd' sx={TextSeparation}>
                        |
                      </Text>
                    )}
                    {item.creditAccount.cuit || item.creditAccount.accountNumber ? (
                      <Text textStyle='bodyMd'>{`${item.creditAccount.cuit || item.creditAccount.accountNumber}`}</Text>
                    ) : null}
                  </CustomBoxAccount>
                  <CustomBoxAccount>
                    {item.creditAccount.cbu !== undefined && <Text textStyle='bodyMd'>{`${item.creditAccount.cbu}`}</Text>}
                    {item.creditAccount.cbu && item.creditAccount.accountType && (
                      <Text as='span' textStyle='bodyMd' sx={TextSeparation}>
                        |
                      </Text>
                    )}
                    {item.creditAccount.accountType !== undefined && <Text textStyle='bodyMd'>{`${item.creditAccount.accountType}`}</Text>}
                  </CustomBoxAccount>
                </>
              )}
            </CreditAccountBox>
          </Tooltip>
          <InporteBox sx={PaddingBox}>
            <Text sx={TextImporte} textStyle='titleSmBold'>
              Importe
            </Text>
            <Text sx={TextImporte} textStyle='bodyMd'>
              {formatAmount(item.currency, item.amount, 'SUE')}
            </Text>
          </InporteBox>
          <DateBox sx={PaddingBox}>
            <Text textStyle='titleSmBold'>Fecha de solicitud</Text>
            <Text textStyle='bodyMd'>{`${item.requestDate}`.split('-').reverse().join('/')}</Text>
          </DateBox>
        </CustomBox>
      ))}
    </>
  );
}

export default ResultListItemAcepted;
