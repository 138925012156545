/* eslint-disable import/prefer-default-export */

import { colors } from '../../styled/generalStyles';

const CustomBox = {
  alignItems: 'center',
  background: colors.Grey40,
  borderRadius: '4px',
  border: `solid 1px ${colors.Grey60}`,
  display: 'flex',
  flexDirection: 'column',
  margin: '40px auto 100px',
  padding: '45px 50px',
  width: '620px',
};

const TextSummary = {
  margin: '0px 20px 0px 0px',
};

const TextNormal = {
  margin: '0px 5px 0px 0px',
};

const TextBox = {
  display: 'flex',
  margin: '0px',
  textAlign: 'center',
};

const TextBoxNameCompany = {
  margin: '0px',
  textAlign: 'center',
};

const CustomInputGroup = {
  margin: '45px',
  zIndex: 0,
};

const Separator = {
  color: colors.Info100,
  margin: '0px 20px 0px 24px',
  fontWeight: 700,
};

const CustomInputPassword = {
  '::-ms-reveal': {
    display: 'none',
  },
};

const CustomTabTitle = {
  marginRight: '16px',
};

const ContentForm = {
  marginBottom: '100px',
};

const CustomMargin = {
  margin: '0px',
  fontSize: '12px',
  whiteSpace: 'pre-wrap',
};

const CustomTooltip = {
  background: `${colors.Grey120}`,
  borderRadius: '8px',
  color: `${colors.White}`,
  padding: '8px 22px',
  placement: 'right',
};

const TabFlexStyle = {
  marginBottom: '39px',
  alignItems: 'center',
  display: 'inline-flex',
  padding: '5px',
};

export {
  ContentForm,
  CustomBox,
  CustomInputGroup,
  CustomInputPassword,
  CustomMargin,
  CustomTabTitle,
  CustomTooltip,
  Separator,
  TabFlexStyle,
  TextBox,
  TextBoxNameCompany,
  TextNormal,
  TextSummary,
};
