import { Box, Skeleton } from '@chakra-ui/react';
import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { TablePaginationComponent } from '@wow/table-pagination';
import { OpenMode } from '@wow/utils';
import { useEffect, useState } from 'react';

import BaseScreen from '../../components/BaseScreen';
import CustomTable from '../../components/CustomTable';
import FilterTable from '../../components/FilterTable';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import ViewNoResult from '../../components/ViewNoResult';
import { DataMessageErrorRequest, PAGE_ACTIONS } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import useModifyTransfers from '../../hooks/useModifyTransfers';
import { ITableModify } from '../../Interfaces/ITableModify';
import { ServiceAction } from '../../Interfaces/transfers';
import { MODIFY_TRANSFERS } from '../../utils/configTableFunctions';
import ModifyDrawer from './components/modifyDrawer';
import { COLUMNS } from './configTable';
import { MODIFY_NOTIFICATION } from './constants';
import { CustomTd, CustomTh, paginator } from './index.styled';

function Modify() {
  const [lotNumber, setLotNumber] = useState<number[]>([]);
  const { setPageContext, setSizeContext, pageContext, sizeContext, clearSelectedLots } = useTransfersContext();
  const {
    dataTransfer,
    isFetching,
    tableDataState,
    pagination,
    firstTime,
    secondTime,
    setSecondTime,
    optionsTransferType,
    statusServicesGetTransfers,
    refetch,
  } = useModifyTransfers(lotNumber);
  const [transferNumber, setTransferNumber] = useState<number>(0);
  const [transferType, setTransferType] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedLotNumber, setSelectedLotNumber] = useState<number>(0);
  const [codeTransferType, setCodeTransferType] = useState<string>('');
  const [transferCurrency, setTransferCurrency] = useState<string>('');

  const handleToggleDrawer = (param: number) => {
    setTransferNumber(param);
    setIsOpen(true);
  };

  const handleToggleDrawerWithDetails = (
    transferTypeParam: string,
    lotNumberParam: number,
    codeTransferTypeParam: string,
    currency: string,
  ) => {
    setTransferType(transferTypeParam);
    setSelectedLotNumber(lotNumberParam);
    setCodeTransferType(codeTransferTypeParam);
    setTransferCurrency(currency);
    setIsOpen(true);
  };

  const onFilterLot = (numberLot: number[]) => {
    setPageContext(1);
    setLotNumber(numberLot);
  };

  const handlePageNavigation = (pageNumber: number, action: string) => {
    setSecondTime(false);
    setPageContext(pageNumber);
    pushAnalyticsEvent({
      event: 'switch_page',
      selection_type: `${pageNumber} | ${action}`,
    });
  };

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_group: 'Transferencias - Modificar - Servicio no disponible',
      content_type: 'No pudimos cargar los datos solicitados',
      item_id: 'Reintentar',
    });
  };

  const onRedirect = () => {
    window.open(
      OpenMode({
        module: 'urlComplete',
        urlComplete: `${process.env.URL_BASE_SIB}${process.env.SIB_TRANSFERENCIAS_PENDIENTES}`,
      }),
    );
  };

  useEffect(() => {
    clearSelectedLots();
    return () => {
      clearSelectedLots();
    };
  }, []);

  if (statusServicesGetTransfers) {
    return (
      <BaseScreen
        title='Modificar transferencias'
        sizeTitle='h1'
        id='titleMod'
        subTitle='Solo podés editar las transferencias en estado Confeccionada.'
        isActiveSubTitle
      >
        <ViewNoResult
          title={DataMessageErrorRequest.title}
          description={DataMessageErrorRequest.description}
          linkName={DataMessageErrorRequest.linkName}
          Icon={IconDisconnected}
          handleToggleClick={handleToggleAnalytics}
        />
      </BaseScreen>
    );
  }

  return (
    <BaseScreen
      title='Modificar transferencias'
      sizeTitle='h1'
      id='titleMod'
      subTitle='Solo podés editar las transferencias en estado Confeccionada.'
      isActiveSubTitle
    >
      {firstTime ? (
        <>
          <AlertComponent buttonLabel={MODIFY_NOTIFICATION.textButton} onClick={onRedirect} variant='information' marginTop='12px'>
            <AlertTitle>{MODIFY_NOTIFICATION.title}</AlertTitle>
            <AlertDescription>
              {MODIFY_NOTIFICATION.description1}
              <b>{MODIFY_NOTIFICATION.description2}</b>
              {MODIFY_NOTIFICATION.description3}
            </AlertDescription>
          </AlertComponent>
          <FilterTable
            onFilterLot={onFilterLot}
            isFetching={isFetching}
            lotNumber={lotNumber}
            customStyle
            isActiveAmount={false}
            isActiveTransferNumber
            optionsTransferType={optionsTransferType}
            serviceAction={ServiceAction.MOD}
          />
        </>
      ) : (
        <Skeleton width='100%' height='64px' borderRadius='4px' margin='24px 0px 8px 0px' data-testid='skeleton-component' />
      )}
      <CustomTable
        CustomTd={CustomTd}
        CustomTh={CustomTh}
        data={dataTransfer as ITableModify[]}
        columns={COLUMNS as []}
        size={sizeContext}
        selectedTable={MODIFY_TRANSFERS}
        onFilterLot={onFilterLot}
        tableDataState={tableDataState as ITableModify}
        id='table'
        isFetching={isFetching}
        firstStatusSkeleton={firstTime}
        secondStatusSkeleton={secondTime}
        handleToggleDrawer={handleToggleDrawer}
        handleToggleDrawerWithDetails={handleToggleDrawerWithDetails}
      />
      {isOpen && (
        <ModifyDrawer
          transferType={transferType}
          lotNumber={selectedLotNumber}
          transferNumber={transferNumber}
          codeTransferType={codeTransferType}
          transferCurrency={transferCurrency}
          isOpen={isOpen}
          onCancel={setIsOpen}
          refetch={refetch}
        />
      )}
      {firstTime && secondTime ? (
        <TablePaginationComponent
          totalPages={pagination.totalPages || 1}
          currentIndex={pagination.pageNumber && pagination.pageNumber - 1}
          totalRecords={pagination.totalElements}
          perPage={[15, 30, 45]}
          disabledPerPage={pagination.totalElements <= 15}
          currentPerPage={pagination.totalElementsRequested}
          onNext={() => handlePageNavigation(pageContext + 1, PAGE_ACTIONS.NEXT)}
          onPrev={() => handlePageNavigation(pageContext - 1, PAGE_ACTIONS.PREV)}
          onFirst={() => handlePageNavigation(1, PAGE_ACTIONS.FIRST)}
          onLast={() => handlePageNavigation(pagination?.totalPages, PAGE_ACTIONS.LAST)}
          onJump={(value: number) => {
            if (Number(value) < pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.PREV);
            } else if (Number(value) > pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.NEXT);
            }
          }}
          onPerPageChange={(value: number) => {
            setSecondTime(false);
            setSizeContext(Number(value));
            setPageContext(1);
          }}
        />
      ) : (
        <Box sx={paginator}>
          <Skeleton width='100%' height='56px' borderRadius='4px' marginTop='6px' data-testid='skeleton-component' />
        </Box>
      )}
    </BaseScreen>
  );
}

export default Modify;
