/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import { Image, SystemStyleObject } from '@chakra-ui/react';

import { TRANSFER_TYPE_B2B, TRANSFER_TYPE_PDS } from '../../views/LotDetail/constants';
import { CODE_BANK } from './constants';
import logoStyles from './styled';

interface Props {
  src: string | undefined;
  transferType: string;
  style?: SystemStyleObject | undefined;
}

function BankLogo({ src, transferType, style = {} }: Props) {
  const codeExist = CODE_BANK.find(element => element === src);
  if (transferType === TRANSFER_TYPE_B2B || transferType === TRANSFER_TYPE_PDS) {
    return <Image sx={{ ...logoStyles, ...style }} src={require('../../assets/logos/default.png')} />;
  }
  return (
    <>
      {codeExist ? (
        <Image sx={{ ...logoStyles, ...style }} src={require(`../../assets/logos/${src}.png`)} />
      ) : (
        <Image sx={{ ...logoStyles, ...style }} src={require('../../assets/logos/default.png')} />
      )}{' '}
    </>
  );
}

export default BankLogo;
