import { SelectOption } from '@interfaces/transferSetup';

const sortAndCombineOptions = (options: SelectOption[], appliedOptions: string[]) => {
  const elementsSelected = options.filter(option => appliedOptions.includes(option.value)).sort((a, b) => a.label.localeCompare(b.label));

  const elementsNotSelected = options
    .filter(option => !appliedOptions.includes(option.value))
    .sort((a, b) => a.label.localeCompare(b.label));

  return [...elementsSelected, ...elementsNotSelected];
};

export { sortAndCombineOptions };
