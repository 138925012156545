import { colors } from '../../styled/generalStyles';

const ContentListBank = {
  alignItems: 'center',
  borderBottom: `solid 1px ${colors.Grey60}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 0px',
  margin: '10px 0px',
};

const CustomInput = {
  width: '320px',
};

const ContenBank = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '4px',
};

const ContentIconButton = {
  zIndex: '0',
};

const CustomText = {
  margin: '0px',
  display: 'inline-flex',
};

export { ContenBank, ContentIconButton, ContentListBank, CustomInput, CustomText };
