/* eslint-disable react/jsx-props-no-spreading */
import { useToast } from '@chakra-ui/react';
import { ToastComponent } from '@wow/toast';

import { TOAST_DURATION, TOAST_TYPE } from '../constants-app';
import { ToastContainer } from '../views/TransferSetup/Steps/Step2/index.styled';

function useToastMessage() {
  const toast = useToast();

  const showToast = (
    id: string,
    description: string,
    status: TOAST_TYPE,
    isCtaLabel?: boolean,
    ctaLabel?: string,
    onClickLabel?: () => void,
  ) => {
    toast({
      id,
      duration: TOAST_DURATION,
      position: 'bottom-left',
      status,
      description,
      containerStyle: ToastContainer,
      render: props => (
        <ToastComponent {...props} onClose={() => toast.close(id)} isCtaLabel={isCtaLabel} ctaLabel={ctaLabel} ctaOnClick={onClickLabel} />
      ),
    });
  };

  return {
    success: (id: string, description: string) => showToast(id, description, TOAST_TYPE.SUCCESS),
    error: (id: string, description: string, label?: string, onClick?: () => void) =>
      showToast(id, description, TOAST_TYPE.ERROR, true, label, onClick),
    info: (id: string, description: string) => showToast(id, description, TOAST_TYPE.INFO),
  };
}

export default useToastMessage;
