/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { IconButtonComponent } from '@wow/button';
import { useRef } from 'react';
import { Edit2, Layers } from 'react-feather';
import { Link } from 'react-router-dom';

import { ARS_CURRENCY, CURRENCY_DOLAR, CURRENCY_PESOS } from '../../constants-app';
import { ITableModify } from '../../Interfaces/ITableModify';
import { colors } from '../../styled/generalStyles';
import { CustomMarginText } from '../../styled/global.styled';
import { decimalFormat } from '../../utils/FormatChange';
import { DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH, TEXT_TOOLTIP_UNIFIED_SHIPPING, TITLE_TOOLTIP_UNIFIED_SHIPPING } from './constants';
import { ColorFontsHeaderTable, CustomApplicationDate, CustomBoxLote, SeparatorDebitAccount, TitleDebitAccount } from './index.styled';

const columnHelper = createColumnHelper<ITableModify>();

export const COLUMNS = [
  columnHelper.accessor(row => `${row.transferNumber}`, {
    id: 'numeroTransferencia',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Nro. Transferencia
      </Text>
    ),
    cell: props => (
      <Text sx={CustomMarginText} textStyle='bodySm'>
        {props.row.original.transferNumber}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.lotNumber}`, {
    id: 'numeroLote',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Lote
      </Text>
    ),
    cell: ({ row }) => (
      <CustomBoxLote>
        <Text sx={CustomMarginText} textStyle='bodySm' as='span'>
          {row.original.lotNumber}
        </Text>
        {row.original.unifiedShippingMark === 'Y' && (
          <Tooltip
            color={colors.White}
            fontSize='12px'
            placement='right'
            hasArrow
            background={colors.Grey120}
            borderRadius='8px'
            label={
              <Box>
                <Text sx={CustomMarginText} textStyle='bodySmSemi'>
                  {TITLE_TOOLTIP_UNIFIED_SHIPPING}
                </Text>
                <Text sx={CustomMarginText} textStyle='bodySm'>
                  {TEXT_TOOLTIP_UNIFIED_SHIPPING}
                </Text>
              </Box>
            }
            padding='8px'
            width='260px'
          >
            <Flex>
              <Layers color={colors.Secondary100} size='16px' />
            </Flex>
          </Tooltip>
        )}
        <Box />
      </CustomBoxLote>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('requestDate', {
    id: 'fechaSolicitud',
    header: () => (
      <Text sx={{ ...CustomApplicationDate, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Fecha solicitud
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm' as='span'>
        {`${row.original.requestDate}`.split('-').reverse().join('/')}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.debitAccount.bankName} ${row.debitAccount.accountName || ''} ${row.debitAccount.cbu}`, {
    id: 'debitAccount',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...TitleDebitAccount, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Cuenta débito
      </Text>
    ),
    cell: ({ row }) => {
      const elemText = useRef<HTMLInputElement | null>(null);
      const creditAccountElemTextWidth = elemText?.current?.clientWidth || '';
      const hasMaxLength = Number(creditAccountElemTextWidth) >= DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH;
      const tooltipLabel = hasMaxLength ? (
        <div>
          <Text textStyle='bodySmSemi'>{row.original.debitAccount.bankName}</Text>
          {row.original.debitAccount.cuit ? (
            <Box>
              <Box>
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.accountName}
                </Text>
                {row.original.debitAccount.accountName && <span>{' | '}</span>}
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.cuit}
                </Text>
              </Box>
              <Box>
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.cbu || row.original.debitAccount.accountNumber}
                </Text>
                {(row.original.debitAccount.cbu || row.original.debitAccount.accountNumber) && <span>{' | '}</span>}
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.accountType}
                </Text>
              </Box>
            </Box>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      );
      return (
        <Tooltip label={tooltipLabel} color={colors.White} hasArrow placement='right' background={colors.Grey120} borderRadius='8px'>
          <Box>
            <Text as='p' sx={CustomMarginText} textStyle='bodySmSemi'>
              {row.original.debitAccount.bankName}
            </Text>
            <>
              <Box display='flex' alignItems='center'>
                <Text as='p' ref={elemText} margin='0px' textStyle='bodySm'>
                  {row.original.debitAccount.accountName}
                </Text>
                {row.original.debitAccount.accountName && (
                  <Text as='span' textStyle='bodySmSemi' sx={SeparatorDebitAccount}>
                    {' | '}
                  </Text>
                )}
                <Text as='span' textStyle='bodySm'>
                  {row.original.debitAccount.cuit}
                </Text>
              </Box>
              <Box display='flex' alignItems='center'>
                <Text as='p' textStyle='bodySm' margin='0px'>
                  {row.original.debitAccount.cbu || row.original.debitAccount.accountNumber}
                </Text>
                {(row.original.debitAccount.cbu || row.original.debitAccount.accountNumber) && (
                  <Text as='span' textStyle='bodySmSemi'>
                    {' | '}
                  </Text>
                )}
                <Text as='span' textStyle='bodySm'>
                  {row.original.debitAccount.accountType}
                </Text>
              </Box>
            </>
          </Box>
        </Tooltip>
      );
    },
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.creditAccount.bankName} ${row.creditAccount.accountName || ''} ${row.creditAccount.cbu}`, {
    id: 'creditAccount',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...TitleDebitAccount, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Cuenta crédito
      </Text>
    ),
    cell: ({ row }) => {
      const elemText = useRef<HTMLInputElement | null>(null);
      const creditAccountElemTextWidth = elemText?.current?.clientWidth || '';
      const hasMaxLength = Number(creditAccountElemTextWidth) >= DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH;
      const tooltipLabel = hasMaxLength ? (
        <div>
          <Text textStyle='bodySmSemi'>{row.original.creditAccount.bankName}</Text>
          {row.original.creditAccount.cuit ? (
            <Box>
              <Box>
                <Text as='span' sx={CustomMarginText}>
                  {row.original.creditAccount.accountName}
                </Text>
                {row.original.creditAccount.accountName && <span>{' | '}</span>}
                <Text as='span' sx={CustomMarginText}>
                  {row.original.creditAccount.cuit}
                </Text>
              </Box>
              <Box>
                <Text as='span' sx={CustomMarginText}>
                  {row.original.creditAccount.cbu || row.original.creditAccount.accountNumber}
                </Text>
                {(row.original.creditAccount.cbu || row.original.creditAccount.accountNumber) && <span>{' | '}</span>}
                <Text as='span' sx={CustomMarginText}>
                  {row.original.creditAccount.accountType}
                </Text>
              </Box>
            </Box>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      );
      return (
        <Tooltip label={tooltipLabel} color={colors.White} hasArrow placement='right' background={colors.Grey120} borderRadius='8px'>
          <Box>
            <Text as='p' sx={CustomMarginText} textStyle='bodySmSemi'>
              {row.original.creditAccount.bankName}
            </Text>
            <>
              <Box display='flex' alignItems='center'>
                <Text as='p' ref={elemText} margin='0px' textStyle='bodySm'>
                  {row.original.creditAccount.accountName}
                </Text>
                {row.original.creditAccount.accountName && (
                  <Text as='span' textStyle='bodySmSemi' sx={SeparatorDebitAccount}>
                    {' | '}
                  </Text>
                )}
                <Text as='span' textStyle='bodySm'>
                  {row.original.creditAccount.cuit}
                </Text>
              </Box>
              <Box display='flex' alignItems='center'>
                <Text as='p' textStyle='bodySm' margin='0px'>
                  {row.original.creditAccount.cbu || row.original.creditAccount.accountNumber}
                </Text>
                {(row.original.creditAccount.cbu || row.original.creditAccount.accountNumber) && (
                  <Text as='span' textStyle='bodySmSemi'>
                    {' | '}
                  </Text>
                )}
                <Text as='span' textStyle='bodySm'>
                  {row.original.creditAccount.accountType}
                </Text>
              </Box>
            </>
          </Box>
        </Tooltip>
      );
    },
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.amount}`, {
    id: 'importe',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Importe
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='labelSmBold' as='span'>
        {row.original.amount !== 0
          ? `${row.original.currency === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR} ${decimalFormat(row.original.amount || 0)}`
          : `${row.original.currency === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR} ***`}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Estado
      </Text>
    ),
    cell: () => (
      <Text sx={CustomMarginText} textStyle='bodySm' as='span'>
        Confeccionada
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor('type', {
    id: 'tipoTransferenciaDescripcion',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Tipo
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm' as='span'>
        {row.original.lotTransferTypeDescription}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'detail',
    enableSorting: false,
    header: '',
    cell: ({ row, table }) => {
      const handleClick = () => {
        if (table?.options?.meta?.handleToggleDrawer) {
          table.options.meta.handleToggleDrawer(row?.original?.transferNumber);
        }

        if (table?.options?.meta?.handleToggleDrawerWithDetails) {
          table.options.meta.handleToggleDrawerWithDetails(
            row?.original?.lotTransferTypeDescription,
            row?.original?.lotNumber,
            row?.original?.type,
            row?.original?.currency,
          );
        }
      };
      return (
        <>
          <Tooltip
            label='Modificar transferencia'
            color={colors.White}
            background={colors.Grey120}
            borderRadius='8px'
            placement='top'
            hasArrow
          >
            <Link to=''>
              <IconButtonComponent aria-label='icon-button' variant='icon-text' size='i-md' onClick={handleClick} icon={<Edit2 />} />
            </Link>
          </Tooltip>
          <Box />
        </>
      );
    },
    footer: info => info.column.id,
  }),
];
