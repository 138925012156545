/* eslint-disable react/jsx-props-no-spreading */
import { Currencies } from '@interfaces/transfers';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { AuthorizationState, EXCEPTION_CODES, filtersAppliedDrawerDefault, RetentionState, TRANSFER_TYPE_CODE } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { AdditionalInformation, Concept, TransferData } from '../Interfaces/api';
import { useTransferSetup2Props } from '../Interfaces/hooks/IUseTransferSetupStep2';
import { DraftModal } from '../Interfaces/modals';
import { AccountsNotAvailable, LotTransfer, SelectOption } from '../Interfaces/transferSetup';
import { getConcepts, getDocumentsToPay, getTypesRetention, getUsageReferences } from '../services/api';
import { convertAmountNumberToText } from '../utils/convertAmountNumberToText.ts';
import { convertAmountTextToNumber } from '../utils/convertAmountTextToNumber';
import { ids, texts, VALID_CODES } from '../views/TransferSetup/Steps/Step2/constants';
import useToastMessage from './useToastMessage';

export function useTransferSetupStep2({
  transferSelected,
  debitAccounts,
  debitAccountsLoading,
  debitAccountsError,
  debitBanksLoading,
  debitBanksError,
  debitAccountSelected,
  debitAccountSelectedId,
  debitBanksCurrencyARS,
  creditAccountSelected,
  creditAccountSelectedId,
  creditAccounts,
  creditAccountsLoading,
  creditAccountsError,
  creditBanksLoading,
  creditBanksError,
  loadDebitAccounts,
  loadDebitBanks,
  setDebitUsageReferences,
  setDebitAccountsLoading,
  setDebitBanksLoading,
  resetDebitAccountFilters,
  handleSelectDebitAccount,
  setDebitCurrencySelected,
  loadCreditAccounts,
  loadCreditBanks,
  setCreditUsageReferences,
  setCreditAccountsLoading,
  setCreditBanksLoading,
  resetCreditAccountFilters,
  handleSelectCreditAccount,
  setCreditCurrencySelected,
  handleRemoveSelectedCreditAccount,
  setTransferSelected,
  setEnabledAccountSelection,
  loadCreditAccountsFiltered,
}: useTransferSetup2Props) {
  const toastHook = useToastMessage();
  const {
    manualTransferData,
    transferTypeByOperators,
    holidays,
    setManualTransferData,
    setDebitFiltersApplied,
    setIsReferenceActive,
    clearDrawerConfectionFilters,
  } = useTransfersContext();

  const initialTransferData = {
    date: manualTransferData.requestDate || '',
    observation: '',
    amount: 0,
    reason: '',
    voucher: '',
    concept: null,
    isDisclaimerChecked: false,
  };

  const initialAdditionalInformation = {
    numberClient: '',
    document: null,
    documentNumber: '',
    retentionType: null,
    totalRetentionAmount: 0,
    paymentOrderType: '',
    paymentOrderNumber: '',
    creditNoteNumber: 0,
    creditNoteAmount: 0,
  };

  const transferType = transferTypeByOperators.find(item => item.code === manualTransferData.transferType);

  const [firstExecution, setFirstExecution] = useState(true);

  const [usageReferences, setUsageReferences] = useState<{ debitUsagesReference: string[]; creditUsagesReference: string[] }>({
    debitUsagesReference: [],
    creditUsagesReference: [],
  });
  const [usageReferencesLoading, setUsageReferencesLoading] = useState(true);
  const [usageReferencesError, setUsageReferencesError] = useState(false);

  const [isValidTransfer, setIsValidTransfer] = useState(false);
  const [isAnyServiceFailed, setIsAnyServiceFailed] = useState<boolean>(false);
  const [allFetchesComplete, setAllFetchesComplete] = useState<boolean>(false);
  const [allFetchesDone, setAllFetchesDone] = useState<boolean>(false);
  const [showRemoveTransferModal, setShowRemoveTransferModal] = useState<boolean>(false);
  const [transferToRemove, setTransferToRemove] = useState<LotTransfer | null>(null);

  const [transferData, setTransferData] = useState<TransferData>(initialTransferData);
  const [transferAmountString, setTransferAmountString] = useState<string>(convertAmountNumberToText(transferData.amount));
  const [disclaimerConceptText, setDisclaimerConceptText] = useState<string>('');
  const [conceptsList, setConceptsList] = useState<Concept[]>([]);
  const [conceptListLoading, setConceptListLoading] = useState(false);
  const [conceptListError, setConceptListError] = useState(false);
  const [conceptsListOptions, setConceptsListOptions] = useState<SelectOption[]>([]);
  const [isTransferDataSectionVisible, setIsTransferDataSectionVisible] = useState(false);
  const [isTERProccess, setIsTERProccess] = useState(false);
  const [isPROVProcessSelected, setIsPROVProcessSelected] = useState(false);
  const [additionalInformation, setAdditionalInformation] = useState<AdditionalInformation>(initialAdditionalInformation);
  const [totalRetentionAmountString, setTotalRetentionAmountString] = useState<string>(
    convertAmountNumberToText(additionalInformation.totalRetentionAmount),
  );
  const [creditNoteAmountString, setCreditNoteAmountString] = useState<string>(
    convertAmountNumberToText(additionalInformation.creditNoteAmount),
  );
  const [documentToPayListOptions, setDocumentToPayListOptions] = useState<SelectOption[]>([]);
  const [documentToPayLoading, setDocumentToPayLoading] = useState(false);
  const [documentToPayError, setDocumentToPayError] = useState(false);
  const [retentionTypeListOptions, setRetentionTypeListOptions] = useState<SelectOption[]>([]);
  const [retentionTypeLoading, setRetentionTypeLoading] = useState(false);
  const [retentionTypeError, setRetentionTypeError] = useState(false);

  const { refetch: fetchUsageReferences } = useQuery(
    ['get-usage-references', manualTransferData.transferType],
    () => getUsageReferences(manualTransferData.transferType)?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setUsageReferences(data?.data);
          setDebitUsageReferences(data?.data.debitUsagesReference);
          setCreditUsageReferences(data?.data.creditUsagesReference);
        } else {
          setUsageReferencesError(true);
        }
        setUsageReferencesLoading(false);
      },
      onError: () => {
        setUsageReferencesError(true);
        setUsageReferencesLoading(false);
      },
    },
  );

  const { refetch: fetchConcepts } = useQuery(['get-concepts'], () => getConcepts()?.then(res => res.data), {
    enabled: false,
    retry: 3,
    notifyOnChangeProps: ['data', 'error'],
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.success) {
        const { totalThirdPartyConcepts } = data?.data || {};
        const options: SelectOption[] = totalThirdPartyConcepts?.map(conceptItem => ({
          value: conceptItem?.conceptCode,
          label: conceptItem?.description,
        }));
        setConceptsList(totalThirdPartyConcepts);
        setConceptsListOptions(options);
      } else {
        setConceptListError(true);
      }
      setConceptListLoading(false);
    },
    onError: () => {
      setConceptListLoading(false);
      setConceptListError(true);
    },
  });

  const { refetch: fetchDocumentsToPay } = useQuery(['get-documents-to-pay'], () => getDocumentsToPay()?.then(res => res.data), {
    enabled: false,
    retry: 3,
    notifyOnChangeProps: ['data', 'error'],
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.success) {
        const { documentTypeCancels } = data.data || {};
        const options: SelectOption[] = documentTypeCancels?.map(documentItem => ({
          value: String(documentItem?.code),
          label: documentItem?.description,
        }));
        setDocumentToPayListOptions(options);
      } else {
        setDocumentToPayError(true);
      }
      setDocumentToPayLoading(false);
    },
    onError: () => {
      setDocumentToPayLoading(false);
      setDocumentToPayError(true);
    },
  });

  const { refetch: fetchTypesRetention } = useQuery(
    ['get-types-retention'],
    () => getTypesRetention(RetentionState.ENABLED, AuthorizationState.V)?.then(res => res.data),
    {
      enabled: false,
      retry: 3,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          const { retentionTypeView } = data.data || {};
          const options: SelectOption[] = retentionTypeView?.map(retentionItem => ({
            value: String(retentionItem?.code),
            label: retentionItem?.description,
          }));
          setRetentionTypeListOptions(options);
        } else {
          setRetentionTypeError(true);
        }
        setRetentionTypeLoading(false);
      },
      onError: () => {
        setRetentionTypeLoading(false);
        setRetentionTypeError(true);
      },
    },
  );

  const onRetryAllServices = (): void => {
    setFirstExecution(true);
    setIsAnyServiceFailed(false);
    setUsageReferencesLoading(true);
    setDebitAccountsLoading(true);
    setDebitBanksLoading(true);
    setCreditAccountsLoading(true);
    setCreditBanksLoading(true);

    fetchUsageReferences();
    loadDebitAccounts();
    loadDebitBanks();
    loadCreditAccounts();
    loadCreditBanks();

    if (isTERProccess) {
      setConceptListLoading(true);
      fetchConcepts();
    }

    if (isPROVProcessSelected) {
      setDocumentToPayLoading(true);
      setRetentionTypeLoading(true);
      fetchDocumentsToPay();
      fetchTypesRetention();
    }
  };

  const onChangeConcept = (concept: SelectOption | null) => {
    if (concept) {
      const conceptSelected = conceptsList?.find(conceptItem => conceptItem.conceptCode === concept.value);
      setDisclaimerConceptText(conceptSelected?.disclaimerText || '');
      setTransferData({
        ...transferData,
        concept,
        isDisclaimerChecked: false,
      });
    }
  };

  const resetTransferData = () => {
    setTransferData(initialTransferData);
    setDisclaimerConceptText('');
    setAdditionalInformation(initialAdditionalInformation);
    setTransferAmountString('');
    setTotalRetentionAmountString('');
    setCreditNoteAmountString('');
  };

  const resetForm = () => {
    resetDebitAccountFilters();
    if (debitAccounts.length > 1) {
      handleSelectDebitAccount(null);
    }
    setDebitCurrencySelected(debitBanksCurrencyARS ? Currencies.ARS : Currencies.USD);

    resetCreditAccountFilters();
    if (creditAccounts.length > 1) {
      handleSelectCreditAccount(null);
    }
    setCreditCurrencySelected(debitBanksCurrencyARS ? Currencies.ARS : Currencies.USD);

    loadDebitAccounts();
    loadCreditAccounts();

    resetTransferData();
    setTransferSelected(null);
  };

  const handleAddTransfer = () => {
    const transferDataParams: TransferData = {
      ...transferData,
      amount: Number(transferAmountString),
    };

    const additionalInformationParams: AdditionalInformation = {
      ...additionalInformation,
      totalRetentionAmount: Number(totalRetentionAmountString),
      creditNoteAmount: Number(creditNoteAmountString),
    };

    const transfer: LotTransfer = {
      transferNumber: manualTransferData.transfers.length + 1,
      debitAccount: debitAccountSelected,
      creditAccount: creditAccountSelected,
      transferData: transferDataParams,
      additionalInformation: isPROVProcessSelected ? additionalInformationParams : null,
    };
    setManualTransferData({
      ...manualTransferData,
      hasDraft: null,
      transfers: [...manualTransferData.transfers, transfer].sort((a, b) => b.transferNumber - a.transferNumber),
    });

    handleRemoveSelectedCreditAccount();
    resetCreditAccountFilters();
    resetTransferData();
    loadCreditAccountsFiltered();
  };

  const handleEditTransfer = (transfer: LotTransfer) => {
    resetDebitAccountFilters();
    handleSelectDebitAccount(String(transfer.debitAccount?.accountId));

    resetCreditAccountFilters();
    handleSelectCreditAccount(String(transfer.creditAccount?.accountId));

    setTransferData(transfer.transferData);
    setTransferAmountString(String(transfer.transferData.amount));
    const concept = transfer.transferData.concept?.value || '';
    const conceptSelected = concept ? conceptsList?.find(conceptItem => conceptItem.conceptCode === concept) : undefined;
    setDisclaimerConceptText(concept ? conceptSelected?.disclaimerText || '' : '');
    if (transfer.additionalInformation) {
      setAdditionalInformation(transfer.additionalInformation);
      setTotalRetentionAmountString(String(transfer.additionalInformation?.totalRetentionAmount));
      setCreditNoteAmountString(String(transfer.additionalInformation?.creditNoteAmount));
    }

    setTransferSelected(transfer);
  };

  const onCancelRemoveTransfer = (): void => {
    setShowRemoveTransferModal(false);
    setTransferToRemove(null);
  };

  const onRemoveTransfers = (): void => {
    const { transfers } = manualTransferData;

    const newTransfers = transfers
      .filter(item => item.transferNumber !== transferToRemove?.transferNumber)
      .sort((a, b) => a.transferNumber - b.transferNumber)
      .map((transfer, index) => ({
        ...transfer,
        transferNumber: index + 1,
      }))
      .sort((a, b) => b.transferNumber - a.transferNumber);

    setManualTransferData({
      ...manualTransferData,
      transfers: newTransfers,
    });

    setShowRemoveTransferModal(false);
    setTransferToRemove(null);
    resetForm();
    toastHook.success(ids.toastDeleteSuccess, texts.deleteSuccess);
  };

  const handleShowRemoveTransferModal = (transfer: LotTransfer): void => {
    setShowRemoveTransferModal(true);
    setTransferToRemove(transfer);
  };

  const handleEditTransferCancel = () => {
    resetForm();
  };

  const handleEditTransferSave = () => {
    const parseAmount = (amountString: string) =>
      amountString.includes(',') ? convertAmountTextToNumber(amountString) : Number(amountString);
    const transfer: LotTransfer = {
      transferNumber: Number(transferSelected?.transferNumber),
      debitAccount: debitAccountSelected,
      creditAccount: creditAccountSelected,
      transferData: {
        ...transferData,
        amount: parseAmount(transferAmountString),
      },
      additionalInformation: isPROVProcessSelected
        ? {
            ...additionalInformation,
            totalRetentionAmount: parseAmount(totalRetentionAmountString),
            creditNoteAmount: parseAmount(creditNoteAmountString),
          }
        : null,
    };
    const transfers = [...manualTransferData.transfers];
    const index = transfers.findIndex(item => item.transferNumber === transfer.transferNumber);
    transfers[index] = transfer;
    setManualTransferData({
      ...manualTransferData,
      hasDraft: null,
      transfers: transfers.sort((a, b) => b.transferNumber - a.transferNumber),
    });
    resetForm();
    toastHook.success(ids.toastSaveSuccess, texts.saveSuccess);
  };

  const setAccountsNotAvailable = (id: AccountsNotAvailable) => {
    setManualTransferData({
      ...manualTransferData,
      accountsNotAvailable: id,
      transferType: '',
      step: 1,
    });
  };

  const init = () => {
    setFirstExecution(true);
    loadDebitAccounts();
    loadCreditAccounts();
    fetchUsageReferences();
    loadDebitBanks();
    loadCreditBanks();
    setDebitFiltersApplied(filtersAppliedDrawerDefault);
    setIsReferenceActive(false);
    clearDrawerConfectionFilters();

    if (manualTransferData?.transferType === TRANSFER_TYPE_CODE.TER) {
      setConceptListLoading(true);
      fetchConcepts();
    }

    if (manualTransferData?.transferType === TRANSFER_TYPE_CODE.PRO) {
      setRetentionTypeLoading(true);
      setDocumentToPayLoading(true);
      fetchTypesRetention();
      fetchDocumentsToPay();
    }

    setIsTransferDataSectionVisible(VALID_CODES.includes(manualTransferData?.transferType));
    setIsTERProccess(manualTransferData?.transferType === TRANSFER_TYPE_CODE.TER);
    setIsPROVProcessSelected(manualTransferData?.transferType === TRANSFER_TYPE_CODE.PRO);
  };

  useEffect(() => {
    let validTransfer =
      !!debitAccountSelectedId &&
      !!creditAccountSelectedId &&
      Number(transferAmountString) > 0 &&
      !!transferData.date &&
      (manualTransferData?.transferType === TRANSFER_TYPE_CODE.TER ? !!transferData.concept?.value : true) &&
      (disclaimerConceptText ? transferData.isDisclaimerChecked : true);
    if (transferSelected) {
      let countChanges = 0;
      if (
        transferSelected.debitAccount?.accountId !== debitAccountSelectedId ||
        transferSelected.creditAccount?.accountId !== creditAccountSelectedId
      ) {
        countChanges += 1;
      }
      if (transferSelected.transferData) {
        if (
          !isEqual(transferData, transferSelected.transferData) ||
          transferSelected.transferData.amount !== Number(transferAmountString)
        ) {
          countChanges += 1;
        }
      }
      if (transferSelected.additionalInformation) {
        if (
          !isEqual(additionalInformation, transferSelected.additionalInformation) ||
          (transferSelected.additionalInformation?.totalRetentionAmount
            ? transferSelected.additionalInformation?.totalRetentionAmount !== Number(totalRetentionAmountString)
            : false) ||
          (transferSelected.additionalInformation?.creditNoteAmount
            ? transferSelected.additionalInformation?.creditNoteAmount !== Number(creditNoteAmountString)
            : false)
        ) {
          countChanges += 1;
        }
      }
      validTransfer = validTransfer && !!countChanges;
    }
    let hasDraft = null;
    if (validTransfer) {
      hasDraft = transferSelected ? DraftModal.edit : DraftModal.add;
    }
    setManualTransferData({
      ...manualTransferData,
      hasDraft,
    });
    setIsValidTransfer(validTransfer);
  }, [
    transferSelected,
    manualTransferData?.transferType,
    debitAccountSelectedId,
    creditAccountSelectedId,
    transferData,
    transferAmountString,
    disclaimerConceptText,
    additionalInformation,
    totalRetentionAmountString,
    creditNoteAmountString,
  ]);

  useEffect(() => {
    if (allFetchesDone) {
      const errorsServices = [usageReferencesError, debitAccountsError, debitBanksError, creditAccountsError, creditBanksError];
      isTERProccess && errorsServices.push(conceptListError);
      isPROVProcessSelected && errorsServices.push(documentToPayError, retentionTypeError);
      const hasAnyError = firstExecution && errorsServices.some(isError => isError);

      setIsAnyServiceFailed(hasAnyError);
      setAllFetchesComplete(!hasAnyError);
      setAllFetchesDone(false);
      setFirstExecution(false);
    }
  }, [
    firstExecution,
    allFetchesDone,
    usageReferencesError,
    debitAccountsError,
    debitBanksError,
    creditAccountsError,
    creditBanksError,
    conceptListError,
    documentToPayError,
    retentionTypeError,
    isTERProccess,
    isPROVProcessSelected,
  ]);

  useEffect(() => {
    const loadingServices = [usageReferencesLoading, debitAccountsLoading, debitBanksLoading, creditAccountsLoading, creditBanksLoading];
    isTERProccess && loadingServices.push(conceptListLoading);
    isPROVProcessSelected && loadingServices.push(documentToPayLoading, retentionTypeLoading);
    const servicesFinishLoading = loadingServices.every(isLoading => !isLoading);

    setAllFetchesDone(servicesFinishLoading);
  }, [
    usageReferencesLoading,
    debitAccountsLoading,
    debitBanksLoading,
    creditAccountsLoading,
    creditBanksLoading,
    conceptListLoading,
    documentToPayLoading,
    retentionTypeLoading,
    isTERProccess,
    isPROVProcessSelected,
  ]);

  useEffect(() => {
    if (!debitAccountsLoading && !creditAccountsLoading) {
      if (!debitAccounts.length && !creditAccounts.length) {
        setAccountsNotAvailable(AccountsNotAvailable.both);
      } else if (!debitAccounts.length) {
        setAccountsNotAvailable(AccountsNotAvailable.debit);
      } else if (!creditAccounts.length) {
        setAccountsNotAvailable(AccountsNotAvailable.credit);
      } else if (
        debitAccounts.length === 1 &&
        creditAccounts.length === 1 &&
        (debitAccounts[0].identifier === creditAccounts[0].identifier || debitAccounts[0].currency !== creditAccounts[0].currency)
      ) {
        setAccountsNotAvailable(AccountsNotAvailable.unique);
      } else {
        setEnabledAccountSelection(true);
      }
    }
  }, [debitAccountsLoading, debitAccounts, creditAccountsLoading, creditAccounts]);

  useEffect(() => {
    init();
  }, []);

  return {
    allFetchesComplete,
    isAnyServiceFailed,
    transferType,
    manualTransferData,
    usageReferences,
    holidays,
    transferData,
    transferAmountString,
    conceptsListOptions,
    disclaimerConceptText,
    isTERProccess,
    isTransferDataSectionVisible,
    conceptListLoading,
    isValidTransfer,
    additionalInformation,
    isPROVProcessSelected,
    retentionTypeListOptions,
    showRemoveTransferModal,
    documentToPayListOptions,
    totalRetentionAmountString,
    creditNoteAmountString,
    transferToRemove,
    onCancelRemoveTransfer,
    onRetryAllServices,
    onRemoveTransfers,
    handleShowRemoveTransferModal,
    setTransferData,
    setTransferAmountString,
    setDisclaimerConceptText,
    onChangeConcept,
    setAdditionalInformation,
    setCreditNoteAmountString,
    setTotalRetentionAmountString,
    handleAddTransfer,
    handleEditTransfer,
    handleEditTransferCancel,
    handleEditTransferSave,
  };
}
