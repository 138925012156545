import { colors } from '../../styled/generalStyles';

const iconBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '18.33px',
  height: '18.33px',
  position: 'relative',
  color: colors.Primary100,
  marginRight: '8px',
};

const checkboxBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  width: '261px',
  height: '36px',
  gap: '8px',
  marginLeft: '6px',
  fontSize: '14px',
};

const helpCircleStyle = {
  width: '18.33px',
  color: colors.Info100,
};

const checkboxContainerStyles = {
  alignItems: 'center',
  gap: '12px',
};

const customSkeletonCircle = {
  width: '22px',
  height: '22px',
  borderRadius: '50%',
};

const customSkeletonFirstCheckbox = {
  width: '188px',
  height: '16px',
  borderRadius: '4px',
  marginLeft: '12px',
};

export { checkboxBoxStyles, checkboxContainerStyles, customSkeletonCircle, customSkeletonFirstCheckbox, helpCircleStyle, iconBoxStyles };
