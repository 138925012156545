import { Box, Skeleton } from '@chakra-ui/react';
import { Card, CardSection } from '@wow/card';

import {
  card,
  cardContent,
  cardSection,
  skeletonAvatar,
  skeletonDetail,
  skeletonFooter,
  skeletonIcon,
  skeletonSubtitle,
  skeletonTitle,
} from './index.styled';
import CardAccountSkeletonProps from './interfaces';

function CardAccountSkeleton({ isDebit }: CardAccountSkeletonProps) {
  return (
    <Card style={card}>
      <CardSection style={cardSection}>
        <Box marginLeft='12px'>
          <Skeleton style={skeletonAvatar} />
        </Box>
        <Box sx={cardContent}>
          <Skeleton style={skeletonTitle} />
          <Skeleton style={skeletonSubtitle} />
          <Skeleton style={skeletonDetail} />
        </Box>
        <Box marginRight='12px'>
          <Skeleton style={skeletonIcon} />
        </Box>
      </CardSection>
      {isDebit && <Skeleton style={skeletonFooter} />}
    </Card>
  );
}

export default CardAccountSkeleton;
