import { Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const CustomBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomText = styled(Text)`
  margin: 0px 10px 0px 5px;
`;

export { CustomBox, CustomText };
