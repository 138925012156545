import { AsyncConfectionTransfer } from '@interfaces/api';
import { Currencies } from '@interfaces/transfers';
import { LotTransfer, SelectOption } from '@interfaces/transferSetup';

function mapAsyncTransfers(transfers: AsyncConfectionTransfer[]) {
  return transfers.map(
    (item, index) =>
      ({
        transferNumber: index,
        debitAccount: {
          bank: {
            bcoIdBco: item.bankId,
            bankName: item.bankName,
            logoBank: item.bankLogo,
          },
          accountId: item.accountId,
          accountNumber: item.accountNumber,
          accountType: item.accountType,
          accountName: item.denominationAccount,
          cbu: item.cbu,
          cuit: item.cuit,
          currency: item.currency,
        },
        transferData: {
          date: item.dateRequest,
          amount: item.amount,
        },
        quantityTef: item.quantityTef,
      } as LotTransfer),
  );
}

function mapValidatedAsyncTransfers(
  transfers: AsyncConfectionTransfer[],
  conceptsOptions: SelectOption[],
  documentTypesOptions: SelectOption[],
  retentionTypesOptions: SelectOption[],
) {
  const validatedTransfers: LotTransfer[] = [];
  let transferNumber = 0;
  transfers.forEach(item => {
    item.creditAccounts.forEach(creditAccount => {
      transferNumber += 1;
      validatedTransfers.push({
        transferNumber,
        debitAccount: {
          bank: {
            bcoIdBco: item.bankId,
            bankName: item.bankName,
            logoBank: item.bankLogo,
          },
          accountId: item.accountId,
          accountNumber: item.accountNumber,
          accountType: item.accountType,
          accountName: item.denominationAccount,
          cbu: item.cbu,
          cuit: item.cuit,
          currency: item.currency as Currencies,
        },
        creditAccount: {
          accountId: creditAccount.accountId,
          accountNumber: creditAccount.accountNumber,
          accountType: creditAccount.accountType,
          accountName: creditAccount.denominationAccount,
          bank: {
            bcoIdBco: creditAccount.bankId,
            bankName: creditAccount.bankName,
            logoBank: creditAccount.bankLogo,
          },
          cbu: creditAccount.cbu,
          cuit: creditAccount.cuit,
          currency: item.currency as Currencies,
          useReference: '',
          transferType: '',
        },
        transferData: {
          date: item.dateRequest,
          amount: creditAccount.amount,
          observation: creditAccount.observation,
          reason: creditAccount.addendum?.reasons || '',
          voucher: creditAccount.addendum?.voucher || '',
          concept: creditAccount.addendum?.concept
            ? conceptsOptions.find(options => options.value === creditAccount.addendum?.concept) || null
            : null,
          isDisclaimerChecked: creditAccount.addendum?.disclaimerAccepted || false,
        },
        additionalInformation: {
          numberClient: creditAccount.addendum?.clientCode || '',
          document: creditAccount.addendum?.documentType
            ? documentTypesOptions.find(options => options.value === creditAccount.addendum?.documentType) || null
            : null,
          documentNumber: creditAccount.addendum?.documentNumber || '',
          retentionType: creditAccount.addendum?.retentionType
            ? retentionTypesOptions.find(options => options.value === creditAccount.addendum?.retentionType) || null
            : null,
          totalRetentionAmount: creditAccount.addendum?.totalRetention || 0,
          paymentOrderType: creditAccount.addendum?.orderPaymentType || '',
          paymentOrderNumber: creditAccount.addendum?.paymentOrderNumber || '',
          creditNoteNumber: creditAccount.addendum?.creditNoteNumber || 0,
          creditNoteAmount: creditAccount.addendum?.creditNoteAmount || 0,
        },
      });
    });
  });
  return validatedTransfers;
}

export { mapAsyncTransfers, mapValidatedAsyncTransfers };
