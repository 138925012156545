import { useState } from 'react';

import { CardKey } from '../views/Modify/components/modifyDrawer/interfaces';

const useVisibility = () => {
  const [visibility, setVisibility] = useState<{ [key in CardKey]: boolean }>({
    debitAccount: false,
    creditAccount: false,
  });

  const toggleVisibility = (key: CardKey) => {
    setVisibility(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return { visibility, toggleVisibility, setVisibility };
};

export default useVisibility;
