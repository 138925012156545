/* eslint-disable no-shadow */
import React from 'react';

import { BankAccount } from './api';

export type File = {
  rowId?: string;
  fileId: string;
  fileName: string;
  dateTime: string;
};

export type Lot = {
  transferNumbers: number;
  debitAccount: {
    cbu: string;
    bankCodeBCRA: string;
    accountId: string;
    cuit: string;
    bankName: string;
    accountName: string;
    currency: string;
    accountNumber: string;
    accountType: string;
  };
  creditAccount: [
    {
      cbu: string;
      bankCodeBCRA: string;
      accountId: string;
      cuit: string;
      bankName: string;
      accountName: string;
      currency: string;
      accountNumber: string;
      accountType: string;
    },
  ];
  requestDate: string;
  requestDateStatus: string;
  totalAmount: number;
  lotNumber: number;
  lotTransferTypeCode: string;
  lotTransferTypeDescription: string;
  otpRequired: string;
  quantityCreditAccounts: number;
  unifiedShipping: string;
  lotId: string;
  lotStatus: string;
  uniqueId?: string;
  macTransfer: [
    {
      mensajeMac: string;
      numeroTransferencia: number;
      numeroPaso: number;
      estado: string;
      firmantesHash: string;
    },
  ];
};

export type PartialRejectAuthDebitAccount = {
  cbu?: string;
  bankCodeBCRA?: string;
  accountId?: string;
  cuit?: string;
  bankName?: string;
  accountName?: string;
  currency?: string;
  accountNumber?: string;
  accountType?: string;
};
export type PartialRejectAuthCreaditAccount = {
  cbu?: string;
  bankCodeBCRA?: string;
  accountId?: string;
  cuit?: string;
  bankName?: string;
  accountName?: string;
  currency?: string;
  accountNumber?: string;
  accountType?: string;
};

export type LotDetail = {
  lotNumber: number;
  transferNumber: number;
  uniqueId?: string;
  amount: number;
  createDate: string;
  requestDate: string;
  datanetNumber: number;
  transferType: string;
  status: string;
  currency: string;
  debitAccount: PartialRejectAuthDebitAccount;
  creditAccount: PartialRejectAuthCreaditAccount;
  signHash: string;
  signs: [
    {
      name: string;
      lastName: string;
      cuil: string;
      dateHour: string;
    },
  ];
  otpMark: string;
  stepNumber: string;
  referenceNumber: string;
  shippingNumber: string;
  unifiedShippingMark: string;
  macMessage: string;
  permissions: [{ permisoDescripcion: string }];
};

export type Pagination = {
  pageNumber?: number;
  totalElements: number;
  totalElementsRequested: number;
  totalPages: number;
};

export interface ViewNoResultProps {
  title: string;
  description: string[] | string;
  description2?: string;
  linkName?: string;
  Icon: React.ElementType;
  onFilterLot?: (numberLot: number[]) => void;
  handleToggleClick?: () => void;
  handleToggleDrawerRetry?: () => void;
  retryOtherLink?: boolean;
  nothingHere?: boolean;
  onClickLink?: () => void;
  drawerIcon?: boolean;
  iconDisconnectDrawer?: boolean;
  transferDetailDrawer?: boolean;
  isConfection?: boolean;
  isConfectionDrawer?: boolean;
}

export interface TransferOperationDetails {
  amount: number;
  bankCode: string;
  bankName: string;
  createAt: string;
  currencyType: string;
  lot: number;
  macMessage: string;
  step: string;
  transfer: number;
  type: string;
  unifiedSend: string;
  immediately?: string | null;
  canInmediate: boolean;
}

export interface TransferOperationTotals {
  totalTransfers: number;
  amount: number;
  currencyType: string;
  hasPartialAmountSue: boolean;
}

export interface UnifiedShippingChildrenProps {
  lotNumbers: number[];
  unifiedTitleShippingText1: string;
  unifiedTitleShippingText2: string;
}

export interface CauseRejected {
  transfer: number;
  message: string;
}

export interface LotRejected {
  totalTransfer: number;
  numberLot: number;
  causeRejected?: CauseRejected[];
}

export interface TransfersRejected {
  text?: string[];
  lotsRejected?: LotRejected[];
  message: string;
  totalTransferByMessage: number;
}

export interface TransfersAccepted {
  totalTransfer: number;
  numberLot: number;
  numberTransfer: number[];
}

export interface TransfersValidated {
  totalTransfer: number;
  numberLot: number;
  numberTransfer: number[];
}

export interface TransfersRejectedUnassociatedMacKey {
  message: string;
  totalTransferByMessage: number;
  text: string;
}

export interface TableDataStateType {
  data?: Lot[] | undefined;
}

export interface TableDataStateTypeDrawerError {
  data?: File[] | undefined;
}

export type ActionFunction = () => void;

export enum TransfersFlow {
  Authorize = 'Authorize',
  Send = 'Send',
  Unauthorize = 'Unauthorize',
  Annul = 'Annul',
  Revoke = 'Revoke',
  Confection = 'Confection',
}

export interface DetailParams {
  lot: string;
  date: string;
  bank: string;
  status: string;
  amount: number;
  transfersFlow?: TransfersFlow;
  origin?: string;
  confection: boolean;
}

export interface ServiceParams {
  myApprovals?: string;
  transfersStatus?: string;
  size?: string;
  page?: string;
  orderProperty?: string;
  confection?: boolean;
}

export enum ServiceAction {
  ANU = 'ANU',
  AUT = 'AUT',
  CON = 'CON',
  DES = 'DES',
  ENV = 'ENV',
  MOD = 'MOD',
  REV = 'REV',
}

export enum ResultErrorCode {
  TEF_TRANSFERS_REJECT_ADD = 'TEF_TRANSFERS_REJECT_ADD',
  TEF_NOT_ACCEPTS_TRANSFER_TYPE_CRED = 'TEF_NOT_ACCEPTS_TRANSFER_TYPE_CRED',
  TEF_NOT_ACCEPTS_MULTIBANK_CRED = 'TEF_NOT_ACCEPTS_MULTIBANK_CRED',
  TEF_NOT_ACCEPTS_TRANSFER_TYPE_DEB = 'TEF_NOT_ACCEPTS_TRANSFER_TYPE_DEB',
  TEF_NOT_ACCEPTS_MULTIBANK_DEB = 'TEF_NOT_ACCEPTS_MULTIBANK_DEB',
  TEF_OPEN_CREDIT_ACCOUNT_DISABLED = 'TEF_OPEN_CREDIT_ACCOUNT_DISABLED',
  TEF_NOT_BANK_OPERATIVE = 'TEF_NOT_BANK_OPERATIVE',
  TEF_BANK_DOES_NOT_OFFER_SERVICE = 'TEF_BANK_DOES_NOT_OFFER_SERVICE',
  TEF_BANK_DOES_NOT_OPERATE_CREDITS = 'TEF_BANK_DOES_NOT_OPERATE_CREDITS',
  TEF_BANK_DOES_NOT_OPERATE_DEBITS = 'TEF_BANK_DOES_NOT_OPERATE_DEBITS',
  TEF_OPE_NO_TEF = 'TEF_OPE_NO_TEF',
  TEF_SERVICE_BANK_DISABLED = 'TEF_SERVICE_BANK_DISABLED',
  TEF_NON_EXISTENT_TOP = 'TEF_NON_EXISTENT_TOP',
  TEF_ACCOUNT_CREDIT_USE_ENABLED = 'TEF_ACCOUNT_CREDIT_USE_ENABLED',
  TEF_CREDIT_ACCOUNT_ERROR = 'TEF_CREDIT_ACCOUNT_ERROR',
  TEF_CREDIT_TIME_HOURS = 'TEF_CREDIT_TIME_HOURS',
  TEF_DEBIT_TIME_HOURS = 'TEF_DEBIT_TIME_HOURS',
  TEF_DEBIT_ACCOUNT_NOT_ENABLED = 'TEF_DEBIT_ACCOUNT_NOT_ENABLED',
  TEF_CREDIT_ACCOUNT_NOT_ENABLED = 'TEF_CREDIT_ACCOUNT_NOT_ENABLED',
  TEF_DEBIT_ACCOUNT_NOT_ENABLED_TRANSFERS = 'TEF_DEBIT_ACCOUNT_NOT_ENABLED_TRANSFERS',
  TEF_CREDIT_BRANCH_ENABLED = 'TEF_CREDIT_BRANCH_ENABLED',
  TEF_DEBIT_BRANCH_ENABLED = 'TEF_DEBIT_BRANCH_ENABLED',
  TEF_NOT_AUTHORIZED_STATUS = 'TEF_NOT_AUTHORIZED_STATUS',
  TEF_ACCOUNT_RELATIONS = 'TEF_ACCOUNT_RELATIONS',
  TEF_CAN_NOT_OPERATE_ACCOUNT_DEB = 'TEF_CAN_NOT_OPERATE_ACCOUNT_DEB',
  TEF_NO_TIME_HOURS = 'TEF_NO_TIME_HOURS',
  TEF_CAN_NOT_OPERATE_ACCOUNT_CRED = 'TEF_CAN_NOT_OPERATE_ACCOUNT_CRED',
  TEF_ACCOUNT_MODE_USE_CREDIT = 'TEF_ACCOUNT_MODE_USE_CREDIT',
  TEF_ACCOUNT_MODE_USE_DEBIT = 'TEF_ACCOUNT_MODE_USE_DEBIT',
  TEF_EXCEED_CANT_TEF = 'TEF_EXCEED_CANT_TEF',
  TEF_OVER_TOPE_DAY_ESQ = 'TEF_OVER_TOPE_DAY_ESQ',
  TEF_OVER_TOPE_DAY_ESQ_CTA_USE = 'TEF_OVER_TOPE_DAY_ESQ_CTA_USE',
  TEF_EXCEED_TOPE_DAY_CTA = 'TEF_EXCEED_TOPE_DAY_CTA',
  TEF_OVER_TOPE_TRX_ESQ_CTA = 'TEF_OVER_TOPE_TRX_ESQ_CTA',
  TEF_OVER_TOPE_TRX_ESQ_CTA_USE = 'TEF_OVER_TOPE_TRX_ESQ_CTA_USE',
  TEF_IMPAC_CTA_DEBIT_PART = 'TEF_IMPAC_CTA_DEBIT_PART',
  TEF_IMP_CTA_PART = 'TEF_IMP_CTA_PART',
  TEF_LIMIT_IMPORT = 'TEF_LIMIT_IMPORT',
  TEF_ERROR_IMPORT_LIMIT = 'TEF_ERROR_IMPORT_LIMIT',
  TEF_EXCEED_TOPE = 'TEF_EXCEED_TOPE',
  TEF_AMOUNT_TEF_EXCEEDED = 'TEF_AMOUNT_TEF_EXCEEDED',
  TEF_OPE_CAN_NOT_SIGN = 'TEF_OPE_CAN_NOT_SIGN',
  TEF_OPERATOR_NOT_OPERA_WITH_BANK = 'TEF_OPERATOR_NOT_OPERA_WITH_BANK',
  TEF_NO_REMOVE_AUTHORIZATION = 'TEF_NO_REMOVE_AUTHORIZATION',
  TEF_NOT_AVAILABLE = 'TEF_NOT_AVAILABLE',
  TEF_NOT_FOUND_PRESENTATION_AFIP = 'TEF_NOT_FOUND_PRESENTATION_AFIP',
  REV_UNIFIED_SHIPPING = 'REV_UNIFIED_SHIPPING',
  REV_OPERATOR_NOT_AUTHORIZED = 'REV_OPERATOR_NOT_AUTHORIZED',
  REV_NON_STATUS = 'REV_NON_STATUS',
  TEF_GENERIC_ERROR = 'TEF_GENERIC_ERROR',
}

export enum ResultErrorIcon {
  alertTriangle = 'alert-triangle',
  bank = 'bank',
  clock = 'clock',
  edit3 = 'edit-3',
  settings = 'settings',
  trendingUp = 'trending-up',
  layers = 'layers',
  calendar = 'calendar',
  lock = 'lock',
}

export interface ResultError {
  code: ResultErrorCode;
  icon: ResultErrorIcon;
  description: string;
}

export interface ResultItem {
  error: ResultError;
  count: number;
}

export enum UrlTransfers {
  Authorize = 'autorizar',
  Send = 'enviar',
  Unauthorize = 'quitar',
  Annul = 'anular',
  Revoke = 'revocar',
}

export interface Option {
  label: string;
  value: string;
}

export interface ListTransferTypesOptions {
  code: string;
  text: string;
}
export interface TransfersAcceptedPartialRejectAuth {
  lotNumber: number;
  transferNumber: number;
  type: string;
  amount: number;
  createDate: string;
  requestDate: string;
  transferType: string;
  status: string;
  currency: string;
  debitAccount: {
    cbu: string;
    accountId: string;
    cuit: string;
    bankName: string;
    accountName: string;
    accountNameDetail: {
      community: string;
      vendorName: string;
      accountName: string;
      visibleAccountId: string;
    };
    currency: string;
    accountNumber: string;
    accountType: string;
    typeAccountId: number;
    currencyId: number;
    codeBCRA: string;
  };
  creditAccount: {
    cbu: string;
    accountId: string;
    cuit: string;
    bankName: string;
    accountName: string;
    accountNameDetail: {
      community: string;
      vendorName: string;
      accountName: string;
      visibleAccountId: string;
    };
    currency: string;
    accountNumber: string;
    accountType: string;
    typeAccountId: number;
    currencyId: number;
    codeBCRA: string;
  };
  signHash: string;
  otpMark: string;
  stepNumber: string;
  unifiedShippingMark: string;
  lotTransferTypeCode: string;
  lotTransferTypeDescription: string;
}

export interface TotalAmountPartialRejectAuth {
  amount: number;
  currencyType: string;
  hasPartialAmountSue: boolean;
  totalTransfers: number;
}

export interface TransferTypeOptionSelected {
  calendarDefer: number;
  deferMaxDays: number;
  code: string;
  description: string;
}

export interface AmountRanges {
  fromAmount: number | null;
  toAmount: number | null;
}

export interface FilterStatus {
  isTransferTypeActive: boolean;
  isLotListActive: boolean;
  isRangeAmountsActive: boolean;
  isTransferNumberActive: boolean;
}

export enum AccountingType {
  debit = 'debit',
  credit = 'credit',
}

export enum Currencies {
  ARS = 'ARS',
  USD = 'USD',
}

export type Paging = {
  totalAccounts: number;
  totalPages: number;
  page: number;
  accounts: BankAccount[][];
};
