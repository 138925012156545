/* eslint-disable no-shadow */
interface Props {
  openDrawer: () => void;
}

enum AsyncProcessesMode {
  inProgress = 'inProgress',
  result = 'result',
  success = 'success',
  error = 'error',
}

interface AsyncProcessesStatusesCount {
  total: number;
  inProgress: number;
  success: number;
  error: number;
}

export { AsyncProcessesMode };
export type { AsyncProcessesStatusesCount, Props };
