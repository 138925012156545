import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Icon, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, DollarSign, Layers } from 'react-feather';

import { useTransfersContext } from '../../../../../../context/TransfersContext';
import { colors } from '../../../../../../styled/generalStyles';
import { texts, TRANSFER_TYPE } from '../../constants';
import TransferItem from '../TransferItem';
import {
  AccordionBody,
  AccordionBodyContent,
  AccordionBodyLotDetailFrameOne,
  AccordionBodyLotDetailFrameTwo,
  AccordionContainer,
  AccordionHeader,
  AccordionItemTranfers,
  AccordionTransfers,
  AccordionTransfersContent,
  Chevron,
  ChevronContainer,
  ConsolidatedText,
} from './index.styled';
import { Props } from './interfaces';

function TransferAccordion({ handleEditTransfer, handleRemoveTransfer, transferSelectedNumber }: Props) {
  const { manualTransferData } = useTransfersContext();
  const [accordionIndex, setAccordionIndex] = useState(manualTransferData.transfers.length ? [1] : [0, 1]);

  useEffect(() => {
    setAccordionIndex(manualTransferData.transfers.length ? [1] : [0, 1]);
  }, [manualTransferData.transfers.length]);

  return (
    <Accordion
      defaultIndex={[0, 1]}
      index={accordionIndex}
      onChange={(index: number[]) => setAccordionIndex(index)}
      allowMultiple
      sx={AccordionContainer}
    >
      <AccordionItem border='0'>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              sx={AccordionHeader}
              bg={isExpanded ? colors.Primary60 : colors.White}
              data-testid='accordion-show-details-button'
            >
              <Text as='span' textStyle='h5Bold' margin={0}>
                {texts.lotInfo}
              </Text>
              <Flex sx={ChevronContainer}>
                <Icon as={isExpanded ? ChevronUp : ChevronDown} sx={Chevron} />
              </Flex>
            </AccordionButton>
            <AccordionPanel pb='16px' sx={AccordionBody}>
              <Box sx={AccordionBodyContent}>
                <Text textStyle='labelLgBold' margin={0}>
                  {texts.transferType} {TRANSFER_TYPE[manualTransferData?.transferType] || ''}
                </Text>
                <Box sx={AccordionBodyLotDetailFrameOne}>
                  {!!manualTransferData?.description && (
                    <Text as='span' textStyle='bodySm'>
                      {texts.description}
                      <Text as='span' textStyle='bodySmSemi'>
                        {manualTransferData?.description}
                      </Text>
                    </Text>
                  )}
                  {!!manualTransferData?.paymentNumber && (
                    <Text as='span' textStyle='bodySm' margin={0}>
                      {texts.paymentNumber}
                      <Text as='span' textStyle='bodySmSemi'>
                        {manualTransferData?.paymentNumber}
                      </Text>
                    </Text>
                  )}
                </Box>
                <Flex sx={AccordionBodyLotDetailFrameTwo}>
                  <DollarSign data-testid='dollar-icon' width='20px' height='20px' color={colors.Secondary100} />
                  <Text textStyle='bodySm' style={ConsolidatedText}>
                    {texts.consolidated}
                    <Text as='span' textStyle='bodySmSemi'>
                      {manualTransferData?.consolidated ? texts.yes : texts.no}
                    </Text>
                  </Text>
                  <Layers data-testid='layers-icon' width='20px' height='20px' color={colors.Secondary100} />
                  <Text textStyle='bodySm' margin='0'>
                    {texts.unifiedShipping}
                    <Text as='span' textStyle='bodySmSemi'>
                      {manualTransferData?.unifiedShipping ? texts.yes : texts.no}
                    </Text>
                  </Text>
                </Flex>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
      <AccordionItem border='0' sx={AccordionItemTranfers}>
        {({ isExpanded }) => (
          <>
            <AccordionButton sx={AccordionHeader} bg={isExpanded ? colors.Primary60 : colors.White}>
              <Text as='span' textStyle='h5Bold' margin={0}>
                {texts.lotTransfersTitle}
              </Text>
              <Flex sx={ChevronContainer}>
                <Icon as={isExpanded ? ChevronUp : ChevronDown} sx={Chevron} />
              </Flex>
            </AccordionButton>
            <AccordionPanel sx={AccordionTransfers}>
              {manualTransferData.transfers.length ? (
                <Flex flexDirection='column'>
                  {manualTransferData.transfers.map((item, index) => (
                    <TransferItem
                      key={`k${index + 1}`}
                      transfer={item}
                      isActive={transferSelectedNumber === item.transferNumber}
                      isDisabled={!!transferSelectedNumber}
                      handleEditTransfer={handleEditTransfer}
                      handleRemoveTransfer={handleRemoveTransfer}
                    />
                  ))}
                </Flex>
              ) : (
                <Box sx={AccordionTransfersContent}>
                  <Text as='span' textStyle='bodyMd'>
                    {texts.lotTransfersEmpty}
                  </Text>
                </Box>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

export default TransferAccordion;
