/* eslint-disable no-nested-ternary */
import { Box, Skeleton } from '@chakra-ui/react';
import { Card, CardSection } from '@wow/card';

import { card, cardContent, cardSection } from './index.styled';

function CardAccountSkeleton() {
  return (
    <Card style={card}>
      <CardSection style={cardSection}>
        <Box marginLeft='12px'>
          <Skeleton width='40px' height='40px' borderRadius='50%' />
        </Box>
        <Box sx={cardContent}>
          <Skeleton width='110px' height='15px' />
          <Skeleton width='300px' height='24px' />
          <Skeleton width='270px' height='20px' />
        </Box>
      </CardSection>
    </Card>
  );
}

export default CardAccountSkeleton;
