import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

const CustomBox = styled(Box)`
  display: flex;
  margin-top: 24px;
  margin-bottom: 100px;
  width: 100%;
`;

const BoxLeft = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  width: 40%;
`;

const BoxRigth = styled(Box)`
  width: 60%;
`;

export { BoxLeft, BoxRigth, CustomBox };
