/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject } from 'react';

import { TRANSFER_STATUS } from '../constants-app';
import { ServiceParams, TransfersFlow } from '../Interfaces/transfers';
import { arrayToObjectType } from '../Interfaces/utils/configTableFunctions';

export const arrayToObject = (arr: arrayToObjectType[], isLot = true) =>
  arr?.reduce((obj: Record<string, boolean>, element) => {
    const key = (isLot ? element.id : element.transferId) as string;
    return {
      ...obj,
      [key]: true,
    };
  }, {});

export const getRowIdPendingTransfers = (row: any, relativeIndex: any, parent: any) => {
  return parent ? [parent.id, row.uniqueId].join('.') : row?.lotId;
};

export const getRowIdLotDetail = (row: any, relativeIndex: any, parent: any) => {
  return parent ? [parent.id, row.uniqueId].join('.') : row?.transferNumber;
};

export const PENDING_TRANSFERS = 'pendingTransfers';

export const AUTH_TRANSFERS = 'authTransfers';

export const UNAUTHORIZE_TRANSFERS = 'unauthorizeTransfers';

export const LOT_DETAIL = 'lotDetail';

export const ANNUL_TRANSFERS = 'annulTransfers';

export const REVOKE_TRANSFERS = 'revokeTransfers';

export const MODIFY_TRANSFERS = 'modifyTransfers';

export const HISTORICAL_ERROR_DRAWER_LIST = 'HISTORICAL_ERROR_DRAWER';

export function hasMaxLengthFunction(elemText: MutableRefObject<HTMLInputElement | null>, maxLength: number) {
  if (elemText.current) {
    const creditAccountElemTextWidth = elemText.current?.clientWidth || '';
    return Number(creditAccountElemTextWidth) >= maxLength;
  }
  return false;
}

export function getServiceParams(transfersFlow: TransfersFlow): ServiceParams {
  const params: ServiceParams = {};
  switch (transfersFlow) {
    case TransfersFlow.Send:
      params.transfersStatus = TRANSFER_STATUS.FIR;
      break;
    case TransfersFlow.Unauthorize:
      params.transfersStatus = `${TRANSFER_STATUS.EFR};${TRANSFER_STATUS.FIR}`;
      params.myApprovals = 'true';
      break;
    case TransfersFlow.Annul:
      params.transfersStatus = TRANSFER_STATUS.CON;
      break;
    case TransfersFlow.Revoke:
      params.transfersStatus = TRANSFER_STATUS.DPD;
      break;
    case TransfersFlow.Authorize:
    default:
      params.transfersStatus = `${TRANSFER_STATUS.CON};${TRANSFER_STATUS.EFR}`;
      params.myApprovals = 'false';
      break;
  }
  return params;
}
