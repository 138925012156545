/* eslint-disable no-nested-ternary */
import { mapAsyncTransfers, mapValidatedAsyncTransfers } from '@views/ConfectionResult/helpers';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthorizationState, DOWNLOAD_FORMAT, EXCEPTION_CODES, RetentionState, routesNames } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { AsyncConfectionResponseData, Permissions } from '../Interfaces/api';
import { ServiceAction, TransfersFlow } from '../Interfaces/transfers';
import { LotTransfer, LotTransferRejected, ResultRejectedItem, ResultStatus, SelectOption } from '../Interfaces/transferSetup';
import {
  getAsyncConfection,
  getConcepts,
  getDocumentsToPay,
  getDownloadFileTransfersAsync,
  getPermissions,
  getTypesRetention,
} from '../services/api';
import { downloadTransfersFile } from '../utils/downloadTransfersFile';
import { generateRejectedTransfContent } from '../utils/generateRejectedTransfContent';
import { generateTransfContent } from '../utils/generateTransfContent';
import {
  CONFECTION_ERRORS,
  downloadFailedModal,
  downloadInfoModal,
  downloadSuccessfulModal,
  GENERIC_ERROR,
  texts,
} from '../views/ConfectionResult/constants';
import { TRANSFER_TYPE } from '../views/TransferSetup/Steps/Step2/constants';
import { TransfersGroup } from '../views/TransferSetup/Steps/Step3/interfaces';
import useToastMessage from './useToastMessage';
import { useTransferSetupStep3 } from './useTransferSetupStep3';

export function useConfectionResult() {
  const toastHook = useToastMessage();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    manualTransferData,
    sectionPermissions,
    confectionData,
    clearConfection,
    setDetailParams,
    setManualTransferData,
    setSectionPermissions,
  } = useTransfersContext();
  const {
    summary: result,
    isSummaryLoading,
    isConfectionLoading,
    isConfectionError,
    isAsyncProcess,
    getSummary: getResult,
    handleConfection,
    handleCancelConfection,
    handleRetryConfection,
    handleAsyncModalOk,
  } = useTransferSetupStep3();

  const [showPendingModal, setShowPendingModal] = useState(false);
  const [postChangedTransfers, setPostChangedTransfers] = useState(false);
  const [asyncConfection, setAsyncConfection] = useState<AsyncConfectionResponseData | null>(null);
  const [asyncConfectionLoading, setAsyncConfectionLoading] = useState(false);
  const [asyncConfectionError, setAsyncConfectionError] = useState(false);
  const [conceptsOptions, setConceptsOptions] = useState<SelectOption[]>([]);
  const [documentTypesOptions, setDocumentTypesOptions] = useState<SelectOption[]>([]);
  const [retentionTypesOptions, setRetentionTypesOptions] = useState<SelectOption[]>([]);

  const processId = location?.state?.processId || '';

  const isResultLoading = asyncConfectionLoading || isSummaryLoading;
  const isResultError = processId && asyncConfectionError;

  const { refetch: fetchConcepts } = useQuery(['get-concepts-async'], () => getConcepts()?.then(res => res.data), {
    enabled: false,
    retry: 3,
    onSuccess: response => {
      if (response?.exception?.code === EXCEPTION_CODES.success) {
        const { totalThirdPartyConcepts } = response?.data || {};
        const options: SelectOption[] = totalThirdPartyConcepts?.map(conceptItem => ({
          value: conceptItem?.conceptCode,
          label: conceptItem?.description,
        }));
        setConceptsOptions(options);
      }
    },
  });

  const { refetch: fetchDocumentsTypes } = useQuery(['get-document-types-async'], () => getDocumentsToPay()?.then(res => res.data), {
    enabled: false,
    retry: 3,
    onSuccess: response => {
      if (response?.exception?.code === EXCEPTION_CODES.success) {
        const { documentTypeCancels } = response.data || {};
        const options: SelectOption[] = documentTypeCancels?.map(documentItem => ({
          value: String(documentItem?.code),
          label: documentItem?.description,
        }));
        setDocumentTypesOptions(options);
      }
    },
  });

  const { refetch: fetchRetentionTypes } = useQuery(
    ['get-retention-types-async'],
    () => getTypesRetention(RetentionState.ENABLED, AuthorizationState.V)?.then(res => res.data),
    {
      enabled: false,
      retry: 3,
      onSuccess: response => {
        if (response?.exception?.code === EXCEPTION_CODES.success) {
          const { retentionTypeView } = response.data || {};
          const options: SelectOption[] = retentionTypeView?.map(retentionItem => ({
            value: String(retentionItem?.code),
            label: retentionItem?.description,
          }));
          setRetentionTypesOptions(options);
        }
      },
    },
  );

  const { refetch: fetchPermissions } = useQuery(['get-permissions-operators'], () => getPermissions().then(res => res.data), {
    retry: 3,
    enabled: false,
    onSuccess: (responseData: Permissions) => {
      if (responseData?.exception?.code === EXCEPTION_CODES.success) {
        setSectionPermissions(responseData.data.sections);
      }
    },
  });

  const { refetch: fetchAsyncConfection } = useQuery(
    ['get-async-confection', processId],
    () => getAsyncConfection(processId).then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: response => {
        if (
          response?.exception?.code === EXCEPTION_CODES.success ||
          response?.exception?.code === EXCEPTION_CODES.partialRejection ||
          response?.exception?.code === EXCEPTION_CODES.totalRejection
        ) {
          setAsyncConfectionError(false);
          setAsyncConfection(response.data);
        } else {
          setAsyncConfectionLoading(false);
          setAsyncConfectionError(true);
        }
      },
      onError: () => {
        setAsyncConfectionLoading(false);
        setAsyncConfectionError(true);
      },
    },
  );

  const transfersPermissions = [ServiceAction.AUT, ServiceAction.ENV, ServiceAction.ANU, ServiceAction.DES];
  const transfersPermissionsChecks = transfersPermissions.map(permission =>
    sectionPermissions.some(item => item?.section?.includes(permission)),
  );
  const [hasPermissionsAuthorize, hasPermissionsSend, hasPermissionsAnull, hasPermissionsUnauthorize] = transfersPermissionsChecks;

  const canGoToTransfers = hasPermissionsAuthorize || hasPermissionsSend || hasPermissionsAnull || hasPermissionsUnauthorize;

  const transferType = asyncConfection ? TRANSFER_TYPE[asyncConfection.typeTefs] : TRANSFER_TYPE[manualTransferData.transferType];

  const acceptedTransfers = asyncConfection
    ? mapAsyncTransfers(asyncConfection.acceptedTransfers.transfer)
    : manualTransferData.transfers.filter(item => {
        const exist = confectionData.acceptedTransfers.find(acceptedItem => acceptedItem.idTefConfection === item.transferNumber);
        return exist;
      });
  const acceptedTransfersTotal = asyncConfection ? asyncConfection?.acceptedTransfers?.totalTransfer : acceptedTransfers.length;
  const hasAcceptedTransfers = !!acceptedTransfersTotal;
  const acceptedTransfersLotNumber = asyncConfection
    ? asyncConfection?.acceptedTransfers?.lotNumber
    : acceptedTransfersTotal
    ? confectionData.acceptedTransfers[0].lotNumber
    : 0;

  const rejectedTransfers: LotTransferRejected[] = [];
  confectionData.rejectedTransfers.forEach(rejectedItem => {
    const error = CONFECTION_ERRORS.find(e => e.errors?.includes(rejectedItem.message.split('|')[0])) || GENERIC_ERROR;
    const transfer = manualTransferData.transfers.find(item => rejectedItem.idTefConfection === item.transferNumber);
    if (transfer) {
      rejectedTransfers.push({ ...transfer, error });
    }
  });
  rejectedTransfers.sort((a, b) => {
    const dateA = new Date(a.transferData.date).getTime();
    const dateB = new Date(b.transferData.date).getTime();
    const bankNameA = a.debitAccount?.bank.bankName ?? '';
    const bankNameB = b.debitAccount?.bank.bankName ?? '';
    return dateA - dateB || bankNameA.localeCompare(bankNameB);
  });

  let rejectedTransfersTotal = 0;

  const rejectedTransfersGroups: ResultRejectedItem[] = (
    asyncConfection ? asyncConfection.rejectedTransfersDetail : confectionData.rejectedTransferDetails
  ).map(item => {
    rejectedTransfersTotal += item.totalTransferByMessage;
    const error = CONFECTION_ERRORS.find(e => e.code === item.message) || GENERIC_ERROR;
    return { error, count: item.totalTransferByMessage };
  });

  const hasRejectedTransfers = !!rejectedTransfersTotal;

  const validatedTransfers = asyncConfection
    ? mapValidatedAsyncTransfers(asyncConfection.validateTransfers.transfer, conceptsOptions, documentTypesOptions, retentionTypesOptions)
    : manualTransferData.transfers.filter(transferItem => {
        const exist = confectionData.validateTransfers.find(item => item.idTefConfection === transferItem.transferNumber);
        return exist;
      });
  const validatedTransfersTotal = asyncConfection ? asyncConfection?.validateTransfers?.totalTransfer : validatedTransfers.length;
  const hasValidatedTransfers = !!validatedTransfersTotal;

  const { transfersGroups, totals: customSummary } = result;

  const handleFetchPermissions = () => {
    fetchPermissions();
  };

  const handleRetryDownload = (status: ResultStatus, format: DOWNLOAD_FORMAT) => {
    handleDownload(status, format);
  };

  const handleDownload = async (status: ResultStatus, format: DOWNLOAD_FORMAT) => {
    toastHook.info(downloadInfoModal.id, downloadInfoModal.title);
    let content = '';
    let fileName = status === ResultStatus.accepted ? texts.acceptedFileName : texts.rejectedFileName;
    if (status === ResultStatus.validated) {
      fileName = texts.validatedFileName;
    }

    let isDownloadSuccess = false;

    try {
      if (asyncConfection) {
        const fileContent = await getDownloadFileTransfersAsync(status, format, String(asyncConfection?.processId)).then(
          response => response.data,
        );
        isDownloadSuccess = downloadTransfersFile(fileContent || '', format, fileName);
      } else {
        if (status === ResultStatus.accepted || status === ResultStatus.validated) {
          const transfers: LotTransfer[] = [];
          (hasValidatedTransfers ? validatedTransfers : acceptedTransfers).forEach(item => {
            const transfer = (hasValidatedTransfers ? confectionData.validateTransfers : confectionData.acceptedTransfers).find(
              transferItem => transferItem.idTefConfection === item.transferNumber,
            );
            if (transfer) {
              transfers.push({
                ...item,
                transferNumber: transfer.orderId,
              });
            }
          });

          if (hasValidatedTransfers) {
            transfers.sort((a, b) => {
              const dateA = new Date(a.transferData.date).getTime();
              const dateB = new Date(b.transferData.date).getTime();
              const bankNameA = a.debitAccount?.bank.bankName ?? '';
              const bankNameB = b.debitAccount?.bank.bankName ?? '';
              return dateA - dateB || bankNameA.localeCompare(bankNameB);
            });
          } else {
            transfers.sort((a, b) => a.transferNumber - b.transferNumber);
          }

          content = generateTransfContent(
            !hasValidatedTransfers ? acceptedTransfersLotNumber : undefined,
            manualTransferData?.transferType,
            transfers,
            format === DOWNLOAD_FORMAT.csv,
          );
        } else {
          content = generateRejectedTransfContent(manualTransferData?.transferType, rejectedTransfers, format === DOWNLOAD_FORMAT.csv);
        }

        isDownloadSuccess = downloadTransfersFile(content, format, fileName);
      }

      if (isDownloadSuccess) {
        toastHook.success(downloadSuccessfulModal.id, downloadSuccessfulModal.title);
      }
    } catch {
      toastHook.error(downloadFailedModal.id, downloadFailedModal.title, downloadFailedModal.textButon, () =>
        handleRetryDownload(status, format),
      );
    }
  };

  const handleGoToDetail = (data: TransfersGroup) => {
    setDetailParams({
      lot: data?.lotNumber?.toString() || '',
      date: data?.date,
      bank: data?.transfers[0].debitAccount?.accountId || '',
      status: 'CON',
      amount: data?.totalAmount,
      origin: TransfersFlow.Confection,
      confection: true,
    });
    navigate(routesNames.LotDetailsModify, { state: { from: location, processId } });
  };

  const handleGoToConfection = () => {
    clearConfection();
    navigate(routesNames.TransferSetup, { state: { from: location } });
  };

  const handleGoToTransfers = () => {
    clearConfection();
    navigate(routesNames.Root, { state: { from: location } });
  };

  const handlePreparePending = () => {
    setManualTransferData({
      ...manualTransferData,
      ...(asyncConfection
        ? {
            transferType: asyncConfection.typeTefs,
            requestDate: asyncConfection.validateTransfers.transfer[0].dateRequest,
            description: asyncConfection.description,
            paymentNumber: asyncConfection.payNumber,
            unifiedShipping: asyncConfection.unifiedRelease,
            consolidated: asyncConfection.consolidatedExtract,
          }
        : {}),
      transfers: validatedTransfers,
    });
    setPostChangedTransfers(true);
  };

  const handleConfirmPendingModal = (confirm = false) => {
    setShowPendingModal(false);
    if (confirm) {
      handlePreparePending();
    }
  };

  const getAsyncProcess = () => {
    setAsyncConfectionError(false);
    setAsyncConfectionLoading(true);
    fetchAsyncConfection();
  };

  const handleRetryAsyncProcess = () => {
    getAsyncProcess();
  };

  const handleAsyncModal = () => {
    setAsyncConfection(null);
    setAsyncConfectionLoading(false);
    setAsyncConfectionError(false);
    handleAsyncModalOk();
  };

  const init = () => {
    if (processId) {
      handleFetchPermissions();
      fetchConcepts();
      fetchDocumentsTypes();
      fetchRetentionTypes();
      getAsyncProcess();
    } else if (hasValidatedTransfers || hasAcceptedTransfers || hasRejectedTransfers) {
      handleFetchPermissions();
      getResult(hasValidatedTransfers ? validatedTransfers : acceptedTransfers);
    } else {
      handleGoToConfection();
    }
  };

  let primaryButtonText = canGoToTransfers ? texts.goToTransfers : texts.goToConfection;
  let primaryButtonAction = canGoToTransfers ? handleGoToTransfers : handleGoToConfection;
  let secondaryButtonText = canGoToTransfers ? texts.goToConfection : undefined;
  const secondaryButtonAction = hasValidatedTransfers || canGoToTransfers ? handleGoToConfection : undefined;

  if (hasValidatedTransfers) {
    primaryButtonText = texts.preparePending;
    primaryButtonAction = () => setShowPendingModal(true);
    secondaryButtonText = texts.cancel;
  }

  useEffect(() => {
    if (asyncConfection && confectionData?.operationId && !confectionData?.processId) {
      setAsyncConfection(null);
      setAsyncConfectionLoading(false);
      setAsyncConfectionError(false);
    }
  }, [asyncConfection, confectionData?.operationId, confectionData?.processId]);

  useEffect(() => {
    if (postChangedTransfers) {
      handleConfection();
      setPostChangedTransfers(false);
    }
  }, [postChangedTransfers]);

  useEffect(() => {
    getResult(hasValidatedTransfers ? validatedTransfers : acceptedTransfers, true);
  }, [confectionData.acceptedTransfers, confectionData.rejectedTransfers, confectionData.validateTransfers]);

  useEffect(() => {
    if (asyncConfection) {
      getResult(hasValidatedTransfers ? validatedTransfers : acceptedTransfers, true, asyncConfection?.acceptedTransfers?.lotNumber);
      setTimeout(() => {
        setAsyncConfectionLoading(false);
      }, 300);
    }
  }, [asyncConfection]);

  useEffect(() => {
    if (processId) {
      init();
    }
  }, [processId]);

  useEffect(() => {
    init();
  }, []);

  return {
    isResultLoading,
    isResultError,
    isConfectionLoading,
    isConfectionError,
    transferType,
    transfersGroups,
    acceptedTransfersTotal,
    acceptedTransfersLotNumber,
    rejectedTransfersTotal,
    rejectedTransfersGroups,
    validatedTransfersTotal,
    hasAcceptedTransfers,
    hasRejectedTransfers,
    hasValidatedTransfers,
    customSummary,
    primaryButtonText,
    secondaryButtonText,
    showPendingModal,
    isAsyncProcess,
    handleFetchPermissions,
    handleGoToDetail,
    handleDownload,
    primaryButtonAction,
    secondaryButtonAction,
    handleCancelConfection,
    handleRetryConfection,
    handleConfirmPendingModal,
    handleRetryAsyncProcess,
    handleAsyncModal,
  };
}
