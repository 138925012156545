import { Box, Skeleton } from '@chakra-ui/react';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { TablePaginationComponent } from '@wow/table-pagination';
import { pushAnalyticsEvent } from '@wow/utils';

import CustomTable from '../../components/CustomTable';
import FilterTable from '../../components/FilterTable';
import IconBeware from '../../components/Icons/icon-beware';
import IconError from '../../components/Icons/icon-cancel-modal';
import IconSuccess from '../../components/Icons/icon-checkmark';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import IconInfoPopup from '../../components/Icons/icon-info-popup';
import CustomModal from '../../components/Modal';
import ViewNoResult from '../../components/ViewNoResult';
import { DataMessageErrorRequest, LOADING_SCREEN_TEXT, OPTION_SELECT_PAGE, PAGE_ACTIONS } from '../../constants-app';
import useAnnulTransfers from '../../hooks/useAnnulTransfers';
import { Lot, ServiceAction } from '../../Interfaces/transfers';
import { ANNUL_TRANSFERS } from '../../utils/configTableFunctions';
import { TEXT_MODAL_ASYNC_ERROR, TEXT_MODAL_SEND_ASYNC_IS_DONE } from '../EntityKey/constants';
import { COLUMNS } from './configTable';
import {
  ANNUL_ASYNC_MODAL_DONE,
  ANNUL_ASYNC_MODAL_IN_PROGRESS_TEXT,
  ASYNC_MODAL_ANNUL_ERROR_TEXT,
  ASYNC_MODAL_IN_PROGRESS_TEXT,
  BUTTON_TEXT,
  CONFIRMATION_MODAL_TEXT,
  ERROR_MODAL_TEXTS,
  SUCCESS_MODAL_TEXTS,
  TEXT_TITLE_ANULL_LOADING,
  ZERO_ANULL_MODAL_TEXTS,
} from './constants';
import { CustomTd, CustomTh, Paginator } from './index.styled';

function AnnulTransfers(): JSX.Element {
  const {
    isFetching,
    isError,
    lots,
    sizeContext,
    tableDataState,
    pagination,
    pageContext,
    showAnnulConfirmationModal,
    isLoadingAnull,
    showErrorModal,
    showZeroModal,
    isAnnullingLots,
    showSuccessModal,
    isAsyncProcess,
    isAsyncProcessDone,
    isFetchingAsyncProcess,
    isAsyncProcessError,
    servicesWorking,
    lotNumber,
    firstTime,
    secondTime,
    setAnnulConfirmationModal,
    onFilterLot,
    handleToggleAnalytics,
    setSizeContext,
    setPageContext,
    setShowSuccessModal,
    onCloseModalZeroAnull,
    onClickAnull,
    onClickRetry,
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    onCloseModalError,
    setIsAsyncProcessError,
    onCloseModalAsyncProcessStatusIsDoneGoToTransfers,
    onCloseModalProcessedAsynchronouslyGoToTransfers,
    handlePageNavigation,
  } = useAnnulTransfers();

  const onClickButtonAnull = () => {
    pushAnalyticsEvent({
      event: 'anular_transferencias',
      content_type: 'Botones Transferencias',
      item_id: 'Anular',
    });
    pushAnalyticsEvent({
      event: 'modal_impression',
      modal_name: 'Confirmación de anulación de TEFs',
    });
    setAnnulConfirmationModal(true);
  };

  const onCancel = () => {
    pushAnalyticsEvent({
      event: 'click_modal',
      modal_name: 'Confirmación de anulación de TEFs',
      item_id: 'Cancelar',
    });
    setAnnulConfirmationModal(false);
  };

  const onCloseModalSuccess = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal TEFs anuladas',
      item_id: 'Entendido',
    });
    setShowSuccessModal(false);
  };

  if (isError || !servicesWorking) {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Transferencias - Anular - Servicio no disponible',
    });
    return (
      <ViewNoResult
        title={DataMessageErrorRequest.title}
        description={DataMessageErrorRequest.description}
        linkName={DataMessageErrorRequest.linkName}
        Icon={IconDisconnected}
        handleToggleClick={handleToggleAnalytics}
      />
    );
  }
  return (
    <>
      {isAnnullingLots || isFetchingAsyncProcess ? (
        <LoadingScreen
          title={isLoadingAnull ? TEXT_TITLE_ANULL_LOADING : LOADING_SCREEN_TEXT.title}
          description={LOADING_SCREEN_TEXT.description}
        />
      ) : null}
      {firstTime ? (
        <FilterTable onFilterLot={onFilterLot} isFetching={isFetching} serviceAction={ServiceAction.ANU} lotNumber={lotNumber} />
      ) : (
        <Skeleton width='100%' height='64px' borderRadius='4px' margin='0 0 12px 0' data-testid='skeleton-component' />
      )}
      <CustomTable
        showButton
        CustomTd={CustomTd}
        CustomTh={CustomTh}
        data={lots as Lot[]}
        columns={COLUMNS as []}
        size={sizeContext}
        selectedTable={ANNUL_TRANSFERS}
        handleClickButtom={onClickButtonAnull}
        onFilterLot={onFilterLot}
        tableDataState={tableDataState}
        text={BUTTON_TEXT}
        id='table'
        isFetching={isFetching}
        firstStatusSkeleton={firstTime}
        secondStatusSkeleton={secondTime}
      />
      {firstTime && secondTime ? (
        <TablePaginationComponent
          totalPages={pagination?.totalPages || 1}
          currentIndex={pagination.pageNumber && pagination.pageNumber - 1}
          totalRecords={pagination?.totalElements}
          perPage={OPTION_SELECT_PAGE}
          disabledPerPage={pagination?.totalElements <= 15}
          currentPerPage={pagination?.totalElementsRequested}
          onNext={() => handlePageNavigation(pageContext + 1, PAGE_ACTIONS.NEXT)}
          onPrev={() => handlePageNavigation(pageContext - 1, PAGE_ACTIONS.PREV)}
          onFirst={() => handlePageNavigation(1, PAGE_ACTIONS.FIRST)}
          onLast={() => handlePageNavigation(pagination.totalPages, PAGE_ACTIONS.LAST)}
          onJump={(value: number) => {
            if (Number(value) < pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.PREV);
            } else if (Number(value) > pageContext) {
              handlePageNavigation(Number(value), PAGE_ACTIONS.NEXT);
            }
          }}
          onPerPageChange={(value: number) => {
            setSizeContext(Number(value));
            setPageContext(1);
          }}
        />
      ) : (
        <Box sx={Paginator}>
          <Skeleton width='100%' height='56px' borderRadius='4px' marginTop='6px' data-testid='skeleton-component' />
        </Box>
      )}
      <CustomModal
        isOpen={showAnnulConfirmationModal}
        onCancel={() => setAnnulConfirmationModal(false)}
        Icon={IconInfoPopup}
        altIcon={CONFIRMATION_MODAL_TEXT.altIcon}
        title={CONFIRMATION_MODAL_TEXT.title}
        firstDescription={CONFIRMATION_MODAL_TEXT.description}
        secondDescription=''
        firstTextButton={CONFIRMATION_MODAL_TEXT.firstTextButton}
        secondTextButton={CONFIRMATION_MODAL_TEXT.secondTextButton}
        enableFirstButton
        enableSecondButton
        actionButton={onCancel}
        actionButton2={onClickAnull}
        size='xl'
        alternativeButtonStyling
        id='modalConfirmationAnull'
      />
      <CustomModal
        isOpen={showErrorModal}
        onCancel={onCloseModalError}
        Icon={IconError}
        title={ERROR_MODAL_TEXTS.title}
        firstDescription={ERROR_MODAL_TEXTS.description}
        firstTextButton={ERROR_MODAL_TEXTS.cancel}
        secondTextButton={ERROR_MODAL_TEXTS.retry}
        actionButton2={onClickRetry}
        size='xl'
        enableFirstButton
        enableSecondButton
        alternativeButtonStyling
        cancelType
        id='modalErrorAnull'
      />
      <CustomModal
        isOpen={showSuccessModal}
        onCancel={onCloseModalSuccess}
        Icon={IconSuccess}
        title={SUCCESS_MODAL_TEXTS.title}
        firstDescription={SUCCESS_MODAL_TEXTS.description}
        firstTextButton={SUCCESS_MODAL_TEXTS.ok}
        actionButton={onCloseModalSuccess}
        enableFirstButton
        size='xl'
        cancelType
        id='modalSuccessAnull'
      />
      <CustomModal
        isOpen={showZeroModal}
        onCancel={onCloseModalZeroAnull}
        Icon={IconError}
        title={ZERO_ANULL_MODAL_TEXTS.title}
        firstDescription={ZERO_ANULL_MODAL_TEXTS.description}
        firstTextButton={ZERO_ANULL_MODAL_TEXTS.textButton}
        actionButton={onCloseModalZeroAnull}
        size='xl'
        enableFirstButton
        cancelType
        id='modalZeroErrorAnull'
      />
      <CustomModal
        isOpen={isAsyncProcessDone}
        enableFirstButton
        actionButton={onCloseModalAsyncProcessStatusIsDoneGoToTransfers}
        firstTextButton={ANNUL_ASYNC_MODAL_IN_PROGRESS_TEXT.buttonDone}
        firstDescriptionWithBold={ANNUL_ASYNC_MODAL_DONE}
        Icon={IconInfoPopup}
        title={ANNUL_ASYNC_MODAL_IN_PROGRESS_TEXT.titleDone}
        size='xl'
        onCancel={setIsAsyncProcessDone}
      />
      <CustomModal
        isOpen={isAsyncProcess}
        enableFirstButton
        actionButton={onCloseModalProcessedAsynchronouslyGoToTransfers}
        firstTextButton={ANNUL_ASYNC_MODAL_IN_PROGRESS_TEXT.textButton}
        firstDescriptionWithBold={ASYNC_MODAL_IN_PROGRESS_TEXT}
        iconSpinner
        title={ANNUL_ASYNC_MODAL_IN_PROGRESS_TEXT.title}
        size='xl'
        onCancel={setIsAsyncProcess}
      />
      <CustomModal
        isOpen={isAsyncProcessError}
        Icon={IconBeware}
        onCancel={setIsAsyncProcessError}
        altIcon={TEXT_MODAL_SEND_ASYNC_IS_DONE.altIcon}
        firstDescriptionWithBold={ASYNC_MODAL_ANNUL_ERROR_TEXT}
        title={TEXT_MODAL_ASYNC_ERROR.title}
        firstTextButton={TEXT_MODAL_SEND_ASYNC_IS_DONE.firstTextButton}
        enableFirstButton
        actionButton={() => setIsAsyncProcessError(false)}
        size='xl'
        cancelType
      />
    </>
  );
}

export default AnnulTransfers;
