import { TRANSFER_TYPE } from '@views/TransferSetup/Steps/Step2/constants';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { EXCEPTION_CODES, routesNames, TRANSFER_TYPE_CODE } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { BankAccount, ConfectionRequestData, ConfectionTransfer } from '../Interfaces/api';
import { LotTransfer, ResultErrorCode } from '../Interfaces/transferSetup';
import { postConfection } from '../services/api';
import { getConfectionService } from '../views/TransferSetup/Steps/Step3/helpers';
import { Summary } from '../views/TransferSetup/Steps/Step3/interfaces';

export function useTransferSetupStep3() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    manualTransferData,
    confectionData,
    setConfectionData,
    clearConfection,
    setConfectionReset,
    setUpdateAsyncProcesses,
    setManualTransferData,
  } = useTransfersContext();
  const { transferType, description, paymentNumber } = manualTransferData;
  const transferTypeText = TRANSFER_TYPE[transferType];

  const summaryInitial: Summary = {
    totals: {
      items: 0,
      ARS: { items: 0, amount: 0 },
      USD: { items: 0, amount: 0 },
    },
    transfersGroups: [],
  };

  const [summary, setSummary] = useState(summaryInitial);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [isConfectionLoading, setIsConfectionLoading] = useState(false);
  const [isConfectionError, setIsConfectionError] = useState(false);
  const [currentOperationId, setCurrentOperationId] = useState(1);
  const [isAsyncProcess, setIsAsyncProcess] = useState(false);

  const hasDescription = !!description;
  const hasPaymentNumber = !!paymentNumber;

  const service = getConfectionService(transferType);

  const { mutate: mutateConfection, reset: resetMutationConfection } = useMutation(
    (data: ConfectionRequestData) => postConfection(service, data)?.then(res => res.data),
    {
      onSuccess: responseData => {
        const exceptionCode = responseData?.exception?.code;
        if (exceptionCode === EXCEPTION_CODES.asyncProcess) {
          setUpdateAsyncProcesses(true);
          setConfectionData(responseData.data);
          setIsAsyncProcess(true);
        } else if (
          exceptionCode === EXCEPTION_CODES.success ||
          exceptionCode === EXCEPTION_CODES.partialRejection ||
          exceptionCode === EXCEPTION_CODES.totalRejection
        ) {
          setConfectionData(responseData.data);

          const nonEnabledSchedule = responseData.data.rejectedTransferDetails.findIndex(item =>
            item.message.includes(ResultErrorCode.TEF_NON_ENABLED_SCHEDULE),
          );

          if (nonEnabledSchedule > -1) {
            setManualTransferData({ ...manualTransferData, step: 4 });
          } else {
            navigate(routesNames.ConfectionResult, { state: { from: location } });
          }
        } else {
          setIsConfectionError(true);
        }
        setIsConfectionLoading(false);
        setCurrentOperationId(value => value + 1);
      },
      onError: () => {
        setIsConfectionError(true);
        setIsConfectionLoading(false);
      },
    },
  );

  const getSummary = (transfers?: LotTransfer[], showLoading?: boolean, lotNumber?: number) => {
    if (showLoading) {
      setIsSummaryLoading(true);
    }
    const data = cloneDeep(summaryInitial);

    (transfers || manualTransferData.transfers).forEach(transfer => {
      const debitAccount = transfer.debitAccount as BankAccount;
      const { currency } = debitAccount;
      const { date, amount } = transfer.transferData;

      const index = data.transfersGroups.findIndex(
        group => group.debitAccount?.cbu === debitAccount.cbu && group.date === transfer.transferData.date,
      );

      data.totals.items += transfer.quantityTef || 1;
      data.totals[currency].items += transfer.quantityTef || 1;
      data.totals[currency].amount += amount;

      const accepted = confectionData.acceptedTransfers.find(item => item.idTefConfection === transfer.transferNumber);
      if (index > -1 && data.transfersGroups[index]) {
        data.transfersGroups[index].transfers.push({
          ...transfer,
          ...(accepted ? { transferNumber: accepted.orderId } : {}),
        });
        if (!transfer.quantityTef) {
          data.transfersGroups[index].totalItems += 1;
        }
        data.transfersGroups[index].totalAmount += amount;
      } else {
        data.transfersGroups.push({
          transferType,
          debitAccount,
          date,
          currency,
          totalItems: transfer.quantityTef || 1,
          totalAmount: amount,
          lotNumber: accepted?.lotNumber || lotNumber,
          transfers: [
            {
              ...transfer,
              ...(accepted ? { transferNumber: accepted.orderId } : {}),
            },
          ],
        });
      }

      data.transfersGroups.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        const bankNameA = a.debitAccount?.bank?.bankName;
        const bankNameB = b.debitAccount?.bank?.bankName;
        if (bankNameA && bankNameB) {
          return dateA - dateB || bankNameA.localeCompare(bankNameB);
        }
        return dateA - dateB;
      });
    });

    setSummary(data);
    setTimeout(() => {
      setIsSummaryLoading(false);
    }, 500);
  };

  const handleConfection = () => {
    setIsConfectionLoading(true);
    setIsConfectionError(false);
    resetMutationConfection();

    const requestData: ConfectionRequestData = {
      operationId: currentOperationId,
      unifiedliberation: manualTransferData.unifiedShipping,
      payNumber: manualTransferData.paymentNumber || '',
      consolidated: manualTransferData.consolidated,
      description: manualTransferData.description || '',
      resendValidated: true,
      tefs: manualTransferData.transfers.map(item => {
        let transfer: ConfectionTransfer = {
          idTefConfeccion: item.transferNumber,
          requestDate: item.transferData.date,
          accountDebitCbuAlias: String(item.debitAccount?.cbu),
          accountCreditCbuAlias: String(item.creditAccount?.cbu),
          observation: item.transferData.observation,
          amount: item.transferData.amount,
        };
        if (transferType === TRANSFER_TYPE_CODE.TER) {
          transfer = {
            ...transfer,
            conception: item.transferData.concept?.value || '',
            disclaimerAccepted: item.transferData.isDisclaimerChecked,
            voucher: item.transferData.voucher,
            cause: item.transferData.reason,
          };
        }
        if (transferType === TRANSFER_TYPE_CODE.PRO) {
          transfer = {
            ...transfer,
            clientCode: item.additionalInformation?.numberClient,
            amountCreditNote: item.additionalInformation?.creditNoteAmount,
            documentNumber: item.additionalInformation?.documentNumber,
            creditNoteNumber: item.additionalInformation?.creditNoteNumber,
            payOrderNumber: item.additionalInformation?.paymentOrderNumber,
            documentType: item.additionalInformation?.document?.value,
            orderPayType: item.additionalInformation?.paymentOrderType,
            retentionType: item.additionalInformation?.retentionType?.value,
            totalRetention: item.additionalInformation?.totalRetentionAmount,
          };
        }
        return transfer;
      }),
    };

    mutateConfection(requestData);
  };

  const handleCancelConfection = () => {
    setIsConfectionError(false);
  };

  const handleRetryConfection = () => {
    setIsConfectionError(false);
    setCurrentOperationId(value => value + 1);
    handleConfection();
  };

  const handleAsyncModalOk = () => {
    setIsAsyncProcess(false);
    if (location.pathname === routesNames.TransferSetup) {
      setConfectionReset(true);
    } else {
      clearConfection();
      navigate(routesNames.TransferSetup);
    }
  };

  useEffect(() => {
    if (location?.pathname === routesNames.ConfectionSummary && !transferType) {
      clearConfection();
      navigate(routesNames.TransferSetup);
    }
  }, []);

  return {
    manualTransferData,
    transferTypeText,
    hasDescription,
    hasPaymentNumber,
    summary,
    isSummaryLoading,
    confectionData,
    isConfectionLoading,
    isConfectionError,
    isAsyncProcess,
    getSummary,
    handleConfection,
    handleCancelConfection,
    handleRetryConfection,
    handleAsyncModalOk,
  };
}
