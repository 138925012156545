import { TRANSFER_TYPE_CODE } from '../../constants-app';

const TOOLTIP_UNIFIED_SHIPPING = 'Los lotes con envío unificado solo se pueden\nseleccionar desde la sección "Enviar"';

const TRANSFER_TYPE_AFI = 'AFI';
const TRANSFER_TYPE_B2B = 'INT';
const TRANSFER_TYPE_PDS = 'PDS';
const UNIFIED_SHIPPING_MARK = 'Y';

const CREDIT_ACCOUNT_TOOLTIP_MAX_WIDTH = 400;
const DENOMINATION_TOOLTIP_MAX_WIDTH = 200;

const TRANSFER_TYPE = {
  [TRANSFER_TYPE_CODE.SUE]: 'Sueldos',
  [TRANSFER_TYPE_CODE.INT]: 'BtoB',
  [TRANSFER_TYPE_CODE.CUE]: 'Cuentas Propias',
  [TRANSFER_TYPE_CODE.PRO]: 'Proveedores',
  [TRANSFER_TYPE_CODE.AFI]: 'AFIP',
  [TRANSFER_TYPE_CODE.PPR]: 'Depositos Judiciales',
  [TRANSFER_TYPE_CODE.PDS]: 'Interpagos',
  [TRANSFER_TYPE_CODE.TER]: 'Pagos Terceros',
  [TRANSFER_TYPE_CODE.FAC]: 'Facturación',
  [TRANSFER_TYPE_CODE.TPJ]: 'Pagos Judiciales',
};

const LOADING_SCREEN_TEXT = {
  title: 'Estamos procesando tu información',
  description: 'Esto puede demorar unos instantes.',
};

const TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION = {
  title: 'Estás por deshacer\nla selección total del lote',
  firstDescription: 'Borraremos la selección total y tendrás que volver a seleccionar las\ntransferencias.',
  secondDescription: 'Si no la conocés, contactá a tu administrador.',
  firstTextButton: 'Cancelar',
  secondTextButton: 'Continuar',
  altIcon: 'icon-beware-modal',
};

export {
  CREDIT_ACCOUNT_TOOLTIP_MAX_WIDTH,
  DENOMINATION_TOOLTIP_MAX_WIDTH,
  LOADING_SCREEN_TEXT,
  TEXT_MODAL_IS_EDITING_WITH_FULLSELECTION,
  TOOLTIP_UNIFIED_SHIPPING,
  TRANSFER_TYPE,
  TRANSFER_TYPE_AFI,
  TRANSFER_TYPE_B2B,
  TRANSFER_TYPE_PDS,
  UNIFIED_SHIPPING_MARK,
};

export const WizardDetail = {
  titleLotDetail: 'Información general del lote',
  descLotDetail: 'En esta sección encontrarás los datos del lote.',
  titleLotList: 'Transferencias por lote',
  descLotList: 'Visualizá las transferencias que forman parte del lote.',
  titleTefDetail: 'Detalle de transferencia',
  descTefDetail: 'Si hacés clic en la lupa de la derecha, encontrarás información ampliada de cada transferencia.',
};

export const EXCEPTION_CODES = {
  timeOut: 'TEF-0015',
};
