/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { ColumnDef, getCoreRowModel, getPaginationRowModel, Row, SortingState, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { useTransfersContext } from '../context/TransfersContext';
import { DataLot, LotSelected, LotSelectedTransfer } from '../Interfaces/contextTypes';
import { ITableModify } from '../Interfaces/ITableModify';
import { File, Lot, LotDetail } from '../Interfaces/transfers';
import { PayloadData } from '../Interfaces/utils/configTableFunctions';
import { arrayToObject, getRowIdLotDetail, getRowIdPendingTransfers, LOT_DETAIL } from '../utils/configTableFunctions';

interface Props {
  selectedTable: string;
  pendingTableId: string | undefined;
  totalTransfer: number | undefined;
  totalAmount: number | undefined;
  size: number;
  data: Lot[] | LotDetail[] | File[] | ITableModify[];
  columns: ColumnDef<Lot | LotDetail | File>[];
  handleToggleDrawer?: (e: number) => void;
  handleToggleDrawerWithDetails?: (transferType: string, lotNumber: number, codeTransferType: string, currency: string) => void;
}

function useTableLogic({
  selectedTable,
  pendingTableId,
  totalTransfer,
  totalAmount,
  size,
  data,
  columns,
  handleToggleDrawer,
  handleToggleDrawerWithDetails,
}: Props) {
  const {
    dataLot,
    lotsSelected,
    detailLotId,
    lotSelectionHandler,
    isFullSelection,
    setDataLot,
    setLotsSelected,
    setLotSelectionHandler,
    orderAppliedContext,
    setOrderAppliedContext,
    setOrderPropertyContext,
    setOrderPropertyLotDetailContext,
  } = useTransfersContext();

  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>(orderAppliedContext);
  const [notFirstSelection, setNotFirstSelection] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isTableReady, setIsTableReady] = useState(false);
  const getRowId = selectedTable === LOT_DETAIL ? getRowIdLotDetail : getRowIdPendingTransfers;

  const table = useReactTable({
    data,
    columns,
    meta: {
      handleToggleDrawer,
      handleToggleDrawerWithDetails,
    },
    getRowId,
    state: {
      rowSelection,
      sorting,
    },
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleData = (payloads: PayloadData[]) => {
    const updatedData = [...dataLot];
    const updatedLotsSelected = [...lotsSelected];
    payloads.forEach(payload => {
      const parentRowSelected = updatedLotsSelected.find(item => item.id === payload.pendingTableId);
      const isRowSelected = payload.rowSelection.length > 0;
      if (parentRowSelected) {
        const newTranfers: LotSelectedTransfer[] = [...(parentRowSelected.transfers || []), ...payload.rowSelection];
        const transfersWithoutDuplicates = newTranfers.filter(
          (obj, index, self) => index === self.findIndex(t => t.transferId === obj.transferId),
        );
        const transfers = transfersWithoutDuplicates.filter(
          item1 => payload.removeElements && !payload.removeElements.some(item2 => item1.transferId === item2.original.transferNumber),
        );
        parentRowSelected.transfers = transfers;
      } else if (isRowSelected) {
        updatedLotsSelected.push({
          id: payload.pendingTableId,
          transfers: payload.rowSelection,
          quantity: payload.totalTransfer,
          isPartialSelection: true,
        });
      }
      const itemIndex = updatedData.findIndex(item => item.pendingTableId === payload.pendingTableId);
      if (itemIndex === -1) {
        if (payload.rowSelection.length > 0) {
          const newData: DataLot = { ...payload, lotsSelected: [] };
          updatedData.push(newData);
        }
      } else if (payload.rowSelection.length > 0) {
        updatedData[itemIndex] = { ...payload, lotsSelected: updatedData[itemIndex].lotsSelected };
      } else {
        updatedData.splice(itemIndex, 1);
        const lotIndex = updatedLotsSelected.findIndex((lot: LotSelected) => lot.id === payload.pendingTableId);
        const selectedLot = updatedLotsSelected.find((lot: LotSelected) => lot.id === payload.pendingTableId);
        if (selectedLot?.transfers?.length === 0) {
          updatedLotsSelected.splice(lotIndex, 1);
        }
      }
    });
    setDataLot(updatedData);
    setLotsSelected(updatedLotsSelected);
  };

  useEffect(() => {
    if (table.getRowModel().flatRows.length > 0 && table.getIsSomeRowsSelected() !== undefined) {
      setIsTableReady(true);
    } else {
      setIsTableReady(false);
    }
  }, [table.getRowModel().flatRows, table.getSelectedRowModel().flatRows]);

  useEffect(() => {
    table.setPageSize(size);
    if (selectedTable !== LOT_DETAIL) {
      table.setRowSelection(lotSelectionHandler);
    }
  }, [data]);

  useEffect(() => {
    if (Object.values(rowSelection).length) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (notFirstSelection && isTableReady) {
      if (selectedTable === LOT_DETAIL) {
        const rows = table.getRowModel().flatRows as Row<LotDetail>[];
        const selectedRows = table.getSelectedRowModel().flatRows as Row<LotDetail>[];
        const unselectedRows = rows.filter(item1 => !selectedRows.some(item2 => item1.id === item2.id));
        const rowSelectionData: LotSelectedTransfer[] = selectedRows.map(row => ({
          amount: row.original.amount,
          currency: row.original.currency,
          transferId: row.original.transferNumber,
          unifiedShippingMark: row.original.unifiedShippingMark,
        }));
        handleData([
          {
            pendingTableId: pendingTableId || '',
            totalAmount: totalAmount || 0,
            totalTransfer: totalTransfer || 0,
            rowSelection: rowSelectionData,
            removeElements: unselectedRows,
          },
        ]);
      } else {
        const rows = table.getRowModel().flatRows as Row<Lot>[];
        const selectedRows = table.getSelectedRowModel().flatRows as Row<Lot>[];
        const unselectedRows = rows.filter(item1 => !selectedRows.some(item2 => item1.original.lotId === item2.original.lotId));
        const selectionToAdd = selectedRows
          .map(row => ({
            id: row.original.lotId,
            quantity: row.original.transferNumbers,
            unifiedShipping: row.original.unifiedShipping,
            transfers: [],
            lotNumber: row.original.lotNumber,
          }))
          .filter(item1 => !lotsSelected.some(item2 => item1.id === item2.id));
        const newLotsSelected = [...lotsSelected, ...selectionToAdd].filter(
          item1 => !unselectedRows.some(item2 => item1.id === item2.original.lotId),
        );

        if (dataLot.length > newLotsSelected.length) {
          const filteredDataLot = dataLot.filter(lot => newLotsSelected.some(selectedLot => selectedLot.id === lot.pendingTableId));
          setDataLot(filteredDataLot);
        }

        setLotsSelected(newLotsSelected);
      }
    }
  }, [table.getSelectedRowModel().flatRows]);

  useEffect(() => {
    if (selectedTable === LOT_DETAIL) {
      const getTransfersById = (lotSelected: LotSelected[], tableId = ''): LotSelectedTransfer[] => {
        const lotItem = lotSelected.find(item => item.id === tableId);
        return lotItem && lotItem.transfers ? lotItem.transfers : [];
      };
      if (notFirstSelection) {
        const hasFullSelection = isFullSelection?.find(item => item[detailLotId]);
        if (hasFullSelection) {
          const rowSelectionData = table.getRowModel().flatRows.map(rowItem => ({
            id: rowItem.id,
          }));
          return setRowSelection(arrayToObject(rowSelectionData));
        }
      }
      setRowSelection(arrayToObject(getTransfersById(lotsSelected, pendingTableId), false));
      setNotFirstSelection(true);
    } else {
      setLotSelectionHandler(arrayToObject(lotsSelected));
      setRowSelection(arrayToObject(lotsSelected));
      setNotFirstSelection(true);
    }
  }, [pendingTableId, table.getRowModel().flatRows]);

  useEffect(() => {
    if (sorting?.length) {
      const isOrderChanged = JSON.stringify(sorting) !== JSON.stringify(orderAppliedContext);
      if (isOrderChanged) {
        const newOrderAppliedContext = [...orderAppliedContext];
        let orderProperty;
        if (selectedTable !== LOT_DETAIL) {
          newOrderAppliedContext[0] = { ...sorting[0] };
          orderProperty = `${newOrderAppliedContext[0].id}${newOrderAppliedContext[0].desc ? '-' : '+'}`;
          setOrderPropertyContext(orderProperty);
        } else {
          const secondarySorting = sorting.length > 1 ? sorting[1] : sorting[0];
          newOrderAppliedContext[1] = { ...secondarySorting };
          orderProperty = `${newOrderAppliedContext[1].id}${newOrderAppliedContext[1].desc ? '-' : '+'}`;
          setOrderPropertyLotDetailContext(orderProperty);
        }
        setOrderAppliedContext(newOrderAppliedContext);
      }
    }
  }, [sorting, selectedTable]);

  useEffect(() => {
    if (JSON.stringify(table.getState()?.sorting) !== JSON.stringify(orderAppliedContext)) {
      table.setSorting(orderAppliedContext);
    }
  }, [orderAppliedContext]);

  return { disableButton, table };
}

export default useTableLogic;
