/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { getOidcStoraged } from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { COMPANY_ID, IS_VISIBLE_OFFERING_ALERT, RESPONSE_CODES } from '../components/OfferingAlert/constants';
import { OfferingResponse } from '../Interfaces/api';
import { getOfferingDetail } from '../services/api';

const useOfferingAlert = () => {
  const [showOfferingAlert, setShowOfferingAlert] = useState(false);
  const [offeringData, setOfferingData] = useState<OfferingResponse>({} as OfferingResponse);

  const { refetch: refetchOffering } = useQuery(['get-offering'], () => getOfferingDetail().then(res => res.data), {
    retry: false,
    enabled: false,
    onSuccess: (data: OfferingResponse) => {
      if (data?.exception?.code !== RESPONSE_CODES.ERROR) {
        setOfferingData(data);
        setShowOfferingAlert(true);
        sessionStorage.setItem(IS_VISIBLE_OFFERING_ALERT, JSON.stringify(true));
        sessionStorage.setItem(COMPANY_ID, getOidcStoraged()?.profile?.idEntidad);
      }
    },
    onError: () => {
      setShowOfferingAlert(false);
    },
  });

  useEffect(() => {
    const showAlert = JSON.parse(sessionStorage.getItem(IS_VISIBLE_OFFERING_ALERT) || 'null');
    const companySaved = sessionStorage.getItem(COMPANY_ID) || '';
    if (showAlert === null || showAlert === true || getOidcStoraged()?.profile?.idEntidad !== companySaved) {
      refetchOffering();
    }
  }, []);

  const onCloseAlert = () => {
    setShowOfferingAlert(!showOfferingAlert);
    sessionStorage.setItem(IS_VISIBLE_OFFERING_ALERT, JSON.stringify(!showOfferingAlert));
    sessionStorage.setItem(COMPANY_ID, getOidcStoraged()?.profile?.idEntidad);
  };

  return {
    showOfferingAlert,
    offeringData,
    refetchOffering,
    onCloseAlert,
  };
};

export default useOfferingAlert;
