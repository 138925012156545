import { SystemStyleObject } from '@chakra-ui/react';

import { colors } from '../../styled/generalStyles';

const accordion: SystemStyleObject = {
  marginTop: '12px',
  marginBottom: '100px',

  '> div, & p': {
    margin: 0,
  },

  '> div + div': {
    '&, &:focus:hover, &>*, &:focus:hover>*, &>*[aria-expanded=true], &>*[data-expanded], &:hover>button:not(disabled)': {
      borderRadius: 0,
    },
  },

  '>div:hover>button[aria-expanded=true]': {
    background: colors.Primary50,
  },
};

const item: SystemStyleObject = {
  border: 0,
};

const header: SystemStyleObject = {
  height: '86px',
  justifyContent: 'space-between',
  boxShadow: `${colors.ShadowXs}`,
  background: colors.White,
  padding: '0 16px 0 0',
  borderRadius: '8px 8px 0 0',

  '&[aria-expanded=false]:hover, &[aria-expanded=false]:focus, &:focus:hover': {
    background: colors.White,
  },

  '&[aria-expanded=true]:hover': {
    background: colors.Primary50,
  },

  '&[aria-expanded=true], &[aria-expanded=true]:focus': {
    background: colors.Primary50,
    borderBottom: `1px solid ${colors.Grey60}`,
  },

  '& + div': {
    background: colors.White,
  },
};

const headerMain: SystemStyleObject = {
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
};

const headerTexts: SystemStyleObject = {
  flexDirection: 'column',
  alignItems: 'flex-start',
};

const badge: SystemStyleObject = {
  alignItems: 'center',
  justifyContent: 'center',
  width: '100px',
  margin: '0 8px',
};

const headerActions: SystemStyleObject = {
  justifyContent: 'center',
  alignItems: 'center',
};

const chevron: SystemStyleObject = {
  color: colors.Primary100,
  width: '20px',
  height: '20px',
  margin: '0 10px 0 22px',
};

const menuList: SystemStyleObject = {
  padding: '8px 0',
  minWidth: '160px',
  marginTop: '-2px',
  backgroundColor: colors.White,
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 6px 10px 4px, rgba(0, 0, 0, 0.3) 0px 2px 3px',
  borderRadius: '10px',

  '& > button': {
    margin: '0',
    padding: '14px 16px',
    fontFamily: 'Inter',
    fontSize: '14px',
  },
};

const panel: SystemStyleObject = {
  padding: '0',
};

export default {
  accordion,
  item,
  badge,
  chevron,
  header,
  headerActions,
  headerMain,
  headerTexts,
  menuList,
  panel,
};
