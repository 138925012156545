import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../../../styled/generalStyles';

const CustomBox = styled(Box)`
  display: flex;
  padding: 16px 24px 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;

const CustomText = {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '15px',
  color: colors.Error100,
  margin: 0,
};

export { CustomBox, CustomText };
