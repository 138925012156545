import { BANK_NAME } from '../constants';

const MessageMacError = (missingBankCodes: string[], existInBankCodeAll: boolean) => {
  if (missingBankCodes.length === 1) {
    if (!existInBankCodeAll) {
      return [];
    }
    return [BANK_NAME[missingBankCodes[0]]];
  }

  if (missingBankCodes.length > 1) {
    if (!existInBankCodeAll) {
      return [];
    }
    return missingBankCodes.map(code => BANK_NAME[code]);
  }
  return [];
};

export default MessageMacError;
