/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { DataLot, LotSelected, LotSelectedTransfer, ResponseDataLotAndTransfers } from '../Interfaces/contextTypes';

const sortDataByGetTransfers = (lotsSelected: LotSelected[], dataLot: DataLot[]) => {
  const response: ResponseDataLotAndTransfers[] = [];

  for (const obj1 of lotsSelected) {
    const objResponse = response?.find((obj: ResponseDataLotAndTransfers) => obj?.lotId === obj1.id);
    if (objResponse) {
      continue;
    }
    const objTransfer = dataLot?.find((item: DataLot) => item?.pendingTableId === obj1.id);
    if (objTransfer) {
      const idTransfer = obj1.transfers.map((row: LotSelectedTransfer) => row?.transferId);
      if (idTransfer.length > 0) {
        response.push({
          lotId: obj1.id,
          tranfers: idTransfer,
        });
      }
    } else {
      response.push({
        lotId: obj1.id,
      });
    }
  }
  return response;
};

export default sortDataByGetTransfers;
