import { Box, Skeleton } from '@chakra-ui/react';
import { TablePaginationComponent } from '@wow/table-pagination';
import { pushAnalyticsEvent } from '@wow/utils';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import BottomNav from '../../components/BottomNav';
import CustomTable from '../../components/CustomTable';
import DrawerDetails from '../../components/Drawer';
import IconDisconnected from '../../components/Icons/icon-disconnected';
import ViewNoResult from '../../components/ViewNoResult';
import { DataMessageErrorRequest, orderAppliedValueDefault, orderPropertyLotDetail, PAGE_ACTIONS, routesNames } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import { TransferDetails } from '../../Interfaces/Drawer';
import {
  LotDetail as ILotDetail,
  Pagination as PaginationInterfaces,
  ServiceParams,
  TableDataStateType,
  TransfersFlow,
} from '../../Interfaces/transfers';
import { getLotDetail, getTransfersDetail } from '../../services/api';
import { LOT_DETAIL } from '../../utils/configTableFunctions';
import { COLUMNS } from './configTable';
import { COLUMNSMODIFY } from './configTableModify';
import { EXCEPTION_CODES } from './constants';
import GeneralLotInformation from './GeneralLotInformation';
import { CustomTd, CustomTdModify, CustomTh, CustomThModify, Paginator } from './index.styled';
import WizardSteps from './WizardSteps';

interface Props {
  activeBadge?: boolean;
}

function LotDetail({ activeBadge }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(15);
  const [lots, setLots] = useState<ILotDetail[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ordenId, setOrdenId] = useState<number>(0);
  const [transfersDetails, setTransfersDetails] = useState<TransferDetails>();
  const [totalTransfer] = useState<number>(0);
  const [totalAmount] = useState<number>(0);
  const [requestDateStatus, setRequestDateStatus] = useState<string>('');
  const queryClient = useQueryClient();
  const [firstTime, setFirstTime] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInterfaces>({
    totalElements: 0,
    totalPages: 0,
    totalElementsRequested: 0,
    pageNumber: 0,
  });
  const {
    detailParams,
    setDetailLotId,
    detailLotId,
    orderPropertyLotDetailContext,
    setOrderAppliedContext,
    setOrderPropertyLotDetailContext,
    orderAppliedContext,
  } = useTransfersContext();

  const params: ServiceParams = {
    size: String(size),
    page: String(page),
    orderProperty: orderPropertyLotDetailContext,
    confection: detailParams.confection,
  };
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();
  const [isTimeOutTranferError, setIsTimeOutTranferError] = useState<boolean>(false);
  const [isTimeOutLotError, setIsTimeOutLotError] = useState<boolean>(false);
  const [tableDataState, setTableDataState] = useState([]);

  const isConfection = detailParams.origin === TransfersFlow.Confection;

  const lotParams = {
    lot: detailParams.lot || '',
    date: detailParams.date || '',
    bank: detailParams.bank || '',
    status: detailParams.status || '',
    params,
  };

  const { refetch, isFetching, status } = useQuery(['get-lot-detail', params], () => getLotDetail(lotParams).then(res => res.data), {
    retry: false,
    onSuccess: data => {
      if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
        setIsTimeOutLotError(true);
        setFirstTime(false);
      } else if (data?.data?.transfer <= 0) {
        navigate(routesNames.Root);
      } else {
        setDetailLotId(data?.data?.lotId);
        setLots(data?.data?.transfer);
        setRequestDateStatus(data?.data?.requestDateStatus);
        setTableDataState(data);
        setPagination(data?.paging);
        setFirstTime(true);
      }
    },
  });

  const { isError: statusTransfersDetail, refetch: refetchTransfersDetail } = useQuery(
    ['get-transfers-detail', ordenId],
    () => getTransfersDetail({ ordenId }).then(res => res.data),
    {
      retry: false,
      enabled: false,
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.timeOut) {
          setIsTimeOutTranferError(true);
          setFirstTime(false);
        } else {
          setTransfersDetails(data.data?.transfer);
          setFirstTime(true);
        }
      },
    },
  );

  const goToConfectionResult = () => {
    navigate(routesNames.ConfectionResult);
  };

  useEffect(() => {
    return () => {
      setFirstTime(false);
    };
  }, [orderAppliedContext, page, size]);

  useEffect(() => {
    if (!lotParams.lot) {
      navigate(location.pathname.includes('confeccion') ? routesNames.TransferSetup : routesNames.Root);
    }
    pushByFlow();
    if (localStorage.getItem('tef-wizard-detail') == null) {
      setInitialized(true);
    }
    setOrderPropertyLotDetailContext(orderPropertyLotDetail);
    const [, secondItem] = orderAppliedValueDefault;
    const auxOrderAppliedContext = [...orderAppliedContext];
    auxOrderAppliedContext[1] = secondItem;
    setOrderAppliedContext(auxOrderAppliedContext);
    if (orderPropertyLotDetailContext === orderPropertyLotDetail) {
      refetch();
    }
  }, []);

  const pushByFlow = () => {
    switch (detailParams.transfersFlow) {
      case TransfersFlow.Send:
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Transferencias - Detalle de lote',
        });
        break;
      case TransfersFlow.Authorize:
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Transferencias - Autorizar - Detalle de lote',
        });
        break;
      case TransfersFlow.Unauthorize:
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Transferencias - Quitar autorización - Detalle de lote',
        });
        break;
      case TransfersFlow.Annul:
        pushAnalyticsEvent({
          event: 'trackContentGroup',
          content_group: 'Transferencias - Anular - Detalle de lote',
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (ordenId !== 0) {
      queryClient.refetchQueries(['get-transfers-detail', ordenId]);
    }
  }, [ordenId, queryClient]);

  const handleToggleDrawer = (param: number) => {
    setOrdenId(param);
    setIsOpen(true);
  };

  const handleToggleDrawerRetry = () => {
    refetchTransfersDetail();
  };

  const handleToggleAnalytics = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'No pudimos cargar los datos solicitados',
      item_id: 'CTA - Reintentar',
    });
  };

  useEffect(() => {
    if (status === 'error') {
      pushAnalyticsEvent({
        event: 'trackContentGroup',
        content_group: 'Transferencias - Detalle de lote error de servicio',
      });
    }
  }, [status]);

  const handleFinishWizard = () => {
    localStorage.setItem('tef-wizard-detail', 'true');
  };

  const handlePageNavigation = (pageNumber: number, action: string) => {
    setPage(pageNumber);
    pushAnalyticsEvent({
      event: 'switch_page',
      selection_type: `${pageNumber} | ${action}`,
    });
  };

  return (
    <>
      {status === 'error' || isTimeOutLotError ? (
        <ViewNoResult
          title={isConfection ? DataMessageErrorRequest.confectionTitle : DataMessageErrorRequest.title}
          description={DataMessageErrorRequest.description}
          linkName={isConfection ? DataMessageErrorRequest.confectionLinkName : DataMessageErrorRequest.transfersLinkName}
          Icon={IconDisconnected}
          onClickLink={isConfection ? goToConfectionResult : undefined}
          handleToggleClick={handleToggleAnalytics}
          isConfection={isConfection}
        />
      ) : (
        <>
          {initialized && (
            <WizardComponent
              id='tef-wizard-detail'
              initialized={initialized}
              setInitialized={setInitialized}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              steps={WizardSteps}
              width={300}
              height={250}
              offset={0}
              onFinish={handleFinishWizard}
            />
          )}
          <GeneralLotInformation
            requestDateStatus={requestDateStatus}
            data={lots}
            activeBadge={activeBadge}
            totalTransfer={pagination?.totalElements}
            origin={detailParams.origin}
            firstTime={firstTime}
          />
          <CustomTable
            CustomTd={isConfection ? CustomTdModify : CustomTd}
            CustomTh={isConfection ? CustomThModify : CustomTh}
            data={lots as ILotDetail[]}
            columns={(isConfection ? COLUMNSMODIFY : COLUMNS) as []}
            size={size}
            selectedTable={LOT_DETAIL}
            pendingTableId={detailLotId}
            totalTransfer={totalTransfer}
            totalAmount={totalAmount}
            handleToggleDrawer={handleToggleDrawer}
            tableDataState={tableDataState as TableDataStateType}
            id='tableDetail'
            isFetching={isFetching}
            firstStatusSkeleton={firstTime}
            secondStatusSkeleton={firstTime}
          />
          <Box marginBottom='168px'>
            {firstTime ? (
              <TablePaginationComponent
                totalPages={pagination?.totalPages || 1}
                currentIndex={pagination?.pageNumber && pagination.pageNumber - 1}
                totalRecords={pagination?.totalElements}
                perPage={[15, 30, 45]}
                disabledPerPage={pagination?.totalElements <= 15}
                currentPerPage={pagination?.totalElementsRequested}
                onNext={() => handlePageNavigation(page + 1, PAGE_ACTIONS.NEXT)}
                onPrev={() => handlePageNavigation(page - 1, PAGE_ACTIONS.PREV)}
                onFirst={() => handlePageNavigation(1, PAGE_ACTIONS.FIRST)}
                onLast={() => handlePageNavigation(pagination?.totalPages, PAGE_ACTIONS.LAST)}
                onJump={(value: number) => {
                  if (Number(value) < page) {
                    handlePageNavigation(Number(value), PAGE_ACTIONS.PREV);
                  } else if (Number(value) > page) {
                    handlePageNavigation(Number(value), PAGE_ACTIONS.NEXT);
                  }
                }}
                onPerPageChange={(value: number) => {
                  setSize(Number(value));
                  setPage(1);
                }}
              />
            ) : (
              <Box sx={Paginator}>
                <Skeleton width='100%' height='56px' borderRadius='4px' marginTop='6px' data-testid='skeleton-component' />
              </Box>
            )}
          </Box>
          <BottomNav
            handleGoBack={() => {
              pushAnalyticsEvent({
                event: 'select_content',
                content_type: 'Botones seccion Lote de transferencias',
                item_id: 'CTA - Volver',
              });
              navigate(isConfection ? routesNames.ConfectionResult : routesNames.Root, {
                state: { from: location, processId: location?.state?.processId },
              });
            }}
            textButtonGoBack='Volver'
          />
        </>
      )}
      {isOpen && (
        <DrawerDetails
          isOpen={isOpen}
          onCancel={setIsOpen}
          transfersDetails={transfersDetails}
          statusTransfersDetail={statusTransfersDetail || isTimeOutTranferError}
          handleToggleDrawerRetry={handleToggleDrawerRetry}
        />
      )}
    </>
  );
}

export default LotDetail;
