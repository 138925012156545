import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { AsyncProcessStatus } from '@interfaces/api';
import { Badge } from '@wow/badge';
import { format, parseISO } from 'date-fns';
import { ChevronRight, Clock } from 'react-feather';

import { TEST_IDS, TEXTS } from './constants';
import { getView } from './helpers';
import styles from './index.styled';
import { Props } from './interfaces';

function ProcessesCard({ process, onClickView }: Props) {
  const view = getView(process);
  const showLinkButton =
    process.status === AsyncProcessStatus.success ||
    process.status === AsyncProcessStatus.pending ||
    process.status === AsyncProcessStatus.partialRejection ||
    process.status === AsyncProcessStatus.totalRejection;

  return (
    <Flex sx={styles.card} data-testid={`${TEST_IDS.processesCard}${process.number}`}>
      <Flex sx={styles.header}>
        <Badge variant={view.badge}>{view.badgeText}</Badge>
        <Text as='div' textStyle='overlineLg' marginLeft='8px'>
          {TEXTS.confectionNumber} {process.number}
        </Text>
      </Flex>
      <Box sx={styles.content}>
        <Text as='div' textStyle='bodyMd'>
          {view.description}
        </Text>
      </Box>
      <Flex sx={styles.footer}>
        <Flex alignItems='center'>
          <Clock size='16px' />
          <Text as='div' textStyle='labelSm' margin='0 8px'>
            {TEXTS.lastUpdate}
          </Text>
          <Text as='div' textStyle='labelSm'>
            {format(parseISO(process.lastUpdateDate), 'dd/MM/yyyy HH:mm')}
          </Text>
        </Flex>
        {showLinkButton && (
          <Button
            variant='primary-text'
            size='md'
            onClick={() => onClickView(process)}
            rightIcon={<ChevronRight width='24px' />}
            sx={styles.linkButton}
          >
            {view.linkText}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default ProcessesCard;
