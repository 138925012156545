import { Box, Text } from '@chakra-ui/react';
import { BankAccount } from '@interfaces/api';

import { ACCOUNT_NAME_LENGTH } from '../../constants';

const tooltipAccount = (label: string, account: BankAccount | null) => {
  const line1 = [account?.accountName];
  if (account?.cuit) {
    line1.push(account?.cuit);
  }
  const line2 = [];
  if (account?.cbu) {
    line2.push(account?.cbu);
  }
  if (account?.accountType) {
    line2.push(account?.accountType);
  }
  return (
    account &&
    account?.accountName?.length > ACCOUNT_NAME_LENGTH && (
      <Box>
        <Text as='div' textStyle='bodySmSemi'>
          {label} {account?.bank.bankName}
        </Text>
        <Text as='div' textStyle='bodySm'>
          {line1.join(' | ')}
          {!!line2.length && <br />}
          {line2.join(' | ')}
        </Text>
      </Box>
    )
  );
};

const formatAccountName = (accountName: string | undefined, cuit: string | undefined) => {
  const data = [];
  if (accountName) {
    data.push(`${accountName.substring(0, ACCOUNT_NAME_LENGTH)}${accountName.length > ACCOUNT_NAME_LENGTH ? '...' : ''}`);
  }
  if (cuit) {
    data.push(cuit);
  }
  return data.join(' | ');
};

const formatAccountData = (cbu: string | undefined, accountType: string | undefined) => {
  const data = [];
  if (cbu) {
    data.push(cbu);
  }
  if (accountType) {
    data.push(accountType);
  }
  return data.join(' | ');
};

export { formatAccountData, formatAccountName, tooltipAccount };
