/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';

import { State } from '../Interfaces/contextTypes';
import actionsCreators from './actions';
import initialState from './initialState';
import reducer from './reducer';

export const Context = createContext<State | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export default function ContextProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = actionsCreators(dispatch, state);

  const value = useMemo(
    () => ({
      ...state,
      ...actions,
    }),
    [
      state.pageContext,
      state.sizeContext,
      state.lotSelectionHandler,
      state.lotsSelected,
      state.isFullSelection,
      state.dataLot,
      state.inputLot,
      state.filtered,
      state.detailLotId,
      state.bankCodes,
      state.responseOfTransferSelectedByUser,
      state.dataDetailsTransfers,
      state.dataTotalsTransfers,
      state.transfersRejected,
      state.transfersAccepted,
      state.isAuthenticated,
      state.servicesWorking,
      state.detailParams,
      state.panelSelected,
      state.hasNextTefDay,
      state.isBusinessDay,
      state.hasData,
      state.orderPropertyContext,
      state.orderAppliedContext,
      state.orderPropertyLotDetailContext,
      state.selectedTransfersTypesCodes,
      state.sectionPermissions,
      state.viewPartialRejecteAuth,
      state.transferTypeAuthReadyToSend,
      state.otp,
      state.errorUseAuthPartial,
      state.totalAmountPartialReject,
      state.loadingPartialReject,
      state.manualTransferData,
      state.fileSelected,
      state.transferTypeByOperators,
      state.transferTypeOptionSelected,
      state.dateSelected,
      state.amountRanges,
      state.debitAccounts,
      state.holidays,
      state.workingDay,
      state.transferNumber,
      state.confectionData,
      state.confectionReset,
      state.isExitConfectionModalOpen,
      state.redirectPath,
      state.otpConfigured,
      state.asyncProcesses,
      state.asyncProcessesLoading,
      state.asyncProcessesError,
      state.updateAsyncProcesses,
      state.showAsyncProcesses,
      state.isReferenceActive,
      state.debitFiltersApplied,
      state.creditFiltersApplied,
      state.statusButtonModify,
      state.isLoadingLots,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useTransfersContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('Context must be used within a ContextProvider');
  }
  return context;
};
