import { ResultError, ResultErrorCode, ResultErrorIcon, TransfersFlow } from '../../Interfaces/transfers';

export const TEXTS = {
  goToTransfers: 'Ir a Transferencias',
  error: 'Error',
  success: 'Éxito',
  transfers: (count: number) => `${count} transferencia${count > 1 ? 's' : ''}`,
  [TransfersFlow.Authorize]: {
    title: 'Resultado de la autorización',
    accordionTitle: (count: number) => (count === 1 ? '1 transferencia no se autorizó' : `${count} transferencias no se autorizaron`),
    successTitle: (count: number) =>
      count === 1 ? '1 transferencia se autorizó con éxito' : `${count} transferencias se autorizaron con éxito`,
    accordionDescription: `Podés volver a intentarlo desde la sección <strong>Autorizar</strong>.`,
  },
  [TransfersFlow.Unauthorize]: {
    title: 'Resultado de quitar autorización',
    accordionTitle: (count: number) =>
      count === 1 ? 'No podemos quitar tu autorización de 1 transferencia' : `No podemos quitar tu autorización de ${count} transferencias`,
    successTitle: (count: number) =>
      count === 1 ? 'Quitamos tu autorización de 1 transferencia' : `Quitamos tu autorización de ${count} transferencias`,
    accordionDescription: `Podés volver a intentarlo desde la sección <strong>Quitar autorización</strong>.`,
  },
  [TransfersFlow.Annul]: {
    title: 'Resultado de la anulación',
    accordionTitle: (count: number) => (count === 1 ? '1 transferencia no se anuló' : `${count} transferencias no se anularon`),
    successTitle: (count: number) => (count === 1 ? '1 transferencia se anuló' : `${count} transferencias se anularon`),
    accordionDescription: `Podés volver a intentarlo desde la sección <strong>Anular</strong>.`,
  },
  [TransfersFlow.Revoke]: {
    title: 'Resultado de revocar envío',
    accordionTitle: (count: number) =>
      count === 1 ? 'No podemos revocar el envío de 1 transferencia' : `No podemos revocar el envío de ${count} transferencias`,
    successTitle: (count: number) =>
      count === 1 ? 'Revocamos el envío de 1 transferencia' : `Revocamos el envío de ${count} transferencias`,
    accordionDescription: `Podés volver a intentarlo desde la sección <strong>Revocar envío</strong>.`,
  },
};

export const GENERIC_ERROR: ResultError = {
  code: ResultErrorCode.TEF_GENERIC_ERROR,
  icon: ResultErrorIcon.alertTriangle,
  description: 'El sistema no está disponible en este momento.',
};

export const AUTHORIZATION_ERRORS: ResultError[] = [
  GENERIC_ERROR,
  {
    code: ResultErrorCode.TEF_TRANSFERS_REJECT_ADD,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Es probable que las transferencias ya no estén disponibles para autorizar, verificá el estado en Reportes.',
  },
  {
    code: ResultErrorCode.TEF_NOT_ACCEPTS_TRANSFER_TYPE_CRED,
    icon: ResultErrorIcon.bank,
    description: 'El banco de crédito no acepta este tipo de transferencia.',
  },
  {
    code: ResultErrorCode.TEF_NOT_ACCEPTS_MULTIBANK_CRED,
    icon: ResultErrorIcon.bank,
    description: 'El banco de crédito no acepta transferencias multibanco.',
  },
  {
    code: ResultErrorCode.TEF_NOT_ACCEPTS_TRANSFER_TYPE_DEB,
    icon: ResultErrorIcon.bank,
    description: 'El banco de débito no acepta este tipo de transferencia.',
  },
  {
    code: ResultErrorCode.TEF_NOT_ACCEPTS_MULTIBANK_DEB,
    icon: ResultErrorIcon.bank,
    description: 'El banco de débito no acepta transferencias multibanco.',
  },
  {
    code: ResultErrorCode.TEF_OPEN_CREDIT_ACCOUNT_DISABLED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'El banco inhabilitó la cuenta de crédito.',
  },
  {
    code: ResultErrorCode.TEF_NOT_BANK_OPERATIVE,
    icon: ResultErrorIcon.bank,
    description: 'El banco no está operativo en este momento.',
  },
  {
    code: ResultErrorCode.TEF_BANK_DOES_NOT_OFFER_SERVICE,
    icon: ResultErrorIcon.bank,
    description: 'El banco no ofrece el servicio.',
  },
  {
    code: ResultErrorCode.TEF_BANK_DOES_NOT_OPERATE_CREDITS,
    icon: ResultErrorIcon.bank,
    description: 'El banco solo permite enviar transferencias.',
  },
  {
    code: ResultErrorCode.TEF_BANK_DOES_NOT_OPERATE_DEBITS,
    icon: ResultErrorIcon.bank,
    description: 'El banco solo permite recibir transferencias.',
  },
  {
    code: ResultErrorCode.TEF_OPE_NO_TEF,
    icon: ResultErrorIcon.settings,
    description: 'El operador no puede realizar transferencias con esta cuenta.',
  },
  {
    code: ResultErrorCode.TEF_SERVICE_BANK_DISABLED,
    icon: ResultErrorIcon.bank,
    description: 'El servicio está inhabilitado en el banco.',
  },
  {
    code: ResultErrorCode.TEF_NON_EXISTENT_TOP,
    icon: ResultErrorIcon.edit3,
    description: 'Error en el esquema de firmas, comunicate con Soporte.',
  },
  {
    code: ResultErrorCode.TEF_ACCOUNT_CREDIT_USE_ENABLED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Error en la cuenta de crédito, revisá la configuración en ABM.',
  },
  {
    code: ResultErrorCode.TEF_CREDIT_ACCOUNT_ERROR,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Error en la cuenta de crédito, número de cuenta inválido.',
  },
  {
    code: ResultErrorCode.TEF_CREDIT_TIME_HOURS,
    icon: ResultErrorIcon.clock,
    description: 'Estás fuera del horario del banco de crédito para autorizar.',
  },
  {
    code: ResultErrorCode.TEF_DEBIT_TIME_HOURS,
    icon: ResultErrorIcon.clock,
    description: 'Estás fuera del horario del banco de débito para autorizar.',
  },
  {
    code: ResultErrorCode.TEF_DEBIT_ACCOUNT_NOT_ENABLED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'La cuenta de crédito no está habilitada.',
  },
  {
    code: ResultErrorCode.TEF_CREDIT_ACCOUNT_NOT_ENABLED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'La cuenta de débito no está habilitada.',
  },
  {
    code: ResultErrorCode.TEF_DEBIT_ACCOUNT_NOT_ENABLED_TRANSFERS,
    icon: ResultErrorIcon.alertTriangle,
    description: 'La cuenta de débito no permite transferencias, revisá la configuración en ABM, sección Administración, opción Cuentas.',
  },
  {
    code: ResultErrorCode.TEF_CREDIT_BRANCH_ENABLED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'La sucursal de la cuenta de crédito no está habilitada para operar.',
  },
  {
    code: ResultErrorCode.TEF_DEBIT_BRANCH_ENABLED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'La sucursal de la cuenta de débito no está habilitada para operar.',
  },
  {
    code: ResultErrorCode.TEF_NOT_AUTHORIZED_STATUS,
    icon: ResultErrorIcon.alertTriangle,
    description: 'La transferencia no está listá para autorizar, verficá su estado.',
  },
  {
    code: ResultErrorCode.TEF_ACCOUNT_RELATIONS,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Las cuentas no están relacionadas, revisá la configuración en ABM.',
  },
  {
    code: ResultErrorCode.TEF_CAN_NOT_OPERATE_ACCOUNT_DEB,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Los datos de la cuenta de débito son incorrectos. Revisá los datos de la cuenta de origen de la transferencia.',
  },
  {
    code: ResultErrorCode.TEF_NO_TIME_HOURS,
    icon: ResultErrorIcon.settings,
    description: 'No hay horarios cargados para el banco, comunicate con Soporte para resolverlo.',
  },
  {
    code: ResultErrorCode.TEF_CAN_NOT_OPERATE_ACCOUNT_CRED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Los datos de la cuenta de crédito son incorrectos. Revisá los datos de la cuenta de destino de la transferencia.',
  },
  {
    code: ResultErrorCode.TEF_ACCOUNT_MODE_USE_CREDIT,
    icon: ResultErrorIcon.settings,
    description: 'Revisá los usos de cuenta de crédito en el módulo ABM o Reportes.',
  },
  {
    code: ResultErrorCode.TEF_ACCOUNT_MODE_USE_DEBIT,
    icon: ResultErrorIcon.settings,
    description: 'Revisá los usos de cuenta de débito en el módulo ABM o Reportes.',
  },
  {
    code: ResultErrorCode.TEF_EXCEED_CANT_TEF,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite de importe para este tipo de transferencias.',
  },
  {
    code: ResultErrorCode.TEF_OVER_TOPE_DAY_ESQ,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite diario en el esquema de firmas para transferir.',
  },
  {
    code: ResultErrorCode.TEF_OVER_TOPE_DAY_ESQ_CTA_USE,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite diario para el tipo de transferencia en la cuenta, en el esquema de firmas.',
  },
  {
    code: ResultErrorCode.TEF_EXCEED_TOPE_DAY_CTA,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite diario permitido para la cuenta.',
  },
  {
    code: ResultErrorCode.TEF_OVER_TOPE_TRX_ESQ_CTA,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite para el esquema de firmas y cuenta.',
  },
  {
    code: ResultErrorCode.TEF_OVER_TOPE_TRX_ESQ_CTA_USE,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite para el esquema de firmas, cuenta y tipo de transferencia.',
  },
  {
    code: ResultErrorCode.TEF_IMPAC_CTA_DEBIT_PART,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite permitido diario de la cuenta de débito, en el esquema de firmas.',
  },
  {
    code: ResultErrorCode.TEF_IMP_CTA_PART,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite permitido en el esquema de firmas de la cuenta de débito para este tipo de transferencias.',
  },
  {
    code: ResultErrorCode.TEF_LIMIT_IMPORT,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite permitido en el esquema de firmas para este tipo de transferencia.',
  },
  {
    code: ResultErrorCode.TEF_ERROR_IMPORT_LIMIT,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite permitido para esta transferencia (B2B).',
  },
  {
    code: ResultErrorCode.TEF_EXCEED_TOPE,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite permitido para la cuenta dentro el esquema.',
  },
  {
    code: ResultErrorCode.TEF_AMOUNT_TEF_EXCEEDED,
    icon: ResultErrorIcon.trendingUp,
    description: 'Supera el límite por transacción para el tipo de transferencia en la cuenta, en el esquema de firmas.',
  },
  {
    code: ResultErrorCode.TEF_OPE_CAN_NOT_SIGN,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Verificá el esquema de firmas en el módulo ABM o Reportes.',
  },
  {
    code: ResultErrorCode.TEF_OPERATOR_NOT_OPERA_WITH_BANK,
    icon: ResultErrorIcon.settings,
    description: 'Verificá la relación operador y banco en Reportes, sección Administración, opción Operadores.',
  },
];

export const UNAUTHORIZATION_ERRORS: ResultError[] = [
  GENERIC_ERROR,
  {
    code: ResultErrorCode.TEF_NO_REMOVE_AUTHORIZATION,
    icon: ResultErrorIcon.alertTriangle,
    description: 'No se puede quitar tu autorización de la transferencia, verificá su estado.',
  },
];

export const UNANUUL_ERRORS: ResultError[] = [
  GENERIC_ERROR,
  {
    code: ResultErrorCode.TEF_NOT_AVAILABLE,
    icon: ResultErrorIcon.alertTriangle,
    description: 'La transferencia no se puede anular, verificá su estado.',
  },
  {
    code: ResultErrorCode.TEF_NOT_FOUND_PRESENTATION_AFIP,
    icon: ResultErrorIcon.alertTriangle,
    description: 'No se encontró la presentación del VEP de AFIP.',
  },
];

export const REVOKE_ERRORS: ResultError[] = [
  GENERIC_ERROR,
  {
    code: ResultErrorCode.REV_UNIFIED_SHIPPING,
    icon: ResultErrorIcon.layers,
    description: 'Las transferencias pertenecen a un lote con envío unificado y no se seleccionó la totalidad del lote.',
  },
  {
    code: ResultErrorCode.REV_OPERATOR_NOT_AUTHORIZED,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Es probable que no tengas los permisos para revocar el envío.',
  },
  {
    code: ResultErrorCode.REV_NON_STATUS,
    icon: ResultErrorIcon.alertTriangle,
    description: 'Es probable que las transferencias ya no estén disponibles para revocar el envío, verificá el estado.',
  },
];

export const VIEW_PARTIAL_REJECT_AUTHORIZATION = {
  title: 'No podemos autorizar todas las transferencias',
  subTitle:
    'Algunas transferencias tienen errores y no se pueden autorizar. Podés continuar con las transferencias que están <strong>Por autorizar.</strong>',
};

export const TRANSFER_TYPE = {
  AFIP: 'AFI',
  INTERNAL: 'INT',
  TO_DEFINE_SAA: 'PDS',
};

export const TEXT_MODAL_SUCCESS_AUTH = {
  title: 'Transferencias autorizadas',
  altIcon: 'icon-success-modal',
  textButtom: 'Entendido',
};
