import { WizardKey } from './constants';

const WizardSteps = [
  {
    title: WizardKey.titleEntityKey,
    description: WizardKey.descEntityKey,
    target: 'entityKey',
    placement: 'top',
    offsetExtraY: -20,
    offsetExtraX: -40,
    enableScroll: false,
  },
  {
    title: WizardKey.titleSummary,
    description: WizardKey.descSummary,
    target: 'sendSummary',
    placement: 'top',
    offsetExtraY: -20,
    offsetExtraX: 0,
  },
  {
    title: WizardKey.titleOptionKey,
    description: WizardKey.descOptionKey,
    target: 'optionKey',
    placement: 'left',
    offsetExtraY: 0,
    offsetExtraX: 20,
  },
];

export default WizardSteps;
