/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Text } from '@chakra-ui/react';

import { PartialRejectAuthCreaditAccount, PartialRejectAuthDebitAccount } from '../../../../Interfaces/transfers';

interface Props {
  account: PartialRejectAuthCreaditAccount | PartialRejectAuthDebitAccount;
}

function TooltipInformation({ account }: Props) {
  const { bankName, accountName, cuit, accountNumber, cbu, accountType } = account;
  return (
    <>
      <Box>
        <Text as='span' textStyle='bodyMdSemi'>{`${bankName}`}</Text>
      </Box>
      {accountName && <Text as='span'>{`${accountName}  `}</Text>}
      {accountName && (cuit || accountNumber) && <Text as='span'>|</Text>}
      {cuit || accountNumber ? <Text as='span'>{`  ${cuit || accountNumber}`}</Text> : null}
      <Box>
        {cbu !== undefined && <Text as='span'>{`${cbu}  `}</Text>}
        {cbu && accountType && <Text as='span'>|</Text>}
        {accountType !== undefined && <Text as='span'>{`  ${accountType}`}</Text>}
      </Box>
    </>
  );
}

export default TooltipInformation;
