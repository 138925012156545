import * as React from 'react';

function IconCheckMark() {
  return (
    <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M38.0001 73.15C18.6182 73.15 2.8501 57.3819 2.8501 38C2.8501 18.6181 18.6182 2.85001 38.0001 2.85001C57.382 2.85001 73.1501 18.6181 73.1501 38C73.1501 57.3819 57.382 73.15 38.0001 73.15Z'
        fill='#84F2D4'
      />
      <path
        d='M37.9999 3.79999C56.8574 3.79999 72.1999 19.1425 72.1999 38C72.1999 56.8575 56.8574 72.2 37.9999 72.2C19.1424 72.2 3.7999 56.8575 3.7999 38C3.7999 19.1425 19.1424 3.79999 37.9999 3.79999ZM37.9999 1.89999C18.0632 1.89999 1.8999 18.0633 1.8999 38C1.8999 57.9367 18.0632 74.1 37.9999 74.1C57.9366 74.1 74.0999 57.9367 74.0999 38C74.0999 18.0633 57.9366 1.89999 37.9999 1.89999Z'
        fill='#109672'
      />
      <path
        d='M30.4475 53.238L17.3242 40.1147L21.356 36.0848L30.4475 45.1763L53.4242 22.1996L57.456 26.2295L30.4475 53.238Z'
        fill='#13B589'
      />
    </svg>
  );
}
export default IconCheckMark;
