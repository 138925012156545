import { Input } from '@chakra-ui/react';
import AutoNumeric from 'autonumeric';
import { useEffect, useRef } from 'react';

import { autoNumericConfig, INPUT_BACKSPACE, INPUT_DELETE } from './constants';
import { AutoNumericInputProps } from './interfaces';

function AutoNumericInput({
  value,
  currencySymbol = '$',
  decimalPlaces = 2,
  showCurrencySymbol = true,
  onValueChange,
  ...props
}: AutoNumericInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const autoNumericRef = useRef<AutoNumeric | null>(null);
  const debounceRef = useRef<number | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      autoNumericRef.current = new AutoNumeric(inputRef.current, {
        ...autoNumericConfig,
        currencySymbol: showCurrencySymbol ? `${currencySymbol} ` : '',
        decimalPlaces,
        decimalPlacesShownOnFocus: decimalPlaces,
        decimalPlacesShownOnBlur: decimalPlaces,
        allowDecimalPadding: 'floats',
      } as AutoNumeric.Options);
      autoNumericRef.current.set(value);
    }

    return () => {
      if (autoNumericRef.current) {
        autoNumericRef.current.remove();
        autoNumericRef.current = null;
      }
    };
  }, [currencySymbol, decimalPlaces]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === INPUT_BACKSPACE || e.key === INPUT_DELETE) && inputRef.current?.value === '') {
      if (autoNumericRef.current) {
        autoNumericRef.current.set('0');
        if (onValueChange) {
          onValueChange('0');
        }
      }
    }
  };

  useEffect(() => {
    if (autoNumericRef.current && value !== autoNumericRef.current.getNumericString()) {
      autoNumericRef.current.set(value);
    }
  }, [value]);

  const handleBlur = () => {
    if (autoNumericRef.current) {
      autoNumericRef.current.reformat();
    }
  };

  const handleChange = () => {
    if (autoNumericRef.current && onValueChange) {
      const numericValue = autoNumericRef.current.getNumericString() ?? '';
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        onValueChange(numericValue);
      }, 200) as unknown as number;
    }
  };

  return <Input ref={inputRef} onChange={handleChange} onBlur={handleBlur} onKeyDown={handleKeyDown} {...props} />;
}

export default AutoNumericInput;
