/* eslint-disable import/no-unresolved */
import './styled/fonts';
import './styled/index.css';

import { ChakraProvider, extendTheme, Text } from '@chakra-ui/react';
import { PrivateRender } from '@react-ib-mf/style-guide-ui';
import { AccordionChakra as Accordion } from '@wow/accordion';
import { AlertChakra as Alert } from '@wow/alert';
import { BadgeChakra as Badge } from '@wow/badge';
import { BreadcrumbChakra as Breadcrumb } from '@wow/breadcrumb';
import { ButtonChakra as Button, IconButtonComponent as IconButton } from '@wow/button';
import { CheckboxChakra as Checkbox } from '@wow/checkbox';
import { CloseButtonChakra as CloseButton } from '@wow/close-button';
import { FormChakra as Form } from '@wow/form-control';
import { FormErrorChakra as FormError } from '@wow/form-error';
import { FormLabelChakra as FormLabel } from '@wow/form-label';
import { InputChakra as Input } from '@wow/input';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { Menu } from '@wow/menu';
import { SkeletonChakra as Skeleton } from '@wow/progress-indicator';
import { TabsChakra as Tabs } from '@wow/tabs';
import { themeChakra } from '@wow/themes';
import { TooltipChakra as Tooltip } from '@wow/tooltip';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

import i18next from '../i18n';
import packageJson from '../package.json';
import App from './App';
import CacheBuster from './components/CacheBuster';

declare global {
  interface Window {
    appVersion: string;
  }
}

window.appVersion = packageJson.version;

const spacing = {
  px: '1px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
};

const largeSizes = {
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem',
  prose: '60ch',
};

const container = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const sizes = {
  ...spacing,
  ...largeSizes,
  container,
};

const breakpoints = {
  sm: '1200px',
  md: '1350px',
  lg: '1400px',
  xl: '1500px',
  '2xl': '1636px',
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

const theme = extendTheme({
  ...themeChakra,
  sizes,
  space: spacing,
  textStyles: {
    ...themeChakra.textStyles,
  },
  colors: {
    ...themeChakra.colors,
  },
  shadows: {
    ...themeChakra.shadows,
  },
  breakpoints,
  components: {
    Checkbox,
    Alert,
    Tabs,
    Button,
    IconButton,
    Input,
    Tooltip,
    Badge,
    Accordion,
    Menu,
    CloseButton,
    Breadcrumb,
    Skeleton,
    FormLabel,
    Form,
    FormError,
    LoadingScreen,
  },
});

export default function Root() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) {
          return <Text>Loading...</Text>;
        }
        if (isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <PrivateRender>
            <QueryClientProvider client={queryClient}>
              <ChakraProvider resetCSS={false} theme={theme}>
                <I18nextProvider i18n={i18next}>
                  <App />
                </I18nextProvider>
              </ChakraProvider>
            </QueryClientProvider>
          </PrivateRender>
        );
      }}
    </CacheBuster>
  );
}
