import { Box, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import TransferSetupTabs from '../../../../components/TransferSetupTabs';
import LoadFromFile from '../../Panels/LoadFromFile';
import RedirectView from '../../Panels/LoadFromFile/RedirectView';
import ManualTransferSetup from '../../Panels/ManualSetupTransfers';
import { tabs } from './index.styled';
import { Props } from './interfaces';

function Step1({
  tabIndex,
  handleTabsChange,
  handleFormSubmit,
  confectionPermits,
  statusPermission,
  statusGetPermission,
  setShowBottomNav,
}: Props) {
  return (
    <Tabs size='lg' isLazy isManual sx={tabs} index={tabIndex} onChange={handleTabsChange}>
      <TransferSetupTabs
        showManual={confectionPermits.canCreate}
        showFile={confectionPermits.canUpload}
        showModel={confectionPermits.canCreate}
        statusPermission={statusPermission}
      />
      <TabPanels>
        <TabPanel>
          <ManualTransferSetup onSubmit={handleFormSubmit} statusGetPermission={statusGetPermission} setShowBottomNav={setShowBottomNav} />
        </TabPanel>
        <TabPanel>
          <LoadFromFile setShowBottomNav={setShowBottomNav} />
        </TabPanel>
        <TabPanel>
          <Box marginTop='24px'>
            <RedirectView setShowBottomNav={setShowBottomNav} text='modelo' />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default Step1;
