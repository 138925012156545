import { TransfersFlow } from '../../Interfaces/transfers';

export const getFlow = (pathname: string) => {
  const path = pathname.split('/');
  const name = path[path.length - 1];
  switch (name) {
    case 'desautorizacion':
      return TransfersFlow.Unauthorize;
    case 'anulacion':
      return TransfersFlow.Annul;
    case 'revocarenvio':
      return TransfersFlow.Revoke;
    case 'autorizacion':
    default:
      return TransfersFlow.Authorize;
  }
};
