/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountingType } from '@interfaces/transfers';
import { ResultStatus } from '@interfaces/transferSetup';
import { getToken } from '@react-ib-mf/style-guide-ui';
import { addParamToQueryString } from '@utils/addParamToQueryString';
import axios from 'axios';
import { DOWNLOAD_FORMAT } from 'src/constants-app';

import {
  AccountBalancesResponse,
  AsyncConfectionResponse,
  AsyncProcessesResponse,
  BankAccountRequestData,
  BankAccountsResponse,
  BanksResponse,
  ConceptsResponse,
  ConfectionRequestData,
  ConfectionService,
  DataReadyUpdateTransfer,
  DocumentsToPay,
  FieldTransferTypeResponse,
  HolidaysResponse,
  ModifyTransferResponse,
  OfferingResponse,
  paramsGetLots,
  paramsGetModifyTransfers,
  paramsGetTransfersDetail,
  paramsLotDetail,
  PostDataAuthPartial,
  PostDataTransfersAction,
  ResponseAsyncProcess,
  ResponsePostTransfersAction,
  RevokeData,
  sendTef,
  TransfersActionResponse,
  TransferTypesResponse,
  TypesRetention,
  UsageReferencesResponse,
  WorkingDayResponse,
} from '../Interfaces/api';
import { ResponseDataLotAndTransfers } from '../Interfaces/contextTypes';
import { ServiceAction } from '../Interfaces/transfers';

const newAxios = axios.create({
  baseURL: process.env.TRANSFERS_BASE_URL,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: `${process.env.API_USER_KEY}` },
  timeout: 60000,
});

const getLots = (params: paramsGetLots) => newAxios.get('lots', { params });

const getLotDetail = ({ lot, date, bank, status, params }: paramsLotDetail) =>
  newAxios.get(`lots/${lot}/${date}/${bank}/${status}`, { params });

const getPermissions = () => newAxios.get(`permissions`);

const getMacKeys = (pathParams: any) => newAxios.get(`mackey/entity?${pathParams}`);

const postSendTefs = (params: sendTef) => newAxios.post(`transfer/operator/send`, { transfer: params });

const getNameCompany = () => newAxios.get(`mackey/entity/validate`);

const getSubscriberId = () => newAxios.get(`clients/info`);

const getDetailsOfAllTransfers = (params: ResponseDataLotAndTransfers[], action: string) =>
  newAxios.post(`/transfers/details`, { lots: params, action });

const getTransfersDetail = ({ ordenId, params }: paramsGetTransfersDetail) =>
  newAxios.get(`transfers/${ordenId}`, { params: params || false });

const getAsyncProcess = (action: ServiceAction, processId: number | null) =>
  newAxios.get<ResponseAsyncProcess>(`transfer/${action}/async/${processId}`);

const getParamsProsegur = () => newAxios.get('parameters');

const getParamsHolidays = () => newAxios.get<HolidaysResponse>('parameters/holidays/upcoming?countryId=AR&weekend=Y');

const getParamsWorkingDay = () => newAxios.get<WorkingDayResponse>('parameters/workingDay');

const postTransfersAction = (action: ServiceAction, data: PostDataTransfersAction) =>
  newAxios.post<ResponsePostTransfersAction>(`transfers/${action}`, data);

const getOfferingDetail = () => newAxios.get<OfferingResponse>(`/clients/offerings`);

const postAuthPartialTransfers = (params: PostDataAuthPartial) => newAxios.post(`/lots/processDetails`, { transfer: params });

const getTransferType = () => newAxios.get<FieldTransferTypeResponse>(`/transfers/enabledTefTypes`);

const getTransferTypes = () => newAxios.get<TransferTypesResponse>('/transfer/types');

const getUsageReferences = (transferType: string) =>
  newAxios.get<UsageReferencesResponse>(`accounts/usagesReferences?transferType=${transferType}&accountType=A`);

const getBankAccounts = ({
  accountingType,
  transferType,
  usageReference = [],
  bankCode = [],
  accountSelected = '',
  currency = '',
  filterData = '',
  page = 1,
  size = 50,
}: BankAccountRequestData) => {
  const queryString = [`tefTypeCodes=${transferType}`];

  if (usageReference?.length > 0) {
    const encodedUsageReferences = usageReference.map(referenceItem => encodeURIComponent(referenceItem));
    addParamToQueryString('useReference', encodedUsageReferences, queryString);
  }

  addParamToQueryString('bankCode', bankCode, queryString);

  currency && queryString.push(`currency=${currency}`);
  accountSelected && queryString.push(`accountSelected=${accountSelected}`);
  filterData && queryString.push(`suggester=${encodeURIComponent(filterData)}`);
  queryString.push(`page=${page}`);
  queryString.push(`size=${size}`);
  return newAxios.get<BankAccountsResponse>(`accounts/v2/${accountingType}Accounts?${queryString.join('&')}`);
};

const getDebitAccounts = (params: BankAccountRequestData) => getBankAccounts({ ...params, accountingType: AccountingType.debit });

const getCreditAccounts = (params: BankAccountRequestData) => getBankAccounts({ ...params, accountingType: AccountingType.credit });

const getBanks = (accountingType: AccountingType, transferType: string, currency?: string) => {
  const currencyString = currency ? `&currency=${currency}` : '&currency=ARS&currency=USD';
  return newAxios.get<BanksResponse>(`banks/${accountingType}Bank?transferType=${transferType}${currencyString}`);
};

const getDebitBanks = (transferType: string) => getBanks(AccountingType.debit, transferType);

const getCreditBanks = (transferType: string, currency?: string) => getBanks(AccountingType.credit, transferType, currency);

const getAccountBalances = (cbu: string) => newAxios.get<AccountBalancesResponse>(`accounts/accountBalances?cbu=${cbu}`);

const getConcepts = () => newAxios.get<ConceptsResponse>('parameters/concepts/thirdparties');

const getListModifiedTransfers = ({ transfer, ...params }: paramsGetModifyTransfers) =>
  newAxios.post(`transfer/listConfectionModify`, { transfer }, { params });

const getDocumentsToPay = () => newAxios.get<DocumentsToPay>('/transfer/DocumentsCanceledSuppliers');

const getTypesRetention = (retentionState: string, authorizationState: string) =>
  newAxios.get<TypesRetention>(
    `/parameters/suppliers/retention/type?retentionState=${retentionState}&authorizationState=${authorizationState}`,
  );

const postConfection = (service: ConfectionService, data: ConfectionRequestData) =>
  newAxios.post<TransfersActionResponse>(`transfer/${service}`, data);

const getAsyncProcesses = () => newAxios.get<AsyncProcessesResponse>('asynchronousprocess');

const getAsyncConfection = (processId: number | string) =>
  newAxios.get<AsyncConfectionResponse>(`asynchronousconfection?processId=${processId}`);

const putModifytransfer = (service: string, data: DataReadyUpdateTransfer) =>
  newAxios.put<ModifyTransferResponse>(`transfers/MOD/${service}`, data);

const getDownloadFileTransfersAsync = (status: ResultStatus, formatFile: DOWNLOAD_FORMAT, processNumber: string) =>
  newAxios.get<string>(`asynchronousfilesprocess/${status}/${formatFile}/${processNumber}`);

const revokeTransfers = (data: RevokeData) => newAxios.post<TransfersActionResponse>('transfer/revoke', data);

export {
  getAccountBalances,
  getAsyncConfection,
  getAsyncProcess,
  getAsyncProcesses,
  getConcepts,
  getCreditAccounts,
  getCreditBanks,
  getDebitAccounts,
  getDebitBanks,
  getDetailsOfAllTransfers,
  getDocumentsToPay,
  getDownloadFileTransfersAsync,
  getListModifiedTransfers,
  getLotDetail,
  getLots,
  getMacKeys,
  getNameCompany,
  getOfferingDetail,
  getParamsHolidays,
  getParamsProsegur,
  getParamsWorkingDay,
  getPermissions,
  getSubscriberId,
  getTransfersDetail,
  getTransferType,
  getTransferTypes,
  getTypesRetention,
  getUsageReferences,
  postAuthPartialTransfers,
  postConfection,
  postSendTefs,
  postTransfersAction,
  putModifytransfer,
  revokeTransfers,
};
