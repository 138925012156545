/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import IconCancelModal from '@components/Icons/icon-cancel-modal';
import { AccountingType, Currencies } from '@interfaces/transfers';
import { useWizard, WizardComponent } from '@wow/wizard';
import { format, parse } from 'date-fns';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Plus } from 'react-feather';
import StickyBox from 'react-sticky-box';

import AdditionalInformationForm from '../../../../components/AdditionalInformationForm';
import CardAccount from '../../../../components/CardAccount';
import { CardAccountMode } from '../../../../components/CardAccount/constants';
import IconBeware from '../../../../components/Icons/icon-beware';
import IconDisconnected from '../../../../components/Icons/icon-disconnected';
import CustomModal from '../../../../components/Modal';
import SkeletonForm from '../../../../components/SkeletonForm';
import TransferDataSection from '../../../../components/TransferDataSection';
import ViewNoResult from '../../../../components/ViewNoResult';
import { useTransferCredit } from '../../../../hooks/useTransferCredit2';
import { useTransferDebit } from '../../../../hooks/useTransferDebit2';
import { useTransferSetupStep2 } from '../../../../hooks/useTransferSetupStep2';
import { BankAccount } from '../../../../Interfaces/api';
import { LotTransfer } from '../../../../Interfaces/transferSetup';
import AccountsDrawer from './components/AccountsDrawer';
import CardAccountEmpty from './components/CardAccountEmpty';
import SkeletonAccordion from './components/SkeletonAccordion';
import TransferAccordion from './components/TransferAccordion';
import { DRAWER_MAX_ACCOUNTS, ids, TestIds, texts, WIZARD_IDS, WIZARD_STEPS } from './constants';
import { BoxLeft, BoxRight, BtnAddTransfer, Container, ContainerRelative, CustomBoxTransferTypeTitle } from './index.styled';
import { Props } from './interfaces';

function Step2({ handleFormSubmit }: Props) {
  const [transferSelected, setTransferSelected] = useState<LotTransfer | null>(null);
  const debitAccountSelectedRef = useRef<string | null>(null) as MutableRefObject<string | null>;
  const setDebitAccountSelectedRef = (value: string) => {
    debitAccountSelectedRef.current = value;
  };
  const [enabledAccountSelection, setEnabledAccountSelection] = useState<boolean>(false);

  const {
    creditPage,
    creditHasMore,
    creditDrawerIsOpen,
    creditAccountSelectedId,
    creditAccountSelected,
    creditAccounts,
    creditAccountsLoading,
    creditAccountsError,
    creditAccountsFiltered,
    creditAccountsFilteredLoading,
    creditAccountsFilteredError,
    creditBanks,
    creditBanksLoading,
    creditBanksError,
    creditBankSelected,
    creditFilterData,
    sameAccountSelectedCredit,
    creditFiltersApplied,
    creditReferenceOptionsList,
    isAnyCreditFilterApplied,
    creditBankOptionsList,
    setCreditFiltersApplied,
    loadCreditAccounts,
    loadCreditAccountsFilteredMore,
    loadCreditBanks,
    setCreditAccountsLoading,
    setCreditBanksLoading,
    setCreditAccountSelected,
    setCreditAccountSelectedId,
    setCreditBanksCurrencyARS,
    setCreditBanksCurrencyUSD,
    setCreditCurrencySelected,
    handleSelectCreditAccount,
    handleRemoveSelectedCreditAccount,
    handleSelectCreditBank,
    setCreditFilterData,
    loadCreditAccountsFiltered,
    handleSearchCreditAccount,
    changeCreditBanksCurrency,
    resetCreditAccountFilters,
    setCreditUsageReferences,
    setSameAccountSelectedCredit,
    openCreditDrawer,
    closeCreditDrawer,
    fetchCreditBanks,
  } = useTransferCredit({
    transferSelected,
    debitAccountSelectedRef,
    enabledAccountSelection,
    setTransferSelected,
  });

  const {
    debitBankOptionsList,
    debitPage,
    debitHasMore,
    debitDrawerIsOpen,
    debitAccounts,
    debitAccountsLoading,
    debitAccountsError,
    debitAccountSelectedId,
    debitAccountSelected,
    debitAccountChanged,
    debitAccountsFiltered,
    debitAccountsFilteredLoading,
    debitAccountsFilteredError,
    debitBanks,
    debitBanksLoading,
    debitBanksError,
    debitBanksCurrencyARS,
    debitBankSelected,
    debitCurrencySelected,
    debitCurrencyChanged,
    debitFilterData,
    sameAccountSelectedDebit,
    debitFiltersApplied,
    debitReferenceOptionsList,
    isAnyDebitFilterApplied,
    setDebitFiltersApplied,
    loadDebitAccounts,
    loadDebitAccountsFiltered,
    loadDebitAccountsFilteredMore,
    loadDebitBanks,
    setDebitUsageReferences,
    setDebitAccountsLoading,
    setDebitBanksLoading,
    setDebitCurrencySelected,
    handleSelectDebitAccount,
    handleChangeDebitCurrency,
    setDebitFilterData,
    handleSearchDebitAccount,
    resetDebitAccountFilters,
    setSameAccountSelectedDebit,
    openDebitDrawer,
    closeDebitDrawer,
  } = useTransferDebit({
    transferSelected,
    creditAccountSelected,
    enabledAccountSelection,
    setTransferSelected,
    changeCreditBanksCurrency,
    setCreditCurrencySelected,
    setCreditBanksCurrencyARS,
    setCreditBanksCurrencyUSD,
    resetCreditAccountFilters,
    setCreditAccountSelected,
    setCreditAccountSelectedId,
    setDebitAccountSelectedRef,
    loadCreditAccountsFiltered,
    fetchCreditBanks,
  });

  const {
    allFetchesComplete,
    isAnyServiceFailed,
    transferType,
    manualTransferData,
    holidays,
    transferData,
    transferAmountString,
    conceptsListOptions,
    disclaimerConceptText,
    isTERProccess,
    isTransferDataSectionVisible,
    conceptListLoading,
    additionalInformation,
    isPROVProcessSelected,
    retentionTypeListOptions,
    documentToPayListOptions,
    totalRetentionAmountString,
    creditNoteAmountString,
    showRemoveTransferModal,
    isValidTransfer,
    transferToRemove,
    onRetryAllServices,
    setTransferData,
    setTransferAmountString,
    onChangeConcept,
    setAdditionalInformation,
    setCreditNoteAmountString,
    setTotalRetentionAmountString,
    handleAddTransfer,
    handleEditTransfer,
    handleShowRemoveTransferModal,
    onCancelRemoveTransfer,
    handleEditTransferCancel,
    onRemoveTransfers,
    handleEditTransferSave,
  } = useTransferSetupStep2({
    transferSelected,
    debitAccounts,
    debitAccountsLoading,
    debitAccountsError,
    debitBanksLoading,
    debitBanksError,
    debitAccountSelected,
    debitAccountSelectedId,
    debitBanksCurrencyARS,
    creditAccountSelected,
    creditAccountSelectedId,
    creditAccounts,
    creditAccountsLoading,
    creditAccountsError,
    creditBanksLoading,
    creditBanksError,
    loadDebitAccounts,
    loadDebitBanks,
    setDebitUsageReferences,
    setDebitAccountsLoading,
    setDebitBanksLoading,
    resetDebitAccountFilters,
    handleSelectDebitAccount,
    setDebitCurrencySelected,
    loadCreditAccounts,
    loadCreditBanks,
    setCreditUsageReferences,
    setCreditAccountsLoading,
    setCreditBanksLoading,
    handleSelectCreditAccount,
    setCreditCurrencySelected,
    resetCreditAccountFilters,
    handleRemoveSelectedCreditAccount,
    setTransferSelected,
    setEnabledAccountSelection,
    loadCreditAccountsFiltered,
  });

  const transferNumberSelected = transferToRemove?.transferNumber ?? transferSelected?.transferNumber ?? undefined;

  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();

  const wizardAttempts = Number(localStorage.getItem(WIZARD_IDS.step2Attempts) || '0');

  const isWizardSeen = sessionStorage.getItem(WIZARD_IDS.step2);

  const onCloseWizard = () => {
    sessionStorage.setItem(WIZARD_IDS.step2, 'true');
    localStorage.setItem(WIZARD_IDS.step2Attempts, currentStep + 1 === WIZARD_STEPS.length ? '2' : String(wizardAttempts + 1));
  };

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFormSubmit();
  };

  const handleModalSameAccountCancel = () => {
    setSameAccountSelectedDebit(false);
    setSameAccountSelectedCredit(false);
  };

  const handleModifyDebitAccount = () => {
    closeCreditDrawer();
    openDebitDrawer();
  };

  useEffect(() => {
    if (allFetchesComplete && !isWizardSeen && wizardAttempts < 2) {
      setInitialized(true);
    }
  }, [allFetchesComplete]);

  if (isAnyServiceFailed) {
    return (
      <ViewNoResult
        title={texts.serviceErrorMessageTitle}
        description={texts.serviceErrorMessageSubtitle}
        linkName={texts.linkText}
        Icon={IconDisconnected}
        onClickLink={onRetryAllServices}
      />
    );
  }

  return (
    <Box marginTop='8px'>
      <Box sx={CustomBoxTransferTypeTitle}>
        <Text as='span' textStyle='bodyMd'>
          {texts.transferType}
          {transferType?.description}
        </Text>
      </Box>
      <Box
        sx={{ ...Container, ...(initialized ? ContainerRelative : {}) }}
        data-testid={`${TestIds.transferContainer}-${
          transferSelected ? transferSelected.transferNumber : manualTransferData.transfers.length + 1
        }`}
      >
        {allFetchesComplete ? (
          <StickyBox>
            <Box sx={BoxLeft}>
              <Flex as='form' flexDirection='column' onSubmit={handleSubmit} data-testid={TestIds.form}>
                <Box id={TestIds.transferAccounts} margin='-16px -20px 48px' padding='16px 20px'>
                  <Box marginBottom='24px'>
                    <Text as='span' textStyle='titleMdBold'>
                      {texts.debitAccount}
                    </Text>
                  </Box>
                  <Box marginBottom='48px'>
                    {debitAccountSelected || transferSelected?.debitAccount ? (
                      <CardAccount
                        selected
                        account={(debitAccountSelected || transferSelected?.debitAccount) as BankAccount}
                        accountingType={AccountingType.debit}
                        transferType={manualTransferData.transferType}
                        handleRemoveSelectedAccount={openDebitDrawer}
                        mode={debitAccounts.length === 1 ? CardAccountMode.unique : CardAccountMode.selected}
                        autoWidth
                      />
                    ) : (
                      <CardAccountEmpty accountingType={AccountingType.debit} onClickSearch={openDebitDrawer} />
                    )}
                  </Box>
                  <Box marginBottom='24px'>
                    <Text as='span' textStyle='titleMdBold'>
                      {texts.creditAccount}
                    </Text>
                  </Box>
                  <Box>
                    {creditAccountSelected || transferSelected?.creditAccount ? (
                      <CardAccount
                        selected
                        account={(creditAccountSelected || transferSelected?.creditAccount) as BankAccount}
                        accountingType={AccountingType.credit}
                        transferType={manualTransferData.transferType}
                        handleRemoveSelectedAccount={openCreditDrawer}
                        mode={creditAccounts.length === 1 ? CardAccountMode.unique : CardAccountMode.selected}
                        autoWidth
                      />
                    ) : (
                      <CardAccountEmpty accountingType={AccountingType.credit} onClickSearch={openCreditDrawer} />
                    )}
                  </Box>
                </Box>
                <Box id={TestIds.transferData} margin='-16px -20px 96px' padding='16px 20px'>
                  <Box marginBottom='48px'>
                    {isTransferDataSectionVisible && (
                      <Flex flexDirection='column' marginBottom='24px'>
                        <Box marginBottom='24px'>
                          <Text as='span' textStyle='titleMdBold'>
                            {texts.transferAccount}
                          </Text>
                        </Box>
                        <TransferDataSection
                          amount={transferAmountString}
                          isLocalCurrency={debitCurrencySelected === Currencies.ARS}
                          selectedDate={transferData?.date ? parse(transferData?.date, 'yyyy-MM-dd', new Date()) : new Date()}
                          maxDays={Number(transferType?.deferMaxDays)}
                          isLoadingComplete={allFetchesComplete && !conceptListLoading}
                          onChangeDate={date => setTransferData({ ...transferData, date: format(date, 'yyyy-MM-dd') })}
                          holidays={holidays}
                          onChangeObservationInput={observation => setTransferData({ ...transferData, observation })}
                          observation={transferData.observation}
                          onChangeAmountInput={amount => setTransferAmountString(amount)}
                          conceptOptions={conceptsListOptions}
                          concept={transferData.concept}
                          onChangeConcept={concept => onChangeConcept(concept)}
                          reason={transferData.reason}
                          onChangeReasonInput={reason => setTransferData({ ...transferData, reason })}
                          voucher={transferData.voucher}
                          onChangeVoucherInput={voucher => setTransferData({ ...transferData, voucher })}
                          isFullForm={isTERProccess}
                          disclaimerText={disclaimerConceptText}
                          isDisclaimerChecked={transferData.isDisclaimerChecked}
                          onChangeDisclaimer={isDisclaimerChecked => setTransferData({ ...transferData, isDisclaimerChecked })}
                        />
                      </Flex>
                    )}
                    <Text as='span' textStyle='bodyMdSemi'>
                      {texts.disclaimer}
                    </Text>
                  </Box>
                  {isPROVProcessSelected && (
                    <Flex flexDirection='column' marginBottom='48px'>
                      <AdditionalInformationForm
                        retentionAmount={totalRetentionAmountString}
                        retentionType={additionalInformation.retentionType}
                        retentionOptions={retentionTypeListOptions}
                        isLoadingComplete={allFetchesComplete}
                        document={additionalInformation.document}
                        documentOptions={documentToPayListOptions}
                        creditNoteAmount={creditNoteAmountString}
                        creditNoteNumber={String(additionalInformation.creditNoteNumber || '')}
                        numberClient={String(additionalInformation.numberClient)}
                        documentNumber={String(additionalInformation.documentNumber)}
                        paymentOrderType={additionalInformation.paymentOrderType}
                        paymentOrderNumber={additionalInformation.paymentOrderNumber}
                        onChangeNumberClient={numberClient => setAdditionalInformation({ ...additionalInformation, numberClient })}
                        onChangeRetentionType={retentionType => setAdditionalInformation({ ...additionalInformation, retentionType })}
                        onChangeDocument={document => setAdditionalInformation({ ...additionalInformation, document })}
                        onChangeDocumentNumber={documentNumber => setAdditionalInformation({ ...additionalInformation, documentNumber })}
                        onChangePaymentOrderType={paymentOrderType =>
                          setAdditionalInformation({ ...additionalInformation, paymentOrderType: String(paymentOrderType) })
                        }
                        onChangePaymentOrderNumber={paymentOrderNumber =>
                          setAdditionalInformation({ ...additionalInformation, paymentOrderNumber })
                        }
                        onChangeCreditNoteNumber={creditNoteNumber =>
                          setAdditionalInformation({ ...additionalInformation, creditNoteNumber: Number(creditNoteNumber) })
                        }
                        onChangeCreditNoteAmount={creditNoteAmount => setCreditNoteAmountString(creditNoteAmount)}
                        onChangeTotalRetentionAmount={retentionAmount => setTotalRetentionAmountString(retentionAmount)}
                      />
                    </Flex>
                  )}
                  <Box>
                    {transferSelected ? (
                      <Flex flexDirection='row'>
                        <Button
                          variant='primary-text'
                          size='lg'
                          onClick={handleEditTransferCancel}
                          padding={0}
                          marginRight='24px'
                          data-testid={TestIds.btnCancel}
                        >
                          {texts.btnCancel}
                        </Button>
                        <Button
                          variant='primary-outline'
                          size='lg'
                          onClick={handleEditTransferSave}
                          isDisabled={!isValidTransfer}
                          data-testid={TestIds.btnSave}
                        >
                          {texts.btnSave}
                        </Button>
                      </Flex>
                    ) : (
                      <Button
                        variant='primary-outline'
                        size='lg'
                        onClick={handleAddTransfer}
                        leftIcon={<Icon as={Plus} size={24} />}
                        sx={BtnAddTransfer}
                        isDisabled={!isValidTransfer}
                        data-testid={TestIds.btnAdd}
                      >
                        {texts.btnAddTransfer}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Flex>
            </Box>
          </StickyBox>
        ) : (
          <SkeletonForm />
        )}
        {allFetchesComplete ? (
          <Box sx={BoxRight}>
            <Box id={TestIds.transferSummary} margin='-12px' padding='12px'>
              <TransferAccordion
                handleEditTransfer={handleEditTransfer}
                handleRemoveTransfer={handleShowRemoveTransferModal}
                transferSelectedNumber={transferNumberSelected}
              />
            </Box>
          </Box>
        ) : (
          <SkeletonAccordion />
        )}
      </Box>
      <CustomModal
        isOpen={!!debitCurrencyChanged || !!debitAccountChanged}
        Icon={IconBeware}
        onCancel={() => handleChangeDebitCurrency(false)}
        firstDescription={texts.modalCurrencyChangeDescription}
        title={texts.modalCurrencyChangeTitle}
        enableFirstButton
        firstTextButton={texts.btnCancel}
        enableSecondButton
        secondTextButton={texts.modalCurrencyBtnConfirm}
        actionButton2={() => handleChangeDebitCurrency(true)}
        alternativeButtonStyling
        size='xl'
        newStyle
      />
      <CustomModal
        isOpen={sameAccountSelectedDebit || sameAccountSelectedCredit}
        Icon={IconCancelModal}
        onCancel={handleModalSameAccountCancel}
        firstDescription={texts.modalSameAccountDescription}
        title={texts.modalSameAccountTitle}
        enableSecondButton
        secondTextButton={texts.btnUnderstood}
        alternativeButtonStyling
        size='xl'
        newStyle
      />
      <CustomModal
        id={ids.modalRemoveTransfer}
        isOpen={showRemoveTransferModal}
        Icon={IconBeware}
        onCancel={() => onCancelRemoveTransfer()}
        firstDescription={texts.modalRemoveTransferDescription}
        title={texts.modalRemoveTransferTitle}
        enableFirstButton
        firstTextButton={texts.btnCancel}
        enableSecondButton
        secondTextButton={texts.modalRemoveTransferbtnTitle}
        actionButton2={() => onRemoveTransfers()}
        alternativeButtonStyling
        size='xl'
        newStyle
      />
      <AccountsDrawer
        isOpen={debitDrawerIsOpen}
        page={debitPage}
        showFilters={debitAccountsFiltered.length > DRAWER_MAX_ACCOUNTS}
        isLoading={debitAccountsFilteredLoading}
        isError={debitAccountsFilteredError}
        transferType={manualTransferData.transferType}
        accountingType={AccountingType.debit}
        accounts={debitAccountsFiltered}
        accountSelectedId={debitAccountSelectedId}
        searchValue={debitFilterData}
        hasMore={debitHasMore}
        loadMore={loadDebitAccountsFilteredMore}
        handleSelectAccount={handleSelectDebitAccount}
        setSearchValue={setDebitFilterData}
        handleSearch={handleSearchDebitAccount}
        onClickRetry={!debitAccountsFiltered.length ? loadDebitAccountsFiltered : loadDebitAccountsFilteredMore}
        onClose={closeDebitDrawer}
        usageReferenceList={debitReferenceOptionsList}
        isAnyFilterAppliedDrawer={isAnyDebitFilterApplied}
        filtersApplied={debitFiltersApplied}
        setFiltersApplied={setDebitFiltersApplied}
        banksList={debitBankOptionsList}
      />
      <AccountsDrawer
        isOpen={creditDrawerIsOpen}
        page={creditPage}
        showFilters={creditAccountsFiltered.length > DRAWER_MAX_ACCOUNTS}
        showAlertCurrency={!!debitAccountSelectedId}
        isLoading={creditAccountsFilteredLoading}
        isError={creditAccountsFilteredError}
        transferType={manualTransferData.transferType}
        accountingType={AccountingType.credit}
        accounts={creditAccountsFiltered}
        accountSelectedId={creditAccountSelectedId}
        searchValue={creditFilterData}
        hasMore={creditHasMore}
        isAnyFilterAppliedDrawer={isAnyCreditFilterApplied}
        filtersApplied={creditFiltersApplied}
        usageReferenceList={creditReferenceOptionsList}
        loadMore={loadCreditAccountsFilteredMore}
        handleSelectAccount={handleSelectCreditAccount}
        setSearchValue={setCreditFilterData}
        handleSearch={handleSearchCreditAccount}
        onClickRetry={!creditAccountsFiltered.length ? loadCreditAccountsFiltered : loadCreditAccountsFilteredMore}
        onClose={closeCreditDrawer}
        setFiltersApplied={setCreditFiltersApplied}
        onClickModify={handleModifyDebitAccount}
        banksList={creditBankOptionsList}
      />
      {initialized && (
        <WizardComponent
          id={WIZARD_IDS.step2}
          steps={WIZARD_STEPS}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          initialized={initialized}
          setInitialized={setInitialized}
          onFinish={onCloseWizard}
          hasWelcome={false}
        />
      )}
    </Box>
  );
}

export default Step2;
