import { isManualTransferFormEmpty } from '@utils/isManualTransferFormEmpty';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { amountRangesValuesDefault, breadcrumbNames, filtersValuesDefault, routesNames } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { ServiceAction } from '../Interfaces/transfers';

export interface Items {
  key: string;
  id: string;
  link: string;
  label: string;
  isCurrentPage?: boolean;
}

const useBreadcrumb = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<Items[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    manualTransferData,
    sectionPermissions,
    setTransfersRejected,
    setTransfersAccepted,
    setLotsSelected,
    setDataLot,
    setInputLot,
    setPageContext,
    setIsExitConfectionModalOpen,
    setSizeContext,
    setDataDetailsTransfers,
    setSelectedTransfersTypesCodes,
    setFiltered,
    setAmountRanges,
    clearConfection,
    setRedirectPath,
  } = useTransfersContext();

  const inConfection = location?.pathname.startsWith(routesNames.TransferSetup);

  const transfersPermissions = [ServiceAction.AUT, ServiceAction.ENV, ServiceAction.ANU, ServiceAction.DES];
  const transfersPermissionsChecks = transfersPermissions.map(permission =>
    sectionPermissions.some(item => item?.section?.includes(permission)),
  );
  const [hasAuthorizePermission, hasSendPermission, hasAnullPermission, hasUnauthorizePermission] = transfersPermissionsChecks;
  const hasTransfersPermission = hasAuthorizePermission || hasSendPermission || hasAnullPermission || hasUnauthorizePermission;

  useEffect(() => {
    const breadcrumbSections = [];
    // add the "Inicio" section directly to the beginning and redirect to root
    const root = breadcrumbNames.ROOT;
    breadcrumbSections.push({
      key: root,
      id: root,
      label: root,
      link: '/',
      onClick: (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (location?.pathname === routesNames.TransferSetup && !isManualTransferFormEmpty(manualTransferData)) {
          setIsExitConfectionModalOpen(true);
          setRedirectPath('/');
          return;
        }
        clearConfection();
        navigate('/');
      },
    });
    const transfers = breadcrumbNames.TRANSFERENCIAS;
    breadcrumbSections.push({
      key: transfers,
      id: transfers,
      label: transfers,
      isCurrentPage: location?.pathname === routesNames.Root,
      link: `${routesNames.Root}`,
      disabled: inConfection && !hasTransfersPermission,
      onClick: (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (location?.pathname.startsWith('/transferencias/resultado')) {
          setDataDetailsTransfers([]);
          setSelectedTransfersTypesCodes([]);
          setLotsSelected([]);
          setDataLot([]);
          setFiltered(filtersValuesDefault);
          setInputLot([]);
          setTransfersAccepted([]);
          setTransfersRejected([]);
          setPageContext(1);
          setSizeContext(15);
          setAmountRanges(amountRangesValuesDefault);
        }
        if (inConfection) {
          if (hasTransfersPermission) {
            if (location?.pathname === routesNames.TransferSetup && !isManualTransferFormEmpty(manualTransferData)) {
              setIsExitConfectionModalOpen(true);
              setRedirectPath(routesNames.Root);
              return;
            }
          } else {
            return;
          }
        }
        clearConfection();
        navigate(`${routesNames.Root}`, { state: { from: location } });
      },
    });
    let name = '';
    switch (location?.pathname) {
      case routesNames.LotDetailsSend:
      case routesNames.LotDetailsAuth:
      case routesNames.LotDetailsUnauth:
      case routesNames.LotDetailsAnnul:
      case routesNames.LotDetailsRevoke:
        name = breadcrumbNames.DETALLE;
        break;
      case routesNames.EntityToken:
        name = breadcrumbNames.AUTORIZAR;
        break;
      case routesNames.EntityKey:
        name = breadcrumbNames.ENVIAR;
        break;
      case routesNames.ResultSend:
      case routesNames.ResultAuth:
      case routesNames.ResultUnauth:
      case routesNames.ResultAnnul:
      case routesNames.ResultRevoke:
        name = breadcrumbNames.RESULTADO;
        break;
      case routesNames.TransferSetup:
      case routesNames.ConfectionInvalidDate:
      case routesNames.ConfectionSummary:
        name = breadcrumbNames.CONFECCION;
        break;
      case routesNames.ConfectionResult: {
        name = breadcrumbNames.RESULTADO;
        const confection = breadcrumbNames.CONFECCION;
        breadcrumbSections.push({
          key: confection,
          id: confection,
          label: confection,
          link: routesNames.TransferSetup,
          isCurrentPage: false,
          onClick: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            clearConfection();
            navigate(routesNames.TransferSetup);
          },
        });
        break;
      }
      case routesNames.Modify:
        name = breadcrumbNames.MODIFY;
        break;
      case routesNames.LotDetailsModify: {
        name = breadcrumbNames.DETALLE;
        const confection = breadcrumbNames.CONFECCION;
        breadcrumbSections.push({
          key: confection,
          id: confection,
          label: confection,
          link: routesNames.TransferSetup,
          isCurrentPage: false,
          onClick: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            clearConfection();
            navigate(routesNames.TransferSetup);
          },
        });
        const confectionResult = breadcrumbNames.RESULTADO;
        breadcrumbSections.push({
          key: confectionResult,
          id: confectionResult,
          label: confectionResult,
          link: routesNames.ConfectionResult,
          isCurrentPage: false,
          onClick: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            navigate(routesNames.ConfectionResult, { state: { from: location, processId: location?.state?.processId } });
          },
        });
        break;
      }
      default:
        break;
    }
    if (name !== '') {
      breadcrumbSections.push({ key: name, id: name, label: name, link: '', isCurrentPage: true });
    }
    setBreadcrumbItems(breadcrumbSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, manualTransferData]);

  return { breadcrumbItems, inConfection, hasTransfersPermission };
};

export default useBreadcrumb;
