interface IconBinocularsProps {
  width?: number;
  height?: number;
}

function IconBinoculars({ width = 120, height = 120 }: IconBinocularsProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 120 120' fill='none'>
      <path d='M73.5 38.499V24C73.5 18.21 78.21 13.5 84 13.5C89.79 13.5 94.5 18.21 94.5 24V38.499H73.5Z' fill='#AAC7FF' />
      <path
        d='M84 15C88.962 15 93 19.038 93 24V36.999H75V24C75 19.038 79.038 15 84 15ZM84 12C77.373 12 72 17.373 72 24C72 24.684 72 39.315 72 39.999H96C96 39.315 96 24.684 96 24C96 17.373 90.627 12 84 12Z'
        fill='#2172DC'
      />
      <path
        d='M70.5 79.383V38.532C70.5 34.281 74.385 25.5 86.391 25.5C97.311 25.5 102.504 33.432 102.558 33.51C102.624 33.681 117.426 64.761 118.446 79.383H70.5Z'
        fill='#D6E2FF'
      />
      <path
        d='M86.391 27C95.766 27 100.509 33.204 101.208 34.197C102.423 36.768 115.023 63.678 116.796 77.886H72V38.532C72 35.541 74.643 27 86.391 27ZM86.391 24C72.963 24 69 34.02 69 38.532C69 42.225 69 54.675 69 80.886H120C120 66.513 103.818 32.706 103.818 32.706C103.818 32.706 98.259 24 86.391 24Z'
        fill='#2172DC'
      />
      <path
        d='M49.5 42.141C51.558 41.598 56.235 40.5 60.363 40.5C64.422 40.5 68.619 41.562 70.5 42.108V66.978C68.253 66.36 64.395 65.502 60.363 65.502C56.208 65.502 51.915 66.417 49.5 67.029V42.141Z'
        fill='#AAC7FF'
      />
      <path
        d='M60.363 42C63.591 42 66.933 42.714 69 43.251V65.055C66.672 64.521 63.57 63.999 60.363 63.999C56.991 63.999 53.538 64.578 51 65.133V43.308C53.277 42.756 57.012 42 60.363 42ZM60.363 39C54.612 39 48 41.001 48 41.001C48 41.001 48 47.373 48 53.001C48 54.42 48 55.791 48 57C48 60.453 48 65.424 48 69C48 69 54.405 66.999 60.363 66.999C66.321 66.999 72 69 72 69C72 65.424 72 60.453 72 57C72 55.791 72 54.417 72 53.001C72 47.373 72 41.001 72 41.001C72 41.001 66.114 39 60.363 39Z'
        fill='#2172DC'
      />
      <path
        d='M94.5 58.5C88.1348 58.5 82.0303 61.0286 77.5294 65.5294C73.0286 70.0303 70.5 76.1348 70.5 82.5C70.5 88.8652 73.0286 94.9697 77.5294 99.4706C82.0303 103.971 88.1348 106.5 94.5 106.5C100.865 106.5 106.97 103.971 111.471 99.4706C115.971 94.9697 118.5 88.8652 118.5 82.5C118.5 76.1348 115.971 70.0303 111.471 65.5294C106.97 61.0286 100.865 58.5 94.5 58.5Z'
        fill='#AAC7FF'
      />
      <path
        d='M94.5 60C106.905 60 117 70.092 117 82.5C117 94.908 106.905 105 94.5 105C82.095 105 72 94.908 72 82.5C72 70.092 82.095 60 94.5 60ZM94.5 57C80.418 57 69 68.418 69 82.5C69 96.582 80.418 108 94.5 108C108.582 108 120 96.582 120 82.5C120 68.418 108.582 57 94.5 57Z'
        fill='#2172DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M96 78C92.6867 78 90 80.6867 90 84C90 87.3137 87.3137 90 84 90C80.6863 90 78 87.3137 78 84C78 74.0593 86.0593 66 96 66C99.3137 66 102 68.6863 102 72C102 75.3137 99.3137 78 96 78Z'
        fill='white'
      />
      <path d='M25.5 38.499V24C25.5 18.21 30.21 13.5 36 13.5C41.79 13.5 46.5 18.21 46.5 24V38.499H25.5Z' fill='#AAC7FF' />
      <path
        d='M36 15C40.962 15 45 19.038 45 24V36.999H27V24C27 19.038 31.038 15 36 15ZM36 12C29.373 12 24 17.373 24 24C24 24.684 24 39.315 24 39.999H48C48 39.315 48 24.684 48 24C48 17.373 42.627 12 36 12Z'
        fill='#2172DC'
      />
      <path
        d='M1.557 79.383C2.577 64.758 17.379 33.675 17.535 33.351C17.655 33.186 22.755 25.5 33.609 25.5C45.615 25.5 49.5 34.278 49.5 38.532V79.386L1.557 79.383Z'
        fill='#D6E2FF'
      />
      <path
        d='M33.609 27C45.357 27 48 35.541 48 38.532V77.886H3.207C4.977 63.678 17.58 36.768 18.795 34.197C19.473 33.231 24.237 27 33.609 27ZM33.609 24C21.741 24 16.179 32.706 16.179 32.706C16.179 32.706 0 66.513 0 80.886H51C51 54.678 51 42.225 51 38.532C51 34.02 47.037 24 33.609 24Z'
        fill='#2172DC'
      />
      <path
        d='M25.5 58.5C19.1348 58.5 13.0303 61.0286 8.52944 65.5294C4.02856 70.0303 1.5 76.1348 1.5 82.5C1.5 88.8652 4.02856 94.9697 8.52944 99.4706C13.0303 103.971 19.1348 106.5 25.5 106.5C31.8652 106.5 37.9697 103.971 42.4706 99.4706C46.9714 94.9697 49.5 88.8652 49.5 82.5C49.5 76.1348 46.9714 70.0303 42.4706 65.5294C37.9697 61.0286 31.8652 58.5 25.5 58.5Z'
        fill='#AAC7FF'
      />
      <path
        d='M25.5 60C37.905 60 48 70.092 48 82.5C48 94.908 37.905 105 25.5 105C13.095 105 3 94.908 3 82.5C3 70.092 13.095 60 25.5 60ZM25.5 57C11.418 57 0 68.418 0 82.5C0 96.582 11.418 108 25.5 108C39.582 108 51 96.582 51 82.5C51 68.418 39.582 57 25.5 57Z'
        fill='#2172DC'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27 78C23.6867 78 21 80.6867 21 84C21 87.3137 18.3137 90 15 90C11.6863 90 9 87.3137 9 84C9 74.0593 17.0593 66 27 66C30.3137 66 33 68.6863 33 72C33 75.3137 30.3137 78 27 78Z'
        fill='white'
      />
    </svg>
  );
}

export default IconBinoculars;
