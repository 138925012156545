// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Inter', sans-serif;
}

.paginator {
  border-top: solid 1px rgba(0, 0, 0, 0.24);
  padding-top: 6px;
  margin-top: 29px;
}

button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

ol, li {
  padding: 0;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.addAnimationSkeletonfadeIn {
  animation: fadeIn ease 2s;
  opacity: 1;
};

.withOutAnimationSkeletonfadeIn {
  animation: none;
  opacity: 0;
};
`, "",{"version":3,"sources":["webpack://./src/styled/index.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,yCAAyC;EACzC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,qBAAqB;EACrB,YAAY;EACZ,SAAS;EACT,UAAU;EACV,cAAc;EACd,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,IAAI,SAAS,CAAC;EACd,MAAM,SAAS,CAAC;AAClB;;AAEA;EACE,IAAI,SAAS,CAAC;EACd,MAAM,SAAS,CAAC;AAClB;;AAEA;EACE,IAAI,SAAS,CAAC;EACd,MAAM,SAAS,CAAC;AAClB;;AAEA;EACE,IAAI,SAAS,CAAC;EACd,MAAM,SAAS,CAAC;AAClB;;AAEA;EACE,IAAI,SAAS,CAAC;EACd,MAAM,SAAS,CAAC;AAClB;;AAEA;EACE,yBAAyB;EACzB,UAAU;AACZ,CAAA;;AAEA;EACE,eAAe;EACf,UAAU;AACZ,CAAA","sourcesContent":["body {\r\n  font-family: 'Inter', sans-serif;\r\n}\r\n\r\n.paginator {\r\n  border-top: solid 1px rgba(0, 0, 0, 0.24);\r\n  padding-top: 6px;\r\n  margin-top: 29px;\r\n}\r\n\r\nbutton {\r\n  appearance: none;\r\n  -webkit-appearance: none;\r\n  -moz-appearance: none;\r\n  border: none;\r\n  margin: 0;\r\n  padding: 0;\r\n  color: inherit;\r\n  font: inherit;\r\n  cursor: pointer;\r\n  background: none;\r\n}\r\n\r\nblockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {\r\n  margin: 0;\r\n}\r\n\r\nol, li {\r\n  padding: 0;\r\n}\r\n\r\n@keyframes fadeIn {\r\n  0% {opacity:0;}\r\n  100% {opacity:1;}\r\n}\r\n\r\n@-moz-keyframes fadeIn {\r\n  0% {opacity:0;}\r\n  100% {opacity:1;}\r\n}\r\n\r\n@-webkit-keyframes fadeIn {\r\n  0% {opacity:0;}\r\n  100% {opacity:1;}\r\n}\r\n\r\n@-o-keyframes fadeIn {\r\n  0% {opacity:0;}\r\n  100% {opacity:1;}\r\n}\r\n\r\n@-ms-keyframes fadeIn {\r\n  0% {opacity:0;}\r\n  100% {opacity:1;}\r\n}\r\n\r\n.addAnimationSkeletonfadeIn {\r\n  animation: fadeIn ease 2s;\r\n  opacity: 1;\r\n};\r\n\r\n.withOutAnimationSkeletonfadeIn {\r\n  animation: none;\r\n  opacity: 0;\r\n};\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
