const HEADER_TEXT = {
  title: 'Transferencias',
  description: 'Gestioná las transferencias pendientes de acuerdo a tus permisos.',
};

export const WizardTransfers = {
  titleTef: '¡Renovamos Transferencias!',
  descTef: 'Hicimos cambios en el diseño para que puedas encontrar rápidamente lo que necesitás.',
  titleSec: 'Secciones de Transferencias',
  descSec: 'En estas pestañas encontrarás todas las acciones que podés hacer en Transferencias.',
  titleLotList: 'Lotes pendientes',
  descLotList: 'En esta sección encontrarás los lotes que podés gestionar.',
  titleLotDetail: 'Detalle de lote',
  descLotDetail: 'Si hacés clic en la lupa de la derecha, encontrarás información ampliada de cada lote.',
  titleLotSearch: 'Filtros de lotes',
  descLotSearch: 'Usá los filtros disponibles y encontrá más rápido las transferencias que necesitás.',
  titleConfirm: 'Confirmar selección',
  descConfirm: 'Seleccioná las transferencias que necesitás e iniciá el proceso desde este botón.',
};

export const EXCEPTION_CODES = {
  timeOut: 'TEF-0015',
};

export default HEADER_TEXT;

export const TEXT_MODAL_ALERT = {
  title: '¿Querés descartar la selección?',
  firstDescription: 'Al salir de esta sección, se borrarán las transferencias que seleccionaste.',
  secondTextButton: 'Si, descartar',
  firstTextButton: 'Cancelar',
  altIcon: 'icon-alert-modal',
};
