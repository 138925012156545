import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useTransfersContext } from '../context/TransfersContext';
import { ServiceAction, TransfersFlow } from '../Interfaces/transfers';
import { getAsyncProcess } from '../services/api';
import { ASYNC_DELAY_RETRY, ASYNC_MAX_RETRIES, EXCEPTION_CODES_PROCESS_ASYNCHRONOUS } from '../views/EntityToken/constants';
import useAuthPartial from './useAuthPartial';

const useAsyncProcess = (
  idProcess: number | null,
  action: ServiceAction,
  flow: TransfersFlow,
  setShowModalSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  reload?: () => void,
) => {
  const navigate = useNavigate();
  const { mutateAuthPartial } = useAuthPartial();
  const { transfersRejected, setTransfersRejected, setTransfersAccepted, setViewPartialRejecteAuth, setLoadingPartialReject } =
    useTransfersContext();
  const [isAsyncProcess, setIsAsyncProcess] = useState(false);
  const [isAsyncProcessDone, setIsAsyncProcessDone] = useState(false);
  const [isAsyncProcessError, setIsAsyncProcessError] = useState(false);
  const [closeModalCompletely, setCloseModalCompletely] = useState(false);
  const [retries, setRetries] = useState(1);
  const timer = useRef<number | null>(null);

  const handleSetAsyncProcess = () => {
    setIsAsyncProcess(true);
    switch (flow) {
      case TransfersFlow.Authorize:
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'Autorizando transferencias',
        });
        break;
      case TransfersFlow.Unauthorize:
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'Quitando autorización de transferencias',
        });
        break;
      case TransfersFlow.Annul:
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'Anulando transferencias',
        });
        break;
      case TransfersFlow.Revoke:
        pushAnalyticsEvent({
          event: 'modal_impression',
          modal_name: 'Revocando el envío de transferencias',
        });
        break;
      default:
        break;
    }
  };

  const { refetch: refetchAsyncProcess, isLoading: isFetchingAsyncProcess } = useQuery(
    ['get-async-process', action, idProcess],
    () => getAsyncProcess(action, idProcess).then(res => res.data),
    {
      enabled: false,
      retry: false,
      onSuccess: response => {
        const exception: string = response?.exception?.code;
        let resultPath;
        switch (flow) {
          case TransfersFlow.Unauthorize:
            resultPath = '/desautorizacion';
            break;
          case TransfersFlow.Annul:
            resultPath = '/anulacion';
            break;
          case TransfersFlow.Revoke:
            resultPath = '/revocarenvio';
            break;
          default:
            resultPath = '/autorizacion';
        }
        if (retries === ASYNC_MAX_RETRIES || EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.processFailedTimeOut === exception) {
          setIsAsyncProcess(false);
          if (!closeModalCompletely) {
            switch (flow) {
              case TransfersFlow.Authorize:
                pushAnalyticsEvent({
                  event: 'modal_impression',
                  modal_name: 'La autorización se está procesando',
                });
                break;
              case TransfersFlow.Unauthorize:
                pushAnalyticsEvent({
                  event: 'modal_impression',
                  modal_name: 'Estamos quitando tu autorización',
                });
                break;
              case TransfersFlow.Annul:
                pushAnalyticsEvent({
                  event: 'modal_impression',
                  modal_name: 'La anulación se está procesando',
                });
                break;
              case TransfersFlow.Revoke:
                pushAnalyticsEvent({
                  event: 'modal_impression',
                  modal_name: 'Estamos revocando el envío',
                });
                break;
              default:
                break;
            }
            setIsAsyncProcessDone(true);
          }
          return;
        }
        if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.processProgress === exception) {
          if (!closeModalCompletely) handleSetAsyncProcess();
          timer.current = setTimeout(() => {
            refetchAsyncProcess();
            setRetries(retries + 1);
          }, ASYNC_DELAY_RETRY) as unknown as number;
          return;
        }
        if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.processFailed === exception) {
          setIsAsyncProcessError(true);
        } else if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.asynchronousProcessNotFound === exception) {
          handleSetAsyncProcess();
          timer.current = setTimeout(() => {
            refetchAsyncProcess();
            setRetries(retries + 1);
          }, ASYNC_DELAY_RETRY) as unknown as number;
        } else if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.satisfactoryProcess === exception) {
          setIsAsyncProcess(false);
          if (transfersRejected.length) {
            setTransfersAccepted(response?.data?.transfersAccepted);
            navigate(`/transferencias/resultado${resultPath}`);
          } else {
            reload && reload();
            if (!closeModalCompletely) {
              setShowModalSuccess(true);
            }
          }
        } else if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.totalRejection === exception) {
          setTransfersRejected([...transfersRejected, ...response.data.transfersRejected]);
          navigate(`/transferencias/resultado${resultPath}`);
        } else if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.partialRejection === exception) {
          switch (flow) {
            case TransfersFlow.Authorize:
              pushAnalyticsEvent({
                event: 'transferencia_autorizada',
                content_group: 'Transferencias - Resumen de autorización',
                status: 'Autorización parcial',
              });
              break;
            case TransfersFlow.Unauthorize:
              pushAnalyticsEvent({
                event: 'autorización_transferencia_quitada',
                content_group: 'Transferencias - Resumen de quitar autorización',
                status: 'Quita parcial',
              });
              break;
            case TransfersFlow.Annul:
              pushAnalyticsEvent({
                event: 'transferencia_anulada',
                content_group: 'Transferencias - Resumen de anulación',
                status: 'Anulación parcial',
              });
              break;
            case TransfersFlow.Revoke:
              pushAnalyticsEvent({
                event: 'envio_revocado_transferencia',
                content_group: 'Transferencias - Resumen de revocar envío',
                status: 'Revocación de envío parcial',
              });
              break;
            default:
              break;
          }
          setTransfersRejected([...transfersRejected, ...response.data.transfersRejected]);
          setTransfersAccepted(response.data.transfersAccepted);
          navigate(`/transferencias/resultado${resultPath}`);
        } else if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.parcialValidation === exception) {
          setTransfersRejected([...transfersRejected, ...response.data.transfersRejected]);
          setViewPartialRejecteAuth(true);
          mutateAuthPartial({ transfer: response.data.transfersValidated[0].numberTransfer });
          setLoadingPartialReject(true);
        } else if (EXCEPTION_CODES_PROCESS_ASYNCHRONOUS.parcialOperation === exception) {
          setTransfersRejected([...transfersRejected, ...response.data.transfersRejected]);
          setTransfersAccepted(response.data.transfersAccepted);
          setViewPartialRejecteAuth(true);
          mutateAuthPartial({ transfer: response.data.transfersValidated[0].numberTransfer });
          setLoadingPartialReject(true);
        } else {
          handleSetAsyncProcess();
        }
      },
      onError: () => {
        if (retries === ASYNC_MAX_RETRIES) {
          setIsAsyncProcess(false);
          if (!closeModalCompletely) {
            setIsAsyncProcessDone(true);
          }
          return;
        }
        handleSetAsyncProcess();
        timer.current = setTimeout(() => {
          refetchAsyncProcess();
          setRetries(retries + 1);
        }, ASYNC_DELAY_RETRY) as unknown as number;
      },
    },
  );

  useEffect(() => {
    setIsAsyncProcess(false);
    setRetries(1);
    if (idProcess) {
      refetchAsyncProcess();
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, [idProcess]);

  return {
    isFetchingAsyncProcess,
    isAsyncProcess,
    isAsyncProcessDone,
    isAsyncProcessError,
    setIsAsyncProcess,
    setIsAsyncProcessDone,
    setIsAsyncProcessError,
    setCloseModalCompletely,
    closeModalCompletely,
    setRetries,
  };
};

export default useAsyncProcess;
