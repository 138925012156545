/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef } from 'react';
import { ChevronDown, X } from 'react-feather';
import Select, { ClearIndicatorProps, components, DropdownIndicatorProps, GroupBase } from 'react-select';

import { SelectOption } from '../../Interfaces/transferSetup';
import { selectCustomStyles } from '../../styled/global.styled';
import CustomSelectProps, { DefaultSelectProps } from './interfaces';

function ClearIndicator(props: ClearIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>) {
  return (
    <components.ClearIndicator {...props}>
      <X size={20} />
    </components.ClearIndicator>
  );
}

function DropdownIndicator(props: DropdownIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  );
}

function CustomSelect({
  id,
  name,
  options,
  placeholder,
  value,
  width,
  status = false,
  isLeftAddon = false,
  isClearable = false,
  isSearchable = true,
  isDisabled = false,
  testId,
  onChange,
  setOptionDisabled,
}: CustomSelectProps) {
  const selectRef = useRef<DefaultSelectProps | null>(null);
  useEffect(() => {
    if (!value) {
      selectRef?.current?.clearValue();
    }
  }, [value]);
  return (
    <Select
      ref={ref => {
        if (ref) {
          selectRef.current = ref as DefaultSelectProps;
        }
      }}
      id={id}
      name={name}
      options={options}
      placeholder={placeholder || undefined}
      onChange={option => (option?.value ? onChange(option as SelectOption) : onChange(null))}
      classNamePrefix='react-select'
      styles={selectCustomStyles(status, width, isLeftAddon)}
      className='single-select'
      menuPlacement='auto'
      value={value ?? null}
      components={{ ClearIndicator, DropdownIndicator }}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isOptionDisabled={option => (option?.value && setOptionDisabled ? setOptionDisabled(option as SelectOption) : false)}
      data-testid={testId}
    />
  );
}

export default CustomSelect;
