import { colors } from '../../styled/generalStyles';

const CustomMargin = {
  margin: '0px',
  fontSize: '12px',
  whiteSpace: 'pre-wrap',
};

const CustomTooltip = {
  background: colors.Grey120,
  borderRadius: '8px',
  color: colors.White,
  padding: '8px 10px',
  placement: 'right',
  marginLeft: '-11px',
};

export { CustomMargin, CustomTooltip };
