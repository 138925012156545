import { colors } from '../../styled/generalStyles';

export const CalendarStyle = {
  width: '20px',
  height: '22px',
  color: colors.Secondary70,
};

export const InputGroupStyles = {
  width: '261px',
  height: '36px',
  gap: '10px',
};

export const InputGroupDrawerStyles = {
  width: '268px',
  height: '36px',
  gap: '10px',
};

export const inputStyle = {
  pl: '45px',
  height: '100%',
  cursor: 'default',
};

export const inputLeftElementStyle = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  zIndex: '1',
};

export const BatchDataStyle = {
  textStyle: 'titleMdBold',
  margin: '0px',
};

export const checkboxContainerStyles = {
  alignItems: 'center',
  gap: '12px',
};
