const convertAmountNumberToText = (amount: number): string => {
  if (amount === 0) {
    return '';
  }

  return amount.toLocaleString('es-ar', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export { convertAmountNumberToText };
