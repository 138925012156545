import {
  BinocularsDrawerTitleText,
  ConfectionDescriptionText,
  ConfectionTitleText,
  CustomAltBox,
  CustomBox,
  CustomBoxConfection,
  CustomBoxDrawerBinoculars,
  CustomBoxDrawerDisconnected,
  CustomTransferDetailDrawer,
  DefaultDescriptionText,
  DefaultTitleText,
  DisconnectDrawerTitleText,
  DrawerDescriptionText,
  RetryLinkStyle,
} from '../components/ViewNoResult/styled';
import { InterfacesPropsUseViewNoResultStyles, InterfacesPropsViewNoResultStyles } from '../Interfaces/hooks/IUseViewNoResultStyles';

const useViewNoResultStyles = ({
  iconDisconnectDrawer,
  drawerIcon,
  nothingHere,
  transferDetailDrawer,
  isConfection,
  isConfectionDrawer,
}: InterfacesPropsUseViewNoResultStyles): InterfacesPropsViewNoResultStyles => {
  switch (true) {
    case isConfection:
      return {
        containerStyle: CustomBoxConfection,
        customTitleText: ConfectionTitleText,
        customDescriptionText: ConfectionDescriptionText,
        iconWidth: '80px',
        iconHeight: '80px',
        retryLinkStyle: RetryLinkStyle,
      };
    case isConfectionDrawer:
      return {
        containerStyle: CustomBox,
        customTitleText: ConfectionTitleText,
        customDescriptionText: ConfectionDescriptionText,
        iconWidth: '80px',
        iconHeight: '80px',
        retryLinkStyle: RetryLinkStyle,
      };
    case iconDisconnectDrawer:
      return {
        containerStyle: CustomBoxDrawerDisconnected,
        customTitleText: DisconnectDrawerTitleText,
        customDescriptionText: DrawerDescriptionText,
        iconWidth: 76,
        iconHeight: 76,
        retryLinkStyle: RetryLinkStyle,
      };
    case drawerIcon:
      return {
        containerStyle: CustomBoxDrawerBinoculars,
        customTitleText: BinocularsDrawerTitleText,
        customDescriptionText: DrawerDescriptionText,
        iconWidth: '80px',
        iconHeight: '80px',
        retryLinkStyle: RetryLinkStyle,
      };
    case nothingHere:
      return {
        containerStyle: CustomAltBox,
        customTitleText: DefaultTitleText,
        customDescriptionText: DefaultDescriptionText,
        iconWidth: '120px',
        iconHeight: '120px',
        retryLinkStyle: {},
      };
    case transferDetailDrawer:
      return {
        containerStyle: CustomTransferDetailDrawer,
        customTitleText: DefaultTitleText,
        customDescriptionText: DefaultDescriptionText,
        iconWidth: '120px',
        iconHeight: '120px',
        retryLinkStyle: {},
      };
    default:
      return {
        containerStyle: CustomBox,
        customTitleText: DefaultTitleText,
        customDescriptionText: DefaultDescriptionText,
        iconWidth: '120px',
        iconHeight: '120px',
        retryLinkStyle: {},
      };
  }
};

export default useViewNoResultStyles;
