import { Box, Button, Text } from '@chakra-ui/react';
import { filtersAppliedDrawerDefault } from '@src/constants-app';

import FilterItem from './components/FilterItem';
import { IDS, TEXTS } from './constants';
import { FilterType, Props } from './interfaces';
import { CustomBox } from './styled';

function FilterAccountsDrawer({
  isLoading = false,
  accountingType,
  usageReferenceList,
  filterOptionsApplied,
  banksList,
  isEmptyResult,
  setFilterOptionsApplied,
  onApplyFilters,
}: Props) {
  const updateAppliedUsageReferenceFilterList = (newListSelectedReferences: string[]): void => {
    setFilterOptionsApplied({
      ...filterOptionsApplied,
      usageReferences: newListSelectedReferences,
    });
  };

  const updateAppliedBankFilterList = (newListSelectedBanks: string[]): void => {
    setFilterOptionsApplied({
      ...filterOptionsApplied,
      banks: newListSelectedBanks,
    });
  };

  const onResetFilters = (): void => {
    setFilterOptionsApplied(filtersAppliedDrawerDefault);
    onApplyFilters();
  };

  return (
    <CustomBox id={`${accountingType}${IDS.filterAccountsDrawer}`} data-testid={`${accountingType}${IDS.filterAccountsDrawer}`}>
      <Box className='contentTextFilter'>
        <Text textStyle='labelMdSemi' as='span'>
          {TEXTS.title}
        </Text>
      </Box>
      <Box className='contentOptionsFilter'>
        {usageReferenceList.length > 0 && (
          <FilterItem
            filterType={FilterType.usageReferences}
            isLoading={isLoading}
            options={usageReferenceList}
            appliedOptions={filterOptionsApplied.usageReferences}
            setAppliedOptions={updateAppliedUsageReferenceFilterList}
            onApplyFilters={onApplyFilters}
            isEmptyResult={isEmptyResult}
          />
        )}
        {banksList.length > 0 && (
          <FilterItem
            filterType={FilterType.banks}
            isLoading={isLoading}
            options={banksList}
            appliedOptions={filterOptionsApplied.banks}
            setAppliedOptions={updateAppliedBankFilterList}
            onApplyFilters={onApplyFilters}
            isEmptyResult={isEmptyResult}
          />
        )}
      </Box>
      <Box className='contentClearFilters'>
        <Button
          variant='primary-text'
          size='md'
          onClick={onResetFilters}
          padding='0px'
          isDisabled={isLoading || (!filterOptionsApplied.usageReferences.length && !filterOptionsApplied.banks.length)}
        >
          {TEXTS.clear}
        </Button>
      </Box>
    </CustomBox>
  );
}

export default FilterAccountsDrawer;
