import { Flex, Skeleton } from '@chakra-ui/react';

import { TestIds } from './constants';
import { BoxSkeletonLeft, SkeletonBlock1, SkeletonBlock2, SkeletonBody, SkeletonHeader, SkeletonHeader2 } from './index.styled';

function SkeletonForm() {
  return (
    <Flex direction='column' sx={BoxSkeletonLeft} data-testid={TestIds.skeletonForm}>
      <Flex direction='column' gap='24px'>
        <Skeleton style={SkeletonHeader} />
        <Skeleton style={SkeletonBody} />
      </Flex>
      <Flex direction='column' gap='24px'>
        <Flex alignItems='center' justifyContent='space-between'>
          <Skeleton style={SkeletonHeader} />
          <Skeleton style={SkeletonHeader2} />
        </Flex>
        <Skeleton style={SkeletonBody} />
      </Flex>
      <Flex direction='column' gap='24px'>
        <Skeleton style={SkeletonHeader} />
        <Flex alignItems='center' justifyContent='space-between'>
          <Skeleton style={SkeletonBlock1} />
          <Skeleton style={SkeletonBlock1} />
        </Flex>
        <Skeleton style={SkeletonBlock2} />
      </Flex>
    </Flex>
  );
}

export default SkeletonForm;
