import { colors } from '../../styled/generalStyles';

const card = {
  alignItems: 'normal',
  borderColor: colors.White,
};

const cardContent = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '3px',
  flex: '1 0 0',
  marginLeft: '16px',
  minHeight: '92px',
};

const cardSection = {
  paddingBottom: '8px',
};

const skeletonAvatar = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
};

const skeletonTitle = {
  width: '110px',
  height: '15px',
};

const skeletonSubtitle = {
  width: '300px',
  height: '24px',
};

const skeletonDetail = {
  width: '270px',
  height: '20px',
};

const skeletonIcon = {
  width: '48px',
  height: '48px',
  borderRadius: '50%',
};

const skeletonFooter = {
  width: '100%',
  height: '40px',
};

export { card, cardContent, cardSection, skeletonAvatar, skeletonDetail, skeletonFooter, skeletonIcon, skeletonSubtitle, skeletonTitle };
