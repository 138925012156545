import { ResultError, ResultErrorCode, ResultErrorCodeId, ResultErrorIcon } from '../../Interfaces/transferSetup';

const texts = {
  title: 'Resultado de la confección',
  validatedSubtitle:
    'Algunas transferencias tienen errores y no se pueden confeccionar. Podés continuar con las transferencias que están <b>Por confeccionar</b>.',
  success: 'Éxito',
  pending: 'Por confeccionar',
  error: 'Error',
  acceptedTitle: (transfers: number, lotNumber: number) =>
    `${transfers} transferencia${transfers > 1 ? 's' : ''} se confeccion${transfers > 1 ? 'aron' : 'ó'} con éxito con el lote ${lotNumber}`,
  acceptedDescription: 'Podés descargar el detalle para ver los números de transferencia.',
  rejectedTitle: (transfers: number) =>
    `${transfers} transferencia${transfers > 1 ? 's' : ''} no se confeccion${transfers > 1 ? 'aron' : 'ó'}`,
  rejectedDescription: 'Descargá el detalle para encontrar más información y volvé a intentarlo desde <b>Confeccionar</b>.',
  validatedTitle: (transfers: number) =>
    `${transfers} transferencia${transfers > 1 ? 's' : ''} pendiente${transfers > 1 ? 's' : ''} por confeccionar`,
  validatedDescription: `No se terminaron de confeccionar porque son parte de un lote con <b>envío unificado</b> y otras transferencias tienen error.<br>Podés terminar de confeccionar solo estas transferencias y descartar las que tienen error.`,
  date: 'Fecha de solicitud',
  transfers: (count: number) => `${count} transferencia${count > 1 ? 's' : ''}`,
  total: 'Total a debitar de la cuenta',
  download: 'Descargar',
  format: 'Formato',
  txt: '.txt',
  csv: '.csv',
  viewDetail: 'Ver detalle de lote',
  goToConfection: 'Confeccionar otro lote',
  goToTransfers: 'Ir a Transferencias',
  separator: ';;;;;;;;;;;;;',
  acceptedFileName: 'ConfeccionTEF_Exito_',
  rejectedFileName: 'ConfeccionTEF_Error_',
  validatedFileName: 'ConfeccionTEF_PorConfeccionar_',
  cancel: 'Cancelar',
  retry: 'Reintentar',
  continue: 'Sí, continuar',
  preparePending: 'Confeccionar pendientes',
  loadingTitle: 'Estamos confeccionando las transferencias',
  loadingDescription: 'Esto puede demorar unos instantes.',
  errorTitle: 'No podemos confeccionar el lote',
  errorDescription: '¡No te preocupes! Las transferencias agregadas se mantienen, solo tenés que volver a intentarlo.',
  pendingModalTitle: '¿Querés confeccionar solo las\ntransferencias pendientes?',
  pendingModalDescription:
    'Si continuás, las transferencias que tienen error se descartarán y se<br>confeccionará el lote solo con las pendientes.',
  serviceErrorTitle: 'La información no está disponible en este momento',
  serviceErrorDescription: 'Estamos trabajando para solucionarlo.',
  ok: 'Entendido',
};

const testIds = {
  result: 'result',
  resultItem: 'resultItem',
  skeleton: 'skeleton',
  csvAccepted: 'csvAccepted',
  txtAccepted: 'txtAccepted',
  csvRejected: 'csvRejected',
  txtRejected: 'txtRejected',
};

const GENERIC_ERROR: ResultError = {
  code: ResultErrorCode.TEF_GENERIC_ERROR,
  icon: ResultErrorIcon.alertTriangle,
  description: 'El sistema no está disponible en este momento.',
};

const CONFECTION_ERRORS: ResultError[] = [
  GENERIC_ERROR,
  {
    code: ResultErrorCode.TEF_CON_NOT_AUTHORIZED,
    errors: [ResultErrorCodeId.transfConfeccionErrorNoTienePermisoEspecifico],
    icon: ResultErrorIcon.user,
    description: 'No tenés permisos para confeccionar este tipo de transferencias',
  },
  {
    code: ResultErrorCode.TEF_CON_DATE_ERROR,
    errors: [
      ResultErrorCodeId.transfConfErrorFechaSolicitud,
      ResultErrorCodeId.transfConfErrorFechaSolicitudAnterior,
      ResultErrorCodeId.tefGestionErrorFSolicitudVencida,
      ResultErrorCodeId.tefGestionErrorFechaSolicitudNoEsDiaHabil,
      ResultErrorCodeId.tefGestionErrorDiasDiferimientoMin,
      ResultErrorCodeId.tefTratamErrorFSolicitudNoEsDiaHabil,
    ],
    icon: ResultErrorIcon.calendar,
    description: 'La fecha de solicitud que elegiste no está habilitada',
  },
  {
    code: ResultErrorCode.TEF_CON_SERVICE_NOT_ENABLED,
    errors: [ResultErrorCodeId.transfConfErrorServicioNoHabilitado],
    icon: ResultErrorIcon.user,
    description: 'No tenés habilitado el servicio para confeccionar transferencias de este tipo',
  },
  {
    code: ResultErrorCode.TEF_CON_DEBIT_ACCOUNT_ERROR,
    errors: [
      ResultErrorCodeId.tefGestionErrorCtaControlDebitoNoOperativa,
      ResultErrorCodeId.tefGestionErrorCtaDebHa,
      ResultErrorCodeId.tefGestionErrorCuentaDebitoUsoHabilitado,
      ResultErrorCodeId.tefGestionErrorCuentaInhabilitadaDebito,
      ResultErrorCodeId.tefGestionErrorCuentaParteNoHabilitada,
      ResultErrorCodeId.tefGestionErrorCtaParteNoTef,
    ],
    icon: ResultErrorIcon.alertTriangle,
    description: 'La cuenta de débito no está habilitada',
  },
  {
    code: ResultErrorCode.TEF_CON_CREDIT_ACCOUNT_ERROR,
    errors: [
      ResultErrorCodeId.tefGestionErrorCtaControlCreditoNoOperativa,
      ResultErrorCodeId.tefGestionErrorCtaCredHa,
      ResultErrorCodeId.tefGestionErrorCuentaCreditoUsoHabilitado,
      ResultErrorCodeId.tefGestionErrorCuentaContraparteNoHabilitada,
    ],
    icon: ResultErrorIcon.alertTriangle,
    description: 'La cuenta de crédito no está habilitada',
  },
  {
    code: ResultErrorCode.TEF_CON_NOT_OPERATOR_BANK,
    errors: [ResultErrorCodeId.tefGestionErrorOperadorNoOperaConBanco],
    icon: ResultErrorIcon.user,
    description: 'No tenés la habilitación necesaria para operar con este banco',
  },
  {
    code: ResultErrorCode.TEF_CON_NOT_OPERATOR_DEBIT_ACCOUNT,
    errors: [ResultErrorCodeId.tefGestionErrorOpeCtaParteDebito, ResultErrorCodeId.tefGestionErrorOperadorModoUsoDebito],
    icon: ResultErrorIcon.user,
    description: 'No tenés la habilitación necesaria para operar con la cuenta de débito',
  },
  {
    code: ResultErrorCode.TEF_CON_NOT_OPERATOR_CREDIT_ACCOUNT,
    errors: [ResultErrorCodeId.tefGestionErrorOpeCtaParteCredito, ResultErrorCodeId.tefGestionErrorOperadorModoUsoCredito],
    icon: ResultErrorIcon.user,
    description: 'No tenés la habilitación necesaria para operar con la cuenta de crédito',
  },
  {
    code: ResultErrorCode.TEF_CON_DEBIT_ACCOUNT_USE_ERROR,
    errors: [ResultErrorCodeId.tefGestionErrorCuentaModoUsoDebito],
    icon: ResultErrorIcon.alertTriangle,
    description: 'Revisá los usos de cuenta de débito en el módulo ABM o Reportes',
  },
  {
    code: ResultErrorCode.TEF_CON_CREDIT_ACCOUNT_USE_ERROR,
    errors: [ResultErrorCodeId.tefGestionErrorCuentaModoUsoCredito],
    icon: ResultErrorIcon.alertTriangle,
    description: 'Revisá los usos de cuenta de crédito en el módulo ABM o Reportes',
  },
  {
    code: ResultErrorCode.TEF_CON_TEF_NOT_EXIST,
    errors: [ResultErrorCodeId.offlineTefTipoTefInexistente],
    icon: ResultErrorIcon.transfer,
    description: 'El tipo de transferencia que elegiste no existe',
  },
  {
    code: ResultErrorCode.TEF_CON_TEF_ERROR,
    errors: [ResultErrorCodeId.tefGestionErrorOpeNoTef],
    icon: ResultErrorIcon.user,
    description: 'Esta cuenta no permite realizar transferencias',
  },
  {
    code: ResultErrorCode.TEF_CON_BANK_CREDIT_ACCOUNT_NOT_ACCEPTED_DIFF,
    errors: [ResultErrorCodeId.tefTratamErrorNoSePuedeTratarBcoCredNoAceptaDif],
    icon: ResultErrorIcon.bank,
    description: 'El banco de crédito no acepta transferencias con esa fecha de solicitud',
  },
  {
    code: ResultErrorCode.TEF_NEW_CREDITACCOUNT,
    errors: [ResultErrorCodeId.tefGestionErrorCtaCreditoNuevaNoSuperaTiempEspera],
    icon: ResultErrorIcon.alertTriangle,
    description: 'Cuenta de crédito no disponible. Podés utilizar la cuenta de crédito después de 24 horas de su activación.',
  },
  {
    code: ResultErrorCode.TEF_UNRELATED_ACCOUNT,
    errors: [ResultErrorCodeId.tefGestionErrorRelacionesCuenta],
    icon: ResultErrorIcon.alertTriangle,
    description: 'Las cuentas no están relacionadas, revisá la configuración en ABM.',
  },
  {
    code: ResultErrorCode.TEF_NON_ENABLED_SCHEDULE,
    errors: [ResultErrorCodeId.tefGestionErrorHorarioHaDebito, ResultErrorCodeId.tefGestionErrorHorarioHaCredito],
    icon: ResultErrorIcon.alertTriangle,
    description: 'Fecha de solicitud no válida',
  },
];

const downloadSuccessfulModal = {
  id: 'toast-dowload-success',
  title: 'Descargaste el archivo con éxito',
};

const downloadFailedModal = {
  id: 'toast-dowload-fail',
  title: 'No podemos descargar el archivo.',
  textButon: 'Reintentar',
};

const downloadInfoModal = {
  id: 'toast-dowload-info',
  title: 'Estamos descargando el archivo.',
};

export { CONFECTION_ERRORS, downloadFailedModal, downloadInfoModal, downloadSuccessfulModal, GENERIC_ERROR, testIds, texts };
