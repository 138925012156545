import { Box, Flex, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';

import { formatAmount } from '../../../../../../utils/formatAmount';
import { TEST_IDS, TEXTS } from '../../constants';
import { formatAccountData, formatAccountName, tooltipAccount } from './helpers';
import { Container } from './index.styled';
import { Props } from './interfaces';

function SummaryItem({ data, index }: Props) {
  return (
    <Tr sx={Container} data-testid={`${TEST_IDS.summaryItem}${index + 1}`}>
      <Td width='25%'>
        <Tooltip label={tooltipAccount(TEXTS.debitAccount, data.debitAccount)} hasArrow placement='right'>
          <Box>
            <Text as='div' textStyle='labelLgBold'>
              {TEXTS.debitAccount} {data.debitAccount?.bank.bankName}
            </Text>
            <Text as='div' textStyle='bodyMd'>
              {formatAccountName(data.debitAccount?.accountName, data.debitAccount?.cuit)}
              <br />
              {formatAccountData(data.debitAccount?.cbu, data.debitAccount?.accountType)}
            </Text>
          </Box>
        </Tooltip>
      </Td>
      <Td width='5%' />
      <Td width='25%'>
        <Tooltip label={tooltipAccount(TEXTS.creditAccount, data.creditAccount)} hasArrow placement='right'>
          <Box>
            <Text as='div' textStyle='labelLgBold'>
              {TEXTS.creditAccount} {data.creditAccount?.bank.bankName}
            </Text>
            <Text as='div' textStyle='bodyMd'>
              {formatAccountName(data.creditAccount?.accountName, data.creditAccount?.cuit)}
              <br />
              {formatAccountData(data.creditAccount?.cbu, data.creditAccount?.accountType)}
            </Text>
          </Box>
        </Tooltip>
      </Td>
      <Td width='5%' />
      <Td width='20%' textAlign='right'>
        <Text as='div' textStyle='labelLgBold'>
          {TEXTS.amount}
        </Text>
        <Text as='div' textStyle='bodyMd'>
          {formatAmount(data.debitAccount?.currency || 'ARS', data.transferData.amount, '')}
        </Text>
      </Td>
      <Td width='5%' />
      <Td width='15%'>
        <Flex direction='row-reverse'>
          <Flex direction='column'>
            <Text as='div' textStyle='labelLgBold'>
              {TEXTS.requestDate}
            </Text>
            <Text as='div' textStyle='bodyMd'>
              {format(parseISO(data.transferData.date), 'dd/MM/yyyy')}
            </Text>
          </Flex>
        </Flex>
      </Td>
    </Tr>
  );
}

export default SummaryItem;
