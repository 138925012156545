import * as React from 'react';

function IconCancelModal() {
  return (
    <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M38.0001 73.15C18.6182 73.15 2.8501 57.3819 2.8501 38C2.8501 18.6181 18.6182 2.85 38.0001 2.85C57.382 2.85 73.1501 18.6181 73.1501 38C73.1501 57.3819 57.382 73.15 38.0001 73.15Z'
        fill='#F9ADAD'
      />
      <path
        d='M37.9999 3.8C56.8574 3.8 72.1999 19.1425 72.1999 38C72.1999 56.8575 56.8574 72.2 37.9999 72.2C19.1424 72.2 3.7999 56.8575 3.7999 38C3.7999 19.1425 19.1424 3.8 37.9999 3.8ZM37.9999 1.9C18.0632 1.9 1.8999 18.0633 1.8999 38C1.8999 57.9367 18.0632 74.1 37.9999 74.1C57.9366 74.1 74.0999 57.9367 74.0999 38C74.0999 18.0633 57.9366 1.9 37.9999 1.9Z'
        fill='#D71111'
      />
      <path d='M26.5801 53.4501L22.5496 49.4195L49.4202 22.5499L53.4506 26.5805L26.5801 53.4501Z' fill='#D71111' />
      <path d='M22.5493 26.5805L26.5798 22.5499L53.4503 49.4195L49.4198 53.4501L22.5493 26.5805Z' fill='#D71111' />
      <path
        d='M53.4509 26.581L49.4191 22.5492L38.0001 33.9701L26.5811 22.5492L22.5493 26.581L33.9702 38L22.5493 49.419L26.5811 53.4508L38.0001 42.0299L49.4191 53.4508L53.4509 49.419L42.03 38L53.4509 26.581Z'
        fill='#D71111'
      />
    </svg>
  );
}
export default IconCancelModal;
