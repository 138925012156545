/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-prototype-builtins */
import { decrypt } from '@react-ib-mf/style-guide-ui';

import { macKeys, sendTef } from '../Interfaces/api';
import { BankPassword, MacKeys, ResponseEndpointDetailsTransfers } from '../Interfaces/entityKey';
import { TransferOperationDetails } from '../Interfaces/transfers';
import { BANK_CODE } from '../views/EntityKey/constants';
import MessageMacError from '../views/EntityKey/utils/messageMacError';
import { generateMac } from './desEncrypter';
import { checkDigito } from './validarMac';

const SortTransfers = (
  responseGetMacKeys: ResponseEndpointDetailsTransfers | null,
  responseOfTransferSelectedByUser: TransferOperationDetails[],
  password: string,
  bankCodes: string[],
  macsByBanks?: BankPassword[],
  subscriberId?: string,
  isAnticipate?: boolean | null,
  hasNextTefDay?: boolean,
  isOutOfTime?: boolean | null,
  flagModal?: boolean,
) => {
  const ListKeys: macKeys[] = [];
  const ListKeysFiltered: macKeys[] = [];
  const OrderedTransfers: sendTef[] = [];
  const OrderedTransfersFilterdByValidMac: sendTef[] = [];
  let MessageError: string[] = [];
  let Count = 0;

  responseGetMacKeys?.macKeys?.forEach((element: MacKeys) => {
    const item = { bankCode: element.bankCode, bankName: element.bankName, macKey: decrypt(element.macKeyData, password) };
    ListKeysFiltered.push({ ...item });
    const objectMacKey = [...ListKeysFiltered];
    const objetosConMacKey = objectMacKey?.filter((list: macKeys) => list.macKey !== '');

    ListKeys.push(...objetosConMacKey);
  });

  if (macsByBanks) {
    macsByBanks?.forEach((element: BankPassword) => {
      const isValidlastDigit = checkDigito(element.value, element.key, subscriberId);
      const item = { bankCode: element.key, bankName: element.value.substr(8), macKey: element.value.substr(0, 8), isValidlastDigit };
      ListKeysFiltered.push({ ...item });
      const objectMacKey = [...ListKeysFiltered];
      const objetosConMacKey = objectMacKey?.filter((list: macKeys) => list.macKey !== '');
      ListKeys.push(...objetosConMacKey);
    });
  }

  if ((responseGetMacKeys || macsByBanks) && ListKeys.length > 0) {
    const macBankCodes = ListKeys.map((macKey: macKeys) => macKey.bankCode);
    const missingBankCodes = bankCodes?.filter((bankCode: string) => !macBankCodes?.includes(bankCode));
    const existInBankCodeAll = missingBankCodes?.every((code: any) => BANK_CODE?.hasOwnProperty(code));
    MessageError = MessageMacError(missingBankCodes, existInBankCodeAll);
    Count = responseOfTransferSelectedByUser?.filter((obj: TransferOperationDetails) => missingBankCodes.includes(obj.bankCode)).length;
    const transfersReadyTosend = responseOfTransferSelectedByUser?.filter((obj: TransferOperationDetails) =>
      macBankCodes.includes(obj.bankCode),
    );

    transfersReadyTosend.forEach(
      ({ transfer, type, macMessage, step, lot, createAt, unifiedSend, bankCode, canInmediate }: TransferOperationDetails) => {
        const MAC = ListKeys?.find(({ bankCode: bankCodeListKey }: macKeys) => bankCodeListKey === bankCode);
        OrderedTransfers.push({
          orderId: transfer,
          typeTransferCode: type,
          mac1: generateMac(macMessage, MAC && MAC.macKey),
          numberStep: step,
          numberLot: lot,
          dateCreation: createAt,
          unifiedShipping: unifiedSend,
          ...(flagModal && canInmediate ? { immediately: isAnticipate ? 'Y' : 'N' } : {}),
        });

        if (macsByBanks && MAC?.isValidlastDigit) {
          OrderedTransfersFilterdByValidMac.push({
            orderId: transfer,
            typeTransferCode: type,
            mac1: generateMac(macMessage, MAC && MAC.macKey),
            numberStep: step,
            numberLot: lot,
            dateCreation: createAt,
            unifiedShipping: unifiedSend,
            ...(flagModal && canInmediate ? { immediately: isAnticipate ? 'Y' : 'N' } : {}),
          });
        }
      },
    );
  } else {
    const existInBankCodeAll = true;
    Count = responseOfTransferSelectedByUser.length;
    MessageError = MessageMacError(bankCodes, existInBankCodeAll);
  }

  const TEF_UNASSOCIATED_MAC_KEY = {
    message: 'TEF_UNASSOCIATED_MAC_KEY',
    totalTransferByMessage: Count,
    text: MessageError,
  };

  return { OrderedTransfers, TEF_UNASSOCIATED_MAC_KEY, OrderedTransfersFilterdByValidMac, ListKeys };
};

export default SortTransfers;
