const MODIFY_DRAWER_TEXTS = {
  Tooltip: 'Esta es la descripción del lote',
  DateFormat: 'yyyy-MM-dd',
  TempAccount: 'Sueldos',
  TransferTypeTitle: 'Tipo de transferencia: ',
  LotNumberTitle: 'Número de lote: ',
  LotNumberTemp: '123',
  TempDescription: 'Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
  TransferDataTitle: 'Datos de la transferencia',
  ObligatoryData: '*Estos datos son obligatorios.',
  Confirm: 'Guardar',
  Cancel: 'Cancelar',
  alertText1: 'Tené en cuenta que solo te mostraremos las cuentas de crédito disponibles según la moneda de la cuenta de débito.',
  creditInfoText: 'Solo podés cambiar a otra cuenta bancaria. ¡Pronto podrás agregar un CVU!',
  buttonDiscard: 'Descartar cambios',
};

const getTransferText = (transferNumber: string | number): string => `Modificar transferencia nº ${transferNumber}`;

const IDS_TOAS = {
  toastSaveSuccess: 'toast-save-success',
  toastDeleteSuccess: 'toast-delete-success',
  modalRemoveTransfer: 'modal-remove-transfer',
};

const TEXT_TOAS = {
  toastTextSaveSuccess: 'Modificaste la transferencia con éxito.',
  toastTextNotSavePermissions: 'No podemos modificar la transferencia.',
};

export { getTransferText, IDS_TOAS, MODIFY_DRAWER_TEXTS, TEXT_TOAS };
