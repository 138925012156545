import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Icon, Text } from '@chakra-ui/react';
import { Badge } from '@wow/badge';
import { pushAnalyticsEvent } from '@wow/utils';
import { useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import BaseScreen from '../../components/BaseScreen';
import Footer from '../../components/Footer';
import { amountRangesValuesDefault, filtersValuesDefault, routesNames } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import styles from '../Result/index.styled';
import ResultListItem from './Components/ResultListItem';
import { MODAL_ERRORS_TRANSFERS_ALERT } from './constants';

function SendResult() {
  const navigate = useNavigate();
  const {
    transfersRejected,
    transfersAccepted,
    setTransfersRejected,
    setTransfersAccepted,
    setLotsSelected,
    setDataLot,
    setInputLot,
    setPageContext,
    setSizeContext,
    setSelectedTransfersTypesCodes,
    setAmountRanges,
    setFiltered,
  } = useTransfersContext();

  useEffect(() => {
    if (transfersRejected.length <= 0 && transfersAccepted.length <= 0) {
      navigate(routesNames.Root);
    }
    return () => {
      setTransfersAccepted([]);
      setTransfersRejected([]);
    };
  }, []);

  const countRejectedTransfers = [...new Set([...transfersRejected])];
  const countRejected = countRejectedTransfers.reduce((accumulator, currentValue) => accumulator + currentValue.totalTransferByMessage, 0);
  const countAccepted = transfersAccepted.reduce((accumulator, currentValue) => accumulator + currentValue.totalTransfer, 0);

  const handleRedirect = () => {
    pushAnalyticsEvent({
      event: 'select_content',
      content_type: 'Botones modal transferencias enviadas',
      item_id: 'Ir a pendientes de envío',
    });
    setLotsSelected([]);
    setDataLot([]);
    setTransfersAccepted([]);
    setTransfersRejected([]);
    setSelectedTransfersTypesCodes([]);
    setFiltered(filtersValuesDefault);
    setInputLot([]);
    setPageContext(1);
    setSizeContext(15);
    setAmountRanges(amountRangesValuesDefault);
    navigate(routesNames.Root);
  };

  return (
    <BaseScreen title='Resultado del envío' sizeTitle='h1' id='result'>
      {countAccepted > 0 && (
        <Box sx={styles.listHeaderSuccessStyle}>
          <Box marginRight='38'>
            <Badge variant='success-light'>Éxito</Badge>
          </Box>
          <Box sx={styles.boxStyle}>
            <Text textStyle='h5Bold'>
              {countAccepted === 1 ? '1 transferencia se envió' : `${countAccepted} transferencias se enviaron`}
            </Text>
          </Box>
        </Box>
      )}
      <Accordion
        allowToggle
        defaultIndex={0}
        sx={{
          ...styles.accordion,
          marginTop: countAccepted ? '0px' : '12px',
        }}
      >
        <AccordionItem sx={{ ...styles.accordionItem, ...(countAccepted > 0 ? styles.accordionItemWithoutBorder : {}) }}>
          {({ isExpanded }) => (
            <>
              <AccordionButton sx={styles.accordionButton}>
                <Flex sx={styles.accordionButtonMain}>
                  <Flex sx={styles.badge}>
                    <Badge variant='error-light'>Error</Badge>
                  </Flex>
                  <Flex sx={styles.accordionButtonTexts}>
                    <Text textStyle='h5Bold'>
                      {countRejected === 1 ? '1 transferencia no se envió' : `${countRejected} transferencias no se enviaron`}
                    </Text>
                    <Text
                      textStyle='labelMd'
                      dangerouslySetInnerHTML={{ __html: `Podés volver a intentarlo desde la sección <strong>Enviar</strong>.` }}
                    />
                  </Flex>
                </Flex>
                <Icon as={isExpanded ? ChevronUp : ChevronDown} sx={styles.chevron} />
              </AccordionButton>
              <AccordionPanel sx={styles.accordionPanel}>
                <ResultListItem transfersRejected={transfersRejected} modalErrorsTransfersAlert={MODAL_ERRORS_TRANSFERS_ALERT} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <Footer statusButtom={false} onSubmit={handleRedirect} textButton='Ir a Transferencias' />
    </BaseScreen>
  );
}

export default SendResult;
