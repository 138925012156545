import { Box, Flex, Text } from '@chakra-ui/react';
import { Info, ShoppingBag } from 'react-feather';

import { CURRENCY_DOLAR } from '../../constants-app';
import { decimalFormat, formatNumber } from '../../utils/FormatChange';
import IconAlertIco from '../IconAlert';
import { InterfacesPropsCustomSummary } from './interfaces';
import { BoldStyles, BoxStyles, LotsInfoBoxStyles, NormalStyles, Separator } from './styled';

function CustomSummary({
  title,
  totalTransfersArs,
  totalAmountArs,
  totalTransfersUsd,
  totalAmountUsd,
  noPermits,
  id,
}: InterfacesPropsCustomSummary) {
  return (
    <Flex sx={LotsInfoBoxStyles} id={id}>
      {(totalTransfersArs > 0 || totalTransfersUsd > 0) && title && (
        <Text as='span' sx={BoldStyles} textStyle='labelLgBold'>
          {title}
        </Text>
      )}
      {totalTransfersArs >= 1 && (
        <>
          <Box sx={BoxStyles}>
            <Info />
          </Box>
          <Text as='span' sx={NormalStyles} textStyle='labelLg'>{`${formatNumber(totalTransfersArs)} ${
            totalTransfersArs >= 2 ? 'transferencias' : 'transferencia'
          }`}</Text>
          <Box sx={BoxStyles}>
            <ShoppingBag />
          </Box>
          <Text as='span' textStyle='labelLg'>
            {totalAmountArs === 0 ? '$ ***' : `$ ${decimalFormat(totalAmountArs)}`}
          </Text>
          {noPermits && totalAmountArs !== 0 && <IconAlertIco />}
        </>
      )}
      {totalTransfersArs >= 1 && totalTransfersUsd >= 1 && (
        <Box sx={Separator}>
          <Text as='span' textStyle='labelLg'>
            |
          </Text>
        </Box>
      )}
      {totalTransfersUsd >= 1 && (
        <>
          <Box sx={BoxStyles}>
            <Info />
          </Box>
          <Text as='span' sx={NormalStyles} textStyle='labelLg'>{`${formatNumber(totalTransfersUsd)} ${
            totalTransfersUsd >= 2 ? 'transferencias' : 'transferencia'
          }`}</Text>
          <Box sx={BoxStyles}>
            <ShoppingBag />
          </Box>
          <Text as='span' textStyle='labelLg'>
            {totalAmountUsd === 0 ? `${CURRENCY_DOLAR} ***` : `${CURRENCY_DOLAR} ${decimalFormat(totalAmountUsd)}`}
          </Text>
        </>
      )}
    </Flex>
  );
}

export default CustomSummary;
