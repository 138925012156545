import { SystemStyleObject } from '@chakra-ui/react';

import { colors } from '../../../../styled/generalStyles';

const CustomBox: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  height: '64px',
  alignItems: 'center',
  padding: '0 24px 0 16px',
};

const withBorder: SystemStyleObject = {
  borderTop: `1px solid ${colors.Grey60}`,
};

export { CustomBox, withBorder };
