import {
  amountRangesValuesDefault,
  filtersAppliedDrawerDefault,
  filtersValuesDefault,
  orderAppliedValueDefault,
  orderPropertyLotDetail,
  orderPropertyValueDefault,
} from '../constants-app';
import { ContextAction, ContextState } from '../Interfaces/contextTypes';
import { actionsTypes } from './constants';

const reducer = (state: ContextState, action: ContextAction) => {
  switch (action.type) {
    case actionsTypes.SET_STATE:
      return {
        ...state,
        [action.target]: action.payload,
      };
    case actionsTypes.CLEAR_SELECTED_LOTS:
      return {
        ...state,
        pageContext: 1,
        sizeContext: 15,
        lotsSelected: [],
        dataLot: [],
        inputLot: [],
        filtered: filtersValuesDefault,
        dataDetailsTransfers: [],
        dataTotalsTransfers: [],
        transfersAccepted: [],
        transfersRejected: [],
        orderPropertyContext: orderPropertyValueDefault,
        orderAppliedContext: orderAppliedValueDefault,
        orderPropertyLotDetailContext: orderPropertyLotDetail,
        selectedTransfersTypesCodes: [],
        amountRanges: amountRangesValuesDefault,
        transferNumber: '',
      };
    case actionsTypes.CLEAR_CONFECTION:
      return {
        ...state,
        transferTypeOptionSelected: {
          calendarDefer: 0,
          deferMaxDays: 0,
          code: '',
          description: '',
        },
        manualTransferData: {
          transferType: '',
          requestDate: null,
          description: '',
          paymentNumber: '',
          consolidated: false,
          unifiedShipping: false,
          hasDraft: null,
          transfers: [],
          step: 1,
          accountsNotAvailable: null,
        },
        confectionData: {
          status: false,
          operationId: '',
          processId: 0,
          acceptedTransfers: [],
          rejectedTransfers: [],
          rejectedTransferDetails: [],
          validateTransfers: [],
        },
      };
    case actionsTypes.CLEAR_DRAWER_CONFECTION_FILTERS_APPLIED:
      return {
        ...state,
        debitFiltersApplied: filtersAppliedDrawerDefault,
        creditFiltersApplied: filtersAppliedDrawerDefault,
      };
    default:
      return state;
  }
};

export default reducer;
