import { colors } from '../../../../../../styled/generalStyles';

const Base = {
  width: '100%',
  borderRadius: '4px',
};

const BoxSkeleton = {
  width: '608px',
  borderRadius: '12px 0px 0px 0px',
  backgroundColor: colors.White,
  boxShadow: colors.ShadowMd,
};

const SkeletonCircle = {
  width: '25.5px',
  height: '25.5px',
  borderRadius: '100%',
};

const SkeletonHeader1 = {
  ...Base,
  width: '189px',
  height: '30px',
};

const SkeletonHeader2 = {
  ...Base,
  width: '306px',
  height: '30px',
};

const SkeletonBody = {
  ...Base,
  height: '68px',
};

const SkeletonFooter = {
  ...Base,
  height: '20px',
};

export { BoxSkeleton, SkeletonBody, SkeletonCircle, SkeletonFooter, SkeletonHeader1, SkeletonHeader2 };
