import { Text } from '@chakra-ui/react';

import CustomDescription from './styled';

interface Props {
  description: string;
}

function Header({ description }: Props) {
  return (
    <Text textStyle='titleSm' sx={CustomDescription}>
      {description}
    </Text>
  );
}

export default Header;
