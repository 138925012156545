import { ARS_CURRENCY, CURRENCY_DOLAR, CURRENCY_PESOS, TRANSFER_TYPE_CODE } from '../constants-app';
import { decimalFormat } from './FormatChange';

export const formatAmount = (currency: string, totalAmount: number, lotTransferTypeCode: string): string => {
  const currencySymbol = currency === ARS_CURRENCY ? CURRENCY_PESOS : CURRENCY_DOLAR;

  let amount = '';

  if (lotTransferTypeCode === TRANSFER_TYPE_CODE.SUE) {
    amount = totalAmount ? decimalFormat(totalAmount) : '***';
  } else {
    amount = decimalFormat(totalAmount) || '';
  }

  return `${currencySymbol} ${amount}`;
};
