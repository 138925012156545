/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Text, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { IconButtonComponent } from '@wow/button';
import { pushAnalyticsEvent } from '@wow/utils';
import { useRef } from 'react';
import { Layers, ZoomIn } from 'react-feather';
import { Link } from 'react-router-dom';

import CheckboxCell from '../../components/CheckboxCell';
import CheckboxHeader from '../../components/CheckboxHeader';
import CustomText from '../../components/CustomText';
import { routesNames } from '../../constants-app';
import { useTransfersContext } from '../../context/TransfersContext';
import { Lot } from '../../Interfaces/transfers';
import { colors } from '../../styled/generalStyles';
import { CustomMarginText } from '../../styled/global.styled';
import { hasMaxLengthFunction } from '../../utils/configTableFunctions';
import { formatAmount } from '../../utils/formatAmount';
import { ALL_STATUS_TRANSFERS, DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH } from '../AuthorizationTransfers/constants';
import {
  TEXT_TOOLTIP_ICON,
  TEXT_TOOLTIP_LOT,
  TEXT_TOOLTIP_QUANTITY,
  TEXT_TOOLTIP_UNIFIED_SHIPPING,
  TITLE_TOOLTIP_UNIFIED_SHIPPING,
} from './constants';
import {
  ColorFontsHeaderTable,
  CustomBoxStyle,
  CustomMargin,
  CustomTooltipDebitStyle,
  CustomTooltipStyle,
  SeparatorDebitAccount,
} from './index.styled';

const columnHelper = createColumnHelper<Lot>();

export const COLUMNS = [
  columnHelper.accessor('requestDate', {
    id: 'select',
    enableSorting: false,
    header: ({ table }) => <CheckboxHeader table={table} />,
    cell: ({ row }) => <CheckboxCell row={row} />,
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => `${row.requestDate}`, {
    id: 'fechaSolicitud',
    header: () => (
      <Tooltip label='Fecha de solicitud' color={colors.White} background={colors.Grey120} borderRadius='8px' placement='top' hasArrow>
        <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
          Fecha
        </Text>
      </Tooltip>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm'>
        {`${row?.original?.requestDate}`.split('-').reverse().join('/')}
      </Text>
    ),
    footer: info => info?.column?.id,
  }),
  columnHelper.accessor('lotNumber', {
    id: 'numeroLote',
    header: () => (
      <>
        <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
          Lote
        </Text>
        <Box />
      </>
    ),
    cell: ({ row }) => (
      <>
        <Box>
          <Text textStyle='bodySm' as='span' margin='0px 4px 0px 0px'>
            {row.original.lotNumber}
          </Text>
          {row?.original?.unifiedShipping === 'Y' && (
            <Tooltip
              sx={CustomTooltipStyle}
              placement='right'
              hasArrow
              label={
                <Box sx={CustomBoxStyle}>
                  <Box>
                    <Text as='span' textStyle='bodySmSemi' sx={CustomMargin}>
                      {TITLE_TOOLTIP_UNIFIED_SHIPPING}
                    </Text>
                    <Text as='span' sx={CustomMargin} textStyle='bodySm'>
                      {TEXT_TOOLTIP_UNIFIED_SHIPPING}
                    </Text>
                  </Box>
                </Box>
              }
            >
              <Layers color={colors.Secondary100} size='16px' />
            </Tooltip>
          )}
        </Box>
        <Box />
      </>
    ),
    footer: info => info?.column?.id,
  }),
  columnHelper.accessor('transferNumbers', {
    id: 'cantidadTransferencias',
    header: () => (
      <Tooltip
        label={
          <Box>
            <CustomText margin='0px' fontSize='12px' text={TEXT_TOOLTIP_QUANTITY} />
            <CustomText margin='0px' fontSize='12px' text={TEXT_TOOLTIP_LOT} />
          </Box>
        }
        hasArrow
        placement='top'
      >
        <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
          Cantidad
        </Text>
      </Tooltip>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm' as='span'>
        {row?.original?.transferNumbers}
      </Text>
    ),
    footer: info => info?.column?.id,
  }),
  columnHelper.accessor('lotTransferTypeDescription', {
    id: 'tipoTransferenciaDescripcion',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Tipo
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm' as='span'>
        {row?.original?.lotTransferTypeDescription}
      </Text>
    ),
    footer: info => info?.column?.id,
  }),
  columnHelper.accessor('debitAccount', {
    id: 'debitAccount',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Cuenta débito
      </Text>
    ),
    cell: ({ row }) => {
      const elemText = useRef<HTMLInputElement | null>(null);
      const hasMaxLength = hasMaxLengthFunction(elemText, DEBIT_ACCOUNT_TOOLTIP_MAX_WIDTH);
      const tooltipLabel = hasMaxLength ? (
        <div>
          <Text textStyle='bodySmSemi'>{row.original.debitAccount.bankName}</Text>
          {row.original.debitAccount.cuit ? (
            <Box>
              <Box>
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.accountName}
                </Text>
                {row.original.debitAccount.accountName && <span>{' | '}</span>}
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.cuit}
                </Text>
              </Box>
              <Box>
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.accountNumber}
                </Text>
                {(row.original.debitAccount.cbu || row.original.debitAccount.accountNumber) && <span>{' | '}</span>}
                <Text as='span' sx={CustomMarginText}>
                  {row.original.debitAccount.accountType}
                </Text>
              </Box>
            </Box>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      );
      return (
        <Tooltip label={tooltipLabel} sx={CustomTooltipDebitStyle} hasArrow placement='right' borderRadius='8px'>
          <Box>
            <Text as='p' sx={CustomMarginText} textStyle='bodySmSemi'>
              {row.original.debitAccount.bankName}
            </Text>
            <>
              <Box display='flex' alignItems='center'>
                <Text as='p' ref={elemText} margin='0px' textStyle='bodySm'>
                  {row.original.debitAccount.accountName}
                </Text>
                {row.original.debitAccount.accountName && (
                  <Text as='span' textStyle='bodySmSemi' sx={SeparatorDebitAccount}>
                    {' | '}
                  </Text>
                )}
                <Text as='span' textStyle='bodySm'>
                  {row.original.debitAccount.cuit}
                </Text>
              </Box>
              <Box display='flex' alignItems='center'>
                <Text as='p' textStyle='bodySm' margin='0px'>
                  {row.original.debitAccount.cbu || row.original.debitAccount.accountNumber}
                </Text>
                {(row.original.debitAccount.cbu || row.original.debitAccount.accountNumber) && (
                  <Text as='span' textStyle='bodySmSemi'>
                    {' | '}
                  </Text>
                )}
                <Text as='span' textStyle='bodySm'>
                  {row.original.debitAccount.accountType}
                </Text>
              </Box>
            </>
          </Box>
        </Tooltip>
      );
    },
    footer: info => info?.column?.id,
  }),
  columnHelper.accessor('totalAmount', {
    id: 'importeTotal',
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Importe
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='labelSmBold' as='span'>
        {formatAmount(row?.original?.debitAccount?.currency, row?.original?.totalAmount, row?.original?.lotTransferTypeCode)}
      </Text>
    ),
    footer: info => info?.column?.id,
  }),
  columnHelper.accessor('lotStatus', {
    id: 'statu',
    enableSorting: false,
    header: () => (
      <Text sx={{ ...CustomMarginText, ...ColorFontsHeaderTable }} textStyle='titleSmBold'>
        Estado
      </Text>
    ),
    cell: ({ row }) => (
      <Text sx={CustomMarginText} textStyle='bodySm' as='span'>
        {ALL_STATUS_TRANSFERS[row?.original?.lotStatus]}
      </Text>
    ),
    footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: 'detail',
    enableSorting: false,
    header: '',
    cell: ({ row }) => {
      const { setDetailParams } = useTransfersContext();
      const handleLinkOnClick = () => {
        pushAnalyticsEvent({
          event: 'select_content',
          content_type: 'Botones seccion Transferencias',
          item_id: 'Ver detalle de lote',
        });
        setDetailParams({
          lot: `${row?.original?.lotNumber}`,
          date: row?.original?.requestDate,
          bank: row?.original?.debitAccount.accountId,
          status: row?.original?.lotStatus,
          amount: row?.original?.totalAmount,
          confection: false,
        });
      };
      return (
        <Tooltip label={TEXT_TOOLTIP_ICON} placement='top' hasArrow>
          <Link to={routesNames.LotDetailsAnnul} onClick={handleLinkOnClick}>
            <IconButtonComponent aria-label='icon-button' variant='icon-text' size='i-md' onClick={() => ''} icon={<ZoomIn />} />
          </Link>
        </Tooltip>
      );
    },
    footer: info => info.column.id,
  }),
];
