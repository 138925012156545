import { addDays, format, isBefore, parse } from 'date-fns';
import { ChangeEvent, useEffect, useState } from 'react';

import { TRANSFER_TYPE_CODE } from '../constants-app';
import initialState from '../context/initialState';
import { useTransfersContext } from '../context/TransfersContext';
import { UseManualTransferFormReturn } from '../Interfaces/hooks/IUseManualTransferForm';
import { ManualTransferData, SelectOption } from '../Interfaces/transferSetup';
import useCheckboxLogic from './useCheckboxLogic';

export function useManualTransferForm(): UseManualTransferFormReturn {
  const {
    manualTransferData,
    transferTypeByOperators,
    transferTypeOptionSelected,
    otpConfigured,
    workingDay,
    setManualTransferData,
    setTransferTypeOptionSelected,
    setDateSelected,
  } = useTransfersContext();
  const [isBTOBProccesSelected, setIsBTOBProccesSelected] = useState(false);

  const [minDate, setMinDate] = useState<Date | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(
    manualTransferData.requestDate ? parse(manualTransferData.requestDate, 'yyyy-MM-dd', new Date()) : new Date(),
  );
  const [formState, setFormState] = useState<ManualTransferData>(manualTransferData);
  const [validDate, setValidDate] = useState(true);
  const selectedTransferType = transferTypeByOperators?.find(
    transferTypeItem => transferTypeItem?.code === manualTransferData?.transferType,
  );
  const [maxDays, setMaxDays] = useState(selectedTransferType?.deferMaxDays || 180);
  const [transferTypeChanged, setTransferTypeChanged] = useState<SelectOption | null>(null);

  const checkboxLogic = useCheckboxLogic(formState);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    checkboxLogic.handleCheckboxChange(formState, setFormState)(event);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = event.target;
    if (type !== 'checkbox') {
      setFormState(prev => ({ ...prev, [name]: value }));
    }
  };

  const onChangeDate = (date: Date) => {
    setSelectedDate(date);
    setFormState(prev => ({
      ...prev,
      requestDate: date ? format(date, 'yyyy-MM-dd') : '',
    }));
  };

  const onRedirectBTOBProcess = (): void => {
    const initialStateForm: ManualTransferData = {
      ...initialState.manualTransferData,
      requestDate: format(minDate || new Date(), 'yyyy-MM-dd'),
    };
    setFormState(initialStateForm);
    setManualTransferData(initialStateForm);
    setSelectedDate(minDate || new Date());
    setIsBTOBProccesSelected(false);
  };

  const handleSelectChange = (selectedOption: SelectOption | null, confirm = false) => {
    if (selectedOption?.value === TRANSFER_TYPE_CODE.INT) {
      setIsBTOBProccesSelected(true);
      return;
    }

    const newState: ManualTransferData = {
      ...manualTransferData,
      transferType: selectedOption?.value || '',
    };

    if (
      manualTransferData.transferType &&
      manualTransferData.transferType !== selectedOption?.value &&
      !!manualTransferData.transfers.length
    ) {
      if (confirm) {
        newState.transfers = [];
        setTransferTypeChanged(null);
      } else {
        setTransferTypeChanged(selectedOption);
        return;
      }
    }

    if (selectedOption?.value === TRANSFER_TYPE_CODE.SUE) {
      newState.consolidated = true;
      newState.unifiedShipping = true;
    }

    setFormState(newState);

    const selectedItem = transferTypeByOperators?.find(transferTypeItem => transferTypeItem?.code === selectedOption?.value);
    if (selectedItem) {
      setTransferTypeOptionSelected({
        calendarDefer: selectedItem?.calendarDefer,
        deferMaxDays: selectedItem?.deferMaxDays,
        code: selectedItem?.code,
        description: selectedItem?.description,
      });
    }
  };

  const handleSelectOptionDisabled = (option: SelectOption | null) => {
    if (!otpConfigured) {
      return option?.value !== TRANSFER_TYPE_CODE.INT;
    }
    return false;
  };

  const handleChangeTransferType = (confirm: boolean) => {
    if (confirm) {
      handleSelectChange(transferTypeChanged, true);
    } else {
      setTransferTypeChanged(null);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!manualTransferData?.requestDate) {
        onChangeDate(selectedDate);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (workingDay && !workingDay?.isWorkingDay) {
      setTimeout(() => {
        const date = parse(workingDay?.nextWorkingDay, 'yyyy-MM-dd', new Date());
        setMinDate(date);
        onChangeDate(date);
      }, 500);
    }
  }, [workingDay]);

  useEffect(() => {
    const { transferType, requestDate, description, paymentNumber, unifiedShipping, consolidated, transfers } = formState;
    setManualTransferData({
      ...manualTransferData,
      transferType,
      requestDate,
      description,
      paymentNumber,
      unifiedShipping,
      consolidated,
      transfers,
    });
  }, [formState]);

  useEffect(() => {
    if (transferTypeOptionSelected?.code) {
      setMaxDays(transferTypeOptionSelected?.deferMaxDays);
    }
  }, [transferTypeOptionSelected?.code, transferTypeOptionSelected?.deferMaxDays]);

  useEffect(() => {
    const isValidDate = isBefore(selectedDate, addDays(minDate || new Date(), maxDays));
    setDateSelected(isValidDate ? selectedDate : null);
    setValidDate(isValidDate);
    if (!isValidDate) {
      setFormState(prev => ({
        ...prev,
        requestDate: '',
      }));
    }
  }, [selectedDate, maxDays, minDate]);

  useEffect(() => {
    if (!manualTransferData.transferType) {
      const newState: ManualTransferData = manualTransferData;
      setFormState(newState);
      onChangeDate(selectedDate);
    }
  }, [manualTransferData.transferType]);

  return {
    minDate,
    selectedDate,
    formState,
    maxDays,
    validDate,
    transferTypeChanged,
    isBTOBProccesSelected,
    handleInputChange,
    onChangeDate,
    handleSelectChange,
    setIsBTOBProccesSelected,
    onRedirectBTOBProcess,
    handleCheckboxChange,
    handleChangeTransferType,
    handleSelectOptionDisabled,
  };
}
