import { Currencies } from '@interfaces/transfers';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { EXCEPTION_CODES, filtersAppliedDrawerDefault } from '../constants-app';
import { useTransfersContext } from '../context/TransfersContext';
import { Bank, BankAccount } from '../Interfaces/api';
import { useTransferCreditProps2 } from '../Interfaces/hooks/IUseTransferCredit';
import { SelectOption } from '../Interfaces/transferSetup';
import { getCreditAccounts, getCreditBanks } from '../services/api';

export function useTransferCredit({
  transferSelected,
  debitAccountSelectedRef,
  enabledAccountSelection,
  setTransferSelected,
}: useTransferCreditProps2) {
  const { manualTransferData, debitAccounts, creditFiltersApplied, setCreditFiltersApplied } = useTransfersContext();

  const [creditPage, setCreditPage] = useState(1);
  const [creditHasMore, setCreditHasMore] = useState(false);
  const [creditDrawerIsOpen, setCreditDrawerIsOpen] = useState(false);
  const [creditAccounts, setCreditAccounts] = useState<BankAccount[]>([]);
  const [creditAccountsLoading, setCreditAccountsLoading] = useState(false);
  const [creditAccountsError, setCreditAccountsError] = useState(false);
  const [creditAccountsFiltered, setCreditAccountsFiltered] = useState<BankAccount[]>([]);
  const [creditAccountsFilteredLoading, setCreditAccountsFilteredLoading] = useState(false);
  const [creditAccountsFilteredError, setCreditAccountsFilteredError] = useState(false);
  const [creditAccountSelectedId, setCreditAccountSelectedId] = useState<string | null>(null);
  const [creditAccountSelected, setCreditAccountSelected] = useState<BankAccount | null>(null);
  const [creditUsageReferences, setCreditUsageReferences] = useState<string[]>([]);
  const [creditUsageReferenceSelected, setCreditUsageReferenceSelected] = useState<SelectOption | null>(null);
  const [creditBanks, setCreditBanks] = useState<Bank[]>([]);
  const [creditBanksLoading, setCreditBanksLoading] = useState(true);
  const [creditBanksError, setCreditBanksError] = useState(false);
  const [creditBankSelected, setCreditBankSelected] = useState<SelectOption | null>(null);
  const [creditBanksCurrencyARS, setCreditBanksCurrencyARS] = useState(false);
  const [creditBanksCurrencyUSD, setCreditBanksCurrencyUSD] = useState(false);
  const [creditCurrencySelected, setCreditCurrencySelected] = useState<string>(Currencies.ARS);
  const [creditFilterTypeSelected, setCreditFilterTypeSelected] = useState<SelectOption | null>(null);
  const [creditFilterData, setCreditFilterData] = useState<string>('');
  const [statusGetCreditBanks, setStatusGetCreditBanks] = useState(false);
  const [sameAccountSelectedCredit, setSameAccountSelectedCredit] = useState(false);
  const [creditReferenceOptionsList, setCreditReferenceOptionsList] = useState<SelectOption[]>([]);
  const [isAnyCreditFilterApplied, setIsAnyCreditFilterApplied] = useState<boolean>(false);
  const [creditBankOptionsList, setCreditBankOptionsList] = useState<SelectOption[]>([]);

  const openCreditDrawer = () => {
    setCreditDrawerIsOpen(true);
  };

  const closeCreditDrawer = () => {
    setCreditDrawerIsOpen(false);
  };

  const changeCreditBanksCurrency = (currency: string) => {
    setCreditCurrencySelected(currency);
    setCreditBanksCurrencyARS(currency === Currencies.ARS);
    setCreditBanksCurrencyUSD(currency === Currencies.USD);
    setCreditUsageReferenceSelected(null);
    setCreditBankSelected(null);
    setCreditFilterTypeSelected(null);
    setCreditFilterData('');
    setCreditAccountSelectedId(null);
    setCreditAccountSelected(null);
  };

  const handleSelectCreditAccount = useCallback(
    (value: string | null) => {
      setCreditAccountSelectedId(value);

      if (!value) {
        setCreditAccountSelected(null);
        return;
      }

      const findIndex = creditAccountsFiltered.findIndex(item => item.accountId === value);
      const creditAccount = creditAccountsFiltered[findIndex];

      if (creditAccount && debitAccountSelectedRef?.current && creditAccount?.identifier === debitAccountSelectedRef?.current) {
        setSameAccountSelectedCredit(true);
        setCreditUsageReferenceSelected(null);
        setCreditBankSelected(null);
        setCreditFilterTypeSelected(null);
        setCreditFilterData('');
        setCreditAccountSelectedId(null);
        setCreditAccountSelected(null);
        return;
      }
      setCreditAccountSelected(creditAccount);

      if (creditDrawerIsOpen) {
        setTimeout(() => {
          closeCreditDrawer();
        }, 300);
      }
    },
    [creditAccountsFiltered, debitAccountSelectedRef?.current, creditDrawerIsOpen],
  );

  const handleRemoveSelectedCreditAccount = () => {
    if (transferSelected?.creditAccount) {
      setTransferSelected({ ...transferSelected, creditAccount: null });
    }
    setCreditAccountSelectedId(null);
    setCreditAccountSelected(null);
  };

  const handleSelectCreditUsageReference = (selectedOption: SelectOption | null) => {
    setCreditUsageReferenceSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleSelectCreditBank = (selectedOption: SelectOption | null) => {
    setCreditBankSelected(selectedOption?.value ? selectedOption : null);
  };

  const handleSelectCreditCurrency = (currency: string) => {
    setCreditCurrencySelected(currency);
  };

  const handleSelectCreditFilterType = (selectedOption: SelectOption | null) => {
    setCreditFilterTypeSelected(selectedOption?.value ? selectedOption : null);
  };

  const { refetch: fetchCreditAccounts } = useQuery(
    ['get-credit-accounts-filtered', manualTransferData.transferType, creditCurrencySelected || '', debitAccountSelectedRef?.current || ''],
    () =>
      getCreditAccounts({
        transferType: manualTransferData.transferType,
        currency: debitAccountSelectedRef?.current ? creditCurrencySelected : '',
        accountSelected: debitAccountSelectedRef?.current || '',
      })?.then(res => res.data),
    {
      retry: 3,
      enabled: false,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success || data?.exception?.code === EXCEPTION_CODES.empty) {
          const accounts: BankAccount[] = data?.data?.accounts || [];
          setCreditAccounts(accounts);
          setCreditAccountsFiltered(accounts);
          setCreditHasMore(data?.paging?.totalPages ? data?.paging?.totalPages > 1 : false);
        } else {
          setCreditAccountsError(true);
          setCreditAccountsFilteredError(true);
          setCreditHasMore(false);
        }
        setCreditAccountsLoading(false);
        setCreditAccountsFilteredLoading(false);
      },
      onError: () => {
        setCreditAccountsLoading(false);
        setCreditAccountsError(true);
        setCreditAccountsFilteredLoading(false);
        setCreditAccountsFilteredError(true);
        setCreditHasMore(false);
      },
    },
  );

  const { refetch: fetchCreditAccountsFiltered } = useQuery(
    [
      'get-credit-accounts-filtered',
      creditPage,
      manualTransferData.transferType,
      creditFiltersApplied.usageReferences || [],
      creditFiltersApplied.banks || [],
      debitAccountSelectedRef?.current || '',
      creditCurrencySelected,
      creditFilterTypeSelected?.value || '',
      creditFilterData,
    ],
    () =>
      getCreditAccounts({
        page: creditPage,
        transferType: manualTransferData.transferType,
        usageReference: creditFiltersApplied.usageReferences || [],
        bankCode: creditFiltersApplied.banks || [],
        accountSelected: debitAccountSelectedRef?.current || '',
        currency: debitAccountSelectedRef?.current ? creditCurrencySelected : '',
        filterType: creditFilterTypeSelected?.value || '',
        filterData: creditFilterData,
      })?.then(res => res.data),
    {
      retry: false,
      enabled: false,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success || data?.exception?.code === EXCEPTION_CODES.empty) {
          const accounts: BankAccount[] = data?.data.accounts || [];
          if (creditPage > 1) {
            setCreditAccountsFiltered(prevAccounts => [...prevAccounts, ...accounts]);
          } else {
            setCreditAccountsFiltered(accounts);
          }
          setCreditHasMore(data?.paging?.totalPages ? creditPage < data?.paging?.totalPages : false);
        } else {
          setCreditAccountsFilteredError(true);
          setCreditHasMore(false);
        }
        setCreditAccountsFilteredLoading(false);
      },
      onError: () => {
        setCreditAccountsFilteredError(true);
        setCreditAccountsFilteredLoading(false);
        setCreditHasMore(false);
      },
    },
  );

  const { refetch: fetchCreditBanks, isError: isErrorFetchCreditBanks } = useQuery(
    ['get-credit-banks', manualTransferData.transferType, debitAccountSelectedRef],
    () =>
      getCreditBanks(manualTransferData.transferType, debitAccountSelectedRef?.current ? creditCurrencySelected : '')?.then(
        res => res.data,
      ),
    {
      retry: 3,
      enabled: false,
      notifyOnChangeProps: ['data', 'error'],
      onSuccess: data => {
        if (data?.exception?.code === EXCEPTION_CODES.success) {
          setCreditBanks(data?.data.banks);
          setStatusGetCreditBanks(true);
        } else {
          setCreditBanksError(true);
        }
        setCreditBanksLoading(false);
      },
      onError: () => {
        setCreditBanksError(true);
        setCreditBanksLoading(false);
      },
    },
  );

  const handleSearchCreditAccount = () => {
    loadCreditAccountsFiltered();
  };

  const resetCreditAccountFilters = () => {
    setCreditFiltersApplied(filtersAppliedDrawerDefault);
    setCreditUsageReferenceSelected(null);
    setCreditBankSelected(null);
    setCreditFilterTypeSelected(null);
    setCreditFilterData('');
  };

  const loadCreditAccounts = () => {
    setCreditPage(1);
    setCreditAccounts([]);
    setCreditAccountsLoading(true);
    setCreditAccountsError(false);
    setCreditAccountsFiltered([]);
    setCreditAccountsFilteredLoading(true);
    setCreditAccountsFilteredError(false);
    setTimeout(() => {
      fetchCreditAccounts();
    }, 300);
  };

  const loadCreditAccountsFiltered = () => {
    setCreditPage(1);
    setCreditAccountsFiltered([]);
    setCreditAccountsFilteredLoading(true);
    setCreditAccountsFilteredError(false);
    setTimeout(() => {
      fetchCreditAccountsFiltered();
    }, 300);
  };

  const loadCreditAccountsFilteredMore = () => {
    if (creditAccountsFilteredError) {
      setCreditHasMore(true);
      setCreditAccountsFilteredError(false);
    } else {
      setCreditPage(value => value + 1);
    }
    setTimeout(() => {
      fetchCreditAccountsFiltered();
    }, 500);
  };

  const loadCreditBanks = () => {
    setCreditBanks([]);
    setCreditBanksLoading(true);
    setCreditBanksError(false);
    setTimeout(() => {
      fetchCreditBanks();
    }, 300);
  };

  useEffect(() => {
    if (enabledAccountSelection && creditAccounts.length === 1) {
      const debitAccountSelected =
        debitAccountSelectedRef?.current && debitAccounts.length
          ? debitAccounts.find(item => item.identifier === debitAccountSelectedRef?.current)
          : undefined;
      if (
        !debitAccountSelected ||
        (debitAccountSelected &&
          debitAccountSelected?.identifier !== creditAccounts[0].identifier &&
          debitAccountSelected?.currency === creditAccounts[0].currency)
      ) {
        handleSelectCreditAccount(creditAccounts[0].accountId);
      } else {
        handleSelectCreditAccount(null);
      }
    }
  }, [debitAccountSelectedRef?.current, creditAccounts, debitAccounts, enabledAccountSelection]);

  useEffect(() => {
    const options = creditUsageReferences?.map(reference => ({ value: reference, label: reference })) || [];
    setCreditReferenceOptionsList(options);
  }, [creditUsageReferences]);

  useEffect(() => {
    if (creditFiltersApplied.banks?.length > 0 || creditFiltersApplied.usageReferences?.length > 0) {
      setIsAnyCreditFilterApplied(true);
    } else {
      setIsAnyCreditFilterApplied(false);
    }
  }, [creditFiltersApplied]);

  useEffect(() => {
    const bankOptionsList =
      creditBanks
        .filter((obj, index, banksList) => banksList.findIndex(item => item.bcraCode === obj.bcraCode) === index)
        .map(bank => ({ value: bank.bcraCode, label: bank.bankName })) || [];
    setCreditBankOptionsList(bankOptionsList);
  }, [creditBanks]);

  useEffect(() => {
    if (!creditAccountsLoading && creditAccounts.length) {
      setCreditAccountsLoading(false);
    }
  }, [creditAccountsLoading, creditAccounts]);

  return {
    creditPage,
    creditHasMore,
    creditDrawerIsOpen,
    creditAccountSelectedId,
    creditAccountSelected,
    creditAccounts,
    creditAccountsLoading,
    creditAccountsError,
    creditAccountsFiltered,
    creditAccountsFilteredLoading,
    creditAccountsFilteredError,
    creditUsageReferences,
    creditUsageReferenceSelected,
    creditBanks,
    creditBanksLoading,
    creditBanksError,
    creditBanksCurrencyARS,
    creditBanksCurrencyUSD,
    creditBankSelected,
    creditCurrencySelected,
    creditFilterTypeSelected,
    creditFilterData,
    isErrorFetchCreditBanks,
    statusGetCreditBanks,
    sameAccountSelectedCredit,
    creditFiltersApplied,
    creditReferenceOptionsList,
    isAnyCreditFilterApplied,
    creditBankOptionsList,
    fetchCreditBanks,
    setCreditFiltersApplied,
    loadCreditAccounts,
    loadCreditAccountsFiltered,
    loadCreditAccountsFilteredMore,
    loadCreditBanks,
    setCreditAccountsLoading,
    setCreditBanksLoading,
    setCreditBanksCurrencyARS,
    setCreditBanksCurrencyUSD,
    setCreditAccountSelected,
    setCreditAccountSelectedId,
    setCreditCurrencySelected,
    handleSelectCreditAccount,
    handleRemoveSelectedCreditAccount,
    handleSelectCreditUsageReference,
    handleSelectCreditBank,
    handleSelectCreditCurrency,
    handleSelectCreditFilterType,
    setCreditFilterData,
    handleSearchCreditAccount,
    changeCreditBanksCurrency,
    resetCreditAccountFilters,
    setCreditUsageReferences,
    setSameAccountSelectedCredit,
    openCreditDrawer,
    closeCreditDrawer,
  };
}
