const logoStyles = {
  maxWidth: '40px',
  width: '40px',
  height: '40px',
  marginRight: '6px',
  outline: '1px solid',
  outlineColor: 'gray',
  borderRadius: '50%',
};
export default logoStyles;
