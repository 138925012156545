const TEXTS = {
  title: {
    debit: 'Elegir cuenta de débito',
    credit: 'Elegir cuenta de crédito',
  },
  alertCurrency: 'Tené en cuenta que solo te mostraremos las cuentas de crédito disponibles según la moneda de la cuenta de débito.',
  subtitle: 'Estas son tus cuentas disponibles',
};

const TEXTS_ERROR = {
  title: 'La información no está disponible en este\n momento',
  description: 'Estamos trabajando para solucionarlo.',
  link: 'Reintentar',
};

const TEXTS_EMPTY = {
  title: 'No hay resultados para tu búsqueda',
  description:
    'Para este tipo de transferencia, con la cuenta de débito y moneda\nque elegiste, no hay una cuenta de crédito disponible.\nModificá la cuenta de débito o revisá la configuración en ABM.',
  link: 'Modificar cuenta de débito',
};

const IDS = {
  drawerTitle: 'drawerTitle',
  drawerBody: 'drawerBody',
};

export { IDS, TEXTS, TEXTS_EMPTY, TEXTS_ERROR };
