const Container = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '16px',
};

const ContainerRelative = {
  '& > div': {
    // !important is necessary
    position: 'relative !important',
    top: 'auto !important',
  },
};

const BoxLeft = {
  display: 'flex',
  flexDirection: 'column',
  width: '610px',
  padding: '0 32px',
};

const BoxRight = {
  display: 'flex',
  flexDirection: 'column',
  width: '610px',
  paddingBottom: '100px',
};

const BtnAddTransfer = {
  'span.chakra-button__icon>svg': {
    width: '24px',
    height: '24px',
  },
};

const ToastContainer = {
  marginLeft: '64px',
  width: '500px',
};

const CustomBoxTransferTypeTitle = {
  marginBottom: '24px',
};

export { BoxLeft, BoxRight, BtnAddTransfer, Container, ContainerRelative, CustomBoxTransferTypeTitle, ToastContainer };
