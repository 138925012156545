import { Box, Flex, FormControl, FormLabel, Icon, Input, Text } from '@chakra-ui/react';
import AutoNumericInput from '@components/AutoNumericInput';
import { IconButtonComponent } from '@wow/button';
import { Card, CardSection } from '@wow/card';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

import { useAmountInput } from '../../hooks/useAmountInput';
import { addAnimationSkeletonfadeIn, withOutAnimationSkeletonfadeIn } from '../../styled/global.styled';
import CustomSelect from '../CustomSelect';
import {
  ADDITIONAL_INFORMATION_PRO_TEST_ID,
  CLIENT_NUMBER_LABEL,
  CLIENT_NUMBER_PLACEHOLDER,
  CLIENT_TEST_ID,
  CREDIT_NOTE_AMOUNT_LABEL,
  CREDIT_NOTE_AMOUNT_TEST_ID,
  CREDIT_NOTE_NUMBER_LABEL,
  CREDIT_NOTE_NUMBER_TEST_ID,
  DOCUMENT_NUMBER_LABEL,
  DOCUMENT_NUMBER_TEST_ID,
  DOCUMENT_TO_CANCEL_LABEL,
  DOCUMENT_TO_CANCEL_PLACEHOLDER,
  DOCUMENT_TO_CANCEL_TEST_ID,
  ICON_BUTTON_ACCORDION_TEST_ID,
  MAX_LENGTH_CLIENT_NUMBER,
  MAX_LENGTH_PAYMENT_ORDER_TYPE,
  PAYMENT_ORDER_NUMBER_LABEL,
  PAYMENT_ORDER_NUMBER_TEST_ID,
  PAYMENT_ORDER_TYPE_LABEL,
  PAYMENT_ORDER_TYPE_TEST_ID,
  PLACEHOLDER_INPUT_AMOUNT,
  RETENTION_AMOUNT_LABEL,
  RETENTION_AMOUNT_TEST_ID,
  RETENTION_TYPE_LABEL,
  RETENTION_TYPE_PLACEHOLDER,
  RETENTION_TYPE_TEST_ID,
  TITLE,
  TWELVE_CHARACTERS_MAXIMUM,
} from './constants';
import { CustomBox } from './index.styled';
import { AdditionalInformationFormProps } from './interfaces';

function AdditionalInformationForm({
  retentionAmount,
  retentionType,
  retentionOptions,
  isLoadingComplete,
  document,
  documentOptions,
  creditNoteAmount,
  creditNoteNumber,
  numberClient,
  documentNumber,
  paymentOrderType,
  paymentOrderNumber,
  onChangeNumberClient,
  onChangeRetentionType,
  onChangeDocument,
  onChangeDocumentNumber,
  onChangeTotalRetentionAmount,
  onChangePaymentOrderType,
  onChangePaymentOrderNumber,
  onChangeCreditNoteNumber,
  onChangeCreditNoteAmount,
}: AdditionalInformationFormProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [animation, setAnimation] = useState({});

  const { inputValue: totalRetentionAmountValue } = useAmountInput(retentionAmount);

  const { inputValue: creditNoteAmountValue } = useAmountInput(creditNoteAmount);

  useEffect(() => {
    onChangeTotalRetentionAmount(totalRetentionAmountValue);
    onChangeCreditNoteAmount(creditNoteAmountValue);
  }, [totalRetentionAmountValue, creditNoteAmountValue]);

  useEffect(() => {
    const styles = isLoadingComplete ? addAnimationSkeletonfadeIn : withOutAnimationSkeletonfadeIn;
    setAnimation(styles);
  }, [isLoadingComplete]);

  return (
    <Box data-testid={ADDITIONAL_INFORMATION_PRO_TEST_ID}>
      <Card>
        <CardSection>
          <Text textStyle='titleMdBold'>{TITLE}</Text>
          <IconButtonComponent
            data-testid={ICON_BUTTON_ACCORDION_TEST_ID}
            aria-label='icon-button'
            variant='icon-text'
            size='i-md'
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            icon={<Icon as={isAccordionOpen ? ChevronUp : ChevronDown} />}
          />
        </CardSection>
        {isAccordionOpen && (
          <Box sx={CustomBox}>
            <Box width='100%' sx={animation}>
              <Flex gap='24px' marginBottom='24px'>
                <FormControl>
                  <FormLabel htmlFor={CLIENT_TEST_ID}>{CLIENT_NUMBER_LABEL}</FormLabel>
                  <Input
                    id={CLIENT_TEST_ID}
                    name={CLIENT_TEST_ID}
                    data-testid={CLIENT_TEST_ID}
                    type='text'
                    size='md'
                    onChange={e => onChangeNumberClient(e?.target?.value)}
                    maxLength={MAX_LENGTH_CLIENT_NUMBER}
                    value={numberClient}
                    placeholder={CLIENT_NUMBER_PLACEHOLDER}
                  />
                </FormControl>
              </Flex>
              <Flex gap='24px' marginBottom='24px'>
                <FormControl>
                  <FormLabel htmlFor={DOCUMENT_TO_CANCEL_TEST_ID}>{DOCUMENT_TO_CANCEL_LABEL}</FormLabel>
                  <CustomSelect
                    value={document || undefined}
                    id={DOCUMENT_TO_CANCEL_TEST_ID}
                    name={DOCUMENT_TO_CANCEL_TEST_ID}
                    options={documentOptions}
                    placeholder={DOCUMENT_TO_CANCEL_PLACEHOLDER}
                    onChange={e => onChangeDocument(e)}
                    status={isLoadingComplete}
                    width='100%'
                    isSearchable={false}
                    isClearable
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor={DOCUMENT_NUMBER_TEST_ID}>{DOCUMENT_NUMBER_LABEL}</FormLabel>
                  <Input
                    id={DOCUMENT_NUMBER_TEST_ID}
                    name={DOCUMENT_NUMBER_TEST_ID}
                    data-testid={DOCUMENT_NUMBER_TEST_ID}
                    type='text'
                    size='md'
                    onChange={e => onChangeDocumentNumber(e?.target?.value)}
                    maxLength={TWELVE_CHARACTERS_MAXIMUM}
                    value={documentNumber}
                  />
                </FormControl>
              </Flex>
              <Flex gap='24px' marginBottom='24px'>
                <FormControl>
                  <FormLabel htmlFor={RETENTION_TYPE_TEST_ID}>{RETENTION_TYPE_LABEL}</FormLabel>
                  <CustomSelect
                    value={retentionType || undefined}
                    id={RETENTION_TYPE_TEST_ID}
                    name={RETENTION_TYPE_TEST_ID}
                    options={retentionOptions}
                    placeholder={RETENTION_TYPE_PLACEHOLDER}
                    onChange={e => onChangeRetentionType(e)}
                    status={isLoadingComplete}
                    width='100%'
                    isSearchable={false}
                    isClearable
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor={RETENTION_AMOUNT_TEST_ID}>{RETENTION_AMOUNT_LABEL}</FormLabel>
                  <AutoNumericInput
                    value={retentionAmount}
                    id={RETENTION_AMOUNT_TEST_ID}
                    name={RETENTION_AMOUNT_TEST_ID}
                    data-testid={RETENTION_AMOUNT_TEST_ID}
                    placeholder={PLACEHOLDER_INPUT_AMOUNT}
                    size='md'
                    decimalPlaces={2}
                    onValueChange={e => onChangeTotalRetentionAmount(e)}
                    showCurrencySymbol={false}
                  />
                </FormControl>
              </Flex>
              <Flex gap='24px' marginBottom='24px'>
                <FormControl>
                  <FormLabel htmlFor={PAYMENT_ORDER_TYPE_TEST_ID}>{PAYMENT_ORDER_TYPE_LABEL}</FormLabel>
                  <Input
                    id={PAYMENT_ORDER_TYPE_TEST_ID}
                    name={PAYMENT_ORDER_TYPE_TEST_ID}
                    data-testid={PAYMENT_ORDER_TYPE_TEST_ID}
                    type='text'
                    size='md'
                    onChange={e => onChangePaymentOrderType(e?.target?.value)}
                    maxLength={MAX_LENGTH_PAYMENT_ORDER_TYPE}
                    value={paymentOrderType}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor={PAYMENT_ORDER_NUMBER_TEST_ID}>{PAYMENT_ORDER_NUMBER_LABEL}</FormLabel>
                  <Input
                    id={PAYMENT_ORDER_NUMBER_TEST_ID}
                    name={PAYMENT_ORDER_NUMBER_TEST_ID}
                    data-testid={PAYMENT_ORDER_NUMBER_TEST_ID}
                    type='text'
                    size='md'
                    onChange={e => onChangePaymentOrderNumber(e?.target?.value)}
                    maxLength={TWELVE_CHARACTERS_MAXIMUM}
                    value={paymentOrderNumber}
                  />
                </FormControl>
              </Flex>
              <Flex gap='24px'>
                <FormControl>
                  <FormLabel htmlFor={CREDIT_NOTE_NUMBER_TEST_ID}>{CREDIT_NOTE_NUMBER_LABEL}</FormLabel>
                  <Input
                    id={CREDIT_NOTE_NUMBER_TEST_ID}
                    name={CREDIT_NOTE_NUMBER_TEST_ID}
                    data-testid={CREDIT_NOTE_NUMBER_TEST_ID}
                    size='md'
                    onChange={e => /^\d*$/.test(e?.target?.value) && onChangeCreditNoteNumber(Number(e?.target?.value))}
                    maxLength={TWELVE_CHARACTERS_MAXIMUM}
                    value={creditNoteNumber}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor={CREDIT_NOTE_AMOUNT_TEST_ID}>{CREDIT_NOTE_AMOUNT_LABEL}</FormLabel>
                  <AutoNumericInput
                    value={creditNoteAmount}
                    id={CREDIT_NOTE_AMOUNT_TEST_ID}
                    name={CREDIT_NOTE_AMOUNT_TEST_ID}
                    data-testid={CREDIT_NOTE_AMOUNT_TEST_ID}
                    placeholder={PLACEHOLDER_INPUT_AMOUNT}
                    size='md'
                    decimalPlaces={2}
                    onValueChange={onChangeCreditNoteAmount}
                    showCurrencySymbol={false}
                  />
                </FormControl>
              </Flex>
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default AdditionalInformationForm;
