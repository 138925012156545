import { ManualTransferData } from '@interfaces/transferSetup';

const isManualTransferFormEmpty = (manualTransfer: ManualTransferData): boolean => {
  const { transferType, description, paymentNumber, consolidated, unifiedShipping, hasDraft, transfers } = manualTransfer;

  return (
    transferType === '' &&
    description === '' &&
    paymentNumber === '' &&
    consolidated === false &&
    unifiedShipping === false &&
    hasDraft === null &&
    transfers.length === 0
  );
};

export { isManualTransferFormEmpty };
