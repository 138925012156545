import { Box, Table, Text } from '@chakra-ui/react';
import BaseScreen from '@components/BaseScreen';
import BottomNav from '@components/BottomNav';
import IconButtonGoUp from '@components/ButtonGoUp';
import IconBeware from '@components/Icons/icon-beware';
import IconDisconnected from '@components/Icons/icon-disconnected';
import CustomModal from '@components/Modal';
import ViewNoResult from '@components/ViewNoResult';
import { useTransferSetupStep4 } from '@hooks/useTransferSetupStep4';
import { AlertComponent, AlertDescription } from '@wow/alert';
import { useEffect, useState } from 'react';

import SummaryItem from './components/SummaryItem';
import SummarySkeleton from './components/SummarySkeleton';
import { TEST_IDS, TEXTS } from './constants';

function Step4({ isRoute }: { isRoute?: boolean }) {
  const {
    isLoading,
    isError,
    formatCurrentDate,
    formatNextDate,
    transfersList,
    transferType,
    showDiscardModal,
    handleInvalidDate,
    handleDiscardInvalidDate,
    handleCancelDiscard,
    handleRetryRequests,
  } = useTransferSetupStep4();
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

  const handleUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsScrollUpVisible(scrollTop > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isError) {
    return (
      <BaseScreen title={TEXTS.header} sizeTitle='h2Bold' id='titleTef'>
        <ViewNoResult
          title={TEXTS.errorTitle}
          description={TEXTS.errorDescription}
          linkName={TEXTS.buttonRetry}
          Icon={IconDisconnected}
          onClickLink={handleRetryRequests}
        />
        <BottomNav
          textButtonPrimaryText={TEXTS.buttonNextWorkingDay}
          clickButtonPrimaryText={() => null}
          buttonPrimaryDisabled
          textButtonPrimaryOutLineText={TEXTS.buttonDiscard}
          buttonPrimaryOutLineDisabled
          handleFourthButton={() => null}
          collapsableTransferType={false}
          size='md'
        />
      </BaseScreen>
    );
  }

  return (
    <Box>
      {isRoute && (
        <Text textStyle='h2Bold' whiteSpace='pre-line'>
          {TEXTS.header}
        </Text>
      )}
      <Box margin='12px 0 100px'>
        {isScrollUpVisible && <IconButtonGoUp handleUp={handleUp} />}
        {isLoading ? (
          <SummarySkeleton />
        ) : (
          <Box id={TEST_IDS.summaryTable}>
            <AlertComponent id='alert-invalid-date' variant='warning' width='100%'>
              <AlertDescription>
                <Text
                  textStyle='bodySm'
                  dangerouslySetInnerHTML={{ __html: TEXTS.warningTitle(transfersList.length, formatCurrentDate) }}
                  whiteSpace='pre-line'
                />
              </AlertDescription>
              <style>{`.chakra-alert__desc { max-width: none; }`}</style>
            </AlertComponent>
            <Text as='div' textStyle='h4Bold' margin='12px 0 16px'>
              {TEXTS.title(formatNextDate)}
            </Text>
            <Table>
              {transfersList.map((item, index) => (
                <SummaryItem key={`${item.transferNumber}`} index={index} data={item} />
              ))}
            </Table>
          </Box>
        )}
      </Box>
      {isRoute && (
        <>
          <BottomNav
            textButtonPrimaryText={TEXTS.buttonNextWorkingDay}
            clickButtonPrimaryText={handleInvalidDate}
            buttonPrimaryDisabled={isLoading}
            textButtonPrimaryOutLineText={TEXTS.buttonDiscard}
            buttonPrimaryOutLineDisabled={isLoading}
            handleFourthButton={() => handleDiscardInvalidDate(false)}
            showTextTransferType
            showTextTransferTypeLoading={isLoading}
            textTransferType={transferType}
            collapsableTransferType={false}
            size='md'
          />
          <CustomModal
            isOpen={showDiscardModal}
            Icon={IconBeware}
            onCancel={handleCancelDiscard}
            title={TEXTS.modalDiscardTitle(transfersList.length)}
            firstDescription={TEXTS.modalDiscardDescription(transfersList.length)}
            enableFirstButton
            firstTextButton={TEXTS.buttonCancel}
            enableSecondButton
            secondTextButton={TEXTS.buttonDiscardConfirm}
            actionButton2={() => handleDiscardInvalidDate(true)}
            alternativeButtonStyling
            size='xl'
            newStyle
          />
        </>
      )}
    </Box>
  );
}

export default Step4;
